import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "España",
        dialogDetermine: "Seguro",
        dreivatives: "Entrega",
        markets: "Citas",
        assets: "activos",
        liquidity: "Minería de liquidez",
        helpCentre: "Centro ayuda",
        announcementCenter: "Centro anuncios",
        about: "sobre",
        bulletinCentre: "Centro anuncios",
        serviceAgreement: "Acuerdo servicio",
        support: "apoyo",
        onlineSupportCustomerService: "Servicio línea",
        suggestionsAndFeedback: "Sugerencias comentarios",
        service: "Servir",
        download: "descarga",
        coinApplication: "Solicitud listado monedas",
        privacyStatement: "Declaracion privacidad",
        trade: "comercio",
        standardConteact: "Sostenible",
        signOut: "desconectar",
        assetWallets: "Cartera activos",
        financeRecords: "Registros cambios cuenta",
        identification: "Autenticación",
        personalCenter: "centro personal",
        chooseLanguage: "Elige un idioma",
        tips: "pista",
        dark: "modo oscuro",
        light: "modo diurno",
        languageTitle: "idioma",
        convertCurrency: "Convertir divisa",
        cancel: "Cancelar",
        aboutUs: "sobre nosotros",
        standard: "Continuidad norma",
        share: "compartir",
        introduce: "Introducción plataforma",
        set: "configuración",
        changePassword: "Cambiar contraseña inicio sesión",
        denomination: "Moneda cotización",
        color: "Configuración colores",
        code: "número versión",
        color1: "El rojo sube y el verde cae",
        color2: "El verde sube y el rojo cae",
        setLangue: "configuración idioma",
        vip: "Beneficios VIP",
        vip2: "Nivel VIP actual",
        safeMenu: "Centro Seguridad",
        conventional: "convencional",
        other: "otro",
        quickTips: "Agregue hasta 3 botones función acceso directo",
        quickTransaction: "Transacción rápida",
        safeConvenient: "Seguro y conveniente",
        timeSharing: "tiempo compartido",
        currentLevel: "nivel actual",
        levelBenefits: "%{level} nivel de ingresos",
        teamLevel: "Nivel equipo",
        levelLower: "%{nivel}subordinado",
        rules: "Descripción regla",
        bindWithdrawalAddress: "Vincular dirección retiro",
        tipsBindWithdrawalAddress: "Aún no ha vinculado su dirección de retiro, vincúlela primero",
        walletAddressFormatIncorrect: "El formato de la dirección de la billetera es incorrecto",
        tradeRules1: "Predice el ascenso y la caída en el período futuro. El precio en el momento de la orden se utiliza como precio inicial y el precio en el último segundo del período de predicción se utiliza como precio de liquidación. Si el precio de liquidación es mayor que el precio inicial, significa un aumento, y si el El precio de liquidación es menor que el precio inicial, significa una disminución.",
        tradeRules2: "1.que es un contrato",
        tradeRules3: "Esta plataforma proporciona contratos perpetuos basados ​​en USDT. Los usuarios pueden juzgar el aumento o la caída y elegir comprar contratos largos o vender contratos cortos para obtener ganancias del aumento o caída de los precios de los activos digitales. Los contratos perpetuos no tienen fecha de entrega y los usuarios pueden conservarlos para siempre.",
        tradeRules4: "2.¿Qué es el margen?",
        tradeRules5: "En el mercado de contratos virtuales, los usuarios solo necesitan pagar una pequeña cantidad de fondos en una determinada proporción según el precio del contrato como garantía financiera para el cumplimiento del contrato, y luego pueden participar en la compra y venta del contrato. Este tipo de fondos es el depósito de contrato virtual. \nLos contratos en esta plataforma adoptan el modelo de margen separado de cuentas separadas. Cada moneda corresponde a una cuenta de contrato. Los activos y posiciones de las diferentes monedas de contrato son independientes entre sí, y las transferencias y transacciones entre diferentes cuentas de contrato no se afectan entre sí. Después de abrir la cuenta de contrato de un usuario, los riesgos y beneficios de todas las posiciones mantenidas en la cuenta de contrato se calcularán juntos.",
        tradeRules6: "3.Cómo calcular el margen",
        tradeRules7: "Margen de posición = valor nominal del contrato * número de contratos abiertos / multiplicador de apalancamiento\nmargen congelado = la suma de los márgenes de todas las órdenes realizadas con éxito y órdenes de apertura en la orden actual\nmargen disponible = saldo de cuenta - margen de posición - margen congelado",
        tradeRules8: "4.Cómo calcular la tasa de margen",
        tradeRules9: "La tasa de margen es una medida del riesgo de los activos del usuario. \nTasa de margen = (Pérdidas y ganancias no realizadas + Margen de posición + Margen disponible + Margen de congelación - Tarifa de liquidación) / Margen de posición \nCuanto menor sea la tasa de margen, mayor será el riesgo de la cuenta. Cuando la tasa de margen sea menor o igual al 0%, se activará la liquidación forzosa.",
        tradeRules10: "5.¿Cuál es el precio de paridad forzosa estimado?",
        tradeRules11: "El precio de liquidación forzosa estimado, es decir, el precio calculado por el sistema que teóricamente desencadena la liquidación forzosa, es sólo de referencia y puede desviarse ligeramente del valor real.",
        tradeRules12: "6.¿Qué es el patrimonio total de la cuenta y las pérdidas y ganancias no realizadas?",
        tradeRules13: "Patrimonio total de la cuenta = saldo de la cuenta + pérdidas y ganancias no realizadas\nLas pérdidas y ganancias no realizadas son las pérdidas y ganancias de la posición actualmente mantenida por el usuario en el contrato de divisas. Las pérdidas y ganancias no realizadas cambiarán con los últimos cambios en el precio de la transacción. \nPérdidas y ganancias no realizadas para posiciones largas = (1/precio promedio de la posición - 1/último precio de transacción) * número de contratos para posiciones largas * valor nominal del contrato * último precio de transacción \n Ganancias y pérdidas no realizadas para posiciones cortas = (1/ último precio de transacción - 1/ Precio promedio de la posición) * Número de contratos cortos * Valor nominal del contrato * Último precio de transacción",
        text1: "seguridad de la cuenta",
        text2: "modo día y noche",
        text3: "limpiar cache",
        text4: "¿Está seguro de que desea cerrar sesión?",
        text5: "¿Estás seguro de que quieres borrar el caché?",
        text6: "multi lenguaje",
        text7: "Para proteger su seguridad, le recomendamos activar al menos una autenticación de dos factores."
    },
    login: {
        login: "Acceso",
        register: "registro",
        title1: "Opere en cualquier momento y en cualquier lugar y vea las condiciones del mercado en tiempo real",
        title2: "Opere en cualquier momento y en cualquier lugar y vea las condiciones del mercado en tiempo real",
        title3: "La plataforma de comercio de criptomonedas más confiable",
        formTitle1: "Cuenta Ingreso",
        formTitle2: "¡Bienvenido de nuevo! Inicie sesión con su correo electrónico, número de teléfono",
        btn1: "Teléfono",
        btn2: "Correo",
        btn3: "Acceso",
        btn4: "registro",
        btn5: "Olvidaste contraseña?",
        field1: "Número telefónico",
        field2: "contraseña",
        field3: "Correo",
        placeholder1: "nación",
        placeholder2: "Por favor introduce teléfono móvil",
        placeholder3: "Por favor ingrese contraseña",
        placeholder4: "por favor ingrese su correo electrónico",
        content12: "Por favor introduce teléfono móvil",
        content13: "Por favor ingrese contraseña",
        content14: "por favor ingrese su correo electrónico",
        content16: "Servicios financieros activos digitales",
        loginSuccess: "Inicio sesión correcto ",
        chooseArea: "Por favor seleccione código área",
        placeholder5: "Por favor seleccione código  país",
        placeholder6: "recordar contraseña",
        placeholder7: "Descargar aplicación",
        placeholder8: "Contactar Servicio Cliente",
        placeholder9: "Seleccione país región",
        placeholder10: "codigo AREA",
        placeholder11: "Inicio sesión billetera",
        placeholder12: "La operación falló, cambie a",
        placeholder13: "billetera"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "acerca de",
        aboutCoinbeadCenterDetails: "Tiene la plataforma de comercio de activos blockchain líder en el mundo y opera todo el ecosistema.",
        footDetails1: "volumen de operaciones diario",
        footDetails2: "transacciones/segundo",
        footDetails3: "Centro ayuda",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Es un sitio web internacional de activos digitales de fama mundial, que proporciona principalmente monedas y derivados de activos digitales como Bitcoin (BTC), Litecoin (LTC) y Ethereum (ETH) a usuarios globales.",
        coinbeadCenterDetailsm: "Al adoptar tecnologías avanzadas como GSLB, servidores de red, transacciones de red, redes, motores de comercio de memoria de alta velocidad de múltiples máquinas, billeteras frías, billeteras activas inteligentes fuera de línea, etc., servimos a nuestros clientes con múltiples terminales, como teléfonos móviles web y PC. Proporcionar servicios de transacciones de tarifas digitales seguros, estables y confiables. Al mismo tiempo, mejoramos y actualizamos productos y servicios en función de las sugerencias y necesidades de los usuarios para brindar mejores servicios a cada cliente, y la mejora de la experiencia del usuario innovadora no tiene fin.",
        infrastructureCenterTitle: "infraestructura",
        infrastructureCenterDetails: "Nuestra misión es brindar servicios de infraestructura para todo el ecosistema blockchain.",
        freeCenterTitle: "gratis",
        freeCenterDetails: "Nuestra visión es hacer que el dinero fluya libremente en todo el mundo. Creemos que difundir esta libertad puede hacer del mundo un lugar mejor.",
        futureCenterTitle: "Pioneros en el futuro de la tecnología",
        futureCenterDetails: "Abra el futuro de la tecnología",
    },
    applicationForListing: {
        title: "Solicitud de listado de monedas",
        projectName: "nombre del proyecto",
        tokenName: "Nombre del token",
        projectDescription: "Descripción del Proyecto",
        contractAddress: "Dirección del contrato",
        officialWebsite: "página web oficial",
        ContactAndEmail: "Email contacto",
        ContactAndName: "Nombre contacto",
        file: "Cargar archivo adjunto",
        pleaseEnter: "por favor escribe",
        enterFile: "Por favor sube archivos",
        upload1: "Actualmente limitado a 1 selección de archivos,",
        upload2: "Esta vez elegí",
        upload3: "archivos,",
        upload4: "Total seleccionado",
        upload5: "archivos",
    },
    public: {
        submit: "entregar",
        copySuccessfully: "Copiado exitosamente",
        copyFailed: "Copia fallida",
        noDate: "Sin datos",
        submitSuccessfully: "Enviado satisfactoriamente",
        goBack: "volver a la última página",
        cancel: "Cancelar",
        home: "Inicio",
        loading: "cargando...",
        confirm: "confirmar",
        loadingFailed: "anomalía red",
        nomore: "no más",
        tryAgain: "¡Actualice la página/salga del programa y cárguelo nuevamente!",
        hot: "Zonas populares",
    },
    inviteFriends: {
        inviteFriends: "Compartir con amigos",
        freedomOfWealth: "Cooperación ganar-ganar",
        myInvitationCode: "mi código de invitación",
        clickCopy: "Haga clic para copiar",
        myInvitationLink: "mi enlace de invitación",
        clicktoSaveTheQRCode: "Haga clic para guardar el código QR",
        saveQRCode: "Invitar código QR",
        numberOfSubordinates: "numero subordinados",
        rebateIncome: "Ingresos reembolso",
        people: "gente",
        myPromotionRevenue: "Mis ingresos promocionales",
        numberOfDirectSubordinates: "Número de subordinados directos",
        totalNumberOfSubordinates: "Número total de subordinados",
        directSubordinateReturnsCommission: "Reembolso de comisión para subordinados directos",
        theTotalCommission: "reembolso total",
        checkHistory: "Ver registros de reembolso",
        history: "Registro comisiones",
        theLowerAccount: "cuenta subordinada",
        type: "tipo:",
        serviceCharge: "Tasa tramitación:",
        percentageofCommissionReturned: "Ratio reembolso:",
        commissionrebateAmount: "Monto reembolso:",
        time: "tiempo:",
        typeName1: "Recarga comisión",
        typeName2: "Distribución de tres niveles de la tarifa de gestión de pedidos de MT",
        typeName3: "MT Distribución Rentable de Tres Niveles",
        typeName4: "Recompensas por recomendación",
        typeName30: "Recarga comisión",
        typeName31: "Reembolso periódico de ganancias comerciales",
        typeName32: "Reembolso de ganancias de minería de liquidez",
        typeName45: "Reembolso tarifa transacción",
        typeName46: "Reembolso comisión sobre tarifa gestión propia",
        typeName50: "Reembolso tarifa gestión contrato de entrega",
        typeName51: "Reembolso tarifa gestión contratos perpetuos",
        typeName52: "Reembolso comisión adicional por gastos gestión contrato entrega",
        typeName53: "Reembolso comisión adicional por tarifas gestión contratos perpetuos",
        typeName54: "Reembolsos adicionales en tarifas gestión contratos perpetuos",
        shareRules1: "Instrucciones invitación",
        shareRules2: "Ingresa al centro de invitaciones, copia el enlace o código de invitación y compártelo con tus amigos. Los amigos pueden convertirse en sus subordinados registrándose con su código de invitación.",
        shareRules3: "Gana reembolsos",
        shareRules4: "Cuando los subordinados realizan transacciones, usted puede obtener las comisiones correspondientes; se admiten hasta tres niveles de subordinados. Por ejemplo, si invita al amigo A, A invita a B y B invita a C, entonces A, B y C realizan contratos y otras transacciones en la plataforma, y ​​todos pueden obtener las comisiones correspondientes.",
        shareRules5: "Nivel de equipo",
        shareRules6: "Cuantos más subordinados de primer nivel promuevas, mayor será el nivel del equipo y mayor será el reembolso que podrás disfrutar. Los niveles de equipo se dividen en LV1-LV6. Las reglas de actualización se muestran en la siguiente tabla, donde 'N' es el número de subordinados de primer nivel que han recargado y completado la autenticación de nombre real.",
        shareRules7: "Cuando los subordinados realizan apuestas sobre contratos de entrega, usted puede recibir comisiones en proporción a sus apuestas.",
        upgradeConditions: "Requerir",
        recommendedNumberOfPeople: "Número personas recomendado"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Sugerencias y comentarios",
        text: "Si encuentra errores o tiene sugerencias de productos durante el uso, envíenos sus comentarios.",
        email: "dirección correo electrónico",
        enterEmail: "Por favor ingrese dirección correo electrónico",
        enterCorrectEmail: "Por favor ingrese dirección correo electrónico correcta",
        title: "título",
        enterTitle: "Por favor ingrese el título de una pregunta",
        describeTheProblem: "Describe el problema",
        enterDescribeTheProblem: "Por favor ingresa el problema que describe",
        submit: "entregar",
    },
    help: {
        enterSearch: "buscar",
        title: "Centro ayuda",
        search: "Por favor ingrese su pregunta",
        text1: "Comprenda sus necesidades y continúe brindándole servicios más convenientes"
    },
    announcement: {
        enterSearch: "buscar",
        title: "Centro anuncios",
        search: "Por favor ingrese su pregunta"
    },
    privacy: {
        title: "Declaracion privacidad",
        content1: "1. ¿Qué información personal sobre los usuarios se recopila?",
        content2: "La información personal se recopila para proporcionar productos y servicios y mejorarlos continuamente.",
        content3: "Los siguientes son los tipos de información personal que recopilamos:",
        content4: 'Información que usted nos proporciona: Recibimos y almacenamos cualquier información que usted proporcione en relación con la Plataforma. Puede optar por no proporcionar cierta información, pero como resultado es posible que no pueda utilizar ciertos "servicios de esta plataforma". Ejemplos de información que usted nos proporciona incluyen:',
        content5: "Nombre",
        content6: "DIRECCIÓN",
        content7: "País ciudadanía",
        content8: "foto identificación",
        content9: "Número teléfono móvil/número correo electrónico",
        content10: "Otra información que nos ayuda a identificarle",
        content11: "Información recopilada automáticamente: recopilamos y almacenamos automáticamente ciertos tipos de información sobre su uso de los Servicios de la Plataforma. Como muchos sitios web, utilizamos cookies y otros identificadores únicos. Obtenemos ciertos tipos de información cuando su navegador web o dispositivo accede a la Plataforma. Ejemplos de información que recopilamos y analizamos incluyen:",
        content12: "La dirección de Protocolo de Internet (IP) que conecta una computadora personal a Internet;",
        content13: "Información de inicio de sesión, dirección de correo electrónico, contraseña y ubicación de un dispositivo personal o computadora;",
        content14: "Configuración de versión y zona horaria;",
        content15: "Historial de transacciones.",
        content16: "Información de otras fuentes: podemos recopilar información sobre usted de otras fuentes, como el historial crediticio de las agencias de crédito. Utilizaremos esta información para prevenir y detectar fraudes.",
        content17: "2. ¿Puede ser utilizado por niños?",
        content18: 'Esta plataforma no permite que ninguna persona menor de 18 años utilice el "Servicio".',
        content19: "3. ¿Para qué se utilizan las cookies y otros identificadores?",
        content20: "Utilizamos cookies y herramientas similares para optimizar la experiencia del usuario, brindar nuestros servicios y comprender cómo los clientes usan nuestros servicios para que podamos realizar mejoras específicas. Utilizamos cookies para garantizar que nuestro sistema pueda reconocer su navegador o dispositivo y brindarle servicios.",
        content21: 'Utilizamos cookies operativas y herramientas similares (colectivamente, "Cookies") para proporcionar servicios, tales como:',
        content22: "Para identificarlo cuando inicia sesión y utiliza nuestros servicios.",
        content23: "Proporcionar funciones y servicios personalizados.",
        content24: "Protéjase contra actividades fraudulentas.",
        content25: "Mejorar la seguridad.",
        content26: "Realice un seguimiento de sus preferencias (como moneda e idioma).",
        content27: "También utilizamos cookies para comprender cómo los usuarios utilizan nuestros servicios para que podamos realizar mejoras específicas.",
        content28: "4. ¿Se compartirá mi información personal?",
        content29: "La información del usuario es una parte importante de nuestro negocio y no venderemos la información personal de los usuarios a otros. Esta plataforma solo compartirá la información personal de los usuarios con sus subsidiarias o afiliadas bajo las siguientes circunstancias: Estas subsidiarias o afiliadas deben cumplir con esta declaración de privacidad o al menos seguir prácticas que sean tan protectoras como las medidas de protección descritas en esta declaración de privacidad.",
        content30: "Proveedores de servicios externos: empleamos a otras empresas e individuos para realizar funciones en nuestro nombre. Ejemplos de tales funciones incluyen: analizar datos, brindar asistencia de marketing, procesar pagos, transmitir contenido y evaluar y gestionar el riesgo crediticio. Estos proveedores de servicios externos tienen acceso a la información personal necesaria para realizar sus funciones, pero no pueden utilizarla para ningún otro propósito. Además, deben procesar información personal de conformidad con esta declaración de privacidad y las leyes de protección de datos aplicables.",
        content31: "Transferencias comerciales: a medida que nuestro negocio continúa creciendo, podemos vender o comprar otros negocios o servicios. En dichas transacciones, la información del usuario generalmente se encuentra entre los activos comerciales transferidos, pero permanece sujeta a las promesas contenidas en cualquier declaración de privacidad preexistente (a menos, por supuesto, que el usuario acepte lo contrario). Además, si se adquiriera nuestra empresa o sustancialmente todos sus activos, la información del usuario también se transferiría. Protección de nuestra empresa y de otros: cuando creemos que la divulgación de la cuenta y otra información personal es apropiada para cumplir con la ley o nuestras obligaciones regulatorias, hacer cumplir o aplicar nuestros Términos de uso y otros acuerdos, o los derechos, la propiedad o la seguridad de nuestros usuarios. u otros Cuándo, divulgaremos la cuenta y otra información personal. Esto incluye el intercambio de información con otras empresas y organizaciones para protegerse contra el fraude y reducir el riesgo crediticio.",
        content32: "5. Transferencias internacionales de datos personales",
        content33: 'Podemos transferir sus datos fuera del Espacio Económico Europeo ("EEE"). Implementaremos salvaguardias adecuadas para garantizar que dichas transferencias cumplan con las normas de protección de datos aplicables, a menos que la Comisión Europea haya confirmado que el país al que se transfieren los datos proporciona un nivel adecuado de protección.',
        content34: "6. ¿Está segura mi información personal?",
        content35: "Diseñamos nuestros sistemas teniendo en cuenta su seguridad y privacidad. Utilizamos protocolos y software de cifrado para esforzarnos por proteger la seguridad de la información personal durante la transmisión.",
        content36: "Siempre adoptamos medidas de seguridad físicas, electrónicas y de procedimiento al recopilar, almacenar y divulgar información personal. Nuestros procedimientos de seguridad significan que es posible que necesitemos verificar su identidad antes de revelarle información personal.",
        content37: "Lo más importante que puede hacer es proteger las contraseñas de su cuenta personal contra accesos no autorizados. Recomendamos establecer una contraseña única para su cuenta que sea distinta de otras cuentas en línea. Y asegúrese de cerrar sesión después de usar una computadora compartida.",
        content38: "7. ¿Cómo debo proteger la información personal?",
        content39: "Si tiene alguna pregunta u objeción sobre la forma en que recopilamos y procesamos información personal, comuníquese con el personal de servicio al cliente.",
        content40: "Después de aceptar que procesemos su información personal para un propósito específico, puede retirar su intención en cualquier momento y dejaremos de procesar sus datos para ese propósito.",
        content41: "Además, tiene derecho a solicitar acceso, rectificación y eliminación de datos personales y a solicitar la portabilidad de los datos, sujeto a las leyes aplicables. También puede oponerse a nuestro procesamiento de sus datos personales o solicitar que se restrinja nuestro procesamiento de sus datos personales en determinadas circunstancias.",
        content42: "8. GDPR de la UE y Ley de Protección de Datos del Reino Unido – Base legal",
        content43: "El RGPD de la UE y la ley de protección de datos del Reino Unido nos exigen que cumplamos con la base legal al utilizar información personal. Nuestra base depende de los fines específicos para los cuales se utiliza la información personal. Estas bases incluyen:",
        content44: "Ejecución de un contrato: para proporcionarle o comunicarnos con usted sobre productos o servicios, incluido nuestro uso de su información personal para aceptar y procesar pedidos y procesar pagos.",
        content45: "Nuestros intereses comerciales legítimos y los intereses de los usuarios: detectamos y prevenimos el fraude y el abuso para proteger la seguridad de nuestros usuarios, la nuestra o la de otros.",
        content46: "Su consentimiento: requerimos su consentimiento para procesar su información personal para los fines específicos que se le comunican. Una vez que nos hayas dado tu consentimiento para procesar tu información personal para un fin específico, podrás retirar tu consentimiento en cualquier momento y dejaremos de procesar tus datos para ese fin.",
        content47: "Cumplir con las obligaciones legales: utilizamos su información personal según lo exige la ley. Por ejemplo, recopilamos información de cuentas bancarias con fines de verificación de identidad.",
        content48: "Las bases legales anteriores y otras dependen de los fines específicos para los cuales utilizamos la información personal.",
        content49: "9. Condiciones de uso, avisos y modificaciones",
        content50: "Su uso y cualquier disputa sobre la privacidad están sujetos a esta Declaración y a nuestros Términos de uso. Si tiene alguna inquietud sobre la privacidad de esta plataforma, contáctenos con una explicación detallada y estaremos encantados de resolver el problema por usted. También tiene derecho a ponerse en contacto con su autoridad local de protección de datos.",
        content51: "Nuestro negocio cambia constantemente, al igual que nuestra declaración de privacidad. Debe consultar nuestro sitio web con frecuencia para ver los últimos cambios. Si no está de acuerdo con el contenido revisado, deberá dejar de acceder inmediatamente. Después de que se publique la versión actualizada de la Política de privacidad, su acceso continuo significa que está de acuerdo con el contenido actualizado y acepta cumplir con la Política de privacidad actualizada. A menos que se indique lo contrario, la Declaración de Privacidad actual se aplica a toda la información que tenemos sobre usted y su cuenta.",
    },
    serviceAgreement: {
        title: "Acuerdo de servicio",
        content1: 'Este sitio web es una plataforma específica para que los usuarios realicen transacciones de activos digitales y proporcionen servicios relacionados (en lo sucesivo, "el servicio" o "el servicio"). Para facilitar la descripción de este acuerdo, este sitio web utiliza colectivamente "nosotros" u otros pronombres en primera persona en este acuerdo. Siempre que la persona física u otro sujeto que inicie sesión en el sitio web sea un usuario de este sitio web, para la conveniencia de expresar este acuerdo, a continuación se utilizará "usted" u otra segunda persona. Para facilitar la expresión de este Acuerdo, en este Acuerdo nos referiremos a usted y a nosotros colectivamente como las "Partes", y a usted o a nosotros individualmente como una "Parte".',
        content2: "pista importante:",
        content3: "Le recordamos especialmente:",
        content4: "1 Los activos digitales en sí no son emitidos por ninguna institución o empresa financiera ni por este sitio web.",
        content5: "2 Los mercados de activos digitales son nuevos, no están confirmados y es posible que no crezcan;",
        content6: "3 Los activos digitales son utilizados principalmente por especuladores, con relativamente poco uso en los mercados minoristas y comerciales. Las transacciones de activos digitales son extremadamente riesgosas. Se negocian continuamente durante todo el día, sin límite de subidas y bajadas, y los precios se ven fácilmente afectados por el mercado. responsables y políticas gubernamentales globales fluctuación;",
        content7: "4 La Compañía se reserva el derecho de suspender o cancelar su cuenta en cualquier momento si determina, a su entera discreción, que usted ha violado este Acuerdo, o que los servicios proporcionados por este sitio web o su uso de los servicios proporcionados por este sitio web son ilegal según las leyes de su jurisdicción, o suspender o cancelar su uso de los servicios o transacciones de activos digitales proporcionados por este sitio web. Cualquier persona de [China continental, Taiwán, Israel, Irak, Bangladesh, Bolivia, Ecuador, Kirguistán, Sebastopol y el Reino Unido (usuarios minoristas)] tiene prohibido utilizar los servicios de transacciones por contrato proporcionados por este sitio web. La lista de países o regiones antes mencionada cambiará según las políticas y los tipos de productos de los diferentes países o regiones. Es posible que no le notifiquemos específicamente en ese momento, así que preste atención a las actualizaciones de este acuerdo de manera oportuna.",
        content8: "El comercio de activos digitales conlleva riesgos extremadamente altos y no es adecuado para la mayoría de las personas. Usted comprende y comprende que esta transacción puede resultar en una pérdida parcial o total, por lo que debe determinar el monto de la transacción en función del grado de pérdida que puede afrontar. Usted sabe y comprende que los activos digitales generarán riesgos derivados, por lo que si tiene alguna pregunta, se recomienda buscar primero la ayuda de asesores profesionales. Además, además de los riesgos mencionados anteriormente, también existen riesgos que no se pueden predecir. Debe considerar cuidadosamente y utilizar un criterio claro para evaluar su situación financiera y los riesgos antes mencionados antes de tomar cualquier decisión de comprar o vender activos digitales, y asumir todas las pérdidas resultantes. No asumimos ninguna responsabilidad por esto.",
        content9: "Tenga en cuenta:",
        content10: "1 Usted comprende que este sitio web solo se utiliza como un lugar para obtener información sobre activos digitales, encontrar partes de transacciones, negociar y realizar transacciones sobre activos digitales. Este sitio web no participa en ninguna de sus transacciones, por lo que debe usar su propio criterio prudente. para determinar los activos y/o transacciones digitales relevantes o la autenticidad, legalidad y validez de la información, y asumirá por sí sola las responsabilidades y pérdidas resultantes.",
        content11: "2 Cualquier opinión, noticia, discusión, análisis, precio, recomendación y otra información contenida en este sitio web son comentarios generales del mercado y no constituyen consejos de inversión. No somos responsables de ninguna pérdida que surja directa o indirectamente de la confianza en esta información, incluida, entre otras, la pérdida de beneficios.",
        content12: "3 Este sitio web se reserva el derecho de tomar su propia decisión, modificar o cambiar el contenido en cualquier momento. Hemos tomado medidas razonables para garantizar la exactitud de la información en el sitio web, pero no podemos garantizar su exactitud y no seremos responsables de cualquier pérdida o daño causado por la información en este sitio web o la falta de información.Pérdidas directas o indirectas que surjan de retrasos o fallas en el enlace a Internet, la transmisión o la recepción de avisos e información.",
        content13: "4 También existen riesgos al utilizar sistemas comerciales basados ​​en Internet, incluidos, entre otros, fallos de software, hardware y conexión a Internet. Como no tenemos control sobre la confiabilidad y disponibilidad de Internet, no aceptamos ninguna responsabilidad por distorsiones, retrasos y fallas de conexión.",
        content14: "5 Está prohibido utilizar este sitio web para participar en todas las actividades comerciales ilegales o actividades ilegales como lavado de dinero, contrabando, soborno comercial, etc. Si se encuentra alguna transacción o actividad ilegal sospechosa, este sitio web tomará varios medios disponibles, incluidos, entre otros, no se limita a congelar cuentas y notificar a las partes relevantes, autoridades, etc., no asumimos todas las responsabilidades que surjan de ello y nos reservamos el derecho de exigir responsabilidad a las personas relevantes.",
        content15: "6 Está prohibido utilizar este sitio web para manipular maliciosamente el mercado, realizar transacciones indebidas y otras actividades comerciales poco éticas. Si se descubren tales incidentes, este sitio web advertirá, restringirá las transacciones y cerrará todos los comportamientos poco éticos, como la manipulación maliciosa de precios y la influencia maliciosa en el mercado. sistema de comercio. No asumimos toda la responsabilidad que surja de las medidas de protección preventiva, como la suspensión de cuentas, y nos reservamos el derecho de exigir responsabilidad a las personas relevantes.",
        content16: "1. Principios generales",
        content17: '1.1 El "Acuerdo de usuario" (en adelante, "este Acuerdo" o "Estos Términos y condiciones"), consta del texto principal, "Términos de privacidad", "Conozca a su cliente y Política contra el lavado de dinero" y varias otras políticas que tienen han sido publicados o podrán publicarse en el futuro en este sitio web, reglas de clase, declaraciones, descripciones, etc.',
        content18: "1.2 Debe leer este acuerdo detenidamente antes de utilizar los servicios proporcionados por este sitio web. Si no comprende algo o si es necesario, consulte a un abogado profesional. Si no está de acuerdo con este Acuerdo y/o su modificación en cualquier momento, deje de utilizar inmediatamente los servicios proporcionados por este sitio web o no inicie sesión en este sitio web. Una vez que inicie sesión en este sitio web, utilice cualquier servicio de este sitio web o cualquier otro comportamiento similar, significa que ha comprendido y aceptado plenamente el contenido de este Acuerdo, incluida cualquier modificación realizada por este sitio web a este Acuerdo en cualquier momento.",
        content19: '1.3 Puede convertirse en miembro de este sitio web (en adelante, "miembro") completando la información relevante de acuerdo con los requisitos de este sitio web y registrándose exitosamente después de otros procedimientos relevantes. Al hacer clic en el botón "Aceptar" durante el registro. proceso, usted firma electrónicamente un formulario de acuerdo con la empresa, o cuando hace clic en cualquier botón marcado "Acepto" o un significado similar durante el uso de este sitio web, o realmente utiliza los servicios proporcionados por este sitio web de otras maneras permitidas por este sitio web. , significa que usted comprende, acepta y acepta completamente todos los términos de este Acuerdo. La ausencia de su firma manuscrita no afectará la fuerza legal vinculante de este Acuerdo para usted.',
        content20: "1.4 Después de convertirse en miembro de este sitio web, recibirá una cuenta de miembro y la contraseña correspondiente. Usted es responsable de mantener la cuenta de miembro y la contraseña; será legalmente responsable de todas las actividades y eventos realizados bajo su cuenta.",
        content21: "1.5 Solo los miembros que se convierten en miembros de este sitio web pueden usar la plataforma de comercio de activos digitales proporcionada por este sitio web para transacciones y disfrutar de otros servicios estipulados por este sitio web que solo están disponibles para miembros; los no miembros solo pueden iniciar sesión en el sitio web, navegar por el sitio web y otros servicios estipulados por este sitio web.",
        content22: "1.6 Al registrarse y utilizar cualquiera de los servicios y funciones proporcionados por este sitio web, se considerará que ha leído, comprendido y:",
        content23: "1.6.1 Acepte estar sujeto a todos los términos y condiciones de este Acuerdo.",
        content24: "1.6.2 Usted confirma que es mayor de 18 años o tiene la edad legal para celebrar contratos de acuerdo con las diferentes leyes aplicables. Su registro en este sitio web, ventas o compras, publicación de información, etc. y aceptación de los servicios de este sitio web debe ser conforme con sus requisitos Tener jurisdicción sobre las leyes y regulaciones relevantes de un país o región soberano, y tener plena capacidad para aceptar estos términos, realizar transacciones y utilizar este sitio web para realizar transacciones de activos digitales.",
        content25: "1.6.3 Garantizas que todos los activos digitales que te pertenecen involucrados en la transacción se obtienen legalmente y tienen propiedad.",
        content26: "1.6.4 Usted acepta que es el único responsable de sus propias actividades comerciales o no comerciales y de cualquier ganancia o pérdida.",
        content27: "1.6.5 Usted confirma que la información que proporciona al registrarse es verdadera y precisa.",
        content28: "1.6.6 Usted acepta cumplir con los requisitos de cualquier ley pertinente, incluida la declaración de cualquier beneficio comercial a efectos fiscales.",
        content29: "1.6.7 Usted acepta no involucrarse o participar en conductas o actividades que perjudiquen los intereses de este sitio web o de la empresa en ningún momento, estén o no relacionados con los servicios proporcionados por este sitio web.",
        content30: "1.6.8 Este acuerdo solo rige los derechos y obligaciones entre usted y nosotros, y no involucra las relaciones legales ni las disputas legales que surjan de transacciones de activos digitales entre los usuarios de este sitio web y otros sitios web y usted.",
        content31: "2. Modificaciones del acuerdo",
        content32: "Nos reservamos el derecho de revisar este Acuerdo de vez en cuando y anunciarlo en el sitio web sin notificarle por separado. Después de la revisión, el tiempo de revisión se marcará en la [Última hora de actualización] que se muestra en este Acuerdo, y tomará automáticamente efectos una vez sea publicado en el sitio web. Debe navegar y prestar atención al tiempo de actualización y al contenido actualizado de este Acuerdo de vez en cuando. Si no está de acuerdo con los cambios relevantes, debe dejar de usar inmediatamente los servicios de este sitio web; su uso continuo de los servicios de este sitio web significa que usted acepta y acepta estar sujeto al acuerdo revisado.",
        content33: "3. Registro",
        content34: "3.1 Calificaciones de registro",
        content35: "Usted confirma y promete que cuando complete el proceso de registro o utilice los servicios proporcionados por este sitio web de otras formas permitidas por este sitio web, deberá tener la capacidad de firmar este acuerdo y utilizar los servicios de este sitio web según lo estipulado por las leyes aplicables. persona física, persona jurídica u otra organización. Una vez que haga clic en el botón Aceptar registrarse, significa que usted o su agente autorizado aceptaron el contenido del acuerdo y su agente se registró y utilizó los servicios de este sitio web. Si no tiene las calificaciones mencionadas anteriormente, usted y su agente autorizado asumirán todas las consecuencias que resulten de ello, y la compañía se reserva el derecho de cancelar o congelar permanentemente su cuenta, y responsabilizar a usted y a su agente autorizado.",
        content36: "3.2 Propósito del registro",
        content37: "Usted confirma y promete que su registro en este sitio web no tiene como objetivo violar las leyes y regulaciones o alterar el orden de las transacciones de activos digitales en este sitio web.",
        content38: "3.3Proceso de registro",
        content39: "3.3.1 Usted acepta proporcionar una dirección de correo electrónico válida, un número de teléfono móvil y otra información según se requiera en la página de registro de usuario de este sitio web. Puede utilizar la dirección de correo electrónico, el número de teléfono móvil que proporcionó o confirmó u otros métodos permitidos por este sitio web como inicio de sesión. método para ingresar a este sitio web. De ser necesario, de acuerdo con las leyes y reglamentos pertinentes de las diferentes jurisdicciones, deberá proporcionar su nombre real, documento de identidad y otra información relevante estipulada en las leyes y reglamentos, cláusulas de privacidad y cláusulas contra el lavado de dinero, y actualizar constantemente la información de registro en de manera oportuna, detallada y precisa. Toda la información escrita originalmente será referenciada como información de registro. Usted es responsable de la autenticidad, integridad y exactitud de dicha información, y asumirá cualquier pérdida directa o indirecta y consecuencias adversas que surjan de la misma.",
        content40: "3.3.2 Si las leyes, regulaciones, reglas, órdenes y otras regulaciones del país soberano o región donde se encuentra tienen requisitos de nombre real para los números de teléfonos móviles, usted acepta que el número de teléfono móvil registrado proporcionado se ha registrado con el nombre real. Si no lo proporciona de acuerdo con las regulaciones, cualquier consecuencia que se le cause será una pérdida directa o indirecta y las consecuencias adversas correrán por su cuenta.",
        content41: "3.3.3 Si usted proporciona la información requerida para el registro de forma legal, completa y efectiva y la ha verificado, tiene derecho a obtener el número de cuenta y la contraseña de este sitio web. Cuando obtiene el número de cuenta y la contraseña de este sitio web, se considera que su registro es válido. exitoso y puede iniciar sesión como miembro en este sitio web.",
        content42: "3.3.4 Usted acepta recibir correos electrónicos y/o mensajes cortos enviados por este sitio web relacionados con la gestión y operación de este sitio web.",
        content43: "4. Servicio",
        content44: "Este sitio web solo proporciona servicios de plataforma de comercio en línea para sus actividades de comercio de activos digitales (incluidos, entre otros, el comercio de activos digitales y otros servicios) a través de este sitio web.",
        content45: "4.1 Contenido del servicio",
        content46: "4.1.1 Tiene derecho a explorar las condiciones del mercado en tiempo real y la información de transacciones de varios productos de activos digitales en este sitio web, y tiene derecho a enviar instrucciones de transacciones de activos digitales y completar transacciones de activos digitales a través de este sitio web.",
        content47: "4.1.2 Tiene derecho a ver la información de su cuenta de membresía en este sitio web y a aplicar las funciones proporcionadas por este sitio web para operar.",
        content48: "4.1.3 Tiene derecho a participar en las actividades del sitio web organizadas por este sitio web de acuerdo con las reglas de actividad publicadas en este sitio web.",
        content49: "4.1.4 Este sitio web promete brindarle otros servicios.",
        content50: "4.2 Reglas de servicio Usted promete cumplir con las siguientes reglas de servicio de este sitio web:",
        content51: "4.2.1 Deberá cumplir con las leyes, regulaciones y requisitos de políticas, garantizar la legalidad de las fuentes de todos los activos digitales en su cuenta y no participará en actividades ilegales o de otro tipo que dañen los derechos e intereses de este sitio web o de terceros en este sitio web. o mediante el uso de sus servicios, incluidos, entre otros: No está limitado a enviar o recibir información ilegal, ilegal o que infrinja los derechos de otros, enviar o recibir esquemas piramidales u otra información o comentarios dañinos, usar o falsificar correo electrónico información del encabezado de este sitio web sin la autorización de este sitio web, etc.",
        content52: "4.2.2 Debe cumplir con las leyes y regulaciones y usar y conservar adecuadamente su número de cuenta, contraseña de inicio de sesión, contraseña de fondos, el número de teléfono móvil vinculado a usted al registrarse y el código de verificación de teléfono móvil recibido en su teléfono móvil. Usted es totalmente responsable de cualquier operación y consecuencias del uso de su cuenta y contraseña de inicio de sesión, contraseña de fondos y código de verificación de teléfono móvil. Cuando descubra que un tercero utiliza el número de cuenta, la contraseña de inicio de sesión, la contraseña de fondos o el código de verificación de este sitio web sin su autorización, o que existen otros problemas de seguridad de la cuenta, debe notificar de inmediato y de manera efectiva a este sitio web y solicitar que este sitio web suspenda el servicio de la cuenta de este sitio web. Este sitio web tiene derecho a tomar medidas sobre su solicitud dentro de un tiempo razonable, pero este sitio web no asume ninguna responsabilidad por las consecuencias (incluidas, entre otras, las pérdidas que sufra) que hayan ocurrido antes de tomar medidas. No puede donar, prestar, arrendar, transferir ni disponer de otra manera de su cuenta en este sitio web a otros sin el consentimiento de este sitio web.",
        content53: "4.2.3 Usted acepta ser responsable de todas las actividades que ocurran con su cuenta y contraseña en este sitio web (incluidas, entre otras, la divulgación de información, la publicación de información, los clics en línea para aceptar o presentar diversas reglas y acuerdos, la renovación en línea de acuerdos o la compra de servicios, etcétera).",
        content54: "4.2.4 Al realizar transacciones de activos digitales en este sitio web, no debe interferir maliciosamente con el progreso normal de las transacciones de activos digitales ni alterar el orden de las transacciones; no debe interferir con el funcionamiento normal de este sitio web ni interferir con el uso que otros usuarios hacen de este sitio web. servicios por cualquier medio técnico u otros medios; no debe utilizar datos ficticios para difamar maliciosamente la buena voluntad de este sitio web a través de hechos y otros medios.",
        content55: "4.2.5 Si tiene una disputa con otros usuarios debido a transacciones en línea, no podrá solicitar a este sitio web que proporcione información relevante a través de canales no judiciales o administrativos.",
        content56: "4.2.6 Cualquier impuesto a pagar, así como todo hardware, software, servicio y otros gastos incurridos durante el uso de los servicios proporcionados por este sitio web serán juzgados y asumidos únicamente por usted.",
        content57: "4.2.7 Debe cumplir con este acuerdo y otros términos de servicio y reglas operativas publicadas y actualizadas por este sitio web de vez en cuando, y tiene derecho a cancelar el uso de los servicios proporcionados por este sitio web en cualquier momento.",
        content58: "4.3 reglas del producto",
        content59: "4.3.1 Explorar información de transacciones",
        content60: "Cuando navega por la información de transacciones en este sitio web, debe leer atentamente todo el contenido de la información de la transacción, incluidos, entre otros, el precio, el volumen de comisiones, la tarifa de manejo, la dirección de compra o venta, y solo después de aceptar completamente todos los contenidos contenidos. en la información de la transacción Las transacciones se pueden realizar con solo hacer clic en un botón.",
        content61: "4.3.2 Ver detalles de la transacción",
        content62: "Puede ver los registros de transacciones correspondientes a través de su cuenta.",
        content63: "5. Derechos y obligaciones de este sitio web",
        content64: "5.1 Si no tiene las calificaciones de registro estipuladas en este acuerdo, este sitio web tiene el derecho de rechazar su registro. Para aquellos que ya se han registrado, este sitio web tiene el derecho de cancelar su cuenta de membresía. Este sitio web se reserva el derecho de retenerlo o su agente autorizado responsable tiene derecho. Al mismo tiempo, este sitio web se reserva el derecho de decidir si acepta su registro en cualquier otra circunstancia.",
        content65: "5.2 Según el propio criterio de este sitio web, si este sitio web determina que usted o los usuarios de su cuenta asociada no son adecuados para inversiones de alto riesgo, tenemos derecho a suspender o cancelar su cuenta y el uso de todas las cuentas asociadas.",
        content66: "5.3 Cuando este sitio web descubre que el usuario de la cuenta no es el registrante inicial de la cuenta, tiene derecho a suspender o cancelar el uso de la cuenta.",
        content67: "5.4 Cuando este sitio web sospecha razonablemente que la información que usted proporciona es incorrecta, falsa, inválida o incompleta a través de pruebas técnicas, muestreo manual y otros métodos de prueba, tiene el derecho de notificarle para corregir o actualizar la información o suspender o cancelar el suministro de este sitio web le brinda servicios.",
        content68: "5.5 Este sitio web se reserva el derecho de corregir cualquier información mostrada en este sitio web si se descubre que hay un error obvio.",
        content69: "5.6 Este sitio web se reserva el derecho de modificar, suspender o terminar los servicios de este sitio web en cualquier momento. Este sitio web no necesita notificarle con anticipación para ejercer el derecho de modificar o terminar los servicios. Si este sitio web termina uno o más servicios de este sitio web, la terminación será desde este sitio web en el sitio web, a partir de la fecha de publicación del anuncio de terminación.",
        content70: "5.7 Este sitio web tomará los medios técnicos y las medidas de gestión necesarios para garantizar el funcionamiento normal de este sitio web, proporcionar un entorno comercial y servicios comerciales necesarios y confiables, y mantener el orden de las transacciones de activos digitales.",
        content71: "5.8 Si no inicia sesión en este sitio web con su cuenta de membresía y contraseña durante un año consecutivo, este sitio web tiene derecho a cancelar su cuenta. Una vez cancelada la cuenta, este sitio web tiene derecho a abrir el nombre de miembro correspondiente a otros usuarios para su registro y uso.",
        content72: "5.9 Este sitio web garantiza la seguridad de sus activos digitales fortaleciendo la inversión en tecnología, mejorando las precauciones de seguridad y otras medidas, y le notificará con anticipación cuando ocurran riesgos de seguridad previsibles en su cuenta.",
        content73: "5.10 Este sitio web tiene derecho a eliminar todo tipo de información de contenido en este sitio web que no cumpla con las leyes y regulaciones o las regulaciones de este sitio web en cualquier momento. Este sitio web no necesita notificarle con anticipación para ejercer dichos derechos.",
        content74: "5.11 Este sitio web tiene el derecho de solicitarle que proporcione más información o materiales de acuerdo con las leyes, regulaciones, reglas, órdenes y otros requisitos regulatorios de su país o región soberana, y a tomar medidas razonables para cumplir con los requisitos regulatorios locales. están obligados a cooperar; este sitio web tiene el derecho de suspender o dejar de abrir permanentemente algunos o todos los servicios de este sitio web de acuerdo con los requisitos de las leyes, regulaciones, reglas, órdenes y otras especificaciones de su país o región soberana.",
        content75: "5.12 Este sitio web se reserva el derecho de cerrar su cuenta con un aviso de siete días hábiles a su exclusivo criterio. Por lo tanto, dispone de siete días hábiles para cancelar órdenes y cerrar posiciones. Si no canceló la orden y cerró la posición cuando expire el período, cancelaremos la orden y cerraremos la posición a la fuerza, y le devolveremos los activos digitales restantes en su cuenta.",
        content76: "5.13 Para proteger los derechos e intereses de los comerciantes, este sitio web tiene el derecho de realizar ajustes a los productos en línea bajo circunstancias especiales (como fallas del sistema, fallas de la red, condiciones extremas del mercado, etc.), tales como: contratos de entrega anticipada y liquidación, tipos de contrato y contratos de entrega anticipada y liquidación.El plazo y el precio de liquidación y entrega están sujetos al anuncio en este sitio web.",
        content77: "6. Compensación",
        content78: "6.1 En ningún caso nuestra responsabilidad hacia usted por daños directos excederá las tarifas totales de servicio que le cobramos por el uso de este sitio web durante un período de tres (3) meses.",
        content79: "6.2 Si viola este Acuerdo u otras leyes y regulaciones, debe compensarnos con al menos 2 millones de dólares estadounidenses y asumir todos los costos incurridos (incluidos los honorarios de abogados, etc.). Si no es suficiente para compensar las pérdidas reales, debe hacer preparado para ello.",
        content80: "7. Derecho a solicitar medidas cautelares",
        content81: "Tanto nosotros como usted reconocemos que los recursos de derecho consuetudinario por su incumplimiento de contrato o posible incumplimiento de contrato pueden no ser suficientes para compensar todas las pérdidas que sufrimos, por lo que tenemos derecho a solicitar medidas cautelares según lo permita el derecho consuetudinario o la equidad en el caso. en caso de incumplimiento de contrato o posible incumplimiento de contrato, todos los demás recursos.",
        content82: "8. Limitación de responsabilidad y exención de responsabilidad",
        content83: "8.1 Usted comprende y acepta que, bajo ninguna circunstancia, no seremos responsables de los siguientes asuntos:",
        content84: "8.1.1 Pérdida de ingreso;",
        content85: "8.1.2 ganancias comerciales o pérdidas contractuales;",
        content86: "8.1.3 Pérdidas causadas por interrupción del negocio;",
        content87: "8.1.4 pérdida de ahorros monetarios previstos;",
        content88: "8.1.5 Pérdidas causadas por problemas de información;",
        content89: "8.1.6 pérdida de oportunidades, buena voluntad o reputación;",
        content90: "8.1.7 corrupción o pérdida de datos;",
        content91: "8.1.8 el costo de comprar productos o servicios sustitutos;",
        content92: "8.1.9 Cualquier pérdida o daño indirecto, especial o incidental que surja de agravio (incluida negligencia), incumplimiento de contrato o cualquier otro motivo, ya sea que dicha pérdida o daño sea razonablemente previsible o no por nuestra parte; ya sea que hayamos sido informados o no de la existencia de dicho daño. por adelantado Posibilidad de Pérdida o Daño.",
        content93: "Los artículos 8.1.1 a 8.1.9 son independientes entre sí.",
        content94: "8.2 Usted comprende y acepta que no somos responsables de ningún daño causado a usted debido a cualquiera de las siguientes circunstancias:",
        content95: "8.2.1 Sus transacciones específicas pueden implicar violaciones importantes de las leyes o incumplimientos de contrato.",
        content96: "8.2.2 Su comportamiento en este sitio web puede ser ilegal o poco ético.",
        content97: "8.2.3 Costos y pérdidas incurridos por la compra u obtención de cualquier dato, información o realización de transacciones a través de los servicios de este sitio web o por acciones sustitutivas.",
        content98: "8.2.4 Su malentendido sobre los servicios de este sitio web.",
        content99: "8.2.5 Cualquier otra pérdida relacionada con los servicios prestados por este sitio web que no sea causada por nosotros.",
        content100: "8.3 Somos responsables del mantenimiento de equipos de redes de información, fallas de conexión de redes de información, fallas de computadoras, comunicaciones u otros sistemas, fallas de energía, condiciones climáticas, accidentes, huelgas, disputas laborales, disturbios, levantamientos, disturbios, productividad o materiales de producción insuficientes, incendios, inundaciones. , tormentas, explosiones, guerras, motivos bancarios u otros socios, colapso del mercado de activos digitales, acciones gubernamentales, órdenes de agencias judiciales o administrativas, otras acciones que están fuera de nuestro control o que no tenemos capacidad de controlar, o motivos de terceros. no asume ninguna responsabilidad por la imposibilidad o retraso en el servicio, o sus pérdidas.",
        content101: "8.4 No podemos garantizar que toda la información, programas, textos, etc. contenidos en este sitio web sean completamente seguros y estén libres de interferencias y daños por virus, troyanos y otros programas maliciosos. Por lo tanto, si inicia sesión y utiliza cualquier servicio de este sitio web o descargar y utilizar cualquier programa, información, datos, etc. descargados son decisiones suyas y usted asume los riesgos y posibles pérdidas bajo su propio riesgo.",
        content102: "8.5 No ofrecemos ninguna garantía ni compromiso sobre ninguna información, productos y servicios de sitios web de terceros vinculados a este sitio web o cualquier otra forma de contenido que no nos pertenezca. Si utiliza algún servicio, información y servicios proporcionados por terceros Los sitios web de terceros, Productos, etc. son decisiones suyas y usted asume todas las responsabilidades que se deriven de ellas.",
        content103: "8.6 No ofrecemos ninguna garantía expresa o implícita sobre el uso de los servicios de este sitio web, incluidos, entre otros, la idoneidad de los servicios proporcionados por este sitio web, la ausencia de errores u omisiones, la continuidad, la precisión, la confiabilidad y la idoneidad para un particular. objetivo. Al mismo tiempo, no asumimos ningún compromiso ni garantía en cuanto a la validez, exactitud, corrección, confiabilidad, calidad, estabilidad, integridad y actualidad de la tecnología y la información involucradas en los servicios proporcionados por este sitio web. Iniciar sesión o utilizar los servicios proporcionados por este sitio web es su decisión personal y usted asume sus propios riesgos y posibles pérdidas. No ofrecemos ninguna garantía expresa o implícita con respecto al mercado, el valor y el precio de los activos digitales. Usted comprende y comprende que el mercado de activos digitales es inestable. Los precios y los valores pueden fluctuar o colapsar significativamente en cualquier momento. El comercio de activos digitales es tu libertad personal, elige y decide bajo tu propio riesgo y posibles pérdidas.",
        content104: "8.7 Nuestras garantías y compromisos establecidos en este Acuerdo son las únicas garantías y representaciones hechas por nosotros en relación con este Acuerdo y los servicios proporcionados en este sitio web y reemplazan cualquier garantía y compromiso que surja de cualquier otra manera, ya sea escrita u oral, expresa. de o implícito. Todas estas garantías y representaciones solo representan nuestros propios compromisos y garantías y no garantizan que ningún tercero cumplirá con las garantías y compromisos de este Acuerdo.",
        content105: "8.8 No renunciamos a ningún derecho no mencionado en este Acuerdo para limitar, excluir o compensar nuestra responsabilidad por daños en la máxima medida aplicable a la ley.",
        content106: "8.9 Después de registrarse, acepta cualquier operación que realicemos de acuerdo con las reglas estipuladas en este acuerdo, y cualquier riesgo incurrido corre por su cuenta.",
        content107: "9. Terminación del Acuerdo",
        content108: "9.1 Este sitio web tiene derecho a cancelar todos los servicios de este sitio web de acuerdo con este acuerdo. Este acuerdo finalizará en la fecha en que finalicen todos los servicios de este sitio web.",
        content109: "9.2 Después de la terminación de este acuerdo, usted no tiene derecho a exigir que este sitio web continúe brindándole servicios o cumpliendo otras obligaciones, incluido, entre otros, exigir que este sitio web conserve o le revele cualquier información en su cuenta de sitio web original. a usted o a un tercero, que reenvía cualquier información que no haya leído o enviado.",
        content110: "9.3 La terminación de este acuerdo no afectará las demás responsabilidades de la parte que no incumple frente a la parte que incumple.",
        content111: "10. Derechos de propiedad intelectual",
        content112: "10.1 Todos los logros intelectuales contenidos en este sitio web incluyen, entre otros, logotipos de sitios web, bases de datos, diseños de sitios web, textos y gráficos, software, fotografías, vídeos, música, sonidos y combinaciones de los mismos, compilaciones de software, códigos fuente relacionados y software (incluidas pequeñas aplicaciones). ) Los derechos de propiedad intelectual de programas y scripts) pertenecen a este sitio web. No puede reproducir, alterar, copiar, enviar ni utilizar ninguno de los materiales o contenidos anteriores con fines comerciales.",
        content113: "10.2 Todos los derechos contenidos en el nombre de este sitio web (incluidos, entre otros, el fondo de comercio, las marcas comerciales y los logotipos) pertenecen a la empresa.",
        content114: "10.3 Su aceptación de este acuerdo se considerará como su iniciativa de transferir los derechos de autor de cualquier forma de información que publique en este sitio web, incluidos, entre otros: derechos de reproducción, derechos de distribución, derechos de alquiler, derechos de exhibición, derechos de interpretación, derechos de proyección, Los derechos de transmisión, los derechos de transmisión de información por Internet, los derechos de filmación, los derechos de adaptación, los derechos de traducción, los derechos de compilación y otros derechos transferibles que deben ser disfrutados por el titular de los derechos de autor se transfieren exclusivamente a este sitio web de forma gratuita. Este sitio web tiene el derecho de presentar una solicitud independiente. demandar a cualquier sujeto por infracción y obtener una indemnización completa. Este acuerdo es válido para cualquier contenido de trabajo protegido por la ley de derechos de autor que usted publique en este sitio web, ya sea que el contenido se forme antes de la firma de este acuerdo o después de la firma de este acuerdo.",
        content115: "10.4 No puede utilizar ni disponer ilegalmente de los derechos de propiedad intelectual de este sitio web u otros al utilizar los servicios de este sitio web. No puede publicar ni autorizar a otros sitios web (y medios) a utilizar la información publicada en este sitio web de ninguna forma.",
        content116: "11. Protección de la información",
        content117: "Prevalecerán las disposiciones de los “Términos de Privacidad” publicados por separado en este sitio web.",
        content118: "12. Cálculo",
        content119: "Hemos verificado todos los resultados del cálculo de las transacciones y todos los métodos de cálculo se han publicado en el sitio web, pero no podemos garantizar que el uso del sitio web no se interrumpa o esté libre de errores.",
        content120: "13. Control de exportaciones",
        content121: "Usted comprende y reconoce que, de acuerdo con las leyes pertinentes, no está permitido exportar, reexportar, importar o transferir ningún material (incluido el software) en este sitio web, por lo que garantiza que no implementará ni ayudará ni participará activamente en ninguno de las exportaciones mencionadas anteriormente o actividades relacionadas que violen las leyes y regulaciones, transferencias u otras violaciones de las leyes y regulaciones aplicables; si se descubre tal situación, debe informarnos activamente de manera oportuna y ayudarnos a manejarla.",
        content122: "14. Transferencia",
        content123: "Los derechos y obligaciones estipulados en este Acuerdo vincularán también a los cesionarios, herederos, albaceas y administradores de las partes que se beneficien de dichos derechos y obligaciones. No puede transferirlo a ningún tercero sin nuestro consentimiento, pero podemos transferir nuestros derechos y obligaciones en este Acuerdo a cualquier tercero en cualquier momento y avisarle.",
        content124: "15. Divisibilidad",
        content125: "Si cualquier tribunal de jurisdicción competente considera que alguna disposición de este Acuerdo es inaplicable, inválida o ilegal, no afectará la validez de las disposiciones restantes de este Acuerdo.",
        content126: "16. Relación sin agencia",
        content127: "Nada en este Acuerdo se considerará que nos crea, implica o de otro modo nos constituye como su agente, fideicomisario u otro representante, excepto que se disponga lo contrario en este Acuerdo.",
        content128: "17. Abstención",
        content129: "La renuncia por nuestra parte o por parte de usted a la responsabilidad de cualquiera de las partes por incumplimiento de contrato u otra responsabilidad estipulada en este Acuerdo no se considerará ni interpretará como una renuncia a otra responsabilidad por incumplimiento de contrato; la falta de ejercicio de cualquier derecho o recurso no se considerará en ningún caso de ninguna manera se interpretará como una renuncia a dichos derechos o recursos.",
        content130: "18. Título",
        content131: "Todos los encabezados se utilizan únicamente para facilitar la expresión del acuerdo y no se utilizan para ampliar o limitar el contenido o alcance de los términos del acuerdo.",
        content132: "19. Ley Aplicable",
        content133: "19.1 Antes de plantear una disputa o reclamo, usted reconoce y acepta comunicarse primero con este sitio web por correo electrónico para resolver la disputa de manera informal. Intentaremos resolver su disputa internamente lo antes posible; ambas partes acuerdan resolver la disputa a través de negociaciones de buena fe (discusiones será confidencial y estará protegido por las normas aplicables y no se utilizará como prueba en ningún procedimiento legal).",
        content134: '19.2Usted reconoce y acepta que en caso de cualquier disputa, controversia, desacuerdo o reclamo, incluida la existencia, validez, interpretación, desempeño, incumplimiento o terminación de estos Términos o cualquier disputa que surja de o en conexión con estos Términos ("Disputa" ), las partes antes mencionadas presentarán la disputa a la Corte Internacional de Arbitraje de la Cámara de Comercio Internacional ("Corte Internacional de Arbitraje de la CCI") de conformidad con las "Reglas de Arbitraje de la CCI" vigentes en ese momento. El lugar del arbitraje será Belice y la ley aplicable del arbitraje se determinará de conformidad con las Reglas de Arbitraje de la Cámara de Comercio Internacional. A menos que las partes acuerden lo contrario, solo se nombrará un árbitro según las Reglas de Arbitraje de la CCI. El árbitro tendrá autoridad exclusiva para decidir sobre su propia jurisdicción, incluyendo, entre otras, cualquier objeción a la existencia, alcance o validez del acuerdo de arbitraje o la arbitrabilidad de cualquier reclamo o reconvención. Cualquier arbitraje se llevará a cabo en inglés. Independientemente de cómo se lleve a cabo el arbitraje, el árbitro emitirá un laudo escrito que sea razonable y suficiente para explicar el laudo y las conclusiones esenciales, si las hubiere, en las que se basa el laudo. El laudo arbitral será definitivo y vinculante para las partes y podrá hacerse cumplir en cualquier tribunal de jurisdicción competente.',
        content135: "20. Eficacia e interpretación del acuerdo",
        content136: "20.1 Este acuerdo entra en vigor cuando hace clic en la página de registro de este sitio web para aceptar registrarse, completar el proceso de registro y obtener una cuenta y contraseña para este sitio web. Es vinculante tanto para este sitio web como para usted.",
        content137: "20.2 El derecho de interpretación final de este acuerdo pertenece a este sitio web.",
        content138: "Conozca su cliente y su política contra el blanqueo de capitales",
        content139: "1. Introducción",
        content140: '1.1 Nos comprometemos a cumplir cuidadosamente con las leyes y regulaciones relacionadas con "Conozca a su cliente" y contra el lavado de dinero y no violaremos intencionalmente la "Política contra el lavado de dinero y Conozca a su cliente". Dentro del alcance de nuestro control razonable, tomaremos las medidas y tecnologías necesarias para brindarle servicios seguros y protegerlo, en la medida de lo posible, de las pérdidas causadas por el comportamiento de lavado de dinero de sospechosos de delitos.',
        content141: "1.2 Nuestra Política Conozca a su Cliente y Antilavado de Dinero es un sistema integral de políticas internacionales que incluye políticas Conozca a su Cliente y Antilavado de Dinero para las diferentes jurisdicciones legales a las que está afiliado.",
        content142: "2. Conozca a su cliente y las políticas contra el lavado de dinero son las siguientes:",
        content143: "2.1 Promulgar políticas de conocimiento del cliente y contra el lavado de dinero y actualizarlas periódicamente para cumplir con los estándares establecidos por las leyes y reglamentos correspondientes;",
        content144: "2.2 Promulgar y actualizar algunos principios rectores y reglas para operar este sitio web, y nuestros empleados brindarán servicios de acuerdo con la guía de estos principios y reglas;",
        content145: "2.3 Diseñar y completar procedimientos para el seguimiento y control interno de las transacciones, tales como verificar identidades a través de medios estrictos y organizar la formación de un equipo profesional que sea responsable del trabajo contra el lavado de dinero;",
        content146: "2.4 Utilizar métodos de prevención de riesgos para realizar la debida diligencia y supervisión continua de los clientes;",
        content147: "2.5 Revisar y verificar periódicamente las transacciones que hayan ocurrido;",
        content148: "2.6 Reportar transacciones sospechosas a las autoridades competentes;",
        content149: "2.7 La prueba de identidad, la dirección y los registros de transacciones se conservarán durante al menos seis años y podrán presentarse a las autoridades reguladoras sin previo aviso.",
        content150: "3. Información y verificación de identidad",
        content151: "3.1 Información de identidad",
        content152: "3.1.1 De acuerdo con diferentes regulaciones en diferentes jurisdicciones y diferentes tipos de entidades, el contenido de su información que recopilamos puede ser inconsistente. En principio, la siguiente información se recopilará de personas registradas:",
        content153: "Información personal básica: su nombre, dirección (y dirección permanente, si es diferente), fecha de nacimiento y nacionalidad y otra información disponible. La verificación de la identidad debe basarse en documentos emitidos por autoridades oficiales u otras autoridades similares, como pasaportes, documentos de identidad u otros documentos de identificación requeridos y activados por diferentes jurisdicciones. La dirección que usted proporcione será verificada utilizando métodos apropiados, como verificar el billete de transporte de pasajeros o la nota de tasa de interés o consultar el registro electoral.",
        content154: "Foto válida: antes de registrarse, debe proporcionar una fotografía suya sosteniendo su identificación contra su pecho;",
        content155: "Datos de contacto: Número de teléfono/móvil y/o dirección de correo electrónico válida.",
        content156: "3.1.2 Si es una corporación u otra entidad legal, recopilaremos la siguiente información para identificarlo a usted o al beneficiario final de su cuenta fiduciaria.",
        content157: "Registro de la empresa y certificado de registro; copias de los estatutos y memorandos de la empresa; materiales de certificación detallados de la estructura de capital de la empresa y la descripción de propiedad, que demuestren la resolución de la junta directiva del director autorizado que determina la apertura y ejecución de la cuenta en este sitio web; empresa directores que deben proporcionarse según sea necesario, documentos de identidad del accionista principal y de la persona autorizada para firmar la cuenta en este sitio web; la dirección comercial principal de la empresa, si es diferente de la dirección postal de la empresa, proporcione la dirección postal. Si la dirección local de una empresa no coincide con su dirección comercial principal, se la considera un cliente de mayor riesgo y deberá presentar documentación adicional.",
        content158: "Dependiendo de las diferentes regulaciones en diferentes jurisdicciones y diferentes tipos de entidades, requerimos otras certificaciones y documentos emitidos por las autoridades, así como los documentos que consideremos necesarios.",
        content159: "3.1.3 Solo aceptamos versiones en inglés o chino de la información de identidad. De lo contrario, traduzca su información de identidad al inglés y hágala certificar ante notario.",
        content160: "3.2 Confirmar verificación",
        content161: "3.2.1 Le solicitamos que proporcione el contenido de la página completa de su documento de identificación.",
        content162: "3.2.2 Le solicitamos una fotografía sosteniendo su documento de identificación contra su pecho.",
        content163: "3.2.3 Por lo general, las copias de los documentos justificativos deben cotejarse con los comprobantes originales. Sin embargo, una copia es aceptable si un certificador adecuado y confiable puede certificar que la copia es una reproducción exacta y completa del documento original. Entre esos certificadores se incluyen embajadores, comisionados judiciales, magistrados locales, etc.",
        content164: "3.2.4El requisito de identificar al beneficiario final y el control de la cuenta es determinar qué individuos poseen o controlan en última instancia al cliente directo, y/o establecer que las transacciones en curso se ejecutan en nombre de otros. Si se trata de una empresa, se debe verificar la identidad de los accionistas principales (como aquellos que poseen el 10% o más del interés con derecho a voto). Generalmente, se considerará que la posesión del 25% de las acciones está dentro del riesgo normal, debiendo verificarse la identidad del accionista; la posesión del 10% de las acciones o la posesión de más derechos de voto o acciones se considerará una situación de alto riesgo, y la identidad del accionista debe ser verificada.",
        content165: "4. Monitorear las transacciones",
        content166: "4.1 Establecemos y ajustamos los límites máximos diarios de transacciones y retiros de moneda de vez en cuando según la seguridad y las condiciones reales de las transacciones.;",
        content167: "4.2 Si las transacciones ocurren con frecuencia en un usuario registrado o existen circunstancias no razonables, nuestro equipo profesional evaluará y decidirá si son sospechosas.;",
        content168: "4.3 Si determinamos que una transacción es sospechosa según nuestro propio juicio, podemos tomar medidas restrictivas como suspender la transacción, rechazarla o incluso revertirla lo antes posible si es posible, e informarlo a la autoridad competente, pero no te avisará.;",
        content169: "4.4 Nos reservamos el derecho de rechazar solicitudes de registro de personas de jurisdicciones que no cumplan con los estándares internacionales contra el lavado de dinero o personas que puedan ser consideradas personas políticamente expuestas. Nos reservamos el derecho de suspender o cancelar transacciones que sean sospechosas a nuestro exclusivo criterio en en cualquier momento, pero no lo hacemos. Incumplir cualquier deber o responsabilidad que se le deba.",
    },
    register: {
        formTitle1: "crear",
        formTitle2: "Regístrese utilizando su correo electrónico o número de teléfono móvil",
        formTitle3: "Cuenta",
        field1: "Código de verificación",
        field2: "Obtener",
        field3: "confirmar Contraseña",
        field4: "Código invitación",
        field41: "(opcional)",
        field5: "He leído y estoy de acuerdo",
        field6: "Términos servicio",
        placeholder1: "por favor ingrese el código de verificación",
        content7: "por favor ingrese el código de verificación",
        content12: "Por favor ingrese el número de teléfono",
        content13: "Por favor, ingrese contraseña",
        content14: "por favor ingrese su correo electrónico",
        content18: "Por favor ingrese la contraseña nuevamente",
        content19: "Por favor, acepte los términos del servicio",
        content20: "Por favor ingresa el código de verificación correcto",
        content23: "¿Aún no has recibido tu código de verificación?",
        content24: "Por favor ingrese el código de verificación a continuación",
        registerSuccess: "registración exitosa",
        content25: "Las contraseñas son inconsistentes",
        content26: "Por favor ingrese la dirección de correo electrónico correcta",
        content27: "Restablecimiento de contraseña exitoso"
    },
    download: {
        content1: "¡Opere en cualquier momento y lugar!",
        content2: "Perfectamente compatible con múltiples terminales, satisfaciendo las necesidades de transacciones de varios escenarios en cualquier momento",
        content3: "Consulta las condiciones del mercado en tiempo real en cualquier momento.",
        content4: "Compre y venda fácilmente BTC, ETH, XRP y otros activos digitales",
        content5: "Reciba alertas tempranas sobre los cambios de precios de las criptomonedas que le interesan",
        content6: "Ver préstamos al contado de Bitcoin, préstamos de futuros y precios de opciones",
        content7: "Compare los precios de las criptomonedas en varios intercambios",
        title: "descargar",
        install: "Notas de instalación",
        one: "1. Permitir la descarga de archivos de descripción.",
        two: "2. El archivo de descripción se ha descargado.",
        three: "3. Ingrese a [Configuración] en su teléfono y busque [General]",
        four: "4. Archivos de descripción y administrador de dispositivos.",
        five: "5. Haga clic en el archivo de descripción.",
    },
    identity: {
        noAuthentication: {
            title: "Certificación Junior",
            pleaseSelectYourCountry: "por favor seleccione su país",
            name: "Nombre",
            enterName: "por favor, escriba su nombre",
            city: "ciudad",
            enterCity: "Por favor ingresa tu ciudad",
            IDNumber: "número de identificación",
            enterIDNumber: "Por favor ingrese su número de identificación",
        },
        noAdvancedAuthentication: {
            title: "Certificación avanzada",
            infoCountry: "país/región",
            infoName: "Nombre",
            infoNumber: "número de identificación",
            infoAddress: "DIRECCIÓN",
        },
        text: "no certificado",
        recertification: "recertificación",
        certification: "Certificación",
        authenticationInProgress: "verificado",
        inAuthentication: "Certificación en proceso",
        uploadTheFrontOfTheDocument: "Sube el frente del DNI",
        uploadTheReverseSideOfTheDocument: "Cargue el reverso del certificado",
        uploadAPhotoOfAHandHeldID: "Sube una foto de tu DNI en mano",
        pleaseUpload: "por favor ",
        pleaseCompleteOrdinaryCertificationFirst: "Es necesario esperar el éxito de la revisión de la certificación primaria.",
        refused: "rechazado",
        reason: "razón",
        succeeded: "tuvo éxito",
        uploading: "subiendo",
        newTitle: "Seleccione el país donde se emitió su identificación",
        pleaseOrdinaryCertificationFirst: "Realice primero la certificación primaria",
        text1: "Modificar avatar",
        text2: "Apodo",
        text3: "cuenta",
        text4: "Modificación de apodo",
        text5: "nuevo apodo",
        text6: "Introduce tu nuevo apodo",
        text7: "Contraseña anterior",
        text8: "Ingrese su antigua contraseña",
        text9: "Nueva contraseña",
        text10: "Introduzca su nueva contraseña",
        text11: "confirmar Contraseña",

        text12: "Ingrese su nueva contraseña nuevamente",
        text13: "nuevo buzón de correo",
        text14: "Código de verificación",
        text15: "por favor ingrese el código de verificación",
        text16: "tu número de teléfono",
        text17: "Nuevo número de teléfono móvil",
        text18: "por favor, introduzca su número de teléfono",
        text19: "Código de verificación del teléfono móvil",
        text20: "Certificación Junior",
        text21: "Por favor verifique su información de identidad",
        text22: "País",
        text23: "Ciudad",
        text24: "Introduce tu ciudad",
        text25: "Introduzca su nombre",
        text26: "Tu número de identificación",
        text27: "Introduce tu número de DNI",
        text28: "Foto frontal del documento de identidad",
        text29: "Haga clic para cargar sus documentos",
        text30: "volver a subir",
        text31: "subir",
        text32: "Con fotografía de identificación",
        text33: "Haga clic para cargar su foto de identificación de explotación",
        text34: "Has vinculado Google Authenticator",
        text35: "llave",
        text36: "Por favor ingresa el código de verificación de Google",
        text37: "enviar",
        text38: "No puede estar vacío",
        text39: "El formato del correo electrónico es incorrecto.",
        text40: "Dos contraseñas son inconsistentes",
        text41: "Copiado exitosamente",
        text42: "Copia fallida",
        text43: "Modificación avatar",
        text44: "cambiar la contraseña",
        text45: "Enlace de correo electrónico",
        text46: "Modificar correo electrónico",
        text47: "Enlace de teléfono móvil",
        text48: "Modificar teléfono móvil",
        text49: "Verificación Google",
        text50: "Vincular el Autenticador de Google",
        text51: "Ingrese el código de verificación de Google",
        text52: "ahorrar",
        text53: "Confirmar envío",
        text54: "Revisar",
        text55: "vinculante",
        text56: "Desactivar la verificación de Google",
        text57: "Próximo paso",
        text58: "Finalizar",
        text59: "La verificación de Google está vinculada",
        text60: "La verificación de Google ha sido desagregada",
        text61: "Se ha presentado la certificación primaria.",
        text62: "Certificación avanzada enviada",
        text63: "No se ha subido ninguna imagen",
        text64: "Reenviar",
        text65: "Por favor escribe un apodo",
        text66: "Apodo guardado exitosamente",
        text67: "Aún no se han subido imágenes",
        text68: "Restablecimiento de contraseña completo",
        text69: "Correo electrónico modificado exitosamente",
        text70: "Avatar guardado exitosamente",
        text71: "Cargar imagen",
        text72: "tu buzón",
        text73: "Introduce tu nuevo correo electrónico",
        text74: "Su nombre",
        text75: "Ir a autenticación",
        text76: "Por favor verifique la foto de su documento de identidad"
    },
    retrievePassword: {
        formTitle1: "restablecer la contraseña",
        formTitle2: "Para la seguridad de sus activos, las transacciones están prohibidas dentro de las 24 horas posteriores al cambio de contraseña.",
        formTitle3: "Recuperar Contraseña",
        formTitle4: "Confirmar los cambios",
        formTitle5: "Por favor ingrese la contraseña anterior",
        formTitle6: "Por favor ingrese una nueva contraseña"
    },
    home: {
        title1: "La plataforma de comercio de criptomonedas más popular",
        title3: "por que es ",
        title4: " Es una plataforma blockchain de código abierto de alto rendimiento diseñada para respaldar y operar una infraestructura digital segura, compatible y predecible.",
        field1: "Intercambio establecido",
        field2: "Servicios financieros de activos digitales",
        field3: "Decenas de millones de usuarios",
        field4: "Establecer múltiples fondos de protección de inversores",
        field5: "Comercio rápido",
        field6: "CME Group puede igualar órdenes con latencia ultrabaja para brindar la mejor experiencia comercial.",
        title5: "tendencia del mercado",
        title6: "ver más",
        table1: "Divisa",
        table2: "Último precio",
        table3: "24H altibajos",
        table4: "Volumen de operaciones 24 horas",
        table5: "Volumen de transacciones 24 horas",
        table6: "Citas",
        title7: "acerca de ",
        title8: " Estamos comprometidos a brindar a los usuarios las herramientas comerciales más avanzadas, tecnología innovadora y un servicio al cliente de calidad incomparable.",
        title9: "El campo de los activos digitales cambia cada día que pasa y sólo si somos previsores y avanzamos rápidamente podremos seguir el ritmo de la innovación tecnológica.",
        title901: " Brindar los servicios de comercio de activos digitales más avanzados a nuestros usuarios y proveedores de liquidez globales.",
        title10: "Creemos firmemente que cada usuario merece la mejor experiencia comercial.",
        title11: "Seguridad en la que puedes confiar",
        title12: "Nuestras sofisticadas medidas de seguridad y fondos SAFU protegen sus activos digitales de todos los riesgos. ",
        title13: "Las mejores tarifas de transacción",
        title14: "Tarifas de transacción preferenciales, derechos VIP competitivos y disfrute de servicios de la mejor calidad.",
        title15: "Atención al cliente 24*7 horas",
        title16: "Modo de operación a tiempo completo y en todo clima las 24 horas, los 7 días de la semana, responderemos a sus consultas relevantes lo antes posible.",
        title17: "0 tarifas comerciales",
        title18: "Utilice múltiples métodos de pago para operar con monedas digitales sin tarifas de manejo, de forma segura y rápida.",
        title19: "¡Opere en cualquier momento y lugar!",
        title20: "Perfectamente compatible con múltiples terminales, satisfaciendo las necesidades de transacciones de varios escenarios en cualquier momento",
        title21: "Consulta las condiciones del mercado en tiempo real en cualquier momento.",
        title22: "Compre y venda fácilmente BTC, ETH, XRP y otros activos digitales",
        title23: "Reciba alertas tempranas sobre los cambios de precios de las criptomonedas que le interesan",
        title24: "Ver préstamos al contado de Bitcoin, préstamos de futuros y precios de opciones",
        title25: "Compare los precios de las criptomonedas en varios intercambios",
        title26: "Regístrate ahora",
        title27: "Dólar",
        title28: "24H Volumen",
        title29: "Proveedor de liquidez integrado",
        title30: "usuario",
        title31: "Más",
        title32: "¡Binance Loan agrega CTSI y KSM como activos colaterales y lanza descuentos de reducción de tasas de interés en BUSD y USDT!",
        title33: "Nuestra ventaja",
        title34: "seguro y estable",
        title35: "El equipo técnico superior, la protección de seguridad integral, la investigación y el desarrollo independientes de un motor de síntesis de transacciones de alta velocidad aún pueden operar de manera estable y confiable en transacciones simultáneas masivas.",
        title36: "Cumplimiento profesional",
        title37: "El equipo de operaciones profesional, con muchos años de experiencia en blockchain y finanzas, posee una licencia de comercio de activos digitales compatible y tiene una garantía de reserva del 100%.",
        title38: "Usuario primero",
        title39: "Frente al mercado global, compatible con varios idiomas, funcionando las 24 horas del día, los 7 días de la semana, un sólido soporte comunitario y un servicio al cliente profesional.",
        title40: "alto rendimiento",
        title41: "300.000 transacciones por segundo y tiempo de respuesta de pedidos inferior a 1 milisegundo.",
        title42: "sobre nosotros",
        title43: "¡Opere en cualquier momento y lugar!",
        title44: "Perfectamente compatible con múltiples terminales, satisfaciendo las necesidades de transacciones de varios escenarios en cualquier momento",
        title45: "(Escanea el código QR para descargar)",
        title46: "Noticias sobre monedas digitales",
        title47: "nuestro socio comercial",
        title48: "lista perdedores",
        title49: "facturación 24h",
        title50: "24H Change"
    },
    quotation: {
        marketTrading: "Citas",
        turnover: "Volumen",
        optional: "Opcional",
        contractMarket: "mercado de contratos",
        currencyPair: "par de divisas",
        latestPrice: "Precio de cierre",
        upsAndDowns: "cambio de cotizacion",
        highest: "más alto",
        lowest: "más bajo",
        transactionAmount: "Volumen de transacciones",
        operate: "funcionar",
        buy: "comercio",
        topTurnovers: "Lista de transacciones",
        topGainers: "Lista ganadores",
        tradingPair: "par comercial",
        buyOrder: "Comprar",
        sellOrder: "vender orden"
    },
    accountChange: {
        demo: "simulación",
        realAccount: "cuenta real",
        demoAccount: "cuenta demo",
        accountRole: "Rol de cuenta",
        pleaseSelect: "por favor elige",
        AccountChangeType: "Tipo de cambio de cuenta",
        AccountType: "Tipo de cuenta",
        currencyType: "Tipo cambio monetario",
        allType: "Todos los tipos",
        allAccount: "Todas las cuentas",
        allCoin: "Todas las monedas",
        coin: "Divisa",
        amount: "Cambiar cantidad",
        balance: "Saldo de la cuenta",
        time: "tiempo",
        title: "Registros de cambios de cuenta",
        filtrar: "filtrar",
        check: "cambiar a",
        showOnly: "Mostrar solo tenencias de divisas",
        holdAssets: "Saldo de moneda",
        accountList: "lista de cuentas"
    },
    contractTransaction: {
        chart: "cuadro",
        basicEdition: "Versión básica",
        depthMap: "mapa de profundidad",
        second: "Segundo",
        minute: "punto",
        day: "cielo",
        week: "semana",
        month: "luna",
        open: "abierto",
        trade: "comercio",
        buyLong: "compra larga",
        buyShort: "compra corta",
        cannotBuy: "Esta moneda no admite transacciones de contratos de entrega.",
        cannotBuy1: "Esta moneda no admite el comercio perpetuo.",
        tradingAssets: "activos comerciales",
        amountAvailable: "cantidad disponible",
        timeStr: "tiempo",
        transactionPeriod: "periodo negociación",
        quantity: "cantidad",
        enterQuantity: "Por favor ingrese la cantidad",
        buyIn: "Ve largo",
        latestTransaction: "última transacción",
        time: "tiempo",
        direction: "dirección",
        price: "precio",
        sell: "corto",
        positionOrder: "Orden de entrega",
        historicalOrders: "Órdenes históricas",
        product: "producto",
        realAccount: "(cuenta real)",
        demoAccount: "(cuenta demo)",
        openingTime: "tiempo de la orden",
        openingPrice: "Precio de apertura",
        curretPrice: "Precio actual",
        liquidationPrice: "Precio cierre",
        profitOrLoss: "Ganancia y perdida",
        endTime: "hora cierre",
        areYouSureToBuy: "¿Estás seguro de que quieres comprar?",
        areYouSureToLongBuy: "¿Estás seguro de que quieres ir más allá?",
        areYouSureToShortBuy: "¿Estás seguro de que quieres comprar corto?",
        successfullyBought: "Compra con éxito",
        minBuy: "Compra mínima",
        assets: "activos",
        withdraw: "retirar",
        entrustedOrder: "Orden",
        total: "total",
        continueToPlaceOrder: "Continuar para realizar un pedido",
        orderConfirmation: "Confirmación del pedido",
        buyRu: "Comprar",
        sellChu: "vender",
        areYouSureToSell: "¿Estás seguro de que quieres vender?",
        operation: "funcionar",
        buyCover: "posición de la cubierta",
        commitSuccess: "Transacción exitosa",
        onlyEnterIntegers: "Sólo se pueden ingresar números enteros",
        trend: "tendencia",
        lotteryTime: "Fecha límite para realizar el pedido",
        countdown: "cuenta regresiva",
        period: "Asunto",
        cycle: "Periodo de tiempo",
        unlimited: "Ilimitado",
        put: "Put",
        call: "Call",
        pending: "En comisión",
        unopened: "Aún no dibujado",
        opened: "Premio sorteado",
        settlementPrice: "Precio acordado",
        rateOfReturn: "tasa retorno",
        currentPositions: "Posición",
        positionQuantity: "Cantidad posición",
        guarantee: "margen",
        text1: "Alza",
        text2: "Baja",
        text3: "esperando para comprar",
        text4: "Ordene la cantidad",
        text5: "El número transacciones",
        text6: "Cancelar",
        text7: "el tiempo de entrega",
        text8: "Recordatorio de cancelación",
        text9: "¿Estás seguro de que deseas cancelar el pedido?",
        text10: "cantidad ilegal",
        text11: "Recordatorio de pedido",
        text12: "¿Estás seguro de que estás comprando alcista?",
        text13: "¿Estás seguro de comprar el put?",
        text14: "Cancelación exitosa"
    },
    mining: {
        title1: "La plataforma de minería de activos digitales blockchain líder en el mundo",
        title2: "Abierto y transparente · seguro y estable",
        title3: "Por favor seleccione moneda",
        title4: "Rendimiento Minero Anualizado",
        title5: "cielo",
        title6: "Periodo minero",
        title7: "Detalles producto",
        btn: "Mio ahora",
        btn2: "cierre",
        tabs1: "en curso",
        tabs2: "redimido",
        table1: "nombre del producto",
        table2: "Periodo minero",
        table3: "tiempo minería",
        table4: "Cantidad minera",
        table5: "Fecha caducidad",
        table6: "Progresos hasta el vencimiento",
        table7: "Estado pedido",
        egularMining: "Minería regularmente",
        inProgress: "en curso",
        redeemed: "redimido",
        redemption: "redención",
        regularMining: "Minería regularmente",
        earlyRedemption: "Redención temprana",
        need: "necesidad",
        handlingFee: "tasa tramitación",
        redemptionConfirmationMessage: "¿Por favor confirme si desea canjear por adelantado?",
        form1: "Rendimiento Minero Anualizado",
        form2: "Periodo minero",
        form3: "Moneda minera",
        form4: "Importe de la suscripción (mínimo/máximo)",
        form5: "Comisión de reembolso anticipado: ",
        form6: "precio de compra",
        form7: "Fondos disponibles",
        form8: "precio de compra",
        form9: "Fondos disponibles",
        form10: "Por favor ingresa el monto de la compra",
        form11: "todo",
        form12: "Ingresos estimados",
        form13: "Fondos insuficientes disponibles",
        form14: "Por favor ingresa el monto de la compra",
        form15: "Mi pedido",
        form16: "ingreso",
        form17: "detalles del pedido",
        form18: "Instrucciones para el canje anticipado:",
        form19: "Monto de suscripción (mínimo)",
        form20: "Monto de suscripción (máximo)",
        form21: "Cantidad retenida",
        form22: "ganando tiempo",
        form23: "tiempo redención",
        form24: "encima",
        form25: "Suscripción exitosa",
        form26: "Redención exitosa",
        form27: "Fácil gestión financiera con rendimientos superiores al 25%, superando al mercado y también puedes recibir recompensas después de comprar.",
        form28: "Invite a amigos a recibir una recompensa de 50 USDT y los vales de contrato también pueden generar una comisión del 20 %."
    },
    assets: {
        content1: "Activos cuenta",
        content2: "Las ganancias de hoy",
        content3: "cuenta actual",
        content4: "La cuenta real son los activos reales de su cuenta, que pueden usarse para transacciones y retiros.",
        content5: "retirar",
        content6: "depositar",
        content7: "intercambio",
        content8: "transferir",
        content9: "por favor elige",
        content10: "cuenta demo",
        content11: "cuenta real",
        content12: "adquirir activos",
        content13: "¿Está seguro de que desea cambiar a una cuenta real?",
        content14: "¿Está seguro de que desea cambiar a una cuenta demo?",
        content15: "conseguir el éxito",
        table1: "Divisa",
        table2: "cantidad",
        table3: "congelar",
        table4: "Disponible",
        dialog1: "Detalles de producto",
        dialog2: "1. Este activo solo puede usarse en modo de cuenta demo.",
        dialog3: "2. Para evitar abusos, sólo se permite un reembolso cada mes.",
        dialog4: "3. Al recibir activos, la cantidad de activos recibidos se agregará a la cantidad original y el estado de la posición no cambiará.",
        dialog5: "4. Si tiene alguna otra pregunta, comuníquese con el servicio al cliente.",
        dialog6: "cierre",
        dialog7: "Obtenga fondos demostración",
        dialog8: "transferencia",
        dialog9: "trasferencia",
        dialog10: "cambiar",
        dialog11: "Valuación",
        dialog12: "Activos de demostración",
        dialog13: "mi cuenta",
        dialog14: "Activos totales de la cuenta",
        dialog15: "Resumen de activos",
        dialog16: "Valoración de activos totales",
        dialog17: "Valoración de activos",
        dialog18: "Las ganancias de hoy",
        dialog19: "congelar fondos",
        dialog20: "Reciba activos simulados cada mes",
        dialog21: "recibir",
        dialog22: "Has recibido con éxito",
        dialog23: '30 días de ingresos acumulados',
        dialog24: "Producto financiero",
        dialog25: "mi gestión financiera",
        dialog26: "Gestión financiera histórica",
        dialog27: "registro de historia",
        dialog28: "Registros de financiación"
    },
    validator: {
        dialog14: "validador",
        dialog15: "Validador de enlace",
        dialog16: "interruptor validador",
        dialog17: "Código de verificación",
        dialog18: "Código QR validador",
        dialog19: "clave validador",
        dialog20: "ahorrar",
        dialog21: "Por favor seleccione un validador",
        dialog22: "copiar clave",
        dialog23: "Guardado exitosamente",
        dialog24: "por favor ingrese el código de verificación",
        dialog25: "Recordatorio amistoso: antes de confirmar el guardado, abra la APLICACIÓN correspondiente y escanee el código QR a continuación o agregue la clave manualmente.",
        dialog26: "Habilitar su autenticador proporciona la máxima seguridad para su cuenta.",
        dialog27: "Deberá ingresar un código de verificación único al que accederá a través de su teléfono móvil cada vez que inicie sesión o retire fondos.",
        dialog28: "Configurar validador",
        dialog29: "ayuda",
        dialog30: "No ha vinculado el código de autenticación. Obtenga una protección más sólida con un autenticador.",
        dialog31: "El validador aún no se ha configurado, configúrelo primero",
        dialog32: "ajustes"
    },
    recharge: {
        title1: "¿No estás seguro de cómo comprar usando moneda digital?",
        title2: "Sólo tres sencillos pasos!",
        title3: "Seleccione la moneda digital que desea comprar en el menú desplegable e ingrese la cantidad o monto de la compra.",
        title4: "Elige tu método de pago preferido",
        title5: "Una vez confirmado el pago, la moneda digital que compró se transferirá automáticamente a su cuenta.",
        title6: "Por favor seleccione la moneda que desea recargar",
        title7: "Registro depósito",
        table1: "Divisa",
        table2: "cantidad",
        table3: "Nombre cadena",
        table4: "estado",
        table5: "razón",
        table6: "tiempo",
        table7: "Ver bono",
        dialog1: "Por favor ingrese el código de verificación a continuación",
        dialog2: "código pago",
        dialog3: "Copiar",
        dialog4: "DIRECCIÓN",
        dialog5: "Cantidad depósito",
        dialog6: "Captura pantalla transacción",
        dialog7: "noticia importante",
        dialog8: "Por favor complete monto depósito",
        dialog9: "cargar imagen",
        dialog10: "Por favor ingresa el contenido",
        dialog11: "Por favor sube capturas de pantalla de recarga",
        dialog12: "No revisado",
        dialog13: "rechazado",
        dialog14: "Depositado",
        tip1: "Enviado satisfactoriamente",
        tip2: "Copiado exitosamente",
        tip3: "Copia fallida",
        tip4: "Por favor seleccione la moneda que desea recargar",
        tip5: "1.La dirección de depósito anterior es la dirección de pago oficial de la plataforma. Busque la dirección de depósito oficial de la plataforma. Usted será responsable de la pérdida de fondos causada por un depósito erróneo;",
        tip6: "2.Asegúrese de confirmar la seguridad de su computadora y navegador para evitar que la información sea manipulada o filtrada;",
        tip7: "3.Después de recargar la dirección anterior, todo el nodo de la red debe confirmar antes de que se pueda acreditar la cuenta;",
        tip8: "4.Seleccione el sistema de token y la moneda anteriores y transfiera el monto correspondiente para el depósito. No transfiera ningún otro activo no relacionado, de lo contrario no se recuperará.",
        tip9: "Guardar código QR",
        tip10: "completar",
        tip11: "noticia importante",
        tip12: "Enlaces externos",
        tip13: "Recargar código QR",
        tip14: "Próximo paso",
        tip15: "Registro recarga",
        tip16: "Detalles recarga",
        tip17: "Dirección depósito",
        tip18: "Pago rápido",
        tip20: "Saldo insuficiente",
        tip22: "Enviado, esperando confirmación",
        tip23: "A la espera del pago",
        tip24: "Cantidad",
        tip25: "El pago se ha enviado, esperando la confirmación del bloqueo.",
        tip26: "Pago rechazado",
        tip27: "Pago fallido",
        tip28: "Pago exitoso",
        bankChannel: "canal tarjetas bancarias",
        tip29: "Búsquedas históricas",
        tip30: "Lista monedas",
        tip31: "Seleccione red",
        tip32: "Cuando recargue esta moneda, recargue únicamente a través de las redes admitidas a continuación. Si recarga a través de otras redes, se perderán los fondos.",
        tip33: "Cuando retire esta moneda, retírela únicamente a través de las redes admitidas a continuación. Si retira a través de otras redes, se perderán los fondos.",
        tip34: "Recarga rápida",
        tip35: "Escanear código para recargar",
        tip36: "recarga manual",
        tip37: "Red de recarga",

        tip38: "Dirección de recarga",
        tip39: "Monto recarga",
        tip40: "Carga del recibo de transacción",
        tip41: "cargar imagen",
        tip42: "confirmar el envío",
        tip43: "confirmar pago",
        tip44: "Nota: Por favor confirma la dirección que necesitas para recargar, no somos responsables de la pérdida de dinero si la ingresas incorrectamente.",
        tip45: "recordar",
        tip46: "Dirección copiada correctamente!",
        tip47: "Error al copiar la dirección!",
        tip48: "Saldo disponible insuficiente",
        tip49: "La recarga fue enviada exitosamente y está esperando confirmación en la cadena.",
        tip50: "Recarga fallida",
        tip51: "El monto de la recarga es incorrecto",
        tip52: "La conexión falló",
        tip53: "Cartera no iniciada",
        tip54: "conexión denegada",
        tip55: "Enviado correctamente, esperando la revisión y aprobación del servicio de atención al cliente.",
        tip56: "Sube una captura de pantalla del recibo de la transacción.",
        tip57: "Envío fallido",
        tip58: "Registro recarga",
        tip59: "Registro retiros",
        tip60: "Recargar moneda",
        tip61: "moneda retiro",
        tip62: "Dirección recarga",
        tip63: "La captura pantalla muestra",
        tip64: "Haga clic para ampliar la imagen",
        tip65: "Tiempo recarga",
        tip66: "Motivo rechazo",
        tip67: "Cantidad retiro",
        tip68: "Cantidad recibida",
        tip69: "Red retiro efectivo",
        tip70: "dirección retiro",
        tip71: "tiempo retiro",
        tip72: "cierre",
        tip73: "Captura  pantalla transacción",
        tip74: "En revisión",
        tip75: "rechazado",
        tip76: "terminado",
        tip77: "rechazado",
        tip78: "Auditado",
        tip79: "Desembolsado",
        tip80: "extracto",
        tip81: "red transferencia",
        tip82: "Dirección/Factura",
        tip83: "Retirar todo",
        tip84: "No retire dinero directamente a la dirección de crowdfunding o ICO; de lo contrario, no podrá recibir los tokens emitidos por crowdfunding o ICO.",
        tip85: "Cantidad llegada",
        tip86: "Confirmar retiro",
        tip87: "Dirección retiro incorrecta",
        tip88: "Monto retiro incorrecto",
        tip89: "El monto a retirar es al menos",
        tip90: "El monto máximo de retiro es",
        tip91: "Se ha enviado la solicitud de retiro.",
        tip92: "lista direcciones",
        tip93: "Aún no has agregado una dirección",
        tip94: "Agrégalo ahora",
        tip95: "Añadir dirección",
        tip96: "agregar nueva dirección",
        tip97: "por favor elige",
        tip98: "DIRECCIÓN",
        tip99: "Por favor seleccione una red de transferencia",
        tip100: "Por favor ingrese la dirección",
        tip101: "Dirección agregada exitosamente",
        tip102: "en transacción",
    },
    fundExchange: {
        title1: "¿Cómo intercambiar fondos?",
        title2: "Seleccione la moneda apropiada para convertir.",
        title3: "Seleccione la moneda adecuada para convertir.",
        title4: "Ingrese el monto a intercambiar y haga clic en Enviar para completar el intercambio de fondos.",
        title5: "de",
        title6: "llegar",
        title7: "Cantidad de cambio",
        title8: "tipo de cambio",
        title9: "Disponible",
        title10: "Por favor ingrese la cantidad de canje",
        title11: "No existe un par comercial correspondiente para el intercambio.",
        title12: "Por favor ingresa la cantidad a canjear",
        title13: "Redención exitosa",
        title14: "Registro intercambio",
        table1: "Usar moneda",
        table2: "cantidad",
        table3: "tipo de cambio",
        table4: "Cambio de divisas",
        table5: "Recibo",
        table6: "tiempo",
        title15: "de",
        title16: "Actualmente no hay activos disponibles, seleccione otro"
    },
    fundTransfer: {
        title1: "¿Cómo transferir fondos?",
        title2: "Seleccione la cuenta corriente que necesita ser transferida.",
        title3: "Seleccione la cuenta que desea transferir.",
        title4: "Ingrese el monto de la transferencia y haga clic en Enviar para completar la transferencia.",
        title5: "Divisa",
        title6: "Cantidad transferencia",
        title7: "Por favor ingrese el monto a transferir",
        title8: "registro transferencia",
        title9: "Por favor seleccione el tipo de billetera",
        title10: "Por favor seleccione moneda",
        title11: "Por favor seleccione el tipo de billetera",
        title12: "Por favor seleccione moneda",
        title13: "Por favor ingrese el monto a transferir",
        title14: "Enviado satisfactoriamente",
        table1: "Transferir cuenta",
        table2: "transferir moneda",
        table3: "Cantidad transferencia",
        table4: "cuenta transferencia",
        table5: "tiempo",
        table6: "transferir",
        table7: "Transferencia fondos",
        table8: "Transferir a",
        table9: "registro transferencia",
        table10: "Detalles transferencia",
        table11: "Transferencia exitosa",
        table12: "Transferencia fallida",
        table13: "La cuenta corriente no se puede transferir"
    },
    withdrawal: {
        title1: "noticia importante",
        title2: "1.Para evitar el arbitraje, puede solicitar el retiro de moneda cuando el volumen de transacciones alcance la cuota.",
        title3: "2.La solicitud de retiro se acreditará dentro de las 24 horas posteriores al envío. Si el retiro no se recibe después del tiempo estimado de retiro, consulte con el servicio de atención al cliente en línea.",
        title4: "3.Después de enviar la solicitud de retiro, los fondos están congelados porque el retiro está en curso y el sistema retiene los fondos temporalmente, lo que no significa que haya perdido el activo o que haya una anomalía con el activo.",
        title5: "Divisa",
        title6: "dirección billetera",
        title7: "Cantidad monedas retiradas",
        title8: "Registro retiro monedas",
        title9: "Por favor seleccione moneda",
        title10: "Por favor seleccione el tipo de cadena",
        title101: "",
        title11: "por favor escribe",
        title12: "dirección billetera",
        title13: "Enviado satisfactoriamente",
        title14: "bajo revisión",
        title15: "Revisión exitosa",
        title16: "Fallo de auditoría",
        title17: "Desembolsado",
        title18: "Por favor ingresa o pega la dirección de tu billetera",
        title19: "Por favor ingrese el monto del retiro",
        title20: "tasa de tramitación",
        title21: "Llegada real",
        table1: "Divisa",
        table2: "Cantidad monedas retiradas",
        table3: "Nombre cadena",
        table4: "dirección billetera",
        table5: "estado",
        table6: "razón",
        table7: "tiempo aplicación",
        table8: "retirar",
        table81: "retirar",
        table9: "dirección retiro",
        table10: "Por favor seleccione la moneda que desea retirar",
        table11: "Por favor asegúrese de ingresar",
        table12: 'Dirección (normalmente comienza con "0x")',
        table13: "Monedas que se pueden retirar (se deducen los gastos de gestión):",
        table14: "Seleccione el tipo de moneda",
        table15: "¿Encontraste problemas? Retroalimentación inmediata",
        table16: "balance",
        table17: "Detalles retiro",
        password: "contraseña",
        passwordTips: "Por favor, ingrese contraseña",
        passwordAg: "confirmar Contraseña",
        passwordAgTips: "Por favor ingrese la contraseña nuevamente",
        oldPassword: "Contraseña anterior",
        oldPasswordTips: "Por favor ingrese contraseña anterior",
        newPassword: "Nueva contraseña",
        newPasswordTips: "Por favor ingrese una nueva contraseña",
        passwordNotSame: "Las contraseñas son inconsistentes",
        withdrawPasswordSet: "Establecer contraseña retiro",
        withdrawPasswordModify: "Cambiar contraseña retiro",
        withdrawPassword: "Retirar contraseña",
        withdrawPasswordTips: "Por favor ingrese la contraseña de retiro",
        setWithdrawPasswordFirst: "Primero establezca una contraseña de retiro",
        withdrawPasswordSetSuccess: "Instalación exitosa",
        withdrawPasswordModifySuccess: "Modificado con éxito",
        submit: "entregar",
    },
    customer: {
        content1: "Servicio en línea",
        content2: "Servicio al Cliente",
        content3: "Por favor, introduce tu información de contacto",
        content4: "Por favor ingresa el contenido",
        content5: "enviar",
        content6: "Error al enviar, error en la conexión de red",
        content7: "imagen",
        content8: "el mensaje está vacío",
        content9: "mi parte",
        content10: "compartir enlace",
        content11: "guardar foto",
        content12: "Mis Ganancias",
        content13: "Descripción del nivel",
        content14: "Tamaño del equipo",
        content15: "Ingresos por invitación",
        content16: "Descripción regla",
        content17: "nivel membresía",
        content18: "El monto recarga es mayor que",
        content19: "Nivel de equipo",
        content20: "Nivel de equipo e ingresos",
        content21: "Para cumplir con las condiciones",
        content22: "Ingresos por distribución",
        content23: "equipo de nivel",
        content24: "nivel, invitación",
        content25: "miembros válidos",
        content26: "ingreso primario",
        content27: "ingreso secundario",
        content28: "Ingresos nivel 3",
    },
    standardContract: {
        increaseMargin: "aumentar el margen",
        increaseAmount: "aumentar cantidad",
        Pleaseenterthemargin: "Por favor ingrese el monto del margen que desea aumentar",
        Pleaseenterthecorrect: "Por favor ingrese el monto correcto del depósito",
        Increasemarginsuccessfully: "Aumentar el margen con éxito",
        takeProfitPriceWrong: "Precio obtención beneficios incorrecto",
        stoppriceerror: "Error precio parada",
        thetakeprofit1: "El precio de toma de ganancias debe ser mayor que el precio de mercado.",
        thetakeprofit2: "El precio del stop loss debe ser inferior al precio de mercado.",
        thetakeprofit3: "El precio de toma de ganancias debe ser menor que el precio de mercado.",
        thetakeprofit4: "El precio del stop loss debe ser superior al precio de mercado.",
        thetakeprofit5: "Cantidad incorrecta",
        estimatedprofitloss: "Ganancias y pérdidas estimadas",
        positionAmount: "Número de posiciones",
        closeAmount: "Cantidad cierre",
        whenthelatest: "Cuando se alcanza el último precio",
        whenthelatest2: ", se activará la orden de toma de ganancias a precio de mercado para cerrar la posición actual. Ganancias y pérdidas esperadas",
        whenthelatest3: ", se activará la orden de stop loss del mercado para cerrar la posición actual. Ganancias y pérdidas esperadas",
        contract: "contrato",
        ordercanceledsuccessfully: "Pedido cancelado con éxito",
        successfullyordered: "ordenado exitosamente",
        positionopeningreminder: "Recordatorio apertura posición",
        wrongquantity: "Cantidad incorrecta",
        quantitycannot: "La cantidad no puede ser mayor que",
        modificationstoploss: "Modificación del stop beneficio y stop loss exitosa",
        positionclosedsuccessfully: "Posición cerrada con éxito",
        orderCancellation: "Cancelación de orden",
        orderCancellation2: "¿Estás seguro de que deseas cancelar el pedido?",
        tobeexecuted: "Para ser ejecutado",
        expired: "venció",
        failed: "fallido",
        contractTransaction: "contrato perpetuo",
        increase: "Aumentar",
        openPosition: "Almacén completo",
        isolatedPosition: "Posición aislada",
        limitOrder: "Límite",
        marketOrder: "Mercado",
        pleaseSelectLeverage: "Por favor seleccione apalancamiento",
        pleaseEnterQuantity: "Por favor ingrese la cantidad",
        canOpenMore: "Posición máxima abierta",
        contractAmount: "Monto del contrato",
        openingFee: "tasa de tramitación",
        currentPosition: "Posición",
        historicalPositions: "Posiciones históricas",
        currentCommission: "Orden actual",
        historicalCommission: "Historia comisión",
        buyPrice: "precio de compra",
        income: "ganancia",
        quantity: "cantidad",
        marginRate: "tasa de margen",
        initialMargin: "Margen inicial",
        estimatedPriceParity: "Precio de liquidación forzosa",
        operate: "funcionar",
        finishQuantity: "Volumen de transacciones",
        averagePrice: "precio medio de transacción",
        pleaseEnterLeverage: "Por favor ingrese el apalancamiento",
        takeProfitAndStopLoss: "TP&SL",
        takeProfit: "Saca provecho",
        stopLoss: "detener la pérdida de",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Cerrar",
        marketLquidation: "Todo plano",
        cancel: "Cancelar orden",
        enterBuyPrice: "Por favor ingrese el precio de compra",
        areYouSureLquidation: "¿Estás seguro de cerrar la posición?",
        confirm: "Seguro",
        enterStopWinPrice: "Por favor ingrese el precio de toma de ganancias",
        enterStopLossPrice: "Por favor introduzca el precio del stop loss",
        commissionTime: "tiempo comisión",
        commissionPrice: "Precio comisión",
        commissionQuantity: "Importe total comisiones",
        volumeAlreadyTraded: "Cantidad completada",
        areYouSureToWinOrLoss: "¿Está seguro de que desea detener las ganancias y las pérdidas?",
        maxQuantity: "Posición máxima",
        saleQuantity: "Posición cerrada",
        salePrice: "precio medio cierre",
        saleTime: "tiempo",
        succeeded: "tuvo éxito",
        cancelled: "Cancelado",
        piece: "abierto",
        triggerPrice: "precio activación",
        setWin: "Establecer toma de ganancias",
        setLoss: "Establecer stop loss",
        enterWinQuantity: "Por favor ingrese el monto de la toma de ganancias",
        enterLossQuantity: "Por favor ingrese el monto del stop loss",
        planCommission: "Comisión Planificación",
        enterWinTriggerPrice: "Ingrese el precio de activación de la toma de ganancias",
        enterLossTriggerPrice: "Por favor ingrese el precio de activación del stop loss",
        insufficientQuantity: "Cantidad insuficiente",
        enterTriggerPrice: "Por favor ingrese el precio de activación",
        tips1: "El precio de su orden es superior al precio actual y se ejecutará directamente al precio actual del mercado. ¿Está seguro de que desea comprar?",
        tips2: "El precio de su orden es inferior al precio actual y se ejecutará directamente al precio actual del mercado. ¿Está seguro de comprar?",
        type: "tipo",
        increaseQuantity: "Aumentar posición",
        reduceQuantity: "Reducir posiciones",
        limitCommission: "orden límite",
        tips3: "El precio del stop loss debe ser inferior al precio de mercado.",
        tips4: "El precio de toma de ganancias debe ser mayor que el precio de mercado.",
        tips5: "El precio del stop loss debe ser superior al precio de mercado.",
        tips6: "El precio de toma de ganancias debe ser menor que el precio de mercado.",
        tips7: "El precio del stop loss debe ser inferior al precio de mercado y al precio de la orden.",
        tips8: "El precio de obtención de beneficios debe ser mayor que el precio de la orden (puede ser menor que el precio de mercado)",
        tips9: "El precio del stop loss debe ser mayor que el precio de mercado y mayor que el precio de la orden.",
        tips10: "El precio de obtención de beneficios debe ser menor que el precio de la orden (puede ser mayor que el precio de mercado)",
        accountEquity: "Saldo cuenta",
        unrealizedPL: "ganancia no realizada",
        tips11: "¿Estás seguro de que tienes razón?",
        tips12: "Mercado",
        tips13: "Cerrar todas posiciones",
        tips14: "no lo vuelvas a recordar",
        profitRate: "Tasa de rendimiento de cierre",
        openTime: "Hora de apertura",
        unwindTime: "hora de cierre",
        dealTime: "tiempo transacción",
        amountClose: "Cantidad de posición cerrada",
        long: "muchos",
        short: 'nulo',
        orderQuantity: "Ordene la cantidad",
        entrustedMargin: "margen confiado",
    },
    spotGoods: {
        spotGoods: "Intercambio flash",
        sellPrice: "precio de venta",
        orderRecord: "Registro canje flash",
        transactionTime: "tiempo transacción",
        maxBuy: "disponible para compra",
        maxSell: "Disponible para venta",
        purchaseQuantity: "Cantidad compra",
        slippage: "deslizamiento",
        convertTips: "Cerraremos la transacción al precio más favorable con un deslizamiento del 0-5%.",
        changePrice: "Precio cambio actual",
        consume: "consumir",
        get: "conseguir",
        changeTips: "¿Estás seguro de que quieres canjear?",
        confirm: "Confirmar canje"
    },
    news: {
        title: "Centro de Noticias",
        redMore: "leer más",
    },
    credit: {
        credit: "puntuación de crédito",
        creditScore: "puntuación de crédito total",
        latestEntry: "último registro",
        liveUpdate: "Actualización en vivo",
        systemEvaluation: "Evaluación del sistema",
        time: "fecha",
        name: "nombre",
        reason: "razón",
        scoreStory: "Registro puntaje crédito"
    },
    borrowing: {
        borrowing: "préstamo",
        diversifiedMarket: "Mercados diversificados",
        totalSupply: "Oferta total",
        totalBorrowings: "endeudamiento total",
        tips1: "Este es el monto total que puede pedir prestado contra su garantía, hasta el límite de endeudamiento.",
        tips2: "Su tasa de interés estable seguirá siendo la misma durante la vigencia de su préstamo. Recomendado para suministro a largo plazo y usuarios a los que les gusta la previsibilidad.",
        totalSupplys: "oferta total",
        supplyAPR: "tasa de oferta",
        totalBorrowings: "Endeudamiento total",
        borrowAPR: "tasa endeudamiento",
        check: "Controlar",
        market: "mercado",
        availableLiquidity: "Liquidez disponible",
        currencyPrice: "precio actual de la moneda",
        supplyAssets: "activos de oferta",
        tips3: "Nota: La tasa de interés a continuación es una tasa de interés fija. Una vez finalizado el período de suministro, el capital más las ganancias se devolverán a su cuenta.",
        supplyCycle: "ciclo suministro",
        minimumSupply: "suministro mínimo",
        supply: "suministrar",
        borrowedAssets: "activos prestados",
        tips4: "Importante saber: Para pedir dinero prestado, deberá proporcionar cualquier activo colateral o prueba,",
        tips5: "Consulte la descripción del activo hipotecario.",
        repaymentCycle: "ciclo pago",
        BorrowableAmount: "Monto del préstamo disponible",
        borrow: "préstamo",
        accountInformation: "Información de la cuenta",
        walletBalance: "saldo billetera",
        canSupply: "Disponible",
        AmountCanBeBorrowed: "Monto total del préstamo disponible",
        borrowedAmount: "Monto prestado",
        remainingAmount: "balance restante",
        getBorrowednAmount: "Obtener límite de préstamo",
        supplyOrder: "orden suministro",
        borrowedOrder: "orden préstamo",
        supplyAmount: "cantidad suministro",
        expectedProfits: "Rendimiento esperado",
        supplyTime: "tiempo suministro",
        expirationTime: "Fecha caducidad",
        borrowingAmount: "Monto del préstamo",
        dueRepayment: "Monto reembolso",
        borrowingTime: "Tiempo liberación",
        repaymentTime: "tiempo amortización",
        repay: "reembolso",
        supplyCurrency: "moneda oferta",
        buyNow: "Comprar ahora",
        supplyRecords: "registros suministro",
        borrowRecords: "Registros préstamos",
        completed: "terminado",
        borrowNow: "pedir prestado ahora",
        borrowCurrency: "moneda prestada",
        borrowMin: "pequeñísimo",
        borrowMax: "máximo",
        borrowRange: "Monto del préstamo (mínimo/máximo)",
        tips6: "Pague a tiempo. Si está vencido, se cobrará una cantidad diaria adicional de (principal + intereses).",
        tips7: "Tarifa vencida.",
        pleaseEnterBorrowAmount: "Por favor ingrese el monto del préstamo",
        interest: "Interés",
        tips8: "¿Estás seguro de que quieres pagar por adelantado?",
        borrowChannel: "canal de endeudamiento",
        tips9: "Empecemos por conocerte",
        name: "nombre",
        lastName: "apellido",
        birthDate: "fecha de nacimiento",
        tips10: "Un poco más de información, ¿cuál es tu dirección?",
        familyAddress: "Dirección de casa (incluido el número de unidad)",
        city: "Ciudad",
        country: "nación",
        postalCode: "Código Postal",
        pleaseEnterYourHomeAddress: "Por favor ingrese la dirección de su casa",
        tips11: "Cuéntanos tu situación financiera",
        tips12: "Es posible que necesitemos confirmar esta información más adelante, así que intente ser lo más preciso posible.",
        yourMonthlyIncome: "tus ingresos mensuales",
        enterYourMonthlyIncome: "Por favor ingrese su ingreso mensual",
        extraIncomeOptional: "Ingresos adicionales (opcional)",
        enterExtraIncome: "Por favor ingrese su ingreso adicional",
        monthlyRentOrMortgagePayment: "Pago mensual de alquiler o hipoteca",
        enterMonthlyRent: "Por favor ingrese su alquiler mensual o pago de hipoteca",
        tip13: "Cuéntanos si tienes una hipoteca",
        tip14: "Si no se selecciona ninguna garantía hipotecaria, la probabilidad de pasar la revisión es baja.",
        isThereMortgage: "¿Existe una hipoteca?",
        selectIsThereMortgage: "Por favor seleccione si hay una hipoteca",
        selectMortgageType: "Por favor seleccione el tipo de hipoteca",
        yes: "Sí",
        no: "No",
        uploadScreenshotOfTransaction: "Sube capturas de pantalla de transacciones",
        tip15: "Mantengámonos en contacto",
        tip16: "Podemos comunicarnos con usted a este número si necesitamos más información sobre su solicitud de préstamo.",
        tip17: "He leído y firmado electrónicamente mi consentimiento",
        tip18: "Términos de uso y consentimiento electrónico",
        tip19: ", que incluye su consentimiento para que le enviemos información sobre nuestra",
        tip20: "Términos de uso de productos y servicios",
        tip21: "Después de completar la información, espere la evaluación y revisión del sistema.",
        phoneNumber: "número de teléfono",
        enterPhoneNumber: "Por favor, introduzca su número de teléfono",
        tip22: "Marque la casilla para aceptar los términos de uso y el formulario de consentimiento electrónico.",
        inReview: "bajo revisión",
        applicationAmount: "Monto de la solicitud",
        reasonForRejection: "Motivo del rechazo",
        reapply: "Aplicar nuevo",
        applyStatus: "estado aplicación",
        currentOrder: "Orden actual",
        interestRate: "tasa interés"
    },
    yuebao: {
        filed1: "invertir",
        filed2: "sacar",
        filed3: "Ingreso diario",
        filed4: "aceleración equipo",
        filed5: "Depositado",
        filed6: "Ganancias de ayer",
        filed7: "Los ingresos totales",
        filed8: "Fondos disponibles",
        filed9: "Por favor ingrese la cantidad",
        filed10: "Minería liquidez",
        filed11: "Introducción",
        field12: "Precio mínimo compra",
        field13: "Lo máximo que puedes comprar",
        filed14: "Suscripción",
        filed15: 'regular',
        filed16: 'tasa interés anualizada',
    },
    tipsPopover: {
        title: "Instalar esta aplicación",
        press: "de acuerdo a",
        and: "y",
        scrren: "Añadir a la pantalla principal",
        hasInstall: "(Si está instalado, cierre la ventana.)",
    },
    follow: {
        title1: "plan seguimiento",
        follow_text1: "Iniciar un pedido de seguimiento",
        follow_text2: "Historial lanzamientos",
        follow_text3: "título",
        follow_text4: 'par comercial',
        follow_text5: 'Duración compra',
        follow_text6: 'tiempo orden',
        follow_text7: 'tiempo liberación',
        follow_text8: 'Número seguidores',
        follow_text9: 'Total orden',
        follow_text10: 'funcionar',
        follow_text11: 'invitar',
        follow_text12: 'Siga dirección del pedido',
        follow_text13: 'Establecer condiciones pedido',
        follow_text14: 'Condiciones para seguir pedidos',
        follow_text15: 'cantidad fija',
        follow_text16: 'porcentaje',
        follow_text17: 'personas invitadas',
        follow_text18: 'La identificación otra parte',
        follow_text19: "Confirmar para seguir el pedido",
        follow_text20: "Monto tu pedido (opcional)",
        follow_text21: "Por favor introduce un título",
        follow_text22: "Por favor seleccione un par comercial",
        follow_text23: "Por favor seleccione dirección del pedido",
        follow_text24: "Por favor seleccione duración compra",
        follow_text25: "Por favor seleccione la hora del pedido",
        follow_text26: "Por favor seleccione la cantidad de compra",
        follow_text27: "La cantidad máxima de compra no puede ser inferior a la cantidad mínima de compra.",
        follow_text28: "Compra mínima",
        follow_text29: "La mayoría compra",
        follow_text30: "Crear orden de copia exitosamente",
        follow_text31: "Lista de pares comerciales",
        follow_text32: "Ya segui el orden",
        follow_text33: "Por favor ingresa la información del invitado",
        follow_text34: "Invitado",
        follow_text35: "me invitó",
        follow_text36: "Copiando historial",
        follow_text37: "Rango monto pedido"
    },
    followHistory: {
        title1: 'Historial lanzamientos',
        title2: "Copiando historial",
        follow_h_text1: "El importe total del pedido.",
        follow_h_text2: "Número total de personas que siguen órdenes",
        follow_h_text3: "Número lanzamientos",
        follow_h_text4: "tasa ganancia",
        follow_h_text5: "Número de veces para seguir órdenes",
        follow_h_text6: "Benefíciese seguir pedidos",
        follow_h_text7: "dirección",
        follow_h_text8: "ganar",
        follow_h_text9: "déficit",
    },
    "asset.index.1579E8B41F8D5134": "mis bienes",
    "asset.index.B3F6F05Dfbe431Cd": "simulación",
    "asset.index.B9Aeab4Ee8F33746": "Valoración activos",
    "asset.index.583Df8Bd541Eb7E8": "tendencias ganancias",
    "asset.index.B1254815D133Cc26": "7 días",
    "asset.index.2F615F6C029F01B7": "30 dias",
    "asset.index.29439Fb58E4Bbe22": "retirar",
    "asset.index.831Ab31568A78285": "completar",
    "asset.index.39F7A0D48D12E8C9": "transferir",
    "asset.index.B99063C5689Beaec": "flash",
    "asset.index.E1Dc620F2F038B2A": "Recibir activos",
    "asset.index.972A0B4C2B4393D0": "mi cuenta",
    "asset.index.9Dfb0Fb334620733": "Saldo disponible",
    "asset.index.Cdc5Fbd26790Cb47": "cantidad congelada",
    "asset.index.3D8132D72Ae965Cd": "Valuación",
    "asset.receive.825503Cc430559Cb": "adquirir activos",
    "asset.receive.54154A0553A535B7": "Activos demostración",
    "asset.receive.F2E57B00A9C5D129": "1.Este activo solo puede usarse en modo de cuenta demo",
    "asset.receive.Ea0D1D52989299Bd": "2.Para evitar abusos, sólo se permite un reembolso cada mes.",
    "asset.receive.090E0037Aa235D3D": "3.La recepción de activos agregará la cantidad de activos recibidos sobre la base original y no cambiará el estado de la posición.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Si tiene alguna otra pregunta, comuníquese con el servicio de atención al cliente.",
    "asset.receive.Df5B3A511D986575": "Obtenga fondos demostración",
    "asset.records.1Fa02E8187A39592": "últimos tres meses",
    "asset.records.7B291C37333Fe34D": "de cerca a lejos",
    "asset.records.A476Cd1Ec868Fc5F": "últimos tres meses,",
    "asset.records.9D5755A842E55Bb7": "de cerca a lejos,",
    "asset.records.E3Fa05E607203Cdc": "completar",
    "asset.records.B4F206C6Bec08Dea": "retirar",
    "asset.index.Df5B3A511D986576": "Ha recibido correctamente los activos de demostración.",
    "deposit.depositSpeedy.Efe81D0E0174239B": "método pago",
    "deposit.depositSpeedy.B9293Ee96E435F85": "moneda fiduciaria",
    "deposit.depositSpeedy.16658A855F07D171": "Cantidad",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Seguro",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "No puede estar vacío",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Recarga mínima",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Recarga máxima",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Depositar USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Recargar moneda",
    "deposit.depositWallet.B404C543712B71D5": "Red recarga",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Dirección depósito",
    "deposit.depositWallet.D3980D54Acf242Db": "Guardar código QR",
    "deposit.depositWallet.A06E84801D6582Be": "Monto recarga",
    "deposit.depositWallet.Daaaeb1B7B22B126": "pista",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Recarga exitosa",
    "deposit.depositWallet.4F48Abbafce532Bb": "Enviado exitosamente, esperando revisión de servicio al cliente",
    "deposit.depositWallet.A88C481228811696": "Te negaste a cambiar de red",
    "deposit.depositWallet.C6Ebf27895C21932": "Abra manualmente el complemento de billetera TRON e inicie sesión",
    "deposit.index.B5491C8Dd09D8183": "Selecciona la moneda que deseas recargar",
    "deposit.index.2F784B2298D39987": "Recarga rápida",
    "deposit.index.0Daa03Dcdcabd837": "Enlaces externos",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Confirmación retiro",
    "withdraw.bottomSheet.A086Fa20082C849D": "Por favor asegúrese de que la información a continuación sea correcta",
    "withdraw.bottomSheet.Bf8B184D32971501": "Red retiro",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "DIRECCIÓN",
    "withdraw.bottomSheet.F9Db93B87E08763B": "cantidad",
    "withdraw.bottomSheet.4Ad26475002878C0": "tasa tramitación",
    "withdraw.index.A96454041169Af6D": "Seleccione el tipo de moneda",
    "withdraw.index.2A563B7081Dd5B83": "dirección retiro",
    "withdraw.index.B189C2Ef713Cfff4": "Cantidad monedas retiradas",
    "withdraw.index.558F35140E84B562": "Disponible:",
    "withdraw.index.F4E759087E12Cb77": "Retirar todo",
    "withdraw.index.5Cbd9A6B284D25Ae": "Contraseña del fondo",
    "withdraw.index.B89Cbe4Bfc33Bb41": "tasa tramitación:",
    "withdraw.index.C886De8D8A27026E": "noticia importante",
    "withdraw.index.Eac7C5A4095F5606": "1.Para evitar el arbitraje, puede solicitar el retiro de moneda cuando el volumen de transacciones alcance la cuota.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.La solicitud de retiro se acreditará dentro de las 24 horas posteriores al envío. Si el retiro no se recibe después del tiempo estimado de retiro, consulte con el servicio de atención al cliente en línea.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Después de enviar la solicitud de retiro, los fondos están congelados porque el retiro está en curso y el sistema retiene los fondos temporalmente, lo que no significa que haya perdido el activo o que haya una anomalía con el activo.",
    "withdraw.index.4639F3A6E07C00B3": "Próximo paso",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Por favor ingresa o pega la dirección de tu billetera",
    "withdraw.index.25768141C8Bdea91": "Por favor ingrese el monto del retiro",
    "withdraw.index.97A85734Cd1B7Bf0": "Por favor ingrese la contraseña del fondo",
    "withdraw.index.39C7D74B33241C78": "El monto del retiro es incorrecto",
    "withdraw.index.86A5D17908197525": "Confirmación de retiro, esperando revisión para liberar monedas)",
    "withdraw.index.48Fb603C6638C7F6": "Por favor establezca una contraseña de fondo",
    "withdraw.index.873Ade9A3Bacc2F7": "pista",
    "bill.index.5Bbb2A6Ac68D6B2E": "cuenta real",
    "bill.index.75751E0Eb8261C40": "cuenta demo",
    "bill.index.Cbc853B37Ecc2E97": "Tipo de cambio de cuenta",
    "bill.index.4A0Cacddeb2E38B1": "Tipo de cuenta",
    "bill.index.F16217028E098B2F": "Divisa",
    "bill.index.A620Dbe6F06B78Ba": "Todos los tipos",
    "bill.index.2C3C148Bb94D9B3F": "Todas las cuentas",
    "c2C.index.Ab45695E4Bf1E382": "transacción C2C",
    "c2C.index.2702Dc911Dfea762": "quiero comprar",
    "c2C.index.D737D5B8367A53C4": "quiero vender",
    "c2C.index.C0C540A3Ba9Abe01": "Volumen",
    "c2C.index.D8644E8D60C8Bd8A": "precio unitario",
    "c2C.index.Ebb8670B203D3604": "límite",
    "c2C.index.F8C6245A2Ebf1D27": "Todas tarjetas bancarias",
    "c2C.index.F8C6245A2Ebf1D28": "colección moneda",
    "c2C.index.F8C6245A2Ebf1D29": "tarjeta bancaria",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Comprar",
    "c2C.index.Cc3Aa8141Da9Ec9F": "vender",
    "c2C.index.67A39834582Ad99A": "Sin datos",
    "c2C.order.04C23810B47F77F7": "por cantidad",
    "c2C.order.6905365902E0A73D": "por cantidad",
    "c2C.order.3F0Ecebbd9D2F23B": "Disponible",
    "c2C.order.16853Bda54120Bf1": "todo",
    "c2C.order.Ad3335796F2C95F7": "Suma global",
    "c2C.order.61092E27A6879318": "Para proteger la seguridad de los activos, ¡aumente su conciencia sobre la prevención!",
    "c2C.order.0F51881F0Fb920B2": "Información comercial",
    "c2C.order.Dd74Ceca85E5Bc65": "límite tiempo pago",
    "c2C.order.De3341388A59073F": "30 minutos",
    "c2C.order.D334B597A352167F": "Apodo vendedor",
    "c2C.order.D334B597A352167G": "Apodo comprador",
    "c2C.order.9D5F89F99Ced890D": "medios de transacción",
    "c2C.order.8Adf755B14Bc6B7C": "Por favor lea primero lo siguiente",
    "c2C.order.686B16F454A7338B": "No hagas ningún comentario al realizar transferencias con tarjeta bancaria, de lo contrario el dinero no se liberará y la cuenta se bloqueará directamente. Después del pago, debe proporcionar un nuevo diagrama de detalles de la transacción después del pago (si el diagrama P o los detalles de la transacción ocultos se informan a la plataforma para congelar la cuenta)",
    "c2C.order.9Bec8Fba867B739B": "Selecciona el método de pago",
    "c2C.order.Cf2D892C419Fea36": "compra mínima",
    "c2C.order.7290A6B7456A03E0": "compra más grande",
    "c2C.orderConfirm.F7E99B8C203E2B07": "detalles del pedido",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Se ha generado el pedido",
    "c2C.orderConfirm.0F5433503F3845Ce": "Paga al vendedor dentro del tiempo de cuenta regresiva.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Esperando el pago de los compradores ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "Contacte al vendedor",
    "c2C.orderConfirm.8E8140D7Ab607B25": "contactar al comprador",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "La moneda digital del vendedor ahora está depositada en la cuenta de depósito en garantía; no dude en pagar  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Nombre real del comprador:",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Atención al cliente 7x24 horas ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "número de orden",
    "c2C.orderConfirm.726C51702F70C486": "tiempo de creación",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Contactar Servicio al Cliente",
    "c2C.orderConfirm.103408Fbbe30287F": "cancelar orden",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "He confirmado el pago",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Ya pagado",
    "c2C.orderConfirm.387Ca3625B260E4E": "Tu pedido pagado",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "cancelar transacción",
    "c2C.orderConfirm.76C86566Cc3751C0": "Orden cancelada",
    "c2C.orderConfirm.205B349751D176B4": "Pago exitoso",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "método de pago",
    "c2C.paymentAdd.C87E865Df6800304": "Configuración del método pago",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Tipo método pago",
    "c2C.paymentAdd.06910Bddf002695F": "nombre real",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Cuenta Paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Número de cuenta bancaria/número de tarjeta",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Nombre moneda",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Pagar código QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Haga clic para cargar",
    "c2C.paymentAdd.6Daae340F2E9718A": "Nombre del banco",
    "c2C.paymentAdd.347751415C3Da489": "Nombre cadena",
    "c2C.paymentAdd.15B3627Faddccb1D": "Observación",
    "c2C.paymentAdd.86Da96Cbd274058F": "Recordatorio: cuando venda moneda digital, el método de pago que elija se mostrará al comprador. Confirme que la información esté completada con precisión.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "ahorrar",
    "c2C.paymentAdd.90D2A3A5C07179C0": "por favor ingresa tu nombre real",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Por favor introduzca su",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Por favor ingrese el nombre de su banco",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Por favor ingresa el nombre de tu cadena",
    "c2C.paymentAdd.94F034F0E01439E0": "Por favor ingrese su dirección",
    "c2C.paymentAdd.B12E035663A42B32": "Todas las tarjetas bancarias",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "colección moneda",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Por favor cargue el código de pago",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Agregado exitosamente",
    "c2C.paymentDetail.86Da96Cbd274058F": "Recordatorio: cuando venda moneda digital, el método de pago que elija se mostrará al comprador. Confirme que la información esté completada con precisión.",
    "c2C.paymentDetail.C4C613Da60226De4": "Modificado con éxito",
    "c2C.paymentMethod.7609F7C8337C7A17": "Método de pago C2C",
    "c2C.records.9E36893D5736Abb4": "Lista de orden",
    "c2C.records.4B53F9A5B13B3235": "Pago pendiente",
    "c2C.records.4B53F9A5B13B3236": "cancelar transacción",
    "c2C.records.4B53F9A5B13B3237": "Ya pagado",
    "contract.index.9706Df877458F0Be": "Almacén completo",
    "contract.index.623Fc7D3D40Aa479": "Margen inicial",
    "contract.index.F92Dce1169330387": "Todo plano",
    "contract.index.3598B97Dd28Dbf20": "Por favor ingrese la cantidad (piezas)",
    "contract.index.6E1Fae821Dc0B797": "Sostenible",
    "contract.index.Eb07Eb0C37C14575": "Entrega",
    "contract.index.Eb6492F19B1C1Fb6": "Detalles históricos de transacciones",
    "contract.index.873Ade9A3Bacc2F7": "pista",
    "contract.index.Ff63B0F93D39F50B": "¿Estás seguro de que tienes razón?",
    "contract.index.Ff63B0F93D39F50D": "Ve largo",
    "contract.index.Ff63B0F93D39F50E": "corto",
    "contract.index.Ff63B0F93D39F50F": "conducta",
    "contract.index.Ff63B0F93D39F50G": "¿Cerrar todas las posiciones?",
    "customer.dialog.3D410C88E8A24Fe8": "Confirmar apodo",
    "customer.dialog.4B3340F7872B411F": "Cancelar",
    "customer.dialog.549B1456Bb5F98B3": "Introduzca su apodo",
    "customer.dialog.B773B3E45Ccfad00": "El apodo está vacío.",
    "customer.index.7308C6B849D159E9": "Detalles de la imagen",
    "delivery.callDialog.C74F562C3Fe91Fe3": "ordenado exitosamente",
    "delivery.callDialog.B8C98F42Caa15Ca5": "dirección",
    "delivery.callDialog.B79A581Dae543974": "compra larga",
    "delivery.callDialog.B79A581Dae543975": "compra corta",
    "delivery.callDialog.3B26F0F7E90773F3": "Precio de apertura",
    "delivery.callDialog.65Fb252Bd94E1039": "Ingresos estimados",
    "delivery.index.B483Ba4Fce633539": "Entrega",
    "delivery.index.224Dd6Cb58D58249": "periodo de negociación",
    "delivery.index.28158F517E902788": "Compra mínima",
    "delivery.index.A1232162B14Bec76": "Orden entrega",
    "delivery.index.445B1D3176D9C69D": "Órdenes históricas",
    "delivery.index.Ab6Cb86E28653C5C": "Entrega",
    "delivery.index.20A7A6F14D3698C0": "Hora de apertura",
    "delivery.index.Ec6821A49395Ddc2": "hora de cierre",
    "delivery.index.254Fdc665C391743": "Precio de cierre",
    "delivery.index.2A0C5E68B44A472A": "Ganancia y perdida",
    "delivery.index.E05B33309F6D236A": "Por favor ingrese la cantidad",
    "delivery.index.B2899E073Cb355D9": "Compra mínima",
    "download.description.869467A93C7Dd4A1": "Notas de instalación",
    "download.description.C80C1Edd6Bee12Bc": "1.Permitir que se descarguen archivos de descripción",
    "download.description.2F5Bc180F92642B4": "2.Archivo de descripción descargado",
    "download.description.3C3B849F6Ccf712F": "3.Ingrese a [Configuración] en su teléfono y el archivo de descripción descargado se mostrará debajo de la información de la cuenta.",
    "download.description.86Affd862985E045": "4.Haga clic para descargar el archivo de descripción",
    "download.description.32832492Bfc1D95E": "5.Haga clic para instalar",
    "download.index.245883C80F181C4A": "descargar",
    "download.index.Ef3De8Cb2722937A": "Comience a operar en cualquier momento y en cualquier lugar",
    "download.index.F4A87Eaeed691638": "Mantente al día de las últimas novedades a través de nuestra APP",
    "download.index.Ad3Aba52D0D39D01": "Descargue el paquete de instalación",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Consejos amables",
    "download.safariGuide.2Cc68Aaadf623477": "Por favor visita en Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Está visitando un sitio web descentralizado, ejecútelo en la aplicación Safari.",
    "download.safariGuide.06Da016F3E2F79Bd": "copiar link",
    "finance.index.B034B367545D5236": "Proyectos globales de alta calidad.",
    "finance.index.6Ebfac2C0A9228B0": "Más de 1,6 millones de usuarios activos, 122 400 millones de dólares",
    "finance.index.52Bdcaf19659363D": "Minería de liquidez",
    "finance.index.D66924272B031Bf5": "Ingresos anualizados súper altos",
    "finance.index.34789D88Afcefa64": "Ver artículos",
    "finance.index.E12676B95654D21E": "préstamo de crédito",
    "finance.index.4C924Cfd89A11Ac7": "Descubra más oportunidades comerciales",
    "finance.index.C251D4B594Da2848": "Cuantificación de IA",
    "finance.index.4E53Cf2E5F173Dd3": "Arrendamiento con un clic, transacción inteligente con IA",
    "finance.records.Cce168301447D1Ba": "en curso",
    "finance.records.4587B6Bd7Cfa4454": "redimido",
    "finance.records.18685418E3A50147": "Minería",
    "finance.records.Df9Fd438F97Cc908": "cielo",
    "finance.records.E23Cb03C5Ad359E5": "el término",
    "finance.records.Bd87Dac6Cba7Eba8": "Cantidad compra",
    "finance.records.2D0Fff99265E8B1A": "Rentable",
    "finance.records.2D0Fff99265E8B1B": "Beneficio vencimiento",
    "finance.records.024F5F0B851782De": "Tiempo minería:",
    "finance.records.3143A26D3F983896": "Tiempo canje:",
    "finance.records.96574Aaf8Db85Ead": "Redención temprana",
    "finance.records.531E1829203181Ac": "¿Estás seguro de que quieres canjear anticipadamente?;",
    "finance.records.9205843B8923Dac3": "Redención exitosa;",
    "loan.applyCallback.B9258Ec8746A74D8": "canal endeudamiento",
    "loan.applyForm.7B8Ed340400A6E2B": "nombre",
    "loan.applyForm.549A44Bae725F4A2": "apellido",
    "loan.applyForm.2A27E61375E7Fea8": "fecha nacimiento",
    "loan.applyForm.89925B019D537738": "dirección correo electrónico",
    "loan.applyForm.Afaeb32Cb4D62E28": "Dirección de casa (incluido el número de unidad)",
    "loan.applyForm.E05B5D049B64B040": "Ciudad",
    "loan.applyForm.B166E4E8Fe9513Fa": "nación",
    "loan.applyForm.83Aaede109E395F8": "Código Postal",
    "loan.applyForm.647Ed21332A4968B": "tus ingresos mensuales",
    "loan.applyForm.Cf0A9B845D34D4B4": "Ingresos adicionales (opcional)",
    "loan.applyForm.7048F618Acc6A54C": "Pago mensual de alquiler o hipoteca",
    "loan.applyForm.15C1Bba18Ae18545": "Cuéntanos si tienes una hipoteca",
    "loan.applyForm.3921E90625A84E36": "Si no se selecciona ninguna garantía hipotecaria, la probabilidad de pasar la revisión es baja.",
    "loan.applyForm.262Eec4F8906B0F6": "¿Existe una hipoteca?",
    "loan.applyForm.8634Eb21B6910F21": "Por favor seleccione el tipo de hipoteca",
    "loan.applyForm.A4897B3A331B5E61": "Bono de transacción",
    "loan.applyForm.6623384C683De457": "Mantengámonos en contacto",
    "loan.applyForm.A8360Bdba86834Cb": "Podemos comunicarnos con usted a este número si necesitamos más información sobre su solicitud de préstamo.",
    "loan.applyForm.C1C373F385A4C7B3": "número teléfono",
    "loan.applyForm.1B9785Badb91191F": "Después de completar la información, espere la evaluación y revisión del sistema.",
    "loan.applyForm.8755B9C761D4080C": "He leído y firmado electrónicamente mi consentimiento",
    "loan.applyForm.3E987B787468148C": "Términos de uso y consentimiento electrónico",
    "loan.applyForm.Bc54E76B61C50Fb9": "Por favor lea y acepte el acuerdo.",
    "loan.applyForm.89E158384F634Eb8": "Volver al paso anterior",
    "loan.applyForm.Efa5A4548E2F3510": "Direccion casa",
    "loan.applyForm.4Cadf1787541B35D": "Sí,",
    "loan.applyForm.Fab408D2F4100447": "No,",
    "loan.applyForm.3Bad7A307480Cc56": "El formato del correo electrónico es incorrecto.;",
    "loan.applyForm.306322F49B36855D": "La solicitud ha sido enviada, espere la revisión del servicio de atención al cliente.",
    "loan.applyForm.3C6E0E858C995Ff9": "Por favor cargue el comprobante de transacción",
    "loan.applyForm.2Bf443476B271376": "Se pueden subir hasta 4 fotos.",
    "loan.applyQuota.Ef1D8E23003B9A6F": "canal de endeudamiento",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Obtener límite préstamo",
    "loan.applyQuota.9E0780F9352C7273": "saldo billetera",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Monto total del préstamo disponible",
    "loan.applyQuota.033A05462195B080": "Monto prestado",
    "loan.applyQuota.9Aa89B036Ef4821D": "balance restante",
    "loan.applyQuota.5656Fb52Ef17A557": "Obtener límite préstamo",
    "loan.applyQuota.6Ff6702B4C917D40": "bajo revisión",
    "loan.applyQuota.6Ff6702B4C917D41": "rechazado",
    "loan.applyQuota.6Ff6702B4C917D42": "tuvo éxito",
    "loan.applyQuota.0Bad0E857Ea4A952": "tiempo de aplicación",
    "loan.applyQuota.3E7E56E2De2Cc546": "Monto solicitud",
    "loan.applyQuota.8F1D170D9E54Af5B": "Progreso solicitud",
    "loan.applyQuota.6167Ca035B93Caf3": "razón",
    "loan.applyQuota.410C7954F9A3981D": "Aplicar nuevo",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Por favor seleccione moneda",
    "loan.index.4C10307B22E885C3": "Descubra más oportunidades comerciales y cree riqueza",
    "loan.index.Ae8D37Dea2B3717B": "tasa de interés",
    "loan.index.1Bb54625682E85Db": "Introducción a la regla",
    "loan.index.5Bd31Fab681C6E9D": "ver más",
    "loan.index.111F95F404814327": "Moneda del préstamo",
    "loan.index.6Bc2723B3Bec106D": "préstamo",
    "loan.index.3200106280E6E370": "tasa de interés",
    "loan.index.049A6Ccb6C81A635": "Monto del préstamo (mín./máx.)",
    "loan.productDetails.5037Ad86Ab4B7E02": "préstamo",
    "loan.productDetails.37181C74559Db182": "Orden ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquidez",
    "loan.productDetails.89Aea9Cf9337C40F": "Cantidad disponible",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Cantidad a reembolsar",
    "loan.productDetails.C65F329Ef14C4A3D": "Cantidad  monedas prestadas",
    "loan.productDetails.3A74667815Adc8Bf": "máximo",
    "loan.productDetails.Ab851D8351573227": "cielo",
    "loan.productDetails.Ddf82D8A2C8F46A0": "ciclo pago",
    "loan.productDetails.C105E5562005Aeb1": "cielo",
    "loan.productDetails.3F346Be2137F0756": "Monto del préstamo (mín./máx.)",
    "loan.productDetails.2707F78Df864E194": "Interés",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Pague a tiempo. Si está vencido, se cobrará una cantidad diaria adicional de (principal + intereses).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% es tarifa vencida",
    "loan.productDetails.4D94Faa033Be7356": "He acordado",
    "loan.productDetails.62429A957A22780A": "Acuerdo de servicio de préstamo de monedas",
    "loan.productDetails.Bc54E76B61C50Fb9": "Por favor lea y acepte el acuerdo.",
    "loan.productDetails.Ec8Ba071C53A67Cb": "pedir prestado ahora",
    "loan.productDetails.59246A0Bf081D398": "el menos",
    "loan.productDetails.Ec3F1172B71Cd36C": "monto minimo",
    "loan.productDetails.7Ccc4Cf1D81930B3": "mayor cantidad",
    "loan.productDetails.071488598990Aa68": "Por favor lea y acepte el acuerdo.;",
    "loan.productDetails.579Dd216D3339119": "Préstamo exitoso;",
    "loan.records.4951B36Fd0D2A831": "Historial de préstamos y crédito.",
    "loan.records.Bb0Aaa30514B7Cba": "registro actual",
    "loan.records.0F8B0A7D80769051": "Historial pagos",
    "loan.records.D4B42E43D88De6F1": "registro  historia",
    "loan.records.Cb1847D6281897E6": "Monto del préstamo",
    "loan.records.C7Bfcacfc2Adea58": "Monto de reembolso",
    "loan.records.8B369164D5Fea340": "Tiempo de préstamo:",
    "loan.records.Ab2402Ad3Ffd35E7": "Regresar temprano",
    "loan.records.B7A0Ca0C195D0Cd1": "Tiempo de liberación:",
    "loan.records.42014Bdcfd9C411B": "Tiempo de amortización:",
    "loan.records.A0Acfaee54F06458": "terminado",
    "loan.records.67A39834582Ad99A": "Sin datos",
    "loan.records.6A687Ff5B8F1598F": "¿Estás seguro de que quieres pagar por adelantado?",
    "loan.records.7C20D7008D0Eae86": "Pago exitoso",
    "mining.bottomSheet.Ba91637D32Ccba04": "Minería de liquidez",
    "mining.bottomSheet.7De3D636A165E008": "tasa de retorno",
    "mining.bottomSheet.F3003345B189D683": "Periodo minero",
    "mining.bottomSheet.3E4C0A8C533F5848": "tipo de moneda",
    "mining.bottomSheet.Adcba368993Ae65E": "Importe de la suscripción (mínimo/máximo)",
    "mining.bottomSheet.94743B24E4Ed4166": "Ilimitado",
    "mining.bottomSheet.286944Fedd211257": "Fondos disponibles",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Ingresos estimados:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Mio ahora",
    "mining.bottomSheet.Bb1493C567C114Dd": "Consejos amables:",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Comisión de reembolso anticipado",
    "mining.index.8B2Bba1422Eed6F0": "Crear decenas de miles de millones de riqueza, 100% ingresos netos",
    "mining.index.42997E300086Ee89": "mayor retorno",
    "mining.index.510F74F42052Ecce": "Productos mineros",
    "mining.index.B853F96626106912": "ir a minar",
    "mining.productList.37181C74559Db182": "Orden",
    "mining.productList.Aac5B810004C46C8": "regular",
    "mining.productList.D2Fb4F78C387D888": "Has minado exitosamente",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Confirmar pedido",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Monto del alquiler",
    "quantify.bottomSheet.Fbd931286Be387Aa": "ciclo",
    "quantify.bottomSheet.9Ad84Db481807844": "indefinidamente",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Tasa de rendimiento diaria",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "fecha valor",
    "quantify.bottomSheet.88008603Ba7Bde64": "Compra exitosa",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "¡Obtenga su participación en los ingresos a partir de hoy!",
    "quantify.bottomSheet.C778A674C4A054F9": "revisar orden",
    "quantify.bottomSheet.685Dad322B7029B0": "Transacción de devolución",
    "quantify.buyForm.Acf8560A04334E95": "precio de compra",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Descripción general",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "tiempo de pago de dividendos",
    "quantify.buyForm.Fcda03Adbc5Fca97": "cada día",
    "quantify.buyForm.Afb89504Fc399189": "Por favor ingrese la cantidad de compra",
    "quantify.buyForm.1435Afdfb4Ec0596": "Cantidad mínima de compra",
    "quantify.buyForm.1B97F1730Bacd839": "Cantidad máxima de compra",
    "quantify.index.32F56C9B7D0E250A": "Periodo: indefinido",
    "quantify.index.E34F028D00904630": "precio",
    "quantify.index.E34F028D00904631": ",Tasa de rendimiento diaria",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Monto del depósito en garantía",
    "quantify.productDetail.53635B53Bca31Dfa": "Ingreso diario estimado",
    "quantify.productDetail.F9E46449A6269E09": "Ingresos acumulados",
    "quantify.productDetail.98Fc46B1B8C993B2": "Alojamiento de pedidos",
    "quantify.productDetail.93624Bb045830966": "Alquila ahora",
    "quantify.records.0C16A18C32B9Cf4A": "Registro de pedido",
    "quantify.records.B191F5614B0E2312": "Historial de compras",
    "quantify.records.6A06033B86A73F7C": "registro de redención",
    "quantify.records.45E0F7E71611618E": "Ingresos acumulados",
    "quantify.records.45E0F7E71611618F": "Beneficio al vencimiento",
    "quantify.records.Aae1201E38C4Baaa": "Correr",
    "flash.index.C41Fd4F2Fdfa594B": "consumir",
    "flash.index.1Ac59Fa2218E3B00": "Disponible:",
    "flash.index.Bf7233A660821549": "conseguir",
    "flash.index.548E775Dd67C7472": "Confirmar cambio",
    "flash.index.066A2449Bd616F03": "Por favor ingrese la cantidad de canje",
    "flash.index.Fca26Fcf9C06563F": "Canje flash exitoso",
    "message.index.40031524233F4564": "Centro de mensajes",
    "message.index.8E5586210Fd44D69": "Recordatorio de posición perpetua",
    "message.index.Bcd3F470E88C197D": "Aún no hay notificación",
    "message.index.23A557Fbce545879": "Aviso de liquidación permanente",
    "message.index.B5E19B36B5125550": "recordatorio de retiro",
    "message.index.716A981465138334": "Recordatorio de recarga",
    "message.index.884474Ebff2977Ba": "información del sistema",
    "message.index.08Cc7D24752Ec42C": "Servicio en línea",
    "message.index.1D820A6A19Ab025D": "Servicio manual en línea 7 x 24 horas",
    "more.index.29881E0B5Df58Bf7": "Más características",
    "more.index.C77Ee35911D10D9D": "Funciones comunes",
    "more.index.3558F69B0260Be61": "Función comercial",
    "more.index.7243Fe610Ed98Cec": "cuenta real",
    "more.index.7243Fe610Ed98Ced": "cuenta demo",
    "more.index.693C7B35Bdf3E3A3": "finanzas",
    "more.index.F855997Fac114554": "Centro de ayuda",
    "more.index.Ae9A1252278Da742": "ayuda",
    "more.index.Ace012504Bc529E0": "información",
    "more.index.05682Dbef5Eeb0A8": "Servicio al Cliente",
    "more.index.C7Af227A5D5C7Bb9": "Has cambiado a una cuenta real",
    "more.index.D818A65592694786": "Has cambiado a una cuenta demo",
    "notice.details.B360807Dbe727E6E": "sobre nosotros",
    "notice.details.92F2552F695E70E3": "sobre nosotros",
    "notice.details.7Aaceaf69B67F3D1": "Introducción a la regla",
    "notice.index.6128438Be34Bcb9F": "Aviso perpetuo",
    "notice.index.71627F5Eb7128261": "Notificación retiro",
    "notice.index.2Dce570Fda841705": "Notificación recarga",
    "notice.index.228A7C9C6448A9B1": "notificación del sistema",
    "setting.dialog.8B4F5B74660Deabe": "Configuración de colores",
    "setting.dialog.Bfc7D25486Ebc1A9": "El verde sube y el rojo cae",
    "setting.dialog.796Ea0E1A82C320B": "El rojo sube y el verde cae",
    "setting.index.8Ddc5864E1739466": "Preferencias",
    "setting.index.F5205801D57D6727": "idioma",
    "setting.index.2E2C12Dd0Cc1B63B": "Moneda de cotización",
    "setting.index.322D8496Aec3D918": "número de versión",
    "setting.index.Aa7E6C204C751Cfa": "desconectar",
    "share.index.75Bc38Bbf7D2E766": "invitar a amigos",
    "share.index.45575Eb5D42213E6": "mi código de invitación",
    "share.index.29F67F0644A78Bf3": "Copiar",
    "share.index.19Cc65Bb6Bb98Fe7": "mi enlace de invitación",
    "share.index.5372712F162Fb78B": "Número de subordinados directos",
    "share.index.C88D6B95E3Ab7D6F": "Número total de subordinados",
    "share.index.Bb5Fa5471Aeecc62": "Reembolso de comisión para subordinados directos",
    "share.index.426B6Bac9A5F3D4F": "reembolso total",
    "share.index.197E4A428F0401C9": "Ver registros de reembolso",
    "share.records.88A6B2E2B74B13C4": "Registro de comisiones",
    "share.records.B671A35Ffdc93B89": "Reembolso",
    "transfer.index.B17617A869C03Ca5": "billetera",
    "transfer.index.E4116Dcc6F6C7270": "Transferencia exitosa",
    "transfer.index.48Fb603C6638C7F6": "Por favor establezca una contraseña de fondo",
    "transfer.records.Eb0E39Eb5911D418": "conseguir",
    "transfer.records.977554Be0Aa65052": "Tiempo de canje flash:",
    "transfer.records.9Aa33884Aa400B7C": "Tiempo de transferencia:",
    "form1.forget.76636Db8Fae5B124": "tu buzón",
    "form1.forget.35354B9Cca1Bb0E6": "Código de verificación de correo electrónico",
    "form1.forget.604A0B11E916F387": "contraseña",
    "form1.forget.9Cb1C9C51E7503D6": "confirmar Contraseña",
    "form1.forget.3A4F7E04B008453C": "Su número de teléfono",
    "form1.forget.Cf11Dce5B4713B5A": "Código de verificación del teléfono móvil",
    "form1.forget.3012Edba57D4F297": "Por favor introduzca su correo electrónico",
    "form1.forget.F1Ee1D97E869E447": "por favor ingrese el código de verificación",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Por favor, introduzca su contraseña",
    "form1.forget.C6Cb174Da411D5F7": "Por favor introduce tu teléfono móvil",
    "form1.forget.947B3B21Ee45E582": "Por favor introduce tu teléfono móvil",
    "form1.forget.3E0580F1E8630Df6": "Dos contraseñas son inconsistentes",
    "form1.forget.C5A61836B8Cc74C6": "Cambio de contraseña redirigido exitosamente...",
    "form1.forget.B96D072Bc8962565": "Reenviar",
    "form1.login.2B72C27Ff828Cfc4": "tu contraseña",
    "form1.login.D7C6B872Af9Cb17F": "recordar mi contraseña",
    "form1.login.0Fff44C827A4F3B6": "Acceso",
    "form1.login.1B7F2C1Ede3990Cc": "¿Sin cuenta? Regístrate ahora",
    "form1.login.F657F7Be1Cc33Bd2": "olvida la contraseña",
    "form1.login.2074A1Eec4B088B0": "Inicio de sesión de billetera",
    "form1.login.1B8938Fbcc3C9B56": "Iniciar sesión correctamente y redirigir...",
    "form1.login.07162A7Bb9310555": "Instale la billetera ERC20",
    "form1.login.766969966Ae151Dc": "Instale la billetera TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Te negaste a cambiar de red",
    "form1.register.Aaef0710Cb4Db554": "Ingrese de nuevo la contraseña",
    "form1.register.54Dfea0415C708Ff": "Código de invitación",
    "form1.register.6839940Dac08C794": "registro",
    "form1.register.9901A3B5550Aa58E": "¿Ya tienes una cuenta? Acceso",
    "form1.register.2548Ca53785252Ea": "Por favor ingresa tu código de invitación",
    "form1.register.Dd71Fd65900Dd03E": "Lea el Acuerdo de servicio de registro y acepte,",
    "form1.register.7975613909490B77": "Registro exitoso y redireccionamiento...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Elige billetera",
    "userc.certification.2Af9511640153D2E": "Certificación Júnior",
    "userc.certification.177B191D8E0Fa84D": "no certificado",
    "userc.certification.601218D4D572E98A": "verificado",
    "userc.certification.6E37C51Ba9012Fc8": "No pasó",
    "userc.certification.1B9B563E05C96474": "Certificación avanzada",
    "userc.certification.1418B83803Ab56B4": "Primero complete la certificación primaria.",
    "userc.creditScore.0B149112601Acc4D": "puntuación de crédito",
    "userc.creditScore.88Ac7F84E106C5F6": "puntuación de crédito total",
    "userc.creditScore.3E1D6E0E7F1E6155": "Evaluación del sistema",
    "userc.index.62Eac58D04Bbca0F": "Evaluación del sistema",
    "userc.index.F4Cac53F93Cb2Aff": "comercio simulado",
    "userc.index.42F409Fdf3559773": "Centro de Seguridad",
    "userc.index.1B8E211009807Db8": "Autenticación",
    "userc.index.Bdfcf21Eb8F2Eb70": "Registros de cambios de cuenta",
    "userc.index.6C18D67F6D19586B": "Introducción a la plataforma",
    "userc.index.807A814Ed58A93Bc": "limpiar cache",
    "userc.index.E43Daad59759611C": "no certificado",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Cambiar contraseña de inicio de sesión",
    "userc.secureCenter.1F37Ba427Debf24A": "Vinculación de cuenta",
    "userc.secureCenter.F9A5Ffa750664004": "Vincular el Autenticador de Google",
    "userb.certificationForm.7685Af7D30043Cd6": "Certificación Junior",
    "userb.certificationForm.7685Af7D30043Cd7": "Certificación avanzada",
    "userb.certificationForm.70E37C35Abc3F462": "País",
    "userb.certificationForm.F92C25A68E4D08C5": "Ciudad",
    "userb.certificationForm.0Ccc6387B38E0318": "Su nombre",
    "userb.certificationForm.94C67Bba370Fdd46": "número de licencia",
    "userb.certificationForm.B2D4C7Fffe79258E": "Correo",
    "userb.certificationForm.57554268761Fb47A": "Sube el frente de tu identificación",
    "userb.certificationForm.D79E90201F5319Bd": "Sube el reverso de tu DNI",
    "userb.certificationForm.9C6Bad08Af29E20E": "Sube una foto de tu DNI",
    "userb.certificationForm.F96B62Dfa31Cde45": "Confirmar envío",
    "userb.certificationForm.2125D78Ea8C6D287": "Requisitos de carga",
    "userb.certificationForm.0D64864D3076A824": "Ver ejemplo",
    "userb.certificationForm.35287Deb72281E87": "1.Debe poder leer claramente la información de la tarjeta de identificación.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.El contenido de la foto es verdadero y válido y no se permite ninguna modificación.",
    "userb.certificationForm.25A90660554319F9": "3.El número de identificación y el nombre deben ser claramente visibles y ser compatibles con JPG/JPEG/PNG.",
    "userb.certificationForm.5A9Aefbc03C778F7": "por favor escribe",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Envío exitoso, espere la revisión",
    "userb.certificationForm.744Bd07D8Abd2513": "Por favor cargue el frente de su identificación",
    "userb.certificationForm.6De1C319130F7Fef": "Por favor cargue el reverso de su identificación",
    "userb.certificationForm.79F8E8B172E30C8A": "Por favor sube una foto de tu documento de identidad.",
    "userb.certificationForm.46412E16D28A4753": "Error al subir la foto",
    "userb.changeDialog.676B0Adca1F8Eea4": "cambiar la contraseña",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "número de teléfono",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "interruptor validador",
    "userb.modifyInfoDialog.62149E566814154C": "Seleccionar validador",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Abra la aplicación de autenticación y haga clic+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Seleccione 'Escanear código QR' o 'Introducir clave''",
    "userb.modifyInfoDialog.93F094E2B4369472": "Escanea el código QR o introduce la clave",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "cerrar/abrir",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Contraseña de fondo antigua",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Nueva contraseña de fondo",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Confirmar nueva contraseña del fondo",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Confirmar contraseña del fondo",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Por favor ingrese su antigua contraseña de fondo",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Por favor ingrese su nueva contraseña de fondo",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Por favor ingrese la contraseña de su fondo",
    "userb.modifyInfoDialog.258A534498Fe984F": "Por favor confirme la contraseña de su fondo",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Vinculación de cuenta",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Contraseña del fondo",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "confirmar",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "El validador está cerrado",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "La verificación de Google se activó correctamente",
    "userb.modifyInfoDialog.24Dba26593F43C74": "La contraseña del fondo se modificó con éxito",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Establecer contraseña de fondo exitosamente",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Vinculación exitosa",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Error del código de verificación",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Ejemplo de carga de certificado",
    "userb.veBottomSheet.6F38A0D5858633Ee": "fondo oscuro y limpio",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Iluminación suficiente",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Marco auxiliar de alineación",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Las fotos son claras.",
    "userb.veBottomSheet.50B741382E71F103": "Posibles razones del fracaso.",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "La cubierta protectora del documento de identidad no se ha retirado",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "El fondo se ve afectado por el desorden.",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "La tarjeta de identificación es reflectante.",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "La luz es demasiado oscura/obstruida por la sombra del teléfono móvil.",
    "userb.veBottomSheet.1A75D2Fc2614592B": "sosteniendo tarjeta de identificación",
    "home.home.C3525Eb110Db58Aa": "Más",
    "home.home.29761532Fc8Ebecc": "comercio",
    "home.home.F15Ea99Bfa6E1848": "Certificación de seguridad, compre con confianza",
    "home.home.46E1Ab39Bae7182A": "Rápido",
    "home.home.Fde2Eb7F6F3Dd800": "Transacciones rápidas y operación simple",
    "home.home.Ea4756Bc1642E0F1": "nombre",
    "home.home.B6B1127Ede1C97B4": "Último precio",
    "home.home.3587Ad870Eb0Ab86": "24H Change",
    "home.home.Cb1A4E14Ec2Cd2B5": "Descubrir",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "metales",
    "home.home.5Fc1474Dbf74Ac1A": "Agrícolas",
    "home.home.135318F4664F7773": "energía",
    "kline.kLine.C96Ff250Cf6B8B84": "24H High",
    "kline.kLine.6E8Be64F19D74565": "24H Volumen",
    "kline.kLine.08A4Bfbe26A0F262": "24H Low",
    "kline.kLine.8701Deb075066331": "24H Volumen(USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 M",
    "kline.kLine.B49Cca240E013124": "5 M",
    "kline.kLine.F27A90Be97Ba3D6C": "15 M",
    "kline.kLine.Ff3E62395B694384": "30 M",
    "kline.kLine.C1993F9C6Db1A674": "1 hora",
    "kline.kLine.Fd2Cbbae405E284C": "1 día",
    "kline.kLine.C9Cd71904395042C": "Enero",
    "kline.kLine.7D8127Ec18C7B8C7": "Comprar",
    "kline.kLine.E3D3Ffdb9B3E5636": "Cantidad (piezas)",
    "kline.kLine.6834Fec1838D8029": "Precio (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "vender orden",
    "kline.kLine.B9Bea1A9A88D1419": "Largo",
    "kline.kLine.D36Cfa96Ee2B343B": "Abrir corto",
    "other.languageRateSelect.6897784800834C1B": "configuración de idioma",
    "other.languageRateSelect.6897784800834C1C": "Seleccione la moneda del precio",
    "other.navigationBar.F6E62F9642018Cbb": "Inicio",
    "other.navigationBar.0Ec227161A47B49D": "Citas",
    "other.navigationBar.29761532Fc8Ebecc": "comercio",
    "other.navigationBar.693C7B35Bdf3E3A3": "finanzas",
    "other.navigationBar.9734C54620Eb09Af": "activos",

    "home.index.25Aa4Fc745150030": "Rey de las criptomonedas",
    "home.index.2D6Ecf8Faaa8B124": "Más rápido, mejor, más fuerte",
    "home.index.1Db26267D1041223": "Opere fácilmente con más de 1700 criptomonedas",
    "home.index.F8E18C90C3E35618": "Regístrate ahora",
    "home.index.29761532Fc8Ebecc": "comercio",
    "home.index.D8A95E4506487452": "Contratos populares",
    "home.index.5Bd31Fab681C6E9D": "ver más",
    "home.index.010Ae07A09Caf221": "par comercial",
    "home.index.F4D875118E6D6Dbb": "Último precio",
    "home.index.3Dfdfb3D6599100E": "24H arriba abajo",
    "home.index.28Fb39Bd41E54242": "tendencia",
    "home.index.B47A3911Bacd94C6": "Lista de ganadores",
    "home.index.712910D574F43D95": "Ingresos estables",
    "home.index.5507Fd830D7Bf51F": "Las mejores estrategias, fáciles de copiar",
    "home.index.7De3D636A165E008": "tasa de retorno",
    "home.index.B853F96626106912": "ir a minar",
    "home.index.213B5C96Be7Cd1F8": "días, tasa de interés anualizada",
    "home.index.213B5C96Be7Cd1F9": "Máximo comprable",
    "home.index.Dc710Cffe6313Bb5": "comprobar los detalles",
    "home.index.B92Ffc837Ecaa1Dd": "noticias",
    "home.index.6C18D67F6D19586B": "Introducción a la plataforma",
    "home.index.6342E3C33Cb48Cd5": "La moneda digital es un medio de intercambio que utiliza principios criptográficos para garantizar la seguridad de las transacciones. A diferencia de las monedas fiduciarias como el dólar estadounidense, las monedas digitales no tienen forma física. Las monedas digitales populares como Bitcoin (BTC), Ethereum (ETH) y Polkadot (DOT) utilizan blockchain como tecnología subyacente para actuar como un libro de contabilidad digital descentralizado. Todas las transacciones de moneda digital se registran en la cadena de bloques y no se pueden cambiar una vez confirmadas y verificadas. A diferencia de las monedas tradicionales, donde los bancos mantienen libros de contabilidad centralizados, las transacciones de moneda digital se realizan en cadenas de bloques públicas a las que cualquiera puede acceder.",
    "home.index.198Efc4254683Eef": "Además, según el mecanismo de consenso, todos pueden verificar las transacciones de moneda digital y agregarlas a la cadena de bloques, logrando así la descentralización. A medida que han invertido empresas líderes a nivel mundial como Samsung, BlackRock, Morgan Stanley y Alphabet, la gente cree que la tecnología blockchain cambiará por completo el sistema financiero global. La moneda digital le abre la puerta al mundo de las finanzas descentralizadas, permitiéndole explorar las infinitas posibilidades que ofrece la tecnología de vanguardia.",
    "home.index.E710339D9576292E": "Nuestra ventaja",
    "home.index.F9Ce8B2C63885B26": "seguro y estable",
    "home.index.C4D0A2C9Ff0305Ce": "El equipo técnico superior desarrolla de forma independiente un motor de síntesis de transacciones de alta velocidad, que aún puede funcionar de manera estable y confiable en transacciones simultáneas masivas.",
    "home.index.2E8708Ee3F11Cc1A": "Profesional y confiable",
    "home.index.Bb12Cbcf89C56C5C": "El equipo de operaciones profesional, con muchos años de experiencia en blockchain y finanzas, posee una licencia de comercio de activos digitales compatible y tiene una garantía de reserva del 100%.",
    "home.index.Aa4723Bfbf6808F4": "Servir con corazón",
    "home.index.1Bf0204B7D3Ee230": "Frente al mercado global, compatible con varios idiomas, funcionando las 24 horas del día, los 7 días de la semana, un sólido soporte comunitario y un servicio al cliente profesional.",
    "home.index.2E7Dfd53D26F7224": "alto rendimiento",
    "home.index.1C65453103B5Fcc3": "300.000 transacciones por segundo y tiempo de respuesta de pedidos inferior a 1 milisegundo.",
    "home.index.85Ac49Ece9Dcc80B": "¡Opere en cualquier momento y en cualquier lugar!",
    "home.index.2799E18842E4Ebe9": "¡Abra una posición al instante, respaldada tanto por la aplicación OKEX como por la página web!",
    "home.index.2Bf52Ce75E29Fc88": "Escanea el código para descargar ahora",
    "home.index.88D1422B33571066": "Androide",
    "home.index.04Ee893775563F21": "IOS",
    "home.index.04Ee893775563F22": "servicio de producto",
    "home.index.04Ee893775563F23": "gestión financiera",
    "home.index.04Ee893775563F24": "devolver",
    "home.index.04Ee893775563F26": "IOS & Androide",
    "homepage.text1": "Compra Bitcoin, Ethereum y",
	"homepage.text1_2": "más de 1000 criptomonedas",
	"homepage.text2": "Tu experiencia en criptomonedas",
	"homepage.text4": "Gestión financiera de alto rendimiento",
	"homepage.text5": "Gestión financiera estable, altos rendimientos anuales",
	"homepage.text6": "Recompensas compartidas",
	"homepage.text7": "Transacciones rápidas, operación sencilla",
	"homepage.text8": "Ver más",
	"homepage.text9": "Intercambio instantáneo",
	"homepage.text10": "Certificación de seguridad, compra con confianza",
	"homepage.text11": "Equipo técnico de vanguardia, seguridad integral",
	"homepage.text12": "Protección, independencia",
	"homepage.text13": "Profesional y confiable",
	"homepage.text14": "Equipo de operaciones profesional con años de experiencia en blockchain y finanzas",
	"homepage.text15": "Servicio dedicado",
	"homepage.text16": "Orientado al mercado global, soporte multilingüe, operación 24/7",
	"homepage.text17": "Alto rendimiento",
	"homepage.text18": "Procesamiento de hasta 300,000 transacciones por segundo, tiempo de respuesta del pedido inferior a 1 milisegundo",
    "passwod.limit":"La longitud de la contraseña debe ser mayor o igual a 6 caracteres"
}
