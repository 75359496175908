import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "simplified Chinese",
        dialogDetermine: "recognize",
        dreivatives: "Futures",
        markets: "Market",
        assets: "Asset",
        liquidity: "Liquidity mining",
        helpCentre: "Help Center",
        announcementCenter: "Announcement Center",
        about: "With respect to",
        bulletinCentre: "Announcement Center",
        serviceAgreement: "Service agreement",
        support: "Support",
        onlineSupportCustomerService: "Online Customer Service",
        suggestionsAndFeedback: "Suggestions and feedback",
        service: "service",
        download: "Download",
        coinApplication: "Coin application",
        privacyStatement: "Privacy Statement",
        trade: "contract trading",
        standardConteact: "Perpetual Contracts",
        signOut: "Log out",
        assetWallets: "Asset Wallet",
        financeRecords: "Carrying change records",
        identification: "authenticate",
        personalCenter: "Personal center",
        chooseLanguage: "Select Language",
        tips: "Draw attention to sth.",
        dark: "Night Mode",
        light: "Daylight mode",
        languageTitle: "Language",
        convertCurrency: "Currency of conversion",
        cancel: "Abolish",
        aboutUs: "About Us",
        standard: "selfish sustainability",
        share: "Share",
        introduce: "Platform Introduction",
        set: "set up",
        changePassword: "Change Login Password",
        denomination: "Denomination currency",
        color: "Color Configuration",
        code: "Version number",
        color1: "lit. red rises, green falls",
        color2: "lit. green rises and red falls",
        setLangue: "Setting the language",
        vip: "VIP rights and benefits",
        vip2: "Current VIP Level",
        safeMenu: "security center",
        conventional: "conventional",
        other: "other",
        quickTips: "Add up to 3 shortcut function buttons",
        quickTransaction: "Quick transaction",
        safeConvenient: "Safe and convenient",
        timeSharing: "Timeframe",
        currentLevel: "current level",
        levelBenefits: "%{level}level income",
        teamLevel: "Team level",
        levelLower: "%{level}subordinate",
        rules: "Rule description",
        bindWithdrawalAddress: "Bind withdrawal address",
        tipsBindWithdrawalAddress: "You have not bound your withdrawal address yet，Please bind first",
        walletAddressFormatIncorrect: "Wallet address format is incorrect",
        tradeRules1: "Predict the rise and fall in the future。The price at the time of placing the order is the starting price，The settlement price is the price at the last second of the prediction period.，If the settlement price is greater than the starting price, it is an increase.，If the settlement price is less than the starting price, it is a decrease.。",
        tradeRules2: "1.what is a contract",
        tradeRules3: "This platform provides USDT-based perpetual contracts，Users can judge the rise or fall by，Choose to buy long or sell short contracts to capture rising digital asset prices/declining earnings。Perpetual contracts have no delivery date，Users can always hold。",
        tradeRules4: "2.What is margin",
        tradeRules5: "In the virtual contract market，Users only need to follow the contract price，Pay a small amount of funds according to a certain proportion as a financial guarantee for the performance of the contract，便可参与合约的买卖，This kind of funds is the virtual contract margin。\nThe contracts on this platform adopt the separate-account margin model.。Each currency corresponds to a contract account，The account assets and positions of different contract currencies are independent of each other.，And transfers and transactions between different contract accounts do not affect each other.。After the user's contract account is opened,，The risks and benefits of all positions held in this contract account will be calculated together.。",
        tradeRules6: "3.How to calculate margin",
        tradeRules7: "Position margin = contract face value * Number of open contracts / Leverage multiple\nFreeze margin = The total margin of all successfully placed orders and opening orders within the current order.\nAvailable margin = Account Balance - Position margin - Freeze margin",
        tradeRules8: "4.How to Calculate Margin Rate",
        tradeRules9: "Margin rate is a measure of user asset risk。\nmargin rate =（unrealized profit or loss + Position margin + Available margin + Freeze margin - Closing fee） / Position margin\nThe smaller the margin rate，The riskier the account。When the margin rate is less than or equal to 0%hour，Will trigger forced liquidation。",
        tradeRules10: "5.What is the estimated forced parity price?",
        tradeRules11: "Estimated forced parity price，That is, the price calculated by the system that theoretically triggers forced liquidation.，For reference only，There may be slight deviations from actual values。",
        tradeRules12: "6.What is total account equity?、unrealized profit or loss",
        tradeRules13: "Total account equity = Account Balance + unrealized profit or loss\nUnrealized profit and loss，It is the profit and loss of the position currently held by the user in the currency contract.，Unrealized profit and loss will change with the latest transaction price changes。\n Unrealized profit and loss on long positions=（1/average position price - 1/Latest transaction price）* Number of long position contracts * contract face value * Latest transaction price\n Unrealized profit and loss on short position=（1/Latest transaction price - 1/average position price）* Number of short contracts * contract face value * Latest transaction price",
        text1: "Account security",
        text2: "Day and night mode",
        text3: "Clear cache",
        text4: "Are you sure you want to log out?？",
        text5: "Are you sure you want to clear cache?？",
        text6: "Multi-language",
        text7: "To protect your safety，It is recommended to enable at least one two-factor authentication"
    },
    login: {
        login: "Log in",
        register: "Register",
        title1: "Trade anytime, anywhere，View real-time quotes",
        title2: "Trade anytime, anywhere，View real-time quotes",
        title3: "The Most Trusted Cryptocurrency Trading Platform",
        formTitle1: "Account login",
        formTitle2: "Welcome back！use your email、Phone number login",
        btn1: "Phone",
        btn2: "Mail",
        btn3: "Log in",
        btn4: "Register",
        btn5: "Forget password？",
        field1: "Phone number",
        field2: "Password",
        field3: "Mail",
        placeholder1: "Enter verification code",
        placeholder2: "Please enter phone number",
        placeholder3: "Please enter password",
        placeholder4: "Please enter your email",
        content12: "Please enter phone number",
        content13: "Please enter password",
        content14: "Please enter your email",
        content16: "Digital asset financial services",
        loginSuccess: "Login successful ",
        chooseArea: "Please select area code",
        placeholder5: "Please select country code",
        placeholder6: "Remember my password",
        placeholder7: "Download APP",
        placeholder8: "Contact customer service",
        placeholder9: "Select country or region",
        placeholder10: "Area code",
        placeholder11: "Wallet login",
        placeholder12: "Operation failed，Please switch to",
        placeholder13: "Wallet"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "About",
        aboutCoinbeadCenterDetails: "The world's leading blockchain asset trading platform，Operating the entire ecosystem at the same time。",
        footDetails1: "daily trading volume",
        footDetails2: "trade/per second",
        footDetails3: "Help Center",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "It is a world-famous international website for digital assets.，Mainly provides Bitcoin to global users（BTC）、Litecoin（LTC）、Ethereum（ETH）Coins and derivatives such as digital assets",
        coinbeadCenterDetailsm: "Adopt GSLB、Network Server、online trading、network、High-speed memory trading engine for multiple machines、cold wallet、Advanced technologies such as smart offline hot wallets，Web version mobile version、PC and other terminals provide security for our customers、Stablize、Reliable digital tariff transaction service。At the same time, we based on users’ suggestions and needs，Improve and upgrade products and services，Provide better service to every customer，There is no end to the improvement of innovative user experience。",
        infrastructureCenterTitle: "infrastructure",
        infrastructureCenterDetails: "Our mission is to provide infrastructure services for the entire blockchain ecosystem",
        freeCenterTitle: "free",
        freeCenterDetails: "Our vision is to make money flow freely around the world。we believe，spread this freedom，can make the world a better place",
        futureCenterTitle: "Pioneering the future of technology",
        futureCenterDetails: "Open up the future of technology",
    },
    applicationForListing: {
        title: "Coin listing application",
        projectName: "project name",
        tokenName: "Token name",
        projectDescription: "Project Description",
        contractAddress: "Contract address",
        officialWebsite: "official website",
        ContactAndEmail: "Contact email",
        ContactAndName: "Contact name",
        file: "Upload Attachment",
        pleaseEnter: "please enter",
        enterFile: "Please upload files",
        upload1: "Current limit selection 1 files，",
        upload2: "This time I chose",
        upload3: "files，",
        upload4: "Total selected",
        upload5: "files",
    },
    public: {
        submit: "submit",
        copySuccessfully: "Copied successfully",
        copyFailed: "Copy failed",
        noDate: "No data",
        submitSuccessfully: "Submitted successfully",
        goBack: "go back to the last page",
        cancel: "Cancel",
        home: "Home",
        loading: "loading...",
        confirm: "confirm",
        loadingFailed: "network anomaly",
        nomore: "no more",
        tryAgain: "Please refresh the page/exit the program then re-upload!",
        hot: "Popular areas",
    },
    inviteFriends: {
        inviteFriends: "Share with friends",
        freedomOfWealth: "Win-win cooperation",
        myInvitationCode: "my invitation code",
        clickCopy: "Click to copy",
        myInvitationLink: "My invitation link",
        clicktoSaveTheQRCode: "Click to save the QR code",
        saveQRCode: "Invite QR code",
        numberOfSubordinates: "Number of subordinates",
        rebateIncome: "Rebate income",
        people: "people",
        myPromotionRevenue: "My promotional income",
        numberOfDirectSubordinates: "Number of direct subordinates",
        totalNumberOfSubordinates: "Total number of subordinates",
        directSubordinateReturnsCommission: "Commission rebate for direct subordinates",
        theTotalCommission: "total rebate",
        checkHistory: "View rebate records",
        history: "Commission record",
        theLowerAccount: "Subordinate account",
        type: "type：",
        serviceCharge: "Handling fee：",
        percentageofCommissionReturned: "Rebate ratio：",
        commissionrebateAmount: "Rebate amount：",
        time: "time：",
        typeName1: "Recharge and commission",
        typeName2: "MT order handling fee three-level distribution",
        typeName3: "MT Profitable Three-Level Distribution",
        typeName4: "Referral rewards",
        typeName30: "Recharge and commission",
        typeName31: "Periodic trading profit rebate",
        typeName32: "Liquidity mining profit rebate",
        typeName45: "Transaction fee rebate",
        typeName46: "Commission rebate on own handling fee",
        typeName50: "Delivery contract handling fee rebate",
        typeName51: "Perpetual Contract Handling Fee Rebate",
        typeName52: "Additional commission rebate for delivery contract handling fees",
        typeName53: "Additional commission rebate for perpetual contract handling fees",
        typeName54: "Additional rebates on perpetual contract handling fees",
        shareRules1: "Invitation instructions",
        shareRules2: "Enter the invitation center，Copy the invitation link or code，Share with your friends。Friends can become your subordinates by registering with your invitation code。",
        shareRules3: "Earn rebates",
        shareRules4: "When subordinates conduct transactions，You can get corresponding rebates，Supports up to three levels of subordinates。 for example，You invited friend A，A invited B，B invited C，则A、B、C conducts contracts and other transactions within the platform，You can get corresponding rebates。",
        shareRules5: "Team level",
        shareRules6: "The more first-level subordinates are promoted，The higher the team level，The higher the rebate you can enjoy。The team level is divided into LV1-LV6，See the table below for upgrade rules，included among these'N'Number of people who have recharged and completed real-name authentication for the first level of subordination。",
        shareRules7: "Subordinates bet on delivery contracts，You can get a commission proportional to your bet amount。",
        upgradeConditions: "Require",
        recommendedNumberOfPeople: "Recommended number of people"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Suggestions and feedback",
        text: "If you encounter bugs or have product suggestions during use, please give us feedback.",
        email: "email address",
        enterEmail: "Please enter email address",
        enterCorrectEmail: "Please input the correct email address",
        title: "title",
        enterTitle: "Please enter a question title",
        describeTheProblem: "Describe the problem",
        enterDescribeTheProblem: "Please enter the problem you describe",
        submit: "submit",
    },
    help: {
        enterSearch: "search",
        title: "Help Center",
        search: "Please enter your question",
        text1: "Understand your needs，Continue to provide you with more convenient services"
    },
    announcement: {
        enterSearch: "search",
        title: "Announcement Center",
        search: "Please enter your question"
    },
    privacy: {
        title: "Privacy Statement",
        content1: "1. What personal information of users is collected？",
        content2: "Personal information is collected to provide products and services and to continuously improve them。",
        content3: "The following are the types of personal information we collect：",
        content4: "Information you provide to us：We receive and store any information you provide related to this platform。You can choose not to provide certain information，However, you may not be able to use some“This platform service”。Examples of information you provide to us include：",
        content5: "Name",
        content6: "address",
        content7: "citizenship",
        content8: "ID photo",
        content9: "phone number/Email number",
        content10: "Other information that helps us identify you",
        content11: "Information collected automatically：We automatically collect and store information about your use“This platform service”certain types of information，Like many websites，We use cookies and other unique identifiers。When your web browser or device accesses the Platform，we will obtain certain types of information。Examples of information we collect and analyze include：",
        content12: "Internet protocol that connects personal computers to the Internet(IP)address；",
        content13: "Login information for your personal device or computer、email address、Password and location；",
        content14: "Version and time zone settings；",
        content15: "transaction history。",
        content16: "Information from other sources：We may obtain information from other sources（Credit history records such as credit bureaus）Information collected about you。We will use this information to prevent and detect fraud。",
        content17: "2. Can children use it?？",
        content18: "This platform is not allowed to be used by anyone under the age of 18“Serve”。",
        content19: "3. What are cookies and other identifiers used for?？",
        content20: "We use cookies and similar tools to optimize user experience、To provide our services and understand how customers use our services，in order to make targeted improvements。We use cookies to ensure that our system can recognize your browser or device and provide you with services。",
        content21: "We use operational cookies and similar tools（collective name“Cookies”）to provide services，For example：",
        content22: "Identify you when you log in and use our services。",
        content23: "Provide customized features and services。",
        content24: "Protect against fraudulent activity。",
        content25: "Improve security。",
        content26: "Track your preferences（such as currency and language）。",
        content27: "We also use cookies to understand how users use our services，in order to make targeted improvements。",
        content28: "4. Will my personal information be shared?？",
        content29: "User information is an important part of our business，We do not sell users' personal information to others。This platform will only share users’ personal information with its subsidiaries or affiliates under the following circumstances:，These subsidiaries or affiliates are required to comply with this Privacy Statement or to follow practices that are at least as protective as those described in this Privacy Statement。",
        content30: "Third party service provider：We employ other companies and individuals to perform functions on our behalf。Examples of such functions include：analyze data、Provide marketing assistance、Process payment、Transfer content and assess and manage credit risk。These third party service providers have access to personal information needed to perform their functions，But it may not be used for other purposes。also，They must process personal information in compliance with this privacy statement and applicable data protection laws。",
        content31: "business transfer：As our business continues to grow，We may sell or buy other businesses or services。in such transactions，User information is generally among the transferred business assets，but remain bound by the commitments in any existing privacy statement（certainly，Unless otherwise agreed by the user）。in addition，If the Company or substantially all of its assets are acquired，User information will also be transferred。Protection of our company and others：When we believe that releasing account and other personal information is to comply with the law or our regulatory obligations、Enforce or apply our Terms of Use and other agreements、Rights of Users or Others、appropriate measures for property or safety，We will release said account and other personal information。This includes exchanging information with other companies and organizations，To prevent fraud and reduce credit risk。",
        content32: "5. International transfers of personal data",
        content33: 'We may transfer your data outside the European Economic Area ("EEA"). We will put in place appropriate safeguards to ensure that such transfers comply with applicable data protection rules, unless the European Commission has confirmed that the country to which the data is transferred provides an adequate level of protection。',
        content34: "6. Is my personal information safe?？",
        content35: "We designed our systems with your security and privacy in mind. We use encryption protocols and software to strive to protect the security of personal information during transmission.。",
        content36: "We always adopt physical, electronic and procedural safeguards when collecting, storing and disclosing personal information. Our security procedures mean that we may need to verify your identity before disclosing personal information to you。",
        content37: "The most important thing you can do is protect your personal account passwords from unauthorized access. We recommend setting a unique password for your account that is distinct from other online accounts. And be sure to log out after using a shared computer。",
        content38: "7. How should I protect my personal information?？",
        content39: "If you have any questions or objections about the way we collect and process personal information, please contact customer service staff",
        content40: "After you agree to us processing your personal information for a specific purpose, you can withdraw your intention at any time, and we will stop processing your data for that purpose.。",
        content41: "In addition, you have the right to request access to, rectification and deletion of personal data and to request data portability, subject to applicable laws. You can also object to our processing of your personal data, or request that our processing of your personal data be restricted in certain circumstances.。",
        content42: "8. EU GDPR and UK Data Protection Law – Legal Basis",
        content43: "EU GDPR and UK data protection law require us to comply with the legal basis when using personal information. Our basis depends on the specific purposes for which the personal information is used. These bases include：",
        content44: "Performance of a contract – to provide or communicate with you about products or services, including our use of your personal information to accept and process orders and process payments.。",
        content45: "Our legitimate business interests and user interests - we detect and prevent fraud and abuse and protect the safety of our users, ourselves or others。",
        content46: "Your consent – ​​We require your consent to process your personal information for the specific purposes communicated to you. Once you have given us your consent to process your personal information for a specific purpose, you may withdraw your consent at any time and we will stop processing your data for that purpose.。",
        content47: "Comply with legal obligations - We use your personal information as required by law. For example, we collect bank account information for identity verification purposes。",
        content48: "The above content and other legal bases depend on the specific purposes for which we use personal information.。",
        content49: "9. Conditions of use, notices and amendments",
        content50: "Your use and any dispute over privacy is subject to this Statement and our Terms of Use. If you have any concerns about the privacy of this platform, please contact us with a detailed explanation and we will be happy to resolve the issue for you. You also have the right to contact your local data protection authority。",
        content51: "Our business is constantly changing, and so will our privacy statement. You should check our website frequently to see the latest changes. If you do not agree with the revised content, you should stop accessing immediately. After the updated version of the Privacy Policy is released, your continued access means that you agree with the updated content and agree to abide by the updated Privacy Policy. Unless otherwise stated, the current Privacy Statement applies to all information we hold about you and your account。",
    },
    serviceAgreement: {
        title: "Service Agreement",
        content1: 'This website is a platform specifically for users to conduct digital asset transactions and provide related services (hereinafter referred to as "the service" or "the service"). For the convenience of describing this agreement, this website collectively uses "we" or other first-person pronouns in this agreement. As long as the natural person or other subject who logs in to the website is a user of this website, for the convenience of expressing this agreement, "you" or other second person will be used below. For the convenience of the presentation of this Agreement, we and you are collectively referred to as the "Parties" in this Agreement, and we or you are individually referred to as a "Party".”。',
        content2: "important hint：",
        content3: "We would like to remind you here：",
        content4: "1 The digital assets themselves are not issued by any financial institution or company or this website",
        content5: "2 Digital asset markets are new, unconfirmed, and may not grow；",
        content6: "3 Digital assets are mainly used extensively by speculators, with relatively little use in the retail and commercial markets. Digital asset transactions are extremely risky. They are traded continuously throughout the day, with no limit on rise and fall, and prices are easily affected by market makers and global government policies. fluctuation；",
        content7: "4  The Company reserves the right to suspend or terminate your account at any time if the Company determines, in its sole discretion, that you have violated this Agreement, or that the services provided by this website or your use of the services provided by this website are illegal under the laws of your jurisdiction. , or suspend or terminate your use of the services or digital asset transactions provided by this website. Anyone from [Mainland China, Taiwan, Israel, Iraq, Bangladesh, Bolivia, Ecuador, Kyrgyzstan, Sevastopol, and the United Kingdom (retail users)] is prohibited from using the contract transaction services provided by this website. The aforementioned list of countries or regions will change depending on the policies and product types of different countries or regions. We may not notify you specifically at that time. Please pay attention to the updates to this agreement in a timely manner.。",
        content8: "Digital asset trading carries extremely high risks and is not suitable for most people. You understand and understand that this transaction may result in partial or total loss, so you should determine the amount of the transaction based on the degree of loss you can afford. You know and understand that digital assets will generate derivative risks, so if you have any questions, it is recommended to seek the assistance of professional advisors first. In addition, in addition to the risks mentioned above, there are also risks that cannot be predicted. You should carefully consider and use clear judgment to evaluate your financial situation and the above-mentioned risks before making any decision to buy or sell digital assets, and bear all resulting losses. We do not assume any responsibility for this.。",
        content9: "Warning to you：",
        content10: "1 You understand that this website is only used as a place for you to obtain digital asset information, find transaction parties, negotiate and conduct transactions on digital assets. This website does not participate in any of your transactions, so you should use your own prudent judgment to determine the relevant digital assets and/or transactions. or the authenticity, legality and validity of the information, and shall bear the resulting responsibilities and losses on its own。",
        content11: "2 Any opinions, news, discussions, analyses, prices, recommendations and other information on this website are general market comments and do not constitute investment advice. We are not responsible for any losses arising directly or indirectly from reliance on this information, including but not limited to any loss of profits.。",
        content12: "3 This website reserves the right to make its own decision, modify or change the content at any time. We have taken reasonable measures to ensure the accuracy of the information on the website, but we cannot guarantee its accuracy, and we will not be responsible for any loss or damage caused by the information on this website or the lack of information. Direct or indirect losses arising from delays or failures in linking to the Internet, transmitting or receiving any notices and information。",
        content13: "4 There are also risks in using Internet-based trading systems, including but not limited to software, hardware and Internet connection failures. As we have no control over the reliability and availability of the Internet, we accept no responsibility for distortion, delays and connection failures.。",
        content14: "5 It is prohibited to use this website to engage in all illegal trading activities or illegal activities such as money laundering, smuggling, commercial bribery, etc. If any suspected illegal transactions or illegal activities are found, this website will take various available means, including but not limited to freezing accounts and notifying relevant parties. Authorities, etc., we do not assume all responsibilities arising therefrom and reserve the right to pursue liability from relevant persons.。",
        content15: "6 It is prohibited to use this website to maliciously manipulate the market, improper transactions and other unethical trading activities. If such incidents are discovered, this website will warn, restrict transactions, and close all unethical behaviors such as malicious price manipulation and malicious influence on the trading system. We do not assume any liability arising from preventive protection measures such as account suspension and reserve the right to pursue liability from relevant persons.。",
        content16: "1、General principles",
        content17: '1.1 "User Agreement" (hereinafter referred to as "this Agreement" or "These Terms and Conditions"), consists of the main text, "Privacy Terms", "Know Your Customer and Anti-Money Laundering Policy" and various other policies that have been published or may be published in the future on this website. Class rules, statements, descriptions, etc.。',
        content18: "1.2 You should read this agreement carefully before using the services provided by this website. If you do not understand anything or if it is otherwise necessary, please consult a professional lawyer. If you do not agree to this Agreement and/or its modification at any time, please immediately stop using the services provided by this website or no longer log in to this website. Once you log in to this website, use any services of this website or any other similar behavior, it means that you have understood and fully agreed to the contents of this Agreement, including any modifications made by this website to this Agreement at any time.。",
        content19: '1.3 You can become a member of this website (hereinafter referred to as a "member") by filling in the relevant information in accordance with the requirements of this website and successfully registering after other relevant procedures. By clicking the "Agree" button during the registration process, you sign electronically. Form of agreement with the company; or when you click on any button marked "Agree" or similar meaning during the use of this website, or actually use the services provided by this website in other ways permitted by this website, it means that you fully understand , agree and accept all the terms of this agreement. The absence of your handwritten signature will not affect the legal binding force of this agreement on you.。',
        content20: "1.4 After becoming a member of this website, you will receive a member account and corresponding password, which you are responsible for keeping; you shall be legally responsible for all activities and events conducted under your account。",
        content21: "1.5 Only members who become members of this website can use the digital asset trading platform provided by this website for transactions and enjoy other services stipulated by this website that are only available to members; non-members can only log in to the website, browse the website and other services stipulated by this website. services。",
        content22: "1.6 By registering and using any services and functions provided by this website, you will be deemed to have read, understood and：",
        content23: "1.6.1 Accept all terms and conditions of this Agreement。",
        content24: "1.6.2 You confirm that you are over 18 years old or have the legal age to enter into contracts according to different applicable laws. Your registration on this website, sales or purchases, publishing information, etc. and accepting the services of this website should be in compliance with your requirements. Have jurisdiction over the relevant laws and regulations of a sovereign country or region, and have the full ability to accept these terms, enter into transactions, and use this website for digital asset transactions.。",
        content25: "1.6.3 You guarantee that all digital assets involved in the transaction that belong to you are legally obtained and have ownership。",
        content26: "1.6.4 You agree that you are solely responsible for your own trading or non-trading activities and for any gains or losses。",
        content27: "1.6.5 You confirm that the information provided when registering is true and accurate。",
        content28: "1.6.6 You agree to comply with the requirements of any relevant law for tax purposes, including reporting any trading profits。",
        content29: "1.6.7 You agree not to engage in or participate in behavior or activities that harm the interests of this website or the company at any time, whether or not related to the services provided by this website。",
        content30: "1.6.8 This agreement only governs the rights and obligations between you and us, and does not involve the legal relationships and legal disputes arising from digital asset transactions between users of this website and other websites and you.。",
        content31: "2、Agreement Amendment",
        content32: "We reserve the right to revise this Agreement from time to time and announce it on the website without notifying you separately. After the revision, the revision time will be marked on the [Last Update Time] displayed in this Agreement, and it will automatically take effect once it is published on the website. You should browse and pay attention to the update time and updated content of this Agreement from time to time. If you do not agree with the relevant changes, you should immediately stop using the services of this website; your continued use of the services of this website means that you accept and agree to be bound by the revised agreement.。",
        content33: "3、register",
        content34: "3.1 Registration qualifications",
        content35: "You confirm and promise that when you complete the registration process or actually use the services provided by this website in other ways permitted by this website, you should have the ability to sign this agreement and use the services of this website as stipulated by applicable laws. natural person, legal person or other organization. Once you click the Agree to Register button, it means that you or your authorized agent has agreed to the content of the agreement and has been registered and used the services of this website by his agent. If you do not have the aforementioned subject qualifications, you and your authorized agent shall bear all consequences resulting therefrom, and the company reserves the right to cancel or permanently freeze your account, and hold you and your authorized agent accountable.。",
        content36: "3.2 Registration purpose",
        content37: "You confirm and promise that your registration on this website is not for the purpose of violating laws and regulations or disrupting the order of digital asset transactions on this website.",
        content38: "3.3Registration process",
        content39: "3.3.1 You agree to provide a valid email address, mobile phone number and other information as required on the user registration page of this website. You can use the email address, mobile phone number you provided or confirmed or other methods allowed by this website as a login method to enter this website. If necessary, in accordance with the relevant laws and regulations of different jurisdictions, you must provide your real name, identity document and other relevant information stipulated in laws and regulations, privacy clauses and anti-money laundering clauses, and constantly update the registration information in a timely, detailed and accurate manner. Require. All originally typed information will be referenced as registration information. You are responsible for the authenticity, completeness and accuracy of such information, and bear any direct or indirect losses and adverse consequences arising therefrom.。",
        content40: "3.3.2 If the laws, regulations, rules, orders and other regulations of the sovereign country or region where you are located have real-name requirements for mobile phone numbers, you agree that the registered mobile phone number provided has been registered under real-name. If you do not provide it in accordance with the regulations, any consequences caused to you will be Direct or indirect losses and adverse consequences shall be borne by you。",
        content41: "3.3.3 If you provide the information required for registration legally, completely and effectively and have verified it, you are entitled to obtain the account number and password of this website. When you obtain the account number and password of this website, it is deemed that your registration is successful and you can log in as a member on this website.。",
        content42: "3.3.4 You agree to receive emails and/or short messages sent by this website related to the management and operation of this website.。",
        content43: "4. Service",
        content44: "This website only provides online trading platform services for your digital asset trading activities (including but not limited to digital asset trading and other services) through this website.。",
        content45: "4.1 Service Content",
        content46: "4.1.1 You have the right to browse the real-time market conditions and transaction information of various digital asset products on this website, and you have the right to submit digital asset transaction instructions and complete digital asset transactions through this website.。",
        content47: "4.1.2 You have the right to view the information under your membership account on this website, and to apply the functions provided by this website to operate。",
        content48: "4.1.3 You have the right to participate in website activities organized by this website in accordance with the activity rules published on this website.。",
        content49: "4.1.4 Other services this website promises to provide you。",
        content50: "4.2 Service Rules You promise to abide by the following service rules of this website：",
        content51: "4.2.1 You shall abide by laws, regulations, and policy requirements, ensure the legality of the sources of all digital assets in your account, and shall not engage in illegal or other activities that harm the rights and interests of this website or third parties on this website or by using its services, including but not limited to: Not limited to sending or receiving any illegal, illegal, or infringing information on the rights of others, sending or receiving pyramid schemes or other harmful information or remarks, using or forging email header information on this website without authorization from this website, etc.。",
        content52: "4.2.2 You should abide by laws and regulations and properly use and keep your account number, login password, fund password, the mobile phone number bound to you when registering, and the mobile phone verification code received by your mobile phone. You are fully responsible for any operations and consequences of using your account and login password, fund password, and mobile phone verification code. When you find that this website’s account number, login password, or fund password or verification code are used by a third party without your authorization, or there are other account security issues, you should immediately and effectively notify this website and request this website to suspend the service of this website’s account. . This website has the right to take action on your request within a reasonable time, but this website does not bear any responsibility for the consequences (including but not limited to any losses you suffer) that have occurred before taking action. You may not donate, borrow, lease, transfer or otherwise dispose of your website account to others without the consent of this website.。",
        content53: "4.2.3 You agree to be responsible for all activities that occur under your account and password on this website (including but not limited to information disclosure, posting of information, online clicks to agree or submission of various rules and agreements, online renewal of agreements or purchase of services, etc.)。",
        content54: "4.2.4 When conducting digital asset transactions on this website, you must not maliciously interfere with the normal progress of digital asset transactions or disrupt the order of transactions; you must not interfere with the normal operation of this website or interfere with other users' use of this website's services by any technical means or other means; you must not use fictitious Maliciously defaming the goodwill of this website through facts and other means。",
        content55: "4.2.5 If you have a dispute with other users due to online transactions, you may not request this website to provide relevant information through non-judicial or administrative channels.。",
        content56: "4.2.6 You shall solely judge and bear any tax payable and all hardware, software, service and other expenses incurred during your use of the services provided by this website.。",
        content57: "4.2.7 You should abide by this agreement and other service terms and operating rules published and updated by this website from time to time, and have the right to terminate the use of the services provided by this website at any time。",
        content58: "4.3 product rules",
        content59: "4.3.1 Browse transaction information",
        content60: "When you browse the transaction information on this website, you should carefully read all the contents contained in the transaction information, including but not limited to price, commission volume, handling fee, buying or selling direction, and only after you fully accept all contents contained in the transaction information Trade with the click of a button。",
        content61: "4.3.2 View transaction details",
        content62: "You can view the corresponding transaction records through your account。",
        content63: "5. Rights and obligations of this website",
        content64: "5.1 If you do not have the registration qualifications stipulated in this agreement, this website has the right to refuse your registration. For those who have already registered, this website has the right to cancel your membership account. This website reserves the right to hold you or your authorized agent accountable. s right. At the same time, this website reserves the right to decide whether to accept your registration under any other circumstances.。",
        content65: "5.2 Based on this website’s own judgment, if this website finds that you or your associated account users are not suitable for high-risk investments, we have the right to suspend or terminate your account and the use of all associated accounts.。",
        content66: "5.3 When this website discovers that the account user is not the initial registrant of the account, it has the right to suspend or terminate the use of the account.。",
        content67: "5.4 When this website reasonably suspects that the information you provided is wrong, untrue, invalid or incomplete through technical testing, manual sampling and other testing methods, it has the right to notify you to correct or update the information or to suspend or terminate the provision of this website services to you.。",
        content68: "5.5This website reserves the right to correct any information displayed on this website when it is discovered that there is an obvious error.。",
        content69: "5.6 This website reserves the right to modify, suspend or terminate the services of this website at any time. This website does not need to notify you in advance to exercise the right to modify or terminate services. If this website terminates one or more services of this website, the termination will be from this website on the website. Effective on the date of publication of the termination announcement。",
        content70: "5.7 This website will take necessary technical means and management measures to ensure the normal operation of this website, provide necessary and reliable trading environment and trading services, and maintain the order of digital asset transactions.。",
        content71: "5.8 If you do not log in to this website using your membership account and password for one consecutive year, this website has the right to cancel your account. After the account is canceled, this website has the right to open the corresponding member name to other users for registration and use.。",
        content72: "5.9 This website ensures the security of your digital assets by strengthening technology investment, improving security precautions and other measures, and will notify you in advance when foreseeable security risks arise in your account.。",
        content73: "5.10 This website has the right to delete all kinds of content information on this website that does not comply with laws and regulations or the regulations of this website at any time. This website does not need to notify you in advance to exercise such rights.。",
        content74: "5.11 This website has the right to request you to provide more information or materials in accordance with the laws, regulations, rules, orders and other regulatory requirements of your sovereign country or region, and to take reasonable measures to comply with local regulatory requirements. You Obliged to cooperate; this website has the right to suspend or permanently stop opening some or all of this website's services to you in accordance with the laws, regulations, rules, orders and other requirements of your sovereign country or region.。",
        content75: "5.12 This website reserves the right to close your account upon seven working days' notice at its sole discretion. Therefore, you have seven working days to cancel orders and close positions. If you have not canceled the order or closed the position when the period expires, we will forcefully cancel the order and close the position, and return the remaining digital assets in your account to you.。",
        content76: "5.13 In order to protect the rights and interests of traders, this website has the right to make adjustments to online products under special circumstances (such as system failure, network failure, extreme market conditions, etc.), such as: contracts for early delivery and settlement, contract types and contracts for early delivery and settlement. The cycle and settlement and delivery prices are subject to the announcement on this website.。",
        content77: "6. Compensation",
        content78: "6.1 In no event will our liability to you for direct damages exceed the total service fees we charged you for your use of this website for a period of three (3) months。",
        content79: "6.2 If you violate this Agreement or other laws and regulations, you must compensate us for at least US$2 million and bear all costs incurred (including attorney fees, etc.). If it is not enough to cover the actual losses, you must make up for it.。",
        content80: "7. Right to seek injunctive relief",
        content81: "We and you both acknowledge that common law remedies for your breach of contract or possible breach of contract may not be sufficient to compensate for all the losses we suffer, so we have the right to seek injunctive relief as permitted by common law or equity in the event of your breach of contract or possible breach of contract. All other remedies。",
        content82: "8. Limitation of liability and disclaimer",
        content83: "8.1 You understand and agree that, under any circumstances, we will not be responsible for the following matters：",
        content84: "8.1.1 loss of income；",
        content85: "8.1.2 Trading profits or contract losses；",
        content86: "8.1.3 Losses caused by business interruption；",
        content87: "8.1.4 Loss of expected monetary savings；",
        content88: "8.1.5 Losses caused by information problems；",
        content89: "8.1.6 Loss of opportunity, goodwill or reputation；",
        content90: "8.1.7 Corruption or loss of data；",
        content91: "8.1.8 Cost of purchasing substitute products or services；",
        content92: "8.1.9 Any indirect, special or incidental loss or damage arising from tort (including negligence), breach of contract or any other reason, whether or not such loss or damage is reasonably foreseeable by us; whether or not we have been informed of the existence of such in advance Possibility of loss or damage。",
        content93: "8.1.1 Clauses to 8.1.9 are independent of each other。",
        content94: "8.2 You understand and agree that we will not be liable for any damages caused to you by any of the following circumstances:：",
        content95: "8.2.1 Your specific transactions may involve major violations of laws or breaches of contract。",
        content96: "8.2.2 Your behavior on this website is suspected of being illegal or immoral。",
        content97: "8.2.3 Costs and losses incurred by purchasing or obtaining any data, information or conducting transactions through the services of this website or other substitute actions。",
        content98: "8.2.4 Your misunderstanding about the services of this website。",
        content99: "8.2.5 Any other losses related to the services provided by this website that are not caused by us。",
        content100: "8.3 We are responsible for information network equipment maintenance, information network connection failure, computer, communication or other system failure, power failure, weather conditions, accidents, strikes, labor disputes, riots, uprisings, riots, insufficient productivity or production materials, fire, Floods, storms, explosions, wars, bank or other partner reasons, digital asset market collapse, government actions, orders of judicial or administrative agencies, other actions that are beyond our control or that we have no ability to control, or third-party reasons We do not assume any responsibility for the inability or delay in service, or for your losses.。",
        content101: "8.4 We cannot guarantee that all the information, programs, texts, etc. contained in this website are completely safe and free from interference and damage by any viruses, Trojans and other malicious programs. Therefore, if you log in and use any services on this website or download and use any downloaded programs, Information, data, etc. are all your personal decisions and you bear the risks and possible losses at your own risk.。",
        content102: "8.5 We do not make any guarantees or commitments about any information, products and services of any third-party websites linked to this website or any other form of content that does not belong to us. If you use any services, information and services provided by third-party websites, Products, etc. are all your personal decisions and you bear all responsibilities arising therefrom.。",
        content103: "8.6 We do not make any express or implied guarantees for your use of the services on this website, including but not limited to the suitability of the services provided by this website, no errors or omissions, continuity, accuracy, reliability, and suitability for a particular purpose. At the same time, we do not make any commitment or guarantee as to the validity, accuracy, correctness, reliability, quality, stability, completeness and timeliness of the technology and information involved in the services provided by this website. Whether to log in or use the services provided by this website is your personal decision and you bear your own risks and possible losses. We do not make any express or implied guarantees regarding the market, value and price of digital assets. You understand and understand that the digital asset market is unstable. Prices and values ​​may fluctuate or collapse significantly at any time. Trading digital assets is your personal freedom. Choose and decide at your own risk and possible losses.。",
        content104: "8.7 Our warranties and commitments set out in this Agreement are the only warranties and representations made by us in relation to this Agreement and the services provided on this website and supersede any warranties and commitments arising in any other way and manner, whether written or oral, express of or implied. All these warranties and representations only represent our own commitments and guarantees and do not guarantee that any third party will comply with the guarantees and commitments in this Agreement.。",
        content105: "8.8 We do not waive any rights not mentioned in this Agreement to limit, exclude or offset our liability for damages to the fullest extent applicable law.。",
        content106: "8.9 After you register, you agree to any operations we perform in accordance with the rules stipulated in this agreement, and any risks incurred will be borne by you.。",
        content107: "9. Termination of Agreement",
        content108: "9.1 This website has the right to terminate all services of this website in accordance with the provisions of this agreement. This agreement shall be terminated on the date of termination of all services of this website.。",
        content109: "9.2 After the termination of this agreement, you have no right to require this website to continue to provide any services to it or perform any other obligations, including but not limited to requiring this website to retain or disclose to you any information in its original website account to you or a third party. The third party forwards any information that it has not read or sent, etc.。",
        content110: "9.3 The termination of this agreement will not affect the observant party's requirement to bear other responsibilities against the breaching party.。",
        content111: "10. Intellectual property rights",
        content112: "10.1 All intellectual achievements contained in this website include but are not limited to website logos, databases, website designs, text and graphics, software, photos, videos, music, sounds and combinations thereof, software compilations, related source codes and software (including small applications) The intellectual property rights of programs and scripts) belong to this website. You may not reproduce, alter, copy, send or use any of the foregoing materials or content for commercial purposes。",
        content113: "10.2 All rights contained in the name of this website (including but not limited to goodwill and trademarks, logos) belong to the company。",
        content114: "10.3 Your acceptance of this agreement shall be deemed as your initiative to transfer the copyright of any form of information you publish on this website, including but not limited to: reproduction rights, distribution rights, rental rights, exhibition rights, performance rights, screening rights, broadcast rights, information Internet transmission rights, filming rights, adaptation rights, translation rights, compilation rights and other transferable rights that should be enjoyed by the copyright holder are exclusively transferred to this website free of charge. This website has the right to file an independent lawsuit against any subject for infringement and obtain full compensation. This agreement is valid for any content protected by copyright law that you publish on this website, whether the content is formed before the signing of this agreement or after the signing of this agreement.。",
        content115: "10.4 You may not illegally use or dispose of the intellectual property rights of this website or others when using the services of this website. You may not publish the information published on this website in any form or authorize other websites (and media) to use it.。",
        content116: "11. Information protection",
        content117: 'The terms of the "Privacy Terms" published separately on this website shall prevail.。',
        content118: "12. Calculation",
        content119: "All transaction calculation results have been verified by us, and all calculation methods have been published on the website, but we cannot guarantee that the use of the website will not be interrupted or error-free。",
        content120: "13. Export Control",
        content121: "You understand and acknowledge that according to relevant laws, you are not allowed to export, re-export, import or transfer any materials (including software) on this website, so you guarantee that you will not actively implement or assist or participate in any of the above-mentioned exports or related activities that violate laws and regulations. Transfer or other violations of applicable laws and regulations; if such a situation is discovered, you should actively report it to us in a timely manner and assist us in handling it.。",
        content122: "14. Transfer",
        content123: "The rights and obligations stipulated in this Agreement also bind the assignees, heirs, executors and administrators of the parties who benefit from the rights and obligations. You may not transfer it to any third party without our consent, but we may transfer our rights and obligations in this Agreement to any third party at any time and give you notice。",
        content124: "15. Divisibility",
        content125: "If any provision of this Agreement is held to be unenforceable, invalid or illegal by any court of competent jurisdiction, it will not affect the validity of the remaining provisions of this Agreement.。",
        content126: "16. Non-agency relationship",
        content127: "Nothing in this Agreement shall be deemed to create, imply or otherwise constitute us as your agent, trustee or other representative, except as otherwise provided in this Agreement.。",
        content128: "17. Abstention",
        content129: "The waiver by us or you of any party's liability for breach of contract or other liability stipulated in this Agreement shall not be deemed or interpreted as a waiver of other liability for breach of contract; the failure to exercise any right or remedy shall not in any way be construed as a waiver of such rights or remedies. give up。",
        content130: "18. Title",
        content131: "All titles are for convenience of expression of the agreement and are not used to expand or limit the content or scope of the terms of the agreement.。",
        content132: "19. Applicable Law",
        content133: "19.1 Before raising a dispute or claim, you acknowledge and agree to first contact this website via email to resolve the dispute informally. We will try to resolve your dispute internally as soon as possible; both parties agree to resolve the dispute through good faith negotiations (discussions shall be confidential and protected by applicable rules and not used as evidence in any legal proceedings)。",
        content134: '19.2 You acknowledge and agree that in the event of any dispute, controversy, disagreement or claim, including the existence, validity, interpretation, performance, breach or termination of these Terms or any dispute arising out of or in connection with these Terms ("Dispute") , the above-mentioned parties shall submit the dispute to the International Court of Arbitration of the International Chamber of Commerce ("ICC International Court of Arbitration") in accordance with the then-effective "ICC Rules of Arbitration". The place of arbitration shall be Belize and the applicable law of the arbitration shall be determined in accordance with the International Chamber of Commerce Arbitration Rules. Unless otherwise agreed by the parties, only one arbitrator will be appointed under the ICC Rules of Arbitration. The arbitrator shall have exclusive authority to rule on his or her own jurisdiction, including, but not limited to, any objection to the existence, scope or validity of the arbitration agreement or the arbitrability of any claim or counterclaim. Any arbitration will be conducted in English. Regardless of how the arbitration is conducted, the arbitrator shall issue a written award that is reasonable and sufficient to explain the award and the essential conclusions, if any, on which the award is based. The arbitral award shall be final and binding on the parties and may be enforced in any court of competent jurisdiction。',
        content135: "20. Effectiveness and interpretation of the agreement",
        content136: "20.1 This agreement takes effect when you click on the registration page of this website to agree to register, complete the registration process, and obtain the account and password of this website. It is binding on both this website and you.。",
        content137: "20.2 The final interpretation right of this agreement belongs to this website。",
        content138: "Know your customer and anti-money laundering policy",
        content139: "1. Introduction",
        content140: '1.1 We promise to carefully comply with the laws and regulations related to "Know Your Customer" and anti-money laundering and shall not intentionally violate the "Know Your Customer and Anti-Money Laundering Policy". Within the scope of our reasonable control, we will take necessary measures and technologies to provide you with safe services and protect you from losses caused by criminal suspects money laundering behavior as much as possible.。',
        content141: "1.2 Our Know Your Customer and Anti-Money Laundering Policy is a comprehensive international policy system that includes Know Your Customer and Anti-Money Laundering policies for the different legal jurisdictions to which you are affiliated。",
        content142: "2. Know your customer and anti-money laundering policies are as follows：",
        content143: "2.1 Promulgate know-your-customer and anti-money laundering policies and update them from time to time to meet the standards set by applicable laws and regulations；",
        content144: "2.2 Promulgate and update some guiding principles and rules for operating this website, and our employees will provide services in accordance with the guidance of these principles and rules；",
        content145: "2.3 Design and complete procedures for internal monitoring and control of transactions, such as strict identity verification and arranging for the formation of a professional team responsible for anti-money laundering work；",
        content146: "2.4 Adopt a risk prevention approach to conduct due diligence and ongoing supervision of clients；",
        content147: "2.5 Review and regularly check transactions that have occurred；",
        content148: "2.6 Report suspicious transactions to the competent authorities；",
        content149: "2.7 Proof of identity, address and transaction records will be maintained for at least six years and may be submitted to regulatory authorities without further notice to you.。",
        content150: "3. Identity information and verification",
        content151: "3.1 Identity Information",
        content152: "3.1.1 According to different regulations in different jurisdictions and different entity types, the content of your information we collect may be inconsistent. In principle, the following information will be collected from registered individuals.：",
        content153: "Basic personal information: your name, address (and permanent address, if different), date of birth and nationality and other information available. Identity verification should be based on documents issued by official or other similar authorities, such as passports, ID cards or other identification documents required and triggered by different jurisdictions. The address you provide will be verified using appropriate methods, such as checking the passenger transport ticket or interest rate note or checking the electoral register, etc.。",
        content154: "Valid Photo: Before you register, you must provide a photo of yourself holding your ID against your chest；",
        content155: "Contact details: Telephone/mobile number and/or valid email address。",
        content156: "3.1.2 If you are a corporation or other legal entity, we will collect the following information to identify you or the ultimate beneficiary of your trust account。",
        content157: "Company registration and registration certificate; copies of the company's articles of association and memorandum; detailed certification materials of the company's equity structure and ownership description, proving the board resolution of the authorized principal that determines the opening and execution of the account on this website; company directors who need to be provided as required , identity documents of the major shareholder and the person authorized to sign the account on this website; the company's main business address, if it is different from the company's mailing address, provide the mailing address. If the company's local address is inconsistent with its main business address, it is considered a higher-risk customer and needs to submit additional additional documents.。",
        content158: "Depending on the different regulations in different jurisdictions and different types of entities, other certifications and documents issued by authorities required by us and documents we deem necessary。",
        content159: "3.1.3 We only accept English or Chinese versions of identity information. If not, please translate your identity information into English and have it notarized.。",
        content160: "3.2 Confirm Verification",
        content161: "3.2.1 Full page content where we ask you to provide identification documents。",
        content162: "3.2.2 We ask for a photo of you holding your identification document against your chest。",
        content163: "3.2.3 Copies of supporting documents should generally be checked against the original vouchers. However, a copy is acceptable if a suitable and trustworthy certifier can certify that the copy is an accurate and complete reproduction of the original document. Such certifiers include ambassadors, judicial commissioners, local magistrates, etc.。",
        content164: "3.2.4 The requirement to identify the ultimate beneficiary and control of the account is to determine which individuals ultimately own or control the direct customer, and/or to establish that ongoing transactions are being executed on behalf of others. If it is a business, the identity of the major shareholders (such as those holding 10% or more of the voting interest) should be verified. Generally, holding 25% of the shares will be deemed to be within normal risk, and the identity of the shareholder must be verified; holding 10% of the shares or having more voting rights or stocks is deemed to be a high-risk situation, and the identity of the shareholder must be verified.。",
        content165: "4. Monitor transactions",
        content166: "4.1 We set and adjust daily transaction and currency withdrawal maximum limits from time to time based on security and actual transaction conditions.;",
        content167: "4.2 If transactions occur frequently in a registered user or there are unreasonable circumstances, our professional team will evaluate and decide whether they are suspicious.;",
        content168: "4.3 If we determine that a transaction is suspicious based on our own judgment, we may take restrictive measures such as suspending the transaction, rejecting the transaction, or even reverse the transaction as soon as possible if possible, and report it to the competent authority, but will not notify you.;",
        content169: "4.4 We reserve the right to reject registration applications from persons from jurisdictions that do not comply with international anti-money laundering standards or persons who may be considered politically exposed persons. We reserve the right to suspend or terminate transactions that are suspicious in our sole judgment at any time, but we do not do so. Breach any duties or responsibilities owed to you。",
    },
    register: {
        formTitle1: "create",
        formTitle2: "Register using your email or mobile phone number",
        formTitle3: "Account",
        field1: "Verification code",
        field2: "Obtain",
        field3: "Confirm Password",
        field4: "Invitation code",
        field41: "（Optional）",
        field5: "I have read and agree",
        field6: "Terms of Service",
        placeholder1: "Please enter verification code",
        content7: "Please enter verification code",
        content12: "Please enter phone number",
        content13: "Please enter password",
        content14: "Please enter your email",
        content18: "Please enter password again",
        content19: "Please agree to the terms of service",
        content20: "Please enter correct verify code",
        content23: "Still haven’t received the verification code？",
        content24: "Please enter the verification code below",
        registerSuccess: "registration success",
        content25: "Passwords are inconsistent",
        content26: "Please input the correct email address",
        content27: "Password reset successful"
    },
    download: {
        content1: "Trade anytime, anywhere",
        content2: "Perfectly compatible with multiple terminals, meeting the transaction needs of various scenarios at any time",
        content3: "Check real-time market conditions at any time",
        content4: "Easily buy and sell BTC, ETH, XRP and other digital assets",
        content5: "Get early warnings on price changes for the cryptocurrencies you care about",
        content6: "View Bitcoin spot loan, futures loan, and option prices",
        content7: "Compare cryptocurrency prices on various exchanges",
        title: "download",
        install: "Installation Notes",
        one: "1、Allow description files to be downloaded",
        two: "2、Description file downloaded",
        three: "3、Go to [Settings] on your phone and find [General]",
        four: "4、Profiles and Device Manager",
        five: "5、Click on the profile",
    },
    identity: {
        noAuthentication: {
            title: "Junior Certification",
            pleaseSelectYourCountry: "Please select your country",
            name: "Name",
            enterName: "please enter your name",
            city: "city",
            enterCity: "Please enter your city",
            IDNumber: "ID number",
            enterIDNumber: "Please enter your ID number",
        },
        noAdvancedAuthentication: {
            title: "Advanced certification",
            infoCountry: "country / region",
            infoName: "Name",
            infoNumber: "ID number",
            infoAddress: "address",
        },
        text: "not certified",
        recertification: "recertification",
        certification: "Certification",
        authenticationInProgress: "verified",
        inAuthentication: "Certification in progress",
        uploadTheFrontOfTheDocument: "Upload the front of the ID",
        uploadTheReverseSideOfTheDocument: "Upload the reverse side of the certificate",
        uploadAPhotoOfAHandHeldID: "Upload a photo of your ID card in hand",
        pleaseUpload: "please ",
        pleaseCompleteOrdinaryCertificationFirst: "Need to wait for the success of the primary certification review",
        refused: "rejected",
        reason: "reason",
        succeeded: "succeeded",
        uploading: "uploading",
        newTitle: "Select the country where your ID was issued",
        pleaseOrdinaryCertificationFirst: "Please perform primary certification first",
        text1: "Modify avatar",
        text2: "Nick name",
        text3: "Account",
        text4: "Nickname modification",
        text5: "New nickname",
        text6: "Enter your new nickname",
        text7: "Old Password",
        text8: "Enter your old password",
        text9: "New Password",
        text10: "Enter your new password",
        text11: "Confirm Password",

        text12: "Enter your new password again",
        text13: "new mail box",
        text14: "Verification code",
        text15: "please enter verification code",
        text16: "your mobile phone number",
        text17: "New mobile phone number",
        text18: "please enter your phone number",
        text19: "Mobile phone verification code",
        text20: "Junior Certification",
        text21: "Please verify your identity information",
        text22: "Country",
        text23: "City",
        text24: "Enter your city",
        text25: "Enter your name",
        text26: "Your ID number",
        text27: "Enter your ID number",
        text28: "ID card front photo",
        text29: "Click to upload your documents",
        text30: "re-upload",
        text31: "upload",
        text32: "Holding ID photo",
        text33: "Click to upload your holding ID photo",
        text34: "You have bound Google Authenticator",
        text35: "key",
        text36: "Please enter the Google verification code",
        text37: "send",
        text38: "Cannot be empty",
        text39: "Email format is wrong",
        text40: "Two passwords are inconsistent",
        text41: "Copied successfully",
        text42: "Copy failed",
        text43: "Avatar modification",
        text44: "Change password",
        text45: "Email binding",
        text46: "Modify email",
        text47: "Mobile phone binding",
        text48: "Modify mobile phone",
        text49: "Google verification",
        text50: "Bind Google Authenticator",
        text51: "Enter Google verification code",
        text52: "save",
        text53: "Confirm Submission",
        text54: "Revise",
        text55: "binding",
        text56: "Turn off Google verification",
        text57: "Next step",
        text58: "Finish",
        text59: "Google verification is bound",
        text60: "Google verification has been unbundled",
        text61: "Primary certification has been submitted",
        text62: "Advanced Certification Submitted",
        text63: "No image uploaded",
        text64: "Resend",
        text65: "Please enter a nickname",
        text66: "Nickname saved successfully",
        text67: "No images uploaded yet",
        text68: "Password reset complete",
        text69: "Email modified successfully",
        text70: "Avatar saved successfully",
        text71: "Upload image",
        text72: "E-mail",
        text73: "Enter your new email",
        text74: "Your Name",
        text75: "Go to authentication",
        text76: "Please verify your holding ID photo"
    },
    retrievePassword: {
        formTitle1: "Reset Password",
        formTitle2: "For the safety of your assets, transactions are prohibited within 24 hours after changing the password.。",
        formTitle3: "Retrieve password",
        formTitle4: "Confirm the changes",
        formTitle5: "Please enter old password",
        formTitle6: "Please enter a new password"
    },
    home: {
        title1: "Please enter new password Most Popular Cryptocurrency Trading Platforms",
        title3: "why is ",
        title4: " Is a high-performance open source blockchain platform designed to support and operate secure, compliant and predictable digital infrastructure。",
        field1: "Established exchange",
        field2: "Digital asset financial services",
        field3: "Tens of millions of users",
        field4: "Establish multiple investor protection funds",
        field5: "Fast Trading",
        field6: "CME GruopAbility to match orders with ultra-low latency for the ultimate trading experience。",
        title5: "market trend",
        title6: "see more",
        table1: "Currency",
        table2: "Latest price",
        table3: "24H ups and downs",
        table4: "24H trading volume",
        table5: "24H transaction volume",
        table6: "Quotes",
        title7: "about ",
        title8: " Committed to providing users with the most advanced trading tools, innovative technology and unparalleled quality customer service。",
        title9: "The field of digital assets is changing with each passing day. Only by being far-sighted and making rapid progress can we keep up with the pace of technological innovation.。",
        title901: " Providing the most advanced digital asset trading services to our users and global liquidity providers。",
        title10: "We firmly believe that every user deserves the best trading experience。",
        title11: "Security you can trust",
        title12: "Our sophisticated security measures and SAFU funds protect your digital assets from all risks。 ",
        title13: "Best transaction rates",
        title14: "Preferential transaction rates, competitive VIP rights, and enjoy the best quality services。",
        title15: "24*7 hours customer service support",
        title16: "24/7 all-weather, full-time operation mode, answer your relevant inquiries as soon as possible。",
        title17: "0 fee trading",
        title18: "Use multiple payment methods to trade digital currencies with 0 fees, safe and fast。",
        title19: "Trade anytime, anywhere",
        title20: "Perfectly compatible with multiple terminals, meeting the transaction needs of various scenarios at any time",
        title21: "Check real-time market conditions at any time",
        title22: "Easily buy and sell BTC, ETH, XRP and other digital assets",
        title23: "Get early warnings on price changes for the cryptocurrencies you care about",
        title24: "View Bitcoin spot loan, futures loan, and option prices",
        title25: "Compare cryptocurrency prices on various exchanges",
        title26: "Sign up now",
        title27: "Dollar",
        title28: "24 hour trading volume",
        title29: "Integrated Liquidity Provider",
        title30: "user",
        title31: "More",
        title32: "Binance Loan adds CTSI&KSM as collateral assets and launches BUSD&USDT interest rate reduction discounts！",
        title33: "Our advantage",
        title34: "Safe and stable",
        title35: "Top technical team, all-round security protection, independent research and development of high-speed transaction synthesis engine, can still operate stably and reliably under massive concurrent transactions。",
        title36: "Professional Compliance",
        title37: "Professional operation team, with many years of experience in blockchain and finance, holding a compliant digital asset trading license, and 100% reserve guarantee。",
        title38: "User first",
        title39: "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service。",
        title40: "high performance",
        title41: "300,000 transactions per second with order response time of less than 1 millisecond。",
        title42: "about Us",
        title43: "Trade anytime, anywhere",
        title44: "Perfectly compatible with multiple terminals, meeting the transaction needs of various scenarios at any time",
        title45: "（Scan code to download）",
        title46: "Digital Currency News",
        title47: "our business partner",
        title48: "Loser list",
        title49: "24h turnover",
        title50: "24h increase and decrease"
    },
    quotation: {
        marketTrading: "Quotes",
        turnover: "Volume",
        optional: "Optional",
        contractMarket: "contract market",
        currencyPair: "currency pair",
        latestPrice: "Closing price",
        upsAndDowns: "Quote change",
        highest: "Highest",
        lowest: "lowest",
        transactionAmount: "Transaction volume",
        operate: "operate",
        buy: "trade",
        topTurnovers: "Transaction list",
        topGainers: "Gainer list",
        tradingPair: "Trading pair",
        buyOrder: "Buy",
        sellOrder: "sell order"
    },
    accountChange: {
        demo: "demo",
        realAccount: "actual account",
        demoAccount: "Demo account",
        accountRole: "Account role",
        pleaseSelect: "please choose",
        AccountChangeType: "Account change type",
        AccountType: "account type",
        currencyType: "Currency type",
        allType: "All types",
        allAccount: "All accounts",
        allCoin: "All currencies",
        coin: "Currency",
        amount: "Change amount",
        balance: "Account balance",
        time: "time",
        title: "Account change records",
        filtrar: "filter",
        check: "switch to",
        showOnly: "Only display currency holdings",
        holdAssets: "Currency balance",
        accountList: "Account list"
    },
    contractTransaction: {
        chart: "chart",
        basicEdition: "Basic version",
        depthMap: "Depth map",
        second: "Second",
        minute: "min",
        day: "Day",
        week: "week",
        month: "month",
        open: "open",
        trade: "trade",
        buyLong: "Buy Long",
        buyShort: "buy short",
        cannotBuy: "This currency does not support delivery contract transactions",
        cannotBuy1: "This currency does not support perpetual trading",
        tradingAssets: "trading assets",
        amountAvailable: "Quantity Available",
        timeStr: "time",
        transactionPeriod: "trading period",
        quantity: "Quantity",
        enterQuantity: "Please enter quantity",
        buyIn: "Buy Long",
        latestTransaction: "latest transaction",
        time: "Time",
        direction: "direction",
        price: "Price",
        sell: "Short",
        positionOrder: "Delivery order",
        historicalOrders: "Historical orders",
        product: "product",
        realAccount: "(real account)",
        demoAccount: "(Demo account)",
        openingTime: "Order time",
        openingPrice: "Opening price",
        curretPrice: "Current price",
        liquidationPrice: "Closing price",
        profitOrLoss: "Profit and loss",
        endTime: "Closing time",
        areYouSureToBuy: "Are you sure to buy?？",
        areYouSureToLongBuy: "Are you sure you want to buy Long?",
        areYouSureToShortBuy: "Are you sure you want to buy short?",
        successfullyBought: "Buy successfully",
        minBuy: "Minimum buy-in",
        assets: "Assets",
        withdraw: "Withdraw",
        entrustedOrder: "Order",
        total: "Total",
        continueToPlaceOrder: "Continue to place an order",
        orderConfirmation: "Order Confirmation",
        buyRu: "buy",
        sellChu: "sell",
        areYouSureToSell: "Are you sure to sell?？",
        operation: "operate",
        buyCover: "cover position",
        commitSuccess: "Transaction successful",
        onlyEnterIntegers: "Only integers can be entered",
        trend: "Trend",
        lotteryTime: "Deadline for ordering",
        countdown: "Countdown",
        period: "Issue",
        cycle: "Time period",
        unlimited: "Unlimited",
        put: "Put",
        call: "Call",
        pending: "On commission",
        unopened: "Not drawn yet",
        opened: "Prize drawn",
        settlementPrice: "Closed Price",
        rateOfReturn: "Rate of return",
        currentPositions: "Current position",
        positionQuantity: "Position quantity",
        guarantee: "Margin",
        text1: "CALL",
        text2: "PUT",
        text3: "Waiting to buy",
        text4: "Order quantity",
        text5: "The number of transactions",
        text6: "Cancel",
        text7: "delivery time",
        text8: "Cancellation reminder",
        text9: "Are you sure you want to cancel the order?",
        text10: "Illegal quantity",
        text11: "Order reminder",
        text12: "Are you sure you are buying bullish?",
        text13: "Are you sure about buying the put?",
        text14: "Cancellation successful"
    },
    mining: {
        title1: "The world's leading blockchain digital asset mining platform",
        title2: "Open and transparent · safe and stable",
        title3: "Please select currency",
        title4: "Mining Annualized Yield",
        title5: "Day",
        title6: "Mining period",
        title7: "Product Details",
        btn: "Mine now",
        btn2: "closure",
        tabs1: "in progress",
        tabs2: "redeemed",
        table1: "product name",
        table2: "Mining period",
        table3: "Mining time",
        table4: "Mining amount",
        table5: "Expire date",
        table6: "Proceeds to maturity",
        table7: "Order Status",
        egularMining: "Mining regularly",
        inProgress: "in progress",
        redeemed: "redeemed",
        redemption: "redemption",
        regularMining: "Mining regularly",
        earlyRedemption: "early redemption",
        need: "need",
        handlingFee: "Handling fee",
        redemptionConfirmationMessage: "Please confirm whether to redeem in advance？",
        form1: "Mining Annualized Yield",
        form2: "Mining period",
        form3: "Mining currency",
        form4: "Subscription amount (minimum/maximum)",
        form5: "Early redemption fee: ",
        form6: "purchasing price",
        form7: "Available funds",
        form8: "purchasing price",
        form9: "Available funds",
        form10: "Please enter the purchase amount",
        form11: "all",
        form12: "Estimated revenue",
        form13: "Insufficient funds available",
        form14: "Please enter the purchase amount",
        form15: "My Order",
        form16: "earnings",
        form17: "order details",
        form18: "Instructions for early redemption:",
        form19: "Subscription amount (minimum)",
        form20: "Subscription amount (maximum)",
        form21: "Quantity held",
        form22: "Buying time",
        form23: "redemption time",
        form24: "over",
        form25: "Subscription successful",
        form26: "Redemption successful",
        form27: "Easy financial management with over 25% returns, outperforming the market, and you can also receive rewards after purchasing",
        form28: "Invite friends to receive a reward of 50 USDT, and contract vouchers can also return 20% commission."
    },
    assets: {
        content1: "Account assets",
        content2: "Today's earnings",
        content3: "current account",
        content4: "The actual account is your actual account assets, which can be used for transactions and withdrawals.",
        content5: "Withdraw coins",
        content6: "Deposit coins",
        content7: "Exchange",
        content8: "Transfer",
        content9: "Please choose",
        content10: "Demo account",
        content11: "Actual account",
        content12: "Acquire assets",
        content13: "Are you sure you want to switch to a real account?？",
        content14: "Are you sure you want to switch to a demo account?？",
        content15: "get success",
        table1: "Currency",
        table2: "quantity",
        table3: "freeze",
        table4: "Available",
        dialog1: "Product Details",
        dialog2: "1、This asset is only allowed to be used in demo account mode",
        dialog3: "2、To prevent abuse, only one redemption is allowed within a month.",
        dialog4: "3、Receiving assets will add the amount of assets received on the original basis and will not change the position status.",
        dialog5: "4、If you have any other questions, please contact customer service",
        dialog6: "closure",
        dialog7: "Get demo assets now",
        dialog8: "transfer in",
        dialog9: "transfer out",
        dialog10: "switch",
        dialog11: "Valuation",
        dialog12: "Demo assets",
        dialog13: "My account",
        dialog14: "Total account assets",
        dialog15: "Asset overview",
        dialog16: "Total asset valuation",
        dialog17: "Asset Valuation",
        dialog18: "Today's earnings",
        dialog19: "Freeze",
        dialog20: "You can receive simulated asset quota every month",
        dialog21: "receive",
        dialog22: "You have received successfully",
        dialog23: '30 days cumulative income',
        dialog24: "Financial product",
        dialog25: "My financial management",
        dialog26: "Historical financial management",
        dialog27: "history record",
        dialog28: "Funding records"
    },
    validator: {
        dialog14: "Validator",
        dialog15: "Bind validator",
        dialog16: "Validator switch",
        dialog17: "Verification code",
        dialog18: "Validator QR code",
        dialog19: "Validator key",
        dialog20: "Save",
        dialog21: "Please select a validator",
        dialog22: "copy key",
        dialog23: "Saved successfully",
        dialog24: "Please enter verification code",
        dialog25: "Friendly reminder: Before confirming the save, please open the corresponding APP and scan the QR code below or add the key manually.！",
        dialog26: "Activate your authenticator to provide maximum security for your account。",
        dialog27: "You will need to enter a unique verification code accessed via your mobile phone every time you log in or withdraw money.。",
        dialog28: "Set up validator",
        dialog29: "help",
        dialog30: "You have not bound the authentication code. Get stronger protection with an authenticator。",
        dialog31: "The validator has not been set up yet, please set it up first",
        dialog32: "Go to settings"
    },
    recharge: {
        title1: "Don’t know how to use digital currency to buy？",
        title2: "Just three easy steps!",
        title3: "Select the digital currency you want to purchase from the drop-down menu and enter the purchase quantity or amount。",
        title4: "Choose your preferred payment method",
        title5: "After payment is confirmed, the digital currency you purchased will be automatically transferred to your account。",
        title6: "Please select the currency you want to recharge",
        title7: "Deposit record",
        table1: "Currency",
        table2: "quantity",
        table3: "Chain name",
        table4: "State",
        table5: "reason",
        table6: "time",
        table7: "View voucher",
        dialog1: "Please enter the verification code below",
        dialog2: "payment code",
        dialog3: "copy",
        dialog4: "address",
        dialog5: "Deposit amount",
        dialog6: "Transaction screenshot",
        dialog7: "important Notice",
        dialog8: "Please fill in the deposit amount",
        dialog9: "upload image",
        dialog10: "Please enter content",
        dialog11: "Please upload screenshots of recharge",
        dialog12: "Not reviewed",
        dialog13: "rejected",
        dialog14: "Deposited",
        tip1: "Submitted successfully",
        tip2: "Copied successfully",
        tip3: "Copy failed",
        tip4: "Please select the currency you want to recharge",
        tip5: "1.The above deposit address is the official payment address of the platform. Please look for the official deposit address of the platform. Any loss of funds caused by mistaken deposit will be borne by you.；",
        tip6: "2.Please make sure your computer and browser are secure to prevent information from being tampered with or leaked.；",
        tip7: "3.After you recharge the above address, the entire network node needs to confirm before the account can be received.；",
        tip8: "4.Please select the above token system and currency and transfer the corresponding amount for deposit. Please do not transfer any other irrelevant assets, otherwise it will not be retrieved.。",
        tip9: "Save QR code",
        tip10: "Recharge",
        tip11: "important Notice",
        tip12: "Offsite links",
        tip13: "Recharge QR code",
        tip14: "Next step",
        tip15: "Recharge record",
        tip16: "Recharge details",
        tip17: "Deposit address",
        tip18: "Quick payment",
        tip20: "Quick payment",
        tip22: "Submitted, waiting for confirmation",
        tip23: "waiting for payment",
        tip24: "Amount",
        tip25: "Payment has been submitted, waiting for block confirmation",
        tip26: "Payment declined",
        tip27: "Payment Fail",
        tip28: "Payment successful",
        bankChannel: "Bank card channel",
        tip29: "Historical searches",
        tip30: "Currency list",
        tip31: "Select network",
        tip32: "When you recharge this currency, please only recharge through the supported networks below. If you recharge through other networks, funds will be lost.",
        tip33: "When you withdraw this currency, please only withdraw through the supported networks below. If you withdraw through other networks, funds will be lost.",
        tip34: "Quick recharge",
        tip35: "Scan code to recharge",
        tip36: "Manual recharge",
        tip37: "Recharge network",

        tip38: "Recharge address",
        tip39: "Recharge amount",
        tip40: "Transaction receipt upload",
        tip41: "upload image",
        tip42: "confirm submission",
        tip43: "confirm payment",
        tip44: "Note: Please confirm the address you need to recharge. We are not responsible for the loss of money if you fill it in incorrectly.",
        tip45: "remind",
        tip46: "Address copied successfully!",
        tip47: "Address copy failed!",
        tip48: "Insufficient available balance",
        tip49: "The recharge was submitted successfully and is waiting for confirmation on the chain.",
        tip50: "Recharge failed",
        tip51: "The recharge amount is wrong",
        tip52: "Connection failed",
        tip53: "Wallet not logged in",
        tip54: "Connection refused",
        tip55: "Submitted successfully, waiting for customer service review and approval",
        tip56: "Please upload a screenshot of the transaction receipt",
        tip57: "Submission Failed",
        tip58: "Recharge record",
        tip59: "Withdrawals record",
        tip60: "Recharge currency",
        tip61: "Withdrawal currency",
        tip62: "Recharge address",
        tip63: "Screenshot shows",
        tip64: "Click to enlarge image",
        tip65: "Recharge time",
        tip66: "Reason for rejection",
        tip67: "Withdrawal Amount",
        tip68: "Amount received",
        tip69: "Cash withdrawal network",
        tip70: "Withdrawal address",
        tip71: "Withdrawal time",
        tip72: "closure",
        tip73: "Transaction screenshot",
        tip74: "Pending review",
        tip75: "rejected",
        tip76: "completed",
        tip77: "rejected",
        tip78: "Audited",
        tip79: "Disbursed",
        tip80: "extract",
        tip81: "Transfer network",
        tip82: "Address/Invoice",
        tip83: "Withdraw all",
        tip84: "Please do not withdraw money directly to the crowdfunding or ICO address, otherwise you will not be able to receive the tokens issued by the crowdfunding or ICO.",
        tip85: "Arrival quantity",
        tip86: "Confirm withdrawal",
        tip87: "Wrong withdrawal address",
        tip88: "Wrong withdrawal amount",
        tip89: "The amount to withdraw is at least",
        tip90: "The maximum withdrawal amount is",
        tip91: "Withdrawal request has been submitted",
        tip92: "address list",
        tip93: "You haven't added an address yet",
        tip94: "Add it now",
        tip95: "Add address",
        tip96: "add new address",
        tip97: "please choose",
        tip98: "address",
        tip99: "Please select a transfer network",
        tip100: "Please enter address",
        tip101: "Address added successfully",
        tip102: "In transaction",
    },
    fundExchange: {
        title1: "How to exchange funds？",
        title2: "Select the appropriate currency to be converted。",
        title3: "Select the appropriate currency to convert。",
        title4: "Enter the amount to be exchanged and click Submit to complete the fund exchange.。",
        title5: "From",
        title6: "To",
        title7: "Exchange quantity",
        title8: "exchange rate",
        title9: "Available",
        title10: "Please enter the redemption quantity",
        title11: "There is no corresponding trading pair for exchange.",
        title12: "Please enter the quantity to be redeemed",
        title13: "Redemption successful",
        title14: "Exchange record",
        table1: "Use currency",
        table2: "quantity",
        table3: "exchange rate",
        table4: "Exchange currency",
        table5: "Receipt",
        table6: "Time",
        title15: "From",
        title16: "There are currently no assets available, please select another"
    },
    fundTransfer: {
        title1: "How to transfer funds？",
        title2: "Select the current account to be transferred。",
        title3: "Select the account you want to transfer。",
        title4: "Enter the transfer amount and click Submit to complete the transfer.。",
        title5: "Currency",
        title6: "Transfer quantity",
        title7: "Please enter the amount to be transferred",
        title8: "Transfer record",
        title9: "Please select wallet type",
        title10: "Please select currency",
        title11: "Please select wallet type",
        title12: "Please select currency",
        title13: "Please enter the amount to be transferred",
        title14: "Submitted successfully",
        table1: "Transfer out account",
        table2: "transfer currency",
        table3: "Transfer quantity",
        table4: "transfer account",
        table5: "time",
        table6: "transfer",
        table7: "Fund transfer",
        table8: "Transfer to",
        table9: "Transfer record",
        table10: "Transfer details",
        table11: "Transfer successful",
        table12: "Transfer failed",
        table13: "Current account cannot be transferred"
    },
    withdrawal: {
        title1: "important Notice",
        title2: "1.In order to prevent arbitrage, you can apply for currency withdrawal when the transaction volume reaches the quota.。",
        title3: "2.After submitting the withdrawal application, the money will arrive within 24 hours. If the money does not arrive after the expected withdrawal time, please consult the online customer service.。",
        title4: "3.After submitting the withdrawal application, the funds are frozen because the withdrawal is in progress and the funds are temporarily held by the system. This does not mean that you have lost the asset or that there is an abnormality with the asset.。",
        title5: "Currency",
        title6: "wallet address",
        title7: "Amount of coins withdrawn",
        title8: "Coin withdrawal record",
        title9: "Please select currency",
        title10: "Please select chain type",
        title101: "",
        title11: "please enter",
        title12: "wallet address",
        title13: "Submitted successfully",
        title14: "under review",
        title15: "Review successful",
        title16: "Audit failure",
        title17: "Disbursed",
        title18: "Please enter or paste your wallet address",
        title19: "Please enter the withdrawal amount",
        title20: "Handling fee",
        title21: "Actual arrival",
        table1: "Currency",
        table2: "Amount of coins withdrawn",
        table3: "Chain name",
        table4: "wallet address",
        table5: "State",
        table6: "reason",
        table7: "application time",
        table8: "Withdraw coins",
        table81: "Withdraw coins",
        table9: "Withdrawal address",
        table10: "Please select the currency you want to withdraw",
        table11: "Please make sure you enter",
        table12: 'Address (usually starts with "0x")',
        table13: "Withdrawable coins (handling fee deducted)：",
        table14: "Select currency",
        table15: "Encounter problems? Immediate feedback",
        table16: "balance",
        table17: "Withdrawal details",
        password: "Password",
        passwordTips: "Please enter password",
        passwordAg: "Confirm Password",
        passwordAgTips: "Please enter password again",
        oldPassword: "Old Password",
        oldPasswordTips: "Please enter old password",
        newPassword: "New Password",
        newPasswordTips: "Please enter a new password",
        passwordNotSame: "Passwords are inconsistent",
        withdrawPasswordSet: "Set withdrawal password",
        withdrawPasswordModify: "Change withdrawal password",
        withdrawPassword: "Withdraw password",
        withdrawPasswordTips: "Please enter the withdrawal password",
        setWithdrawPasswordFirst: "Please set a withdrawal password first",
        withdrawPasswordSetSuccess: "Setup successful",
        withdrawPasswordModifySuccess: "Successfully modified",
        submit: "submit",
    },
    customer: {
        content1: "Online service",
        content2: "Customer service",
        content3: "Please enter your contact information",
        content4: "Please enter content",
        content5: "Send",
        content6: "Sending failed, network connection failed",
        content7: "Picture",
        content8: "Message is empty",
        content9: "My share",
        content10: "Share link",
        content11: "Save Picture",
        content12: "My earnings",
        content13: "Level description",
        content14: "Team size",
        content15: "Invitation income",
        content16: "Rule description",
        content17: "membership level",
        content18: "The recharge amount is greater than",
        content19: "Team level",
        content20: "Team Level & Income",
        content21: "To meet the conditions",
        content22: "Distribution income",
        content23: "level team",
        content24: "level, invitation",
        content25: "valid members",
        content26: "primary income",
        content27: "secondary income",
        content28: "Level 3 income",
    },
    standardContract: {
        increaseMargin: "Increase margin",
        increaseAmount: "Increase amount",
        Pleaseenterthemargin: "Please enter the margin amount to be increased",
        Pleaseenterthecorrect: "Please enter the correct deposit amount",
        Increasemarginsuccessfully: "Increase margin successfully",
        takeProfitPriceWrong: "Take Profit Price Wrong",
        stoppriceerror: "Stop price error",
        thetakeprofit1: "The take profit price must be higher than the market price",
        thetakeprofit2: "The stop loss price must be lower than the market price",
        thetakeprofit3: "The take profit price must be lower than the market price",
        thetakeprofit4: "The stop loss price must be higher than the market price",
        thetakeprofit5: "Wrong quantity",
        estimatedprofitloss: "Estimated profit and loss",
        positionAmount: "Number of positions",
        closeAmount: "Closing quantity",
        whenthelatest: "When the latest price is reached",
        whenthelatest2: ", the market price take-profit order will be triggered to close the current position. Expected profit and loss",
        whenthelatest3: ", the market stop loss order will be triggered to close the current position. Expected profit and loss",
        contract: "contract",
        ordercanceledsuccessfully: "Order canceled successfully",
        successfullyordered: "successfully ordered",
        positionopeningreminder: "Position opening reminder",
        wrongquantity: "Wrong quantity",
        quantitycannot: "The quantity cannot be greater than",
        modificationstoploss: "Modification of stop profit and stop loss successful",
        positionclosedsuccessfully: "Position closed successfully",
        orderCancellation: "Order Cancellation",
        orderCancellation2: "Are you sure you want to cancel the order?",
        tobeexecuted: "To be executed",
        expired: "expired",
        failed: "failed",
        contractTransaction: "Perpetual",
        increase: "Increase",
        openPosition: "Full warehouse",
        isolatedPosition: "Isolated position",
        limitOrder: "Limit",
        marketOrder: "Market",
        pleaseSelectLeverage: "Please select leverage",
        pleaseEnterQuantity: "Please enter quantity",
        canOpenMore: "Maximum position that can be opened",
        contractAmount: "Contract amount",
        openingFee: "Handling fee",
        currentPosition: "Position",
        historicalPositions: "Historical transactions",
        currentCommission: "Current order",
        historicalCommission: "Historical commission",
        buyPrice: "Buying price",
        income: "profit",
        quantity: "quantity",
        marginRate: "Margin rate",
        initialMargin: "Initial margin",
        estimatedPriceParity: "Forced liquidation price",
        operate: "operate",
        finishQuantity: "Transaction volume",
        averagePrice: "Average transaction price",
        pleaseEnterLeverage: "Please enter leverage",
        takeProfitAndStopLoss: "TP/SL",
        takeProfit: "TP",
        stopLoss: "SL",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Close position",
        marketLquidation: "Flat Price",
        cancel: "Cancel order",
        enterBuyPrice: "Please enter the buying price",
        areYouSureLquidation: "Are you sure to close the position??",
        confirm: "Sure",
        enterStopWinPrice: "Please enter the take profit price",
        enterStopLossPrice: "Please enter stop loss price",
        commissionTime: "Commission time",
        commissionPrice: "Commission price",
        commissionQuantity: "Total amount of commissions",
        volumeAlreadyTraded: "Amount completed",
        areYouSureToWinOrLoss: "Are you sure you want to stop profit and stop loss??",
        maxQuantity: "Maximum position",
        saleQuantity: "Position closed",
        salePrice: "average closing price",
        saleTime: "time",
        succeeded: "succeeded",
        cancelled: "Cancelled",
        piece: "open",
        triggerPrice: "trigger price",
        setWin: "Set take profit",
        setLoss: "Set stop loss",
        enterWinQuantity: "Please enter the take profit amount",
        enterLossQuantity: "Please enter the stop loss amount",
        planCommission: "Plan commission",
        enterWinTriggerPrice: "Please enter the take profit trigger price",
        enterLossTriggerPrice: "Please enter the stop loss trigger price",
        insufficientQuantity: "Insufficient quantity",
        enterTriggerPrice: "Please enter trigger price",
        tips1: "Your order price is higher than the current price, and the transaction will be executed directly at the current market price. Are you sure you want to buy?？",
        tips2: "Your order price is lower than the current price, and the transaction will be directly executed at the current market price. Are you sure you want to buy?？",
        type: "type",
        increaseQuantity: "Increase position",
        reduceQuantity: "Reduce positions",
        limitCommission: "limit order",
        tips3: "The stop loss price must be lower than the market price",
        tips4: "The take profit price must be higher than the market price",
        tips5: "The stop loss price must be higher than the market price",
        tips6: "The take profit price must be lower than the market price",
        tips7: "The stop loss price should be lower than the market price and lower than the order price",
        tips8: "The take profit price should be greater than the order price (can be smaller than the market price)）",
        tips9: "The stop loss price should be greater than the market price and greater than the order price",
        tips10: "The take profit price should be smaller than the order price (can be larger than the market price)",
        accountEquity: "Account Balance",
        unrealizedPL: "Unrealized Income",
        tips11: "Are you sure you are right",
        tips12: "Market price",
        tips13: "Close all positions",
        tips14: "Do not remind again",
        profitRate: "Closing rate of return",
        openTime: "Opening time",
        unwindTime: "Closing time",
        dealTime: "Transaction time",
        amountClose: "Amount of closed position",
        long: "Buy Long",
        short: 'Buy Short',
        orderQuantity: "Order quantity",
        entrustedMargin: "Entrusted margin",
    },
    spotGoods: {
        spotGoods: "Exchange",
        sellPrice: "selling price",
        orderRecord: "Flash redemption record",
        transactionTime: "Transaction time",
        maxBuy: "available for purchase",
        maxSell: "Available for sale",
        purchaseQuantity: "Purchase quantity",
        slippage: "Slippage",
        convertTips: "We will close the transaction at the best price, with slippage of0-5%。",
        changePrice: "Current exchange price",
        consume: "Consume",
        consumeAmount: "Consumption amount",
        get: "Get",
        getAmount: "Get the amount",
        changeTips: "Are you sure you want to redeem?？",
        confirm: "Confirm exchange"
    },
    news: {
        title: "News Center",
        redMore: "read more",
    },
    credit: {
        credit: "credit score",
        creditScore: "total credit score",
        latestEntry: "latest record",
        liveUpdate: "Live Update",
        systemEvaluation: "System evaluation",
        time: "date",
        name: "name",
        reason: "reason",
        scoreStory: "Credit score record"
    },
    borrowing: {
        borrowing: "loan",
        diversifiedMarket: "Diversified markets",
        totalSupply: "Total supply",
        totalBorrowings: "total borrowings",
        tips1: "This is the total amount you can borrow against your collateral, up to the borrowing limit。",
        tips2: "Your stable interest rate will remain the same for the life of your loan. Recommended for long term supply and users who like predictability。",
        totalSupplys: "total supply",
        supplyAPR: "supply rate",
        totalBorrowings: "Total borrowing",
        borrowAPR: "borrowing rate",
        check: "Check",
        market: "market",
        availableLiquidity: "Available liquidity",
        currencyPrice: "current currency price",
        supplyAssets: "supply assets",
        tips3: "Note: The interest rate below is a fixed interest rate. After the supply period ends, the principal plus income will be returned to your account.。",
        supplyCycle: "supply cycle",
        minimumSupply: "minimum supply",
        supply: "supply",
        borrowedAssets: "borrowed assets",
        tips4: "Things to know: To borrow money, you will need to provide any collateral assets or proof，",
        tips5: "Please see the mortgage asset description。",
        repaymentCycle: "repayment cycle",
        BorrowableAmount: "Available loan amount",
        borrow: "loan",
        accountInformation: "account information",
        walletBalance: "wallet balance",
        canSupply: "Available",
        AmountCanBeBorrowed: "Total loan amount available",
        borrowedAmount: "Borrowed amount",
        remainingAmount: "remaining balance",
        getBorrowednAmount: "Get loan limit",
        supplyOrder: "supply order",
        borrowedOrder: "loan order",
        supplyAmount: "supply amount",
        expectedProfits: "Expected return",
        supplyTime: "Supply time",
        expirationTime: "Expire date",
        borrowingAmount: "Loan amount",
        dueRepayment: "Repayment Amount",
        borrowingTime: "Release time",
        repaymentTime: "Repayment time",
        repay: "repayment",
        supplyCurrency: "supply currency",
        buyNow: "Buy now",
        supplyRecords: "supply records",
        borrowRecords: "Loan records",
        completed: "completed",
        borrowNow: "Borrow now",
        borrowCurrency: "Borrowing currency",
        borrowMin: "smallest",
        borrowMax: "Maximum",
        borrowRange: "Borrowing amount (minimum/maximum）",
        tips6: "Please repay in time. If it is overdue, an additional daily amount of (principal + interest) will be charged.",
        tips7: "for late fees。",
        pleaseEnterBorrowAmount: "Please enter the loan amount",
        interest: "Interest",
        tips8: "Are you sure you want to repay in advance?？",
        borrowChannel: "Borrowing channel",
        tips9: "Let's start by getting to know you",
        name: "name",
        lastName: "surname",
        birthDate: "date of birth",
        tips10: "A little more information, what is your address?？",
        familyAddress: "Home address (including unit number）",
        city: "City",
        country: "Nation",
        postalCode: "Postal code",
        pleaseEnterYourHomeAddress: "Please enter your home address",
        tips11: "Tell us about your financial situation",
        tips12: "We may need to confirm this information later, so please try to be as accurate as possible",
        yourMonthlyIncome: "your monthly income",
        enterYourMonthlyIncome: "Please enter your monthly income",
        extraIncomeOptional: "Additional income (optional）",
        enterExtraIncome: "Please enter your additional income",
        monthlyRentOrMortgagePayment: "Monthly rent or mortgage payment",
        enterMonthlyRent: "Please enter your monthly rent or mortgage payment",
        tip13: "Tell us if you have a mortgage",
        tip14: "If no mortgage guarantee is selected, the probability of passing the review is low.",
        isThereMortgage: "Is there a mortgage?",
        selectIsThereMortgage: "Please select whether there is a mortgage",
        selectMortgageType: "Please select mortgage type",
        yes: "yes",
        no: "no",
        uploadScreenshotOfTransaction: "Please upload transaction screenshots",
        tip15: "Let's keep in touch",
        tip16: "We may contact you at this number if we need further information about your loan application",
        tip17: "I have read and electronically signed my consent",
        tip18: "Terms of Use and Electronic Consent",
        tip19: "，This includes your consent for us to send you information about our",
        tip20: "Product and Service Terms of Use",
        tip21: "After filling in the information, please wait for the system assessment and review。",
        phoneNumber: "telephone number",
        enterPhoneNumber: "Please enter your phone number",
        tip22: "Please check the box to agree to the terms of use and electronic consent form",
        inReview: "under review",
        applicationAmount: "Application amount",
        reasonForRejection: "Reason for rejection",
        reapply: "Re-apply",
        applyStatus: "application status",
        currentOrder: "current order",
        interestRate: "interest rate"
    },
    yuebao: {
        filed1: "invest",
        filed2: "take out",
        filed3: "Daily income",
        filed4: "team acceleration",
        filed5: "Deposited",
        filed6: "Yesterday's earnings",
        filed7: "Total revenue",
        filed8: "Available funds",
        filed9: "Please enter the amount",
        filed10: "Liquidity mining",
        filed11: "Introduction",
        field12: "Minimum purchase price",
        field13: "The most you can buy",
        filed14: "Subscription",
        filed15: 'regular',
        filed16: 'annualized interest rate',
    },
    tipsPopover: {
        title: "Install this application",
        press: "according to",
        and: "and",
        scrren: "Add to home screen",
        hasInstall: "(If already installed, please close the window)",
    },
    follow: {
        title1: "Copy trading",
        follow_text1: "Initiate a follow-up order",
        follow_text2: "Release history",
        follow_text3: "Title",
        follow_text4: 'Trading pair',
        follow_text5: 'Purchase duration',
        follow_text6: 'Order time',
        follow_text7: 'Release time',
        follow_text8: 'Number of followers',
        follow_text9: 'Order amount',
        follow_text10: 'Operate',
        follow_text11: 'Invite',
        follow_text12: 'Follow the order direction',
        follow_text13: 'Set order conditions',
        follow_text14: 'Conditions for following orders',
        follow_text15: 'fixed amount',
        follow_text16: 'percentage',
        follow_text17: 'Invited people',
        follow_text18: "The other party's id",
        follow_text19: "Confirm to follow the order",
        follow_text20: "Your order amount (optional)）",
        follow_text21: "Please enter a title",
        follow_text22: "Please select a trading pair",
        follow_text23: "Please select the order direction",
        follow_text24: "Please select purchase duration",
        follow_text25: "Please select the order time",
        follow_text26: "Please select purchase quantity",
        follow_text27: "The maximum purchase quantity cannot be less than the minimum purchase quantity",
        follow_text28: "Minimum buy-in",
        follow_text29: "Most buy",
        follow_text30: "Create copy order successfully",
        follow_text31: "Trading pair list",
        follow_text32: "Already followed the order",
        follow_text33: "Please enter invitee information",
        follow_text34: "Invited",
        follow_text35: "Invited me",
        follow_text36: "Copying history",
        follow_text37: "Order amount range"
    },
    followHistory: {
        title1: 'Release history',
        title2: "Copying history",
        follow_h_text1: "The total amount of the order",
        follow_h_text2: "Total number of people following orders",
        follow_h_text3: "Number of releases",
        follow_h_text4: "winning rate",
        follow_h_text5: "Number of times to follow orders",
        follow_h_text6: "Profit from following orders",
        follow_h_text7: "direction",
        follow_h_text8: "win",
        follow_h_text9: "deficit",
    },
    "asset.index.1579E8B41F8D5134": "my assets",
    "asset.index.B3F6F05Dfbe431Cd": "simulation",
    "asset.index.B9Aeab4Ee8F33746": "Asset Valuation",
    "asset.index.583Df8Bd541Eb7E8": "earnings trends",
    "asset.index.B1254815D133Cc26": "7 days",
    "asset.index.2F615F6C029F01B7": "30 days",
    "asset.index.29439Fb58E4Bbe22": "withdraw",
    "asset.index.831Ab31568A78285": "Recharge",
    "asset.index.39F7A0D48D12E8C9": "transfer",
    "asset.index.B99063C5689Beaec": "Exchange",
    "asset.index.E1Dc620F2F038B2A": "Receive assets",
    "asset.index.972A0B4C2B4393D0": "my account",
    "asset.index.9Dfb0Fb334620733": "Available Balance",
    "asset.index.Cdc5Fbd26790Cb47": "frozen amount",
    "asset.index.3D8132D72Ae965Cd": "Valuation",
    "asset.receive.825503Cc430559Cb": "acquire assets",
    "asset.receive.54154A0553A535B7": "Demo assets",
    "asset.receive.F2E57B00A9C5D129": "1.This asset is only allowed to be used in demo account mode",
    "asset.receive.Ea0D1D52989299Bd": "2.To prevent abuse, only one redemption is allowed within a month.",
    "asset.receive.090E0037Aa235D3D": "3.Receiving assets will add the amount of assets received on the original basis and will not change the position status.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.If you have any other questions, please contact customer service",
    "asset.receive.Df5B3A511D986575": "Get demo assets now",
    "asset.records.1Fa02E8187A39592": "Last three months",
    "asset.records.7B291C37333Fe34D": "From near to far",
    "asset.records.A476Cd1Ec868Fc5F": "Last three months,",
    "asset.records.9D5755A842E55Bb7": "From near to far,",
    "asset.records.E3Fa05E607203Cdc": "Recharge",
    "asset.records.B4F206C6Bec08Dea": "Withdraw",
    "asset.index.Df5B3A511D986576": "You have successfully received the demo assets",
    "deposit.depositSpeedy.Efe81D0E0174239B": "Payment method",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Fiat currency",
    "deposit.depositSpeedy.16658A855F07D171": "Amount",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Confirm",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Cannot be empty",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minimum recharge",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maximum recharge",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Deposit USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Recharge currency",
    "deposit.depositWallet.B404C543712B71D5": "Recharge network",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Deposit address",
    "deposit.depositWallet.D3980D54Acf242Db": "Deposit address",
    "deposit.depositWallet.A06E84801D6582Be": "Recharge amount",
    "deposit.depositWallet.Daaaeb1B7B22B126": "Hint",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Recharge successful",
    "deposit.depositWallet.4F48Abbafce532Bb": "Submitted successfully, waiting for customer service review",
    "deposit.depositWallet.A88C481228811696": "You declined to switch networks",
    "deposit.depositWallet.C6Ebf27895C21932": "Please manually open the TRON wallet plug-in and log in",
    "deposit.index.B5491C8Dd09D8183": "Select the currency you want to recharge",
    "deposit.index.2F784B2298D39987": "Quick recharge",
    "deposit.index.0Daa03Dcdcabd837": "Offsite links",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Withdrawal confirmation",
    "withdraw.bottomSheet.A086Fa20082C849D": "Please make sure the information below is correct",
    "withdraw.bottomSheet.Bf8B184D32971501": "Withdrawal network",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "Address",
    "withdraw.bottomSheet.F9Db93B87E08763B": "Quantity",
    "withdraw.bottomSheet.4Ad26475002878C0": "Handling fee",
    "withdraw.index.A96454041169Af6D": "Select currency",
    "withdraw.index.2A563B7081Dd5B83": "Withdrawal address",
    "withdraw.index.B189C2Ef713Cfff4": "Amount of coins withdrawn",
    "withdraw.index.558F35140E84B562": "Available：",
    "withdraw.index.F4E759087E12Cb77": "Withdraw all",
    "withdraw.index.5Cbd9A6B284D25Ae": "Fund password",
    "withdraw.index.B89Cbe4Bfc33Bb41": "Handling fee:",
    "withdraw.index.C886De8D8A27026E": "important Notice",
    "withdraw.index.Eac7C5A4095F5606": "1.In order to prevent arbitrage, you can apply for currency withdrawal when the transaction volume reaches the quota.。",
    "withdraw.index.C3Cc7Fff24504Be9": "2.After submitting the withdrawal application, the money will arrive within 24 hours. If the money does not arrive after the expected withdrawal time, please consult the online customer service.。",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.After submitting the withdrawal application, the funds are frozen because the withdrawal is in progress and the funds are temporarily held by the system. This does not mean that you have lost the asset or that there is an abnormality with the asset.。",
    "withdraw.index.4639F3A6E07C00B3": "Next step",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Please enter or paste your wallet address",
    "withdraw.index.25768141C8Bdea91": "Please enter the withdrawal amount",
    "withdraw.index.97A85734Cd1B7Bf0": "Please enter the fund password",
    "withdraw.index.39C7D74B33241C78": "Withdrawal amount is wrong",
    "withdraw.index.86A5D17908197525": "Withdrawal confirmation, waiting for review to release coins",
    "withdraw.index.48Fb603C6638C7F6": "Please set a fund password",
    "withdraw.index.873Ade9A3Bacc2F7": "hint",
    "bill.index.5Bbb2A6Ac68D6B2E": "actual account",
    "bill.index.75751E0Eb8261C40": "Demo",
    "bill.index.Cbc853B37Ecc2E97": "Account change type",
    "bill.index.4A0Cacddeb2E38B1": "account type",
    "bill.index.F16217028E098B2F": "Currency",
    "bill.index.A620Dbe6F06B78Ba": "All types",
    "bill.index.2C3C148Bb94D9B3F": "All accounts",
    "c2C.index.Ab45695E4Bf1E382": "C2C transaction",
    "c2C.index.2702Dc911Dfea762": "I want to buy",
    "c2C.index.D737D5B8367A53C4": "I want to sell",
    "c2C.index.C0C540A3Ba9Abe01": "Volume",
    "c2C.index.D8644E8D60C8Bd8A": "unit price",
    "c2C.index.Ebb8670B203D3604": "limit",
    "c2C.index.F8C6245A2Ebf1D27": "All bank cards",
    "c2C.index.F8C6245A2Ebf1D28": "currency collection",
    "c2C.index.F8C6245A2Ebf1D29": "bank card",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Buy",
    "c2C.index.Cc3Aa8141Da9Ec9F": "sell",
    "c2C.index.67A39834582Ad99A": "No data",
    "c2C.order.04C23810B47F77F7": "by amount",
    "c2C.order.6905365902E0A73D": "by quantity",
    "c2C.order.3F0Ecebbd9D2F23B": "Available",
    "c2C.order.16853Bda54120Bf1": "all",
    "c2C.order.Ad3335796F2C95F7": "lump sum",
    "c2C.order.61092E27A6879318": "To protect asset security, please raise awareness of prevention！",
    "c2C.order.0F51881F0Fb920B2": "Trading Information",
    "c2C.order.Dd74Ceca85E5Bc65": "Payment time limit",
    "c2C.order.De3341388A59073F": "30 minutes",
    "c2C.order.D334B597A352167F": "Seller nickname",
    "c2C.order.D334B597A352167G": "Buyer nickname",
    "c2C.order.9D5F89F99Ced890D": "means of transaction",
    "c2C.order.8Adf755B14Bc6B7C": "Please read the following first",
    "c2C.order.686B16F454A7338B": "Do not make any remarks when making bank card transfers, otherwise the money will not be released and the account will be blocked directly. After payment, you need to provide a new transaction details diagram after the payment (if the P diagram or hidden transaction details are reported to the platform to freeze the account）",
    "c2C.order.9Bec8Fba867B739B": "Select payment method",
    "c2C.order.Cf2D892C419Fea36": "minimum purchase",
    "c2C.order.7290A6B7456A03E0": "largest purchase",
    "c2C.orderConfirm.F7E99B8C203E2B07": "order details",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Order has been generated",
    "c2C.orderConfirm.0F5433503F3845Ce": "Please pay the seller within the countdown time。",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Waiting for buyers payment ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "contact seller",
    "c2C.orderConfirm.8E8140D7Ab607B25": "contact buyer",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "The seller's digital currency is now deposited in the escrow account, please feel free to pay  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Buyer's real name：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24 hour customer service support ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "order number",
    "c2C.orderConfirm.726C51702F70C486": "creation time",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Contact Customer Service",
    "c2C.orderConfirm.103408Fbbe30287F": "cancel order",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "I have confirmed payment",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Already paid",
    "c2C.orderConfirm.387Ca3625B260E4E": "Your paid order",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "cancel transaction",
    "c2C.orderConfirm.76C86566Cc3751C0": "Order canceled",
    "c2C.orderConfirm.205B349751D176B4": "payment successful",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "payment method",
    "c2C.paymentAdd.C87E865Df6800304": "Payment method configuration",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Payment method type",
    "c2C.paymentAdd.06910Bddf002695F": "actual name",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "PayPal account",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Bank account number/card number",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Currency name",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Pay QR code",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Click to upload",
    "c2C.paymentAdd.6Daae340F2E9718A": "Bank name",
    "c2C.paymentAdd.347751415C3Da489": "Chain name",
    "c2C.paymentAdd.15B3627Faddccb1D": "Remark",
    "c2C.paymentAdd.86Da96Cbd274058F": "Warm reminder: When you sell digital currency, the payment method you choose will be displayed to the buyer. Please confirm that the information is filled in accurately.。",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "save",
    "c2C.paymentAdd.90D2A3A5C07179C0": "please enter your real name",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Please enter your",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Please enter your bank name",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Please enter your chain name",
    "c2C.paymentAdd.94F034F0E01439E0": "Please enter your address",
    "c2C.paymentAdd.B12E035663A42B32": "All bank cards",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "currency collection",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Please upload the payment code",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Added successfully",
    "c2C.paymentDetail.86Da96Cbd274058F": "Warm reminder: When you sell digital currency, the payment method you choose will be displayed to the buyer. Please confirm that the information is filled in accurately.。",
    "c2C.paymentDetail.C4C613Da60226De4": "Successfully modified",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C payment method",
    "c2C.records.9E36893D5736Abb4": "Order List",
    "c2C.records.4B53F9A5B13B3235": "Pending payment",
    "c2C.records.4B53F9A5B13B3236": "cancel transaction",
    "c2C.records.4B53F9A5B13B3237": "Already paid",
    "contract.index.9706Df877458F0Be": "Full warehouse",
    "contract.index.623Fc7D3D40Aa479": "Initial margin",
    "contract.index.F92Dce1169330387": "Flat Price",
    "contract.index.3598B97Dd28Dbf20": "Please enter the quantity (pieces)",
    "contract.index.6E1Fae821Dc0B797": "Perpetual",
    "contract.index.Eb07Eb0C37C14575": "Delivery",
    "contract.index.Eb6492F19B1C1Fb6": "Historical transaction details",
    "contract.index.873Ade9A3Bacc2F7": "hint",
    "contract.index.Ff63B0F93D39F50B": "Are you sure you are right",
    "contract.index.Ff63B0F93D39F50D": "Buy Long",
    "contract.index.Ff63B0F93D39F50E": "short",
    "contract.index.Ff63B0F93D39F50F": "conduct",
    "contract.index.Ff63B0F93D39F50G": "Close all positions？",
    "customer.dialog.3D410C88E8A24Fe8": "Confirm nickname",
    "customer.dialog.4B3340F7872B411F": "Cancel",
    "customer.dialog.549B1456Bb5F98B3": "Please enter your nickname",
    "customer.dialog.B773B3E45Ccfad00": "Nickname is empty",
    "customer.index.7308C6B849D159E9": "Picture details",
    "delivery.callDialog.C74F562C3Fe91Fe3": "successfully ordered",
    "delivery.callDialog.B8C98F42Caa15Ca5": "direction",
    "delivery.callDialog.B79A581Dae543974": "Buy Long",
    "delivery.callDialog.B79A581Dae543975": "buy short",
    "delivery.callDialog.3B26F0F7E90773F3": "Opening price",
    "delivery.callDialog.65Fb252Bd94E1039": "Estimated revenue",
    "delivery.index.B483Ba4Fce633539": "Delivery Contract",
    "delivery.index.224Dd6Cb58D58249": "trading period",
    "delivery.index.28158F517E902788": "Minimum buy-in",
    "delivery.index.A1232162B14Bec76": "Delivery order",
    "delivery.index.445B1D3176D9C69D": "Historical orders",
    "delivery.index.Ab6Cb86E28653C5C": "",
    "delivery.index.20A7A6F14D3698C0": "Opening time",
    "delivery.index.Ec6821A49395Ddc2": "Closing time",
    "delivery.index.254Fdc665C391743": "Closing price",
    "delivery.index.2A0C5E68B44A472A": "Profit and loss",
    "delivery.index.E05B33309F6D236A": "Please enter quantity",
    "delivery.index.B2899E073Cb355D9": "Minimum buy",
    "download.description.869467A93C7Dd4A1": "Installation Notes",
    "download.description.C80C1Edd6Bee12Bc": "1.Allow description files to be downloaded",
    "download.description.2F5Bc180F92642B4": "2.Description file downloaded",
    "download.description.3C3B849F6Ccf712F": "3.Enter [Settings] on your phone and the downloaded description file will be displayed below the account information.",
    "download.description.86Affd862985E045": "4.Click to download the description file",
    "download.description.32832492Bfc1D95E": "5.Click to install",
    "download.index.245883C80F181C4A": "download",
    "download.index.Ef3De8Cb2722937A": "Start trading anytime, anywhere",
    "download.index.F4A87Eaeed691638": "Keep up to date with the latest news through our APP",
    "download.index.Ad3Aba52D0D39D01": "Download the installation package",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Kind tips",
    "download.safariGuide.2Cc68Aaadf623477": "Please visit in Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "You are visiting a decentralized website, please run in safari application",
    "download.safariGuide.06Da016F3E2F79Bd": "copy Link",
    "finance.index.B034B367545D5236": "Global high-quality projects",
    "finance.index.6Ebfac2C0A9228B0": "1.6 million+ active users, $122.4 billion",
    "finance.index.52Bdcaf19659363D": "Liquidity mining",
    "finance.index.D66924272B031Bf5": "Super high annualized income",
    "finance.index.34789D88Afcefa64": "View items",
    "finance.index.E12676B95654D21E": "credit loan",
    "finance.index.4C924Cfd89A11Ac7": "Discover more trading opportunities",
    "finance.index.C251D4B594Da2848": "AI quantification",
    "finance.index.4E53Cf2E5F173Dd3": "One-click leasing, AI smart transaction",
    "finance.records.Cce168301447D1Ba": "in progress",
    "finance.records.4587B6Bd7Cfa4454": "redeemed",
    "finance.records.18685418E3A50147": "Mining",
    "finance.records.Df9Fd438F97Cc908": "Day",
    "finance.records.E23Cb03C5Ad359E5": "the term",
    "finance.records.Bd87Dac6Cba7Eba8": "Purchase quantity",
    "finance.records.2D0Fff99265E8B1A": "Profitable",
    "finance.records.2D0Fff99265E8B1B": "Profit at maturity",
    "finance.records.024F5F0B851782De": "Mining time：",
    "finance.records.3143A26D3F983896": "redemption time：",
    "finance.records.96574Aaf8Db85Ead": "early redemption",
    "finance.records.531E1829203181Ac": "Are you sure you want to redeem early?;",
    "finance.records.9205843B8923Dac3": "Redemption successful;",
    "loan.applyCallback.B9258Ec8746A74D8": "Borrowing channel",
    "loan.applyForm.7B8Ed340400A6E2B": "name",
    "loan.applyForm.549A44Bae725F4A2": "surname",
    "loan.applyForm.2A27E61375E7Fea8": "date of birth",
    "loan.applyForm.89925B019D537738": "email address",
    "loan.applyForm.Afaeb32Cb4D62E28": "Home address (including unit number）",
    "loan.applyForm.E05B5D049B64B040": "City",
    "loan.applyForm.B166E4E8Fe9513Fa": "Enter verification code",
    "loan.applyForm.83Aaede109E395F8": "postal code",
    "loan.applyForm.647Ed21332A4968B": "your monthly income",
    "loan.applyForm.Cf0A9B845D34D4B4": "Additional income (optional）",
    "loan.applyForm.7048F618Acc6A54C": "Monthly rent or mortgage payment",
    "loan.applyForm.15C1Bba18Ae18545": "Tell us if you have a mortgage",
    "loan.applyForm.3921E90625A84E36": "If no mortgage guarantee is selected, the probability of passing the review is low.",
    "loan.applyForm.262Eec4F8906B0F6": "Is there a mortgage?",
    "loan.applyForm.8634Eb21B6910F21": "Please select mortgage type",
    "loan.applyForm.A4897B3A331B5E61": "Transaction voucher",
    "loan.applyForm.6623384C683De457": "Let's keep in touch",
    "loan.applyForm.A8360Bdba86834Cb": "We may contact you at this number if we need further information about your loan application",
    "loan.applyForm.C1C373F385A4C7B3": "telephone number",
    "loan.applyForm.1B9785Badb91191F": "After filling in the information, please wait for the system assessment and review。",
    "loan.applyForm.8755B9C761D4080C": "I have read and electronically signed my consent",
    "loan.applyForm.3E987B787468148C": "Terms of Use and Electronic Consent",
    "loan.applyForm.Bc54E76B61C50Fb9": "Please read and agree to the agreement",
    "loan.applyForm.89E158384F634Eb8": "Return to previous step",
    "loan.applyForm.Efa5A4548E2F3510": "Home address",
    "loan.applyForm.4Cadf1787541B35D": "yes",
    "loan.applyForm.Fab408D2F4100447": "no",
    "loan.applyForm.3Bad7A307480Cc56": "Email format is wrong;",
    "loan.applyForm.306322F49B36855D": "The application has been submitted, please wait for customer service review",
    "loan.applyForm.3C6E0E858C995Ff9": "Please upload transaction voucher",
    "loan.applyForm.2Bf443476B271376": "Up to 4 pictures can be uploaded",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Borrowing channel",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Get loan limit",
    "loan.applyQuota.9E0780F9352C7273": "wallet balance",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Total loan amount available",
    "loan.applyQuota.033A05462195B080": "Borrowed amount",
    "loan.applyQuota.9Aa89B036Ef4821D": "remaining balance",
    "loan.applyQuota.5656Fb52Ef17A557": "Get loan limit",
    "loan.applyQuota.6Ff6702B4C917D40": "under review",
    "loan.applyQuota.6Ff6702B4C917D41": "rejected",
    "loan.applyQuota.6Ff6702B4C917D42": "succeeded",
    "loan.applyQuota.0Bad0E857Ea4A952": "application time",
    "loan.applyQuota.3E7E56E2De2Cc546": "Application amount",
    "loan.applyQuota.8F1D170D9E54Af5B": "Application Progress",
    "loan.applyQuota.6167Ca035B93Caf3": "reason",
    "loan.applyQuota.410C7954F9A3981D": "Re-apply",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Please select currency",
    "loan.index.4C10307B22E885C3": "Discover more trading opportunities and create wealth",
    "loan.index.Ae8D37Dea2B3717B": "interest rate",
    "loan.index.1Bb54625682E85Db": "Rule introduction",
    "loan.index.5Bd31Fab681C6E9D": "see more",
    "loan.index.111F95F404814327": "Loan currency",
    "loan.index.6Bc2723B3Bec106D": "loan",
    "loan.index.3200106280E6E370": "interest rate",
    "loan.index.049A6Ccb6C81A635": "Loan amount (min/max）",
    "loan.productDetails.5037Ad86Ab4B7E02": "loan",
    "loan.productDetails.37181C74559Db182": "Order ",
    "loan.productDetails.5802Aaf06A1E4755": "Liquidity",
    "loan.productDetails.89Aea9Cf9337C40F": "Quantity available to borrow",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Quantity to be repaid",
    "loan.productDetails.C65F329Ef14C4A3D": "Amount of borrowed coins",
    "loan.productDetails.3A74667815Adc8Bf": "Maximum",
    "loan.productDetails.Ab851D8351573227": "Day",
    "loan.productDetails.Ddf82D8A2C8F46A0": "repayment cycle",
    "loan.productDetails.C105E5562005Aeb1": "Day",
    "loan.productDetails.3F346Be2137F0756": "Loan amount (min/max）",
    "loan.productDetails.2707F78Df864E194": "Interest",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Please repay in time. If it is overdue, an additional daily amount of (principal + interest) will be charged.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%for late fees",
    "loan.productDetails.4D94Faa033Be7356": "I have agreed",
    "loan.productDetails.62429A957A22780A": "Coin Lending Service Agreement",
    "loan.productDetails.Bc54E76B61C50Fb9": "Please read and agree to the agreement",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Borrow now",
    "loan.productDetails.59246A0Bf081D398": "least",
    "loan.productDetails.Ec3F1172B71Cd36C": "minimum amount",
    "loan.productDetails.7Ccc4Cf1D81930B3": "greatest amount",
    "loan.productDetails.071488598990Aa68": "Please read and agree to the agreement;",
    "loan.productDetails.579Dd216D3339119": "Loan successful;",
    "loan.records.4951B36Fd0D2A831": "Loan and credit history",
    "loan.records.Bb0Aaa30514B7Cba": "current record",
    "loan.records.0F8B0A7D80769051": "Repayment history",
    "loan.records.D4B42E43D88De6F1": "history record",
    "loan.records.Cb1847D6281897E6": "Loan amount",
    "loan.records.C7Bfcacfc2Adea58": "Repayment Amount",
    "loan.records.8B369164D5Fea340": "Loan time：",
    "loan.records.Ab2402Ad3Ffd35E7": "Pay off loan early",
    "loan.records.B7A0Ca0C195D0Cd1": "Release time：",
    "loan.records.42014Bdcfd9C411B": "Repayment time：",
    "loan.records.A0Acfaee54F06458": "completed",
    "loan.records.67A39834582Ad99A": "No data",
    "loan.records.6A687Ff5B8F1598F": "Are you sure you want to repay in advance?",
    "loan.records.7C20D7008D0Eae86": "Repayment successful",
    "mining.bottomSheet.Ba91637D32Ccba04": "Liquidity mining",
    "mining.bottomSheet.7De3D636A165E008": "Rate of return",
    "mining.bottomSheet.F3003345B189D683": "Mining period",
    "mining.bottomSheet.3E4C0A8C533F5848": "coin type",
    "mining.bottomSheet.Adcba368993Ae65E": "Subscription amount (minimum/maximum）",
    "mining.bottomSheet.94743B24E4Ed4166": "Unlimited",
    "mining.bottomSheet.286944Fedd211257": "Available funds",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Estimated revenue:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Mine now",
    "mining.bottomSheet.Bb1493C567C114Dd": "Kind tips：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Early redemption fee",
    "mining.index.8B2Bba1422Eed6F0": "Create tens of billions of wealth, 100% net income",
    "mining.index.42997E300086Ee89": "highest return",
    "mining.index.510F74F42052Ecce": "Mining products",
    "mining.index.B853F96626106912": "Go mining",
    "mining.productList.37181C74559Db182": "Order",
    "mining.productList.Aac5B810004C46C8": "regular",
    "mining.productList.D2Fb4F78C387D888": "You have successfully mined",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Confirm Order",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Rental amount",
    "quantify.bottomSheet.Fbd931286Be387Aa": "Cycle",
    "quantify.bottomSheet.9Ad84Db481807844": "indefinitely",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Daily rate of return",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "value date",
    "quantify.bottomSheet.88008603Ba7Bde64": "Purchase successful",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Get revenue share from now on！",
    "quantify.bottomSheet.C778A674C4A054F9": "check order",
    "quantify.bottomSheet.685Dad322B7029B0": "Return transaction",
    "quantify.buyForm.Acf8560A04334E95": "purchasing price",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Overview",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Dividend payment time",
    "quantify.buyForm.Fcda03Adbc5Fca97": "every day",
    "quantify.buyForm.Afb89504Fc399189": "Please enter the purchase quantity",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minimum purchase quantity",
    "quantify.buyForm.1B97F1730Bacd839": "Maximum purchase quantity",
    "quantify.index.32F56C9B7D0E250A": "Period: indefinite",
    "quantify.index.E34F028D00904630": "price",
    "quantify.index.E34F028D00904631": ",Daily rate of return",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Escrow amount",
    "quantify.productDetail.53635B53Bca31Dfa": "Estimated daily income",
    "quantify.productDetail.F9E46449A6269E09": "Cumulative income",
    "quantify.productDetail.98Fc46B1B8C993B2": "Order hosting",
    "quantify.productDetail.93624Bb045830966": "Rent now",
    "quantify.records.0C16A18C32B9Cf4A": "Order record",
    "quantify.records.B191F5614B0E2312": "Purchase History",
    "quantify.records.6A06033B86A73F7C": "redemption record",
    "quantify.records.45E0F7E71611618E": "Cumulative income",
    "quantify.records.45E0F7E71611618F": "Profit at maturity",
    "quantify.records.Aae1201E38C4Baaa": "Running",
    "flash.index.C41Fd4F2Fdfa594B": "Consume",
    "flash.index.1Ac59Fa2218E3B00": "Available:",
    "flash.index.Bf7233A660821549": "Get",
    "flash.index.548E775Dd67C7472": "Confirm exchange",
    "flash.index.066A2449Bd616F03": "Please enter the redemption quantity",
    "flash.index.Fca26Fcf9C06563F": "Flash redemption successful",
    "message.index.40031524233F4564": "Message Center",
    "message.index.8E5586210Fd44D69": "Perpetual position reminder",
    "message.index.Bcd3F470E88C197D": "No notification yet",
    "message.index.23A557Fbce545879": "Permanent Liquidation Notice",
    "message.index.B5E19B36B5125550": "Withdrawal reminder",
    "message.index.716A981465138334": "Recharge reminder",
    "message.index.884474Ebff2977Ba": "system information",
    "message.index.08Cc7D24752Ec42C": "online service",
    "message.index.1D820A6A19Ab025D": "7 X 24-hour online manual service",
    "more.index.29881E0B5Df58Bf7": "More features",
    "more.index.C77Ee35911D10D9D": "Common Functions",
    "more.index.3558F69B0260Be61": "Trading function",
    "more.index.7243Fe610Ed98Cec": "real account",
    "more.index.7243Fe610Ed98Ced": "Demo account",
    "more.index.693C7B35Bdf3E3A3": "finance",
    "more.index.F855997Fac114554": "Help Center",
    "more.index.Ae9A1252278Da742": "help",
    "more.index.Ace012504Bc529E0": "information",
    "more.index.05682Dbef5Eeb0A8": "customer service",
    "more.index.C7Af227A5D5C7Bb9": "You have switched to a live account",
    "more.index.D818A65592694786": "You have switched to a demo account",
    "notice.details.B360807Dbe727E6E": "about Us",
    "notice.details.92F2552F695E70E3": "about Us",
    "notice.details.7Aaceaf69B67F3D1": "Rule introduction",
    "notice.index.6128438Be34Bcb9F": "Perpetual notice",
    "notice.index.71627F5Eb7128261": "Withdrawal notification",
    "notice.index.2Dce570Fda841705": "Recharge notification",
    "notice.index.228A7C9C6448A9B1": "system notification",
    "setting.dialog.8B4F5B74660Deabe": "Color configuration",
    "setting.dialog.Bfc7D25486Ebc1A9": "Green rises and red falls",
    "setting.dialog.796Ea0E1A82C320B": "Red rises and green falls",
    "setting.index.8Ddc5864E1739466": "Preferences",
    "setting.index.F5205801D57D6727": "language",
    "setting.index.2E2C12Dd0Cc1B63B": "Quotation currency",
    "setting.index.322D8496Aec3D918": "version number",
    "setting.index.Aa7E6C204C751Cfa": "sign out",
    "share.index.75Bc38Bbf7D2E766": "invite friends",
    "share.index.45575Eb5D42213E6": "my invitation code",
    "share.index.29F67F0644A78Bf3": "copy",
    "share.index.19Cc65Bb6Bb98Fe7": "My invitation link",
    "share.index.5372712F162Fb78B": "Number of direct subordinates",
    "share.index.C88D6B95E3Ab7D6F": "Total number of subordinates",
    "share.index.Bb5Fa5471Aeecc62": "Commission rebate for direct subordinates",
    "share.index.426B6Bac9A5F3D4F": "total rebate",
    "share.index.197E4A428F0401C9": "View rebate records",
    "share.records.88A6B2E2B74B13C4": "Commission record",
    "share.records.B671A35Ffdc93B89": "Rebate",
    "transfer.index.B17617A869C03Ca5": "wallet",
    "transfer.index.E4116Dcc6F6C7270": "Transfer successful",
    "transfer.index.48Fb603C6638C7F6": "Please set a fund password",
    "transfer.records.Eb0E39Eb5911D418": "get",
    "transfer.records.977554Be0Aa65052": "Exchange time：",
    "transfer.records.9Aa33884Aa400B7C": "transfer time：",
    "form1.forget.76636Db8Fae5B124": "your mailbox",
    "form1.forget.35354B9Cca1Bb0E6": "E-mail verification code",
    "form1.forget.604A0B11E916F387": "Password",
    "form1.forget.9Cb1C9C51E7503D6": "Confirm Password",
    "form1.forget.3A4F7E04B008453C": "Your phone number",
    "form1.forget.Cf11Dce5B4713B5A": "Mobile phone verification code",
    "form1.forget.3012Edba57D4F297": "Please enter your email",
    "form1.forget.F1Ee1D97E869E447": "Please enter verification code",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Please enter your password",
    "form1.forget.C6Cb174Da411D5F7": "Please enter your mobile phone",
    "form1.forget.947B3B21Ee45E582": "Send",
    "form1.forget.3E0580F1E8630Df6": "Two passwords are inconsistent",
    "form1.forget.C5A61836B8Cc74C6": "Password change successfully redirected...",
    "form1.forget.B96D072Bc8962565": "Resend",
    "form1.login.2B72C27Ff828Cfc4": "your password",
    "form1.login.D7C6B872Af9Cb17F": "Remember my password",
    "form1.login.0Fff44C827A4F3B6": "Log in",
    "form1.login.1B7F2C1Ede3990Cc": "Don’t have an account yet? Sign up now",
    "form1.login.F657F7Be1Cc33Bd2": "Forget the password",
    "form1.login.2074A1Eec4B088B0": "Wallet login",
    "form1.login.1B8938Fbcc3C9B56": "Login successful and redirecting...",
    "form1.login.07162A7Bb9310555": "Please install ERC20 wallet",
    "form1.login.766969966Ae151Dc": "Please install TRC20 wallet",
    "form1.login.B88171E1Ad4C50Bf": "You declined to switch networks",
    "form1.register.Aaef0710Cb4Db554": "Enter password again",
    "form1.register.54Dfea0415C708Ff": "Invitation code",
    "form1.register.6839940Dac08C794": "register",
    "form1.register.9901A3B5550Aa58E": "Already have an account? Log in",
    "form1.register.2548Ca53785252Ea": "Please enter your invitation code",
    "form1.register.Dd71Fd65900Dd03E": "Please read the Registration Service Agreement and agree,",
    "form1.register.7975613909490B77": "Registration successful and redirecting...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Choose wallet",
    "userc.certification.2Af9511640153D2E": "Junior Certification",
    "userc.certification.177B191D8E0Fa84D": "not certified",
    "userc.certification.601218D4D572E98A": "verified",
    "userc.certification.6E37C51Ba9012Fc8": "Did not pass",
    "userc.certification.1B9B563E05C96474": "Advanced certification",
    "userc.certification.1418B83803Ab56B4": "Please complete the primary certification first",
    "userc.creditScore.0B149112601Acc4D": "credit score",
    "userc.creditScore.88Ac7F84E106C5F6": "total credit score",
    "userc.creditScore.3E1D6E0E7F1E6155": "System evaluation",
    "userc.index.62Eac58D04Bbca0F": "Personal center",
    "userc.index.F4Cac53F93Cb2Aff": "simulated trading",
    "userc.index.42F409Fdf3559773": "Security center",
    "userc.index.1B8E211009807Db8": "Authentication",
    "userc.index.Bdfcf21Eb8F2Eb70": "Account change records",
    "userc.index.6C18D67F6D19586B": "Platform Introduction",
    "userc.index.807A814Ed58A93Bc": "clear cache",
    "userc.index.E43Daad59759611C": "not certified",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Change login password",
    "userc.secureCenter.1F37Ba427Debf24A": "Account binding",
    "userc.secureCenter.F9A5Ffa750664004": "Bind Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Junior Certification",
    "userb.certificationForm.7685Af7D30043Cd7": "Advanced certification",
    "userb.certificationForm.70E37C35Abc3F462": "Country",
    "userb.certificationForm.F92C25A68E4D08C5": "City",
    "userb.certificationForm.0Ccc6387B38E0318": "Your Name",
    "userb.certificationForm.94C67Bba370Fdd46": "license number",
    "userb.certificationForm.B2D4C7Fffe79258E": "Mail",
    "userb.certificationForm.57554268761Fb47A": "Upload the front of your ID",
    "userb.certificationForm.D79E90201F5319Bd": "Upload the back of your ID",
    "userb.certificationForm.9C6Bad08Af29E20E": "Upload a picture of your ID card",
    "userb.certificationForm.F96B62Dfa31Cde45": "Confirm Submission",
    "userb.certificationForm.2125D78Ea8C6D287": "Upload requirements",
    "userb.certificationForm.0D64864D3076A824": "View example",
    "userb.certificationForm.35287Deb72281E87": "1.Must be able to read ID card information clearly。",
    "userb.certificationForm.3007F2E01Efc7B57": "2.The content of the photo is true and valid and no modification is allowed.。",
    "userb.certificationForm.25A90660554319F9": "3.The ID number and name must be clearly visible, support JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "please enter",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Submission successful, please wait for review",
    "userb.certificationForm.744Bd07D8Abd2513": "Please upload the front of your ID",
    "userb.certificationForm.6De1C319130F7Fef": "Please upload the back of your ID",
    "userb.certificationForm.79F8E8B172E30C8A": "Please upload a photo of your ID card",
    "userb.certificationForm.46412E16D28A4753": "Photo upload failed",
    "userb.changeDialog.676B0Adca1F8Eea4": "change password",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "phone number",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "validator switch",
    "userb.modifyInfoDialog.62149E566814154C": "Select validator",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Open the Authentication app and click+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Select 'Scan QR code' or 'Enter key'",
    "userb.modifyInfoDialog.93F094E2B4369472": "Scan the QR code or enter the key",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "close/open",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Old fund password",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "New fund password",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Confirm new fund password",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Confirm fund password",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Please enter your old fund password",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Please enter your new fund password",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Please enter your fund password",
    "userb.modifyInfoDialog.258A534498Fe984F": "Please confirm your fund password",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Account binding",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Fund password",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "confirm",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Validator is closed",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google verification turned on successfully",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Fund password modified successfully",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Set fund password successfully",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Binding successful",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Verification code error",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Certificate upload example",
    "userb.veBottomSheet.6F38A0D5858633Ee": "dark clean background",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Sufficient lighting",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Alignment auxiliary frame",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Photos are clear",
    "userb.veBottomSheet.50B741382E71F103": "Possible reasons for failure",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "The ID card protective cover has not been removed",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "The background is affected by clutter",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "The ID card is reflective",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "The light is too dark/obstructed by the shadow of the mobile phone",
    "userb.veBottomSheet.1A75D2Fc2614592B": "holding ID card",
    "home.home.C3525Eb110Db58Aa": "More",
    "home.home.29761532Fc8Ebecc": "trade",
    "home.home.F15Ea99Bfa6E1848": "Safety certification, buy with confidenceU",
    "home.home.46E1Ab39Bae7182A": "Quick",
    "home.home.Fde2Eb7F6F3Dd800": "Fast transactions and simple operation",
    "home.home.Ea4756Bc1642E0F1": "name",
    "home.home.B6B1127Ede1C97B4": "latest price",
    "home.home.3587Ad870Eb0Ab86": "24H increase and decrease",
    "home.home.Cb1A4E14Ec2Cd2B5": "Discover",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "APEX",
    "home.home.5Fc1474Dbf74Ac1A": "agricultural products",
    "home.home.135318F4664F7773": "energy",
    "kline.kLine.C96Ff250Cf6B8B84": "24H High",
    "kline.kLine.6E8Be64F19D74565": "24H Turnover",
    "kline.kLine.08A4Bfbe26A0F262": "24H Low",
    "kline.kLine.8701Deb075066331": "24H Turnover (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 min",
    "kline.kLine.B49Cca240E013124": "5 min",
    "kline.kLine.F27A90Be97Ba3D6C": "15 min",
    "kline.kLine.Ff3E62395B694384": "30 min",
    "kline.kLine.C1993F9C6Db1A674": "1 hour",
    "kline.kLine.Fd2Cbbae405E284C": "1 day",
    "kline.kLine.C9Cd71904395042C": "January",
    "kline.kLine.7D8127Ec18C7B8C7": "Buy",
    "kline.kLine.E3D3Ffdb9B3E5636": "Quantity (sheets）",
    "kline.kLine.6834Fec1838D8029": "price（USDT）",
    "kline.kLine.16C52Aae474Bc9Cd": "sell order",
    "kline.kLine.B9Bea1A9A88D1419": "Buy Long",
    "kline.kLine.D36Cfa96Ee2B343B": "Buy short",
    "other.languageRateSelect.6897784800834C1B": "language setting",
    "other.languageRateSelect.6897784800834C1C": "Please select pricing currency",
    "other.navigationBar.F6E62F9642018Cbb": "Home",
    "other.navigationBar.0Ec227161A47B49D": "Quotes",
    "other.navigationBar.29761532Fc8Ebecc": "trade",
    "other.navigationBar.693C7B35Bdf3E3A3": "finance",
    "other.navigationBar.9734C54620Eb09Af": "assets",
    "home.index.25Aa4Fc745150030": " King of Cryptocurrencies",
    "home.index.2D6Ecf8Faaa8B124": "Faster, better, stronger",
    "home.index.1Db26267D1041223": "Easily trade over 1700 cryptocurrencies",
    "home.index.F8E18C90C3E35618": "Register Now",
    "home.index.29761532Fc8Ebecc": "Trade",
    "home.index.D8A95E4506487452": " Popular Contracts",
    "home.index.5Bd31Fab681C6E9D": " See more",
    "home.index.010Ae07A09Caf221": "Trading pairs",
    "home.index.F4D875118E6D6Dbb": "Latest trading price",
    "home.index.3Dfdfb3D6599100E": " 24 Hour Range",
    "home.index.28Fb39Bd41E54242": "Trend chart",
    "home.index.B47A3911Bacd94C6": "Gainer list",
    "home.index.712910D574F43D95": "Stable earnings",
    "home.index.5507Fd830D7Bf51F": "Top strategies, easy to copy",
    "home.index.7De3D636A165E008": "Rate of return",
    "home.index.B853F96626106912": "Go mining",
    "home.index.213B5C96Be7Cd1F8": "days, annualized interest rate",
    "home.index.213B5C96Be7Cd1F9": "Maximum buyable",
    "home.index.Dc710Cffe6313Bb5": "check the details",
    "home.index.B92Ffc837Ecaa1Dd": "News and information",
    "home.index.6C18D67F6D19586B": "Platform Introduction",
    "home.index.6342E3C33Cb48Cd5": "Digital currency is a medium of exchange that uses cryptographic principles to ensure transaction security. Unlike fiat currencies such as the U.S. dollar, digital currencies do not have a physical form. Popular digital currencies such as Bitcoin (BTC), Ethereum (ETH) and Polkadot (DOT) use blockchain as the underlying technology to act as a decentralized digital ledger. All digital currency transactions are recorded in the blockchain and cannot be changed once confirmed and verified. Unlike traditional currencies, where banks maintain centralized ledgers, digital currency transactions occur on public blockchains that can be accessed by anyone.",
    "home.index.198Efc4254683Eef": "In addition, according to the consensus mechanism, everyone can verify digital currency transactions and add them to the blockchain, thus achieving decentralization. As leading global companies such as Samsung, BlackRock, Morgan Stanley and Alphabet have invested, people believe that blockchain technology will completely change the global financial system. Digital currency opens the door to the world of decentralized finance for you, allowing you to explore the infinite possibilities brought by cutting-edge technology.",
    "home.index.E710339D9576292E": "Our advantage",
    "home.index.F9Ce8B2C63885B26": "Safe and stable",
    "home.index.C4D0A2C9Ff0305Ce": "Top technical team, comprehensive security protection, independent research and development of high-speed transaction synthesis engine, can still operate stably and reliably under massive concurrent transactions.",
    "home.index.2E8708Ee3F11Cc1A": "Professional and reliable",
    "home.index.Bb12Cbcf89C56C5C": "Professional operation team, with many years of experience in blockchain and finance, holds a compliant digital asset trading license, and has a 100% reserve guarantee.",
    "home.index.Aa4723Bfbf6808F4": "Serve with heart",
    "home.index.1Bf0204B7D3Ee230": "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service.",
    "home.index.2E7Dfd53D26F7224": "High performance",
    "home.index.1C65453103B5Fcc3": "300,000 transactions per second and order response time of less than 1 millisecond.",
    "home.index.85Ac49Ece9Dcc80B": "Trade anytime, anywhere!",
    "home.index.2799E18842E4Ebe9": "Open a position instantly, supported by both OKEX App and web page!",
    "home.index.2Bf52Ce75E29Fc88": "Scan the code to download now",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple",
    "home.index.04Ee893775563F22": "Products Services",
    "home.index.04Ee893775563F23": "Financial management",
    "home.index.04Ee893775563F24": "Back",
    "home.index.04Ee893775563F25": "Waiting for result",
    "home.index.04Ee893775563F26": "IOS & ANDROID",

    "homepage.text1": "Buy Bitcoin, Ethereum and",
    "homepage.text1_2": "1000+ Cryptocurrencies",
    "homepage.text2": "Your Cryptocurrency Experience Journey",
    "homepage.text4": "High interest rate financial Management",
    "homepage.text5": "Stable financial management with high annualized returns",
    "homepage.text6": "Share Rewards",
    "homepage.text7": "Fast transactions and simple operation",
    "homepage.text8": "View more",
    "homepage.text9": "Instant Exchange",
    "homepage.text10": "Safety certification buy with confidence",
    "homepage.text11": "Top technical team, comprehensive security",
    "homepage.text12": "protection, independent",
    "homepage.text13": "Professional and reliable",
    "homepage.text14": "Professional operation team, with many years of experience in blockchain and finance",
    "homepage.text15": "Serve with heart",
    "homepage.text16": "Facing the global market, supporting multiple languages, running 24/7",
    "homepage.text17": "High performance",
    "homepage.text18": "300,000 transactions per second and order response time of less than 1 millisecond.",
    "passwod.limit":"Password length must be greater than or equal to 6 characters"
}
