import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "簡体字中国語",
        dialogDetermine: "もちろん",
        dreivatives: "配達",
        markets: "引用",
        assets: "資産",
        liquidity: "流動性マイニング",
        helpCentre: "ヘルプセンター",
        announcementCenter: "アナウンスセンター",
        about: "について",
        bulletinCentre: "アナウンスセンター",
        serviceAgreement: "役務契約",
        support: "サポート",
        onlineSupportCustomerService: "オンラインサービス",
        suggestionsAndFeedback: "提案とフィードバック",
        service: "仕える",
        download: "ダウンロード",
        coinApplication: "コイン上場申請",
        privacyStatement: "プライバシーに関する声明",
        trade: "契約取引",
        standardConteact: "持続可能な",
        signOut: "サインアウト",
        assetWallets: "アセットウォレット",
        financeRecords: "アカウント変更記録",
        identification: "認証",
        personalCenter: "パーソナルセンター",
        chooseLanguage: "言語を選択してください",
        tips: "ヒント",
        dark: "ダークモード",
        light: "デイモード",
        languageTitle: "言語",
        convertCurrency: "通貨を換算する",
        cancel: "キャンセル",
        aboutUs: "私たちについて",
        standard: "規格の継続性",
        share: "共有",
        introduce: "プラットフォームの紹介",
        set: "設定",
        changePassword: "ログインパスワードを変更する",
        denomination: "見積通貨",
        color: "カラー構成",
        code: "バージョンナンバー",
        color1: "赤が上昇し、緑が下降します",
        color2: "緑が上がり、赤が下がる",
        setLangue: "言語設定",
        vip: "VIP特典",
        vip2: "現在のVIPレベル",
        safeMenu: "セキュリティセンター",
        conventional: "従来の",
        other: "他の",
        quickTips: "ショートカット機能ボタンを3つまで追加可能",
        quickTransaction: "素早い取引",
        safeConvenient: "安全で便利",
        timeSharing: "時間を共有する",
        currentLevel: "現在のレベル",
        levelBenefits: "%{level} レベルの収入",
        teamLevel: "チームレベル",
        levelLower: "%{レベル}の部下",
        rules: "ルールの説明",
        bindWithdrawalAddress: "出金アドレスをバインドする",
        tipsBindWithdrawalAddress: "出金アドレスをまだバインドしていません。最初にバインドしてください",
        walletAddressFormatIncorrect: "ウォレットアドレスの形式が正しくありません",
        tradeRules1: "将来の期間の騰落を予測します。 注文時の価格を開始価格として使用し、予測期間の最後の 1 秒の価格を清算価格として使用し、清算価格が開始価格よりも高ければ上昇を意味し、予測期間の最後の 1 秒の価格は開始価格を意味します。決済価格が開始価格より低い場合、これは減少を意味します。",
        tradeRules2: "1.契約とは何ですか?",
        tradeRules3: "このプラットフォームはUSDTベースの永久契約を提供しており、ユーザーは上昇か下降かを判断し、ロング契約を買うかショート契約を売るかを選択して、デジタル資産価格の上昇/下降から利益を得ることができます。 永久契約には納期がなく、ユーザーは永久に契約を保持できます。",
        tradeRules4: "2.マージンとは",
        tradeRules5: "仮想契約市場では、ユーザーは契約履行に対する経済的保証として、契約金額に応じて一定割合の少額資金を支払うだけで、契約の売買に参加することができます。この種の資金が仮想契約保証金です。 \nこのプラットフォームの契約は、個別口座の個別証拠金モデルを採用しています。 各通貨は契約口座に対応しており、異なる契約通貨の口座資産とポジションは互いに独立しており、異なる契約口座間の送金や取引は相互に影響を及ぼしません。 ユーザーの契約口座が開設されると、契約口座に保持されているすべてのポジションのリスクとメリットが一緒に計算されます。",
        tradeRules6: "3.証拠金の計算方法",
        tradeRules7: "ポジション証拠金 = 契約額面 * オープン契約の数 / レバレッジ乗数\n凍結証拠金 = 現在の注文で正常に発注されたすべての注文と開始注文の証拠金の合計\n利用可能な証拠金 = 口座残高 - ポジション証拠金 - 凍結証拠金",
        tradeRules8: "4.証拠金率の計算方法",
        tradeRules9: "証拠金率はユーザー資産のリスクの尺度です。 \n証拠金率 = (含み損益 + ポジション証拠金 + 利用可能証拠金 + 凍結証拠金 - 清算手数料) / ポジション証拠金 \n証拠金率が小さいほど、口座のリスクは高くなります。 証拠金率が0%以下の場合は強制決済となります。",
        tradeRules10: "5.推定強制パリティ価格はいくらですか?",
        tradeRules11: "強制清算予定価格、つまり理論上強制清算を引き起こすシステムにより算出される価格は参考値であり、実際の価格とは若干異なる場合があります。",
        tradeRules12: "6.総口座資本と含み損益とは何ですか?",
        tradeRules13: "アカウント資本合計 = アカウント残高 + 未実現損益\n未実現損益は、通貨契約でユーザーが現在保有しているポジションの損益です。未実現損益は、最新の取引価格の変動に応じて変化します。 \nロング ポジションの含み損益 = (1/平均ポジション価格 - 1/最新の取引価格) * ロング ポジションの契約数 * 契約額面 * 最新の取引価格 \n ショート ポジションの含み損益 = (1/最新の取引価格 - 1/ 平均ポジション価格) * 空建枚数 * 契約額面 * 最新の取引価格",
        text1: "アカウントのセキュリティ",
        text2: "昼夜モード",
        text3: "キャッシュの消去",
        text4: "ログアウトしてもよろしいですか?",
        text5: "キャッシュをクリアしてもよろしいですか?",
        text6: "多言語",
        text7: "セキュリティを保護するために、少なくとも 1 つの 2 要素認証を有効にすることをお勧めします。"
    },
    login: {
        login: "ログイン",
        register: "登録する",
        title1: "いつでもどこでも取引し、リアルタイムの市場状況を確認",
        title2: "いつでもどこでも取引し、リアルタイムの市場状況を確認",
        title3: "最も信頼できる暗号通貨取引プラットフォーム",
        formTitle1: "アカウントログイン",
        formTitle2: "おかえり！ メールアドレス、電話番号でログインします",
        btn1: "携帯電話",
        btn2: "郵便",
        btn3: "ログイン",
        btn4: "登録する",
        btn5: "パスワードを忘れる？",
        field1: "電話番号",
        field2: "パスワード",
        field3: "郵便",
        placeholder1: "国家",
        placeholder2: "電話番号を入力してください",
        placeholder3: "パスワードを入力してください",
        placeholder4: "メールアドレスを入力してください",
        content12: "電話番号を入力してください",
        content13: "パスワードを入力してください",
        content14: "メールアドレスを入力してください",
        content16: "デジタル資産金融サービス",
        loginSuccess: "ログイン成功 ",
        chooseArea: "市外局番を選択してください",
        placeholder5: "国コードを選択してください",
        placeholder6: "私のパスワードを覚えておいてください",
        placeholder7: "アプリをダウンロードする",
        placeholder8: "カスタマーサービスにお問い合わせください",
        placeholder9: "国または地域を選択してください",
        placeholder10: "市外局番",
        placeholder11: "ウォレットへのログイン",
        placeholder12: "操作が失敗しました。に切り替えてください。",
        placeholder13: "財布"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "について",
        aboutCoinbeadCenterDetails: "世界をリードするブロックチェーン資産取引プラットフォームを持ち、エコシステム全体を運営しています。",
        footDetails1: "一日の取引高",
        footDetails2: "トランザクション/秒",
        footDetails3: "ヘルプセンター",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "世界的に有名なデジタル資産の国際ウェブサイトであり、主にビットコイン（BTC）、ライトコイン（LTC）、イーサリアム（ETH）などのコインやデジタル資産のデリバティブを世界中のユーザーに提供しています。",
        coinbeadCenterDetailsm: "GSLB、ネットワークサーバー、ネットワークトランザクション、ネットワーク、マルチマシン高速メモリトレーディングエンジン、コールドウォレット、インテリジェントオフラインホットウォレットなどの先進技術を採用し、Web携帯電話やPCなど複数の端末でお客様にサービスを提供します。 . 安全・安定・信頼性の高いデジタル関税取引サービスを提供します。 同時に、すべてのお客様により良いサービスを提供するために、ユーザーの提案やニーズに基づいて製品とサービスを改善およびアップグレードし、革新的なユーザーエクスペリエンスの向上に終わりはありません。",
        infrastructureCenterTitle: "インフラストラクチャー",
        infrastructureCenterDetails: "私たちの使命は、ブロックチェーンエコシステム全体にインフラストラクチャサービスを提供することです",
        freeCenterTitle: "無料",
        freeCenterDetails: "私たちのビジョンは、お金が世界中で自由に流れるようにすることです。 私たちは、この自由を広めることで世界をより良い場所にできると信じています",
        futureCenterTitle: "テクノロジーの未来を開拓する",
        futureCenterDetails: "テクノロジーの未来を切り開く",
    },
    applicationForListing: {
        title: "コイン上場申請",
        projectName: "プロジェクト名",
        tokenName: "トークン名",
        projectDescription: "プロジェクトの説明",
        contractAddress: "契約住所",
        officialWebsite: "公式ウェブサイト",
        ContactAndEmail: "連絡先メールアドレス",
        ContactAndName: "連絡先",
        file: "添付ファイルのアップロード",
        pleaseEnter: "入ってください",
        enterFile: "ファイルをアップロードしてください",
        upload1: "現在、選択できるファイルは 1 つまでに制限されていますが、",
        upload2: "今回私が選んだのは",
        upload3: "ファイル，",
        upload4: "選択された合計",
        upload5: "ファイル",
    },
    public: {
        submit: "提出する",
        copySuccessfully: "正常にコピーされました",
        copyFailed: "コピーに失敗しました",
        noDate: "データなし",
        submitSuccessfully: "正常に送信されました",
        goBack: "最後のページに戻る",
        cancel: "キャンセル",
        home: "表紙",
        loading: "読み込み中...",
        confirm: "確認する",
        loadingFailed: "ネットワーク異常",
        nomore: "もうない",
        tryAgain: "ページを更新するかプログラムを終了して、再度アップロードしてください。",
        hot: "人気エリア",
    },
    inviteFriends: {
        inviteFriends: "友達と共有する",
        freedomOfWealth: "双方に利益のある協力",
        myInvitationCode: "私の招待コード",
        clickCopy: "クリックしてコピー",
        myInvitationLink: "私の招待リンク",
        clicktoSaveTheQRCode: "クリックして QR コードを保存します",
        saveQRCode: "QRコードを招待",
        numberOfSubordinates: "部下の数",
        rebateIncome: "リベート収入",
        people: "人々",
        myPromotionRevenue: "私のプロモーション収入",
        numberOfDirectSubordinates: "直属の部下の数",
        totalNumberOfSubordinates: "部下の総数",
        directSubordinateReturnsCommission: "直属の部下に対するコミッションリベート",
        theTotalCommission: "合計リベート",
        checkHistory: "リベート記録の表示",
        history: "委託記録",
        theLowerAccount: "下位アカウント",
        type: "タイプ：",
        serviceCharge: "手数料：",
        percentageofCommissionReturned: "リベート率：",
        commissionrebateAmount: "リベート額：",
        time: "時間：",
        typeName1: "リチャージとコミッション",
        typeName2: "MT注文手数料の3段階配分",
        typeName3: "MT 収益性の高い 3 段階の分配",
        typeName4: "紹介報酬",
        typeName30: "リチャージとコミッション",
        typeName31: "定期取引利益リベート",
        typeName32: "流動性マイニング利益リベート",
        typeName45: "取引手数料のリベート",
        typeName46: "独自の手数料に対する手数料リベート",
        typeName50: "配送契約事務手数料のリベート",
        typeName51: "永久契約手数料リベート",
        typeName52: "配送契約事務手数料の追加手数料リベート",
        typeName53: "永久契約の手数料に対する追加の手数料リベート",
        typeName54: "永久契約手数料の追加リベート",
        shareRules1: "招待の手順",
        shareRules2: "招待センターにアクセスし、招待リンクまたはコードをコピーして、友達と共有します。 招待コードを登録することで友達を部下にすることができます。",
        shareRules3: "リベートを獲得する",
        shareRules4: "部下が取引を行うと、それに応じた手数料を得ることができ、部下のレベルは最大 3 段階までサポートされます。 たとえば、友人Aを招待し、AがBを招待し、BがCを招待した場合、A、B、Cはプラットフォーム上で契約やその他の取引を行い、全員が対応する手数料を受け取ることができます。",
        shareRules5: "チームレベル",
        shareRules6: "第一レベルの部下を多く昇進させるほど、チームのレベルが上がり、より多くのリベートを享受できます。 チームレベルはLV1～LV6に分かれており、強化ルールは下表の通り（Nはリチャージと実名認証を完了した第1レベル部下の数）。",
        shareRules7: "部下が納品契約に賭けると、賭けに応じてコミッションを受け取ることができます。",
        upgradeConditions: "必要とする",
        recommendedNumberOfPeople: "推奨人数"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "提案とフィードバック",
        text: "使用中にバグが発生したり、製品に関する提案がある場合は、フィードバックをお寄せください。",
        email: "電子メールアドレス",
        enterEmail: "メールアドレスを入力してください",
        enterCorrectEmail: "正しいメールアドレスを入力してください",
        title: "タイトル",
        enterTitle: "質問のタイトルを入力してください",
        describeTheProblem: "問題を説明してください",
        enterDescribeTheProblem: "説明する問題を入力してください",
        submit: "提出する",
    },
    help: {
        enterSearch: "検索",
        title: "ヘルプセンター",
        search: "質問を入力してください",
        text1: "お客様のニーズを理解し、より便利なサービスを提供し続けます"
    },
    announcement: {
        enterSearch: "検索",
        title: "アナウンスセンター",
        search: "質問を入力してください"
    },
    privacy: {
        title: "プライバシーに関する声明",
        content1: "1. ユーザーのどのような個人情報が収集されますか?",
        content2: "製品やサービスの提供、継続的な改善のために個人情報を収集します。",
        content3: "当社が収集する個人情報の種類は次のとおりです：",
        content4: "あなたが当社に提供する情報：当社は、プラットフォームに関連してあなたが提供するあらゆる情報を受け取り、保管します。 特定の情報を提供しないことも選択できますが、その結果、特定の「このプラットフォームのサービス」を使用できなくなる場合があります。 あなたが当社に提供する情報の例は次のとおりです。",
        content5: "名前",
        content6: "住所",
        content7: "国籍",
        content8: "証明写真",
        content9: "携帯電話番号/メール番号",
        content10: "お客様の特定に役立つその他の情報",
        content11: "自動的に収集される情報: 当社は、プラットフォーム サービスの使用に関する特定の種類の情報を自動的に収集および保存します。多くの Web サイトと同様に、Cookie およびその他の一意の識別子を使用します。 お客様の Web ブラウザまたはデバイスがプラットフォームにアクセスすると、当社は特定の種類の情報を取得します。 当社が収集および分析する情報の例は次のとおりです。",
        content12: "パーソナル コンピュータをインターネットに接続するインターネット プロトコル (IP) アドレス。",
        content13: "ログイン情報、電子メール アドレス、パスワード、および個人のデバイスまたはコンピューターの場所。",
        content14: "バージョンとタイムゾーンの設定；",
        content15: "取引履歴。",
        content16: "他の情報源からの情報: 当社は、信用調査機関の信用履歴など、他の情報源からお客様に関する情報を収集する場合があります。 当社はこの情報を不正行為の防止と検出に使用します。",
        content17: "2. 子供でも使えますか？？",
        content18: "本プラットフォームでは、18歳未満の者が「本サービス」を利用することはできません。",
        content19: "3. Cookie やその他の識別子は何に使用されますか?",
        content20: "当社は、ユーザー エクスペリエンスを最適化し、サービスを提供し、顧客が当社のサービスをどのように利用するかを理解して、的を絞った改善を行うために、Cookie および同様のツールを使用します。 当社は、当社のシステムがお客様のブラウザまたはデバイスを確実に認識し、お客様にサービスを提供できるようにするために Cookie を使用します。",
        content21: "当社は、オペレーショナル Cookie および同様のツール (総称して「Cookie」) を使用して、次のようなサービスを提供します。",
        content22: "ログインして当社のサービスを使用するときにお客様を識別します。",
        content23: "カスタマイズされた機能とサービスを提供します。",
        content24: "不正行為から保護する。",
        content25: "セキュリティの向上。",
        content26: "好み（通貨や言語など）を追跡します。",
        content27: "また、当社は Cookie を使用してユーザーが当社のサービスをどのように利用しているかを把握し、的を絞った改善を行うことができます。",
        content28: "4. 私の個人情報は共有されますか?？",
        content29: "ユーザー情報は当社のビジネスの重要な部分であり、ユーザーの個人情報を他者に販売することはありません。 このプラットフォームは、次の場合にのみユーザーの個人情報をその子会社または関連会社と共有します。これらの子会社または関連会社は、このプライバシー ステートメントを遵守するか、少なくともこのプライバシー ステートメントに記載されている保護措置と同じくらい保護的な慣行に従う必要があります。",
        content30: "サードパーティのサービスプロバイダー: 当社は、当社に代わって機能を実行するために他の企業や個人を雇用しています。 このような機能の例としては、データの分析、マーケティング支援の提供、支払いの処理、コンテンツの送信、信用リスクの評価と管理などが挙げられます。 これらのサードパーティ サービス プロバイダーは、その機能を実行するために必要な個人情報にアクセスできますが、それを他の目的に使用することはできません。 さらに、このプライバシーに関する声明および適用されるデータ保護法に従って個人情報を処理する必要があります。",
        content31: "事業譲渡: 当社の事業が成長し続けるにつれて、他の事業やサービスを売却または買収する場合があります。 このような取引では、ユーザー情報は通常、譲渡される事業資産の一部ですが、既存のプライバシーに関する声明の約束に従います (もちろん、ユーザーが別段の同意をしない限り)。 さらに、当社またはその実質的にすべての資産が買収された場合には、ユーザー情報も移転されることになります。 当社および他者の保護: 法律または当社の規制上の義務を遵守し、当社の利用規約およびその他の契約、または当社の権利、財産、または安全を強制または適用するために、アカウントおよびその他の個人情報の公開が適切であると当社が判断する場合。ユーザーまたはその他の個人情報を開示する場合、当社はアカウントおよびその他の個人情報を公開します。 これには、詐欺から保護し、信用リスクを軽減するために、他の企業や組織と情報を交換することが含まれます。",
        content32: "5. 個人データの国際転送",
        content33: "当社はお客様のデータを欧州経済領域 (「EEA」) 外に転送する場合があります。 データの転送先の国が適切なレベルの保護を提供していることを欧州委員会が確認した場合を除き、当社は、かかる転送が適用されるデータ保護規則に準拠することを保証するための適切な保護措置を講じます。",
        content34: "6. 私の個人情報は安全ですか?？",
        content35: "当社はお客様のセキュリティとプライバシーを念頭に置いてシステムを設計しました。 当社は暗号化プロトコルとソフトウェアを使用して、送信中の個人情報のセキュリティの保護に努めます。",
        content36: "当社は、個人情報を収集、保管、開示する際には、常に物理的、電子的、および手続き上の安全対策を講じます。 当社のセキュリティ手順により、個人情報をお客様に開示する前に、お客様の身元を確認する必要がある場合があります。",
        content37: "あなたができる最も重要なことは、個人アカウントのパスワードを不正アクセスから保護することです。 他のオンライン アカウントとは異なる独自のパスワードをアカウントに設定することをお勧めします。 また、共有パソコンを使用した後は必ずログアウトしてください。",
        content38: "7. 個人情報をどのように保護すればよいですか?？",
        content39: "当社の個人情報の収集および処理方法についてご質問または異議がある場合は、カスタマー サービス スタッフまでお問い合わせください。",
        content40: "当社が特定の目的でお客様の個人情報を処理することにお客様が同意した後は、いつでもその意図を撤回することができ、当社はその目的でのお客様のデータの処理を停止します。",
        content41: "さらに、お客様は、適用される法律に従って、個人データへのアクセス、修正および削除を要求し、データのポータビリティを要求する権利を有します。 また、お客様は、当社によるお客様の個人データの処理に異議を唱えたり、特定の状況において当社によるお客様の個人データの処理を制限するよう要求したりすることもできます。",
        content42: "8. EU GDPR および英国データ保護法 – 法的根拠",
        content43: "EU GDPR および英国のデータ保護法により、個人情報を使用する際には法的根拠に従うことが求められます。 当社の基準は、個人情報が使用される特定の目的によって異なります。 これらの塩基には次のものが含まれます。",
        content44: "契約の履行 - 注文の受け付けと処理および支払い処理のためのお客様の個人情報の使用を含む、製品またはサービスについてお客様に提供または連絡するため。",
        content45: "当社の正当なビジネス上の利益とユーザーの利益 - 当社は、ユーザー、自分自身、または他者の安全を守るために、詐欺や悪用を検出および防止します。",
        content46: "お客様の同意 – 当社は、お客様に通知された特定の目的のためにお客様の個人情報を処理するためにお客様の同意を必要とします。 特定の目的で個人情報を処理することに当社が同意した後は、いつでも同意を撤回することができ、当社はその目的でのデータの処理を停止します。",
        content47: "法的義務の遵守 - 当社は法律の義務に従ってお客様の個人情報を使用します。 たとえば、身元確認を目的として銀行口座情報を収集します。",
        content48: "上記およびその他の法的根拠は、当社が個人情報を使用する特定の目的によって異なります。",
        content49: "9. 使用条件、通知および修正",
        content50: "お客様の使用およびプライバシーに関するあらゆる紛争には、本声明および当社の利用規約が適用されます。 このプラットフォームのプライバシーについて懸念がある場合は、詳細な説明を添えて当社までご連絡ください。喜んで問題を解決いたします。 また、地域のデータ保護当局に問い合わせる権利もあります。",
        content51: "当社のビジネスは常に変化しており、プライバシーに関する声明も同様に変化します。 最新の変更を確認するには、当社の Web サイトを頻繁にチェックしてください。 改定内容に同意できない場合は、直ちにアクセスを中止してください。 プライバシー ポリシーの更新版がリリースされた後、引き続きアクセスすることは、更新された内容に同意し、更新されたプライバシー ポリシーに従うことに同意することを意味します。 特に明記されていない限り、現在のプライバシーに関する声明は、お客様とお客様のアカウントに関して当社が保持するすべての情報に適用されます。",
    },
    serviceAgreement: {
        title: "役務契約",
        content1: "本ウェブサイトは、ユーザーがデジタルアセットの取引を行い、関連するサービス（以下「本サービス」または「サービス」といいます）を提供するための専用のプラットフォームです。 本契約を説明する便宜上、本ウェブサイトでは本契約内で「当社」またはその他の一人称代名詞を総称して使用しています。 ウェブサイトにログインする自然人またはその他の主体がこのウェブサイトのユーザーである限り、本規約を表現する便宜上、以下では「あなた」またはその他の二人称を使用します。 本契約を表現する便宜上、本契約では当社とお客様を総称して「当事者」と呼び、当社またはお客様を個別に「当事者」と呼びます。",
        content2: "重要なヒント：",
        content3: "ここで思い出していただきたいのですが：",
        content4: "1 デジタル資産自体は金融機関、企業、またはこのウェブサイトによって発行されたものではありません",
        content5: "2 デジタル資産市場は新しく、未確認であり、成長しない可能性があります。",
        content6: "3 デジタル資産は主に投機家によって多く利用されており、小売市場や商業市場では比較的ほとんど利用されていません。デジタル資産の取引は非常にリスクが高いです。それらは 1 日中継続的に取引され、上昇と下落の制限がなく、価格は市場の影響を受けやすいです。メーカーと世界政府の政策、変動。",
        content7: "4  当社は、お客様が本契約に違反したと当社が単独の裁量で判断した場合、または本ウェブサイトが提供するサービスもしくは本ウェブサイトが提供するサービスの使用が不正であると判断した場合、いつでもお客様のアカウントを一時停止または終了する権利を留保します。管轄区域の法律に基づいて違法である場合、またはこの Web サイトが提供するサービスまたはデジタル資産取引の使用を一時停止または終了する場合があります。 [中国本土、台湾、イスラエル、イラク、バングラデシュ、ボリビア、エクアドル、キルギス、セヴァストポリ、英国（個人ユーザー）]の者は、本ウェブサイトが提供する契約取引サービスを利用することを禁止します。 上記の国または地域のリストは、国または地域ごとの政策や製品の種類の違いにより変更される場合があり、その時点で特に通知しない場合がありますので、本契約のタイムリーな更新にご注意ください。。",
        content8: "デジタル資産取引には非常に高いリスクが伴い、ほとんどの人には適していません。 お客様は、この取引により部分的または全体的な損失が発生する可能性があることを理解しており、許容できる損失の程度に基づいて取引額を決定する必要があります。 デジタル資産にはデリバティブリスクが発生することを承知しており、ご理解いただいているため、ご質問がある場合は、まず専門アドバイザーの支援を求めることをお勧めします。 また、上記のリスク以外にも、予測できないリスクがあります。 デジタル資産の売買を決定する前に、財務状況と上記のリスクを慎重に検討し、明確な判断を下す必要があり、その結果生じるすべての損失を当社は負担しません。",
        content9: "あなたへの警告：",
        content10: "1 あなたは、このウェブサイトがデジタル資産情報の取得、取引当事者の検索、デジタル資産に関する交渉および取引の実行のための場所としてのみ使用されることを理解するものとし、このウェブサイトはあなたの取引には関与しません。関連するデジタル資産および/または取引、または情報の信頼性、合法性、有効性を判断し、その結果として生じる責任と損失を自ら負うものとします。",
        content11: "2 このウェブサイト上の意見、ニュース、ディスカッション、分析、価格、推奨事項、その他の情報は一般的な市場のコメントであり、投資アドバイスを構成するものではありません。 当社は、利益の損失を含むがこれに限定されない、この情報への依存から直接的または間接的に生じるいかなる損失についても責任を負いません。",
        content12: "3 当ウェブサイトは、当ウェブサイト上の情報の正確性を確保するために合理的な措置を講じておりますが、その正確性を保証するものではなく、また、いかなる責任も負わないものとします。本ウェブサイト上の情報、または情報の不足によって生じた損失または損害 インターネットへのリンク、通知や情報の送信または受信の遅延または失敗によって生じる直接的または間接的な損失。",
        content13: "4 インターネットベースの取引システムの使用には、ソフトウェア、ハードウェア、インターネット接続の障害などを含む (ただしこれらに限定されない) リスクもあります。 当社はインターネットの信頼性と可用性を制御できないため、歪み、遅延、接続障害については責任を負いません。",
        content14: "5 このウェブサイトを使用して、マネーロンダリング、密輸、商業贈収賄などのあらゆる違法取引活動または違法行為に従事することを禁止します。違法取引または違法行為の疑いがある場合、このウェブサイトは、以下を含むさまざまな利用可能な手段を講じます。当局等に対し、当社はこれにより生じる一切の責任を負わず、関係者から責任を追及する権利を留保します。",
        content15: "6 当ウェブサイトを利用して、悪意のある市場操作、不正な取引その他の非倫理的な取引行為を行うことを禁止しており、そのような行為が発見された場合には、当ウェブサイトから警告を発し、取引を制限し、悪質な価格操作や市場への悪意のある影響などの非倫理的な行為を排除します。当社は、アカウント停止などの予防的保護措置から生じるすべての責任を負わず、関係者から責任を追及する権利を留保します。",
        content16: "1. 一般原則",
        content17: "1.1 「ユーザー契約」（以下、「本契約」または「本利用規約」といいます）は、本文、「プライバシー規約」、「顧客認識およびマネーロンダリング対策ポリシー」、およびその他のさまざまなポリシーで構成されます。このウェブサイト上で公開されているか、将来公開される可能性があるクラスの規則、声明、説明など。",
        content18: "1.2 本ウェブサイトが提供するサービスをご利用いただく前に、本規約をよくお読みいただき、ご不明な点や必要な場合は、専門の弁護士にご相談ください。 本契約および/またはその変更に同意しない場合は、直ちに本 Web サイトが提供するサービスの使用を中止するか、本 Web サイトにログインしないでください。 この Web サイトにログインし、この Web サイトのサービスを使用するか、またはその他の同様の行為を行った場合、お客様は、本 Web サイトによっていつでも本契約に対して行われる変更を含め、本契約の内容を理解し、完全に同意したことになります。",
        content19: "1.3 お客様は、本ウェブサイトの規定に従って必要事項を入力し、その他の手続きを経て登録を完了し、登録時に「同意する」ボタンをクリックすることにより、本ウェブサイトの会員（以下「会員」といいます）となることができます。当社との契約書、または本 Web サイトの使用中に「同意する」または同様の意味のボタンをクリックしたとき、または本 Web サイトが許可するその他の方法で本 Web サイトが提供するサービスを実際に使用したとき, それは、あなたが本契約に基づくすべての条項を完全に理解し、同意し、受け入れることを意味します。手書きの署名がない場合でも、あなたに対する本契約の法的拘束力には影響しません。",
        content20: "1.4 このウェブサイトのメンバーになると、メンバー アカウントとそれに対応するパスワードが発行されます。メンバー アカウントとパスワードを保管するのはあなた自身の責任であり、あなたのアカウントに基づいて行われるすべての活動およびイベントに対して法的責任を負うものとします。",
        content21: "1.5 本ウェブサイトの会員となった会員のみが、本ウェブサイトが提供するデジタルアセット取引プラットフォームを利用して取引を行うことができ、その他本ウェブサイトが定める会員のみが利用できるサービスを享受することができ、非会員はウェブサイトへのログイン、ウェブサイトの閲覧のみが可能です。その他本ウェブサイトが定めるサービス。",
        content22: "1.6 このウェブサイトが提供するサービスや機能を登録して使用すると、以下を読み、理解したものとみなされます。",
        content23: "1.6.1 本契約のすべての条件に拘束されることに同意します。",
        content24: "1.6.2 あなたは、あなたが 18 歳以上であるか、さまざまな適用法に従って契約を締結できる法定年齢に達していることを確認します。このウェブサイトへの登録、販売または購入、情報の公開など、およびこのウェブサイトのサービスの受け入れは、準拠している必要があります。主権国または地域の関連法規を管轄しており、これらの条件を受け入れ、取引を開始し、この Web サイトを使用してデジタル資産取引を行う完全な能力を持っています。",
        content25: "1.6.3 お客様は、取引に関与するお客様に属するすべてのデジタル資産が合法的に取得され、所有権があることを保証します。",
        content26: "1.6.4 お客様は、ご自身の取引活動または非取引活動、および利益または損失について単独で責任を負うことに同意するものとします。",
        content27: "1.6.5 あなたは、登録時に提供した情報が真実かつ正確であることを確認します。",
        content28: "1.6.6 お客様は、税務上の目的での取引利益の報告を含む、関連する法律の要件を遵守することに同意します。",
        content29: "1.6.7 あなたは、本ウェブサイトが提供するサービスに関連するか否かに関わらず、いかなる場合においても、本ウェブサイトまたは当社の利益を損なう行為や活動に従事したり参加したりしないことに同意するものとします。",
        content30: "1.6.8 この契約は、お客様と当社の間の権利と義務のみを規定し、本ウェブサイトおよび他のウェブサイトのユーザーとお客様との間のデジタル資産取引から生じる法的関係および法的紛争には関与しません。",
        content31: "2. 協定の修正",
        content32: "当社は、お客様に別途通知することなく、随時本規約を改訂し、ウェブサイト上で公表する権利を保有しており、改訂後は、本規約内に表示される「最終更新時刻」に改訂時刻が記載され、自動的に更新されます。ウェブサイトに公開されるとその効力が生じます。 お客様は、本契約の更新時期および更新内容を随時閲覧し、注意を払う必要があり、関連する変更に同意できない場合は、直ちに本ウェブサイトのサービスの使用を中止し、本サービスの継続使用を行う必要があります。ウェブサイトは、改訂された契約に拘束されることを受け入れ、同意することを意味します。",
        content33: "3. 登録",
        content34: "3.1 登録資格",
        content35: "あなたは、登録プロセスを完了するとき、またはこの Web サイトで許可されている他の方法でこの Web サイトが提供するサービスを実際に使用するとき、適用される法律の規定に従って、本契約に署名し、この Web サイトのサービスを使用することができることを確認し、約束します。自然人、法人、またはその他の組織。 「登録に同意する」ボタンをクリックすると、あなたまたはあなたの認定代理人が契約の内容に同意し、その代理人によって登録され、この Web サイトのサービスを使用したことを意味します。 あなたが前述の対象資格を有していない場合、あなたとあなたの認定代理人は、そこから生じるすべての結果を負担するものとし、当社はあなたのアカウントを取り消しまたは永久に凍結し、あなたとあなたの認定代理人に責任を負わせる権利を留保します。",
        content36: "3.2 登録目的",
        content37: "あなたは、このウェブサイトへの登録が、法律や規制に違反したり、このウェブサイトでのデジタル資産取引の秩序を混乱させる目的ではないことを確認し、約束します。",
        content38: "3.3登録の流れ",
        content39: "3.3.1 お客様は、本ウェブサイトのユーザー登録ページに有効な電子メールアドレス、携帯電話番号、その他の情報を必要に応じて提供することに同意するものとし、お客様が提供または確認した電子メールアドレス、携帯電話番号、または本ウェブサイトが許可するその他の方法をログインとして使用することができます。このウェブサイトに入る方法。 必要に応じて、さまざまな管轄区域の関連法規制に従って、法規制、プライバシー条項、マネーロンダリング防止条項に規定されている本名、身分証明書、その他の関連情報を提供し、登録情報を常に更新する必要があります。タイムリーで詳細かつ正確な方法が必要です。 最初に入力されたすべての情報は登録情報として参照されます。 お客様は、かかる情報の信頼性、完全性、正確性について責任を負い、そこから生じる直接的または間接的な損失および悪影響を負担するものとします。",
        content40: "3.3.2 お客様の所在地の主権国または地域の法律、規制、規則、命令、およびその他の規制で携帯電話番号の実名要件が規定されている場合、お客様は、提供された登録携帯電話番号が実名で登録されていることに同意するものとします。規定に従って提供しなかった場合、お客様に生じる結果は直接的または間接的な損失となり、悪影響はお客様が負担するものとします。",
        content41: "3.3.3 登録に必要な情報を合法的、完全かつ効果的に提供し、それが確認された場合、お客様はこのウェブサイトのアカウント番号とパスワードを取得する権利を有し、このウェブサイトのアカウント番号とパスワードを取得した時点で、登録は完了したものとみなされます。成功すると、この Web サイトにメンバーとしてログインできるようになります。",
        content42: "3.3.4 あなたは、このウェブサイトの管理および運営に関連して、このウェブサイトから送信される電子メールおよび/またはショートメッセージを受け取ることに同意するものとします。",
        content43: "4. サービス",
        content44: "このウェブサイトは、このウェブサイトを通じてお客様のデジタル資産取引活動（デジタル資産取引およびその他のサービスを含むがこれらに限定されない）のためのオンライン取引プラットフォーム サービスのみを提供します。",
        content45: "4.1 サービス内容",
        content46: "4.1.1 あなたは、このウェブサイト上でさまざまなデジタル資産商品のリアルタイムの市場状況と取引情報を閲覧する権利を有し、また、このウェブサイトを通じてデジタル資産取引指示を提出し、デジタル資産取引を完了する権利を有します。",
        content47: "4.1.2 あなたは、本ウェブサイト上の会員アカウントに基づいて情報を閲覧し、本ウェブサイトが提供する機能を運用に適用する権利を有します。",
        content48: "4.1.3 あなたは、このウェブサイトで公開されている活動規則に従って、このウェブサイトが主催するウェブサイト活動に参加する権利を有します。",
        content49: "4.1.4 このウェブサイトは、他のサービスを提供することを約束します。",
        content50: "4.2 サービス ルール あなたは、このウェブサイトの以下のサービス ルールを遵守することを約束します。",
        content51: "4.2.1 あなたは、法律、規制、およびポリシー要件を遵守し、アカウント内のすべてのデジタル資産のソースの合法性を確保し、この Web サイトまたはこの Web サイト上の第三者の権利と利益を損なう違法またはその他の活動に従事してはなりませんお客様は、違法、非合法、または他人の権利を侵害する情報の送受信、ねずみ講やその他の有害な情報や発言の送受信、当ウェブサイトのメールヘッダ情報等を当ウェブサイトに無断で転載する行為。",
        content52: "4.2.2 お客様は、口座番号、ログインパスワード、ファンドパスワード、登録時に紐付けられた携帯電話番号、携帯電話に受信した携帯電話認証コードを法令を遵守し、正しく使用・保管してください。 あなたは、自分のアカウント、ログインパスワード、ファンドパスワード、携帯電話認証コードの使用によるあらゆる操作とその結果に対して全責任を負います。 この Web サイトのアカウント番号、ログイン パスワード、またはファンドのパスワードまたは確認コードがあなたの許可なく第三者によって使用されていることが判明した場合、またはその他のアカウントのセキュリティ上の問題がある場合は、直ちにこの Web サイトに通知し、この Web サイトの一時停止を要求する必要があります。当サイトのアカウントのサービスです。 このウェブサイトは、お客様の要求に応じて合理的な期間内に措置を講じる権利を有しますが、このウェブサイトは、措置を講じる前に生じた結果（お客様が被った損失を含みますがこれに限定されません）については一切の責任を負いません。 このウェブサイトの同意なしに、このウェブサイト上のアカウントを他人に寄付、借用、リース、譲渡、またはその他の方法で処分することはできません。",
        content53: "4.2.3 あなたは、この Web サイト上で自分のアカウントとパスワードに基づいて行われるすべての活動 (情報開示、情報の投稿、オンラインでの同意のためのクリックまたはさまざまなルールや契約の提出、契約のオンライン更新または契約の購入を含みますがこれらに限定されません) に対して責任を負うことに同意するものとします。サービスなど）。",
        content54: "4.2.4 この Web サイトでデジタル資産取引を行う場合、デジタル資産取引の通常の進行を悪意を持って妨害したり、取引の順序を混乱させたりしてはなりません。また、この Web サイトの通常の運営を妨害したり、他のユーザーによるこの Web サイトの使用を妨害したりしてはなりません。技術的手段またはその他の手段によるサービスの提供、架空のサービスを使用してはならず、事実やその他の手段によってこの Web サイトの善意を悪意的に中傷することはできません。",
        content55: "4.2.5 オンライン取引が原因で他のユーザーと紛争が生じた場合、非司法または行政チャネルを通じてこのウェブサイトに関連情報を提供するよう要求することはできません。",
        content56: "4.2.6 支払うべき税金、およびこのウェブサイトが提供するサービスの使用中に発生するすべてのハードウェア、ソフトウェア、サービスおよびその他の費用は、お客様が単独で判断し、負担するものとします。",
        content57: "4.2.7 お客様は、本契約およびその他のサービス条件および本ウェブサイトによって随時更新および公開される運用規則に従うものとし、本ウェブサイトによって提供されるサービスの使用をいつでも終了する権利を有します。",
        content58: "4.3 製品ルール",
        content59: "4.3.1 取引情報の閲覧",
        content60: "当ウェブサイトの取引情報を閲覧される際には、取引情報に含まれる価格、手数料、手数料、売買方向等の内容をよくお読みいただき、内容を十分ご承諾いただいた上でご利用ください。取引情報のボタンをクリックするだけで取引が可能です。",
        content61: "4.3.2 トランザクションの詳細を表示する",
        content62: "アカウントを通じて対応する取引記録を表示できます。",
        content63: "5. 本ウェブサイトの権利と義務",
        content64: "5.1 本規約に定める登録資格を有しない方については、当サイトは登録を拒否する権利を有し、既に登録されている方については、当サイトは会員アカウントを抹消する権利を有します。あなたの認定代理人が責任を負います。その通りです。 同時に、このウェブサイトは、その他の状況下であなたの登録を受け入れるかどうかを決定する権利を留保します。",
        content65: "5.2 当ウェブサイト独自の判断に基づき、お客様または関連アカウントのユーザーがハイリスク投資に適していないと当ウェブサイトが判断した場合、当社はお客様のアカウントおよび関連するすべてのアカウントの使用を一時停止または終了する権利を有します。",
        content66: "5.3 当ウェブサイトは、アカウントユーザーがアカウントの初期登録者ではないことを発見した場合、アカウントの使用を一時停止または終了する権利を有します。",
        content67: "5.4 当ウェブサイトは、技術的テスト、手動サンプリング、その他のテスト方法を通じて、お客様が提供した情報が間違っている、虚偽、無効または不完全であると合理的に疑う場合、情報を修正または更新するか、提供を一時停止または終了するようお客様に通知する権利を有します。このウェブサイトはあなたにサービスを提供します。",
        content68: "5.5 当ウェブサイトは、当ウェブサイトに掲載されている情報に明らかな誤りがあることが判明した場合、修正する権利を留保します。",
        content69: "5.6 本ウェブサイトは、いつでも本ウェブサイトのサービスを変更、一時停止、または終了する権利を留保します。本ウェブサイトは、サービスの変更または終了の権利を行使するために事前に通知する必要はありません。本ウェブサイトが本サービスの 1 つまたは複数を終了する場合は、ウェブサイトにアクセスした場合、終了はこのウェブサイト上で行われ、終了のお知らせが掲載された日から有効となります。",
        content70: "5.7 このウェブサイトは、このウェブサイトの正常な動作を確保し、必要かつ信頼できる取引環境と取引サービスを提供し、デジタル資産取引の秩序を維持するために、必要な技術的手段と管理措置を講じます。",
        content71: "5.8 会員アカウントとパスワードを使用して1年間連続して本ウェブサイトにログインしなかった場合、本ウェブサイトはアカウントをキャンセルする権利を有します。 アカウントがキャンセルされた後、当ウェブサイトは、対応する会員名を他のユーザーに登録および使用できるように公開する権利を有します。",
        content72: "5.9 このウェブサイトは、技術投資の強化、セキュリティ予防策の改善およびその他の対策により、お客様のデジタル資産の安全性を確保し、お客様のアカウントに予見されるセキュリティリスクが発生した場合には、事前に通知します。",
        content73: "5.10 当ウェブサイトは、法令や当ウェブサイトの規定に違反する当ウェブサイト上のあらゆるコンテンツ情報をいつでも削除する権利を有しており、かかる権利を行使する場合には、事前に通知する必要はありません。",
        content74: "5.11 このウェブサイトは、お客様の主権国または地域の法律、規制、規則、命令およびその他の規制要件に従って、より多くの情報または資料を提供すること、および現地の規制要件に準拠するための合理的な措置を講じることをお客様に要求する権利を有します。本ウェブサイトは、お客様の主権国または地域の法律、規制、規則、命令およびその他の仕様の要件に従って、お客様に対する本ウェブサイトのサービスの一部またはすべての公開を一時停止または永久に停止する権利を有します。",
        content75: "5.12 このウェブサイトは、独自の裁量により、7 営業日前に通知した上でアカウントを閉鎖する権利を留保します。 したがって、注文をキャンセルしてポジションをクローズするには 7 営業日以内に行う必要があります。 期間が終了しても注文をキャンセルせずポジションを閉じなかった場合、当社は注文を強制的にキャンセルしてポジションを閉じ、アカウントに残っているデジタル資産をお客様に返却します。",
        content76: "5.13 トレーダーの権利と利益を保護するために、このウェブサイトは、次のような特別な状況（システム障害、ネットワーク障害、極端な市場状況など）の下でオンライン商品を調整する権利を有します。決済、契約の種類、早期受渡・決済の契約期間、決済・受渡価格等については、当ウェブサイト上で公表する場合があります。",
        content77: "6. 補償",
        content78: "6.1 いかなる場合においても、お客様に対する直接的な損害に対する当社の責任は、3 か月間の本ウェブサイトの使用に対して当社がお客様に請求したサービス料金の合計を超えることはありません。",
        content79: "6.2 お客様が本契約またはその他の法令に違反した場合、お客様は当社に対して少なくとも 200 万ドルを賠償し、発生したすべての費用 (弁護士費用などを含む) を負担しなければなりません。実際の損失を補償するのに十分でない場合は、お客様はそれに備えて。",
        content80: "7. 差止による救済を求める権利",
        content81: "当社とお客様は双方とも、お客様の契約違反または契約違反の可能性に対するコモンローの救済策では、当社が被るすべての損失を補償するのに十分ではない可能性があることを認めます。そのため、当社は、コモンローまたは法的衡平法で認められる差止めによる救済を求める権利を有します。契約違反または契約違反の可能性があった場合、その他すべての救済策。",
        content82: "8. 責任の制限と免責事項",
        content83: "8.1 お客様は、いかなる状況においても、以下の事項について当社が責任を負わないことを理解し、これに同意するものとします。",
        content84: "8.1.1 収入の喪失。",
        content85: "8.1.2 トレーディング利益または契約損失。",
        content86: "8.1.3 事業中断による損失。",
        content87: "8.1.4 期待された金銭的節約の損失；",
        content88: "8.1.5 情報トラブルによる損失；",
        content89: "8.1.6 機会、信用、または評判の損失。",
        content90: "8.1.7 データの破損または損失；",
        content91: "8.1.8 代替製品またはサービスの購入費用；",
        content92: "8.1.9 不法行為（過失を含む）、契約違反、またはその他の理由から生じる間接的、特別または付随的な損失または損害。かかる損失または損害が当社によって合理的に予見可能かどうか、また当社がその存在を知らされていたかどうかは問いません。紛失または破損の可能性があります。",
        content93: "8.1.1 8.1.9 までの条項は互いに独立しています。",
        content94: "8.2 お客様は、以下のいずれかの状況によりお客様に生じた損害について、当社が責任を負わないことを理解し、これに同意するものとします。",
        content95: "8.2.1 お客様の特定の取引には、重大な法律違反または契約違反が含まれる可能性があります。",
        content96: "8.2.2 この Web サイトでのあなたの行為は違法または非倫理的である可能性があります。",
        content97: "8.2.3 データ、情報の購入または取得、または本ウェブサイトのサービスまたは代替措置による取引の実行によって発生する費用および損失。",
        content98: "8.2.4 このウェブサイトのサービスについての誤解。",
        content99: "8.2.5 その他、本ウェブサイトが提供するサービスに関連して当社の責によらない損失が発生した場合。",
        content100: "8.3 情報ネットワーク機器のメンテナンス、情報ネットワーク接続障害、コンピュータ、通信その他のシステム障害、停電、気象条件、事故、ストライキ、労働争議、暴動、暴動、暴動、生産性や生産資材の不足、火災、洪水などの場合は当社が責任を負います。 、嵐、爆発、戦争、銀行またはその他のパートナーの理由、デジタル資産市場の崩壊、政府の措置、司法または行政機関の命令、その他当社の制御を超えた行為、または当社が制御する能力がない行為、または第三者の理由。サービスの不能または遅延、またはお客様の損失については一切の責任を負いません。",
        content101: "8.4 当社は、本ウェブサイトに含まれるすべての情報、プログラム、テキストなどが完全に安全であり、ウイルス、トロイの木馬、その他の悪意のあるプログラムによる干渉や被害がないことを保証することはできません。または、ダウンロードしたプログラム、情報、データなどをダウンロードして使用するかどうかはすべてお客様ご自身の決定であり、リスクや起こり得る損失はお客様ご自身の責任で負うものとします。",
        content102: "8.5 当社は、この Web サイトにリンクされている第三者 Web サイトの情報、製品、サービス、または当社の主題に属さないその他の形式のコンテンツについて、いかなる保証も約束も行いません。サードパーティの Web サイト、製品などはすべてお客様自身の決定であり、そこから生じるすべての責任はお客様が負うものとします。",
        content103: "8.6 当社は、本ウェブサイトが提供するサービスの適合性、誤りや欠落がないこと、継続性、正確性、信頼性、特定の機能への適合性を含むがこれらに限定されない、本ウェブサイト上のサービスの使用について、明示的または黙示的を問わずいかなる保証も行いません。目的。 また、当社は、本ウェブサイトが提供するサービスに関わる技術や情報の有効性、正確性、正確性、信頼性、品質、安定性、完全性、適時性等について、いかなる約束も保証もいたしません。 このウェブサイトが提供するサービスにログインするか使用するかはお客様個人の決定であり、リスクと損失の可能性はお客様ご自身が負うものとします。 当社は、デジタル資産の市場、価値および価格について、明示的または黙示的保証を行いません。お客様は、デジタル資産市場が不安定であることを理解し、理解するものとします。価格および価値はいつでも大幅に変動または崩壊する可能性があります。デジタル資産の取引は、あなた自身の個人的な自由であり、あなた自身のリスクと起こり得る損失で選択および決定してください。",
        content104: "8.7 本契約に定められた当社の保証および約束は、本契約および本ウェブサイトで提供されるサービスに関して当社が行う唯一の保証および表明であり、書面または口頭、明示を問わず、他の方法および方法で生じる保証および約束に優先します。の、または暗黙の。 これらすべての保証および表明は、当社自身の約束および保証のみを表すものであり、第三者が本契約の保証および約束に従うことを保証するものではありません。",
        content105: "8.8 当社は、本契約に記載されていない、適用法の最大限の範囲で損害賠償責任を制限、除外、または相殺する権利を放棄しません。",
        content106: "8.9 ご登録後は、本規約に定められたルールに従って当社が行うあらゆる業務に同意したものとみなされ、発生するリスクはすべてお客様の負担となります。",
        content107: "9. 契約の終了",
        content108: "9.1 当ウェブサイトは、本規約に従い、本ウェブサイトのすべてのサービスを終了する権利を有しており、本ウェブサイトのすべてのサービスが終了した日をもって、本契約も終了するものとします。",
        content109: "9.2 本契約の終了後、お客様には、本 Web サイトにサービスの提供を継続するよう要求したり、元の Web サイトのアカウント内の情報を保持またはお客様に開示するよう本 Web サイトに要求するなど、その他の義務の履行を要求する権利はありません。第三者は、読んだり送信したりしていない情報を転送します。",
        content110: "9.3 この契約の終了は、違反当事者に対する非違反当事者の他の責任には影響しません。",
        content111: "10. 知的財産権",
        content112: "10.1 この Web サイトに含まれるすべての知的成果には、Web サイトのロゴ、データベース、Web サイトのデザイン、テキストとグラフィックス、ソフトウェア、写真、ビデオ、音楽、サウンドおよびそれらの組み合わせ、ソフトウェアのコンピレーション、関連するソース コードおよびソフトウェア (小規模なアプリケーションを含む) が含まれますが、これらに限定されません。 ) プログラムおよびスクリプトの知的財産権は、当ウェブサイトに帰属します。 上記のマテリアルまたはコンテンツを商業目的で複製、変更、コピー、送信、または使用することはできません。",
        content113: "10.2 このウェブサイトの名称に含まれるすべての権利（のれん、商標、ロゴなどを含みますがこれらに限定されません）は当社に帰属します。",
        content114: "10.3 本契約への同意は、お客様が本ウェブサイト上で公開するあらゆる形式の情報の著作権を譲渡する取り組みとみなされます。これには、複製権、頒布権、レンタル権、展示権、公演権、上映権、放送権、情報インターネット送信権、映画化権、翻案権、翻訳権、編纂権、その他著作権者が享受すべき譲渡可能な権利は、無償で独占的に当ウェブサイトに譲渡されます。侵害を理由にあらゆる対象に対して訴訟を起こし、全額の補償を獲得します。 本契約は、コンテンツが本契約の署名前に作成されたものであるか、本契約の署名後に作成されたものであるかにかかわらず、お客様が本 Web サイトで公開する著作権法で保護されているすべての作品コンテンツに対して有効です。",
        content115: "10.4 本ウェブサイトのサービスを利用する場合、本ウェブサイトまたはその他の知的財産権を違法に使用または処分することはできません。 いかなる形式であっても、この Web サイトに公開されている情報を公開したり、他の Web サイト (およびメディア) に使用を許可したりすることはできません。",
        content116: "11. 情報保護",
        content117: "本ウェブサイト上で別途掲載する「プライバシー規約」の規定が優先して適用されます。",
        content118: "12. 計算",
        content119: "すべての取引計算結果は当社によって検証され、すべての計算方法はウェブサイトで公開されていますが、ウェブサイトの使用が中断されないこと、またはエラーがないことを保証するものではありません。",
        content120: "13. 輸出管理",
        content121: "あなたは、関連法に従って、この Web サイト上のいかなるマテリアル (ソフトウェアを含む) も輸出、再輸出、輸入、または転送することが許可されていないことを理解し、承認するため、以下のいずれかを積極的に実施、支援、または参加しないことを保証するものとします。上記の輸出または関連する法令に違反する行為、譲渡またはその他の適用法令違反、そのような状況が発見された場合には、適時に当社に積極的に報告し、当社の対応を支援してください。",
        content122: "14. 転送",
        content123: "本契約に規定されている権利および義務は、かかる権利および義務から利益を受ける当事者の譲受人、相続人、執行者および管理者も拘束するものとします。 当社の同意なしに第三者に譲渡することはできませんが、当社はいつでも本契約における権利と義務を第三者に譲渡し、お客様に通知することができます。",
        content124: "15. 可分性",
        content125: "本契約のいずれかの条項が管轄裁判所によって強制不能、無効または違法であると判断された場合でも、本契約の残りの条項の有効性には影響しません。",
        content126: "16. 代理店以外の関係",
        content127: "本契約に別段の定めがある場合を除き、本契約のいかなる規定も、当社をお客様の代理人、受託者、またはその他の代表者として創設、暗示、またはその他の方法で構成するとみなされるものではありません。",
        content128: "17. 棄権",
        content129: "契約違反に対する当社またはお客様による当事者の責任または本契約に規定されているその他の責任の放棄は、契約違反に対するその他の責任の放棄とみなされたり解釈されたりするものではなく、権利または救済策を行使しないことはいかなる場合にも適用されません。かかる権利または救済策の放棄、または放棄として解釈される場合があります。",
        content130: "18. タイトル",
        content131: "すべての見出しは、契約の表現の便宜のみを目的としており、契約条件の内容や範囲を拡大または制限するために使用されるものではありません。",
        content132: "19. 準拠法",
        content133: "19.1 紛争または請求を提起する前に、お客様は、紛争を非公式に解決するために電子メールでこのウェブサイトに連絡することを承認し、同意します。当社は、お客様の紛争をできるだけ早く内部で解決するよう努めます。両当事者は、誠実な交渉を通じて紛争を解決することに同意します（議論の内容は機密として扱われ、法的手続きの証拠として使用されることから適用される規則によって保護されます。）",
        content134: "19.2 お客様は、本規約の存在、有効性、解釈、履行、違反、終了を含む、紛争、論争、意見の相違、または請求が発生した場合、または本規約に起因または関連して生じた紛争（「紛争」）が発生した場合に、その旨を承認し、これに同意するものとします。 ）、上記の当事者は、その時点で有効な「ICC 仲裁規則」に従って、国際商工会議所の国際仲裁裁判所（「ICC 国際仲裁裁判所」）に紛争を提出するものとします。 仲裁地はベリーズとし、適用される仲裁法は国際商業会議所の仲裁規則に従って決定されるものとします。 当事者間で別段の合意がない限り、ICC 仲裁規則に基づいて仲裁人は 1 名のみ任命されます。 仲裁人は、仲裁合意の存在、範囲、有効性、あるいは請求や反訴の仲裁可能性に対する異議を含むがこれに限定されない、自身の管轄権について裁定を下す独占的権限を有するものとします。 仲裁はすべて英語で行われます。 仲裁がどのように行われるかに関係なく、仲裁人は、裁定および裁定の基礎となる重要な結論（ある場合）を説明するのに合理的かつ十分な書面による裁定を発行するものとします。 仲裁判断は最終的なものであり、両当事者を拘束するものとし、管轄権を有する裁判所で執行される場合があります。",
        content135: "20. 契約の有効性と解釈",
        content136: "20.1 この契約は、お客様がこの Web サイトの登録ページをクリックして登録に同意し、登録プロセスを完了し、この Web サイトのアカウントとパスワードを取得したときに発効し、この Web サイトとお客様の両方を拘束します。",
        content137: "20.2 本規約の最終解釈権は本ウェブサイトに帰属します。",
        content138: "顧客とマネーロンダリング防止ポリシーを理解する",
        content139: "1. はじめに",
        content140: "1.1 当社は、「Know Your Customer」およびアンチマネーロンダリングに関連する法令を注意深く遵守することを約束し、「Know Your Customer」およびアンチマネーロンダリングポリシーに意図的に違反しないことを約束します。 当社は、合理的な管理の範囲内で、安全なサービスを提供し、犯罪容疑者のマネーロンダリング行為による損失から可能な限りお客様を保護するために必要な措置と技術を講じます。",
        content141: "1.2 当社の顧客確認およびマネーロンダリング防止ポリシーは、お客様が所属するさまざまな法的管轄区域の顧客確認およびマネーロンダリング防止ポリシーを含む包括的な国際ポリシー システムです。",
        content142: "2. 顧客とマネーロンダリング防止ポリシーが次のとおりであることを把握します。",
        content143: "2.1 顧客把握ポリシーとマネーロンダリング対策ポリシーを公布し、対応する法律や規制によって設定された基準を満たすように随時更新します。",
        content144: "2.2 このウェブサイトを運営するためのいくつかの指針と規則を公布および更新し、当社の従業員はこれらの原則と規則の指針に従ってサービスを提供します。",
        content145: "2.3 厳格な手段による本人確認やマネーロンダリング対策業務を担当する専門チームの結成の手配など、取引の内部監視と管理のための手順を設計し完了する。",
        content146: "2.4 リスク防止手法を使用してデューデリジェンスと顧客の継続的な監督を実施します。",
        content147: "2.5 発生したトランザクションをレビューし、定期的に確認します。",
        content148: "2.6 疑わしい取引を管轄当局に報告します。",
        content149: "2.7 身元証明、住所、取引記録は少なくとも 6 年間保管され、お客様へのさらなる通知なしに規制当局に提出される場合があります。",
        content150: "3. 本人確認情報と確認",
        content151: "3.1 アイデンティティ情報",
        content152: "3.1.1 管轄区域や事業体の種類ごとに異なる規制に従って、当社が収集するお客様の情報の内容に一貫性がない場合がありますが、原則として以下の情報が登録された個人から収集されます。",
        content153: "基本的な個人情報：氏名、住所（異なる場合は本住所）、生年月日、国籍、その他入手可能な情報。 身元確認は、パスポート、ID カード、またはさまざまな管轄区域によって要求されトリガーされるその他の身分証明書など、公的機関またはその他の同様の当局によって発行された文書に基づく必要があります。 ご提供いただいた住所は、旅客輸送チケットや金利札の確認、選挙人名簿の確認など、適切な方法で確認されます。",
        content154: "有効な写真: 登録する前に、身分証明書を胸に当てている自分の写真を提出する必要があります。",
        content155: "連絡先の詳細: 電話番号/携帯電話番号および/または有効な電子メール アドレス。",
        content156: "3.1.2 お客様が法人またはその他の法人である場合、当社はお客様またはお客様の信託口座の最終受益者を特定するために以下の情報を収集します。",
        content157: "会社登記および登録証明書、会社の定款および覚書のコピー、会社の資本構成および所有権の説明に関する詳細な認証資料（このウェブサイトでの口座の開設と執行を決定する権限のある本人の取締役会決議を証明するもの）、会社必要に応じて提供する必要がある取締役、大株主および本ウェブサイト上のアカウントに署名する権限を与えられた人物の身分証明書、会社の主な事業所の住所が会社の郵送先住所と異なる場合は、郵送先住所を提供します。 企業の現地住所が主な事業所の住所と一致しない場合、その企業はリスクが高い顧客とみなされ、追加の追加書類を提出する必要があります。",
        content158: "さまざまな管轄区域およびさまざまな種類の事業体のさまざまな規制に応じて、当社が必要と判断した文書だけでなく、当局が発行したその他の証明書や文書も必要となります。",
        content159: "3.1.3 英語または中国語版の身元情報のみを受け付けます。そうでない場合は、身元情報を英語に翻訳し、公証を受けてください。",
        content160: "3.2 検証の確認",
        content161: "3.2.1 身分証明書の全ページ内容を提供する必要があります。",
        content162: "3.2.2 身分証明書を胸に当てている写真をお願いします。",
        content163: "3.2.3 裏付け書類のコピーは通常、原本の伝票と照合する必要があります。 ただし、適切で信頼できる認証者が、そのコピーが元の文書の正確かつ完全な複製であることを証明できる場合、コピーは許容されます。 このような認証者には、大使、司法委員、地方判事などが含まれます。",
        content164: "3.2.4 最終的な受益者と口座の管理を特定するための要件は、どの個人が最終的に直接の顧客を所有または管理しているかを決定すること、および/または進行中の取引が他者に代わって実行されていることを確立することです。 企業の場合、大株主（議決権の10％以上を保有している株主など）の身元を確認する必要があります。 一般に、株式の 25% を保有する場合は通常のリスクの範囲内とみなされ、株主の身元を確認する必要があります。株式の 10% を保有するか、それ以上の議決権または株式を保有する場合は、高リスク状況とみなされ、身元確認が必要となります。株主の確認が必要です。",
        content165: "4. トランザクションの監視",
        content166: "4.1 当社は、セキュリティおよび実際の取引条件に基づいて、日々の取引および通貨引き出しの上限を随時設定および調整します。",
        content167: "4.2 登録ユーザー内で頻繁に取引が行われている場合、または不当な事情がある場合には、専門チームが疑わしいかどうかを評価し、判断します。",
        content168: "4.3 当社は、当社の判断により取引が不審であると判断した場合には、可能な限り速やかに取引の停止、取引の拒否、または取引の取り消し等の制限措置を講じ、所轄官庁に報告することがありますが、あなたに通知しません。",
        content169: "4.4 当社は、国際的なマネーロンダリング防止基準に準拠していない管轄区域の人物、または政治的に露出した人物とみなされる可能性のある人物からの登録申請を拒否する権利を留保し、疑わしい取引については当社の独自の判断で一時停止または終了する権利を留保します。あなたに負う義務または責任に違反すること。",
    },
    register: {
        formTitle1: "作成する",
        formTitle2: "メールアドレスまたは携帯電話番号を使用して登録します",
        formTitle3: "アカウント",
        field1: "検証コード",
        field2: "得る",
        field3: "パスワードを認証する",
        field4: "招待コード",
        field41: "（オプション）",
        field5: "読んで同意します",
        field6: "利用規約",
        placeholder1: "確認コードを入力してください",
        content7: "確認コードを入力してください",
        content12: "電話番号を入力してください",
        content13: "パスワードを入力してください",
        content14: "メールアドレスを入力してください",
        content18: "パスワードをもう一度入力してください",
        content19: "利用規約に同意してください",
        content20: "正しい確認コードを入力してください",
        content23: "まだ認証コードを受け取っていません？",
        content24: "以下に確認コードを入力してください",
        registerSuccess: "登録完了",
        content25: "パスワードが矛盾しています",
        content26: "正しいメールアドレスを入力してください",
        content27: "パスワードのリセットが成功しました"
    },
    download: {
        content1: "いつでもどこでも取引",
        content2: "複数端末に完全対応し、さまざまなシーンの取引ニーズにいつでも対応",
        content3: "いつでもリアルタイムの市場状況を確認できます",
        content4: "BTC、ETH、XRP、その他のデジタル資産を簡単に売買",
        content5: "関心のある暗号通貨の価格変動に関する早期警告を受け取ることができます",
        content6: "ビットコインのスポット ローン、先物ローン、オプションの価格を表示する",
        content7: "さまざまな取引所の仮想通貨の価格を比較する",
        title: "ダウンロード",
        install: "インストールに関する注意事項",
        one: "1、説明ファイルのダウンロードを許可する",
        two: "2、説明ファイルがダウンロードされました",
        three: "3、携帯電話の[設定]に移動し、[一般]を見つけます。",
        four: "4、プロファイルとデバイスマネージャー",
        five: "5、プロフィールをクリックしてください",
    },
    identity: {
        noAuthentication: {
            title: "ジュニア認定",
            pleaseSelectYourCountry: "あなたの国を選択してください",
            name: "名前",
            enterName: "あなたの名前を入力してください",
            city: "市",
            enterCity: "あなたの都市を入力してください",
            IDNumber: "ID番号",
            enterIDNumber: "ID番号を入力してください",
        },
        noAdvancedAuthentication: {
            title: "高度な認定",
            infoCountry: "国・地域",
            infoName: "名前",
            infoNumber: "ID番号",
            infoAddress: "住所",
        },
        text: "認定されていない",
        recertification: "再認定",
        certification: "認証",
        authenticationInProgress: "検証済み",
        inAuthentication: "認証中",
        uploadTheFrontOfTheDocument: "身分証明書の前面をアップロードします",
        uploadTheReverseSideOfTheDocument: "証明書の裏面をアップロードする",
        uploadAPhotoOfAHandHeldID: "手持ちの身分証明書の写真をアップロードしてください",
        pleaseUpload: "お願いします ",
        pleaseCompleteOrdinaryCertificationFirst: "一次認定審査が成功するまで待つ必要がある",
        refused: "拒否されました",
        reason: "理由",
        succeeded: "成功しました",
        uploading: "アップロードする",
        newTitle: "ID が発行された国を選択してください",
        pleaseOrdinaryCertificationFirst: "最初に一次認証を実行してください",
        text1: "アバターの変更",
        text2: "ニックネーム",
        text3: "アカウント",
        text4: "ニックネームの変更",
        text5: "新しいニックネーム",
        text6: "新しいニックネームを入力してください",
        text7: "以前のパスワード",
        text8: "古いパスワードを入力してください",
        text9: "新しいパスワード",
        text10: "新しいパスワードを入力してください",
        text11: "パスワードを認証する",
        text12: "新しいパスワードをもう一度入力してください",
        text13: "新しいメールボックス",
        text14: "検証コード",
        text15: "確認コードを入力してください",
        text16: "あなたの携帯電話番号",
        text17: "新しい携帯電話番号",
        text18: "電話番号を入力してください",
        text19: "携帯電話認証コード",
        text20: "ジュニア認定",
        text21: "身元情報を確認してください",
        text22: "国",
        text23: "市",
        text24: "あなたの都市を入力してください",
        text25: "あなたの名前を入力してください",
        text26: "あなたのID番号",
        text27: "ID番号を入力してください",
        text28: "IDカードの表面写真",
        text29: "クリックして書類をアップロードします",
        text30: "再アップロード",
        text31: "アップロード",
        text32: "証明写真を持っている",
        text33: "クリックして手持ちの証明写真をアップロードしてください",
        text34: "Google Authenticator をバインドしました",
        text35: "鍵",
        text36: "Google 確認コードを入力してください",
        text37: "送信",
        text38: "空にすることはできません",
        text39: "メールの形式が間違っています",
        text40: "2 つのパスワードが矛盾しています",
        text41: "正常にコピーされました",
        text42: "コピーに失敗しました",
        text43: "アバターの変更",
        text44: "パスワードを変更する",
        text45: "電子メールバインディング",
        text46: "電子メールを変更する",
        text47: "携帯電話のバインド",
        text48: "携帯電話を改造する",
        text49: "Google認証",
        text50: "Google認証システムをバインドする",
        text51: "Google 確認コードを入力してください",
        text52: "保存",
        text53: "提出の確認",
        text54: "改訂",
        text55: "バインディング",
        text56: "Google認証をオフにする",
        text57: "次のステップ",
        text58: "仕上げる",
        text59: "Google 認証は拘束されています",
        text60: "Google 認証はバンドルされていません",
        text61: "一次証明書が提出されました",
        text62: "高度な認定が提出されました",
        text63: "画像がアップロードされていません",
        text64: "再送信",
        text65: "ニックネームを入力してください",
        text66: "ニックネームが正常に保存されました",
        text67: "画像はまだアップロードされていません",
        text68: "パスワードのリセットが完了しました",
        text69: "電子メールが正常に変更されました",
        text70: "アバターは正常に保存されました",
        text71: "画像をアップロードする",
        text72: "あなたのメールボックス",
        text73: "新しいメールアドレスを入力してください",
        text74: "あなたの名前",
        text75: "認証に進みます",
        text76: "保有している証明写真を確認してください"
    },
    retrievePassword: {
        formTitle1: "パスワードを再設定する",
        formTitle2: "資産の安全のため、パスワード変更後 24 時間以内の取引は禁止されます。",
        formTitle3: "パスワードを取得する",
        formTitle4: "変更を確認する",
        formTitle5: "古いパスワードを入力してください",
        formTitle6: "新しいパスワードを入力してください"
    },
    home: {
        title1: "最も人気のある暗号通貨取引プラットフォーム",
        title3: "なぜですか ",
        title4: "これは、安全でコンプライアンスに準拠した予測可能なデジタル インフラストラクチャをサポートおよび運用するように設計された、高性能のオープンソース ブロックチェーン プラットフォームです。",
        field1: "確立された取引所",
        field2: "デジタル資産金融サービス",
        field3: "数千万人のユーザー",
        field4: "複数の投資者保護基金を設立する",
        field5: "高速取引",
        field6: "CME Gruop は、究極の取引体験を実現するために、超低遅延で注文を照合することができます。",
        title5: "市場動向",
        title6: "続きを見る",
        table1: "通貨",
        table2: "最新の価格",
        table3: "24時間の浮き沈み",
        table4: "24時間の取引高",
        table5: "24時間の取引量",
        table6: "引用",
        title7: "について ",
        title8: "当社は、ユーザーに最先端の取引ツール、革新的なテクノロジー、比類のない質の高い顧客サービスを提供することに尽力しています。",
        title9: "デジタルアセットの分野は日々変化しており、先見の明を持ち、急速な進歩を遂げることによってのみ、技術革新のペースに追いつくことができます。",
        title901: "最先端のデジタル資産取引サービスをユーザーと世界的な流動性プロバイダーに提供します。",
        title10: "私たちは、すべてのユーザーには最高の取引体験が与えられるべきであると強く信じています。",
        title11: "信頼できるセキュリティ",
        title12: "当社の高度なセキュリティ対策と SAFU ファンドは、デジタル資産をあらゆるリスクから保護します。 ",
        title13: "最高の取引レート",
        title14: "優遇取引レート、競争力のある VIP 権利、そして最高品質のサービスをお楽しみください。",
        title15: "24時間365日のカスタマーサービスサポート",
        title16: "24 時間 365 日、全天候型のフルタイム運用モードにより、関連するお問い合わせにできるだけ早くお答えします。",
        title17: "取引手数料0",
        title18: "複数の支払い方法を使用して、手数料ゼロで安全かつ迅速にデジタル通貨を取引します。",
        title19: "いつでもどこでも取引",
        title20: "複数端末に完全対応し、さまざまなシーンの取引ニーズにいつでも対応",
        title21: "いつでもリアルタイムの市場状況を確認できます",
        title22: "BTC、ETH、XRP、その他のデジタル資産を簡単に売買",
        title23: "関心のある暗号通貨の価格変動に関する早期警告を受け取ることができます",
        title24: "ビットコインのスポット ローン、先物ローン、オプションの価格を表示する",
        title25: "さまざまな取引所の仮想通貨の価格を比較する",
        title26: "今すぐサインアップ",
        title27: "ドル",
        title28: "24時間の取引高",
        title29: "統合流動性プロバイダー",
        title30: "ユーザー",
        title31: "もっと",
        title32: "Binance Loan が担保資産として CTSI と KSM を追加し、BUSD と USDT の金利引き下げ割引を開始します!",
        title33: "私たちの利点",
        title34: "安全で安定した",
        title35: "トップの技術チーム、包括的なセキュリティ保護、高速トランザクション合成エンジンの独立した研究開発により、大規模な同時トランザクションでも安定して確実に動作できます。",
        title36: "専門的なコンプライアンス",
        title37: "ブロックチェーンと金融で長年の経験を持つ専門的な運用チームは、準拠したデジタル資産取引ライセンスを保持しており、100% の準備金保証を備えています。",
        title38: "ユーザーファースト",
        title39: "世界市場に向けて、複数の言語をサポートし、24 時間年中無休で稼働し、強力なコミュニティ サポートとプロフェッショナルなカスタマー サービスを提供します。",
        title40: "ハイパフォーマンス",
        title41: "1 秒あたり 300,000 トランザクション、注文応答時間は 1 ミリ秒未満です。",
        title42: "私たちについて",
        title43: "いつでもどこでも取引",
        title44: "複数端末に完全対応し、さまざまなシーンの取引ニーズにいつでも対応",
        title45: "（コードをスキャンしてダウンロードする）",
        title46: "デジタル通貨ニュース",
        title47: "私たちのビジネスパートナー",
        title48: "敗者リスト",
        title49: "24時間売上高",
        title50: "24時間増減"
    },
    quotation: {
        marketTrading: "引用",
        turnover: "音量",
        optional: "オプション",
        contractMarket: "契約市場",
        currencyPair: "通貨ペア",
        latestPrice: "終値",
        upsAndDowns: "見積変更",
        highest: "最高",
        lowest: "最低",
        transactionAmount: "取引量",
        operate: "操作する",
        buy: "貿易",
        topTurnovers: "取引リスト",
        topGainers: "獲得者リスト",
        tradingPair: "取引ペア",
        buyOrder: "買う",
        sellOrder: "売り注文"
    },
    accountChange: {
        demo: "シミュレーション",
        realAccount: "実際の",
        demoAccount: "アナログ",
        accountRole: "アカウントの役割",
        pleaseSelect: "選んでください",
        AccountChangeType: "アカウント変更タイプ",
        AccountType: "口座の種類",
        currencyType: "通貨の種類",
        allType: "いろんなタイプ",
        allAccount: "すべてのアカウント",
        allCoin: "すべての通貨",
        coin: "通貨",
        amount: "変更金額",
        balance: "勘定残高",
        time: "時間",
        title: "アカウント変更記録",
        filtrar: "フィルター",
        check: "切り替える",
        showOnly: "保有通貨のみを表示",
        holdAssets: "通貨残高",
        accountList: "アカウントリスト"
    },
    contractTransaction: {
        chart: "チャート",
        basicEdition: "基本バージョン",
        depthMap: "深度マップ",
        second: "2番",
        minute: "ポイント",
        day: "空",
        week: "週",
        month: "月",
        open: "開ける",
        trade: "貿易",
        buyLong: "長期購入",
        buyShort: "空売り",
        cannotBuy: "この通貨は配送契約取引をサポートしていません",
        cannotBuy1: "この通貨は永久取引をサポートしていません",
        tradingAssets: "取引資産",
        amountAvailable: "利用可能な数量",
        timeStr: "時間",
        transactionPeriod: "取引期間",
        quantity: "量",
        enterQuantity: "数量を入力してください",
        buyIn: "長く続けてください",
        latestTransaction: "最新の取引",
        time: "時間",
        direction: "方向",
        price: "価格",
        sell: "短い",
        positionOrder: "配送指示書",
        historicalOrders: "歴史的な命令",
        product: "製品",
        realAccount: "(リアルアカウント)",
        demoAccount: "(アナログ)",
        openingTime: "注文時間",
        openingPrice: "オープン価格",
        curretPrice: "現在の価格",
        liquidationPrice: "終値",
        profitOrLoss: "利益と損失",
        endTime: "閉店時間",
        areYouSureToBuy: "本当に購入しますか?",
        areYouSureToLongBuy: "本当に長く続けますか？",
        areYouSureToShortBuy: "ショート購入してもよろしいですか?",
        successfullyBought: "無事に購入できました",
        minBuy: "最低購入額",
        assets: "資産",
        withdraw: "現金引出",
        entrustedOrder: "注文",
        total: "合計",
        continueToPlaceOrder: "引き続き注文してください",
        orderConfirmation: "注文の確認",
        buyRu: "買う",
        sellChu: "売る",
        areYouSureToSell: "本当に販売してもよろしいですか?",
        operation: "操作する",
        buyCover: "カバーの位置",
        commitSuccess: "トランザクションが成功しました",
        onlyEnterIntegers: "整数のみ入力可能",
        trend: "傾向",
        lotteryTime: "注文締め切り",
        countdown: "秒読み",
        period: "問題",
        cycle: "期間",
        unlimited: "無制限",
        put: "下落",
        call: "上昇",
        pending: "委託中",
        unopened: "まだ描かれていません",
        opened: "抽選された賞品",
        settlementPrice: "決済価格",
        rateOfReturn: "利益率",
        currentPositions: "現在位置",
        positionQuantity: "ポジション数量",
        guarantee: "マージン",
        text1: "上昇",
        text2: "下落",
        text3: "購入を待っています",
        text4: "注文数量",
        text5: "取引数",
        text6: "キャンセル",
        text7: "納期",
        text8: "キャンセルリマインダー",
        text9: "注文をキャンセルしてもよろしいですか?",
        text10: "不正な数量",
        text11: "注文リマインダー",
        text12: "本当に強気で買っていますか？",
        text13: "プットを購入してもよろしいですか?",
        text14: "キャンセルが成功しました"
    },
    mining: {
        title1: "世界をリードするブロックチェーンデジタル資産マイニングプラットフォーム",
        title2: "オープンで透明・安全で安定",
        title3: "通貨を選択してください",
        title4: "マイニングの年間収量",
        title5: "空",
        title6: "採掘期間",
        title7: "製品詳細",
        btn: "今は私のもの",
        btn2: "閉鎖",
        tabs1: "進行中",
        tabs2: "償還された",
        table1: "商品名",
        table2: "採掘期間",
        table3: "採掘時間",
        table4: "採掘量",
        table5: "有効期限",
        table6: "満期まで進む",
        table7: "注文の状況",
        egularMining: "定期的にマイニングする",
        inProgress: "進行中",
        redeemed: "償還された",
        redemption: "償還",
        regularMining: "定期的にマイニングする",
        earlyRedemption: "早期償還",
        need: "必要",
        handlingFee: "手数料",
        redemptionConfirmationMessage: "事前に引換の有無をご確認ください？",
        form1: "マイニングの年間収量",
        form2: "採掘期間",
        form3: "マイニング通貨",
        form4: "定期購入金額（最小/最大）",
        form5: "早期償還手数料: ",
        form6: "購入価格",
        form7: "利用可能な資金",
        form8: "購入価格",
        form9: "利用可能な資金",
        form10: "購入金額を入力してください",
        form11: "全て",
        form12: "推定収益",
        form13: "利用可能な資金が不十分です",
        form14: "購入金額を入力してください",
        form15: "私の注文",
        form16: "所得",
        form17: "注文詳細",
        form18: "早期引き換えの手順:",
        form19: "定期購入金額（最低）",
        form20: "購読金額（上限）",
        form21: "保有数量",
        form22: "時間を買う",
        form23: "引き換え時間",
        form24: "以上",
        form25: "サブスクリプションが成功しました",
        form26: "引き換えが成功しました",
        form27: "市場を上回る25%以上の収益率で財務管理が容易、購入後に特典も受け取れる",
        form28: "友達を招待すると 50 USDT の報酬が得られ、契約バウチャーでは 20% のコミッションも返還されます。"
    },
    assets: {
        content1: "アカウント資産",
        content2: "今日の収益",
        content3: "当座預金",
        content4: "実際の口座は、取引や出金に使用できる実際の口座資産です。",
        content5: "コインを引き出す",
        content6: "コインを入金する",
        content7: "交換",
        content8: "移行",
        content9: "選んでください",
        content10: "デモ口座",
        content11: "実際の",
        content12: "資産を取得する",
        content13: "実際のアカウントに切り替えてもよろしいですか?",
        content14: "デモ口座に切り替えてもよろしいですか?",
        content15: "成功を得る",
        table1: "通貨",
        table2: "量",
        table3: "氷結",
        table4: "利用可能",
        dialog1: "製品詳細",
        dialog2: "1、このアセットはデモアカウントモードでのみ使用できます",
        dialog3: "2、乱用を防ぐため、引き換えは 1 か月以内に 1 回のみ許可されます。",
        dialog4: "3、資産を受け取ると、元のベースで受け取った資産の額が追加され、ポジションのステータスは変更されません。",
        dialog5: "4、他にご質問がある場合は、カスタマーサービスまでお問い合わせください",
        dialog6: "閉鎖",
        dialog7: "今すぐデモアセットを入手",
        dialog8: "転入",
        dialog9: "転出",
        dialog10: "スイッチ",
        dialog11: "評価",
        dialog12: "デモアセット",
        dialog13: "私のアカウント",
        dialog14: "口座総資産",
        dialog15: "資産の概要",
        dialog16: "総資産評価額",
        dialog17: "資産評価",
        dialog18: "今日の収益",
        dialog19: "資金を凍結する",
        dialog20: "毎月、資産枠のシミュレーションを受け取ることができます",
        dialog21: "受け取る",
        dialog22: "無事に受け取りました",
        dialog23: '30日間の累計収入',
        dialog24: "金融商品",
        dialog25: "私の財務管理",
        dialog26: "歴史的な財務管理",
        dialog27: "歴史の記録",
        dialog28: "資金調達記録"
    },
    validator: {
        dialog14: "バリデーター",
        dialog15: "バインドバリデーター",
        dialog16: "バリデータスイッチ",
        dialog17: "検証コード",
        dialog18: "バリデーター QR コード",
        dialog19: "バリデータキー",
        dialog20: "保存",
        dialog21: "バリデーターを選択してください",
        dialog22: "キーをコピーする",
        dialog23: "正常に保存",
        dialog24: "確認コードを入力してください",
        dialog25: "フレンドリーなリマインダー: 保存を確認する前に、対応するアプリを開いて下の QR コードをスキャンするか、キーを手動で追加してください。",
        dialog26: "認証システムをアクティブにして、アカウントに最大限のセキュリティを提供します。",
        dialog27: "ログインしたり出金したりするたびに、携帯電話からアクセスできる固有の確認コードを入力する必要があります。",
        dialog28: "バリデーターのセットアップ",
        dialog29: "ヘルプ",
        dialog30: "認証コードをバインドしていません。 認証システムを使用してより強力な保護を実現します。",
        dialog31: "バリデーターがまだセットアップされていません。最初にセットアップしてください。",
        dialog32: "設定に移動"
    },
    recharge: {
        title1: "デジタル通貨を使って購入する方法がわからない？",
        title2: "たったの3ステップで簡単に!",
        title3: "ドロップダウンメニューから購入したいデジタル通貨を選択し、購入数量または金額を入力します。",
        title4: "ご希望の支払い方法を選択してください",
        title5: "支払いが確認されると、購入したデジタル通貨が自動的にアカウントに送金されます。",
        title6: "リチャージしたい通貨を選択してください",
        title7: "入金記録",
        table1: "通貨",
        table2: "量",
        table3: "チェーン名",
        table4: "州",
        table5: "理由",
        table6: "時間",
        table7: "バウチャーを見る",
        dialog1: "以下に確認コードを入力してください",
        dialog2: "支払いコード",
        dialog3: "コピー",
        dialog4: "住所",
        dialog5: "入金額",
        dialog6: "トランザクションのスクリーンショット",
        dialog7: "重要なお知らせ",
        dialog8: "入金額を入力してください",
        dialog9: "画像をアップロードする",
        dialog10: "内容を入力してください",
        dialog11: "リチャージのスクリーンショットをアップロードしてください",
        dialog12: "未レビュー",
        dialog13: "拒否されました",
        dialog14: "入金済み",
        tip1: "正常に送信されました",
        tip2: "重要なお知らせ",
        tip3: "コピーに失敗しました",
        tip4: "リチャージしたい通貨を選択してください",
        tip5: "1.上記の入金アドレスはプラットフォームの公式支払いアドレスです。プラットフォームの公式入金アドレスを探してください。誤った入金による資金の損失はお客様の責任となります。",
        tip6: "2.情報の改ざんや漏洩を防ぐため、ご使用のコンピュータやブラウザのセキュリティを必ずご確認ください。",
        tip7: "3.上記のアドレスにリチャージした後、アカウントを受け取る前にネットワーク ノード全体が確認する必要があります。；",
        tip8: "4.上記のトークン システムと通貨を選択し、対応する金額を入金として転送してください。その他の無関係な資産は転送しないでください。転送しないと取得できません。",
        tip9: "QRコードを保存する",
        tip10: "チャージ",
        tip11: "重要なお知らせ",
        tip12: "オフサイトリンク",
        tip13: "リチャージQRコード",
        tip14: "次のステップ",
        tip15: "リチャージ記録",
        tip16: "リチャージの詳細",
        tip17: "入金アドレス",
        tip18: "クイック支払い",
        tip20: "残高不足",
        tip22: "送信済み、確認待ち",
        tip23: "入金待ち",
        tip24: "額",
        tip25: "支払いは送信され、ブロックの確認を待っています",
        tip26: "支払いが拒否されました",
        tip27: "支払いが拒否されました",
        tip28: "支払い完了",
        bankChannel: "バンクカードチャネル",
        tip29: "履歴検索",
        tip30: "通貨リスト",
        tip31: "ネットワークの選択",
        tip32: "この通貨をリチャージする場合は、以下のサポートされているネットワークを通じてのみリチャージしてください。他のネットワークを通じてリチャージすると、資金が失われます。",
        tip33: "この通貨を引き出す場合は、以下のサポートされているネットワークを通じてのみ引き出してください。他のネットワークを通じて引き出した場合、資金は失われます。",
        tip34: "急速充電",
        tip35: "コードをスキャンして再充電する",
        tip36: "手動充電",
        tip37: "チェーン名",

        tip38: "リチャージアドレス",
        tip39: "チャージ金額",
        tip40: "取引領収書のアップロード",
        tip41: "画像をアップロードする",
        tip42: "提出を確認する",
        tip43: "支払いを確認する",
        tip44: "注意: リチャージに必要なアドレスをご確認ください。間違って入力した場合の損失については、当社は責任を負いません。",
        tip45: "思い出させる",
        tip46: "アドレスが正常にコピーされました!",
        tip47: "アドレスのコピーに失敗しました!",
        tip48: "利用可能な残高が不足しています",
        tip49: "リチャージは正常に送信され、チェーン上での確認を待っています。",
        tip50: "再充電に失敗しました",
        tip51: "チャージ金額が間違っている",
        tip52: "接続に失敗しました",
        tip53: "ウォレットがログインしていません",
        tip54: "接続拒否",
        tip55: "正常に送信されました。カスタマー サービスのレビューと承認を待っています",
        tip56: "取引領収書のスクリーンショットをアップロードしてください",
        tip57: "送信に失敗しました",
        tip58: "リチャージ記録",
        tip59: "出金記録",
        tip60: "通貨",
        tip61: "出金通貨",
        tip62: "リチャージアドレス",
        tip63: "スクリーンショットのショー",
        tip64: "クリックすると画像が拡大します",
        tip65: "充電時間",
        tip66: "拒否理由",
        tip67: "出金金額",
        tip68: "いただいた分量",
        tip69: "チェーン名",
        tip70: "出金アドレス",
        tip71: "出金時間",
        tip72: "閉鎖",
        tip73: "トランザクションのスクリーンショット",
        tip74: "審査待ち",
        tip75: "拒否されました",
        tip76: "完成した",
        tip77: "拒否されました",
        tip78: "監査済み",
        tip79: "支出済み",
        tip80: "抽出する",
        tip81: "転送ネットワーク",
        tip82: "住所/請求書",
        tip83: "全部撤回",
        tip84: "クラウドファンディングやICOのアドレスに直接出金しないでください。出金しないと、クラウドファンディングやICOで発行されたトークンを受け取ることができなくなります。",
        tip85: "入荷数",
        tip86: "出金の確認",
        tip87: "出金アドレスが間違っています",
        tip88: "出金金額が間違っている",
        tip89: "引き出す金額は少なくとも",
        tip90: "出金額の上限は、",
        tip91: "出金リクエストが送信されました",
        tip92: "アドレスリスト",
        tip93: "まだアドレスを追加していません",
        tip94: "今すぐ追加してください",
        tip95: "アドレスを追加",
        tip96: "新しいアドレスを追加",
        tip97: "選んでください",
        tip98: "住所",
        tip99: "転送ネットワークを選択してください",
        tip100: "住所を入力してください",
        tip101: "アドレスが正常に追加されました",
        tip102: "取引中",
    },
    fundExchange: {
        title1: "資金の交換方法？",
        title2: "換算する適切な通貨を選択してください。",
        title3: "変換する適切な通貨を選択してください。",
        title4: "交換する金額を入力し、「送信」をクリックして資金交換を完了します。",
        title5: "から",
        title6: "到着",
        title7: "交換数量",
        title8: "為替レート",
        title9: "利用可能",
        title10: "引き換え数量を入力してください",
        title11: "交換に対応する取引ペアはありません。",
        title12: "引き換える数量を入力してください",
        title13: "償還が成功しました",
        title14: "交換記録",
        table1: "通貨を使用する",
        table2: "量",
        table3: "為替レート",
        table4: "通貨両替",
        table5: "レシート",
        table6: "時間",
        title15: "から",
        title16: "現在利用可能なアセットがありません。別のアセットを選択してください"
    },
    fundTransfer: {
        title1: "資金を移動するにはどうすればよいですか?",
        title2: "移管する現在の口座を選択してください。",
        title3: "移管したいアカウントを選択してください。",
        title4: "送金金額を入力し、「送信」をクリックして送金を完了します。",
        title5: "通貨",
        title6: "転送量",
        title7: "送金額を入力してください",
        title8: "転送記録",
        title9: "ウォレットのタイプを選択してください",
        title10: "通貨を選択してください",
        title11: "ウォレットのタイプを選択してください",
        title12: "通貨を選択してください",
        title13: "送金額を入力してください",
        title14: "正常に送信されました",
        table1: "口座振替",
        table2: "通貨を送金する",
        table3: "転送量",
        table4: "振込口座",
        table5: "時間",
        table6: "移行",
        table7: "口座振替え",
        table8: "転送先",
        table9: "転送記録",
        table10: "転送詳細",
        table11: "転送成功",
        table12: "転送に失敗しました",
        table13: "現在のアカウントは移管できません"
    },
    withdrawal: {
        title1: "重要なお知らせ",
        title2: "1.裁定取引を防ぐため、取引量が割り当て量に達したときに通貨の出金を申請できます。",
        title3: "2.出金申請は提出後 24 時間以内に入金されます。出金予定時刻を過ぎても出金が受け取られない場合は、オンライン カスタマー サービスにお問い合わせください。",
        title4: "3.出金申請後、出金処理中のため資金が凍結された状態となり、システム上で資金が一時的に保持されるため、資産が紛失したり、資産に異常が発生したりするものではありません。",
        title5: "通貨",
        title6: "ウォレットアドレス",
        title7: "引き出したコインの量",
        title8: "コイン出金記録",
        title9: "通貨を選択してください",
        title10: "チェーンの種類を選択してください",
        title101: "",
        title11: "入ってください",
        title12: "ウォレットアドレス",
        title13: "正常に送信されました",
        title14: "検討中",
        title15: "レビューは成功しました",
        title16: "監査の失敗",
        title17: "支出済み",
        title18: "ウォレットアドレスを入力または貼り付けてください",
        title19: "出金額を入力してください",
        title20: "手数料",
        title21: "実際の到着",
        table1: "通貨",
        table2: "引き出したコインの量",
        table3: "チェーン名",
        table4: "ウォレットアドレス",
        table5: "州",
        table6: "理由",
        table7: "申請時間",
        table8: "コインを引き出す",
        table81: "コインを引き出す",
        table9: "出金アドレス",
        table10: "出金したい通貨を選択してください",
        table11: "必ずご入力ください",
        table12: "アドレス (通常は「0x」で始まります)",
        table13: "出金可能コイン（手数料差し引き）：",
        table14: "通貨を選択してください",
        table15: "問題が発生しましたか? 即時フィードバック",
        table16: "バランス",
        table17: "出金詳細",
        password: "パスワード",
        passwordTips: "パスワードを入力してください",
        passwordAg: "パスワードを認証する",
        passwordAgTips: "パスワードをもう一度入力してください",
        oldPassword: "以前のパスワード",
        oldPasswordTips: "古いパスワードを入力してください",
        newPassword: "新しいパスワード",
        newPasswordTips: "新しいパスワードを入力してください",
        passwordNotSame: "パスワードが矛盾しています",
        withdrawPasswordSet: "出金パスワードを設定する",
        withdrawPasswordModify: "出金パスワードを変更する",
        withdrawPassword: "パスワードを撤回する",
        withdrawPasswordTips: "出金パスワードを入力してください",
        setWithdrawPasswordFirst: "最初に出金パスワードを設定してください",
        withdrawPasswordSetSuccess: "セットアップに成功",
        withdrawPasswordModifySuccess: "正常に変更されました",
        submit: "提出する",
    },
    customer: {
        content1: "オンラインサービス",
        content2: "顧客サービス",
        content3: "連絡先情報を入力してください",
        content4: "内容を入力してください",
        content5: "送信",
        content6: "送信失敗、ネットワーク接続失敗",
        content7: "写真",
        content8: "メッセージが空です",
        content9: "私の分け前",
        content10: "共有リンク",
        content11: "画像を保存する",
        content12: "私の収入",
        content13: "レベルの説明",
        content14: "チームの規模",
        content15: "招待収入",
        content16: "ルールの説明",
        content17: "会員レベル",
        content18: "リチャージ金額が次を超えています",
        content19: "チームレベル",
        content20: "チームレベルと収入",
        content21: "条件を満たすには",
        content22: "配給収入",
        content23: "レベルチーム",
        content24: "レベル、招待状",
        content25: "有効なメンバー",
        content26: "主な収入",
        content27: "副収入",
        content28: "レベル 3 の特典 -",
    },
    standardContract: {
        increaseMargin: "マージンを増やす",
        increaseAmount: "増加額",
        Pleaseenterthemargin: "増加する証拠金額を入力してください",
        Pleaseenterthecorrect: "正しい入金額を入力してください",
        Increasemarginsuccessfully: "マージンを正常に増やす",
        takeProfitPriceWrong: "利益確定価格が間違っている",
        stoppriceerror: "逆指値エラー",
        thetakeprofit1: "テイクプロフィット価格は市場価格より高くなければなりません",
        thetakeprofit2: "ストップロス価格は市場価格より低くなければなりません",
        thetakeprofit3: "テイクプロフィット価格は市場価格より低くなければなりません",
        thetakeprofit4: "ストップロス価格は市場価格より高くなければなりません",
        thetakeprofit5: "数量が間違っています",
        estimatedprofitloss: "予想損益",
        positionAmount: "ポジション数",
        closeAmount: "締め切り数量",
        whenthelatest: "最新の価格に達したとき",
        whenthelatest2: "、市場価格の利益確定注文がトリガーされ、現在のポジションを決済します。 予想損益",
        whenthelatest3: "、現在のポジションを閉じるために市場のストップロス注文がトリガーされます。 予想損益",
        contract: "契約",
        ordercanceledsuccessfully: "注文は正常にキャンセルされました",
        successfullyordered: "正常に注文されました",
        positionopeningreminder: "ポジション開始リマインダー",
        wrongquantity: "数量が間違っています",
        quantitycannot: "数量を超えることはできません",
        modificationstoploss: "ストッププロフィットとストップロスの変更が成功しました",
        positionclosedsuccessfully: "ポジションが正常にクローズされました",
        orderCancellation: "注文のキャンセル",
        orderCancellation2: "注文をキャンセルしてもよろしいですか?",
        tobeexecuted: "実行される",
        expired: "期限切れ",
        failed: "失敗した",
        contractTransaction: "永久契約",
        increase: "増加",
        openPosition: "倉庫がいっぱい",
        isolatedPosition: "孤立した位置",
        limitOrder: "価格制限",
        marketOrder: "市場価格",
        pleaseSelectLeverage: "レバレッジを選択してください",
        pleaseEnterQuantity: "数量を入力してください",
        canOpenMore: "開けられる最大位置",
        contractAmount: "契約額",
        openingFee: "手数料",
        currentPosition: "位置",
        historicalPositions: "過去の取引",
        currentCommission: "現在の注文",
        historicalCommission: "歴史委員会",
        buyPrice: "購入価格",
        income: "利益",
        quantity: "量",
        marginRate: "マージン率",
        initialMargin: "当初証拠金",
        estimatedPriceParity: "強制清算価格",
        operate: "操作する",
        finishQuantity: "取引量",
        averagePrice: "平均取引価格",
        pleaseEnterLeverage: "レバレッジを入力してください",
        takeProfitAndStopLoss: "テイクプロフィットとストップロス",
        takeProfit: "利益確定",
        stopLoss: "ストップロス",
        openLong: "Long",
        openShort: "Short",
        liquidation: "クローズポジション",
        marketLquidation: "市場価格は横ばい",
        cancel: "注文をキャンセルする",
        enterBuyPrice: "購入価格を入力してください",
        areYouSureLquidation: "ポジションを閉じてもよろしいですか?",
        confirm: "もちろん",
        enterStopWinPrice: "テイクプロフィット価格を入力してください",
        enterStopLossPrice: "ストップロス価格を入力してください",
        commissionTime: "コミッションタイム",
        commissionPrice: "手数料価格",
        commissionQuantity: "手数料の総額",
        volumeAlreadyTraded: "完了金額",
        areYouSureToWinOrLoss: "プロフィットストップとロスストップを実行してもよろしいですか?",
        maxQuantity: "最大位置",
        saleQuantity: "ポジションをクローズ",
        salePrice: "平均終値",
        saleTime: "時間",
        succeeded: "成功しました",
        cancelled: "キャンセル",
        piece: "開ける",
        triggerPrice: "トリガー価格",
        setWin: "テイクプロフィットを設定する",
        setLoss: "ストップロスを設定する",
        enterWinQuantity: "テイクプロフィット金額を入力してください",
        enterLossQuantity: "ストップロス金額を入力してください",
        planCommission: "企画手数料",
        enterWinTriggerPrice: "利食いトリガー価格を入力してください",
        enterLossTriggerPrice: "ストップロストリガー価格を入力してください",
        insufficientQuantity: "数量が不十分です",
        enterTriggerPrice: "トリガー価格を入力してください",
        tips1: "注文価格は現在の価格より高いため、現在の市場価格で直接約定されます。本当に購入しますか?",
        tips2: "あなたの注文価格は現在の価格より低く、現在の市場価格で直接約定されます。本当に購入しますか?",
        type: "タイプ",
        increaseQuantity: "位置を増やす",
        reduceQuantity: "ポジションを減らす",
        limitCommission: "指値注文",
        tips3: "ストップロス価格は市場価格より低くなければなりません",
        tips4: "テイクプロフィット価格は市場価格より高くなければなりません",
        tips5: "ストップロス価格は市場価格より高くなければなりません",
        tips6: "テイクプロフィット価格は市場価格より低くなければなりません",
        tips7: "ストップロス価格は市場価格よりも低く、注文価格よりも低くなければなりません",
        tips8: "テイクプロフィット価格は注文価格より大きくなければなりません（市場価格より小さくても構いません）",
        tips9: "ストップロス価格は市場価格よりも大きく、注文価格よりも大きくなければなりません",
        tips10: "テイクプロフィット価格は注文価格より小さくなければなりません（市場価格より大きくても構いません）",
        accountEquity: "勘定残高",
        unrealizedPL: "未実現損益",
        tips11: "本当に正しいですか",
        tips12: "市場価格",
        tips13: "すべてのポジションをクローズする",
        tips14: "二度と思い出させないでください",
        profitRate: "クロージング収益率",
        openTime: "開始時間",
        unwindTime: "閉店時間",
        dealTime: "トランザクション時間",
        amountClose: "クローズポジションの量",
        long: "買う",
        short: '売る',
        orderQuantity: "注文数量",
        entrustedMargin: "委託証拠金",
    },
    spotGoods: {
        spotGoods: "両替",
        sellPrice: "販売価格",
        orderRecord: "フラッシュ引き換え記録",
        transactionTime: "トランザクション時間",
        maxBuy: "購入可能",
        maxSell: "販売可能",
        purchaseQuantity: "購入数量",
        slippage: "滑り",
        convertTips: "0～5%のスリッページで最も有利な価格で取引を終了させて​​いただきます。",
        changePrice: "現在の為替価格",
        consume: "消費する",
        get: "得る",
        changeTips: "本当に引き換えますか?",
        confirm: "引き換えを確認する"
    },
    news: {
        title: "ニュースセンター",
        redMore: "続きを読む",
    },
    credit: {
        credit: "信用分",
        creditScore: "信用スコア合計",
        latestEntry: "最新の記録",
        liveUpdate: "ライブアップデート",
        systemEvaluation: "システム評価",
        time: "日付",
        name: "名前",
        reason: "理由",
        scoreStory: "信用スコア記録"
    },
    borrowing: {
        borrowing: "ローン",
        diversifiedMarket: "多様化する市場",
        totalSupply: "総供給量",
        totalBorrowings: "借入総額",
        tips1: "これは、借入限度額まで、担保に対して借入できる総額です。",
        tips2: "安定した金利はローン期間中同じままです。 長期供給や予測可能性を好むユーザーにおすすめです。",
        totalSupplys: "総供給量",
        supplyAPR: "供給率",
        totalBorrowings: "借入総額",
        borrowAPR: "借入金利",
        check: "チェック",
        market: "市場",
        availableLiquidity: "利用可能な流動性",
        currencyPrice: "現在の通貨の価格",
        supplyAssets: "供給資産",
        tips3: "※下記金利は固定金利であり、供給期間終了後は元金と収益金が返金されます。",
        supplyCycle: "供給サイクル",
        minimumSupply: "最低供給量",
        supply: "供給",
        borrowedAssets: "借用資産",
        tips4: "知っておくべき重要事項: お金を借りるには、担保となる資産または証拠を提供する必要があります。",
        tips5: "住宅ローン資産の説明をご覧ください。",
        repaymentCycle: "返済サイクル",
        BorrowableAmount: "融資可能額",
        borrow: "ローン",
        accountInformation: "口座情報",
        walletBalance: "ウォレット残高",
        canSupply: "利用可能",
        AmountCanBeBorrowed: "融資可能総額",
        borrowedAmount: "借入額",
        remainingAmount: "保たれているバランス",
        getBorrowednAmount: "融資限度額を取得する",
        supplyOrder: "供給命令",
        borrowedOrder: "ローン注文",
        supplyAmount: "供給量",
        expectedProfits: "期待リターン",
        supplyTime: "供給時間",
        expirationTime: "有効期限",
        borrowingAmount: "融資額",
        dueRepayment: "返済額",
        borrowingTime: "リリース時間",
        repaymentTime: "返済時期",
        repay: "返済",
        supplyCurrency: "供給通貨",
        buyNow: "今すぐ購入",
        supplyRecords: "供給記録",
        borrowRecords: "融資記録",
        completed: "完成した",
        borrowNow: "今すぐ借りる",
        borrowCurrency: "借入通貨",
        borrowMin: "最小",
        borrowMax: "最大",
        borrowRange: "借入額（最低額・最高額）",
        tips6: "期限内にご返済いただきますようお願いいたします。延滞した場合は、日額（元金＋利息）が別途かかります。",
        tips7: "延滞料金について。",
        pleaseEnterBorrowAmount: "融資金額を入力してください",
        interest: "興味",
        tips8: "本当に前払いしてもよろしいですか？",
        borrowChannel: "チャンネルを借りる",
        tips9: "あなたを知ることから始めましょう",
        name: "名前",
        lastName: "姓",
        birthDate: "生年月日",
        tips10: "もう少し詳しく教えてください、あなたの住所はどこですか？",
        familyAddress: "自宅住所（ユニット番号を含む）",
        city: "市",
        country: "国家",
        postalCode: "郵便番号",
        pleaseEnterYourHomeAddress: "自宅の住所を入力してください",
        tips11: "あなたの経済状況について教えてください",
        tips12: "この情報は後で確認する必要がある場合がありますので、できるだけ正確になるよう努めてください。",
        yourMonthlyIncome: "あなたの月収",
        enterYourMonthlyIncome: "月収を入力してください",
        extraIncomeOptional: "副収入（任意）",
        enterExtraIncome: "追加収入を入力してください",
        monthlyRentOrMortgagePayment: "毎月の家賃や住宅ローンの支払い",
        enterMonthlyRent: "毎月の家賃または住宅ローンの支払い額を入力してください",
        tip13: "住宅ローンがある場合は教えてください",
        tip14: "住宅ローン保証を選択していない場合、審査に通る確率は低くなります。",
        isThereMortgage: "住宅ローンはありますか？",
        selectIsThereMortgage: "住宅ローンの有無を選択してください",
        selectMortgageType: "住宅ローンの種類を選択してください",
        yes: "はい",
        no: "いいえ",
        uploadScreenshotOfTransaction: "取引のスクリーンショットをアップロードしてください",
        tip15: "これからも連絡取り合おうね",
        tip16: "ローン申請に関する詳細情報が必要な場合は、この番号に連絡することがあります。",
        tip17: "同意を読んで電子署名しました",
        tip18: "利用規約と電子同意",
        tip19: "，これには、当社に関する情報を送信することに対するお客様の同意が含まれます。",
        tip20: "製品およびサービスの利用規約",
        tip21: "情報を入力したら、システムの評価とレビューをお待ちください。",
        phoneNumber: "電話番号",
        enterPhoneNumber: "電話番号を入力してください",
        tip22: "利用規約と電子同意フォームに同意するボックスにチェックを入れてください",
        inReview: "検討中",
        applicationAmount: "申請金額",
        reasonForRejection: "拒否理由",
        reapply: "再申請",
        applyStatus: "アプリケーションの状態",
        currentOrder: "現在の順序",
        interestRate: "金利"
    },
    yuebao: {
        filed1: "投資する",
        filed2: "テイクアウト",
        filed3: "一日の収入",
        filed4: "チームの加速",
        filed5: "入金済み入金済み",
        filed6: "昨日の収益",
        filed7: "総収入",
        filed8: "利用可能な資金",
        filed9: "金額を入力してください",
        filed10: "流動性マイニング",
        filed11: "導入",
        field12: "最低購入価格",
        field13: "買えるもので一番多いのは",
        filed14: "サブスクリプション",
        filed15: '通常',
        filed16: '年換算金利',
    },
    tipsPopover: {
        title: "このアプリケーションをインストールする",
        press: "によると",
        and: "そして",
        scrren: "ホーム画面に追加します",
        hasInstall: "(インストールされている場合はウィンドウを閉じてください)",
    },
    follow: {
        title1: "フォローアップ計画",
        follow_text1: "フォローアップ注文を開始する",
        follow_text2: "発売履歴",
        follow_text3: "タイトル",
        follow_text4: '取引ペア',
        follow_text5: '購入期間',
        follow_text6: '注文時間',
        follow_text7: 'リリースタイム',
        follow_text8: 'フォロワー数',
        follow_text9: '注文金額',
        follow_text10: '操作する',
        follow_text11: '招待する',
        follow_text12: '注文の指示に従ってください',
        follow_text13: '注文条件を設定する',
        follow_text14: '次の注文の条件',
        follow_text15: '定額',
        follow_text16: '割合',
        follow_text17: '招待された人々',
        follow_text18: '相手のID',
        follow_text19: "注文に従うことを確認します",
        follow_text20: "ご注文金額（任意）",
        follow_text21: "タイトルを入力してください",
        follow_text22: "取引ペアを選択してください",
        follow_text23: "注文方向を選択してください",
        follow_text24: "購入期間を選択してください",
        follow_text25: "注文時間を選択してください",
        follow_text26: "購入数量を選択してください",
        follow_text27: "最大購入数量を最小購入数量より小さくすることはできません",
        follow_text28: "最低購入額",
        follow_text29: "ほとんどが購入",
        follow_text30: "コピー注文が正常に作成されました",
        follow_text31: "取引ペアリスト",
        follow_text32: "すでに注文に従っています",
        follow_text33: "招待者の情報を入力してください",
        follow_text34: "招待されました",
        follow_text35: "私を招待した",
        follow_text36: "コピー履歴",
        follow_text37: "注文金額範囲"
    },
    followHistory: {
        title1: '発売履歴',
        title2: "コピー履歴",
        follow_h_text1: "ご注文の合計金額",
        follow_h_text2: "注文に従った人の合計数",
        follow_h_text3: "リリース数",
        follow_h_text4: "勝率",
        follow_h_text5: "命令に従う回数",
        follow_h_text6: "次の注文から利益を得る",
        follow_h_text7: "方向",
        follow_h_text8: "勝つ",
        follow_h_text9: "赤字",
    },
    "asset.index.1579E8B41F8D5134": "私の資産",
    "asset.index.B3F6F05Dfbe431Cd": "シミュレーション",
    "asset.index.B9Aeab4Ee8F33746": "資産評価",
    "asset.index.583Df8Bd541Eb7E8": "業績推移",
    "asset.index.B1254815D133Cc26": "7日",
    "asset.index.2F615F6C029F01B7": "30日",
    "asset.index.29439Fb58E4Bbe22": "現金引出",
    "asset.index.831Ab31568A78285": "チャージ",
    "asset.index.39F7A0D48D12E8C9": "移行",
    "asset.index.B99063C5689Beaec": "両替",
    "asset.index.E1Dc620F2F038B2A": "資金の受取",
    "asset.index.972A0B4C2B4393D0": "私のアカウント",
    "asset.index.9Dfb0Fb334620733": "利用可能残高",
    "asset.index.Cdc5Fbd26790Cb47": "冷凍量",
    "asset.index.3D8132D72Ae965Cd": "評価",
    "asset.receive.825503Cc430559Cb": "資産を取得する",
    "asset.receive.54154A0553A535B7": "デモアセット",
    "asset.receive.F2E57B00A9C5D129": "1.このアセットはデモアカウントモードでのみ使用できます",
    "asset.receive.Ea0D1D52989299Bd": "2.乱用を防ぐため、引き換えは 1 か月以内に 1 回のみ許可されます。",
    "asset.receive.090E0037Aa235D3D": "3.資産を受け取ると、元のベースで受け取った資産の額が追加され、ポジションのステータスは変更されません。",
    "asset.receive.7Fd4E1Abc805C0A6": "4.他にご質問がある場合は、カスタマーサービスまでお問い合わせください",
    "asset.receive.Df5B3A511D986575": "今すぐデモアセットを入手",
    "asset.records.1Fa02E8187A39592": "過去3ヶ月間",
    "asset.records.7B291C37333Fe34D": "近くから遠くまで",
    "asset.records.A476Cd1Ec868Fc5F": "過去3ヶ月間,",
    "asset.records.9D5755A842E55Bb7": "近くから遠くまで,",
    "asset.records.E3Fa05E607203Cdc": "チャージ",
    "asset.records.B4F206C6Bec08Dea": "現金引出",
    "asset.index.Df5B3A511D986576": "デモ アセットを正常に受け取りました",
    "deposit.depositSpeedy.Efe81D0E0174239B": "支払方法",
    "deposit.depositSpeedy.B9293Ee96E435F85": "法定通貨",
    "deposit.depositSpeedy.16658A855F07D171": "額",
    "deposit.depositSpeedy.204Ffab8A6E01870": "もちろん",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "空にすることはできません",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "最小リチャージ",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "最大充電量",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "USDTを入金する",
    "deposit.depositWallet.551640C76F4Ca4A1": "通貨",
    "deposit.depositWallet.B404C543712B71D5": "チェーン名",
    "deposit.depositWallet.A484B0F5E94B3F4F": "入金アドレス",
    "deposit.depositWallet.D3980D54Acf242Db": "QRコードを保存する",
    "deposit.depositWallet.A06E84801D6582Be": "チャージ金額",
    "deposit.depositWallet.Daaaeb1B7B22B126": "ヒント",
    "deposit.depositWallet.D599E40E1Eeec1B3": "リチャージ成功",
    "deposit.depositWallet.4F48Abbafce532Bb": "正常に送信されました。カスタマー サービスのレビューを待っています",
    "deposit.depositWallet.A88C481228811696": "ネットワークの切り替えを拒否しました",
    "deposit.depositWallet.C6Ebf27895C21932": "TRONウォレットプラグインを手動で開いてログインしてください。",
    "deposit.index.B5491C8Dd09D8183": "チャージしたい通貨を選択してください",
    "deposit.index.2F784B2298D39987": "急速充電",
    "deposit.index.0Daa03Dcdcabd837": "オフサイトリンク",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "出金確認",
    "withdraw.bottomSheet.A086Fa20082C849D": "以下の情報が正しいことを確認してください",
    "withdraw.bottomSheet.Bf8B184D32971501": "引き出しネットワーク",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "住所",
    "withdraw.bottomSheet.F9Db93B87E08763B": "量",
    "withdraw.bottomSheet.4Ad26475002878C0": "手数料",
    "withdraw.index.A96454041169Af6D": "通貨を選択してください",
    "withdraw.index.2A563B7081Dd5B83": "出金アドレス",
    "withdraw.index.B189C2Ef713Cfff4": "引き出したコインの量",
    "withdraw.index.558F35140E84B562": "利用可能：",
    "withdraw.index.F4E759087E12Cb77": "全部撤回",
    "withdraw.index.5Cbd9A6B284D25Ae": "基金のパスワード",
    "withdraw.index.B89Cbe4Bfc33Bb41": "手数料:",
    "withdraw.index.C886De8D8A27026E": "重要なお知らせ",
    "withdraw.index.Eac7C5A4095F5606": "1.裁定取引を防ぐため、取引量が割り当て量に達したときに通貨の出金を申請できます。",
    "withdraw.index.C3Cc7Fff24504Be9": "2.出金申請は提出後 24 時間以内に入金されますが、出金予定時刻を過ぎても出金が届かない場合は、オンライン カスタマー サービスにご相談ください。",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.出金申請後、出金処理中のため資金が凍結された状態となり、システム上で資金が一時的に保持されるため、資産が紛失したり、資産に異常が発生したりするものではありません。",
    "withdraw.index.4639F3A6E07C00B3": "次のステップ",
    "withdraw.index.81Fba0D65Cc5C3Aa": "ウォレットアドレスを入力または貼り付けてください",
    "withdraw.index.25768141C8Bdea91": "出金額を入力してください",
    "withdraw.index.97A85734Cd1B7Bf0": "ファンドのパスワードを入力してください",
    "withdraw.index.39C7D74B33241C78": "出金額が間違っています",
    "withdraw.index.86A5D17908197525": "出金確認、コインリリースの審査待ち",
    "withdraw.index.48Fb603C6638C7F6": "ファンドのパスワードを設定してください",
    "withdraw.index.873Ade9A3Bacc2F7": "ヒント",
    "bill.index.5Bbb2A6Ac68D6B2E": "実際の",
    "bill.index.75751E0Eb8261C40": "アナログ",
    "bill.index.Cbc853B37Ecc2E97": "アカウント変更タイプ",
    "bill.index.4A0Cacddeb2E38B1": "口座の種類",
    "bill.index.F16217028E098B2F": "通貨",
    "bill.index.A620Dbe6F06B78Ba": "いろんなタイプ",
    "bill.index.2C3C148Bb94D9B3F": "すべてのアカウント",
    "c2C.index.Ab45695E4Bf1E382": "C2C取引",
    "c2C.index.2702Dc911Dfea762": "私が購入したい",
    "c2C.index.D737D5B8367A53C4": "売りたい",
    "c2C.index.C0C540A3Ba9Abe01": "音量",
    "c2C.index.D8644E8D60C8Bd8A": "単価",
    "c2C.index.Ebb8670B203D3604": "限界",
    "c2C.index.F8C6245A2Ebf1D27": "すべての銀行カード",
    "c2C.index.F8C6245A2Ebf1D28": "通貨の収集",
    "c2C.index.F8C6245A2Ebf1D29": "バンクカード",
    "c2C.index.Cc3Aa8141Da9Ec9E": "買う",
    "c2C.index.Cc3Aa8141Da9Ec9F": "売る",
    "c2C.index.67A39834582Ad99A": "データなし",
    "c2C.order.04C23810B47F77F7": "金額によって",
    "c2C.order.6905365902E0A73D": "量によって",
    "c2C.order.3F0Ecebbd9D2F23B": "利用可能",
    "c2C.order.16853Bda54120Bf1": "全て",
    "c2C.order.Ad3335796F2C95F7": "一時金",
    "c2C.order.61092E27A6879318": "資産の安全を守るために予防意識を高めてください！",
    "c2C.order.0F51881F0Fb920B2": "取引情報",
    "c2C.order.Dd74Ceca85E5Bc65": "支払い期限",
    "c2C.order.De3341388A59073F": "30分",
    "c2C.order.D334B597A352167F": "販売者のニックネーム",
    "c2C.order.D334B597A352167G": "購入者のニックネーム",
    "c2C.order.9D5F89F99Ced890D": "取引手段",
    "c2C.order.8Adf755B14Bc6B7C": "まずは以下をお読みください",
    "c2C.order.686B16F454A7338B": "銀行カード送金の際は何も言わないでください。そうしないと、お金は解放されず、口座が直接ブロックされます。 支払い後、支払い後に新しい取引詳細図を提供する必要があります（アカウントを凍結するためにP図または非表示の取引詳細がプラットフォームに報告された場合）",
    "c2C.order.9Bec8Fba867B739B": "支払い方法を選択してください",
    "c2C.order.Cf2D892C419Fea36": "最低購入額",
    "c2C.order.7290A6B7456A03E0": "最大の買い物",
    "c2C.orderConfirm.F7E99B8C203E2B07": "注文詳細",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "注文が生成されました",
    "c2C.orderConfirm.0F5433503F3845Ce": "カウントダウン時間内に販売者にお支払いください。",
    "c2C.orderConfirm.24C3Bcacdb85A451": " 購入者の支払いを待っています ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "販売者に連絡する",
    "c2C.orderConfirm.8E8140D7Ab607B25": "購入者に連絡する",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "売り手のデジタル通貨がエスクロー口座に入金されましたので、ご自由にお支払いください。  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "購入者の本名：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "年中無休のカスタマー サービス サポート ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "注文番号",
    "c2C.orderConfirm.726C51702F70C486": "作成時間",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "カスタマーサービスに連絡する",
    "c2C.orderConfirm.103408Fbbe30287F": "注文をキャンセルする",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "支払いを確認しました",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "既に支払いました",
    "c2C.orderConfirm.387Ca3625B260E4E": "支払い済みの注文",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "取引をキャンセルする",
    "c2C.orderConfirm.76C86566Cc3751C0": "注文がキャンセルされました",
    "c2C.orderConfirm.205B349751D176B4": "支払い完了",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "支払方法",
    "c2C.paymentAdd.C87E865Df6800304": "支払い方法の設定",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "支払い方法の種類",
    "c2C.paymentAdd.06910Bddf002695F": "本名",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "PayPalアカウント",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "銀行口座番号/カード番号」、",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "通貨名",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "有料QRコード",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "クリックしてアップロード",
    "c2C.paymentAdd.6Daae340F2E9718A": "銀行名",
    "c2C.paymentAdd.347751415C3Da489": "チェーン名",
    "c2C.paymentAdd.15B3627Faddccb1D": "述べる",
    "c2C.paymentAdd.86Da96Cbd274058F": "注意: デジタル通貨を販売する際、選択した支払い方法が購入者に表示されますので、情報が正確に入力されていることを確認してください。",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "保存",
    "c2C.paymentAdd.90D2A3A5C07179C0": "本名を入力してください",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "を入力してください",
    "c2C.paymentAdd.Ec389E6190Eedb62": "銀行名を入力してください",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "チェーン名を入力してください",
    "c2C.paymentAdd.94F034F0E01439E0": "住所を入力してください",
    "c2C.paymentAdd.B12E035663A42B32": "すべての銀行カード",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "通貨の収集",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "支払いコードをアップロードしてください",
    "c2C.paymentAdd.07C443212Ff04Bfb": "正常に追加されました",
    "c2C.paymentDetail.86Da96Cbd274058F": "注意: デジタル通貨を販売する際、選択した支払い方法が購入者に表示されますので、情報が正確に入力されていることを確認してください。",
    "c2C.paymentDetail.C4C613Da60226De4": "正常に変更されました",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2Cの支払い方法",
    "c2C.records.9E36893D5736Abb4": "オーダーリスト",
    "c2C.records.4B53F9A5B13B3235": "保留中のお支払い",
    "c2C.records.4B53F9A5B13B3236": "取引をキャンセルする",
    "c2C.records.4B53F9A5B13B3237": "既に支払いました",
    "contract.index.9706Df877458F0Be": "倉庫がいっぱい",
    "contract.index.623Fc7D3D40Aa479": "当初証拠金",
    "contract.index.F92Dce1169330387": "市場価格は横ばい",
    "contract.index.3598B97Dd28Dbf20": "数量（個）を入力してください",
    "contract.index.6E1Fae821Dc0B797": "持続可能な",
    "contract.index.Eb07Eb0C37C14575": "配達",
    "contract.index.Eb6492F19B1C1Fb6": "過去の取引の詳細",
    "contract.index.873Ade9A3Bacc2F7": "ヒント",
    "contract.index.Ff63B0F93D39F50B": "本当に正しいですか",
    "contract.index.Ff63B0F93D39F50D": "長く続けてください",
    "contract.index.Ff63B0F93D39F50E": "短い",
    "contract.index.Ff63B0F93D39F50F": "行為",
    "contract.index.Ff63B0F93D39F50G": "すべてのポジションをクローズする？",
    "customer.dialog.3D410C88E8A24Fe8": "ニックネームの確認",
    "customer.dialog.4B3340F7872B411F": "キャンセル",
    "customer.dialog.549B1456Bb5F98B3": "ニックネームを入力してください",
    "customer.dialog.B773B3E45Ccfad00": "ニックネームが空です",
    "customer.index.7308C6B849D159E9": "画像の詳細",
    "delivery.callDialog.C74F562C3Fe91Fe3": "正常に注文されました",
    "delivery.callDialog.B8C98F42Caa15Ca5": "方向",
    "delivery.callDialog.B79A581Dae543974": "長期購入",
    "delivery.callDialog.B79A581Dae543975": "空売り",
    "delivery.callDialog.3B26F0F7E90773F3": "オープン価格",
    "delivery.callDialog.65Fb252Bd94E1039": "推定収益",
    "delivery.index.B483Ba4Fce633539": "配達",
    "delivery.index.224Dd6Cb58D58249": "交易期间",
    "delivery.index.28158F517E902788": "最低購入額",
    "delivery.index.A1232162B14Bec76": "配送指示書",
    "delivery.index.445B1D3176D9C69D": "歴史的な命令",
    "delivery.index.Ab6Cb86E28653C5C": "配達",
    "delivery.index.20A7A6F14D3698C0": "開始時間",
    "delivery.index.Ec6821A49395Ddc2": "閉店時間",
    "delivery.index.254Fdc665C391743": "終値",
    "delivery.index.2A0C5E68B44A472A": "利益と損失",
    "delivery.index.E05B33309F6D236A": "数量を入力してください",
    "delivery.index.B2899E073Cb355D9": "最低購入額",
    "download.description.869467A93C7Dd4A1": "インストールに関する注意事項",
    "download.description.C80C1Edd6Bee12Bc": "1.説明ファイルのダウンロードを許可する",
    "download.description.2F5Bc180F92642B4": "2.説明ファイルがダウンロードされました",
    "download.description.3C3B849F6Ccf712F": "3.携帯電話の[設定]に入ると、アカウント情報の下にダウンロードした説明ファイルが表示されます。",
    "download.description.86Affd862985E045": "4.クリックして説明ファイルをダウンロードします",
    "download.description.32832492Bfc1D95E": "5.クリックしてインストールします",
    "download.index.245883C80F181C4A": "ダウンロード",
    "download.index.Ef3De8Cb2722937A": "いつでもどこでも取引を始めましょう",
    "download.index.F4A87Eaeed691638": "アプリを通じて最新ニュースを入手してください",
    "download.index.Ad3Aba52D0D39D01": "インストールパッケージをダウンロードする",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "親切なヒント",
    "download.safariGuide.2Cc68Aaadf623477": "Safariでご覧ください",
    "download.safariGuide.C80Fff2A6Ff4E768": "分散型 Web サイトにアクセスしています。Safari アプリケーションで実行してください。",
    "download.safariGuide.06Da016F3E2F79Bd": "リンクをコピーする",
    "finance.index.B034B367545D5236": "グローバルで高品質なプロジェクト",
    "finance.index.6Ebfac2C0A9228B0": "アクティブ ユーザー数 160 万人以上、1,224 億ドル",
    "finance.index.52Bdcaf19659363D": "流動性マイニング",
    "finance.index.D66924272B031Bf5": "超高年収",
    "finance.index.34789D88Afcefa64": "アイテムを見る",
    "finance.index.E12676B95654D21E": "信用ローン",
    "finance.index.4C924Cfd89A11Ac7": "より多くの取引機会を発見",
    "finance.index.C251D4B594Da2848": "AIによる定量化",
    "finance.index.4E53Cf2E5F173Dd3": "ワンクリックリース、AIスマートトランザクション",
    "finance.records.Cce168301447D1Ba": "進行中",
    "finance.records.4587B6Bd7Cfa4454": "償還された",
    "finance.records.18685418E3A50147": "マイニング",
    "finance.records.Df9Fd438F97Cc908": "空",
    "finance.records.E23Cb03C5Ad359E5": "用語",
    "finance.records.Bd87Dac6Cba7Eba8": "購入数量",
    "finance.records.2D0Fff99265E8B1A": "儲かる",
    "finance.records.2D0Fff99265E8B1B": "満期利益",
    "finance.records.024F5F0B851782De": "採掘時間：",
    "finance.records.3143A26D3F983896": "引き換え時間：",
    "finance.records.96574Aaf8Db85Ead": "早期償還",
    "finance.records.531E1829203181Ac": "早期に引き換えますか?",
    "finance.records.9205843B8923Dac3": "引き換えが成功しました;",
    "loan.applyCallback.B9258Ec8746A74D8": "チャンネルを借りる",
    "loan.applyForm.7B8Ed340400A6E2B": "名前",
    "loan.applyForm.549A44Bae725F4A2": "姓",
    "loan.applyForm.2A27E61375E7Fea8": "生年月日",
    "loan.applyForm.89925B019D537738": "電子メールアドレス",
    "loan.applyForm.Afaeb32Cb4D62E28": "自宅住所（ユニット番号を含む）",
    "loan.applyForm.E05B5D049B64B040": "市",
    "loan.applyForm.B166E4E8Fe9513Fa": "国家",
    "loan.applyForm.83Aaede109E395F8": "郵便番号",
    "loan.applyForm.647Ed21332A4968B": "あなたの月収",
    "loan.applyForm.Cf0A9B845D34D4B4": "副収入（任意）",
    "loan.applyForm.7048F618Acc6A54C": "毎月の家賃や住宅ローンの支払い",
    "loan.applyForm.15C1Bba18Ae18545": "住宅ローンがある場合は教えてください",
    "loan.applyForm.3921E90625A84E36": "住宅ローン保証を選択していない場合、審査に通る確率は低くなります。",
    "loan.applyForm.262Eec4F8906B0F6": "住宅ローンはありますか？",
    "loan.applyForm.8634Eb21B6910F21": "住宅ローンの種類を選択してください",
    "loan.applyForm.A4897B3A331B5E61": "取引伝票",
    "loan.applyForm.6623384C683De457": "これからも連絡取り合おうね",
    "loan.applyForm.A8360Bdba86834Cb": "ローン申請に関する詳細情報が必要な場合は、この番号に連絡することがあります。",
    "loan.applyForm.C1C373F385A4C7B3": "電話番号",
    "loan.applyForm.1B9785Badb91191F": "情報を入力したら、システムの評価とレビューをお待ちください。",
    "loan.applyForm.8755B9C761D4080C": "同意を読んで電子署名しました",
    "loan.applyForm.3E987B787468148C": "利用規約と電子同意",
    "loan.applyForm.Bc54E76B61C50Fb9": "規約を読んで同意してください",
    "loan.applyForm.89E158384F634Eb8": "前のステップに戻る",
    "loan.applyForm.Efa5A4548E2F3510": "自宅の住所",
    "loan.applyForm.4Cadf1787541B35D": "はい",
    "loan.applyForm.Fab408D2F4100447": "いいえ",
    "loan.applyForm.3Bad7A307480Cc56": "メールの形式が間違っています;",
    "loan.applyForm.306322F49B36855D": "申請は送信されました。カスタマーサービスのレビューをお待ちください。",
    "loan.applyForm.3C6E0E858C995Ff9": "取引伝票をアップロードしてください",
    "loan.applyForm.2Bf443476B271376": "写真は4枚までアップロード可能",
    "loan.applyQuota.Ef1D8E23003B9A6F": "チャンネルを借りる",
    "loan.applyQuota.Ef1D8E23003B9A6G": "融資限度額を取得する",
    "loan.applyQuota.9E0780F9352C7273": "ウォレット残高",
    "loan.applyQuota.Cbc1Eb9D639A6105": "融資可能総額",
    "loan.applyQuota.033A05462195B080": "借入額",
    "loan.applyQuota.9Aa89B036Ef4821D": "保たれているバランス",
    "loan.applyQuota.5656Fb52Ef17A557": "融資限度額を取得する",
    "loan.applyQuota.6Ff6702B4C917D40": "検討中",
    "loan.applyQuota.6Ff6702B4C917D41": "拒否されました",
    "loan.applyQuota.6Ff6702B4C917D42": "成功しました",
    "loan.applyQuota.0Bad0E857Ea4A952": "申請時間",
    "loan.applyQuota.3E7E56E2De2Cc546": "申請金額",
    "loan.applyQuota.8F1D170D9E54Af5B": "申請の進捗状況",
    "loan.applyQuota.6167Ca035B93Caf3": "理由",
    "loan.applyQuota.410C7954F9A3981D": "再申請",
    "loan.bottomSheet.61Cd62F861D8F5B2": "通貨を選択してください",
    "loan.index.4C10307B22E885C3": "より多くの取引機会を発見し、富を築きましょう",
    "loan.index.Ae8D37Dea2B3717B": "金利",
    "loan.index.1Bb54625682E85Db": "ルールの紹介",
    "loan.index.5Bd31Fab681C6E9D": "続きを見る",
    "loan.index.111F95F404814327": "融資通貨",
    "loan.index.6Bc2723B3Bec106D": "ローン",
    "loan.index.3200106280E6E370": "金利",
    "loan.index.049A6Ccb6C81A635": "融資額（最小/最大）",
    "loan.productDetails.5037Ad86Ab4B7E02": "ローン",
    "loan.productDetails.37181C74559Db182": "注文 ",
    "loan.productDetails.5802Aaf06A1E4755": "流動性",
    "loan.productDetails.89Aea9Cf9337C40F": "借りられる量",
    "loan.productDetails.9E419Ae2D06F3Ad9": "返済額",
    "loan.productDetails.C65F329Ef14C4A3D": "借りたコインの量",
    "loan.productDetails.3A74667815Adc8Bf": "最大",
    "loan.productDetails.Ab851D8351573227": "空",
    "loan.productDetails.Ddf82D8A2C8F46A0": "返済サイクル",
    "loan.productDetails.C105E5562005Aeb1": "空",
    "loan.productDetails.3F346Be2137F0756": "融資額（最小/最大）",
    "loan.productDetails.2707F78Df864E194": "興味",
    "loan.productDetails.B9Dc9B81E726Bdf1": "期限内にご返済いただきますようお願いいたします。延滞した場合は、日額（元金＋利息）が別途かかります。",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%延滞料金について",
    "loan.productDetails.4D94Faa033Be7356": "同意しました",
    "loan.productDetails.62429A957A22780A": "コイン貸付サービス契約書",
    "loan.productDetails.Bc54E76B61C50Fb9": "規約を読んで同意してください",
    "loan.productDetails.Ec8Ba071C53A67Cb": "今すぐ借りる",
    "loan.productDetails.59246A0Bf081D398": "少しでも",
    "loan.productDetails.Ec3F1172B71Cd36C": "最小額",
    "loan.productDetails.7Ccc4Cf1D81930B3": "最大額",
    "loan.productDetails.071488598990Aa68": "規約を読んで同意してください;",
    "loan.productDetails.579Dd216D3339119": "融資成功;",
    "loan.records.4951B36Fd0D2A831": "ローンと信用履歴",
    "loan.records.Bb0Aaa30514B7Cba": "現在の記録",
    "loan.records.0F8B0A7D80769051": "返済履歴",
    "loan.records.D4B42E43D88De6F1": "歴史の記録",
    "loan.records.Cb1847D6281897E6": "融資額",
    "loan.records.C7Bfcacfc2Adea58": "返済額",
    "loan.records.8B369164D5Fea340": "貸出時間：",
    "loan.records.Ab2402Ad3Ffd35E7": "ローンを早めに完済する",
    "loan.records.B7A0Ca0C195D0Cd1": "リリース時間：",
    "loan.records.42014Bdcfd9C411B": "返済時期：",
    "loan.records.A0Acfaee54F06458": "完成した",
    "loan.records.67A39834582Ad99A": "データなし本当に前払いしてもよろしいですか？",
    "loan.records.6A687Ff5B8F1598F": "本当に前払いしてもよろしいですか？",
    "loan.records.7C20D7008D0Eae86": "返済が成功しました",
    "mining.bottomSheet.Ba91637D32Ccba04": "流動性マイニング",
    "mining.bottomSheet.7De3D636A165E008": "利益率",
    "mining.bottomSheet.F3003345B189D683": "採掘期間",
    "mining.bottomSheet.3E4C0A8C533F5848": "コインタイプ",
    "mining.bottomSheet.Adcba368993Ae65E": "定期購入金額（最小/最大）",
    "mining.bottomSheet.94743B24E4Ed4166": "無制限",
    "mining.bottomSheet.286944Fedd211257": "利用可能な資金",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "推定収益:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "今は私のもの",
    "mining.bottomSheet.Bb1493C567C114Dd": "親切なヒント：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "早期償還手数料",
    "mining.index.8B2Bba1422Eed6F0": "純利益100%で数百億の富を生み出す",
    "mining.index.42997E300086Ee89": "最高のリターン",
    "mining.index.510F74F42052Ecce": "鉱業製品",
    "mining.index.B853F96626106912": "採掘に行く",
    "mining.productList.37181C74559Db182": "注文",
    "mining.productList.Aac5B810004C46C8": "通常",
    "mining.productList.D2Fb4F78C387D888": "採掘に成功しました",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "注文の確認",
    "quantify.bottomSheet.6915303B698303B1": "ロボット",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "レンタル金額",
    "quantify.bottomSheet.Fbd931286Be387Aa": "サイクル",
    "quantify.bottomSheet.9Ad84Db481807844": "無期限に",
    "quantify.bottomSheet.994D7226Ca083Ea5": "日次収益率",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "実行日",
    "quantify.bottomSheet.88008603Ba7Bde64": "購入が成功しました",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "これからはレベニューシェアを獲得しましょう！",
    "quantify.bottomSheet.C778A674C4A054F9": "注文を確認する",
    "quantify.bottomSheet.685Dad322B7029B0": "返品取引",
    "quantify.buyForm.Acf8560A04334E95": "購入価格",
    "quantify.buyForm.Cf9Cfbc2C554944F": "概要",
    "quantify.buyForm.8788Df2B625E97B5": "ロボット",
    "quantify.buyForm.50Ca63B45E881D50": "配当金支払時期",
    "quantify.buyForm.Fcda03Adbc5Fca97": "毎日",
    "quantify.buyForm.Afb89504Fc399189": "購入数量を入力してください",
    "quantify.buyForm.1435Afdfb4Ec0596": "最低購入数量",
    "quantify.buyForm.1B97F1730Bacd839": "最大購入数量",
    "quantify.index.32F56C9B7D0E250A": "期間：無期限",
    "quantify.index.E34F028D00904630": "価格",
    "quantify.index.E34F028D00904631": ",日次収益率",
    "quantify.productDetail.6Bbe178Df539A5Ca": "エスクロー金額",
    "quantify.productDetail.53635B53Bca31Dfa": "一日の推定収入",
    "quantify.productDetail.F9E46449A6269E09": "累計収入",
    "quantify.productDetail.98Fc46B1B8C993B2": "注文ホスティング",
    "quantify.productDetail.93624Bb045830966": "今すぐレンタル",
    "quantify.records.0C16A18C32B9Cf4A": "注文履歴",
    "quantify.records.B191F5614B0E2312": "購入履歴",
    "quantify.records.6A06033B86A73F7C": "償還記録",
    "quantify.records.45E0F7E71611618E": "累計収入",
    "quantify.records.45E0F7E71611618F": "満期利益",
    "quantify.records.Aae1201E38C4Baaa": "ランニング",
    "flash.index.C41Fd4F2Fdfa594B": "消費する",
    "flash.index.1Ac59Fa2218E3B00": "利用可能:",
    "flash.index.Bf7233A660821549": "得る",
    "flash.index.548E775Dd67C7472": "交換の確認",
    "flash.index.066A2449Bd616F03": "引き換え数量を入力してください",
    "flash.index.Fca26Fcf9C06563F": "フラッシュ引き換えが成功しました",
    "message.index.40031524233F4564": "メッセージセンター",
    "message.index.8E5586210Fd44D69": "永久位置リマインダー",
    "message.index.Bcd3F470E88C197D": "まだ通知はありません",
    "message.index.23A557Fbce545879": "永久清算通知",
    "message.index.B5E19B36B5125550": "出金リマインダー",
    "message.index.716A981465138334": "充電リマインダー",
    "message.index.884474Ebff2977Ba": "システムインフォメーション",
    "message.index.08Cc7D24752Ec42C": "オンラインサービス",
    "message.index.1D820A6A19Ab025D": "7 X 24 時間のオンラインマニュアルサービス",
    "more.index.29881E0B5Df58Bf7": "その他の機能",
    "more.index.C77Ee35911D10D9D": "共通機能",
    "more.index.3558F69B0260Be61": "トレーディング機能",
    "more.index.7243Fe610Ed98Cec": "リアルアカウント",
    "more.index.7243Fe610Ed98Ced": "アナログ",
    "more.index.693C7B35Bdf3E3A3": "金融",
    "more.index.F855997Fac114554": "ヘルプセンター",
    "more.index.Ae9A1252278Da742": "ヘルプ",
    "more.index.Ace012504Bc529E0": "情報",
    "more.index.05682Dbef5Eeb0A8": "顧客サービス",
    "more.index.C7Af227A5D5C7Bb9": "ライブアカウントに切り替えました",
    "more.index.D818A65592694786": "デモ口座に切り替えました",
    "notice.details.B360807Dbe727E6E": "私たちについて",
    "notice.details.92F2552F695E70E3": "私たちについて",
    "notice.details.7Aaceaf69B67F3D1": "ルールの紹介",
    "notice.index.6128438Be34Bcb9F": "永久通知",
    "notice.index.71627F5Eb7128261": "退会通知",
    "notice.index.2Dce570Fda841705": "リチャージ通知",
    "notice.index.228A7C9C6448A9B1": "システム通知",
    "setting.dialog.8B4F5B74660Deabe": "カラー構成",
    "setting.dialog.Bfc7D25486Ebc1A9": "緑が上がり、赤が下がる",
    "setting.dialog.796Ea0E1A82C320B": "赤が上昇し、緑が下降します",
    "setting.index.8Ddc5864E1739466": "環境設定",
    "setting.index.F5205801D57D6727": "言語",
    "setting.index.2E2C12Dd0Cc1B63B": "見積通貨",
    "setting.index.322D8496Aec3D918": "バージョンナンバー",
    "setting.index.Aa7E6C204C751Cfa": "サインアウト",
    "share.index.75Bc38Bbf7D2E766": "友達を招待",
    "share.index.45575Eb5D42213E6": "私の招待コード",
    "share.index.29F67F0644A78Bf3": "コピー",
    "share.index.19Cc65Bb6Bb98Fe7": "私の招待リンク",
    "share.index.5372712F162Fb78B": "直属の部下の数",
    "share.index.C88D6B95E3Ab7D6F": "部下の総数",
    "share.index.Bb5Fa5471Aeecc62": "直属の部下に対するコミッションリベート",
    "share.index.426B6Bac9A5F3D4F": "合計リベート",
    "share.index.197E4A428F0401C9": "リベート記録の表示",
    "share.records.88A6B2E2B74B13C4": "委託記録",
    "share.records.B671A35Ffdc93B89": "リベート",
    "transfer.index.B17617A869C03Ca5": "財布",
    "transfer.index.E4116Dcc6F6C7270": "転送成功",
    "transfer.index.48Fb603C6638C7F6": "ファンドのパスワードを設定してください",
    "transfer.records.Eb0E39Eb5911D418": "得る",
    "transfer.records.977554Be0Aa65052": "両替時間：",
    "transfer.records.9Aa33884Aa400B7C": "乗り換え時間：",
    "form1.forget.76636Db8Fae5B124": "あなたのメールボックス",
    "form1.forget.35354B9Cca1Bb0E6": "電子メール認証コード",
    "form1.forget.604A0B11E916F387": "パスワード",
    "form1.forget.9Cb1C9C51E7503D6": "パスワードを認証する",
    "form1.forget.3A4F7E04B008453C": "あなたの電話番号",
    "form1.forget.Cf11Dce5B4713B5A": "携帯電話認証コード",
    "form1.forget.3012Edba57D4F297": "あなたのメールアドレスを入力してください",
    "form1.forget.F1Ee1D97E869E447": "確認コードを入力してください",
    "form1.forget.3A5C4C9Ae68Fa0F6": "パスワードを入力してください",
    "form1.forget.C6Cb174Da411D5F7": "携帯電話番号を入力してください",
    "form1.forget.947B3B21Ee45E582": "送信",
    "form1.forget.3E0580F1E8630Df6": "2 つのパスワードが矛盾しています",
    "form1.forget.C5A61836B8Cc74C6": "パスワード変更が正常にリダイレクトされました...",
    "form1.forget.B96D072Bc8962565": "再送信",
    "form1.login.2B72C27Ff828Cfc4": "あなたのパスワード",
    "form1.login.D7C6B872Af9Cb17F": "私のパスワードを覚えておいてください",
    "form1.login.0Fff44C827A4F3B6": "ログイン",
    "form1.login.1B7F2C1Ede3990Cc": "まだアカウントをお持ちでない場合は、 今すぐサインアップ",
    "form1.login.F657F7Be1Cc33Bd2": "パスワードを忘れる",
    "form1.login.2074A1Eec4B088B0": "ウォレットへのログイン",
    "form1.login.1B8938Fbcc3C9B56": "ログイン成功、リダイレクト中...",
    "form1.login.07162A7Bb9310555": "ERC20ウォレットをインストールしてください",
    "form1.login.766969966Ae151Dc": "TRC20ウォレットをインストールしてください",
    "form1.login.B88171E1Ad4C50Bf": "ネットワークの切り替えを拒否しました",
    "form1.register.Aaef0710Cb4Db554": "パスワードを再度入力してください",
    "form1.register.54Dfea0415C708Ff": "招待コード",
    "form1.register.6839940Dac08C794": "登録する",
    "form1.register.9901A3B5550Aa58E": "すでにアカウントをお持ちですか？ ログイン",
    "form1.register.2548Ca53785252Ea": "招待コードを入力してください",
    "form1.register.Dd71Fd65900Dd03E": "登録サービス規約を読み、同意してください,",
    "form1.register.7975613909490B77": "登録が成功し、リダイレクトされます...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "ウォレットを選択してください",
    "userc.certification.2Af9511640153D2E": "ジュニア認定",
    "userc.certification.177B191D8E0Fa84D": "認定されていない",
    "userc.certification.601218D4D572E98A": "検証済み",
    "userc.certification.6E37C51Ba9012Fc8": "不合格",
    "userc.certification.1B9B563E05C96474": "高度な認定",
    "userc.certification.1418B83803Ab56B4": "最初に一次認証を完了してください",
    "userc.creditScore.0B149112601Acc4D": "信用分",
    "userc.creditScore.88Ac7F84E106C5F6": "信用スコア合計",
    "userc.creditScore.3E1D6E0E7F1E6155": "システム評価",
    "userc.index.62Eac58D04Bbca0F": "パーソナルセンター",
    "userc.index.F4Cac53F93Cb2Aff": "模擬取引",
    "userc.index.42F409Fdf3559773": "セキュリティセンター",
    "userc.index.1B8E211009807Db8": "認証",
    "userc.index.Bdfcf21Eb8F2Eb70": "アカウント変更記録",
    "userc.index.6C18D67F6D19586B": "プラットフォームの紹介",
    "userc.index.807A814Ed58A93Bc": "キャッシュの消去",
    "userc.index.E43Daad59759611C": "認定されていない",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "ログインパスワードを変更する",
    "userc.secureCenter.1F37Ba427Debf24A": "アカウントバインディング",
    "userc.secureCenter.F9A5Ffa750664004": "Google認証システムをバインドする",
    "userb.certificationForm.7685Af7D30043Cd6": "ジュニア認定",
    "userb.certificationForm.7685Af7D30043Cd7": "高度な認定",
    "userb.certificationForm.70E37C35Abc3F462": "国",
    "userb.certificationForm.F92C25A68E4D08C5": "市",
    "userb.certificationForm.0Ccc6387B38E0318": "あなたの名前",
    "userb.certificationForm.94C67Bba370Fdd46": "ライセンス番号",
    "userb.certificationForm.B2D4C7Fffe79258E": "郵便",
    "userb.certificationForm.57554268761Fb47A": "身分証明書の前面をアップロードしてください",
    "userb.certificationForm.D79E90201F5319Bd": "身分証明書の裏面をアップロードしてください",
    "userb.certificationForm.9C6Bad08Af29E20E": "IDカードの写真をアップロードしてください",
    "userb.certificationForm.F96B62Dfa31Cde45": "提出の確認",
    "userb.certificationForm.2125D78Ea8C6D287": "アップロード要件",
    "userb.certificationForm.0D64864D3076A824": "例を見る",
    "userb.certificationForm.35287Deb72281E87": "1.IDカード情報を明確に読み取ることができる必要があります。",
    "userb.certificationForm.3007F2E01Efc7B57": "2.写真の内容は真実かつ有効であり、改変は許可されません。。",
    "userb.certificationForm.25A90660554319F9": "3.ID 番号と名前ははっきりと見える必要があり、JPG / JPEG / PNG をサポート",
    "userb.certificationForm.5A9Aefbc03C778F7": "入ってください",
    "userb.certificationForm.3B7A1D8Ce27C937E": "送信は成功しました。審査をお待ちください",
    "userb.certificationForm.744Bd07D8Abd2513": "身分証明書の前面をアップロードしてください",
    "userb.certificationForm.6De1C319130F7Fef": "身分証明書の裏面をアップロードしてください",
    "userb.certificationForm.79F8E8B172E30C8A": "身分証明書の写真をアップロードしてください",
    "userb.certificationForm.46412E16D28A4753": "写真のアップロードに失敗しました",
    "userb.changeDialog.676B0Adca1F8Eea4": "パスワードを変更する",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "電話番号",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "バリデータスイッチ",
    "userb.modifyInfoDialog.62149E566814154C": "バリデーターの選択",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "認証アプリを開き、「+」をタップします",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "「QRコードをスキャン」または「Enterキー」を選択します'",
    "userb.modifyInfoDialog.93F094E2B4369472": "QRコードをスキャンするかキーを入力してください",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "閉じる/開く",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "古いファンドのパスワード",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "新しいファンドのパスワード",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "新しいファンドのパスワードを確認する",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "ファンドのパスワードを確認する",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "古いファンドのパスワードを入力してください",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "新しいファンドのパスワードを入力してください",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "ファンドのパスワードを入力してください",
    "userb.modifyInfoDialog.258A534498Fe984F": "ファンドのパスワードを確認してください",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "アカウントバインディング",
    "userb.modifyInfoDialog.D34746090C25E5Db": "基金のパスワード",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "確認する",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "バリデータは閉じられています",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Google認証が正常に有効になりました",
    "userb.modifyInfoDialog.24Dba26593F43C74": "ファンドのパスワードが正常に変更されました",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "ファンドのパスワードを正常に設定しました",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "バインド成功",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "認証コードエラー",
    "userb.veBottomSheet.Eecc4683C725F6F2": "証明書のアップロード例",
    "userb.veBottomSheet.6F38A0D5858633Ee": "暗いきれいな背景",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "十分な照明",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "アライメント補助枠",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "写真は鮮明です",
    "userb.veBottomSheet.50B741382E71F103": "考えられる失敗の理由",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "IDカード保護カバーが外されていない",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "背景が乱雑な影響を受けています",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "IDカードは反射素材です",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "ライトが暗すぎる/携帯電話の影で遮られる",
    "userb.veBottomSheet.1A75D2Fc2614592B": "IDカードを持っている",
    "home.home.C3525Eb110Db58Aa": "もっと",
    "home.home.29761532Fc8Ebecc": "貿易",
    "home.home.F15Ea99Bfa6E1848": "安全認証済み、安心してご購入ください",
    "home.home.46E1Ab39Bae7182A": "素早い",
    "home.home.Fde2Eb7F6F3Dd800": "迅速な取引と簡単な操作",
    "home.home.Ea4756Bc1642E0F1": "名前",
    "home.home.B6B1127Ede1C97B4": "最新の価格",
    "home.home.3587Ad870Eb0Ab86": "24H増減",
    "home.home.Cb1A4E14Ec2Cd2B5": "発見する",
    "home.home.0F1B1Ac0E96A40D1": "ブロック鎖",
    "home.home.8Eaefb1107A0E210": "外国為替",
    "home.home.Ad65263D034Ea051": "貴金属",
    "home.home.5Fc1474Dbf74Ac1A": "農産物",
    "home.home.135318F4664F7773": "エネルギー",
    "kline.kLine.C96Ff250Cf6B8B84": "24時間最高値",
    "kline.kLine.6E8Be64F19D74565": "24時間の取引量",
    "kline.kLine.08A4Bfbe26A0F262": "24時間最低価格",
    "kline.kLine.8701Deb075066331": "24時間取引高（USDT）",
    "kline.kLine.Ea3C3052136C84Cd": "1点",
    "kline.kLine.B49Cca240E013124": "5点",
    "kline.kLine.F27A90Be97Ba3D6C": "15点",
    "kline.kLine.Ff3E62395B694384": "30点",
    "kline.kLine.C1993F9C6Db1A674": "1時間",
    "kline.kLine.Fd2Cbbae405E284C": "1日",
    "kline.kLine.C9Cd71904395042C": "1月",
    "kline.kLine.7D8127Ec18C7B8C7": "買う",
    "kline.kLine.E3D3Ffdb9B3E5636": "数量（個）",
    "kline.kLine.6834Fec1838D8029": "価格 (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "売り注文",
    "kline.kLine.B9Bea1A9A88D1419": "長く開く",
    "kline.kLine.D36Cfa96Ee2B343B": "売る",
    "other.languageRateSelect.6897784800834C1B": "言語設定",
    "other.languageRateSelect.6897784800834C1C": "価格通貨を選択してください",
    "other.navigationBar.F6E62F9642018Cbb": "表紙",
    "other.navigationBar.0Ec227161A47B49D": "引用",
    "other.navigationBar.29761532Fc8Ebecc": "貿易",
    "other.navigationBar.693C7B35Bdf3E3A3": "金融",
    "other.navigationBar.9734C54620Eb09Af": "資産",

    "home.index.25Aa4Fc745150030": "仮想通貨の王様",
    "home.index.2D6Ecf8Faaa8B124": "より速く、より良く、より強く",
    "home.index.1Db26267D1041223": "1,700以上の仮想通貨を簡単に取引",
    "home.index.F8E18C90C3E35618": "今すぐサインアップ",
    "home.index.29761532Fc8Ebecc": "貿易",
    "home.index.D8A95E4506487452": "人気のある契約",
    "home.index.5Bd31Fab681C6E9D": "続きを見る",
    "home.index.010Ae07A09Caf221": "取引ペア",
    "home.index.F4D875118E6D6Dbb": "最新の取引価格",
    "home.index.3Dfdfb3D6599100E": "24時間価格の増減",
    "home.index.28Fb39Bd41E54242": "傾向",
    "home.index.B47A3911Bacd94C6": "獲得者リスト",
    "home.index.712910D574F43D95": "安定した収入",
    "home.index.5507Fd830D7Bf51F": "簡単にコピーできるトップ戦略",
    "home.index.7De3D636A165E008": "利益率",
    "home.index.B853F96626106912": "採掘に行く",
    "home.index.213B5C96Be7Cd1F8": "日数、年率換算",
    "home.index.213B5C96Be7Cd1F9": "購入可能な最大値",
    "home.index.Dc710Cffe6313Bb5": "詳細を確認する",
    "home.index.B92Ffc837Ecaa1Dd": "ニュース",
    "home.index.6C18D67F6D19586B": "プラットフォームの紹介",
    "home.index.6342E3C33Cb48Cd5": "デジタル通貨は、暗号原理を使用して取引の安全性を確保する交換媒体です。 米ドルなどの法定通貨とは異なり、デジタル通貨には物理的な形がありません。 ビットコイン (BTC)、イーサリアム (ETH)、ポルカドット (DOT) などの人気のあるデジタル通貨は、分散型デジタル台帳として機能する基盤テクノロジーとしてブロックチェーンを使用しています。 すべてのデジタル通貨取引はブロックチェーンに記録され、一度確認および検証されると変更することはできません。 銀行が集中台帳を管理する従来の通貨とは異なり、デジタル通貨取引は誰でもアクセスできるパブリック ブロックチェーン上で行われます。",
    "home.index.198Efc4254683Eef": "さらに、コンセンサスメカニズムに従って、誰もがデジタル通貨取引を検証し、ブロックチェーンに追加できるため、分散化が実現します。 サムスン、ブラックロック、モルガン?スタンレー、アルファベットなどの世界有数の企業が投資しているため、人々はブロックチェーン技術が世界の金融システムを完全に変えると信じています。 デジタル通貨は分散型金融の世界への扉を開き、最先端のテクノロジーがもたらす無限の可能性を探求できるようにします。",
    "home.index.E710339D9576292E": "私たちの利点",
    "home.index.F9Ce8B2C63885B26": "安全で安定した",
    "home.index.C4D0A2C9Ff0305Ce": "トップの技術チーム、包括的なセキュリティ保護、高速トランザクション合成エンジンの独立した研究開発により、大規模な同時トランザクションでも安定して確実に動作できます。",
    "home.index.2E8708Ee3F11Cc1A": "プロフェッショナルで信頼できる",
    "home.index.Bb12Cbcf89C56C5C": "ブロックチェーンと金融で長年の経験を持つ専門的な運用チームは、準拠したデジタル資産取引ライセンスを保持しており、100% の準備金保証を備えています。",
    "home.index.Aa4723Bfbf6808F4": "心を込めて奉仕する",
    "home.index.1Bf0204B7D3Ee230": "世界市場に向けて、複数の言語をサポートし、24 時間年中無休で稼働し、強力なコミュニティ サポートとプロフェッショナルなカスタマー サービスを提供します。",
    "home.index.2E7Dfd53D26F7224": "ハイパフォーマンス",
    "home.index.1C65453103B5Fcc3": "1 秒あたり 300,000 トランザクション、注文応答時間は 1 ミリ秒未満です。",
    "home.index.85Ac49Ece9Dcc80B": "いつでもどこでも取引！",
    "home.index.2799E18842E4Ebe9": "OKEX アプリとウェブページの両方でサポートされているため、即座にポジションをオープンできます!",
    "home.index.2Bf52Ce75E29Fc88": "コードをスキャンして今すぐダウンロードしてください",
    "home.index.88D1422B33571066": "アンドロイド",
    "home.index.04Ee893775563F21": "アップル版",
    "home.index.04Ee893775563F22": "製品サービス",
    "home.index.04Ee893775563F23": "財務管理",
    "home.index.04Ee893775563F24": "戻る",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "ビットコイン、イーサリアム、および",
	"homepage.text1_2": "1000以上の暗号通貨の購入",
	"homepage.text2": "あなたの暗号通貨体験の旅",
	"homepage.text4": "高金利の財務管理",
	"homepage.text5": "安定した財務管理、高年率収益",
	"homepage.text6": "報酬の共有",
	"homepage.text7": "迅速な取引、操作が簡単",
	"homepage.text8": "さらに表示",
	"homepage.text9": "即時交換",
	"homepage.text10": "安全認証で安心購入",
	"homepage.text11": "トップ技術チーム、包括的なセキュリティ",
	"homepage.text12": "保護、独立",
	"homepage.text13": "プロフェッショナルで信頼性のある",
	"homepage.text14": "プロ運営チーム、ブロックチェーンと金融の多年の経験を持つ",
	"homepage.text15": "心を込めたサービス",
	"homepage.text16": "グローバル市場に対応し、多言語で24時間運営",
	"homepage.text17": "高性能",
	"homepage.text18": "秒間30万取引、注文反応時間は1ミリ秒以下",
    "passwod.limit":"パスワードの長さは 6 文字以上である必要があります"
}
