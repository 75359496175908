import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Egyszerűsített kínai",
        dialogDetermine: "biztos",
        dreivatives: "Szállítási szerződés",
        markets: "Idézetek",
        assets: "eszközök",
        liquidity: "Likviditási bányászat",
        helpCentre: "Segítség Központ",
        announcementCenter: "Hirdetési Központ",
        about: "ról ről",
        bulletinCentre: "Hirdetési Központ",
        serviceAgreement: "Szolgáltatási megállapodás",
        support: "támogatás",
        onlineSupportCustomerService: "online szolgáltatás",
        suggestionsAndFeedback: "Javaslatok és visszajelzések",
        service: "Szolgál",
        download: "Letöltés",
        coinApplication: "Érmék listázási alkalmazás",
        privacyStatement: "Adatvédelmi nyilatkozat",
        trade: "szerződéses kereskedés",
        standardConteact: "Fenntartható",
        signOut: "kijelentkezés",
        assetWallets: "Eszköztárca",
        financeRecords: "Számlamódosítási rekordok",
        identification: "Hitelesítés",
        personalCenter: "Személyes központ",
        chooseLanguage: "Válasszon nyelvet",
        tips: "célzás",
        dark: "sötét mód",
        light: "nappali üzemmód",
        languageTitle: "nyelv",
        convertCurrency: "Pénznem konvertálása",
        cancel: "Megszünteti",
        aboutUs: "rólunk",
        standard: "A szabvány folytonossága",
        share: "Ossza meg",
        introduce: "A platform bemutatása",
        set: "beállít",
        changePassword: "Változtassa meg a bejelentkezési jelszót",
        denomination: "Árjegyzés pénzneme",
        color: "Színkonfiguráció",
        code: "verziószám",
        color1: "A piros emelkedik és a zöld leesik",
        color2: "A zöld emelkedik és a piros leesik",
        setLangue: "nyelvi beállítás",
        vip: "VIP előnyök",
        vip2: "Jelenlegi VIP szint",
        safeMenu: "Biztonsági Központ",
        conventional: "hagyományos",
        other: "Egyéb",
        quickTips: "Adjon hozzá legfeljebb 3 gyorsbillentyűt",
        quickTransaction: "Gyors tranzakció",
        safeConvenient: "Biztonságos és kényelmes",
        timeSharing: "időosztás",
        currentLevel: "jelenlegi szint",
        levelBenefits: "%{level} szintű bevétel",
        teamLevel: "Csapat szinten",
        levelLower: "%{level}beosztott",
        rules: "Szabály leírása",
        bindWithdrawalAddress: "Bind visszavonási cím",
        tipsBindWithdrawalAddress: "Még nem kötötte le a kifizetési címét, kérjük, először kösse be",
        walletAddressFormatIncorrect: "A Wallet-cím formátuma helytelen",
        tradeRules1: "Jósolja meg az emelkedést és a bukást a következő időszakban. Kikiáltási árként a rendelés időpontjában érvényes árat, elszámoló árként pedig az előrejelzési időszak utolsó másodpercében érvényes árat használjuk ár alacsonyabb, mint a kikiáltási ár, ez csökkenést jelent.",
        tradeRules2: "1.mi az a szerződés",
        tradeRules3: "Ez a platform USDT-alapú örökös szerződéseket biztosít a felhasználók megítélhetik az emelkedést vagy a csökkenést, és választhatnak a hosszú távú szerződések vásárlása vagy eladása mellett, hogy profitot szerezzenek a digitális eszközök árának emelkedéséből/eséséből. Az állandó szerződéseknek nincs szállítási dátumuk, és a felhasználók örökre megtarthatják őket.",
        tradeRules4: "2.Mi az a margin",
        tradeRules5: "A virtuális szerződéspiacon a felhasználóknak a szerződés teljesítésének pénzügyi biztosítékaként csak a szerződéses árnak megfelelő arányban kell csekély összeget befizetniük, majd részt vehetnek a szerződés adásvételében. Ez a fajta alap a virtuális szerződéses letét. \nAz ezen a platformon lévő szerződések a külön számlák külön fedezeti modelljét alkalmazzák. Minden pénznem egy szerződéses számlának felel meg A különböző szerződéses devizák számlavagyona és pozíciói függetlenek egymástól, a különböző szerződéses számlák közötti átutalások és tranzakciók egymást nem érintik. Miután a felhasználó szerződéses számlája pozíciót nyit, a szerződéses számlán lévő összes pozíció kockázatai és előnyei együttesen kerülnek kiszámításra.",
        tradeRules6: "3.Hogyan kell kiszámítani az árrést",
        tradeRules7: "Pozíció árrés = szerződés névértéke * nyitott szerződések száma / tőkeáttételi szorzó\nbefagyott árrés = az összes sikeresen feladott megbízás és nyitó megbízás fedezetének összege az aktuális megbízásban\nérhető árrés = számlaegyenleg - pozíció árrés - befagyasztott fedezet",
        tradeRules8: "4.Hogyan számítsuk ki a fedezeti rátát",
        tradeRules9: "A fedezeti ráta a felhasználói eszközök kockázatának mértéke. \nMaradék ráta = (Nem realizált nyereség és veszteség + Pozíció margin + Rendelkezésre álló fedezet + Befagyasztás - Likvidációs díj) / Pozíció margin \nMinél kisebb a fedezeti ráta, annál nagyobb a számla kockázata. Ha a letéti ráta kisebb vagy egyenlő, mint 0%, kényszerlikvidálás indul.",
        tradeRules10: "5.Mi a becsült kényszerparitásos ár?",
        tradeRules11: "A becsült kényszer-végelszámolási ár, vagyis az elméletileg kiváltó rendszer által kiszámított ár tájékoztató jellegű, és kis mértékben eltérhet a tényleges értéktől.",
        tradeRules12: "6.Mi a teljes számlatőke és a nem realizált nyereség és veszteség?",
        tradeRules13: "Teljes számla saját tőke = számlaegyenleg + nem realizált nyereség és veszteség\nA nem realizált eredmény a felhasználó által a devizaszerződésben jelenleg tartott pozíció nyeresége és vesztesége A nem realizált eredmény a legutóbbi tranzakciós árváltozással változik. \nHosszú pozíciók nem realizált nyeresége és vesztesége = (1/átlagos pozícióár - 1/legutóbbi tranzakciós ár) * hosszú pozíciókra vonatkozó kontraktusok száma * szerződés névértéke * legutóbbi tranzakciós ár \n rövid pozíciók nem realizált nyeresége és vesztesége = (1/ legfrissebb tranzakciós ár - 1/ Átlagos pozíció ár) * Short kontraktusok száma * Szerződés névértéke * Legutóbbi tranzakciós ár",
        text1: "Fiók biztonság",
        text2: "nappali és éjszakai üzemmód",
        text3: "gyorsítótár törlése",
        text4: "Biztos, hogy ki akar jelentkezni?",
        text5: "Biztosan törli a gyorsítótárat?",
        text6: "többnyelvű",
        text7: "Biztonsága érdekében javasoljuk, hogy kapcsoljon be legalább egy kéttényezős hitelesítést"
    },
    login: {
        login: "Belépés",
        register: "Regisztráció",
        title1: "Kereskedjen bármikor, bárhol, és tekintse meg a valós idejű piaci feltételeket",
        title2: "Kereskedjen bármikor, bárhol, és tekintse meg a valós idejű piaci feltételeket",
        title3: "A legmegbízhatóbb kriptovaluta kereskedési platform",
        formTitle1: "Fiók Belépés",
        formTitle2: "Üdv újra! Jelentkezzen be e-mail címével, telefonszámával",
        btn1: "mobiltelefon",
        btn2: "Levél",
        btn3: "Belépés",
        btn4: "Regisztráció",
        btn5: "elfelejtette a jelszót?",
        field1: "Telefonszám",
        field2: "Jelszó",
        field3: "Levél",
        placeholder1: "nemzet",
        placeholder2: "Kérjük, adja meg a telefonszámot",
        placeholder3: "Kérjük, írja be a jelszót",
        placeholder4: "kérjük adja meg e-mail címét",
        content12: "Kérjük, adja meg a telefonszámot",
        content13: "Kérjük, írja be a jelszót",
        content14: "kérjük adja meg e-mail címét",
        content16: "Digitális eszközök pénzügyi szolgáltatások",
        loginSuccess: "sikeres bejelentkezés ",
        chooseArea: "Kérjük, válassza ki a körzetszámot",
        placeholder5: "Kérjük, válassza ki az ország kódját",
        placeholder6: "Emlékezz a jelszavamra",
        placeholder7: "Töltse le az APP-t",
        placeholder8: "Lépjen kapcsolatba az ügyfélszolgálattal",
        placeholder9: "Válasszon országot vagy régiót",
        placeholder10: "körzetszám",
        placeholder11: "Wallet bejelentkezés",
        placeholder12: "A művelet nem sikerült, kérjük, váltson ide",
        placeholder13: "pénztárca"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "ról ről",
        aboutCoinbeadCenterDetails: "Rendelkezik a világ vezető blokklánc-eszközkereskedési platformjával, és a teljes ökoszisztémát működteti.",
        footDetails1: "napi kereskedési mennyiség",
        footDetails2: "tranzakciók/másodperc",
        footDetails3: "Segítség Központ",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Ez a digitális eszközök világhírű nemzetközi webhelye, amely elsősorban érméket és digitális eszközök származékait kínálja, mint például a Bitcoin (BTC), a Litecoin (LTC) és az Ethereum (ETH) a globális felhasználók számára.",
        coinbeadCenterDetailsm: "A fejlett technológiák, például a GSLB, hálózati szerverek, hálózati tranzakciók, hálózatok, többgépes, nagy sebességű memória kereskedési motorok, hideg pénztárcák, intelligens offline hot pénztárcák stb. átvételével ügyfeleinknek többféle terminált biztosítunk, például webes mobiltelefonokat és PC-ket. Biztonságos, stabil és megbízható digitális tarifa-tranzakciós szolgáltatásokat nyújt. Ugyanakkor a felhasználói javaslatok és igények alapján fejlesztjük és frissítjük a termékeket és szolgáltatásokat, hogy minden vásárlónak jobb szolgáltatásokat nyújthassunk, és az innovatív felhasználói élmény javításának nincs vége.",
        infrastructureCenterTitle: "infrastruktúra",
        infrastructureCenterDetails: "Küldetésünk, hogy infrastrukturális szolgáltatásokat nyújtsunk a teljes blokklánc ökoszisztéma számára",
        freeCenterTitle: "ingyenes",
        freeCenterDetails: "Célunk, hogy a pénz szabadon áramoljon szerte a világon. Hiszünk abban, hogy ennek a szabadságnak a terjesztése jobb hellyé teheti a világot",
        futureCenterTitle: "A technológia jövőjének úttörője",
        futureCenterDetails: "Nyissa meg a technológia jövőjét",
    },
    applicationForListing: {
        title: "Érmék listázási alkalmazás",
        projectName: "Projekt neve",
        tokenName: "Token név",
        projectDescription: "A projekt leírása",
        contractAddress: "Szerződés címe",
        officialWebsite: "hivatalos honlapján",
        ContactAndEmail: "Kapcsolattartó e-mail",
        ContactAndName: "Kapcsolattartó neve",
        file: "Melléklet feltöltése",
        pleaseEnter: "kérlek lépj be",
        enterFile: "Kérjük, töltsön fel fájlokat",
        upload1: "Jelenleg 1 fájl kiválasztása korlátozott",
        upload2: "Ezúttal én választottam",
        upload3: "fájlokat",
        upload4: "Összesen kiválasztva",
        upload5: "fájlokat",
    },
    public: {
        submit: "Beküldés",
        copySuccessfully: "Sikeres másolás",
        copyFailed: "A másolás nem sikerült",
        noDate: "Nincs adat",
        submitSuccessfully: "Sikeresen elküldve",
        goBack: "vissza az utolsó oldalra",
        cancel: "Megszünteti",
        home: "Címlap",
        loading: "Betöltés...",
        confirm: "megerősít",
        loadingFailed: "hálózati anomália",
        nomore: "nem több",
        tryAgain: "Kérjük, frissítse az oldalt / lépjen ki a programból és töltse fel újra!",
        hot: "Népszerű területek",
    },
    inviteFriends: {
        inviteFriends: "Ossza meg barátaival Mindenki",
        freedomOfWealth: "számára előnyös együttműködés",
        myInvitationCode: "a meghívó kódomat",
        clickCopy: "Kattintson a másoláshoz",
        myInvitationLink: "A meghívó linkem",
        clicktoSaveTheQRCode: "Kattintson a QR-kód mentéséhez",
        saveQRCode: "QR-kód meghívása",
        numberOfSubordinates: "Beosztottak száma",
        rebateIncome: "Visszatérítési bevétel",
        people: "emberek",
        myPromotionRevenue: "A promóciós bevételem",
        numberOfDirectSubordinates: "Közvetlen beosztottak száma",
        totalNumberOfSubordinates: "A beosztottak összlétszáma",
        directSubordinateReturnsCommission: "Jutalékkedvezmény a közvetlen beosztottak számára",
        theTotalCommission: "teljes árengedmény",
        checkHistory: "Tekintse meg a visszatérítési nyilvántartásokat",
        history: "Bizottsági rekord",
        theLowerAccount: "Alárendelt fiók",
        type: "típus：",
        serviceCharge: "kezelési költség：",
        percentageofCommissionReturned: "Visszatérítési arány：",
        commissionrebateAmount: "Visszatérítés összege：",
        time: "idő：",
        typeName1: "Feltöltés és jutalék",
        typeName2: "MT megbízáskezelési díj háromszintű elosztás",
        typeName3: "MT nyereséges háromszintű elosztás",
        typeName4: "Ajánlói jutalmak",
        typeName30: "Feltöltés és jutalék",
        typeName31: "Időszakos kereskedési nyereség visszatérítés",
        typeName32: "Likviditási bányászati ​​nyereség visszatérítés",
        typeName45: "Tranzakciós díj visszatérítés",
        typeName46: "Jutalék kedvezmény a saját kezelési díjra",
        typeName50: "Szállítási szerződés kezelési díjának engedménye",
        typeName51: "Folyamatos szerződéskezelési díjkedvezmény",
        typeName52: "További jutalék-visszatérítés a szállítási szerződés kezelési díjaira",
        typeName53: "További jutalékkedvezmény az örökös szerződéskezelési díjakért",
        typeName54: "További kedvezmények az örökös szerződéskezelési díjakból",
        shareRules1: "A meghívó utasításai",
        shareRules2: "Lépjen be a meghívóközpontba, másolja ki a meghívó linkjét vagy kódját, és ossza meg barátaival. A barátok a beosztottakká válhatnak, ha regisztrálnak a meghívókóddal.",
        shareRules3: "Nyerjen engedményeket",
        shareRules4: "Amikor a beosztottak tranzakciókat bonyolítanak le, megfelelő jutalékot kaphat, a beosztottak legfeljebb három szintje támogatott. Például, ha meghívja A barátot, A meghívja B-t, B pedig C-t, akkor A, B és C szerződéseket és egyéb tranzakciókat köthet a platformon, és mindannyian megfelelő jutalékot kaphatnak.",
        shareRules5: "Csapat szinten",
        shareRules6: "Minél több első szintű beosztottat léptet elő, annál magasabb a csapat szintje, és annál magasabb kedvezményt kaphat. A csapatszintek LV1-LV6-ra vannak felosztva. A frissítési szabályok az alábbi táblázatban láthatók, ahol „N” azoknak az első szintű beosztottaknak a száma, akik feltöltötték és végrehajtották a valós név hitelesítését.",
        shareRules7: "Amikor a beosztottak fogadásokat kötnek szállítási szerződésekre, Ön fogadásaik arányában kaphat jutalékot.",
        upgradeConditions: "Kötelező",
        recommendedNumberOfPeople: "Ajánlott létszám"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Javaslatok és visszajelzések",
        text: "Ha hibákat tapasztal vagy termékjavaslatai vannak a használat során, kérjük, adjon visszajelzést.",
        email: "email cím",
        enterEmail: "Kérjük, adja meg az e-mail címet",
        enterCorrectEmail: "Kérjük, adja meg a helyes e-mail címet",
        title: "cím",
        enterTitle: "Adja meg a kérdés címét",
        describeTheProblem: "Írja le a problémát",
        enterDescribeTheProblem: "Kérjük, írja be a leírt problémát",
        submit: "Beküldés",
    },
    help: {
        enterSearch: "keresés",
        title: "Segítség Központ",
        search: "Kérjük, adja meg kérdését",
        text1: "Ismerje meg igényeit, és továbbra is kényelmesebb szolgáltatásokat nyújtson Önnek"
    },
    announcement: {
        enterSearch: "keresés",
        title: "Hirdetési Központ",
        search: "Kérjük, adja meg kérdését"
    },
    privacy: {
        title: "Adatvédelmi nyilatkozat",
        content1: "1. Milyen személyes adatokat gyűjtenek a felhasználókról?",
        content2: "A személyes adatokat a termékek és szolgáltatások biztosításához, valamint azok folyamatos fejlesztéséhez gyűjtjük.",
        content3: "Az alábbiakban felsoroljuk az általunk gyűjtött személyes adatok típusait：",
        content4: 'Az Ön által rendelkezésünkre bocsátott információk: Minden olyan információt megkapunk és tárolunk, amelyet Ön a Platformmal kapcsolatban ad meg. Dönthet úgy, hogy nem ad meg bizonyos információkat, de előfordulhat, hogy ennek következtében nem tud majd használni bizonyos "e platform szolgáltatásait". Példák az Ön által számunkra megadott információkra:：',
        content5: "Név",
        content6: "cím",
        content7: "Az állampolgárság országa",
        content8: "igazolványkép",
        content9: "Mobil telefonszám/e-mail szám",
        content10: "Egyéb információk, amelyek segítenek azonosítani Önt",
        content11: "Automatikusan gyűjtött információk: Automatikusan gyűjtünk és tárolunk bizonyos típusú információkat a Platformszolgáltatások Ön általi használatáról, mint sok webhely, mi is cookie-kat és egyéb egyedi azonosítókat használunk. Bizonyos típusú információkat kapunk, amikor az Ön webböngészője vagy eszköze hozzáfér a Platformhoz. Példák az általunk gyűjtött és elemzett információkra:：",
        content12: "Az Internet Protocol (IP) cím, amely a személyi számítógépet az internethez csatlakoztatja；",
        content13: "Személyes eszköz vagy számítógép bejelentkezési adatai, e-mail cím, jelszó és hely；",
        content14: "Verzió és időzóna beállításai；",
        content15: "tranzakciós előzmények",
        content16: "Egyéb forrásokból származó információk: Információkat gyűjthetünk Önről más forrásokból, például a hitelirodák hiteltörténetéből. Ezeket az információkat a csalások megelőzésére és felderítésére használjuk fel.",
        content17: "2. Gyermekek is használhatják?",
        content18: "Ez a platform nem teszi lehetővé 18 éven aluli személyek számára a „Szolgáltatás” használatát.",
        content19: "3. Mire használhatók a cookie-k és egyéb azonosítók?",
        content20: "Cookie-kat és hasonló eszközöket használunk, hogy optimalizáljuk a felhasználói élményt, biztosítsuk szolgáltatásainkat, és megértsük, hogy az ügyfelek hogyan használják szolgáltatásainkat, így célzott fejlesztéseket hajthatunk végre. Cookie-kat használunk annak biztosítására, hogy rendszerünk felismerje böngészőjét vagy eszközét, és szolgáltatásokat nyújtson Önnek.",
        content21: 'Működési sütiket és hasonló eszközöket (együttesen "Cookie-k") használunk a szolgáltatások nyújtásához, mint pl：',
        content22: "Az Ön azonosítására, amikor bejelentkezik és használja szolgáltatásainkat.",
        content23: "Testreszabott funkciók és szolgáltatások biztosítása.",
        content24: "Véd a csaló tevékenységek ellen.",
        content25: "A biztonság javítása.",
        content26: "Kövesse nyomon beállításait (például pénznemet és nyelvet).",
        content27: "Sütiket is használunk annak megértésére, hogy a felhasználók hogyan használják szolgáltatásainkat, így célzott fejlesztéseket hajthatunk végre.",
        content28: "4. Megosztják a személyes adataimat?",
        content29: "A felhasználói adatok üzleti tevékenységünk fontos részét képezik, és nem adjuk el a felhasználók személyes adatait másoknak. Ez a platform csak az alábbi körülmények között osztja meg a felhasználók személyes adatait leányvállalataival vagy leányvállalataival. Ezeknek a leányvállalatoknak vagy leányvállalatoknak be kell tartaniuk ezt az adatvédelmi nyilatkozatot, vagy legalább olyan gyakorlatokat kell követniük, amelyek ugyanolyan védelmet nyújtanak, mint a jelen adatvédelmi nyilatkozatban leírt védelmi intézkedések.",
        content30: "Harmadik fél szolgáltatók: Más cégeket és magánszemélyeket alkalmazunk a nevünkben végzett feladatok ellátására. Ilyen funkciók például: adatok elemzése, marketing segítségnyújtás, fizetések feldolgozása, tartalom továbbítása, valamint a hitelkockázat felmérése és kezelése. Ezek a külső szolgáltatók hozzáférnek a feladataik ellátásához szükséges személyes adatokhoz, de azokat semmilyen más célra nem használhatják fel. Ezenkívül a személyes adatokat a jelen adatvédelmi nyilatkozatnak és a vonatkozó adatvédelmi törvényeknek megfelelően kell feldolgozniuk.",
        content31: "Üzleti átruházások: Vállalkozásunk tovább növekszik, eladhatunk vagy vásárolhatunk más vállalkozásokat vagy szolgáltatásokat. Az ilyen tranzakciók során a felhasználói információk általában az átadott üzleti eszközök közé tartoznak, de továbbra is vonatkoznak rájuk a korábban meglévő adatvédelmi nyilatkozatban foglalt ígéretek (kivéve természetesen, ha a felhasználó másként vállalja). Ezen túlmenően, ha cégünket vagy annak lényegében teljes vagyonát megszereznénk, a felhasználói adatok is átadásra kerülnek. Vállalatunk és mások védelme: Ha úgy gondoljuk, hogy a fiók és egyéb személyes adatok kiadása megfelelő a törvénynek vagy szabályozási kötelezettségeinknek való megfeleléshez, érvényesítjük vagy alkalmazzuk a felhasználási feltételeinket és egyéb megállapodásainkat, vagy a cégünk jogait, tulajdonát vagy biztonságát. felhasználók vagy mások Mikor adjuk ki a fiókot és egyéb személyes adatokat. Ez magában foglalja az információcserét más vállalatokkal és szervezetekkel a csalás elleni védelem és a hitelkockázat csökkentése érdekében.",
        content32: "5. Személyes adatok nemzetközi továbbítása",
        content33: 'Adatait az Európai Gazdasági Térségen ("EGT") kívülre továbbíthatjuk. Megfelelő biztosítékokat fogunk bevezetni annak biztosítására, hogy az ilyen továbbítások megfeleljenek a vonatkozó adatvédelmi szabályoknak, kivéve, ha az Európai Bizottság megerősítette, hogy az az ország, amelybe az adatokat továbbítják, megfelelő szintű védelmet biztosít.',
        content34: "6. Biztonságban vannak a személyes adataim?",
        content35: "Rendszereinket az Ön biztonságának és adatvédelmének szem előtt tartásával terveztük. Titkosító protokollokat és szoftvereket használunk, hogy megvédjük a személyes adatok biztonságát az átvitel során.",
        content36: "A személyes adatok gyűjtése, tárolása és közzététele során mindig fizikai, elektronikus és eljárási biztosítékokat alkalmazunk. Biztonsági eljárásaink azt jelentik, hogy igazolnunk kell az Ön személyazonosságát, mielőtt személyes adatokat közölnénk Önnel.",
        content37: "A legfontosabb, amit tehet, hogy megvédje személyes fiókja jelszavait az illetéktelen hozzáféréstől. Javasoljuk, hogy állítson be egyedi jelszót a fiókjához, amely különbözik a többi online fióktól. És mindenképpen jelentkezzen ki egy megosztott számítógép használata után.",
        content38: "7. Hogyan védjem meg a személyes adatokat?",
        content39: "Ha bármilyen kérdése vagy kifogása van a személyes adatok gyűjtésének és feldolgozásának módjával kapcsolatban, kérjük, forduljon az ügyfélszolgálat munkatársaihoz",
        content40: "Miután hozzájárult ahhoz, hogy személyes adatait meghatározott célból kezeljük, szándékát bármikor visszavonhatja, és az adatok e célból történő feldolgozását leállítjuk.",
        content41: "Ezen túlmenően Önnek jogában áll kérni a személyes adatokhoz való hozzáférést, azok helyesbítését és törlését, valamint kérheti az adatok hordozhatóságát, a vonatkozó jogszabályoknak megfelelően. Ezenkívül tiltakozhat személyes adatainak kezelése ellen, vagy kérheti, hogy bizonyos körülmények között korlátozzuk személyes adatainak feldolgozását.",
        content42: "8. EU GDPR és az Egyesült Királyság adatvédelmi törvénye – Jogalap",
        content43: "Az EU GDPR és az Egyesült Királyság adatvédelmi törvényei megkövetelik, hogy a személyes adatok felhasználása során betartsuk a jogalapot. Alapja attól függ, hogy a személyes adatokat milyen konkrét célokra használjuk fel. Ezek az alapok tartalmazzák：",
        content44: "Szerződés teljesítése – termékekkel vagy szolgáltatásokkal kapcsolatos vagy Önnel való kommunikáció, beleértve az Ön személyes adatainak rendelések elfogadására és feldolgozására, valamint fizetések feldolgozására történő felhasználását.",
        content45: "Jogos üzleti érdekeink és felhasználói érdekeink – Felismerjük és megelőzzük a csalásokat és visszaéléseket, hogy megvédjük felhasználóink, saját magunk vagy mások biztonságát.",
        content46: "Az Ön hozzájárulása – Az Ön hozzájárulása szükséges ahhoz, hogy személyes adatait az Önnel közölt konkrét célokra feldolgozzuk. Miután hozzájárulását adta személyes adatainak meghatározott célból történő feldolgozásához, bármikor visszavonhatja hozzájárulását, és leállítjuk az adatok e célból történő feldolgozását.",
        content47: "Törvényi kötelezettségek betartása – Személyes adatait a törvényi előírásoknak megfelelően használjuk fel. Például bankszámlaadatokat gyűjtünk személyazonosság-ellenőrzés céljából.",
        content48: "A fenti és egyéb jogalapok attól függnek, hogy milyen konkrét célokra használjuk fel a személyes adatokat.",
        content49: "9. Használati feltételek, megjegyzések és módosítások",
        content50: "Az Ön használatára és az adatvédelemmel kapcsolatos vitákra a jelen Nyilatkozat és a Felhasználási Feltételeink vonatkoznak. Ha bármilyen aggálya van a platform adatvédelmével kapcsolatban, kérjük, forduljon hozzánk részletes magyarázattal, és örömmel megoldjuk a problémát. Joga van továbbá felvenni a kapcsolatot a helyi adatvédelmi hatósággal.",
        content51: "Üzletünk folyamatosan változik, így az adatvédelmi nyilatkozatunk is. A legfrissebb változások megtekintéséhez érdemes gyakran felkeresni weboldalunkat. Ha nem ért egyet a módosított tartalommal, azonnal le kell állítania a hozzáférést. Az Adatvédelmi Szabályzat frissített verziójának megjelenése után az Ön folyamatos hozzáférése azt jelenti, hogy elfogadja a frissített tartalmat, és elfogadja a frissített adatvédelmi szabályzatot. Eltérő rendelkezés hiányában a jelenlegi Adatvédelmi nyilatkozat minden olyan információra vonatkozik, amelyet Önről és fiókjáról tárolunk.",
    },
    serviceAgreement: {
        title: "Szolgáltatási megállapodás",
        content1: 'Ez a weboldal kifejezetten a digitális eszközökkel kapcsolatos tranzakciók lebonyolítására és a kapcsolódó szolgáltatások nyújtására szolgáló platform a felhasználók számára (a továbbiakban: „szolgáltatás” vagy „szolgáltatás”). A megállapodás leírásának megkönnyítése érdekében ez a webhely együttesen használja a „mi” vagy más első személyű névmásokat ebben a megállapodásban. Mindaddig, amíg a weboldalra bejelentkező természetes személy vagy más alany ennek a weboldalnak a felhasználója, a jelen megállapodás kifejezésének megkönnyítése érdekében az alábbiakban „Ön” vagy más második személy szerepel. A jelen Megállapodás kifejezésének megkönnyítése érdekében Önt és minket a jelen Szerződésben együttesen "Feleknek", mi vagy Önt külön-külön "Félnek" nevezünk.',
        content2: "fontos tipp：",
        content3: "Itt szeretnénk emlékeztetni：",
        content4: "1 Magukat a digitális eszközöket nem bocsátja ki egyetlen pénzintézet vagy vállalat, illetve ez a weboldal sem",
        content5: "2 A digitális eszközök piacai újak, meg nem erősítettek, és előfordulhat, hogy nem növekednek;",
        content6: "3 A digitális eszközöket elsősorban a spekulánsok használják, viszonylag kis mértékben a kiskereskedelmi és kereskedelmi piacokon a döntéshozók és a globális kormányzati politikák；",
        content7: "4  A Vállalat fenntartja a jogot, hogy bármikor felfüggesztse vagy megszüntesse az Ön fiókját, ha a Vállalat saját belátása szerint megállapítja, hogy Ön megsértette a jelen Szerződést, vagy hogy a jelen webhely által nyújtott szolgáltatások vagy az általa nyújtott szolgáltatások használata az Ön joghatóságának törvényei szerint illegális, vagy felfüggeszti vagy megszünteti a jelen webhely által biztosított szolgáltatások vagy digitális eszközökkel kapcsolatos tranzakciókat. [Kína, Tajvan, Izrael, Irak, Banglades, Bolívia, Ecuador, Kirgizisztán, Szevasztopol és az Egyesült Királyság (kiskereskedelmi felhasználók)] bárkinek tilos a jelen webhely által biztosított szerződéses tranzakciós szolgáltatások igénybevétele. Az országok vagy régiók fent említett listája a különböző országok vagy régiók irányelveitől és terméktípusaitól függően változhat. Előfordulhat, hogy ebben az időpontban nem értesítjük Önt, ezért kérjük, hogy időben figyeljen a megállapodás frissítéseire.",
        content8: "A digitális eszközök kereskedelme rendkívül magas kockázattal jár, és a legtöbb ember számára nem megfelelő. Ön megérti és megérti, hogy ez a tranzakció részleges vagy teljes veszteséget eredményezhet, ezért a tranzakció összegét a megengedhető veszteség mértéke alapján kell meghatároznia. Tudja és érti, hogy a digitális eszközök származékos kockázatokat generálnak, ezért ha kérdése van, először tanácsos szakember segítségét kérni. Emellett a fent említett kockázatokon túl vannak olyan kockázatok is, amelyeket nem lehet előre jelezni. Mielőtt bármilyen döntést hozna a digitális eszközök vásárlásáról vagy eladásáról, alaposan mérlegelje és mérlegelje pénzügyi helyzetét és a fent említett kockázatokat, és az ebből eredő veszteségeket nem vállalja.",
        content9: "Figyelmeztetés neked：",
        content10: "1 Ön tudomásul veszi, hogy ezt a webhelyet kizárólag a digitális eszközökkel kapcsolatos információk megszerzésére, a tranzakciós felek felkutatására, a digitális eszközökkel kapcsolatos tranzakciók tárgyalására és lebonyolítására használjuk meghatározza a vonatkozó digitális eszközöket és/vagy az információk valódiságát, jogszerűségét és érvényességét, és az ebből eredő felelősséget és veszteségeket egyedül viseli.",
        content11: "2 A weboldalon található vélemények, hírek, viták, elemzések, árak, ajánlások és egyéb információk általános piaci megjegyzések, és nem minősülnek befektetési tanácsnak. Nem vállalunk felelősséget az ezen információkra való hagyatkozásból közvetlenül vagy közvetve felmerülő veszteségekért, ideértve, de nem kizárólagosan az elmaradt haszonért.",
        content12: "3 Ez a weboldal fenntartja magának a jogot, hogy bármikor saját döntést hozzon, módosítsa vagy módosítsa a tartalmat. Megtettük az ésszerű intézkedéseket a weboldalon található információk pontosságának biztosítása érdekében, de nem tudjuk garantálni azok pontosságát, és nem vállalunk felelősséget a weboldalon található információk pontosságáért. minden olyan veszteség vagy kár, amelyet a jelen weboldalon található információk, vagy az információhiány okoz, az internetre való kapcsolódás, az értesítések és információk továbbításának vagy fogadásának késéséből vagy hibájából.",
        content13: "4 Az internet alapú kereskedési rendszerek használatának kockázatai is vannak, beleértve, de nem kizárólagosan, szoftver-, hardver- és internetkapcsolati hibákat. Mivel nincs befolyásunk az internet megbízhatóságára és elérhetőségére, nem vállalunk felelősséget a torzulásokért, késésekért és csatlakozási hibákért.",
        content14: "5 Tilos ezt a weboldalt használni minden illegális kereskedelmi tevékenység vagy illegális tevékenység, például pénzmosás, csempészet, kereskedelmi vesztegetés stb. folytatására. Ha bármilyen feltételezett illegális tranzakciót vagy illegális tevékenységet találnak, ez a webhely különféle eszközöket fog igénybe venni, beleértve, de nem korlátozódik a számlák befagyasztására és az érintett felek értesítésére stb., nem vállalunk minden ebből eredő felelősséget, és fenntartjuk a jogot, hogy felelősséget vállaljunk az érintett személyektől.",
        content15: "6 Tilos ezt a weboldalt felhasználni a piac rosszindulatú manipulálására, helytelen tranzakciókra és egyéb etikátlan kereskedési tevékenységekre. Ha ilyen incidenseket fedeznek fel, ez a webhely figyelmeztet, korlátozza a tranzakciókat, és lezár minden olyan etikátlan magatartást, mint például a rosszindulatú ármanipuláció és rosszindulatú befolyásolás. kereskedési rendszerben nem vállalunk minden olyan felelősséget, amely a megelőző védelmi intézkedésekből, például a számlafelfüggesztésből ered, és fenntartjuk a jogot, hogy felelősséget érvényesítsünk az érintett személyekkel szemben.",
        content16: "1. Általános elvek",
        content17: '1.1 A "Felhasználói Szerződés" (a továbbiakban: jelen Szerződés vagy jelen Feltételek) a fő szövegből áll: "Adatvédelmi feltételek", "Ismerje meg ügyfelét és a pénzmosás elleni szabályzatot" és számos egyéb irányelvet, amelyek közzétették vagy a jövőben közzétehetik ezen a weboldalon az osztályszabályokat, nyilatkozatokat, leírásokat stb.',
        content18: "1.2 Kérjük, figyelmesen olvassa el ezt a szerződést, mielőtt igénybe venné a jelen weboldal által nyújtott szolgáltatásokat. Ha valamit nem ért, vagy ha egyébként szükséges, kérjük, forduljon szakképzett jogászhoz. Ha Ön bármikor nem ért egyet a jelen Szerződéssel és/vagy annak módosításával, kérjük, haladéktalanul hagyja abba a webhely által nyújtott szolgáltatások használatát, vagy ne jelentkezzen be a továbbiakban erre a webhelyre. Ha bejelentkezik erre a weboldalra, használja a webhely bármely szolgáltatását, vagy bármilyen más hasonló viselkedést, ez azt jelenti, hogy megértette és teljes mértékben elfogadta a jelen Szerződés tartalmát, beleértve a jelen Megállapodás ezen webhely által bármikor végrehajtott módosításait.",
        content19: '1.3 Ennek a weboldalnak a tagjává (továbbiakban: tag) a jelen weboldal követelményeinek megfelelő kitöltésével és az egyéb vonatkozó eljárások után sikeres regisztrációval válhat a regisztráció során az "Elfogadom" gombra kattintva folyamatban, elektronikusan aláírja a megállapodást a céggel, vagy amikor a weboldal használata során bármely „Elfogadom” feliratú vagy hasonló jelzésű gombra kattint, vagy a webhely által nyújtott szolgáltatásokat a jelen weboldal által engedélyezett egyéb módon használja. , ez azt jelenti, hogy teljes mértékben megértette, elfogadja és elfogadja a jelen Megállapodásban foglalt valamennyi feltételt. Az Ön saját kezű aláírásának hiánya nem érinti a jelen Szerződés Önre nézve kötelező érvényét.',
        content20: "1.4 Miután ennek a weboldalnak a tagja lett, kap egy tagsági fiókot és a hozzá tartozó jelszót, amelyeket Ön őriz meg minden tevékenységért és eseményért.",
        content21: "1.5 Csak azok a tagok, akik ennek a weboldalnak a tagjai, használhatják a jelen weboldal által biztosított digitális eszköz kereskedési platformot tranzakciókhoz, és élvezhetik az ezen a weboldalon meghatározott egyéb olyan szolgáltatásokat, amelyek csak nem tagok számára érhetők el, beléphetnek a weboldalra, böngészhetik a weboldalt és az ezen a webhelyen meghatározott egyéb szolgáltatások.",
        content22: "1.6 Azzal, hogy regisztrál, és használja a webhely által biztosított bármely szolgáltatást és funkciót, úgy tekintjük, hogy elolvasta, megértette és:",
        content23: "1.6.1 Fogadja el, hogy magára nézve kötelezőnek ismeri el a jelen Szerződés összes feltételét.",
        content24: "1.6.2 Ön megerősíti, hogy elmúlt 18. életévét betöltötte, vagy megvan a megfelelő életkora a szerződések megkötéséhez a különböző hatályos jogszabályok szerint. Az ezen a weboldalon történő regisztráció, az eladások vagy vásárlások, az információk közzététele, stb. az Ön követelményeinek megfelelően rendelkezik egy szuverén ország vagy régió vonatkozó törvényei és rendelkezései felett, és teljes mértékben képes elfogadni ezeket a feltételeket, tranzakciókat kötni, és ezt a webhelyet digitális eszközökkel kapcsolatos tranzakciók lebonyolítására használni.",
        content25: "1.6.3 Ön garantálja, hogy a tranzakcióban részt vevő összes digitális eszköz jogszerűen megszerzett és tulajdonjoggal rendelkezik.",
        content26: "1.6.4 Ön elfogadja, hogy kizárólag Ön felelős saját kereskedési vagy nem kereskedési tevékenységéért, valamint bármely nyereségért vagy veszteségért.",
        content27: "1.6.5 Ön megerősíti, hogy a regisztráció során megadott adatok valósak és pontosak.",
        content28: "1.6.6 Ön beleegyezik, hogy betartja a vonatkozó jogszabályok előírásait, beleértve a kereskedési nyereség adózási célú jelentését.",
        content29: "1.6.7 Ön beleegyezik abba, hogy soha nem vesz részt olyan magatartásban vagy tevékenységben, amely sérti a jelen weboldal vagy a vállalat érdekeit, függetlenül attól, hogy a webhely által nyújtott szolgáltatásokhoz kapcsolódnak-e vagy sem.",
        content30: "1.6.8 Ez a megállapodás csak az Ön és köztünk fennálló jogokat és kötelezettségeket szabályozza, és nem érinti a jelen weboldal és más webhelyek felhasználói és Ön között a digitális eszközökkel folytatott tranzakciókból eredő jogviszonyokat és jogi vitákat.",
        content31: "2. Megállapodás módosítások",
        content32: "Fenntartjuk magunknak a jogot, hogy a jelen Szerződést időről időre felülvizsgáljuk, és külön értesítés nélkül közöljük a weboldalon. a weboldalon való közzétételt követően lép hatályba. Időről időre böngészni kell, és figyelnie kell a jelen Szerződés frissítési idejét és frissített tartalmát weboldal azt jelenti, hogy elfogadja és magára nézve kötelezőnek ismeri el a felülvizsgált szerződést.",
        content33: "3. Regisztráció",
        content34: "3.1 Regisztrációs képesítések",
        content35: "Ön megerősíti és megígéri, hogy amikor befejezi a regisztrációs folyamatot, vagy ténylegesen használja a jelen webhely által nyújtott szolgáltatásokat a jelen webhely által engedélyezett egyéb módon, Önnek lehetősége van arra, hogy aláírja ezt a megállapodást és használja a weboldal szolgáltatásait a vonatkozó jogszabályoknak megfelelően. természetes személy, jogi személy vagy egyéb szervezet. Ha rákattint a Regisztráció elfogadása gombra, az azt jelenti, hogy Ön vagy meghatalmazott képviselője beleegyezett a szerződés tartalmába, és ügynöke regisztrálta, és igénybe vette a webhely szolgáltatásait. Ha Ön nem rendelkezik a fent említett tantárgyi képesítéssel, Ön és meghatalmazottja viseli az ebből eredő minden következményt, és a társaság fenntartja magának a jogot, hogy törölje vagy véglegesen zárolja a fiókját, valamint felelősségre vonja Önt és meghatalmazottját.",
        content36: "3.2 Regisztráció célja",
        content37: "Ön megerősíti és megígéri, hogy ezen a weboldalon történő regisztrációjának célja nem a törvények és előírások megsértése vagy a digitális eszközökkel kapcsolatos tranzakciók sorrendjének megzavarása ezen a weboldalon.",
        content38: "3.3 regisztrációs folyamat",
        content39: "3.3.1 Ön beleegyezik abba, hogy érvényes e-mail címet, mobiltelefonszámot és egyéb szükséges információkat adjon meg a jelen weboldal felhasználói regisztrációs oldalán. Belépésként használhatja az Ön által megadott vagy megerősített e-mail címet, mobiltelefonszámot vagy egyéb, a jelen weboldal által engedélyezett módokat módja annak, hogy belépjen erre a webhelyre. Szükség esetén, a különböző joghatóságok vonatkozó törvényeinek és rendelkezéseinek megfelelően, meg kell adnia valódi nevét, személyazonosító okmányát és egyéb, a törvényekben és szabályozásokban, az adatvédelmi záradékokban és a pénzmosás elleni záradékokban előírt releváns információkat, valamint folyamatosan frissítenie kell a regisztrációs adatokat a időszerű, részletes és pontos módon. Minden eredetileg beírt információ regisztrációs információként fog hivatkozni. Ön felelős az ilyen információk hitelességéért, teljességéért és pontosságáért, és viseli az ebből eredő közvetlen vagy közvetett veszteségeket és hátrányos következményeket.",
        content40: "3.3.2 Ha az Ön tartózkodási helye szerinti szuverén ország vagy régió törvényei, rendeletei, szabályai, rendeletei és egyéb előírásai valódi névre vonatkozó követelményeket írnak elő a mobiltelefonszámokra, Ön elfogadja, hogy a megadott regisztrált mobiltelefonszámot valós néven regisztrálták nem az előírásoknak megfelelően biztosítja, az Önnek okozott bármely következmény Közvetlen vagy közvetett veszteség és hátrányos következmény Önt terheli.",
        content41: "3.3.3 Ha Ön jogszerűen, maradéktalanul és hatékonyan megadja a regisztrációhoz szükséges adatokat, és azokat igazolta, jogosult a weboldal számlaszámának és jelszavának beszerzésére sikeres, és ezen a weboldalon tagként bejelentkezhet.",
        content42: "3.3.4 Ön beleegyezik abba, hogy a weboldal kezelésével és üzemeltetésével kapcsolatos e-maileket és/vagy rövid üzeneteket kapjon.",
        content43: "4. Szolgáltatás",
        content44: "Ez a webhely csak online kereskedési platform szolgáltatásokat nyújt az Ön digitális eszköz kereskedési tevékenységéhez (ideértve, de nem kizárólagosan a digitális eszköz kereskedési és egyéb szolgáltatásokat) ezen a weboldalon keresztül.",
        content45: "4.1 Szolgáltatástartalom",
        content46: "4.1.1 Önnek jogában áll böngészni ezen a weboldalon a különböző digitális eszköztermékek valós idejű piaci feltételeit és tranzakciós információit, valamint jogosult digitális eszköz tranzakciós utasításokat benyújtani és digitális eszköz tranzakciókat teljesíteni ezen a weboldalon keresztül.",
        content47: "4.1.2 Önnek jogában áll megtekinteni ezen a weboldalon a tagsági fiókja alatt található információkat, és a jelen weboldal által biztosított funkciókat alkalmazni a működéshez.",
        content48: "4.1.3 Önnek joga van részt venni a jelen weboldal által szervezett weboldali tevékenységekben az ezen a weboldalon közzétett tevékenységi szabályzat szerint.",
        content49: "4.1.4 Ez a webhely azt ígéri, hogy más szolgáltatásokat is nyújt Önnek.",
        content50: "4.2 Szolgáltatási szabályok Ön vállalja, hogy betartja a jelen weboldal alábbi szolgáltatási szabályzatát：",
        content51: "4.2.1 Önnek be kell tartania a törvényeket, előírásokat és irányelveket, biztosítania kell a fiókjában található összes digitális eszköz forrásának jogszerűségét, és nem folytathat illegális vagy egyéb olyan tevékenységet, amely sérti a jelen webhely vagy harmadik felek jogait és érdekeit ezen a webhelyen. vagy szolgáltatásainak használatával, beleértve, de nem kizárólagosan: Ön nem korlátozódik illegális, illegális vagy mások jogait sértő információ küldésére vagy fogadására, piramisjátékok vagy egyéb káros információk vagy megjegyzések küldésére vagy fogadására, e-mailek használatára vagy hamisítására. fejléc információk ezen a webhelyen a webhely engedélye nélkül stb.",
        content52: "4.2.2 Tartsa be a törvényeket és előírásokat, és rendeltetésszerűen használja és őrizze meg számlaszámát, bejelentkezési jelszavát, pénztári jelszavát, a regisztráció során Önhöz kötött mobiltelefonszámát, valamint a mobiltelefonjára kapott mobiltelefon-ellenőrző kódot. Ön teljes mértékben felelős a fiókja és a bejelentkezési jelszava, az alapjelszó és a mobiltelefon-ellenőrző kód használatával járó műveletekért és következményekért. Ha úgy találja, hogy ennek a webhelynek a számlaszámát, bejelentkezési jelszavát vagy az alap jelszavát vagy ellenőrző kódját harmadik fél az Ön engedélye nélkül használja, vagy más fiókbiztonsági problémák merülnek fel, azonnal és hatékonyan értesítenie kell ezt a webhelyet, és kérnie kell a webhely felfüggesztését. a webhely fiókjának szolgáltatása. Ennek a weboldalnak jogában áll ésszerű időn belül intézkedni az Ön kérelméről, de ez a weboldal nem vállal felelősséget az intézkedések megtétele előtt bekövetkezett következményekért (ideértve, de nem kizárólagosan az Önt elszenvedett veszteségeket). Nem adományozhatja, kölcsönözheti, lízingelheti, nem ruházhatja át vagy más módon nem ruházhatja át ezen a webhelyen található fiókját másoknak a webhely hozzájárulása nélkül.",
        content53: "4.2.3 Ön vállalja, hogy felelős minden olyan tevékenységért, amely fiókjával és jelszavával ezen a webhelyen történik (beleértve, de nem kizárólagosan az információk nyilvánosságra hozatalát, az információk közzétételét, a különféle szabályok és megállapodások elfogadására vagy benyújtására irányuló online kattintásokat, a szerződések online megújítását vagy a szolgáltatások stb.).",
        content54: "4.2.4 Amikor ezen a weboldalon digitális eszközökkel tranzakciókat hajt végre, nem zavarhatja meg rosszindulatúan a digitális eszközökkel kapcsolatos tranzakciók normál folyamatát, nem zavarhatja meg a tranzakciók sorrendjét, nem zavarhatja a webhely normál működését, és nem zavarhatja meg a webhely más felhasználók általi használatát szolgáltatásokat semmilyen technikai vagy egyéb eszközzel nem használhat fel fiktív, rosszindulatú rágalmazza a jelen weboldal jóindulatát tényeken és egyéb módon.",
        content55: "4.2.5 Ha online tranzakciók miatt vitája van más felhasználókkal, nem kérheti ezt a webhelyet, hogy nem bírósági vagy adminisztratív csatornákon keresztül nyújtson releváns információkat.",
        content56: "4.2.6 Kizárólag Ön ítéli meg és viseli a fizetendő adókat, valamint az összes hardver-, szoftver-, szolgáltatás- és egyéb költséget, amely a webhely által nyújtott szolgáltatások használata során merül fel.",
        content57: "4.2.7 Önnek be kell tartania a jelen szerződést, valamint a jelen weboldal által időről időre közzétett és frissített egyéb szolgáltatási feltételeket és működési szabályokat, valamint joga van a jelen weboldal által nyújtott szolgáltatások használatát bármikor megszakítani.",
        content58: "4.3 termékszabályok",
        content59: "4.3.1 Böngésszen a tranzakciós információk között",
        content60: "Amikor ezen a weboldalon böngészi a tranzakciós információkat, figyelmesen olvassa el a tranzakciós információkban szereplő összes tartalmat, beleértve, de nem kizárólagosan az árat, a jutalék mennyiségét, a kezelési díjat, a vételi vagy eladási irányt, és csak azután, hogy teljes mértékben elfogadta a a tranzakciós információk Az ügyleteket egy gombnyomással lehet lebonyolítani.",
        content61: "4.3.2 A tranzakció részleteinek megtekintése",
        content62: "Fiókján keresztül megtekintheti a megfelelő tranzakciós nyilvántartásokat.",
        content63: "5. A jelen weboldal jogai és kötelezettségei",
        content64: "5.1 Ha nem rendelkezik a jelen szerződésben előírt regisztrációs képesítéssel, ennek a weboldalnak joga van megtagadni a regisztrációját a meghatalmazott ügynöke elszámoltatható. Ugyanakkor ez a weboldal fenntartja a jogot annak eldöntésére, hogy bármilyen más körülmények között elfogadja-e regisztrációját.",
        content65: "5.2 A jelen webhely saját megítélése alapján, ha a webhely úgy találja, hogy Ön vagy társított fiókfelhasználói nem alkalmasak magas kockázatú befektetésekre, jogunk van felfüggeszteni vagy megszüntetni az Ön fiókját és az összes kapcsolódó fiók használatát.",
        content66: "5.3 Ha ez a webhely felfedezi, hogy a fiók felhasználója nem a fiók eredeti regisztrálója, jogában áll felfüggeszteni vagy megszüntetni a fiók használatát.",
        content67: "5.4 Ha ez a webhely megalapozottan gyanítja, hogy az Ön által megadott információk tévesek, valótlanok, érvénytelenek vagy hiányosak technikai tesztelés, kézi mintavétel és egyéb tesztelési módszerek révén, jogosult arra, hogy értesítse Önt az információ javításáról vagy frissítéséről, illetve az adatszolgáltatás felfüggesztéséről vagy megszüntetéséről. ez a weboldal szolgáltatást nyújt Önnek.",
        content68: "5.5 Ez a weboldal fenntartja a jogot, hogy javítsa az ezen a weboldalon megjelenő információkat, ha nyilvánvaló hibát észlel.",
        content69: "5.6 Ez a weboldal fenntartja magának a jogot, hogy bármikor módosítsa, felfüggesztse vagy megszüntesse a szolgáltatást honlapon, a felmondás ezen a weboldalon történik.",
        content70: "5.7 Ez a weboldal megteszi a szükséges technikai eszközöket és kezelési intézkedéseket, hogy biztosítsa a weboldal normál működését, biztosítsa a szükséges és megbízható kereskedési környezetet és kereskedési szolgáltatásokat, valamint fenntartsa a digitális eszközökkel kapcsolatos tranzakciók sorrendjét.",
        content71: "5.8 Ha egy egymást követő évben nem jelentkezik be erre a webhelyre tagsági fiókjával és jelszavával, akkor ennek a webhelynek jogában áll törölni a fiókját. A fiók törlése után ennek a weboldalnak jogában áll a megfelelő tagnevet megnyitni más felhasználók számára regisztráció és használat céljából.",
        content72: "5.9 Ez a webhely a technológiai befektetések megerősítésével, a biztonsági óvintézkedések és egyéb intézkedések javításával biztosítja digitális eszközeinek biztonságát, és előre értesíti Önt, ha előrelátható biztonsági kockázatok lépnek fel fiókjában.",
        content73: "5.10 Ez a weboldal bármikor jogosult minden olyan tartalmi információt törölni a weboldalról, amely nem felel meg a törvényeknek és előírásoknak, illetve a jelen weboldal előírásainak.",
        content74: "5.11 Ez a webhely jogosult arra, hogy további információkat vagy anyagokat kérjen Öntől az Ön szuverén országa vagy régiója törvényeivel, rendeleteivel, szabályaival, utasításaival és egyéb szabályozási követelményeivel összhangban, és tegye meg ésszerű intézkedéseket a helyi szabályozási követelményeknek való megfelelés érdekében kötelesek együttműködni, ennek a weboldalnak jogában áll felfüggeszteni vagy véglegesen leállítani a webhely egyes vagy összes szolgáltatását az Ön szuverén országának vagy régiójának törvényei, rendeletei, szabályai, rendelkezései és egyéb előírásai szerint.",
        content75: "5.12 Ez a weboldal fenntartja magának a jogot, hogy saját belátása szerint hét munkanapos felmondási idővel bezárja fiókját. Ezért hét munkanapja van a rendelések törlésére és a pozíciók bezárására. Ha nem törölte a rendelést és nem zárta le a pozíciót a határidő lejártakor, akkor a megbízást erőszakosan töröljük és a pozíciót lezárjuk, a fiókjában fennmaradó digitális eszközöket pedig visszaküldjük Önnek.",
        content76: "5.13 A kereskedők jogainak és érdekeinek védelme érdekében ez a weboldal jogosult speciális körülmények (például rendszerhiba, hálózati hiba, szélsőséges piaci körülmények stb.) esetén módosítani az online termékeket, mint például: korai szállításra vonatkozó szerződések, ill. elszámolás, szerződéstípusok és korai szállításra és elszámolásra vonatkozó szerződések Az időszak és az elszámolás és a szállítási ár a jelen weboldalon történő közzététel tárgyát képezi.",
        content77: "6. Kártérítés",
        content78: "6.1 Az Ön felé fennálló közvetlen károkért való felelősségünk semmilyen esetben sem haladja meg a teljes szolgáltatási díjat, amelyet három (3) hónapos időtartamra felszámítottunk a webhely használatáért.",
        content79: "6.2 Ha Ön megsérti ezt a Szerződést vagy más törvényeket és rendelkezéseket, Önnek legalább 2 millió USD-t kell megtérítenie, és viselnie kell az összes felmerülő költséget (beleértve az ügyvédi díjakat stb.), ha ez nem elegendő a tényleges veszteségek megtérítéséhez készen áll rá.",
        content80: "7. Az eltiltó jogorvoslat kérésének joga",
        content81: "Mi és Ön is elismeri, hogy az Ön szerződésszegése vagy esetleges szerződésszegése esetén a köztörvényes jogorvoslati lehetőségek nem biztos, hogy elegendőek az általunk elszenvedett veszteségek kompenzálására, ezért jogunk van arra, hogy eltiltási jogorvoslatot kérjünk, ahogy azt a common law vagy a méltányosság lehetővé teszi a szerződésszegése vagy esetleges szerződésszegése esetén.",
        content82: "8. A felelősség korlátozása és felelősség kizárása",
        content83: "8.1 Ön megérti és elfogadja, hogy semmilyen körülmények között nem vállalunk felelősséget az alábbi ügyekért：",
        content84: "8.1.1 bevételkiesés；",
        content85: "8.1.2 Kereskedési nyereség vagy szerződéses veszteség；",
        content86: "8.1.3 Üzleti megszakításból eredő veszteségek；",
        content87: "8.1.4 A várható monetáris megtakarítások elvesztése；",
        content88: "8.1.5 Információs problémák okozta veszteségek；",
        content89: "8.1.6 Lehetőség, jóindulat vagy jó hírnév elvesztése；",
        content90: "8.1.7 Adatok sérülése vagy elvesztése；",
        content91: "8.1.8 Helyettesítő termékek vagy szolgáltatások vásárlásának költsége；",
        content92: "8.1.9 Bármilyen közvetett, különleges vagy véletlen veszteség vagy kár, amely jogellenes károkozásból (beleértve a gondatlanságot), szerződésszegésből vagy bármely más okból ered, függetlenül attól, hogy az ilyen veszteség vagy kár ésszerűen előrelátható-e vagy sem; előre Veszteség vagy károsodás lehetősége.",
        content93: "8.1.1 A 8.1.9 cikkelyei függetlenek egymástól.",
        content94: "8.2 Megérti és elfogadja, hogy nem vállalunk felelősséget az alábbi körülmények bármelyike ​​miatt Önnek okozott károkért:：",
        content95: "8.2.1 Az Ön konkrét tranzakciói súlyos törvénysértéseket vagy szerződésszegéseket tartalmazhatnak.",
        content96: "8.2.2 Az Ön viselkedése ezen a webhelyen lehet jogellenes vagy etikátlan.",
        content97: "8.2.3 Bármilyen adat, információ vásárlásából vagy megszerzéséből, illetve a jelen weboldal szolgáltatásain keresztül történő tranzakciókból vagy helyettesítő intézkedésekből eredő költségek és veszteségek.",
        content98: "8.2.4 Az Ön félreértése a webhely szolgáltatásaival kapcsolatban。",
        content99: "8.2.5 A weboldal által nyújtott szolgáltatásokkal kapcsolatos minden egyéb veszteség, amelyet nem mi okoztunk.",
        content100: "8.3 Felelősek vagyunk információs hálózati berendezések karbantartásáért, információs hálózati kapcsolat meghibásodásáért, számítógép-, kommunikációs vagy egyéb rendszerhibáért, áramkimaradásért, időjárási körülményekért, balesetekért, sztrájkokért, munkaügyi vitákért, zavargásokért, felkelésekért, zavargásokért, elégtelen termelékenységért vagy termelési anyagokért, tűzesetért, árvízért , viharok, robbanások, háborúk, banki vagy egyéb partner okok, digitális eszközök piacának összeomlása, kormányzati lépések, igazságügyi vagy közigazgatási szervek utasításai, egyéb olyan cselekmények, amelyeket nem tudunk ellenőrizni, vagy amelyeket nem tudunk ellenőrizni, vagy harmadik féltől származó okok nem vállal semmilyen felelősséget a szolgáltatás képtelenségéért vagy késedelméért, illetve az Ön veszteségeiért.",
        content101: "8.4 Nem tudjuk garantálni, hogy az ezen a weboldalon található összes információ, program, szöveg stb. teljesen biztonságos, és semmilyen vírus, trójai faló és egyéb rosszindulatú program nem zavarja meg és nem semmisíti meg Ez a weboldal, vagy letölthető és felhasználható programok, információk, adatok stb. mind az Ön döntése, és a kockázatokat és az esetleges veszteségeket saját felelősségére viseli.",
        content102: "8.5 Nem vállalunk semmilyen garanciát vagy kötelezettséget a jelen weboldalhoz kapcsolódó harmadik felek webhelyeinek információira, termékeire és szolgáltatásaira, vagy bármely más olyan tartalomra, amely nem tartozik hozzánk -a párt weboldalai, Termékei stb. mind az Ön döntései, és az ebből eredő minden felelősség Önt terheli.",
        content103: "8.6 Nem vállalunk semmilyen kifejezett vagy hallgatólagos garanciát az ezen a weboldalon található szolgáltatások Ön általi használatáért, beleértve, de nem kizárólagosan az ezen a weboldalon nyújtott szolgáltatások alkalmasságát, a hibák vagy hiányosságok hiányát, a folytonosságot, a pontosságot, a megbízhatóságot és az adott célnak való megfelelőséget. célja. Ugyanakkor nem vállalunk kötelezettséget vagy garanciát a jelen weboldal által nyújtott szolgáltatásokhoz kapcsolódó technológia és információk érvényességére, pontosságára, helyességére, megbízhatóságára, minőségére, stabilitására, teljességére és időszerűségére vonatkozóan. Az Ön személyes döntése, hogy bejelentkezik-e vagy igénybe veszi-e a weboldal által nyújtott szolgáltatásokat, és Ön viseli saját kockázatait és esetleges veszteségeit. Nem vállalunk semmilyen kifejezett vagy hallgatólagos garanciát a digitális eszközök piacára, értékére és árára vonatkozóan a személyes szabadságot a saját felelősségére és az esetleges veszteségekre választja.",
        content104: "8.7 A jelen Megállapodásban meghatározott garanciáink és kötelezettségeink az egyedüli garanciáink és nyilatkozataink, amelyeket a jelen Szerződéssel és az ezen a weboldalon nyújtott szolgáltatásokkal kapcsolatban vállalunk, és felülírnak minden más módon és módon felmerülő garanciát és kötelezettségvállalást, akár írásban, akár szóban, kifejezett. vagy hallgatólagos. Mindezek a garanciák és nyilatkozatok csak saját kötelezettségeinket és garanciáinkat jelentik, és nem garantálják, hogy bármely harmadik fél betartja a jelen Szerződésben foglalt garanciákat és kötelezettségvállalásokat.",
        content105: "8.8 Nem mondunk le semmilyen, a jelen Megállapodásban nem említett jogunkról kártérítési felelősségünk korlátozására, kizárására vagy ellentételezésére a vonatkozó jogszabályok legteljesebb mértékben.",
        content106: "8.9 A regisztrációt követően Ön beleegyezik az általunk a jelen szerződésben rögzített szabályok szerint végzett műveletekbe, és a felmerülő kockázatok Önt terhelik.",
        content107: "9. A Szerződés megszűnése",
        content108: "9.1 Ennek a weboldalnak jogában áll felmondani a jelen szerződéssel összhangban jelen weboldal összes szolgáltatását.",
        content109: "9.2 A jelen Szerződés megszűnése után Önnek nincs joga megkövetelni a webhelytől, hogy továbbra is nyújtson szolgáltatásokat, vagy teljesítsen más kötelezettségeket, beleértve, de nem kizárólagosan, megkövetelheti, hogy ez a webhely tartsa meg vagy közöljön bármilyen információt az eredeti webhelyfiókjában. vagy harmadik fél továbbít minden olyan információt, amelyet nem olvasott vagy küldött.",
        content110: "9.3 A jelen szerződés felmondása nem érinti a nem szerződésszegő fél egyéb kötelezettségeit a szerződésszegő féllel szemben.",
        content111: "10. Szellemi tulajdonjogok",
        content112: "10.1 Az ezen a webhelyen található összes szellemi teljesítmény magában foglalja, de nem kizárólagosan a webhelylogókat, adatbázisokat, webhelyterveket, szövegeket és grafikákat, szoftvereket, fényképeket, videókat, zenéket, hangokat és ezek kombinációit, szoftverösszeállításokat, kapcsolódó forráskódokat és szoftvereket (beleértve a kis alkalmazásokat is). ) A programok és szkriptek szellemi tulajdonjogai ehhez a weboldalhoz tartoznak. A fenti anyagokat vagy tartalmakat kereskedelmi célokra nem reprodukálhatja, módosíthatja, másolhatja, küldheti el vagy használhatja fel.",
        content113: "10.2 A jelen weboldal nevében szereplő minden jog (beleértve, de nem kizárólagosan a goodwillt és a védjegyeket, logókat) a vállalatot illeti meg.",
        content114: "10.3 A jelen megállapodás elfogadása úgy tekintendő, mint az Ön kezdeményezése az Ön által ezen a weboldalon közzétett információk bármely formájának szerzői jogának átruházására, beleértve, de nem kizárólagosan a következőket: sokszorosítási jogok, terjesztési jogok, bérleti jogok, kiállítási jogok, előadási jogok, vetítési jogok, közvetítési jogok, információs internetes közvetítési jogok, filmezési jogok, adaptációs jogok, fordítási jogok, összeállítási jogok és egyéb átruházható jogok, amelyeket a szerzői jog tulajdonosának meg kell élveznie, kizárólag ingyenesen átruházzák erre a weboldalra jogsértés miatt pert indíthat bármely alany ellen, és teljes kártérítést kaphat. Ez a megállapodás minden olyan szerzői jogi törvény által védett tartalomra érvényes, amelyet Ön ezen a weboldalon tesz közzé, függetlenül attól, hogy a tartalom a jelen szerződés aláírása előtt vagy a szerződés aláírása után jött létre.",
        content115: "10.4 Ön nem használhatja illegálisan a jelen weboldal vagy mások szellemi tulajdonjogait, és nem rendelkezhet azokkal a webhely szolgáltatásainak igénybevételekor. Nem tehet közzé és nem engedélyezhet más webhelyeket (és médiát) az ezen a weboldalon közzétett információk bármilyen formában történő felhasználására.",
        content116: "11. Információvédelem",
        content117: "A jelen weboldalon külön közzétett „Adatvédelmi feltételek” rendelkezései az irányadóak.",
        content118: "12. Számítás",
        content119: "Minden tranzakciószámítási eredményt ellenőriztünk, és minden számítási módszert közzétettünk a weboldalon, de nem tudjuk garantálni, hogy a weboldal használata nem lesz megszakadva vagy hibamentes.",
        content120: "13. Exportellenőrzés",
        content121: "Ön tudomásul veszi és tudomásul veszi, hogy a vonatkozó törvények értelmében nem exportálhat, reexportálhat, importálhat vagy továbbíthat semmilyen anyagot (beleértve a szoftvert is) ezen a webhelyen, így garantálja, hogy nem fog aktívan megvalósítani, segíteni vagy részt venni a fent említett exportot vagy kapcsolódó tevékenységeket, amelyek sértik a törvényeket és előírásokat, vagy ha ilyen helyzetet fedeznek fel, akkor azt kellő időben jelentenie kell nekünk, és segítenie kell annak kezelésében.",
        content122: "14. Átadás",
        content123: "A jelen Szerződésben meghatározott jogok és kötelezettségek kötik a felek engedményeseit, örököseit, végrehajtóit és ügyintézőit is, akiket az ilyen jogok és kötelezettségek élveznek. Ön beleegyezésünk nélkül nem ruházhatja át harmadik félre, de a jelen Szerződésben foglalt jogainkat és kötelezettségeinket bármikor átruházhatjuk harmadik félre, és erről értesítjük.",
        content124: "15. Oszthatóság",
        content125: "Ha a jelen Megállapodás bármely rendelkezését végrehajthatatlannak, érvénytelennek vagy törvénytelennek ítéli bármely illetékes bíróság, az nem érinti a jelen Szerződés többi rendelkezésének érvényességét.",
        content126: "16. Nem ügynöki kapcsolat",
        content127: "A jelen Megállapodás egyetlen rendelkezése sem tekinthetõ úgy, hogy az Ön ügynökeként, megbízottjaként vagy egyéb képviselõjeként hozzon létre, sugalljon vagy más módon tekintsen bennünket, kivéve, ha a jelen Megállapodás másként rendelkezik.",
        content128: "17. Tartózkodás",
        content129: "A szerzõdésszegésért vagy a jelen Megállapodásban meghatározott egyéb felelõsségrõl való lemondását mi vagy Ön nem tekinti a szerzõdésszegésért való egyéb felelõsségrõl való lemondásnak az ilyen jogokról vagy jogorvoslatokról való lemondásként kell értelmezni.",
        content130: "18. Cím",
        content131: "Az összes címsor csak a megállapodás kifejezésének megkönnyítését szolgálja, és nem a szerződés feltételeinek tartalmának vagy hatályának kiterjesztésére vagy korlátozására szolgál.",
        content132: "19. Alkalmazandó jog",
        content133: "19.1 A vita vagy követelés felvetése előtt Ön tudomásul veszi, és beleegyezik abba, hogy először e-mailben felveszi a kapcsolatot a vita informális megoldása érdekében. Mindkét fél megállapodik abban, hogy a vitát jóhiszemű tárgyalások (megbeszélések) útján rendezzük bizalmasnak és az alkalmazandó szabályok által védettnek kell lenniük, és semmilyen jogi eljárásban nem használható fel bizonyítékként).",
        content134: '19.2 Ön tudomásul veszi és elfogadja, hogy bármilyen vita, vita, nézeteltérés vagy követelés esetén, beleértve a jelen Feltételek létezését, érvényességét, értelmezését, teljesítését, megsértését vagy megszüntetését, vagy bármely, a jelen Feltételekből eredő vagy azokkal kapcsolatos vitát („Vita” ), a fent említett felek a jogvitát a Nemzetközi Kereskedelmi Kamara Nemzetközi Választottbírósága elé terjesztik ("ICC Nemzetközi Választottbíróság") az akkor hatályos "ICC választottbírósági szabályai" szerint. A választottbírósági eljárás helye Belize, és a választottbírósági eljárásra alkalmazandó jogot a Nemzetközi Kereskedelmi Kamara választottbírósági szabályaival összhangban kell meghatározni. A felek eltérő megállapodása hiányában az ICC választottbírósági szabályai szerint csak egy választottbírót neveznek ki. A választottbíró kizárólagos felhatalmazással rendelkezik arra, hogy saját joghatóságáról döntsön, beleértve, de nem kizárólagosan, a választottbírósági megállapodás létezésével, hatályával vagy érvényességével, illetve bármely követelés vagy viszontkereset választottbíróságával kapcsolatos kifogást. Minden választottbírósági eljárás angol nyelven zajlik. A választottbíróság lefolytatásának módjától függetlenül a választottbíró írásos ítéletet ad ki, amely ésszerű és elegendő az ítélet és a döntés alapjául szolgáló lényeges következtetések magyarázatához, ha vannak ilyenek. A választottbírósági ítélet végleges és kötelező a felekre nézve, és bármely illetékes bíróságon végrehajtható.',
        content135: "20. A megállapodás eredményessége és értelmezése",
        content136: "20.1 Ez a megállapodás akkor lép életbe, amikor rákattint a regisztrációs oldalra, hogy beleegyezzen a regisztrációba, befejezze a regisztrációs folyamatot, és megszerezze a fiókot és a jelszót.",
        content137: "20.2 A jelen szerződés végső értelmezési joga ezt a weboldalt illeti meg.",
        content138: "Ismerje meg vásárlói és pénzmosás elleni politikáját",
        content139: "1. Bemutatkozás",
        content140: "1.1 Ígérjük, hogy gondosan betartjuk az „Ismerje meg ügyfelét” és a pénzmosás elleni küzdelemmel kapcsolatos törvényeket és előírásokat, és szándékosan nem sérti meg az „Ismerje meg ügyfelét és a pénzmosás elleni szabályzatot”. Ésszerű ellenőrzésünk keretein belül megtesszük a szükséges intézkedéseket és technológiákat annak érdekében, hogy biztonságos szolgáltatásokat nyújtsunk Önnek, és amennyire csak lehetséges, megóvjuk Önt a bűnözői gyanúsított pénzmosási magatartása által okozott veszteségektől.",
        content141: "1.2 Az Ismerje meg ügyfelét és a Pénzmosás elleni szabályzatunk egy átfogó nemzetközi szabályzatrendszer, amely magában foglalja az Ismerje meg ügyfelét és a Pénzmosás elleni szabályzatot a különböző joghatóságokra vonatkozóan, amelyekhez Ön kapcsolódik.",
        content142: "2. Ismerje meg ügyfelei és a pénzmosás elleni politikáit az alábbiak szerint：",
        content143: "2.1 Ismertesse meg ügyfelét és a pénzmosás elleni szabályzatot, és időről időre frissítse azokat, hogy megfeleljenek a vonatkozó törvények és rendeletek által meghatározott normáknak.；",
        content144: "2.2 Tegyünk közzé és frissítsünk néhány vezérelvet és szabályt a jelen weboldal működtetésére vonatkozóan, és munkatársaink ezen alapelvek és szabályok útmutatásai szerint fognak szolgáltatásokat nyújtani.；",
        content145: "2.3 A tranzakciók belső nyomon követésére és ellenőrzésére szolgáló eljárások megtervezése és teljes körű végrehajtása, mint például a szigorú személyazonosság-ellenőrzés és a pénzmosás elleni munkáért felelős szakmai csapat kialakításának megszervezése；",
        content146: "2.4 Alkalmazzon kockázatmegelőzési megközelítést az ügyfelek kellő átvilágítása és folyamatos felügyelete érdekében；",
        content147: "2.5 Tekintse át és rendszeresen ellenőrizze a megtörtént tranzakciókat；",
        content148: "2.6 Jelentse a gyanús tranzakciókat az illetékes hatóságoknak；",
        content149: "2.7 A személyazonosságot, a címet és a tranzakciós nyilvántartásokat legalább hat évig megőrzik, és az Ön további értesítése nélkül benyújthatják a szabályozó hatóságokhoz.",
        content150: "3. Személyazonossági adatok és ellenőrzés",
        content151: "3.1 Személyazonossági adatok",
        content152: "3.1.1 A különböző joghatóságok és a különböző entitástípusok eltérő szabályozása szerint az általunk gyűjtött információk tartalma ellentmondásos lehet.：",
        content153: "Alapvető személyes adatok: az Ön neve, címe (és állandó lakcíme, ha eltérő), születési ideje és állampolgársága, valamint egyéb rendelkezésre álló adatok. A személyazonosság-ellenőrzésnek hatósági vagy más hasonló hatóságok által kiállított dokumentumokon kell alapulnia, például útleveleken, személyi igazolványokon vagy más, különböző joghatóságok által megkövetelt és kiváltott személyazonosító okmányokon. Az Ön által megadott címet megfelelő módszerekkel ellenőrizzük, például a személyszállítási jegy vagy a kamatlevél ellenőrzésével vagy a választói névjegyzék ellenőrzésével.",
        content154: "Érvényes fénykép: Regisztráció előtt be kell mutatnod egy fényképet, amelyen személyi igazolványát a mellkasához szorítja；",
        content155: "Elérhetőségek: Telefon/mobilszám és/vagy érvényes email cím.",
        content156: "3.1.2 Ha Ön egy vállalat vagy más jogi személy, a következő információkat gyűjtjük be, hogy azonosítsuk Önt vagy bizalmi fiókja végső kedvezményezettjét.",
        content157: "cégbejegyzési és bejegyzési igazolás a társaság alapító okiratának és a társaság saját tőkéjének szerkezetéről és tulajdonosi leírásáról, amely igazolja a meghatalmazott igazgatósági határozatát, amely meghatározza a számla megnyitását és végrehajtását; az igazgatók, akiket szükség szerint meg kell adni, a fő részvényes személyazonossági okmányait és az ezen a weboldalon lévő számla aláírására jogosult személyt, ha az eltér a társaság levelezési címétől, adja meg a levelezési címet. Ha egy vállalat helyi címe nem egyeztethető össze a fő üzleti címével, akkor nagyobb kockázatú ügyfélnek minősül, és további kiegészítő dokumentumokat kell benyújtania.",
        content158: "A különböző joghatóságok és a különböző típusú szervezetek eltérő szabályozásától függően más hatósági igazolásokat és dokumentumokat, valamint általunk szükségesnek ítélt dokumentumokat igényelünk.",
        content159: "3.1.3 A személyazonossági adatoknak csak angol vagy kínai változatát fogadjuk el, ha nem, fordítsa le személyazonossági adatait angolra, és hitelesítse azokat.",
        content160: "3.2 Erősítse meg az Ellenőrzést",
        content161: "3.2.1 Megkérjük, hogy adja meg az azonosító okmánya teljes oldalát.",
        content162: "3.2.2 Kérünk egy fényképet, amelyen személyazonosító okmányát a mellkasához szorítja.",
        content163: "3.2.3 Az igazoló dokumentumok másolatait általában össze kell hasonlítani az eredeti utalványokkal. A másolat azonban elfogadható, ha egy megfelelő és megbízható hitelesítő igazolni tudja, hogy a másolat az eredeti dokumentum pontos és teljes másolata. Ilyen tanúsítók közé tartoznak a nagykövetek, az igazságügyi biztosok, a helyi bírák stb.",
        content164: "3.2.4 A végső kedvezményezett azonosításának és a számla ellenőrzésének követelménye annak meghatározása, hogy végső soron mely személyek birtokolják vagy irányítják a közvetlen ügyfelet, és/vagy annak megállapítása, hogy a folyamatban lévő tranzakciókat mások nevében hajtják végre. Ha üzletről van szó, ellenőrizni kell a fő részvényesek (például a szavazati részesedés 10%-ával vagy annál nagyobb részesedéssel rendelkezők) személyazonosságát. Általában a részvények 25%-ának birtoklása normál kockázatúnak minősül, és a részvényes személyazonosságát igazolni kell, ha a részvények 10%-ával rendelkezik, vagy több szavazati joggal vagy részvényrel rendelkezik, és a személyazonosság kockázatos helyzetnek minősül; igazolni kell.",
        content165: "4. Tranzakciók figyelése",
        content166: "4.1 A napi tranzakciós és valutafelvételi maximális limiteket időről időre beállítjuk és módosítjuk a biztonság és a tényleges tranzakciós feltételek alapján;",
        content167: "4.2 Ha egy regisztrált felhasználónál gyakran fordulnak elő tranzakciók, vagy indokolatlan körülmények állnak fenn, szakmai csapatunk értékeli és eldönti, hogy gyanúsak-e.;",
        content168: "4.3 Ha saját megítélésünk alapján megállapítjuk, hogy egy ügylet gyanús, korlátozó intézkedéseket hozhatunk, például felfüggeszthetjük az ügyletet, elutasíthatjuk az ügyletet, vagy akár a lehető legrövidebb időn belül visszavonhatjuk az ügyletet, és jelenthetjük az illetékes hatóságnak, de nem értesíti Önt.;",
        content169: "4.4 Fenntartjuk a jogot, hogy elutasítsuk a nemzetközi pénzmosás elleni normáknak nem megfelelő joghatóságokból származó vagy politikailag exponáltnak minősülő személyek regisztrációs kérelmét bármikor, de nem sértünk meg semmilyen kötelezettséget vagy felelősséget.",
    },
    register: {
        formTitle1: "teremt",
        formTitle2: "Regisztráljon e-mail címével vagy mobiltelefonszámával",
        formTitle3: "fiók",
        field1: "Ellenőrző kód",
        field2: "Szerezzen be",
        field3: "Jelszó megerősítése",
        field4: "Meghívó kód",
        field41: "(választható)",
        field5: "Elolvastam és egyetértek",
        field6: "Szolgáltatás feltételei",
        placeholder1: "kérjük, adja meg az ellenőrző kódot",
        content7: "kérjük, adja meg az ellenőrző kódot",
        content12: "Kérjük, adja meg a telefonszámot",
        content13: "Kérjük, írja be a jelszót",
        content14: "kérjük adja meg e-mail címét",
        content18: "Kérjük, adja meg újra a jelszót",
        content19: "Kérjük, fogadja el a szolgáltatási feltételeket",
        content20: "Kérjük, írja be a helyes ellenőrző kódot",
        content23: "Még mindig nem kapta meg az ellenőrző kódot?",
        content24: "Kérjük, adja meg alább az ellenőrző kódot",
        registerSuccess: "sikeres regisztráció",
        content25: "A jelszavak következetlenek",
        content26: "Kérjük, adja meg a helyes e-mail címet",
        content27: "A jelszó visszaállítása sikeres volt"
    },
    download: {
        content1: "Kereskedjen bármikor, bárhol",
        content2: "Tökéletesen kompatibilis több terminállal, és bármikor kielégíti a különféle forgatókönyvek tranzakciós igényeit",
        content3: "Bármikor ellenőrizze a valós idejű piaci feltételeket",
        content4: "Könnyen vásárolhat és eladhat BTC, ETH, XRP és egyéb digitális eszközöket",
        content5: "Korai figyelmeztetéseket kaphat az Ön számára fontos kriptovaluták árváltozásairól",
        content6: "Tekintse meg a Bitcoin azonnali kölcsönt, határidős kölcsönt és opciós árakat",
        content7: "Hasonlítsa össze a kriptovaluták árait a különböző tőzsdéken",
        title: "Letöltés",
        install: "Telepítési megjegyzések",
        one: "1、Leíró fájlok letöltésének engedélyezése",
        two: "2、Leíró fájl letöltve",
        three: "3、Nyissa meg a [Beállítások] elemet a telefonján, és keresse meg az [Általános] lehetőséget.",
        four: "4、Profilok és Eszközkezelő",
        five: "5、Kattintson a profilra",
    },
    identity: {
        noAuthentication: {
            title: "Junior bizonyítvány",
            pleaseSelectYourCountry: "Kérem válassza ki az országát",
            name: "Név",
            enterName: "Kérem írja be a nevét",
            city: "város",
            enterCity: "Kérjük, adja meg városát",
            IDNumber: "személyi igazolvány száma",
            enterIDNumber: "Kérjük, adja meg azonosító számát",
        },
        noAdvancedAuthentication: {
            title: "Haladó minősítés",
            infoCountry: "ország/régió",
            infoName: "Név",
            infoNumber: "személyi igazolvány száma",
            infoAddress: "cím",
        },
        text: "nem hitelesített",
        recertification: "újraminősítés",
        certification: "Tanúsítvány",
        authenticationInProgress: "ellenőrizve",
        inAuthentication: "Tanúsítás folyamatban",
        uploadTheFrontOfTheDocument: "Töltse fel az igazolvány elejét",
        uploadTheReverseSideOfTheDocument: "Töltse fel a tanúsítvány hátoldalát",
        uploadAPhotoOfAHandHeldID: "Töltsön fel egy fényképet a kezében lévő személyi igazolványáról",
        pleaseUpload: "kérem ",
        pleaseCompleteOrdinaryCertificationFirst: "Meg kell várni az elsődleges tanúsítvány felülvizsgálatának sikerét",
        refused: "elutasítva",
        reason: "ok",
        succeeded: "sikerült",
        uploading: "Feltöltés",
        newTitle: "Válassza ki azt az országot, ahol személyi igazolványát kiállították",
        pleaseOrdinaryCertificationFirst: "Kérjük, először végezze el az elsődleges tanúsítást",
        text1: "Az avatar módosítása",
        text2: "Nick név",
        text3: "fiókot",
        text4: "Becenév módosítása",
        text5: "új becenév",
        text6: "Írja be az új becenevét",
        text7: "régi jelszó",
        text8: "Írja be a régi jelszavát",
        text9: "új jelszó",
        text10: "Adja meg új jelszavát",
        text11: "Jelszó megerősítése",

        text12: "Írja be újra az új jelszavát",
        text13: "új postafiók",
        text14: "Ellenőrző kód",
        text15: "kérjük, adja meg az ellenőrző kódot",
        text16: "a mobiltelefonszámát",
        text17: "Új mobiltelefonszám",
        text18: "kérlek add meg a telefonszámod",
        text19: "Mobiltelefon ellenőrző kód",
        text20: "Junior bizonyítvány",
        text21: "Kérjük, ellenőrizze személyazonossági adatait",
        text22: "Ország",
        text23: "Város",
        text24: "Adja meg városát",
        text25: "Adja meg nevét",
        text26: "Az Ön azonosító száma",
        text27: "Írja be az azonosító számát",
        text28: "Személyi igazolvány elülső fényképe",
        text29: "Kattintson ide a dokumentumok feltöltéséhez",
        text30: "töltse fel újra",
        text31: "feltölteni",
        text32: "Kezében személyazonosító fénykép",
        text33: "Kattints a személyazonosító okmány fényképének feltöltéséhez",
        text34: "Összekötötte a Google Hitelesítőt",
        text35: "kulcs",
        text36: "Kérjük, adja meg a Google ellenőrző kódot",
        text37: "Küld",
        text38: "Nem lehet üres",
        text39: "Az e-mail formátuma rossz",
        text40: "Két jelszó ellentmondásos",
        text41: "Sikeres másolás",
        text42: "A másolás nem sikerült",
        text43: "Avatar módosítás",
        text44: "Jelszó módosítása",
        text45: "E-mail kötés",
        text46: "E-mail módosítása",
        text47: "Mobiltelefon kötés",
        text48: "Mobiltelefon módosítása",
        text49: "Google ellenőrzés",
        text50: "Kösse össze a Google Hitelesítőt",
        text51: "Írja be a Google ellenőrző kódot",
        text52: "megment",
        text53: "Beküldés megerősítése",
        text54: "Felülvizsgál",
        text55: "kötés",
        text56: "Kapcsolja ki a Google-ellenőrzést",
        text57: "Következő lépés",
        text58: "Befejez",
        text59: "A Google ellenőrzése kötelező",
        text60: "A Google ellenőrzése megszűnt",
        text61: "Az elsődleges tanúsítványt benyújtották",
        text62: "Haladó tanúsítás elküldve",
        text63: "Nincs kép feltöltve",
        text64: "Újraküldés",
        text65: "Kérjük, adjon meg egy becenevet",
        text66: "A becenév sikeresen mentve",
        text67: "Még nincsenek feltöltött képek",
        text68: "A jelszó visszaállítása befejeződött",
        text69: "Az e-mail sikeresen módosítva",
        text70: "Az avatar sikeresen elmentve",
        text71: "Kép feltöltése",
        text72: "a postafiókod",
        text73: "Adja meg új e-mail címét",
        text74: "A neved",
        text75: "Menjen a hitelesítéshez",
        text76: "Kérjük, igazolja a személyazonosító okmány fényképét"
    },
    retrievePassword: {
        formTitle1: "Jelszó visszaállítása",
        formTitle2: "Az eszközei biztonsága érdekében a jelszó megváltoztatását követő 24 órán belül tilos tranzakciókat végezni.",
        formTitle3: "Jelszó lekérése",
        formTitle4: "Erősítse meg a változtatásokat",
        formTitle5: "Kérjük, adja meg a régi jelszót",
        formTitle6: "Kérjük, adjon meg egy új jelszót"
    },
    home: {
        title1: "A legnépszerűbb kriptovaluta kereskedési platform",
        title3: "miért van ",
        title4: " Ez egy nagy teljesítményű, nyílt forráskódú blokklánc-platform, amelyet a biztonságos, kompatibilis és kiszámítható digitális infrastruktúra támogatására és működtetésére terveztek.",
        field1: "Létrehozott csere",
        field2: "Digitális eszközök pénzügyi szolgáltatások",
        field3: "Több tízmillió felhasználó",
        field4: "Több befektetővédelmi alap létrehozása",
        field5: "Gyors kereskedés",
        field6: "A CME Gruop rendkívül alacsony késleltetésű megbízásokat képes párosítani a tökéletes kereskedési élmény érdekében.",
        title5: "piaci trend",
        title6: "többet látni",
        table1: "Valuta",
        table2: "Legújabb ár",
        table3: "24 órás hullámvölgyek",
        table4: "24 órás kereskedési volumen",
        table5: "24 órás tranzakciós mennyiség",
        table6: "Idézetek",
        title7: "ról ről ",
        title8: " Elkötelezettek vagyunk amellett, hogy a felhasználók számára a legfejlettebb kereskedési eszközöket, innovatív technológiát és páratlan minőségű ügyfélszolgálatot biztosítsunk.",
        title9: "A digitális eszközök területe napról napra változik. Csak előrelátóak vagyunk és gyorsan haladunk a technológiai innováció ütemével.",
        title901: " A legfejlettebb digitális eszközkereskedési szolgáltatásokat nyújtjuk felhasználóinknak és globális likviditásszolgáltatóink számára.",
        title10: "Meggyőződésünk, hogy minden felhasználó megérdemli a legjobb kereskedési élményt.",
        title11: "Biztonság, amiben megbízhat",
        title12: "Kifinomult biztonsági intézkedéseink és SAFU-alapjaink megvédik digitális eszközeit minden kockázattól. ",
        title13: "Legjobb tranzakciós árfolyamok",
        title14: "Kedvezményes tranzakciós árak, versenyképes VIP-jogok, és élvezze a legjobb minőségű szolgáltatásokat.",
        title15: "24*7 órás ügyfélszolgálati támogatás",
        title16: "A hét minden napján, 24 órában, teljes munkaidős üzemmódban, a lehető leghamarabb válaszolunk kérdéseire.",
        title17: "0 díjas kereskedés",
        title18: "Használjon többféle fizetési módot a digitális valuták kereskedelméhez nulla kezelési költséggel, biztonságosan és gyorsan.",
        title19: "Kereskedjen bármikor, bárhol",
        title20: "Tökéletesen kompatibilis több terminállal, és bármikor kielégíti a különféle forgatókönyvek tranzakciós igényeit",
        title21: "Bármikor ellenőrizze a valós idejű piaci feltételeket",
        title22: "Könnyen vásárolhat és eladhat BTC, ETH, XRP és egyéb digitális eszközöket",
        title23: "Korai figyelmeztetéseket kaphat az Ön számára fontos kriptovaluták árváltozásairól",
        title24: "Tekintse meg a Bitcoin azonnali kölcsönt, határidős kölcsönt és opciós árakat",
        title25: "Hasonlítsa össze a kriptovaluták árait a különböző tőzsdéken",
        title26: "Iratkozz fel most",
        title27: "Dollár",
        title28: "24 órás kereskedési mennyiség",
        title29: "Integrált Likviditásszolgáltató",
        title30: "felhasználó",
        title31: "Több",
        title32: "A Binance Loan új jelzálogeszközökkel bővítette a CTSI-t és a KSM-et, és kamatcsökkentési kedvezményeket indított a BUSD és USDT számára!",
        title33: "A mi előnyünk",
        title34: "biztonságos és stabil",
        title35: "A kiváló műszaki csapat, az átfogó biztonsági védelem, a független kutatás és fejlesztés a nagy sebességű tranzakciószintézis motornak köszönhetően továbbra is stabilan és megbízhatóan működik hatalmas egyidejű tranzakciók esetén.",
        title36: "Szakmai megfelelőség",
        title37: "Professzionális üzemeltetési csapat, sok éves tapasztalattal a blokklánc és a pénzügyek terén, megfelelő digitális eszköz kereskedési engedéllyel és 100%-os tartalékgaranciával rendelkezik.",
        title38: "Első a felhasználó",
        title39: "Szembenézni a globális piaccal, több nyelv támogatása, éjjel-nappali működés, erős közösségi támogatás és professzionális ügyfélszolgálat.",
        title40: "nagy teljesítményű",
        title41: "300 000 tranzakció másodpercenként, és a megrendelés válaszideje kevesebb, mint 1 milliszekundum.",
        title42: "rólunk",
        title43: "Kereskedjen bármikor, bárhol",
        title44: "Tökéletesen kompatibilis több terminállal, és bármikor kielégíti a különféle forgatókönyvek tranzakciós igényeit",
        title45: "（Kód beolvasása a letöltéshez）",
        title46: "Digitális valuta hírek",
        title47: "üzleti partnerünk",
        title48: "Vesztes lista",
        title49: "24 órás forgalom",
        title50: "24 órás növekedés és csökkenés"
    },
    quotation: {
        marketTrading: "Idézetek",
        turnover: "Hangerő",
        optional: "Választható",
        contractMarket: "szerződéses piac",
        currencyPair: "devizapár",
        latestPrice: "Záró ár",
        upsAndDowns: "Idézetváltás",
        highest: "Legmagasabb",
        lowest: "legalacsonyabb",
        transactionAmount: "Tranzakció mennyisége",
        operate: "működtet",
        buy: "kereskedelmi",
        topTurnovers: "Tranzakciók listája",
        topGainers: "Gainer lista",
        tradingPair: "kereskedési pár",
        buyOrder: "megvesz",
        sellOrder: "eladási megbízás"
    },
    accountChange: {
        demo: "szimuláció",
        realAccount: "tényleges számla",
        demoAccount: "Demo fiók",
        accountRole: "Fiók szerepkör",
        pleaseSelect: "kérlek válassz",
        AccountChangeType: "Számlamódosítás típusa",
        AccountType: "Fiók Típus",
        currencyType: "Pénznem típusa",
        allType: "Minden típus",
        allAccount: "Minden fiók",
        allCoin: "Minden valuta",
        coin: "Valuta",
        amount: "Módosítsa az összeget",
        balance: "Számlaegyenleg",
        time: "idő",
        title: "Számlamódosítási rekordok",
        filtrar: "szűrő",
        check: "váltani",
        showOnly: "Csak a pénznemek megjelenítése",
        holdAssets: "Valutaegyenleg",
        accountList: "Számlák listája"
    },
    contractTransaction: {
        chart: "diagram",
        basicEdition: "Alap verzió",
        depthMap: "Mélység térkép",
        second: "Második",
        minute: "pont",
        day: "ég",
        week: "hét",
        month: "hold",
        open: "nyisd ki",
        trade: "kereskedelmi",
        buyLong: "hosszú vásárlás",
        buyShort: "rövid vásárlás",
        cannotBuy: "Ez a pénznem nem támogatja a szállítási szerződéses tranzakciókat",
        cannotBuy1: "Ez a pénznem nem támogatja az állandó kereskedést",
        tradingAssets: "kereskedési eszközök",
        amountAvailable: "elérhető mennyiség",
        timeStr: "idő",
        transactionPeriod: "kereskedési időszak",
        quantity: "Mennyiség",
        enterQuantity: "Kérjük, adja meg a mennyiséget",
        buyIn: "Menj sokáig",
        latestTransaction: "legutóbbi tranzakció",
        time: "idő",
        direction: "irány",
        price: "ár",
        sell: "rövid",
        positionOrder: "Szállítási rendelés",
        historicalOrders: "Történelmi rendek",
        product: "termék",
        realAccount: "(valódi számla)",
        demoAccount: "(Demo fiók)",
        openingTime: "rendelési idő",
        openingPrice: "Nyitási ár",
        curretPrice: "Jelenlegi ár",
        liquidationPrice: "Záró ár",
        profitOrLoss: "Haszon és veszteség",
        endTime: "Záróra",
        areYouSureToBuy: "Biztos, hogy vásárolni akar?",
        areYouSureToLongBuy: "Biztos, hogy sokáig akarsz menni",
        areYouSureToShortBuy: "Biztos, hogy shortot akarsz vásárolni?",
        successfullyBought: "Sikeres vásárlás",
        minBuy: "Minimális nevezési díj",
        assets: "eszközök",
        withdraw: "visszavonulni",
        entrustedOrder: "Rendelés",
        total: "teljes",
        continueToPlaceOrder: "Folytassa a rendelés leadását",
        orderConfirmation: "Megrendelés visszaigazolása",
        buyRu: "megvesz",
        sellChu: "elad",
        areYouSureToSell: "Biztos, hogy el akarod adni?",
        operation: "működtet",
        buyCover: "fedőhelyzet",
        commitSuccess: "Sikeres tranzakció",
        onlyEnterIntegers: "Csak egész számokat lehet megadni",
        trend: "Irányzat",
        lotteryTime: "Rendelési határidő",
        countdown: "Visszaszámlálás",
        period: "Probléma",
        cycle: "Időszak",
        unlimited: "Korlátlan",
        put: "Put",
        call: "Call",
        pending: "Bizományba",
        unopened: "Még nem rajzolták",
        opened: "Kisorsolták a nyereményt",
        settlementPrice: "Elszámoló ár",
        rateOfReturn: "megtérülési ráta",
        currentPositions: "Aktuális pozíció",
        positionQuantity: "Pozíció mennyisége",
        guarantee: "árrés",
        text1: "Call",
        text2: "Put",
        text3: "Vásárlásra vár",
        text4: "Vásárlás Mennyiség",
        text5: "A tranzakciók száma",
        text6: "Megszünteti",
        text7: "szállítási idő",
        text8: "Lemondási emlékeztető",
        text9: "Biztosan törölni szeretné a rendelést?",
        text10: "Illegális mennyiség",
        text11: "Rendelési emlékeztető",
        text12: "Biztos, hogy bullish-t vásárolsz?",
        text13: "Biztos, hogy megveszed a készletet?",
        text14: "A törlés sikeres"
    },
    mining: {
        title1: "A világ vezető blockchain digitális eszközbányászati ​​platformja",
        title2: "Nyitott és átlátszó · biztonságos és stabil",
        title3: "Kérjük, válasszon pénznemet",
        title4: "Bányászat évesített hozama",
        title5: "ég",
        title6: "Bányászati ​​időszak",
        title7: "termék leírás",
        btn: "Az enyém most",
        btn2: "bezárás",
        tabs1: "folyamatban",
        tabs2: "Megváltva",
        table1: "termék név",
        table2: "Bányászati ​​időszak",
        table3: "Bányászati ​​idő",
        table4: "Bányászati ​​mennyiség",
        table5: "Lejárati dátum",
        table6: "Tovább az érettségig",
        table7: "Rendelés állapota",
        egularMining: "Rendszeres bányászat",
        inProgress: "folyamatban",
        redeemed: "Megváltva",
        redemption: "megváltás",
        regularMining: "Rendszeres bányászat",
        earlyRedemption: "korai megváltás",
        need: "szükség",
        handlingFee: "kezelési költség",
        redemptionConfirmationMessage: "Kérjük, erősítse meg előre, hogy beváltja-e?",
        form1: "Bányászat évesített hozama",
        form2: "Bányászati ​​időszak",
        form3: "Bányászati ​​valuta",
        form4: "Előfizetés összege (minimum/maximum)",
        form5: "Korai visszaváltási díj: ",
        form6: "beszerzési ár",
        form7: "Elérhető alapok",
        form8: "beszerzési ár",
        form9: "Elérhető alapok",
        form10: "Kérjük, adja meg a vásárlás összegét",
        form11: "minden",
        form12: "Becsült bevétel",
        form13: "Nem áll rendelkezésre elegendő forrás",
        form14: "Kérjük, adja meg a vásárlás összegét",
        form15: "Rendelésem",
        form16: "jövedelem",
        form17: "megrendelés részletei",
        form18: "Útmutató a korai visszaváltáshoz:",
        form19: "Előfizetés összege (minimum)",
        form20: "Előfizetés összege (maximum)",
        form21: "Tartott mennyiség",
        form22: "Vásárlási idő",
        form23: "megváltási idő",
        form24: "felett",
        form25: "Az előfizetés sikeres",
        form26: "A beváltás sikeres",
        form27: "Egyszerű pénzügyi kezelés több mint 25%-os megtérüléssel, ami felülmúlja a piacot, és a vásárlás után jutalmakban is részesülhet",
        form28: "Hívja meg barátait 50 USDT jutalomban, és a szerződéses utalványok 20% jutalékot is visszaadhatnak."
    },
    assets: {
        content1: "Számlaeszközök",
        content2: "A mai bevétel",
        content3: "Jelenlegi fiók",
        content4: "A tényleges számla az Ön tényleges számlavagyona, amely tranzakciókra és kifizetésekre használható.",
        content5: "Érmék kivonása",
        content6: "Letéti érmék",
        content7: "csere",
        content8: "átruházás",
        content9: "kérlek válassz",
        content10: "Demo fiók",
        content11: "tényleges számla",
        content12: "eszközöket szerezni",
        content13: "Biztos, hogy valódi fiókra szeretne váltani?",
        content14: "Biztos benne, hogy demo fiókra szeretne váltani?",
        content15: "sikert elérni",
        table1: "Valuta",
        table2: "Mennyiség",
        table3: "fagy",
        table4: "Elérhető",
        dialog1: "termék leírás",
        dialog2: "1、Ez az eszköz csak demószámla módban használható",
        dialog3: "2、A visszaélések megelőzése érdekében egy hónapon belül csak egy beváltás engedélyezett.",
        dialog4: "3、Az eszközök átvétele hozzáadja az eredeti alapon kapott eszközök összegét, és nem változtatja meg a pozíció állapotát.",
        dialog5: "4、Ha további kérdése van, forduljon az ügyfélszolgálathoz",
        dialog6: "bezárás",
        dialog7: "Szerezzen be demóeszközöket most",
        dialog8: "transzfer be",
        dialog9: "transzfer ki",
        dialog10: "kapcsoló",
        dialog11: "Értékelés",
        dialog12: "Demo eszközök",
        dialog13: "a fiókom",
        dialog14: "Teljes számlavagyon",
        dialog15: "Eszköz áttekintése",
        dialog16: "Teljes vagyonértékelés",
        dialog17: "Eszközértékelés",
        dialog18: "A mai bevétel",
        dialog19: "pénzeszközök befagyasztása",
        dialog20: "Minden hónapban kaphat szimulált eszközkvótát",
        dialog21: "kap",
        dialog22: "Sikeresen megkaptad",
        dialog23: '30 nap halmozott jövedelem',
        dialog24: "Pénzügyi termék",
        dialog25: "Pénzügyi vezetésem",
        dialog26: "Történelmi pénzgazdálkodás",
        dialog27: "történelem rekord",
        dialog28: "Finanszírozási nyilvántartások"
    },
    validator: {
        dialog14: "érvényesítő",
        dialog15: "Bind érvényesítő",
        dialog16: "érvényesítő kapcsoló",
        dialog17: "Ellenőrző kód",
        dialog18: "Ellenőrző QR-kód",
        dialog19: "érvényesítő kulcs",
        dialog20: "megment",
        dialog21: "Kérjük, válasszon érvényesítőt",
        dialog22: "kulcs másolása",
        dialog23: "sikeresen elmentve",
        dialog24: "kérjük, adja meg az ellenőrző kódot",
        dialog25: "Barátságos emlékeztető: A mentés megerősítése előtt nyissa meg a megfelelő APP-t, és olvassa be az alábbi QR-kódot, vagy adja hozzá a kulcsot manuálisan!",
        dialog26: "A hitelesítő engedélyezése maximális biztonságot nyújt fiókja számára.",
        dialog27: "Minden bejelentkezéskor vagy pénzfelvételkor meg kell adnia egy egyedi ellenőrző kódot, amelyet mobiltelefonján keresztül ér el.",
        dialog28: "Állítsa be az érvényesítőt",
        dialog29: "Segítség",
        dialog30: "Nem kötötte be a hitelesítési kódot. Erősebb védelmet szerezhet hitelesítővel.",
        dialog31: "Az érvényesítő még nincs beállítva, kérjük először állítsa be",
        dialog32: "Menj a beállításokhoz"
    },
    recharge: {
        title1: "Nem tudja, hogyan vásároljon digitális valutával?",
        title2: "Csak három egyszerű lépés!",
        title3: "Válassza ki a megvásárolni kívánt digitális valutát a legördülő menüből, és adja meg a vásárlás mennyiségét vagy összegét.",
        title4: "Válassza ki a kívánt fizetési módot",
        title5: "A fizetés megerősítése után a megvásárolt digitális valuta automatikusan átkerül a számlájára.",
        title6: "Kérjük, válassza ki a feltölteni kívánt pénznemet",
        title7: "Betéti nyilvántartás",
        table1: "Valuta",
        table2: "Mennyiség",
        table3: "Láncnév",
        table4: "állapot",
        table5: "ok",
        table6: "idő",
        table7: "Az utalvány megtekintése",
        dialog1: "Kérjük, adja meg alább az ellenőrző kódot",
        dialog2: "fizetési kód",
        dialog3: "másolat",
        dialog4: "cím",
        dialog5: "Betét összege",
        dialog6: "Tranzakció képernyőképe",
        dialog7: "Fontos figyelmeztetés",
        dialog8: "Kérjük, töltse ki a befizetés összegét",
        dialog9: "kép feltöltése",
        dialog10: "Kérjük, adja meg a tartalmat",
        dialog11: "Kérjük, töltsön fel képernyőképeket az újratöltésről",
        dialog12: "Nincs felülvizsgálva",
        dialog13: "elutasítva",
        dialog14: "Letétbe helyezve",
        tip1: "Sikeresen elküldve",
        tip2: "Sikeres másolás",
        tip3: "A másolás nem sikerült",
        tip4: "Kérjük, válassza ki a feltölteni kívánt pénznemet",
        tip5: "1.A fenti befizetési cím a platform hivatalos fizetési címe. Kérjük, keresse meg a platform hivatalos befizetési címét.；",
        tip6: "2.Ügyeljen arra, hogy ellenőrizze számítógépe és böngészője biztonságát, hogy megakadályozza az információk megváltoztatását vagy kiszivárogtatását;",
        tip7: "3.Miután feltöltötte a fenti címet, a teljes hálózati csomópontnak meg kell erősítenie a számla fogadását.；",
        tip8: "4.Kérjük, válassza ki a fenti token rendszert és pénznemet, és utalja át a megfelelő összeget a befizetéshez. Kérjük, ne utaljon át semmilyen más nem kapcsolódó eszközt, különben nem kerül lekérésre.",
        tip9: "Mentse el a QR-kódot",
        tip10: "feltölt",
        tip11: "Fontos figyelmeztetés",
        tip12: "Offsite linkek",
        tip13: "Töltse fel a QR-kódot",
        tip14: "Következő lépés",
        tip15: "Feltöltés rekord",
        tip16: "Feltöltés részletei",
        tip17: "Letéti cím",
        tip18: "Gyors fizetés",
        tip20: "Fedezethiány",
        tip22: "Beküldve, megerősítésre vár",
        tip23: "fizetésre vár",
        tip24: "Összeg",
        tip25: "A fizetés elküldve, blokk megerősítésére vár",
        tip26: "Fizetés elutasítva",
        tip27: "Sikertelen fizetés",
        tip28: "Sikeres fizetés",
        bankChannel: "Bankkártya csatorna",
        tip29: "Történelmi kutatások",
        tip30: "Pénznemek listája",
        tip31: "Válassza ki a hálózatot",
        tip32: "Amikor újratölti ezt a pénznemet, kérjük, csak az alábbi támogatott hálózatokon keresztül töltse fel.",
        tip33: "Amikor kiveszi ezt a pénznemet, kérjük, csak az alábbi támogatott hálózatokon keresztül vegyen ki pénzt.",
        tip34: "Gyors újratöltés",
        tip35: "Olvassa be a kódot az újratöltéshez",
        tip36: "Kézi feltöltés",
        tip37: "Töltő hálózat",

        tip38: "Újratöltési cím",
        tip39: "Feltöltés összege",
        tip40: "Tranzakció nyugta feltöltése",
        tip41: "kép feltöltése",
        tip42: "erősítse meg az elküldést",
        tip43: "fizetés megerősítése",
        tip44: "Megjegyzés: Kérjük, erősítse meg az újratöltéshez szükséges címet. Nem vállalunk felelősséget a helytelenül kitöltött pénz elvesztéséért.",
        tip45: "emlékeztet",
        tip46: "A cím sikeresen másolva!",
        tip47: "A címmásolás nem sikerült!",
        tip48: "Nincs elegendő rendelkezésre álló egyenleg",
        tip49: "Az újratöltés sikeresen elküldve, és megerősítésre vár a láncon.",
        tip50: "Az újratöltés nem sikerült",
        tip51: "Az újratöltés összege rossz",
        tip52: "Kapcsolat nem sikerült",
        tip53: "A Wallet nincs bejelentkezve",
        tip54: "kapcsolódás elutasítva",
        tip55: "Sikeresen elküldve, az ügyfélszolgálat áttekintésére és jóváhagyására vár",
        tip56: "Kérjük, töltsön fel egy képernyőképet a tranzakció nyugtájáról",
        tip57: "A beküldés sikertelen",
        tip58: "Feltöltés rekord",
        tip59: "Kivonási rekord",
        tip60: "Valuta feltöltése",
        tip61: "Kivételi pénznem",
        tip62: "Újratöltési cím",
        tip63: "Képernyőkép mutatja",
        tip64: "Kattintson a kép nagyításához",
        tip65: "Újratöltési idő",
        tip66: "Elutasítás oka",
        tip67: "Kivételi összeg",
        tip68: "Kapott összeg",
        tip69: "Készpénzfelvételi hálózat",
        tip70: "Kivételi cím",
        tip71: "Visszavonási idő",
        tip72: "bezárás",
        tip73: "Tranzakció képernyőképe",
        tip74: "Függőben lévő áttekintés",
        tip75: "elutasítva",
        tip76: "elkészült",
        tip77: "elutasítva",
        tip78: "Auditált",
        tip79: "Kifizetve",
        tip80: "kivonat",
        tip81: "átviteli hálózat",
        tip82: "Cím/Számla",
        tip83: "Vedd vissza az összeset",
        tip84: "Kérjük, ne vegyen fel pénzt közvetlenül a közösségi finanszírozás vagy az ICO címére, ellenkező esetben nem tudja átvenni a közösségi finanszírozás vagy az ICO által kibocsátott tokeneket.",
        tip85: "Érkezési mennyiség",
        tip86: "Erősítse meg a visszavonást",
        tip87: "Hibás visszavonási cím",
        tip88: "Hibás visszavonási összeg",
        tip89: "A kivehető összeg legalább",
        tip90: "A maximális kivonási összeg",
        tip91: "A visszavonási kérelmet benyújtották",
        tip92: "címlista",
        tip93: "Még nem adott hozzá címet",
        tip94: "Add hozzá most",
        tip95: "Adjon hozzá címet",
        tip96: "Új cím hozzáadása",
        tip97: "kérlek válassz",
        tip98: "cím",
        tip99: "Kérjük, válasszon átviteli hálózatot",
        tip100: "Kérjük, adja meg a címet",
        tip101: "A cím sikeresen hozzáadva",
        tip102: "tranzakcióban",
    },
    fundExchange: {
        title1: "Hogyan váltsunk alapokat?",
        title2: "Válassza ki a megfelelő pénznemet az átváltáshoz.",
        title3: "Válassza ki a megfelelő pénznemet az átváltáshoz.",
        title4: "Adja meg az átváltandó összeget, majd kattintson a Küldés gombra az alapcsere befejezéséhez.",
        title5: "tól től",
        title6: "megérkezik",
        title7: "Csere mennyiség",
        title8: "árfolyam",
        title9: "Elérhető",
        title10: "Kérjük, adja meg a beváltási mennyiséget",
        title11: "Nincs megfelelő kereskedési pár cserére.",
        title12: "Kérjük, adja meg a beváltandó mennyiséget",
        title13: "A beváltás sikeres",
        title14: "Csere rekord",
        table1: "Használjon valutát",
        table2: "Mennyiség",
        table3: "árfolyam",
        table4: "Pénzváltás",
        table5: "Nyugta",
        table6: "idő",
        title15: "tól től",
        title16: "Jelenleg nincsenek elérhető eszközök, válasszon másikat"
    },
    fundTransfer: {
        title1: "Hogyan lehet pénzt utalni?",
        title2: "Válassza ki az átvinni kívánt aktuális számlát.",
        title3: "Válassza ki az átvinni kívánt fiókot.",
        title4: "Adja meg az átutalás összegét, majd kattintson a Küldés gombra az átutalás befejezéséhez.",
        title5: "Valuta",
        title6: "Transzfer mennyiség",
        title7: "Kérjük, adja meg az átutalandó összeget",
        title8: "átadási rekord",
        title9: "Kérjük, válassza ki a pénztárca típusát",
        title10: "Kérjük, válasszon pénznemet",
        title11: "Kérjük, válassza ki a pénztárca típusát",
        title12: "Kérjük, válasszon pénznemet",
        title13: "Kérjük, adja meg az átutalandó összeget",
        title14: "Sikeresen elküldve",
        table1: "Számla áthelyezése",
        table2: "átutalás pénzneme",
        table3: "Transzfer mennyiség",
        table4: "átutalási számla",
        table5: "idő",
        table6: "átruházás",
        table7: "Pénzátutalás",
        table8: "Transzfer ide",
        table9: "átadási rekord",
        table10: "Az átvitel részletei",
        table11: "Az átvitel sikeres",
        table12: "Az átvitel sikertelen",
        table13: "A folyószámla nem utalható át"
    },
    withdrawal: {
        title1: "Fontos figyelmeztetés",
        title2: "1.Az arbitrázs elkerülése érdekében kérhet devizakivonást, amikor a tranzakciós mennyiség eléri a kvótát.",
        title3: "2.Az elállási kérelem a benyújtástól számított 24 órán belül jóváírásra kerül.",
        title4: "3.A visszavonási kérelem benyújtását követően a pénzeszközök befagyott állapotban vannak, mert a pénzfelvétel folyamatban van, és a pénzeszközök átmenetileg a rendszerben vannak.",
        title5: "Valuta",
        title6: "pénztárca címét",
        title7: "Kivont érmék mennyisége",
        title8: "Érmekivételi rekord",
        title9: "Kérjük, válasszon pénznemet",
        title10: "Kérjük, válassza ki a lánc típusát",
        title101: "",
        title11: "kérlek lépj be",
        title12: "pénztárca címét",
        title13: "Sikeresen elküldve",
        title14: "Felülvizsgálat alatt",
        title15: "Az ellenőrzés sikeres",
        title16: "Ellenőrzési hiba",
        title17: "Kifizetve",
        title18: "Kérjük, adja meg vagy illessze be pénztárca címét",
        title19: "Kérjük, adja meg a visszavonási összeget",
        title20: "kezelési költség",
        title21: "Valós érkezés",
        table1: "Valuta",
        table2: "Kivont érmék mennyisége",
        table3: "Láncnév",
        table4: "pénztárca címét",
        table5: "állapot",
        table6: "ok",
        table7: "alkalmazási idő",
        table8: "Érmék kivonása",
        table81: "Érmék kivonása",
        table9: "Kivételi cím",
        table10: "Kérjük, válassza ki a kivenni kívánt pénznemet",
        table11: "Kérjük, feltétlenül lépjen be",
        table12: 'Cím (általában "0x"-el kezdődik)',
        table13: "Kivehető érmék (kezelési díj levonása)：",
        table14: "Válassza ki a pénznemet",
        table15: "Problémákkal találkozik? Azonnali visszajelzés",
        table16: "Egyensúly",
        table17: "A visszavonás részletei",
        password: "Jelszó",
        passwordTips: "Kérjük, írja be a jelszót",
        passwordAg: "Jelszó megerősítése",
        passwordAgTips: "Kérjük, adja meg újra a jelszót",
        oldPassword: "régi jelszó",
        oldPasswordTips: "Kérjük, adja meg a régi jelszót",
        newPassword: "új jelszó",
        newPasswordTips: "Kérjük, adjon meg egy új jelszót",
        passwordNotSame: "A jelszavak következetlenek",
        withdrawPasswordSet: "Állítsa be a visszavonási jelszót",
        withdrawPasswordModify: "Változtassa meg a visszavonási jelszót",
        withdrawPassword: "Jelszó visszavonása",
        withdrawPasswordTips: "Kérjük, adja meg a visszavonási jelszót",
        setWithdrawPasswordFirst: "Kérjük, először állítson be egy visszavonási jelszót",
        withdrawPasswordSetSuccess: "Sikeres telepítés",
        withdrawPasswordModifySuccess: "Sikeresen módosítva",
        submit: "Beküldés",
    },
    customer: {
        content1: "online szolgáltatás",
        content2: "Vevőszolgálat",
        content3: "Kérjük, adja meg elérhetőségeit",
        content4: "Kérjük, adja meg a tartalmat",
        content5: "Küld",
        content6: "A küldés sikertelen, a hálózati csatlakozás sikertelen",
        content7: "kép",
        content8: "üzenet üres",
        content9: "az én részem",
        content10: "hivatkozás megosztása",
        content11: "kép mentése",
        content12: "a keresetemet",
        content13: "Szintleírás",
        content14: "Csapat mérete",
        content15: "Meghívó bevétel",
        content16: "Szabály leírása",
        content17: "tagsági szint",
        content18: "Az újratöltési összeg nagyobb, mint",
        content19: "Csapat szinten",
        content20: "Csapatszint és bevétel",
        content21: "Hogy megfeleljen a feltételeknek",
        content22: "Elosztási bevétel",
        content23: "szintű csapat",
        content24: "szint, meghívás",
        content25: "érvényes tagok",
        content26: "elsődleges jövedelem",
        content27: "másodlagos jövedelem",
        content28: "3. szintű jövedelem",
    },
    standardContract: {
        increaseMargin: "árrés növelése",
        increaseAmount: "mennyiség növelése",
        Pleaseenterthemargin: "Kérjük, adja meg a növelni kívánt árrés összegét",
        Pleaseenterthecorrect: "Kérjük, adja meg a helyes befizetés összegét",
        Increasemarginsuccessfully: "A margó sikeres növelése",
        takeProfitPriceWrong: "Vedd rosszul a profitárat",
        stoppriceerror: "Stop ár hiba",
        thetakeprofit1: "A vételárnak magasabbnak kell lennie, mint a piaci ár",
        thetakeprofit2: "A stop loss árnak alacsonyabbnak kell lennie a piaci árnál",
        thetakeprofit3: "A vételárnak alacsonyabbnak kell lennie a piaci árnál",
        thetakeprofit4: "A stop loss árnak magasabbnak kell lennie, mint a piaci ár",
        thetakeprofit5: "Rossz mennyiség",
        estimatedprofitloss: "Becsült nyereség és veszteség",
        positionAmount: "Pozíciók száma",
        closeAmount: "Záró mennyiség",
        whenthelatest: "A legfrissebb ár elérésekor",
        whenthelatest2: "akkor a piaci áras take-profit megbízás indul az aktuális pozíció lezárásához. Várható nyereség és veszteség",
        whenthelatest3: "akkor a piaci stop loss megbízás aktiválódik az aktuális pozíció lezárásához. Várható nyereség és veszteség",
        contract: "szerződés",
        ordercanceledsuccessfully: "Megrendelés sikeresen törölve",
        successfullyordered: "sikeresen megrendelt",
        positionopeningreminder: "Emlékeztető a pozíció megnyitására",
        wrongquantity: "Rossz mennyiség",
        quantitycannot: "A mennyiség nem lehet nagyobb, mint",
        modificationstoploss: "A stop profit és a stop loss módosítása sikeres volt",
        positionclosedsuccessfully: "A pozíció sikeresen lezárva",
        orderCancellation: "Rendelés lemondása",
        orderCancellation2: "Biztosan törölni szeretné a rendelést?",
        tobeexecuted: "Kivégezni",
        expired: "lejárt",
        failed: "nem sikerült",
        contractTransaction: "Örökös szerződés",
        increase: "Növekedés",
        openPosition: "Teljes raktár",
        isolatedPosition: "Elszigetelt helyzetben",
        limitOrder: "árhatár",
        marketOrder: "piaci ár",
        pleaseSelectLeverage: "Kérjük, válasszon tőkeáttételt",
        pleaseEnterQuantity: "Kérjük, adja meg a mennyiséget",
        canOpenMore: "Maximális nyitható pozíció",
        contractAmount: "Szerződéses összeg",
        openingFee: "kezelési költség",
        currentPosition: "Pozíció",
        historicalPositions: "Történelmi tranzakciók",
        currentCommission: "Aktuális rendelés",
        historicalCommission: "történelmi megbízás",
        buyPrice: "vételár",
        income: "nyereség",
        quantity: "Mennyiség",
        marginRate: "árrés mértéke",
        initialMargin: "Kezdeti margó",
        estimatedPriceParity: "Kényszer-felszámolási ár",
        operate: "működtet",
        finishQuantity: "Tranzakció mennyisége",
        averagePrice: "átlagos tranzakciós ár",
        pleaseEnterLeverage: "Kérjük, adja meg a tőkeáttételt",
        takeProfitAndStopLoss: "Vegyen profitot és állítsa le a veszteséget",
        takeProfit: "Szerezzen profitot",
        stopLoss: "veszteség megállítása",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Pozíció bezárása",
        marketLquidation: "A piaci ár változatlan",
        cancel: "Megrendelés törlése",
        enterBuyPrice: "Kérjük, adja meg a vételárat",
        areYouSureLquidation: "Biztosan bezárod a pozíciót?",
        confirm: "biztos",
        enterStopWinPrice: "Kérjük, adja meg a haszonvételi árat",
        enterStopLossPrice: "Kérjük, adja meg a stop loss árat",
        commissionTime: "Bizottsági idő",
        commissionPrice: "Bizományi ár",
        commissionQuantity: "A jutalékok teljes összege",
        volumeAlreadyTraded: "Elkészült összeg",
        areYouSureToWinOrLoss: "Biztos benne, hogy meg akarja állítani a nyereséget és a veszteséget?",
        maxQuantity: "Maximális pozíció",
        saleQuantity: "Pozíció zárva",
        salePrice: "átlagos záróár",
        saleTime: "idő",
        succeeded: "sikerült",
        cancelled: "Törölve",
        piece: "nyisd ki",
        triggerPrice: "kiváltó ár",
        setWin: "Állítsa be a profitot",
        setLoss: "Stop loss beállítása",
        enterWinQuantity: "Kérjük, adja meg a nyereség összegét",
        enterLossQuantity: "Kérjük, adja meg a stop loss összegét",
        planCommission: "Terv jutalék",
        enterWinTriggerPrice: "Kérjük, adja meg a profitot kiváltó árat",
        enterLossTriggerPrice: "Kérjük, adja meg a leállítási veszteség kiváltó árat",
        insufficientQuantity: "Elégtelen mennyiség",
        enterTriggerPrice: "Kérjük, adja meg az indulási árat",
        tips1: "Megrendelésének ára magasabb, mint a jelenlegi ár, és közvetlenül az aktuális piaci áron kerül végrehajtásra. Biztosan vásárolni szeretne?",
        tips2: "Megrendelésének ára alacsonyabb, mint a jelenlegi ár, és közvetlenül az aktuális piaci áron kerül végrehajtásra. Biztosan vásárol?",
        type: "típus",
        increaseQuantity: "Pozíció növelése",
        reduceQuantity: "Csökkentse a pozíciókat",
        limitCommission: "limites megbízás",
        tips3: "A stop loss árnak alacsonyabbnak kell lennie a piaci árnál",
        tips4: "A vételárnak magasabbnak kell lennie, mint a piaci ár",
        tips5: "A stop loss árnak magasabbnak kell lennie, mint a piaci ár",
        tips6: "A vételárnak alacsonyabbnak kell lennie a piaci árnál",
        tips7: "A stop loss árnak alacsonyabbnak kell lennie a piaci árnál és alacsonyabbnak kell lennie a rendelési árnál",
        tips8: "A take profit árnak nagyobbnak kell lennie, mint a rendelési ár (lehet kisebb is, mint a piaci ár)",
        tips9: "A stop loss árnak nagyobbnak kell lennie a piaci árnál és nagyobbnak kell lennie a rendelési árnál",
        tips10: "A take profit árnak kisebbnek kell lennie, mint a rendelési ár (lehet nagyobb, mint a piaci ár)",
        accountEquity: "Számlaegyenleg",
        unrealizedPL: "nem realizált nyereség vagy veszteség",
        tips11: "Biztos, hogy igazad van?",
        tips12: "Piaci ár",
        tips13: "Zárja be az összes pozíciót",
        tips14: "Ne emlékeztess újra",
        profitRate: "Záró megtérülési ráta",
        openTime: "Nyitvatartási idő",
        unwindTime: "Záróra",
        dealTime: "Tranzakciós idő",
        amountClose: "A zárt helyzet mennyisége",
        long: "sok",
        short: 'nulla',
        orderQuantity: "Vásárlás Mennyiség",
        entrustedMargin: "rábízott árrés",
    },
    spotGoods: {
        spotGoods: "Flash csere",
        sellPrice: "eladási ár",
        orderRecord: "Flash-beváltási rekord",
        transactionTime: "Tranzakciós idő",
        maxBuy: "megvásárolható",
        maxSell: "Eladó",
        purchaseQuantity: "Vásárlási mennyiség",
        slippage: "Csúszás",
        convertTips: "A tranzakciót a legkedvezőbb áron, 0-5%-os csúszással zárjuk.",
        changePrice: "Aktuális csereár",
        consume: "fogyaszt",
        consumeAmount: "Elfogyasztott mennyiség",
        get: "kap",
        getAmount: "Szerezzen mennyiséget",
        changeTips: "Biztosan be akarod váltani?",
        confirm: "A csere megerősítése"
    },
    news: {
        title: "Hírközpont",
        redMore: "Olvass tovább",
    },
    credit: {
        credit: "hitel pontszám",
        creditScore: "teljes hitelpontszám",
        latestEntry: "legújabb rekordja",
        liveUpdate: "Élő frissítés",
        systemEvaluation: "Rendszerértékelés",
        time: "dátum",
        name: "név",
        reason: "ok",
        scoreStory: "Hitel pontszám rekord"
    },
    borrowing: {
        borrowing: "hitel",
        diversifiedMarket: "Diverzifikált piacok",
        totalSupply: "Teljes kínálat",
        totalBorrowings: "teljes hitelfelvétel",
        tips1: "Ez az a teljes összeg, amelyet a fedezet ellenében felvehetsz, a hitelfelvételi limit erejéig.",
        tips2: "Stabil kamata a hitel futamideje alatt változatlan marad. Hosszú távú ellátásnak és a kiszámíthatóságot kedvelő felhasználóknak ajánlott.",
        totalSupplys: "teljes kínálat",
        supplyAPR: "ellátási ráta",
        totalBorrowings: "Teljes hitelfelvétel",
        borrowAPR: "hitelkamatláb",
        check: "Jelölje be",
        market: "piac",
        availableLiquidity: "Rendelkezésre álló likviditás",
        currencyPrice: "aktuális valutaár",
        supplyAssets: "eszközök ellátása",
        tips3: "Megjegyzés: Az alábbi kamatláb fix kamatláb Az ellátási időszak lejárta után a tőke plusz a bevétel visszakerül a számlájára.",
        supplyCycle: "ellátási ciklus",
        minimumSupply: "minimális kínálat",
        supply: "kínálat",
        borrowedAssets: "kölcsönvett eszközök",
        tips4: "Fontos tudnivaló: Pénz kölcsönzéséhez minden biztosítékot vagy bizonyítékot kell bemutatnia,",
        tips5: "Kérjük, tekintse meg a jelzálog-eszköz leírását.",
        repaymentCycle: "törlesztési ciklus",
        BorrowableAmount: "Rendelkezésre álló hitelösszeg",
        borrow: "hitel",
        accountInformation: "Számla információ",
        walletBalance: "pénztárca egyenleg",
        canSupply: "Elérhető",
        AmountCanBeBorrowed: "A rendelkezésre álló teljes hitelösszeg",
        borrowedAmount: "Kölcsönzött összeg",
        remainingAmount: "fennmaradó egyenleg",
        getBorrowednAmount: "Szerezzen hitelkeretet",
        supplyOrder: "ellátási sorrend",
        borrowedOrder: "hitelrendelés",
        supplyAmount: "kínálat mennyisége",
        expectedProfits: "Várható megtérülés",
        supplyTime: "Ellátási idő",
        expirationTime: "Lejárati dátum",
        borrowingAmount: "Hitelösszeg",
        dueRepayment: "Visszafizetés összege",
        borrowingTime: "Kiadás ideje",
        repaymentTime: "Törlesztési idő",
        repay: "visszafizetés",
        supplyCurrency: "kínálati valuta",
        buyNow: "Vásárolj most",
        supplyRecords: "ellátási nyilvántartások",
        borrowRecords: "Hitel nyilvántartások",
        completed: "elkészült",
        borrowNow: "Kérj kölcsön most",
        borrowCurrency: "Deviza kölcsönzése",
        borrowMin: "legkisebb",
        borrowMax: "maximális",
        borrowRange: "Hitelösszeg (minimális/maximum)",
        tips6: "Késedelmes visszafizetés esetén további napi összeg (tőke + kamat) kerül felszámításra.",
        tips7: "Lejárt díj.",
        pleaseEnterBorrowAmount: "Kérjük, adja meg a kölcsön összegét",
        interest: "Érdeklődés",
        tips8: "Biztos, hogy előre akar törleszteni?",
        borrowChannel: "Csatorna kölcsönzése",
        tips9: "Kezdjük azzal, hogy megismerünk",
        name: "név",
        lastName: "vezetéknév",
        birthDate: "születési dátum",
        tips10: "Még egy kis információ, mi a címed?",
        familyAddress: "Lakcím (beleértve az egység számát)",
        city: "Város",
        country: "nemzet",
        postalCode: "irányítószám",
        pleaseEnterYourHomeAddress: "Kérjük, adja meg otthoni címét",
        tips11: "Meséljen pénzügyi helyzetéről",
        tips12: "Előfordulhat, hogy később meg kell erősítenünk ezeket az információkat, ezért kérjük, próbáljon meg a lehető legpontosabb lenni",
        yourMonthlyIncome: "a havi bevételed",
        enterYourMonthlyIncome: "Kérjük, adja meg havi jövedelmét",
        extraIncomeOptional: "További jövedelem (opcionális)",
        enterExtraIncome: "Kérjük, adja meg további bevételét",
        monthlyRentOrMortgagePayment: "Havi bérleti díj vagy jelzáloghitel",
        enterMonthlyRent: "Kérjük, adja meg havi bérleti díját vagy jelzáloghitel-részletét",
        tip13: "Szóljon nekünk, ha van jelzáloghitele",
        tip14: "Ha nem választanak ki jelzáloggaranciát, kicsi a valószínűsége annak, hogy átmegy a felülvizsgálaton.",
        isThereMortgage: "Van jelzáloghitel?",
        selectIsThereMortgage: "Kérjük, válassza ki, van-e jelzáloghitel",
        selectMortgageType: "Kérjük, válassza ki a jelzáloghitel típusát",
        yes: "Igen",
        no: "nem",
        uploadScreenshotOfTransaction: "Kérjük, töltsön fel a tranzakciós képernyőképeket",
        tip15: "Tartsuk a kapcsolatot",
        tip16: "Ezen a számon felvehetjük Önnel a kapcsolatot, ha további információra van szükségünk hitelkérelmével kapcsolatban",
        tip17: "Hozzájárulásomat elolvastam és elektronikusan aláírtam",
        tip18: "Felhasználási feltételek és elektronikus hozzájárulás",
        tip19: "Ez magában foglalja az Ön hozzájárulását ahhoz, hogy információkat küldjünk Önnek a rólunk",
        tip20: "Termék- és szolgáltatáshasználati feltételek",
        tip21: "Az adatok kitöltése után várja meg a rendszer kiértékelését és áttekintését.",
        phoneNumber: "telefonszám",
        enterPhoneNumber: "Kérlek add meg a telefonszámod",
        tip22: "Kérjük, jelölje be a négyzetet a használati feltételek és az elektronikus hozzájárulási űrlap elfogadásához",
        inReview: "Felülvizsgálat alatt",
        applicationAmount: "Pályázati összeg",
        reasonForRejection: "Elutasítás oka",
        reapply: "Jelentkezzen újra",
        applyStatus: "alkalmazás állapota",
        currentOrder: "aktuális sorrend",
        interestRate: "kamatláb"
    },
    yuebao: {
        filed1: "befektetni",
        filed2: "vegye ki",
        filed3: "Napi bevétel",
        filed4: "csapatgyorsulás",
        filed5: "Letétbe helyezve",
        filed6: "A tegnapi bevétel",
        filed7: "Összes bevétel",
        filed8: "Elérhető alapok",
        filed9: "Kérjük, adja meg az összeget",
        filed10: "Likviditási bányászat",
        filed11: "Bevezetés",
        field12: "Minimális vételár",
        field13: "A legtöbb, amit megvásárolhat",
        filed14: "Feliratkozás",
        filed15: 'szabályos',
        filed16: 'évesített kamatláb',
    },
    tipsPopover: {
        title: "Telepítse ezt az alkalmazást",
        press: "alapján",
        and: "és",
        scrren: "Hozzáadás a kezdőképernyőhöz",
        hasInstall: "(Ha telepítve van, zárja be az ablakot)",
    },
    follow: {
        title1: "Nyomon követési terv",
        follow_text1: "Nyomon követési rendelés kezdeményezése",
        follow_text2: "Kiadási előzmények",
        follow_text3: "cím",
        follow_text4: 'kereskedési pár',
        follow_text5: 'Vásárlás időtartama',
        follow_text6: 'rendelési idő',
        follow_text7: 'kiadás ideje',
        follow_text8: 'Követők száma',
        follow_text9: 'Rendelési mennyiség',
        follow_text10: 'működtet',
        follow_text11: 'meghív',
        follow_text12: 'Kövesse a rendelési utasítást',
        follow_text13: 'Állítsa be a rendelési feltételeket',
        follow_text14: 'A következő rendelések feltételei',
        follow_text15: 'állandó mennyiség',
        follow_text16: 'százalék',
        follow_text17: 'Meghívott emberek',
        follow_text18: 'A másik fél azonosítója',
        follow_text19: "Erősítse meg a sorrend követését",
        follow_text20: "A rendelés összege (opcionális)",
        follow_text21: "Kérjük, adjon meg egy címet",
        follow_text22: "Kérjük, válasszon kereskedési párt",
        follow_text23: "Kérjük, válassza ki a rendelés irányát",
        follow_text24: "Kérjük, válassza ki a vásárlás időtartamát",
        follow_text25: "Kérjük, válassza ki a rendelési időt",
        follow_text26: "Kérjük, válassza ki a vásárlási mennyiséget",
        follow_text27: "A maximális vásárlási mennyiség nem lehet kevesebb, mint a minimális vásárlási mennyiség",
        follow_text28: "Minimális nevezési díj",
        follow_text29: "A legtöbb vásárol",
        follow_text30: "Másolási rendelés létrehozása sikeres volt",
        follow_text31: "Kereskedési pár lista",
        follow_text32: "Már követte a parancsot",
        follow_text33: "Kérjük, adja meg a meghívott adatait",
        follow_text34: "Meghívott",
        follow_text35: "meghívtak",
        follow_text36: "Előzmények másolása",
        follow_text37: "Rendelési mennyiségi tartomány"
    },
    followHistory: {
        title1: 'Kiadási előzmények',
        title2: "Előzmények másolása",
        follow_h_text1: "A rendelés teljes összege",
        follow_h_text2: "A rendeléseket követő személyek teljes száma",
        follow_h_text3: "Kiadások száma",
        follow_h_text4: "nyerési arány",
        follow_h_text5: "Az utasítások követésének száma",
        follow_h_text6: "Nyereség a következő megrendelésekből",
        follow_h_text7: "irány",
        follow_h_text8: "győzelem",
        follow_h_text9: "hiány",
    },
    "asset.index.1579E8B41F8D5134": "vagyonomat",
    "asset.index.B3F6F05Dfbe431Cd": "szimuláció",
    "asset.index.B9Aeab4Ee8F33746": "Eszközértékelés",
    "asset.index.583Df8Bd541Eb7E8": "bevételi trendek",
    "asset.index.B1254815D133Cc26": "7 nap",
    "asset.index.2F615F6C029F01B7": "30 nap",
    "asset.index.29439Fb58E4Bbe22": "visszavonulni",
    "asset.index.831Ab31568A78285": "feltölt",
    "asset.index.39F7A0D48D12E8C9": "átruházás",
    "asset.index.B99063C5689Beaec": "Flash csere",
    "asset.index.E1Dc620F2F038B2A": "Eszközök fogadása",
    "asset.index.972A0B4C2B4393D0": "a fiókom",
    "asset.index.9Dfb0Fb334620733": "Rendelkezésre álló egyenleg",
    "asset.index.Cdc5Fbd26790Cb47": "fagyasztott mennyiség",
    "asset.index.3D8132D72Ae965Cd": "Értékelés",
    "asset.receive.825503Cc430559Cb": "eszközöket szerezni",
    "asset.receive.54154A0553A535B7": "Demo eszközök",
    "asset.receive.F2E57B00A9C5D129": "1.Ez az eszköz csak demószámla módban használható",
    "asset.receive.Ea0D1D52989299Bd": "2.A visszaélések megelőzése érdekében egy hónapon belül csak egy beváltás engedélyezett.",
    "asset.receive.090E0037Aa235D3D": "3.Az eszközök átvétele hozzáadja az eredeti alapon kapott eszközök összegét, és nem változtatja meg a pozíció állapotát.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Ha további kérdése van, forduljon az ügyfélszolgálathoz",
    "asset.receive.Df5B3A511D986575": "Szerezzen be demóeszközöket most",
    "asset.records.1Fa02E8187A39592": "elmúlt három hónap",
    "asset.records.7B291C37333Fe34D": "közelről távolra",
    "asset.records.A476Cd1Ec868Fc5F": "elmúlt három hónap,",
    "asset.records.9D5755A842E55Bb7": "közelről távolra,",
    "asset.records.E3Fa05E607203Cdc": "feltölt",
    "asset.records.B4F206C6Bec08Dea": "visszavonulni",
    "asset.index.Df5B3A511D986576": "Sikeresen megkapta a demóeszközöket",
    "deposit.depositSpeedy.Efe81D0E0174239B": "fizetési mód",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Fiat valuta",
    "deposit.depositSpeedy.16658A855F07D171": "Összeg",
    "deposit.depositSpeedy.204Ffab8A6E01870": "biztos",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Nem lehet üres",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Minimális újratöltés",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Maximális újratöltés",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "USDT befizetés",
    "deposit.depositWallet.551640C76F4Ca4A1": "Valuta feltöltése",
    "deposit.depositWallet.B404C543712B71D5": "Töltő hálózat",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Letéti cím",
    "deposit.depositWallet.D3980D54Acf242Db": "Mentse el a QR-kódot",
    "deposit.depositWallet.A06E84801D6582Be": "Feltöltés összege",
    "deposit.depositWallet.Daaaeb1B7B22B126": "célzás",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Az újratöltés sikeres",
    "deposit.depositWallet.4F48Abbafce532Bb": "Sikeresen elküldve, ügyfélszolgálati véleményre vár",
    "deposit.depositWallet.A88C481228811696": "Ön elutasította a hálózatváltást",
    "deposit.depositWallet.C6Ebf27895C21932": "Nyissa meg manuálisan a TRON Wallet beépülő modult, és jelentkezzen be",
    "deposit.index.B5491C8Dd09D8183": "Válassza ki a feltölteni kívánt pénznemet",
    "deposit.index.2F784B2298D39987": "Gyors újratöltés",
    "deposit.index.0Daa03Dcdcabd837": "Offsite linkek",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Visszaigazolás",
    "withdraw.bottomSheet.A086Fa20082C849D": "Kérjük, győződjön meg arról, hogy az alábbi adatok helyesek",
    "withdraw.bottomSheet.Bf8B184D32971501": "Kivonási hálózat",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "cím",
    "withdraw.bottomSheet.F9Db93B87E08763B": "Mennyiség",
    "withdraw.bottomSheet.4Ad26475002878C0": "kezelési költség",
    "withdraw.index.A96454041169Af6D": "Válassza ki a pénznemet",
    "withdraw.index.2A563B7081Dd5B83": "Kivételi cím",
    "withdraw.index.B189C2Ef713Cfff4": "Kivont érmék mennyisége",
    "withdraw.index.558F35140E84B562": "Elérhető：",
    "withdraw.index.F4E759087E12Cb77": "Vedd vissza az összeset",
    "withdraw.index.5Cbd9A6B284D25Ae": "Alap jelszava",
    "withdraw.index.B89Cbe4Bfc33Bb41": "kezelési költség:",
    "withdraw.index.C886De8D8A27026E": "Fontos figyelmeztetés",
    "withdraw.index.Eac7C5A4095F5606": "1.Az arbitrázs elkerülése érdekében kérhet devizakivonást, amikor a tranzakciós mennyiség eléri a kvótát.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.Az elállási kérelem a benyújtástól számított 24 órán belül jóváírásra kerül.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.A visszavonási kérelem benyújtását követően a pénzeszközök befagyott állapotban vannak, mert a pénzfelvétel folyamatban van, és a pénzeszközök átmenetileg a rendszerben vannak.",
    "withdraw.index.4639F3A6E07C00B3": "Következő lépés",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Kérjük, adja meg vagy illessze be pénztárca címét",
    "withdraw.index.25768141C8Bdea91": "Kérjük, adja meg a visszavonási összeget",
    "withdraw.index.97A85734Cd1B7Bf0": "Kérjük, adja meg az alap jelszavát",
    "withdraw.index.39C7D74B33241C78": "A visszavonási összeg hibás",
    "withdraw.index.86A5D17908197525": "A visszavonás megerősítése, az érmék kiadásának felülvizsgálatára várva",
    "withdraw.index.48Fb603C6638C7F6": "Kérjük, állítson be egy alapjelszót",
    "withdraw.index.873Ade9A3Bacc2F7": "célzás",
    "bill.index.5Bbb2A6Ac68D6B2E": "tényleges számla",
    "bill.index.75751E0Eb8261C40": "Demo fiók",
    "bill.index.Cbc853B37Ecc2E97": "Számlamódosítás típusa",
    "bill.index.4A0Cacddeb2E38B1": "Fiók Típus",
    "bill.index.F16217028E098B2F": "Valuta",
    "bill.index.A620Dbe6F06B78Ba": "Minden típus",
    "bill.index.2C3C148Bb94D9B3F": "Minden fiók",
    "c2C.index.Ab45695E4Bf1E382": "C2C tranzakció",
    "c2C.index.2702Dc911Dfea762": "szeretnék vásárolni",
    "c2C.index.D737D5B8367A53C4": "el szeretném adni",
    "c2C.index.C0C540A3Ba9Abe01": "Hangerő",
    "c2C.index.D8644E8D60C8Bd8A": "egységár",
    "c2C.index.Ebb8670B203D3604": "határ",
    "c2C.index.F8C6245A2Ebf1D27": "Minden bankkártya",
    "c2C.index.F8C6245A2Ebf1D28": "valutagyűjtés",
    "c2C.index.F8C6245A2Ebf1D29": "Bank kártya",
    "c2C.index.Cc3Aa8141Da9Ec9E": "megvesz",
    "c2C.index.Cc3Aa8141Da9Ec9F": "elad",
    "c2C.index.67A39834582Ad99A": "Nincs adat",
    "c2C.order.04C23810B47F77F7": "mennyiség szerint",
    "c2C.order.6905365902E0A73D": "mennyiség szerint",
    "c2C.order.3F0Ecebbd9D2F23B": "Elérhető",
    "c2C.order.16853Bda54120Bf1": "minden",
    "c2C.order.Ad3335796F2C95F7": "átalány",
    "c2C.order.61092E27A6879318": "Az eszközök biztonságának megóvása érdekében kérjük, fokozza a megelőzési tudatosságot!",
    "c2C.order.0F51881F0Fb920B2": "Kereskedési információk",
    "c2C.order.Dd74Ceca85E5Bc65": "Fizetési határidő",
    "c2C.order.De3341388A59073F": "30 perc",
    "c2C.order.D334B597A352167F": "Az eladó beceneve",
    "c2C.order.D334B597A352167G": "Vevő beceneve",
    "c2C.order.9D5F89F99Ced890D": "tranzakció eszköze",
    "c2C.order.8Adf755B14Bc6B7C": "Kérjük, először olvassa el a következőket",
    "c2C.order.686B16F454A7338B": "Bankkártyás átutaláskor ne tegyen megjegyzést, ellenkező esetben a pénz nem kerül felszabadításra, és a számla közvetlenül zárolásra kerül. Fizetés után meg kell adnia egy új tranzakció részleteit diagramot a fizetés után (ha a P diagramot vagy a rejtett tranzakciós adatokat jelentették a platformnak a számla zárolásához)",
    "c2C.order.9Bec8Fba867B739B": "Válassza ki a fizetési módot",
    "c2C.order.Cf2D892C419Fea36": "minimális vásárlás",
    "c2C.order.7290A6B7456A03E0": "legnagyobb vásárlás",
    "c2C.orderConfirm.F7E99B8C203E2B07": "megrendelés részletei",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Megrendelés létrejött",
    "c2C.orderConfirm.0F5433503F3845Ce": "Kérjük, fizessen az eladónak a visszaszámlálási időn belül.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Várom a vevő fizetését ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "kapcsolatfelvétel az eladóval",
    "c2C.orderConfirm.8E8140D7Ab607B25": "lépjen kapcsolatba a vevővel",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Az eladó digitális pénzneme most a letéti számlán van elhelyezve, kérjük, fizessen nyugodtan  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "A vevő valódi neve：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24 órás ügyfélszolgálat ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "Rendelésszám",
    "c2C.orderConfirm.726C51702F70C486": "teremtési idő",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Lépjen kapcsolatba az Ügyfélszolgálattal",
    "c2C.orderConfirm.103408Fbbe30287F": "Rendelés lemondása",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Megerősítettem a fizetést",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Fizetve",
    "c2C.orderConfirm.387Ca3625B260E4E": "Kifizetett rendelésed",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "tranzakció törlése",
    "c2C.orderConfirm.76C86566Cc3751C0": "Megrendelés törölve",
    "c2C.orderConfirm.205B349751D176B4": "Sikeres fizetés",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "fizetési mód",
    "c2C.paymentAdd.C87E865Df6800304": "Fizetési mód beállítása",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Fizetési mód típusa",
    "c2C.paymentAdd.06910Bddf002695F": "tényleges név",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Paypal számla",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Bankszámlaszám/kártyaszám",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Pénznem neve",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Fizessen QR kódot",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Kattintson a Feltöltés gombra",
    "c2C.paymentAdd.6Daae340F2E9718A": "a bank neve",
    "c2C.paymentAdd.347751415C3Da489": "Láncnév",
    "c2C.paymentAdd.15B3627Faddccb1D": "Megjegyzés",
    "c2C.paymentAdd.86Da96Cbd274058F": "Meleg emlékeztető: Amikor digitális valutát ad el, a választott fizetési mód megjelenik a vevő számára. Kérjük, ellenőrizze, hogy az adatok pontosan vannak-e kitöltve.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "megment",
    "c2C.paymentAdd.90D2A3A5C07179C0": "kérjük, adja meg valódi nevét",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Kérjük, adja meg a sajátját",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Kérjük, adja meg banknevét",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Kérjük, adja meg a lánc nevét",
    "c2C.paymentAdd.94F034F0E01439E0": "Kérjük, adja meg a címét",
    "c2C.paymentAdd.B12E035663A42B32": "Minden bankkártya",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "valutagyűjtés",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Kérjük, töltse fel a fizetési kódot",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Sikeresen hozzáadva",
    "c2C.paymentDetail.86Da96Cbd274058F": "Meleg emlékeztető: Amikor digitális valutát ad el, a választott fizetési mód megjelenik a vevő számára. Kérjük, ellenőrizze, hogy az adatok pontosan vannak-e kitöltve.",
    "c2C.paymentDetail.C4C613Da60226De4": "Sikeresen módosítva",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C fizetési mód",
    "c2C.records.9E36893D5736Abb4": "Rendelési lista",
    "c2C.records.4B53F9A5B13B3235": "Függő fizetés",
    "c2C.records.4B53F9A5B13B3236": "tranzakció törlése",
    "c2C.records.4B53F9A5B13B3237": "Fizetve",
    "contract.index.9706Df877458F0Be": "Teljes raktár",
    "contract.index.623Fc7D3D40Aa479": "Kezdeti margó",
    "contract.index.F92Dce1169330387": "A piaci ár változatlan",
    "contract.index.3598B97Dd28Dbf20": "Kérjük adja meg a mennyiséget (db)",
    "contract.index.6E1Fae821Dc0B797": "Fenntartható",
    "contract.index.Eb07Eb0C37C14575": "Szállítás",
    "contract.index.Eb6492F19B1C1Fb6": "A tranzakciók korábbi részletei",
    "contract.index.873Ade9A3Bacc2F7": "célzás",
    "contract.index.Ff63B0F93D39F50B": "Biztos, hogy igazad van?",
    "contract.index.Ff63B0F93D39F50D": "Menj sokáig",
    "contract.index.Ff63B0F93D39F50E": "rövid",
    "contract.index.Ff63B0F93D39F50F": "magatartás",
    "contract.index.Ff63B0F93D39F50G": "Bezárja az összes pozíciót?",
    "customer.dialog.3D410C88E8A24Fe8": "Becenév megerősítése",
    "customer.dialog.4B3340F7872B411F": "Megszünteti",
    "customer.dialog.549B1456Bb5F98B3": "Kérjük, adja meg becenevét",
    "customer.dialog.B773B3E45Ccfad00": "A becenév üres",
    "customer.index.7308C6B849D159E9": "Kép részletei",
    "delivery.callDialog.C74F562C3Fe91Fe3": "sikeresen megrendelt",
    "delivery.callDialog.B8C98F42Caa15Ca5": "irány",
    "delivery.callDialog.B79A581Dae543974": "hosszú vásárlás",
    "delivery.callDialog.B79A581Dae543975": "rövid vásárlás",
    "delivery.callDialog.3B26F0F7E90773F3": "Nyitási ár",
    "delivery.callDialog.65Fb252Bd94E1039": "Becsült bevétel",
    "delivery.index.B483Ba4Fce633539": "Szállítás",
    "delivery.index.224Dd6Cb58D58249": "kereskedési időszak",
    "delivery.index.28158F517E902788": "Minimális nevezési díj",
    "delivery.index.A1232162B14Bec76": "Szállítási rendelés",
    "delivery.index.445B1D3176D9C69D": "Történelmi rendek",
    "delivery.index.Ab6Cb86E28653C5C": "Szállítás",
    "delivery.index.20A7A6F14D3698C0": "Nyitvatartási idő",
    "delivery.index.Ec6821A49395Ddc2": "Záróra",
    "delivery.index.254Fdc665C391743": "Záró ár",
    "delivery.index.2A0C5E68B44A472A": "Haszon és veszteség",
    "delivery.index.E05B33309F6D236A": "Kérjük, adja meg a mennyiséget",
    "delivery.index.B2899E073Cb355D9": "Minimális vásárlás",
    "download.description.869467A93C7Dd4A1": "Telepítési megjegyzések",
    "download.description.C80C1Edd6Bee12Bc": "1.Leíró fájlok letöltésének engedélyezése",
    "download.description.2F5Bc180F92642B4": "2.Leíró fájl letöltve",
    "download.description.3C3B849F6Ccf712F": "3.Írja be a [Beállítások] menüpontot a telefonján, és a letöltött leíró fájl megjelenik a fiókadatok alatt.",
    "download.description.86Affd862985E045": "4.Kattintson a leíró fájl letöltéséhez",
    "download.description.32832492Bfc1D95E": "5.Kattintson a telepítéshez",
    "download.index.245883C80F181C4A": "Letöltés",
    "download.index.Ef3De8Cb2722937A": "Kezdje el a kereskedést bármikor, bárhol",
    "download.index.F4A87Eaeed691638": "Legyen naprakész a legfrissebb hírekről az APP-n keresztül",
    "download.index.Ad3Aba52D0D39D01": "Töltse le a telepítőcsomagot",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Kedves tippek",
    "download.safariGuide.2Cc68Aaadf623477": "Kérjük, látogasson el a Safariba",
    "download.safariGuide.C80Fff2A6Ff4E768": "Ön egy decentralizált webhelyet látogat meg, kérjük, futtassa a safari alkalmazást",
    "download.safariGuide.06Da016F3E2F79Bd": "link másolása",
    "finance.index.B034B367545D5236": "Globális magas színvonalú projektek",
    "finance.index.6Ebfac2C0A9228B0": "1,6 millió+ aktív felhasználó, 122,4 milliárd dollár",
    "finance.index.52Bdcaf19659363D": "Likviditási bányászat",
    "finance.index.D66924272B031Bf5": "Szuper magas éves jövedelem",
    "finance.index.34789D88Afcefa64": "Elemek megtekintése",
    "finance.index.E12676B95654D21E": "hitel kölcsön",
    "finance.index.4C924Cfd89A11Ac7": "Fedezzen fel további kereskedési lehetőségeket",
    "finance.index.C251D4B594Da2848": "AI számszerűsítés",
    "finance.index.4E53Cf2E5F173Dd3": "Egykattintásos lízing, AI intelligens tranzakció",
    "finance.records.Cce168301447D1Ba": "folyamatban",
    "finance.records.4587B6Bd7Cfa4454": "Megváltva",
    "finance.records.18685418E3A50147": "Bányászati",
    "finance.records.Df9Fd438F97Cc908": "ég",
    "finance.records.E23Cb03C5Ad359E5": "a kifejezést",
    "finance.records.Bd87Dac6Cba7Eba8": "Vásárlási mennyiség",
    "finance.records.2D0Fff99265E8B1A": "Jövedelmező",
    "finance.records.2D0Fff99265E8B1B": "Profit lejáratkor",
    "finance.records.024F5F0B851782De": "Bányászati ​​idő：",
    "finance.records.3143A26D3F983896": "megváltási idő：",
    "finance.records.96574Aaf8Db85Ead": "korai megváltás",
    "finance.records.531E1829203181Ac": "Biztos, hogy korán be akarod váltani?;",
    "finance.records.9205843B8923Dac3": "A beváltás sikeres;",
    "loan.applyCallback.B9258Ec8746A74D8": "Csatorna kölcsönzése",
    "loan.applyForm.7B8Ed340400A6E2B": "név",
    "loan.applyForm.549A44Bae725F4A2": "vezetéknév",
    "loan.applyForm.2A27E61375E7Fea8": "születési dátum",
    "loan.applyForm.89925B019D537738": "email cím",
    "loan.applyForm.Afaeb32Cb4D62E28": "Lakcím (beleértve az egység számát)",
    "loan.applyForm.E05B5D049B64B040": "Város",
    "loan.applyForm.B166E4E8Fe9513Fa": "nemzet",
    "loan.applyForm.83Aaede109E395F8": "irányítószám",
    "loan.applyForm.647Ed21332A4968B": "a havi bevételed",
    "loan.applyForm.Cf0A9B845D34D4B4": "További jövedelem (opcionális)",
    "loan.applyForm.7048F618Acc6A54C": "Havi bérleti díj vagy jelzáloghitel",
    "loan.applyForm.15C1Bba18Ae18545": "Szóljon nekünk, ha van jelzáloghitele",
    "loan.applyForm.3921E90625A84E36": "Ha nem választanak ki jelzáloggaranciát, kicsi a valószínűsége annak, hogy átmegy a felülvizsgálaton.",
    "loan.applyForm.262Eec4F8906B0F6": "Van jelzáloghitel?",
    "loan.applyForm.8634Eb21B6910F21": "Kérjük, válassza ki a jelzáloghitel típusát",
    "loan.applyForm.A4897B3A331B5E61": "Tranzakciós utalvány",
    "loan.applyForm.6623384C683De457": "Tartsuk a kapcsolatot",
    "loan.applyForm.A8360Bdba86834Cb": "Ezen a számon felvehetjük Önnel a kapcsolatot, ha további információra van szükségünk hitelkérelmével kapcsolatban",
    "loan.applyForm.C1C373F385A4C7B3": "telefonszám",
    "loan.applyForm.1B9785Badb91191F": "Az adatok kitöltése után várja meg a rendszer kiértékelését és áttekintését.",
    "loan.applyForm.8755B9C761D4080C": "Hozzájárulásomat elolvastam és elektronikusan aláírtam",
    "loan.applyForm.3E987B787468148C": "Felhasználási feltételek és elektronikus hozzájárulás",
    "loan.applyForm.Bc54E76B61C50Fb9": "Kérjük, olvassa el és fogadja el a megállapodást",
    "loan.applyForm.89E158384F634Eb8": "Visszatérés az előző lépéshez",
    "loan.applyForm.Efa5A4548E2F3510": "Lakcím",
    "loan.applyForm.4Cadf1787541B35D": "Igen",
    "loan.applyForm.Fab408D2F4100447": "nem",
    "loan.applyForm.3Bad7A307480Cc56": "Az e-mail formátuma rossz;",
    "loan.applyForm.306322F49B36855D": "A kérelmet benyújtották, kérjük, várja meg az ügyfélszolgálati véleményt",
    "loan.applyForm.3C6E0E858C995Ff9": "Kérjük, töltse fel a tranzakciós utalványt",
    "loan.applyForm.2Bf443476B271376": "Legfeljebb 4 kép tölthető fel",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Csatorna kölcsönzése",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Szerezzen hitelkeretet",
    "loan.applyQuota.9E0780F9352C7273": "pénztárca egyenleg",
    "loan.applyQuota.Cbc1Eb9D639A6105": "A rendelkezésre álló teljes hitelösszeg",
    "loan.applyQuota.033A05462195B080": "Kölcsönzött összeg",
    "loan.applyQuota.9Aa89B036Ef4821D": "fennmaradó egyenleg",
    "loan.applyQuota.5656Fb52Ef17A557": "Szerezzen hitelkeretet",
    "loan.applyQuota.6Ff6702B4C917D40": "Felülvizsgálat alatt",
    "loan.applyQuota.6Ff6702B4C917D41": "elutasítva",
    "loan.applyQuota.6Ff6702B4C917D42": "sikerült",
    "loan.applyQuota.0Bad0E857Ea4A952": "alkalmazási idő",
    "loan.applyQuota.3E7E56E2De2Cc546": "Pályázati összeg",
    "loan.applyQuota.8F1D170D9E54Af5B": "Az alkalmazás folyamata",
    "loan.applyQuota.6167Ca035B93Caf3": "ok",
    "loan.applyQuota.410C7954F9A3981D": "Jelentkezzen újra",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Kérjük, válasszon pénznemet",
    "loan.index.4C10307B22E885C3": "Fedezzen fel további kereskedési lehetőségeket és teremtsen gazdagságot",
    "loan.index.Ae8D37Dea2B3717B": "kamatláb",
    "loan.index.1Bb54625682E85Db": "Szabály bevezetés",
    "loan.index.5Bd31Fab681C6E9D": "többet látni",
    "loan.index.111F95F404814327": "Kölcsön pénzneme",
    "loan.index.6Bc2723B3Bec106D": "hitel",
    "loan.index.3200106280E6E370": "kamatláb",
    "loan.index.049A6Ccb6C81A635": "Kölcsön összege (min/max)",
    "loan.productDetails.5037Ad86Ab4B7E02": "hitel",
    "loan.productDetails.37181C74559Db182": "Rendelés ",
    "loan.productDetails.5802Aaf06A1E4755": "Likviditás",
    "loan.productDetails.89Aea9Cf9337C40F": "Kölcsönözhető mennyiség",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Visszafizetendő mennyiség",
    "loan.productDetails.C65F329Ef14C4A3D": "A kölcsönzött érmék mennyisége",
    "loan.productDetails.3A74667815Adc8Bf": "maximális",
    "loan.productDetails.Ab851D8351573227": "ég",
    "loan.productDetails.Ddf82D8A2C8F46A0": "törlesztési ciklus",
    "loan.productDetails.C105E5562005Aeb1": "ég",
    "loan.productDetails.3F346Be2137F0756": "Kölcsön összege (min/max)",
    "loan.productDetails.2707F78Df864E194": "Érdeklődés",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Késedelmes visszafizetés esetén további napi összeg (tőke + kamat) kerül felszámításra.",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% késedelmi díj",
    "loan.productDetails.4D94Faa033Be7356": "beleegyeztem",
    "loan.productDetails.62429A957A22780A": "Érmekölcsönzési szolgáltatási szerződés",
    "loan.productDetails.Bc54E76B61C50Fb9": "Kérjük, olvassa el és fogadja el a megállapodást",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Kérj kölcsön most",
    "loan.productDetails.59246A0Bf081D398": "legkevésbé",
    "loan.productDetails.Ec3F1172B71Cd36C": "minimális mennyiség",
    "loan.productDetails.7Ccc4Cf1D81930B3": "legnagyobb összeget",
    "loan.productDetails.071488598990Aa68": "Kérjük, olvassa el és fogadja el a megállapodást;",
    "loan.productDetails.579Dd216D3339119": "Sikeres kölcsön;",
    "loan.records.4951B36Fd0D2A831": "Hitel- és hiteltörténet",
    "loan.records.Bb0Aaa30514B7Cba": "aktuális rekord",
    "loan.records.0F8B0A7D80769051": "Törlesztési előzmények",
    "loan.records.D4B42E43D88De6F1": "történelem rekord",
    "loan.records.Cb1847D6281897E6": "Hitelösszeg",
    "loan.records.C7Bfcacfc2Adea58": "Visszafizetés összege",
    "loan.records.8B369164D5Fea340": "Kölcsönzési idő：",
	"loan.records.Ab2402Ad3Ffd35E7": "Lunasi pinjaman lebih awal",
    "loan.records.B7A0Ca0C195D0Cd1": "Kiadás ideje：",
    "loan.records.42014Bdcfd9C411B": "Törlesztési idő：",
    "loan.records.A0Acfaee54F06458": "elkészült",
    "loan.records.67A39834582Ad99A": "Nincs adat",
    "loan.records.6A687Ff5B8F1598F": "Biztos, hogy előre akar törleszteni?",
    "loan.records.7C20D7008D0Eae86": "Sikeres visszafizetés",
    "mining.bottomSheet.Ba91637D32Ccba04": "Likviditási bányászat",
    "mining.bottomSheet.7De3D636A165E008": "megtérülési ráta",
    "mining.bottomSheet.F3003345B189D683": "Bányászati ​​időszak",
    "mining.bottomSheet.3E4C0A8C533F5848": "érme típus",
    "mining.bottomSheet.Adcba368993Ae65E": "Előfizetés összege (minimum/maximum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Korlátlan",
    "mining.bottomSheet.286944Fedd211257": "Elérhető alapok",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Becsült bevétel:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Az enyém most",
    "mining.bottomSheet.Bb1493C567C114Dd": "Kedves tippek：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Korai visszaváltási díj",
    "mining.index.8B2Bba1422Eed6F0": "Hozzon létre több tízmilliárd vagyont, 100%-os nettó jövedelmet",
    "mining.index.42997E300086Ee89": "legmagasabb hozam",
    "mining.index.510F74F42052Ecce": "Bányászati ​​termékek",
    "mining.index.B853F96626106912": "Menj bányászni",
    "mining.productList.37181C74559Db182": "Rendelés",
    "mining.productList.Aac5B810004C46C8": "szabályos",
    "mining.productList.D2Fb4F78C387D888": "Sikeresen bányásztál",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Rendelés megerősítése",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Bérleti összeg",
    "quantify.bottomSheet.Fbd931286Be387Aa": "ciklus",
    "quantify.bottomSheet.9Ad84Db481807844": "határozatlan ideig",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Napi megtérülési ráta",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "értéknap",
    "quantify.bottomSheet.88008603Ba7Bde64": "Sikeres vásárlás",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Mostantól bevételrészesedés！",
    "quantify.bottomSheet.C778A674C4A054F9": "ellenőrizze a rendelést",
    "quantify.bottomSheet.685Dad322B7029B0": "Visszáru tranzakció",
    "quantify.buyForm.Acf8560A04334E95": "beszerzési ár",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Áttekintés",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Osztalékfizetési idő",
    "quantify.buyForm.Fcda03Adbc5Fca97": "minden nap",
    "quantify.buyForm.Afb89504Fc399189": "Kérjük, adja meg a vásárlási mennyiséget",
    "quantify.buyForm.1435Afdfb4Ec0596": "Minimális vásárlási mennyiség",
    "quantify.buyForm.1B97F1730Bacd839": "Maximális vásárlási mennyiség",
    "quantify.index.32F56C9B7D0E250A": "Időtartam: határozatlan",
    "quantify.index.E34F028D00904630": "ár",
    "quantify.index.E34F028D00904631": "Napi megtérülési ráta",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Letéti összeg",
    "quantify.productDetail.53635B53Bca31Dfa": "Becsült napi bevétel",
    "quantify.productDetail.F9E46449A6269E09": "Halmozott jövedelem",
    "quantify.productDetail.98Fc46B1B8C993B2": "Tárhely rendelése",
    "quantify.productDetail.93624Bb045830966": "Béreljen most",
    "quantify.records.0C16A18C32B9Cf4A": "Rendelési rekord",
    "quantify.records.B191F5614B0E2312": "Vásárlási előzmények",
    "quantify.records.6A06033B86A73F7C": "megváltási jegyzőkönyv",
    "quantify.records.45E0F7E71611618E": "Halmozott jövedelem",
    "quantify.records.45E0F7E71611618F": "Profit lejáratkor",
    "quantify.records.Aae1201E38C4Baaa": "Futás",
    "flash.index.C41Fd4F2Fdfa594B": "fogyaszt",
    "flash.index.1Ac59Fa2218E3B00": "Elérhető:",
    "flash.index.Bf7233A660821549": "kap",
    "flash.index.548E775Dd67C7472": "A csere megerősítése",
    "flash.index.066A2449Bd616F03": "Kérjük, adja meg a beváltási mennyiséget",
    "flash.index.Fca26Fcf9C06563F": "A Flash-beváltás sikeres",
    "message.index.40031524233F4564": "Üzenetek",
    "message.index.8E5586210Fd44D69": "Örökös pozíció emlékeztető",
    "message.index.Bcd3F470E88C197D": "Még nincs értesítés",
    "message.index.23A557Fbce545879": "Végleges felszámolási értesítés",
    "message.index.B5E19B36B5125550": "Visszavonási emlékeztető",
    "message.index.716A981465138334": "Újratöltési emlékeztető",
    "message.index.884474Ebff2977Ba": "rendszer információ",
    "message.index.08Cc7D24752Ec42C": "online szolgáltatás",
    "message.index.1D820A6A19Ab025D": "7 x 24 órás online kézi szolgáltatás",
    "more.index.29881E0B5Df58Bf7": "További funkciók",
    "more.index.C77Ee35911D10D9D": "Közös funkciók",
    "more.index.3558F69B0260Be61": "Kereskedési funkció",
    "more.index.7243Fe610Ed98Cec": "valódi számla",
    "more.index.7243Fe610Ed98Ced": "Demo fiók",
    "more.index.693C7B35Bdf3E3A3": "pénzügy",
    "more.index.F855997Fac114554": "Segítség Központ",
    "more.index.Ae9A1252278Da742": "Segítség",
    "more.index.Ace012504Bc529E0": "információ",
    "more.index.05682Dbef5Eeb0A8": "Vevőszolgálat",
    "more.index.C7Af227A5D5C7Bb9": "Élő fiókra váltott",
    "more.index.D818A65592694786": "Demószámlára váltott",
    "notice.details.B360807Dbe727E6E": "rólunk",
    "notice.details.92F2552F695E70E3": "rólunk",
    "notice.details.7Aaceaf69B67F3D1": "Szabály bevezetés",
    "notice.index.6128438Be34Bcb9F": "Örökös értesítés",
    "notice.index.71627F5Eb7128261": "Elállási értesítés",
    "notice.index.2Dce570Fda841705": "Feltöltési értesítés",
    "notice.index.228A7C9C6448A9B1": "rendszer értesítés",
    "setting.dialog.8B4F5B74660Deabe": "Színkonfiguráció",
    "setting.dialog.Bfc7D25486Ebc1A9": "A zöld emelkedik és a piros leesik",
    "setting.dialog.796Ea0E1A82C320B": "A piros emelkedik és a zöld leesik",
    "setting.index.8Ddc5864E1739466": "preferenciák",
    "setting.index.F5205801D57D6727": "nyelv",
    "setting.index.2E2C12Dd0Cc1B63B": "Árjegyzés pénzneme",
    "setting.index.322D8496Aec3D918": "verziószám",
    "setting.index.Aa7E6C204C751Cfa": "kijelentkezés",
    "share.index.75Bc38Bbf7D2E766": "meghívja barátait",
    "share.index.45575Eb5D42213E6": "a meghívó kódomat",
    "share.index.29F67F0644A78Bf3": "másolat",
    "share.index.19Cc65Bb6Bb98Fe7": "A meghívó linkem",
    "share.index.5372712F162Fb78B": "Közvetlen beosztottak száma",
    "share.index.C88D6B95E3Ab7D6F": "A beosztottak összlétszáma",
    "share.index.Bb5Fa5471Aeecc62": "Jutalékkedvezmény a közvetlen beosztottak számára",
    "share.index.426B6Bac9A5F3D4F": "teljes árengedmény",
    "share.index.197E4A428F0401C9": "Tekintse meg a visszatérítési nyilvántartásokat",
    "share.records.88A6B2E2B74B13C4": "Bizottsági rekord",
    "share.records.B671A35Ffdc93B89": "Engedmény",
    "transfer.index.B17617A869C03Ca5": "pénztárca",
    "transfer.index.E4116Dcc6F6C7270": "Az átvitel sikeres",
    "transfer.index.48Fb603C6638C7F6": "Kérjük, állítson be egy alapjelszót",
    "transfer.records.Eb0E39Eb5911D418": "kap",
    "transfer.records.977554Be0Aa65052": "Flash csereidő：",
    "transfer.records.9Aa33884Aa400B7C": "átszállási idő：",
    "form1.forget.76636Db8Fae5B124": "a postafiókod",
    "form1.forget.35354B9Cca1Bb0E6": "E-mail ellenőrző kód",
    "form1.forget.604A0B11E916F387": "Jelszó",
    "form1.forget.9Cb1C9C51E7503D6": "Jelszó megerősítése",
    "form1.forget.3A4F7E04B008453C": "A telefonszámod",
    "form1.forget.Cf11Dce5B4713B5A": "Mobiltelefon ellenőrző kód",
    "form1.forget.3012Edba57D4F297": "Kérem, írja be az e-mail címét",
    "form1.forget.F1Ee1D97E869E447": "kérjük, adja meg az ellenőrző kódot",
    "form1.forget.3A5C4C9Ae68Fa0F6": "Kérem írja be a jelszavát",
    "form1.forget.C6Cb174Da411D5F7": "Kérjük, adja meg mobiltelefonját",
    "form1.forget.947B3B21Ee45E582": "Küld",
    "form1.forget.3E0580F1E8630Df6": "Két jelszó ellentmondásos",
    "form1.forget.C5A61836B8Cc74C6": "A jelszómódosítás sikeresen átirányítva...",
    "form1.forget.B96D072Bc8962565": "Újraküldés",
    "form1.login.2B72C27Ff828Cfc4": "a jelszavad",
    "form1.login.D7C6B872Af9Cb17F": "Emlékezz a jelszavamra",
    "form1.login.0Fff44C827A4F3B6": "Belépés",
    "form1.login.1B7F2C1Ede3990Cc": "Még nincs fiókod? Iratkozz fel most",
    "form1.login.F657F7Be1Cc33Bd2": "felejtsd el a jelszót",
    "form1.login.2074A1Eec4B088B0": "Wallet bejelentkezés",
    "form1.login.1B8938Fbcc3C9B56": "Sikeres bejelentkezés és átirányítás...",
    "form1.login.07162A7Bb9310555": "Kérjük, telepítse az ERC20 pénztárcát",
    "form1.login.766969966Ae151Dc": "Kérjük, telepítse a TRC20 pénztárcát",
    "form1.login.B88171E1Ad4C50Bf": "Ön elutasította a hálózatváltást",
    "form1.register.Aaef0710Cb4Db554": "Írja be újra a jelszót",
    "form1.register.54Dfea0415C708Ff": "Meghívó kód",
    "form1.register.6839940Dac08C794": "Regisztráció",
    "form1.register.9901A3B5550Aa58E": "Már van fiókja? Belépés",
    "form1.register.2548Ca53785252Ea": "Kérjük, adja meg a meghívó kódját",
    "form1.register.Dd71Fd65900Dd03E": "Kérjük, olvassa el a Regisztrációs Szolgáltatási Szerződést és fogadja el",
    "form1.register.7975613909490B77": "Sikeres regisztráció és átirányítás...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Válassz pénztárcát",
    "userc.certification.2Af9511640153D2E": "Junior bizonyítvány",
    "userc.certification.177B191D8E0Fa84D": "nem hitelesített",
    "userc.certification.601218D4D572E98A": "ellenőrizve",
    "userc.certification.6E37C51Ba9012Fc8": "Nem ment át",
    "userc.certification.1B9B563E05C96474": "Haladó minősítés",
    "userc.certification.1418B83803Ab56B4": "Kérjük, először töltse ki az elsődleges tanúsítványt",
    "userc.creditScore.0B149112601Acc4D": "hitel pontszám",
    "userc.creditScore.88Ac7F84E106C5F6": "teljes hitelpontszám",
    "userc.creditScore.3E1D6E0E7F1E6155": "Rendszerértékelés",
    "userc.index.62Eac58D04Bbca0F": "Személyes központ",
    "userc.index.F4Cac53F93Cb2Aff": "szimulált kereskedés",
    "userc.index.42F409Fdf3559773": "Biztonsági Központ",
    "userc.index.1B8E211009807Db8": "Hitelesítés",
    "userc.index.Bdfcf21Eb8F2Eb70": "Számlamódosítási rekordok",
    "userc.index.6C18D67F6D19586B": "A platform bemutatása",
    "userc.index.807A814Ed58A93Bc": "gyorsítótár törlése",
    "userc.index.E43Daad59759611C": "nem hitelesített",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Változtassa meg a bejelentkezési jelszót",
    "userc.secureCenter.1F37Ba427Debf24A": "Számlakötés",
    "userc.secureCenter.F9A5Ffa750664004": "Kösse össze a Google Hitelesítőt",
    "userb.certificationForm.7685Af7D30043Cd6": "Junior bizonyítvány",
    "userb.certificationForm.7685Af7D30043Cd7": "Haladó minősítés",
    "userb.certificationForm.70E37C35Abc3F462": "Ország",
    "userb.certificationForm.F92C25A68E4D08C5": "Város",
    "userb.certificationForm.0Ccc6387B38E0318": "A neved",
    "userb.certificationForm.94C67Bba370Fdd46": "rendszám",
    "userb.certificationForm.B2D4C7Fffe79258E": "Levél",
    "userb.certificationForm.57554268761Fb47A": "Töltse fel személyi igazolványának elülső részét",
    "userb.certificationForm.D79E90201F5319Bd": "Töltse fel igazolványának hátoldalát",
    "userb.certificationForm.9C6Bad08Af29E20E": "Töltsön fel egy képet a személyi igazolványáról",
    "userb.certificationForm.F96B62Dfa31Cde45": "Beküldés megerősítése",
    "userb.certificationForm.2125D78Ea8C6D287": "Feltöltési követelmények",
    "userb.certificationForm.0D64864D3076A824": "Példa megtekintése",
    "userb.certificationForm.35287Deb72281E87": "1.Tisztán kell tudnia olvasni a személyi igazolvány adatait.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.A kép tartalma igaz és érvényes, módosítása nem megengedett.",
    "userb.certificationForm.25A90660554319F9": "3.Az azonosító számnak és névnek jól láthatónak kell lennie, támogatnia kell a JPG / JPEG / PNG fájlokat",
    "userb.certificationForm.5A9Aefbc03C778F7": "kérlek lépj be",
    "userb.certificationForm.3B7A1D8Ce27C937E": "A beküldés sikeres, kérjük, várja meg az ellenőrzést",
    "userb.certificationForm.744Bd07D8Abd2513": "Kérjük, töltse fel személyi igazolványának előlapját",
    "userb.certificationForm.6De1C319130F7Fef": "Kérjük, töltse fel igazolványának hátoldalát",
    "userb.certificationForm.79F8E8B172E30C8A": "Kérjük, töltsön fel egy fényképet a személyi igazolványáról",
    "userb.certificationForm.46412E16D28A4753": "A fénykép feltöltése sikertelen",
    "userb.changeDialog.676B0Adca1F8Eea4": "Jelszó módosítása",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "telefonszám",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "érvényesítő kapcsoló",
    "userb.modifyInfoDialog.62149E566814154C": "Válassza ki az érvényesítőt",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Nyissa meg a Hitelesítés alkalmazást, és érintse meg a + gombot",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": 'Válassza a "QR-kód beolvasása" vagy a "Kulcs beírása" lehetőséget',
    "userb.modifyInfoDialog.93F094E2B4369472": "Olvassa be a QR-kódot, vagy írja be a kulcsot",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "zárni/nyitni",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Régi alapjelszó",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Új alapjelszó",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Erősítse meg az alap új jelszavát",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Erősítse meg az alap jelszavát",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Kérjük, adja meg régi alapjelszavát",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Kérjük, adja meg új alap jelszavát",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Kérjük, adja meg az alap jelszavát",
    "userb.modifyInfoDialog.258A534498Fe984F": "Kérjük, erősítse meg az alap jelszavát",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Számlakötés",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Alap jelszava",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "megerősít",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Az érvényesítő zárva van",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "A Google-ellenőrzés sikeresen bekapcsolva",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Az alap jelszava sikeresen módosítva",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Sikeresen beállította az alap jelszavát",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "A kötés sikeres",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Ellenőrző kód hiba",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Példa a tanúsítvány feltöltésére",
    "userb.veBottomSheet.6F38A0D5858633Ee": "sötét tiszta háttér",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Elegendő világítás",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Igazítási segédkeret",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "A fényképek világosak",
    "userb.veBottomSheet.50B741382E71F103": "A sikertelenség lehetséges okai",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "A személyi igazolvány védőburkolatát nem távolították el",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "A hátteret a rendetlenség befolyásolja",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "A személyi igazolvány fényvisszaverő",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "A fény túl sötét/elzárja a mobiltelefon árnyékát",
    "userb.veBottomSheet.1A75D2Fc2614592B": "kezében személyi igazolvány",
    "home.home.C3525Eb110Db58Aa": "Több",
    "home.home.29761532Fc8Ebecc": "kereskedelmi",
    "home.home.F15Ea99Bfa6E1848": "Biztonsági tanúsítvány, vásároljon bizalommal",
    "home.home.46E1Ab39Bae7182A": "Gyors",
    "home.home.Fde2Eb7F6F3Dd800": "Gyors tranzakciók és egyszerű kezelés",
    "home.home.Ea4756Bc1642E0F1": "név",
    "home.home.B6B1127Ede1C97B4": "legújabb ár",
    "home.home.3587Ad870Eb0Ab86": "24 órás növekedés és csökkenés",
    "home.home.Cb1A4E14Ec2Cd2B5": "Felfedez",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Forex",
    "home.home.Ad65263D034Ea051": "PM-ek",
    "home.home.5Fc1474Dbf74Ac1A": "mezőgazdasági termékek",
    "home.home.135318F4664F7773": "energia",
    "kline.kLine.C96Ff250Cf6B8B84": "24 órás legmagasabb ár",
    "kline.kLine.6E8Be64F19D74565": "24 órás kereskedési mennyiség",
    "kline.kLine.08A4Bfbe26A0F262": "24 órás legalacsonyabb ár",
    "kline.kLine.8701Deb075066331": "24 órás kereskedési mennyiség (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 pont",
    "kline.kLine.B49Cca240E013124": "5 pont",
    "kline.kLine.F27A90Be97Ba3D6C": "15 márka",
    "kline.kLine.Ff3E62395B694384": "30 pont",
    "kline.kLine.C1993F9C6Db1A674": "1 óra",
    "kline.kLine.Fd2Cbbae405E284C": "1 nap",
    "kline.kLine.C9Cd71904395042C": "január",
    "kline.kLine.7D8127Ec18C7B8C7": "megvesz",
    "kline.kLine.E3D3Ffdb9B3E5636": "Mennyiség (darab)",
    "kline.kLine.6834Fec1838D8029": "Ár (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "eladási megbízás",
    "kline.kLine.B9Bea1A9A88D1419": "Hosszan nyitva",
    "kline.kLine.D36Cfa96Ee2B343B": "Nyitva rövidre",
    "other.navigationBar.F6E62F9642018Cbb": "Címlap",
    "other.navigationBar.0Ec227161A47B49D": "Idézetek",
    "other.navigationBar.29761532Fc8Ebecc": "kereskedelmi",
    "other.navigationBar.693C7B35Bdf3E3A3": "pénzügy",
    "other.navigationBar.9734C54620Eb09Af": "eszközök",
    "home.index.25Aa4Fc745150030": "a kriptovaluták királya",
    "home.index.2D6Ecf8Faaa8B124": "Gyorsabb, jobb, erősebb",
    "home.index.1Db26267D1041223": "Könnyen kereskedhet több mint 1700 kriptovalutával",
    "home.index.F8E18C90C3E35618": "Iratkozz fel most",
    "home.index.29761532Fc8Ebecc": "kereskedelmi",
    "home.index.D8A95E4506487452": "Népszerű szerződések",
    "home.index.5Bd31Fab681C6E9D": "többet látni",
    "home.index.010Ae07A09Caf221": "kereskedési pár",
    "home.index.F4D875118E6D6Dbb": "Legutóbbi tranzakciós ár",
    "home.index.3Dfdfb3D6599100E": "24 órás áremelkedés és csökkenés",
    "home.index.28Fb39Bd41E54242": "irányzat",
    "home.index.B47A3911Bacd94C6": "Gainer lista",
    "home.index.712910D574F43D95": "Stabil jövedelem",
    "home.index.5507Fd830D7Bf51F": "Legjobb stratégiák, könnyen másolható",
    "home.index.7De3D636A165E008": "megtérülési ráta",
    "home.index.B853F96626106912": "Menj bányászni",
    "home.index.213B5C96Be7Cd1F8": "nap, évesített kamatláb",
    "home.index.213B5C96Be7Cd1F9": "Maximum megvásárolható",
    "home.index.Dc710Cffe6313Bb5": "ellenőrizze a részleteket",
    "home.index.B92Ffc837Ecaa1Dd": "hírek",
    "home.index.6C18D67F6D19586B": "A platform bemutatása",
    "home.index.6342E3C33Cb48Cd5": "A digitális valuta olyan csereeszköz, amely kriptográfiai elveket alkalmaz a tranzakciók biztonságának biztosítására. A fiat pénznemekkel, például az amerikai dollárral ellentétben a digitális pénznemeknek nincs fizikai formája. Az olyan népszerű digitális valuták, mint a Bitcoin (BTC), az Ethereum (ETH) és a Polkadot (DOT), a blokkláncot használják alapul szolgáló technológiaként, hogy decentralizált digitális főkönyvként működjenek. Az összes digitális valuta-tranzakció rögzítésre kerül a blokkláncban, és a megerősítés és ellenőrzés után nem módosítható. A hagyományos pénznemekkel ellentétben, ahol a bankok központi főkönyveket vezetnek, a digitális valuta-tranzakciók nyilvános blokkláncokon történnek, amelyekhez bárki hozzáférhet.",
    "home.index.198Efc4254683Eef": "Emellett a konszenzusos mechanizmus szerint mindenki ellenőrizheti a digitális valuta tranzakcióit, és hozzáadhatja azokat a blokklánchoz, így érhető el a decentralizáció. A vezető globális vállalatok, mint például a Samsung, a BlackRock, a Morgan Stanley és az Alphabet befektetett, az emberek úgy vélik, hogy a blokklánc technológia teljesen megváltoztatja a globális pénzügyi rendszert. A digitális valuta megnyitja az ajtót a decentralizált pénzügyek világába, lehetővé téve, hogy felfedezze a legmodernebb technológia által kínált végtelen lehetőségeket.",
    "home.index.E710339D9576292E": "A mi előnyünk",
    "home.index.F9Ce8B2C63885B26": "biztonságos és stabil",
    "home.index.C4D0A2C9Ff0305Ce": "A kiváló műszaki csapat, az átfogó biztonsági védelem, a független kutatás és fejlesztés a nagy sebességű tranzakciószintézis motornak köszönhetően továbbra is stabilan és megbízhatóan működik hatalmas egyidejű tranzakciók esetén.",
    "home.index.2E8708Ee3F11Cc1A": "Profi és megbízható",
    "home.index.Bb12Cbcf89C56C5C": "Professzionális üzemeltetési csapat, sok éves tapasztalattal a blokklánc és a pénzügyek terén, megfelelő digitális eszköz kereskedési engedéllyel és 100%-os tartalékgaranciával rendelkezik.",
    "home.index.Aa4723Bfbf6808F4": "Szívvel tálaljuk",
    "home.index.1Bf0204B7D3Ee230": "Szembenézni a globális piaccal, több nyelv támogatása, éjjel-nappali működés, erős közösségi támogatás és professzionális ügyfélszolgálat.",
    "home.index.2E7Dfd53D26F7224": "nagy teljesítményű",
    "home.index.1C65453103B5Fcc3": "300 000 tranzakció másodpercenként, és a megrendelés válaszideje kevesebb, mint 1 milliszekundum.",
    "home.index.85Ac49Ece9Dcc80B": "Kereskedjen bármikor, bárhol!",
    "home.index.2799E18842E4Ebe9": "Nyisson meg egy pozíciót azonnal, az OKEX App és a weblap támogatásával!",
    "home.index.2Bf52Ce75E29Fc88": "Olvassa be a kódot a letöltéshez",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple verzió",
    "home.index.04Ee893775563F22": "termék szolgáltatás",
    "home.index.04Ee893775563F23": "pénzügyi menedzsment",
    "home.index.04Ee893775563F24": "Visszatérés",
    "home.index.04Ee893775563F25": "Várjuk a sorsolást",
    "home.index.04Ee893775563F26": "IOS és ANDROID",
    "homepage.text1": "Bitcoin, Ethereum és egyéb",
	"homepage.text1_2": "1000+ kriptovaluta vásárlása",
	"homepage.text2": "Élményed a kriptovaluták világában",
	"homepage.text4": "Magas hozamú pénzügyi kezelés",
	"homepage.text5": "Stabil pénzügyi kezelés, magas éves hozam",
	"homepage.text6": "Megosztott jutalmak",
	"homepage.text7": "Gyors és egyszerű tranzakciók",
	"homepage.text8": "További információk megtekintése",
	"homepage.text9": "Azonnali átváltás",
	"homepage.text10": "Biztonsági tanúsítvány, bátran vásárolj",
	"homepage.text11": "Világelső technológiai csapat, teljes körű biztonság",
	"homepage.text12": "Védelem, függetlenség",
	"homepage.text13": "Szakmai és megbízható",
	"homepage.text14": "Tapasztalt blokklánc és pénzügyi szakemberekből álló csapat",
	"homepage.text15": "Odaadó szolgáltatás",
	"homepage.text16": "Világpiaci célzás, több nyelv támogatása, éjjel-nappal üzemelés",
	"homepage.text17": "Magas teljesítmény",
	"homepage.text18": "300,000 tranzakció feldolgozása másodpercenként, rendelésre adott válaszidő kevesebb, mint 1 millimásodperc",
    "passwod.limit":"A jelszó hossza legfeljebb 6 karakter lehet"
}
