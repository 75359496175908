import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "简体中文",
        dialogDetermine: "确定",
        dreivatives: "交割合约",
        markets: "行情",
        assets: "资产",
        liquidity: "流动性挖矿",
        helpCentre: "帮助中心",
        announcementCenter: "公告中心",
        about: "关于",
        bulletinCentre: "公告中心",
        serviceAgreement: "服务协议",
        support: "支持",
        onlineSupportCustomerService: "在线客服",
        suggestionsAndFeedback: "建议和反馈",
        service: "服务",
        download: "下载",
        coinApplication: "上币申请",
        privacyStatement: "隐私声明",
        trade: "合约交易",
        standardConteact: "永续",
        signOut: "退出登录",
        assetWallets: "资产钱包",
        financeRecords: "账变记录",
        identification: "身份认证",
        personalCenter: "个人中心",
        chooseLanguage: "选择语言",
        tips: "提示",
        dark: "黑夜模式",
        light: "白天模式",
        languageTitle: "语言",
        convertCurrency: "折算货币",
        cancel: "取消",
        aboutUs: "关于我们",
        standard: "本位永续",
        share: "分享",
        introduce: "平台介绍",
        set: "设置",
        changePassword: "更改登录密码",
        denomination: "计价货币",
        color: "颜色配置",
        code: "版本号",
        color1: "红涨绿跌",
        color2: "绿涨红跌",
        setLangue: "设置语言",
        vip: "VIP权益",
        vip2: "当前VIP等级",
        safeMenu: "安全中心",
        conventional: "常规",
        other: "其他",
        quickTips: "最多添加3个快捷功能按钮",
        quickTransaction: "快捷交易",
        safeConvenient: "安全便捷",
        timeSharing: "分时",
        currentLevel: "当前等级",
        levelBenefits: "%{level}级收益",
        teamLevel: "团队等级",
        levelLower: "%{level}级下级",
        rules: "规则说明",
        bindWithdrawalAddress: "绑定提现地址",
        tipsBindWithdrawalAddress: "您还未绑定提现地址，请先去绑定",
        walletAddressFormatIncorrect: "钱包地址格式不正确",
        tradeRules1: "预测未来一段时间的涨跌。以下单时间的价格为起始价，以预测周期最后一秒的价格为结算价，结算价大于起始价则为涨，结算价小于起始价则为跌。",
        tradeRules2: "1.什么是合约",
        tradeRules3: "本平台提供USDT本位的永续合约，用户可以通过判断涨跌，选择买入做多或卖出做空合约来获取数字资产价格上涨/下降的收益。永续合约没有交割日，用户可以一直持有。",
        tradeRules4: "2.什么是保证金",
        tradeRules5: "在虚拟合约市场上，用户只需根据合约价格，按一定比例交纳少量资金作为履行合约的财力担保，便可参与合约的买卖，这种资金就是虚拟合约保证金。\n本平台的合约采用分账户的逐仓保证金模式。每个币种对应一个合约账户，不同的合约币种的账户资产和仓位相互独立，且不同合约账户之间的划转及交易互不影响。用户的合约账户在开仓后，该合约账户内的所有仓位持仓的风险和收益将合并计算。",
        tradeRules6: "3.如何计算保证金",
        tradeRules7: "持仓保证金 = 合约面值 * 持仓合约数量 / 杠杆倍数\n冻结保证金 = 当前委托内所有已成功挂单开仓委托的保证金总和\n可用保证金 = 账户余额 - 持仓保证金 - 冻结保证金",
        tradeRules8: "4.如何计算保证金率",
        tradeRules9: "保证金率是衡量用户资产风险的指标。\n保证金率 =（未实现盈亏 + 持仓保证金 + 可用保证金 + 冻结保证金 - 平仓手续费） / 持仓保证金\n保证金率越小，账户的风险越高。当保证金率小于等于0%时，会触发强制平仓。",
        tradeRules10: "5.什么是预估强平价",
        tradeRules11: "预估强平价，即系统计算得到的理论上触发强制平仓的价格，仅做参考用，与实际值可能有些许偏差。",
        tradeRules12: "6.什么是账户总权益、未实现盈亏",
        tradeRules13: "账户总权益 = 账户余额 + 未实现盈亏\n未实现盈亏，是用户在该币种的合约当前持有的仓位的盈亏，未实现盈亏会随着最新成交价格变动而变化。\n多仓未实现盈亏=（1/持仓均价 - 1/最新成交价）* 多仓合约张数 * 合约面值 * 最新成交价\n空仓未实现盈亏=（1/最新成交价 - 1/持仓均价）* 空仓合约张数 * 合约面值 * 最新成交价",
        text1: "账户安全",
        text2: "昼夜模式",
        text3: "清理缓存",
        text4: "确定要退出登录吗？",
        text5: "确定要清理缓存吗？",
        text6: "多语言",
        text7: "为保护您的安全，建议至少开启一种双重身份验证"
    },
    login: {
        login: "登录",
        register: "注册",
        title1: "随时随地交易，查看实时行情",
        title2: "随时随地交易，查看实时行情",
        title3: "最值得信赖的加密货币交易平台",
        formTitle1: "账户登录",
        formTitle2: "欢迎回来！使用您的电子邮件、电话号码登录",
        btn1: "手机",
        btn2: "邮箱",
        btn3: "登录",
        btn4: "注册",
        btn5: "忘记密码？",
        field1: "手机号",
        field2: "密码",
        field3: "邮箱",
        placeholder1: "国家",
        placeholder2: "请输入手机号",
        placeholder3: "请输入密码",
        placeholder4: "请输入邮箱",
        content12: "请输入手机号",
        content13: "请输入密码",
        content14: "请输入邮箱",
        content16: "数字资产金融服务",
        loginSuccess: "登录成功 ",
        chooseArea: "请选择区号",
        placeholder5: "请选择国家区号",
        placeholder6: "记住我的密码",
        placeholder7: "下载APP",
        placeholder8: "联系客户服务",
        placeholder9: "选择国家或地区",
        placeholder10: "区号",
        placeholder11: "钱包登录",
        placeholder12: "操作失败，请切换到",
        placeholder13: "钱包"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "关于",
        aboutCoinbeadCenterDetails: "拥有全球领先的区块链资产交易平台，同时运营着整个生态体系。",
        footDetails1: "每日交易量",
        footDetails2: "交易/每秒",
        footDetails3: "帮助中心",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "是全球著名的数字资产国际站，主要面向全球用户提供比特币（BTC）、莱特币（LTC）、以太币（ETH）等数字资产的币和衍生品",
        coinbeadCenterDetailsm: "采用GSLB、网络服务器、网络交易、网络、多机互备的高速内存交易引擎、冷钱包、智能离线化的热钱包等先进的技术，以Web端手机端、PC端等多终端为我们的客户提供安全、稳定、可靠的数字资费交易服务。同时我们根据用户的建议和需求，改进和升级产品和服务，为每一位客户提供更好的服务，和创新用户体验的提升永无止境。",
        infrastructureCenterTitle: "基础建设",
        infrastructureCenterDetails: "我们的使命是为整个区块链生态系统提供基础设施服务",
        freeCenterTitle: "自由",
        freeCenterDetails: "我们的愿景是让金钱在全球范围内自由流动。我们相信，传播这种自由，能让世界变得更好",
        futureCenterTitle: "开拓科技的未来",
        futureCenterDetails: "Open up the future of technology",
    },
    applicationForListing: {
        title: "上币申请",
        projectName: "项目名称",
        tokenName: "代币名称",
        projectDescription: "项目简介",
        contractAddress: "合约地址",
        officialWebsite: "官方网址",
        ContactAndEmail: "联系人邮件",
        ContactAndName: "联系人姓名",
        file: "上传附件",
        pleaseEnter: "请输入",
        enterFile: "请上传文件",
        upload1: "当前限制选择 1 个文件，",
        upload2: "本次选择了",
        upload3: "个文件，",
        upload4: "共选择了",
        upload5: "个文件",
    },
    public: {
        submit: "提交",
        copySuccessfully: "复制成功",
        copyFailed: "复制失败",
        noDate: "暂无数据",
        submitSuccessfully: "提交成功",
        goBack: "返回上一页",
        cancel: "取消",
        home: "首页",
        loading: "加载中...",
        confirm: "确认",
        loadingFailed: "网络异常",
        nomore: "没有更多了",
        tryAgain: "请刷新页面/退出程序 后重新上传!",
        hot: "热门区",
    },
    inviteFriends: {
        inviteFriends: "分享好友",
        freedomOfWealth: "让财富一起自由",
        myInvitationCode: "我的邀请码",
        clickCopy: "点击复制",
        myInvitationLink: "我的邀请链接",
        clicktoSaveTheQRCode: "点击保存二维码",
        saveQRCode: "邀请二维码",
        numberOfSubordinates: "下级人数",
        rebateIncome: "返佣收益",
        people: "人",
        myPromotionRevenue: "我的推广收益",
        numberOfDirectSubordinates: "直属下级人数",
        totalNumberOfSubordinates: "总下级人数",
        directSubordinateReturnsCommission: "直属下级返佣",
        theTotalCommission: "总返佣",
        checkHistory: "查看返佣记录",
        history: "返佣记录",
        theLowerAccount: "下级账户",
        type: "类型：",
        serviceCharge: "手续费：",
        percentageofCommissionReturned: "返佣比例：",
        commissionrebateAmount: "返佣金额：",
        time: "时间：",
        typeName1: "充值返佣",
        typeName2: "MT下单手续费三级分销",
        typeName3: "MT盈利三级分销",
        typeName4: "推荐奖励",
        typeName30: "充值返佣",
        typeName31: "周期交易盈利返佣",
        typeName32: "流动性挖矿盈利返佣",
        typeName45: "交易手续费返佣",
        typeName46: "自身手续费返佣",
        typeName50: "交割合约手续费返佣",
        typeName51: "永续合约手续费返佣",
        typeName52: "交割合约手续费额外返佣",
        typeName53: "永续合约手续费额外返佣",
        typeName54: "永续合约手续费额外返利",
        shareRules1: "邀请说明",
        shareRules2: "进入邀请中心，复制邀请链接或邀请码，分享给您的好友。好友通过您的邀请码注册就可以成为您的下级。",
        shareRules3: "赚取返佣",
        shareRules4: "下级进行交易的时候，您可以获得相应的返佣，最多支持三级下级。 举个例子，您邀请了好友A，A邀请了B，B邀请了C，则A、B、C在平台内进行合约等交易，您都可以获得相应的返佣。",
        shareRules5: "团队等级",
        shareRules6: "推广的一级下级越多，团队等级越高，可享受的返佣越高。团队等级分为LV1-LV6，升级规则见如下表格，其中'N'为已充值并完成实名认证一级下级人数。",
        shareRules7: "下级进行交割合约投注，您可获得其投注额相应比例的返佣。",
        upgradeConditions: "要求",
        recommendedNumberOfPeople: "推荐人数"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "建议及反馈",
        text: "如果使用过程中遇到bug或有产品建议欢迎反馈",
        email: "电子邮件地址",
        enterEmail: "请输入电子邮件地址",
        enterCorrectEmail: "请输入正确的邮箱地址",
        title: "标题",
        enterTitle: "请输入问题标题",
        describeTheProblem: "描述问题",
        enterDescribeTheProblem: "请输入你描述的问题",
        submit: "提交",
    },
    help: {
        enterSearch: "搜索",
        title: "帮助中心",
        search: "请输入您的问题",
        text1: "了解您的需求，持续为你提供更便捷的服务"
    },
    announcement: {
        enterSearch: "搜索",
        title: "公告中心",
        search: "请输入您的问题"
    },
    privacy: {
        title: "隐私声明",
        content1: "1. 收集用户的哪些个人信息？",
        content2: "收集个人信息是为了提供产品和服务并持续改进。",
        content3: "以下是我们收集的个人信息类型：",
        content4: "您提供给我们的信息：我们接收并存储您提供的与本平台相关的任何信息。您可以选择不提供某些信息，但可能因此无法使用某些“本平台服务”。您提供给我们的信息示例包括：",
        content5: "姓名",
        content6: "地址",
        content7: "国籍",
        content8: "证件照",
        content9: "手机号码/邮箱号码",
        content10: "有助于我们识别您身份的其他信息",
        content11: "自动收集的信息：我们会自动收集并存储有关您使用“本平台服务”的某些类型的信息，与许多网站一样，我们会使用Cookie以及其他独特的标识符。当您的网络浏览器或设备访问本平台时，我们便会获得某些类型的信息。我们收集和分析的信息示例包括：",
        content12: "将个人计算机连入互联网的因特网协议(IP)地址；",
        content13: "个人设备或计算机的登录信息、电子邮箱地址、密码和位置；",
        content14: "版本和时区设置；",
        content15: "交易历史记录。",
        content16: "其他来源的信息：我们可能会从其他来源（如征信机构的信用历史记录）收集有关您的信息。我们将通过这些信息防范和检测欺诈行为。",
        content17: "2. 儿童可以使用吗？",
        content18: "本平台不允许未满18岁的任何个人使用“服务”。",
        content19: "3. Cookie及其他标识符是用来干什么的？",
        content20: "我们使用Cookie及类似工具优化用户体验、提供我们的服务并了解客户如何使用我们的服务，以便做出针对性改进。我们使用Cookie是为了确保我们的系统能够识别您的浏览器或设备并向您提供服务。",
        content21: "我们使用操作类Cookie及类似工具（统称“Cookies”）来提供服务，例如：",
        content22: "在登录并使用我们的服务时识别您的身份。",
        content23: "提供定制功能和服务。",
        content24: "防范欺诈性活动。",
        content25: "提高安全性。",
        content26: "跟踪您的偏好（如货币和语言）。",
        content27: "我们还使用Cookie了解用户如何使用我们的服务，以便做出针对性改进。",
        content28: "4. 是否会共享我的个人信息？",
        content29: "用户信息是我们业务的重要组成部分，我们不会向他人出售用户的个人信息。本平台仅在下述情况中与其子公司或关联方共享用户的个人信息，这些子公司或关联方须遵守本隐私声明或至少遵循与本隐私声明中所述保护措施具有同等保护力度的实践。",
        content30: "第三方服务供应商：我们雇用其他公司和个人代表我们履行职能。此类职能示例包括：分析数据、提供营销协助、处理付款、传输内容以及评估和管理信贷风险。这些第三方服务供应商可以访问履行职能所需的个人信息，但不得将其用于其他目的。此外，他们必须遵照本隐私声明和相应的数据保护法来处理个人信息。",
        content31: "业务转移：随着我们的业务不断发展，我们可能会出售或购买其他业务或服务。在此类交易中，用户信息通常属于被转移的业务资产，但仍受任何既存隐私声明中的承诺约束（当然，用户另行同意的除外）。另外，如果本司或其大部分资产被收购，用户信息也将被转移。对本司及其他人的保护：当我们认为发布账户及其他个人信息是遵守法律或我们的监管义务、执行或应用我们的使用条款和其他协议、用户或其他人的权利、财产或安全的恰当举措时，我们会发布所述账户和其他个人信息。这包括与其他公司和组织交换信息，以防范欺诈行为并降低信用风险。",
        content32: "5. 个人数据的国际转移",
        content33: "本司可能将您的数据转移到欧洲经济区（“EEA”）以外的地区。本司会采取适当的保障措施，确保这种转移符合适用的数据保护规则，除非欧洲委员会已确认接收转移数据的国家/地区可提供足够的保护等级。",
        content34: "6. 我的个人信息安全吗？",
        content35: "我们在设计系统时充分考虑到您的安全和隐私。我们使用加密协议和软件，在传输过程中努力保护个人信息安全。",
        content36: "在收集、存储和披露个人信息方面，我们始终采取实体保障、电子保障和程序保障措施。我们的安全程序意味着在向您披露个人信息前，我们可能需要核实您的身份。",
        content37: "对您来说，最重要的事情莫过于防止个人账户密码遭受未经授权的访问。我们建议为您的账户设置区别于其他在线账户的独特密码。并且在使用共享计算机之后，务必退出登录。",
        content38: "7. 我应如何保护个人信息？",
        content39: "如果您对我们收集和处理个人信息的方法存在任何疑问或异议，敬请联系 客服人员",
        content40: "您在同意我们出于特定目的处理您的个人信息后，可以随时将意向撤回，我们将停止出于该目的处理您的数据。",
        content41: "此外，在遵守相应法律的前提下，您有权要求访问、纠正和删除个人数据并要求迁移数据。您也可以反对我们处理您的个人数据，或要求在某些情况下限制我们处理您的个人数据。",
        content42: "8. 欧盟GDPR和英国数据保护法——法律依据",
        content43: "欧盟GDPR和英国数据保护法要求我们在使用个人信息时遵循法律依据。我们的依据取决于使用个人信息的具体目的。这些依据包括：",
        content44: "履行合同 - 向您提供产品或服务或与您沟通相关事宜，包括我们使用您的个人信息来接受和处理订单以及处理付款。",
        content45: "我们的合法商业利益和用户利益 - 我们检测并防范欺诈和滥用行为，为我们的用户、我们自己或他人的安全提供保护。",
        content46: "您的同意 - 我们需要征得您的同意，以便出于向您传达的特定目的而处理您的个人信息。当您同意我们出于特定目的处理您的个人信息后，您可以将意向随时撤回，我们将停止出于该目的处理您的数据。",
        content47: "遵守法律义务 - 我们按法律要求使用您的个人信息。例如，我们收集银行账户信息是出于身份验证目的。",
        content48: "上述内容及其他法律依据视我们使用个人信息的具体目的而定。",
        content49: "9. 使用条件、通知和修订",
        content50: "您的使用以及任何隐私权争议均须遵守本声明及我们的使用条款。如果对本平台的隐私权存在任何疑虑，敬请联系我们并提供详尽说明，我们将竭诚为您解决问题。您也有权联系所在地的数据保护机构。",
        content51: "我们的业务不断变化，隐私声明也会随之变化。您应经常查看我们的网站，了解最新变更。您如果不同意修订后的内容，应立即停止访问。隐私政策更新版发布后，您继续访问即表示您认同更新内容并同意遵守更新后的隐私政策。除非另有说明，否则目前的隐私声明适用于我们掌握的关于您和您账户的所有信息。",
    },
    serviceAgreement: {
        title: "服务协议",
        content1: "本网站是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台。为了本协议表述之方便，本网站在本协议中合称使用“我们”或其他第一人称称呼。只要登陆该网站的自然人或其他主体均为本网站的用户，本协议表述之便利，以下使用“您”或其他第二人称。为了本协议表述之便利，我们和您在本协议中合称为“双方”，我们或您单称为“一方”。",
        content2: "重要提示：",
        content3: "我们在此特别提醒您：",
        content4: "1 数字资产本身不由任何金融机构或公司或本网站发行",
        content5: "2 数字资产市场是全新的、未经确认的，而且可能不会增长；",
        content6: "3 数字资产主要由投机者大量使用，零售和商业市场使用相对较少，数字资产交易存在极高风险，其全天不间断交易，没有涨跌限制，价格容易受庄家、全球政府政策的影响而大幅波动；",
        content7: "4  如果公司根据其单方判断认为您违反了本协议，或者根据您所在管辖区域的法律本网站提供的服务或者您使用本网站提供的服务的行为是非法的，公司有权随时暂停或终止您的账户，或者暂停或终止您使用本网站提供的服务或数字资产交易。禁止【中国大陆、中国台湾、以色列、伊拉克、孟加拉国、玻利维亚、厄瓜多尔、吉尔吉斯斯坦、塞瓦斯托波尔、英国（零售用户） 】的任何人使用本网站提供的合约交易服务 。前述国家或地区名单会随着不同国家或地区的政策和产品类型的不同发生变化，届时我们可能不会专门通知您，请您及时关注本协议的更新。",
        content8: "数字资产交易有极高风险，并不适合绝大部分人士。您了解和理解此交易有可能导致部分损失或全部损失，所以您应该以能承受的损失程度来决定交易的金额。您了解和理解数字资产会产生衍生风险，所以如有任何疑问，建议先寻求专业顾问的协助。此外，除了上述提及过的风险以外，还会有未能预测的风险存在。您应慎重考虑并用清晰的判断能力去评估自己的财政状况及上述各项风险而作出任何买卖数字资产的决定，并承担由此产生的全部损失，我们对此不承担任何责任。",
        content9: "敬告您：",
        content10: "1 您了解本网站仅作为您获取数字资产信息、寻找交易方、就数字资产的交易进行协商及开展交易的场所，本网站不参与您的任何交易，故您应自行谨慎判断确定相关数字资产及/或信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。",
        content11: "2 本网站的任何意见、消息、探讨、分析、价格、建议和其他资讯均是一般的市场评论，并不构成投资建议。我们不承担任何因依赖该资讯直接或间接而产生的损失，包括但不限于任何利润损失。",
        content12: "3 本网站保留随时自行决定、修改或变更本内容的权利，我们已采取合理措施确保网站资讯的准确性，但并不能保证其准确性程度，亦不会承担任何因本网站上的资讯或因未能链结互联网、传送或接收任何通知和信息时的延误或失败而直接或间接产生的损失。",
        content13: "4 使用互联网形式的交易系统亦存有风险，包括但不限于软件，硬件和互联网连接失败等。由于我们不能控制互联网的可靠性和可用性，我们不会对失真，延误和连接失败而承担任何责任。",
        content14: "5 禁止使用本网站从事洗钱、走私、商业贿赂等一切非法交易活动或违法行为，若发现任何涉嫌非法交易或违法行为，本站将采取各种可使用之手段，包括但不限于冻结账户，通知相关权力机关等，我们不承担由此产生的所有责任并保留向相关人士追究责任的权利。",
        content15: "6 禁止使用本网站进行恶意操纵市场、不正当交易等一切不道德交易活动，若发现此类事件，本网站将对所有恶意操纵价格、恶意影响交易系统等不道德的行为采取警告、限制交易、关停账户等预防性的保护措施，我们不承担由此产生的所有责任并保留向相关人士追究责任的权利。",
        content16: "一、总则",
        content17: "1.1 《用户协议》（以下称“本协议”或“本条款及条件”），由正文、《隐私条款》、《了解你的客户和反洗钱政策》以及本网站已经发布的或将来可能发布的各类规则、声明、说明等构成。",
        content18: "1.2 您在使用本网站提供的各项服务之前，应仔细阅读本协议，如有不理解之处或其他必要，请咨询专业律师。如您不同意本协议及/或随时对其的修改，请您立即停止使用本网站提供的服务或不再登陆本网站。您一旦登陆本网站、使用本网站的任何服务或任何其他类似行为即表示您已了解并完全同意本协议各项内容，包括本网站对本协议随时所做的任何修改。",
        content19: "1.3 您通过按照本网站的要求填写相关信息，并经过其他相关程序后成功注册可以成为本网站的会员（以下称“会员”），在进行注册过程中点击“同意”按钮即表示您以电子签署的形式与公司达成协议；或您在使用本网站过程中点击任何标有“同意”或类似意思的按钮的行为或以其他本网站允许的方式实际使用本网站提供的服务时，均表示您完全了解、同意且接受本协议项下的全部条款的约束，无您手写的书面签字不影响本协议对您的法律约束力。",
        content20: "1.4 成为本网站的会员后，您将获得一个会员帐号及相应的密码，该会员帐号和密码由您负责保管；您应当对以您帐号进行的所有活动和事件负法律责任。",
        content21: "1.5 只有成为本网站的会员才可使用本网站提供的数字资产交易平台进行交易及享受其他本网站规定的只有会员才可获得的服务；非会员只有登陆网站、浏览网站及其他本网站规定的可获得的服务。",
        content22: "1.6 通过注册和使用任何由本网站提供的服务和功能，您将被视为已阅读，理解并：",
        content23: "1.6.1 接受本协议所有条款及条件的约束。",
        content24: "1.6.2 您确认您已年满18周岁或根据不同的可适用的法律规定的具有可订立合同的法定年龄，您在本网站的注册、销售或购买、发布信息等接受本网站服务的行为应当符合对您有管辖权的主权国家或地区相关法律法规，并有充分的能力接受这些条款，并订立交易，使用本网站进行数字资产交易。",
        content25: "1.6.3 您保证交易中涉及到的属于您的数字资产均为合法取得并享有所有权。",
        content26: "1.6.4 您同意您为您自身的交易或非交易行为承担全部责任和任何收益或亏损。",
        content27: "1.6.5 您确认注册时提供的信息是真实和准确的。",
        content28: "1.6.6 您同意遵守任何有关法律的规定，就税务目的而言，包括报告任何交易利润。",
        content29: "1.6.7 您同意在任何时候不得从事或参与损害本网站或公司利益的行为或活动，无论是否与本网站提供的服务有关。",
        content30: "1.6.8 本协议只是就您与我们之间达成的权利义务关系进行约束，而并不涉及本网站用户之间与其他网站和您之间因数字资产交易而产生的法律关系及法律纠纷。",
        content31: "二、协议修订",
        content32: "我们保留不时修订本协议、并以网站公示的方式进行公告、不再单独通知您的权利，修订后会在本协议显示的[最近更新时间]标注修订时间，一经在网站公布，立即自动生效。您应不时浏览及关注本协议的更新变更时间及更新内容，如您不同意相关变更，应当立即停止使用本网站服务；您继续使用本网站服务，即表示您接受并同意经修订的协议的约束。",
        content33: "三、注册",
        content34: "3.1 注册资格",
        content35: "您确认并承诺：在您完成注册程序或以其他本网站允许的方式实际使用本网站提供的服务时，您应当是具备可适用的法律规定的可签署本协议及使用本网站服务应具有的能力的自然人、法人或其他组织。您一旦点击同意注册按钮，即表示您自身或您的有权代理人已经同意该协议内容并由其代理注册及使用本网站服务。若您不具备前述主体资格，则您及您的有权代理人应承担因此而导致的一切后果，且公司保留注销或永久冻结您账户，并向您及您的有权代理人追究责任的权利。",
        content36: "3.2 注册目的",
        content37: "您确认并承诺：您进行本网站注册并非出于违反法律法规或破坏本网站数字资产交易秩序的目的",
        content38: "3.3注册流程",
        content39: "3.3.1 您同意根据本网站用户注册页面的要求提供有效电子邮箱、手机号码等信息，您可以使用您提供或确认的邮箱、手机号码或者本网站允许的其它方式作为登陆手段进入本网站。如有必要，按照不同法域相关法律法规的规定，您必须提供您的真实姓名、身份证件等法律法规及隐私条款及反洗钱条款规定的相关信息并不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。您应对该等信息的真实性、完整性和准确性负责，并承担因此产生的任何直接或间接损失及不利后果。",
        content40: "3.3.2 如您所在主权国家或地区的法律法规、规则、命令等规范对手机号码有实名要求，您同意提供注册的手机号码是经过实名登记的，如您不按照规定提供，因此给您带来的任何直接或间接损失及不利后果均应由您承担。",
        content41: "3.3.3 您合法、完整并有效提供注册所需信息并经验证，有权获得本网站账号和密码，您获得本网站账号及密码时视为注册成功，可在本网站进行会员登陆。",
        content42: "3.3.4 您同意接收本网站发送的与本网站管理、运营相关的电子邮件和/或短消息。",
        content43: "四、服务",
        content44: "本网站只为您通过本网站进行数字资产交易活动（包括但不限于数字资产交易等服务）提供网络交易平台服务。",
        content45: "4.1 服务内容",
        content46: "4.1.1 您有权在本网站浏览数字资产各项产品的实时行情及交易信息、有权通过本网站提交数字资产交易指令并完成数字资产交易。",
        content47: "4.1.2 您有权在本网站查看您会员账号下的信息，有权应用本网站提供的功能进行操作。",
        content48: "4.1.3 您有权按照本网站发布的活动规则参与本网站组织的网站活动。",
        content49: "4.1.4 本网站承诺为您提供的其他服务。",
        content50: "4.2 服务规则 您承诺遵守下列本网站服务规则：",
        content51: "4.2.1 您应当遵守法律法规、规章、及政策要求的规定，保证账户中所有数字资产来源的合法性，不得在本网站或利用本网站服务从事非法或其他损害本网站或第三方权益的活动，包括但不限于发送或接收任何违法、违规、侵犯他人权益的信息，发送或接收传销材料或存在其他危害的信息或言论，未经本网站授权使用或伪造本网站电子邮件题头信息等。",
        content52: "4.2.2 您应当遵守法律法规并妥善使用和保管您的账号及登陆密码、资金密码、和您注册时绑定的手机号码、以及手机接收的手机验证码。您对使用您的账号和登陆密码、资金密码、手机验证码进行的任何操作和后果承担全部责任。当您发现本网站账号、登陆密码、或资金密码、验证码被未经您授权的第三方使用，或存在其他账号安全问题时，应立即有效通知本网站，要求本网站暂停本网站账号的服务。本网站有权在合理时间内对您的该等请求采取行动，但本网站对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。您在未经本网站同意的情况下不得将本网站账号以赠与、借用、租用、转让或其他方式处分给他人。",
        content53: "4.2.3 您同意对您的本网站的账号、密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。",
        content54: "4.2.4 您在本网站进行数字资产交易时不得恶意干扰数字资产交易的正常进行、破坏交易秩序；不得以任何技术手段或其他方式干扰本网站的正常运行或干扰其他用户对本网站服务的使用；不得以虚构事实等方式恶意诋毁本网站的商誉。",
        content55: "4.2.5 如您因网上交易与其他用户产生纠纷的，不得通过司法或行政以外的途径要求本网站提供相关资料。",
        content56: "4.2.6 您在使用本网站提供的服务过程中，所产生的应纳税赋，以及一切硬件、软件、服务及其它方面的费用，均由您独自判断和承担。",
        content57: "4.2.7 您应当遵守本网站不时发布和更新的本协议以及其他服务条款和操作规则，有权随时终止使用本网站提供的服务。",
        content58: "4.3 产品规则",
        content59: "4.3.1 浏览交易信息",
        content60: "您在本网站浏览交易信息时，应当仔细阅读交易信息中包含的全部内容，包括但不限于价格、委托量、手续费、买入或卖出方向， 您完全接受交易信息中包含的全部内容后方可点击按钮进行交易。",
        content61: "4.3.2 查看交易明细",
        content62: "您可以通过您的账户查看相应的成交记录。",
        content63: "五、本网站的权利和义务",
        content64: "5.1 如您不具备本协议约定的注册资格，则本网站有权拒绝您进行注册，对已注册的，本网站有权注销您的会员账号，本网站保留向您或您的有权代理人追究责任的权利。同时，本网站保留其他任何情况下决定是否接受您注册的权利。",
        content65: "5.2 根据本网站自己的判断，本网站发现您或您的关联账户使用者不适合高风险投资时，有权中止或终止您的账户以及所有关联账户的使用。",
        content66: "5.3 本网站发现账户使用者并非账户初始注册人时，有权中止或终止该账户的使用。",
        content67: "5.4 本网站通过技术检测、人工抽检等检测方式合理怀疑您提供的信息错误、不实、失效或不完整时，有权通知您更正、更新信息或中止、终止为其提供本网站服务。",
        content68: "5.5 本网站有权在发现本网站上显示的任何信息存在明显错误时，对信息予以更正。",
        content69: "5.6 本网站保留随时修改、中止或终止本网站服务的权利，本网站行使修改或中止服务的权利不需事先告知您；本网站终止本网站一项或多项服务的，终止自本网站在网站上发布终止公告之日生效。",
        content70: "5.7 本网站将采取必要的技术手段和管理措施保障本网站的正常运行，并提供必要、可靠的交易环境和交易服务，维护数字资产交易秩序。",
        content71: "5.8 如您连续一年未使用本网站会员账号和密码登陆本网站，则本网站有权注销您的本网站账号。账号注销后，本网站有权将相应的会员名开放给其他用户注册使用。",
        content72: "5.9 本网站通过加强技术投入、提升安全防范等措施保障您的数字资产的安全，并将在您账户出现可以预见的安全风险时提前通知您。",
        content73: "5.10 本网站有权随时删除本网站内各类不符合法律法规或本网站规定等的内容信息，本网站行使该等权利不需提前通知您。",
        content74: "5.11 本网站有权根据您所属主权国家或地区的法律法规、规则、命令等规范的要求，向您要求提供更多的信息或资料等，并采取合理的措施，以符合当地的规范之要求，您有义务配合；本网站有权根据您所属主权国家或地区的法律法规、规则、命令等规范的要求，暂停或永久停止对您开放本网站部分或全部服务。",
        content75: "5.12 本网站保留自行决定提前七个工作日发出通知后关闭您的账户的权利。因此，您有七个工作日时间撤单和平仓等操作。如果该期限到期时您仍未撤单和平仓，我们会强制撤单和平仓，并将您账户中剩余的数字资产退还给您。",
        content76: "5.13 为保护交易者权益，本网站有权利在特殊情况下（比如系统故障、网络故障、极端行情等），对已上线产品进行调整，例如：合约进行提前交割结算，提前交割结算的合约品种、合约周期及结算交割价格，以本网站公告为准。",
        content77: "六、赔偿",
        content78: "6.1 在任何情况下，我们对您的直接损害的赔偿责任均不会超过我们因您使用本网站为期三（ 3）个月服务而向您收取的总服务费。",
        content79: "6.2 如您发生违反本协议或其他法律法规等情况，您须向我们至少赔偿200万美元及承担由此产生的全部费用（包括律师费等），如不够弥补实际损失，您须补全。",
        content80: "七、寻求禁令救济的权利",
        content81: "我们和您均承认，普通法对您违约或可能违约情况的救济措施可能不足以弥补我们遭受的全部损失，故我们有权在您违约或可能违约情况下寻求禁令救济以及普通法或衡平法允许的其他所有的补救措施。",
        content82: "八、责任限制与免责",
        content83: "8.1 您了解并同意，在任何情况下，我们不就以下各事项承担责任：",
        content84: "8.1.1 收入的损失；",
        content85: "8.1.2 交易利润或合同损失；",
        content86: "8.1.3 业务中断造成的损失；",
        content87: "8.1.4 预期可节省的货币的损失；",
        content88: "8.1.5 信息问题造成的损失；",
        content89: "8.1.6 机会、商誉或声誉的损失；",
        content90: "8.1.7 数据的损坏或损失；",
        content91: "8.1.8 购买替代产品或服务的成本；",
        content92: "8.1.9 任何由于侵权（包括过失）、违约或其他任何原因产生的间接的、特殊的或附带性的损失或损害，不论这种损失或损害是否可以为我们合理预见；不论我们是否事先被告知存在此种损失或损害的可能性。",
        content93: "8.1.1 条至8.1.9条均是彼此独立的。",
        content94: "8.2 您了解并同意，我们不对因下述任一情况而导致您的任何损害赔偿承担责任：",
        content95: "8.2.1 您的具体交易事项可能存在重大违法或违约情形。",
        content96: "8.2.2 您在本网站的行为涉嫌违法或不道德。",
        content97: "8.2.3 通过本网站服务购买或获取任何数据、信息或进行交易等行为或替代行为产生的费用及损失。",
        content98: "8.2.4 您对本网站服务的误解。",
        content99: "8.2.5 任何非因我们的原因而引起的与本网站提供的服务有关的其它损失。",
        content100: "8.3 我们对由于信息网络设备维护、信息网络连接故障、电脑、通讯或其他系统的故障、电力故障、天气原因、意外事故、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、银行或其他合作方原因、数字资产市场崩溃、政府行为、 司法或行政机关的命令、其他不在我们可控范围内或我们无能力控制的行为或第三方的原因而造成的不能服务或延迟服务，以及造成您的损失，我们不承担任何责任。",
        content101: "8.4 我们不能保证本网站包含的全部信息、程序、文本等完全安全，不受任何病毒、木马等恶意程序的干扰和破坏，故您登陆、使用本网站任何服务或下载及使用该下载的任何程序、信息、数据等均是您个人的决定并自行承担风险及可能产生的损失。",
        content102: "8.5 我们对本网站中链接的任何第三方网站的任何信息、产品及业务及其他任何形式的不属于我们的主体的内容等不做任何保证和承诺，您如果使用第三方网站提供的任何服务、信息及产品等均为您个人决定且承担由此产生的一切责任。",
        content103: "8.6 我们对于您使用本网站服务不做任何明示或暗示的保证，包括但不限于本网站提供服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，我们也不对本网站提供的服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。是否登陆或使用本网站提供的服务是您个人的决定且自行承担风险及可能产生的损失。我们对于数字资产的市场、价值及价格等不做任何明示或暗示的保证，您理解并了解数字资产市场是不稳定的，价格和价值随时会大幅波动或崩盘，交易数字资产是您个人的自由选择及决定且自行承担风险及可能产生的损失。",
        content104: "8.7 本协议中规定的我们的保证和承诺是由我们就本协议和本网站提供的服务的唯一保证和陈述，并取代任何其他途径和方式产生的保证和承诺，无论是书面的或口头的，明示的或暗示的。所有这些保证和陈述仅仅代表我们自身的承诺和保证，并不保证任何第三方遵守本协议中的保证和承诺。",
        content105: "8.8 我们并不放弃本协议中未提及的在法律适用的最大范围内我们享有的限制、免除或抵销我们损害赔偿责任的任何权利。",
        content106: "8.9 您注册后即表示认可我们按照本协议中规定的规则进行的任何操作，产生的任何风险均由您承担。",
        content107: "九、协议的终止",
        content108: "9.1 本网站有权依据本协议约定终止全部本网站服务，本协议于本网站全部服务终止之日终止。",
        content109: "9.2 本协议终止后，您无权要求本网站继续向其提供任何服务或履行任何其他义务，包括但不限于要求本网站为您保留或向您披露其原本网站账号中的任何信息， 向您或第三方转发任何其未曾阅读或发送过的信息等。",
        content110: "9.3 本协议的终止不影响守约方向违约方要求承担其他责任。",
        content111: "十、知识产权",
        content112: "10.1 本网站所包含的全部智力成果包括但不限于网站标志、数据库、网站设计、文字和图表、软件、照片、录像、音乐、声音及其前述组合，软件编译、相关源代码和软件 (包括小应用程序和脚本) 的知识产权权利均归本网站所有。您不得为商业目的复制、更改、拷贝、发送或使用前述任何材料或内容。",
        content113: "10.2 本网站名称中包含的所有权利 (包括但不限于商誉和商标、标志) 均归公司所有。",
        content114: "10.3 您接受本协议即视为您主动将您在本网站发表的任何形式的信息的著作权， 包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利无偿独家转让给本网站所有，本网站有权利就任何主体侵权单独提起诉讼并获得全部赔偿。本协议效力及于您在本网站发布的任何受著作权法保护的作品内容， 无论该内容形成于本协议签订前还是本协议签订后。",
        content115: "10.4 您在使用本网站服务过程中不得非法使用或处分本网站或他人的知识产权权利。您不得将已发表于本网站的信息以任何形式发布或授权其它网站（及媒体）使用。",
        content116: "十一、信息保护",
        content117: "以本网站单独公示的《隐私条款》的约定为准。",
        content118: "十二、计算",
        content119: "所有的交易计算结果都经过我们的核实，所有的计算方法都已经在网站上公示，但是我们不能保证网站的使用不会受到干扰或没有误差。",
        content120: "十三、出口控制",
        content121: "您理解并承认，根据相关法律，您不得将本网站上的任何材料（包括软件）出口、再出口、进口或转移，故您保证不会主动实施或协助或参与任何上述违反法规的出口或有关转移或其他违反适用的法律和法规的行为；如发现此类情形，应及时向我们积极报告并协助我们处理。",
        content122: "十四、转让",
        content123: "本协议中约定的权利及义务同样约束从该权利义务中获取到利益的各方的受让人，继承人，遗嘱执行人和管理员。您不得在我们不同意的前提下转让给任何第三人，但我们可随时将我们在本协议中的权利和义务转让给任何第三人，并给予您通知。",
        content124: "十五、可分割性",
        content125: "如本协议中的任何条款被任何有管辖权的法院认定为不可执行的，无效的或非法的，并不影响本协议的其余条款的效力。",
        content126: "十六、非代理关系",
        content127: "本协议中的任何规定均不可被认为创造了、暗示了或以其他方式将我们视为您的代理人、受托人或其他代表人，本协议有其他规定的除外。",
        content128: "十七、弃权",
        content129: "我们或您任何一方对追究本协议约定的违约责任或其他责任的弃权并不能认定或解释为对其他违约责任的弃权；未行使任何权利或救济不得以任何方式被解释为对该等权利或救济的放弃。",
        content130: "十八、标题",
        content131: "所有标题仅供协议表述方便，并不用于扩大或限制该协议条款的内容或范围。",
        content132: "十九、适用法律",
        content133: "19.1 在提出争议或索赔之前，您确认并同意先通过邮箱联系本网站以非正式方式解决纠纷，我们将尝试尽快在内部解决您的争议；双方同意通过善意协商以解决争议(讨论内容应保密，并受适用规则保护而不被用作任何法律程序中的证据)。",
        content134: "19.2 您确认并同意，如果发生任何争议、争论、分歧或权利主张，包括本条款的存在、有效性、解释、履行、违反或终止或由本条款引起的或与之相关的任何争议（“争议”），上述双方应根据届时有效的《国际商会仲裁规则》将争议提交国际商会国际仲裁院（“ICC国际仲裁院”）。仲裁地点为伯利兹，仲裁的适用法律应根据国际商会仲裁规则确定。除非双方另有约定，否则根据国际商会仲裁规则仅指定一名仲裁员。仲裁员应拥有对其自身管辖权进行裁决的排他性权力，包括但不限于就仲裁协议的存在、范围或有效性或任何索赔或反索赔的可仲裁性提出的任何异议。任何仲裁都将以英语进行。无论仲裁以何种方式进行，仲裁员都应出具一份合理的书面裁决，足以解释裁决和裁决所依据的基本结论和结论(如果有的话)。仲裁裁决应为终局裁决，对各方均具有约束力，并可在任何有管辖权的法院被强制执行。",
        content135: "二十、协议的生效和解释",
        content136: "20.1 本协议于您点击本网站注册页面的同意注册并完成注册程序、获得本网站账号和密码时生效，对本网站和您均具有约束力。",
        content137: "20.2 本协议的最终解释权归本网站所有。",
        content138: "了解你的客户和反洗钱政策",
        content139: "一、导言",
        content140: "1.1 我们保证审慎遵守“了解你的客户”和反洗钱相关的法律法规且不得故意违反该《了解你的客户和反洗钱政策》。在我们合理控制的范围内我们将采取必要的措施和技术为您提供安全的服务，尽可能使您免于遭受犯罪嫌疑人的洗钱行为带来的损失。",
        content141: "1.2 我们的了解你的客户和反洗钱政策是一个综合性的国际政策体系，包括您隶属的不同法律辖区的了解你的客户和反洗钱政策。",
        content142: "二、了解你的客户和反洗钱政策如下：",
        content143: "2.1 颁布了解你的客户和反洗钱政策并时时更新以满足相应的法律法规规定的标准；",
        content144: "2.2 颁布和更新运行本网站的一些指导原则和规则，且我们的员工将按照该原则和规则的指导提供服务；",
        content145: "2.3 设计并完成内部监测和控制交易的程序，如以严格的手段验证身份，安排组建专业团队专门负责反洗钱工作；",
        content146: "2.4 采用风险预防的方法对客户进行尽职调查和持续的监督；",
        content147: "2.5 审查和定期检查已发生的交易；",
        content148: "2.6 向主管当局报告可疑交易；",
        content149: "2.7 身份证明文件、地址证明文件和交易记录的证明文件将会维持至少六年，如被提交给监管部门，恕不另行通知您。",
        content150: "三、身份信息与核实确认",
        content151: "3.1 身份信息",
        content152: "3.1.1 根据不同的司法管辖区的不同规定及不同的实体类型，我们收集的您的信息内容可能不一致，原则上将向注册的个人收集以下信息：",
        content153: "个人基本信息：您的姓名，住址（及永久地址，如果不同） ，出生日期及国籍等可获得的其他情况。身份验证应该是根据官方或其他类似权威机构发放的文件，比如护照，身份证或其他不同的辖区要求的和引发的身份证明文件。您提供的地址将使用适当的方法进行验证，比如检查乘用交通工具的票据或利率票据或检查选民登记册等。",
        content154: "有效的照片：在您注册之前，您须提供您将您的身份证件放在胸前的照片；",
        content155: "联系方式：电话/手机号码和/或有效的电子邮件地址。",
        content156: "3.1.2 如果您是一个公司或其他合法实体，我们将收集以下信息以确定您或信托帐户的最终受益人。",
        content157: "公司注册、登记证；公司的章程与备忘录副本；公司的股权机构和所有权说明的详细证明材料，证明决定本网站账户的开立以及执行的授权委托人的董事会决议；按照要求需要提供的公司董事、大股东及本网站账户有权签字人的身份证明文件；该公司的主要营业地址，如果与公司的邮寄地址不同，提供邮寄地址。如果公司在本地的地址与它的主要营业地址不一致的，则被视为风险较高的客户，需要提交额外附加文件。",
        content158: "根据不同的司法管辖区的不同规定及不同的实体类型，我们要求的其他认证和权威部门发布的文件以及我们认为必要的文件。",
        content159: "3.1.3 我们只接受英语版本或汉语版本的身份信息，如不是，请您将您的身份信息翻译成英文的版本并加以公证。",
        content160: "3.2 确认核实",
        content161: "3.2.1 我们要求您提供身份证明文件的全部页面内容。",
        content162: "3.2.2 我们要求您提供您将您的身份证明文件放在您胸前的照片。",
        content163: "3.2.3 证明文件的副本一般应核和原始凭证进行核对。然而，如果某个可信赖的合适的认证人可证明该副本文件是原始文件准确而全面的复制的，该副本可接受。这样的认证人包括大使、司法委员、地方治安官等。",
        content164: "3.2.4 对识别最终受益人和账户控制权的要求是确定哪些个人最终所有或控制直接客户，和/或确定正在进行的交易是由他人代为执行。如果是企业，则大股东的身份（例如那些持有10％或以上的投票权益）应被验证。一般，持股25 ％会被认定为正常风险内，其股东身份须验证；持股10%或拥有更多的投票权或股票时被认定为高风险的情况，股东身份须加以验证。",
        content165: "四、监控交易",
        content166: "4.1 我们根据安全性和实际交易情况时时设置和调整日常交易和提币最高限额;",
        content167: "4.2 如果交易频繁集中发生在某个注册用户或存在超乎合理的情况，我们的专业团队将评估并决定他们是否可疑;",
        content168: "4.3 我们凭借自身的判断认定为可疑交易的情况，我们可能会采取暂停该交易、拒绝该交易等限制性措施，甚至如果可能将尽快逆转该交易，同时向主管部门报告，但不会通知您;",
        content169: "4.4 我们保留拒绝来自于不符合国际反洗钱标准辖区的人或可被视为政治公众人物的人的注册申请，我们保留随时暂停或终止根据我们自身判断为可疑交易的交易，但我们这样做并不违反对您的任何义务和责任。",
    },
    register: {
        formTitle1: "创建",
        formTitle2: "使用您的电子邮箱或手机号进行注册",
        formTitle3: "账户",
        field1: "验证码",
        field2: "获取",
        field3: "确认密码",
        field4: "邀请码",
        field41: "（选填）",
        field5: "我已阅读并同意",
        field6: "服务条款",
        placeholder1: "请输入验证码",
        content7: "请输入验证码",
        content12: "请输入手机号",
        content13: "请输入密码",
        content14: "请输入邮箱",
        content18: "请再次输入密码",
        content19: "请同意服务条款",
        content20: "请输入正确的验证码",
        content23: "仍然没有收到验证码？",
        content24: "请输入下面的验证码",
        registerSuccess: "注册成功",
        content25: "密码不一致",
        content26: "请输入正确的邮箱地址",
        content27: "密码重置成功"
    },
    download: {
        content1: "交易，随时随地",
        content2: "多终端完美兼容，随时满足各种场景的交易需求",
        content3: "随时查看实时行情",
        content4: "轻松买卖BTC, ETH, XRP和其他数字资产",
        content5: "获取您关注的加密货币价格变化预警",
        content6: "查看比特币现贷、期贷、期权价格",
        content7: "比较各种交易所加密货币价格",
        title: "下载",
        install: "安装说明",
        one: "1、允许下载描述文件",
        two: "2、已下载描述文件",
        three: "3、进入手机【设置】找到【通用】",
        four: "4、描述文件与设备管理器",
        five: "5、点击该描述文件",
    },
    identity: {
        noAuthentication: {
            title: "初级认证",
            pleaseSelectYourCountry: "请选择您的国家",
            name: "姓名",
            enterName: "请输入您的姓名",
            city: "所在的城市",
            enterCity: "请输入您所在的城市",
            IDNumber: "证件号码",
            enterIDNumber: "请输入您的证件号码",
        },
        noAdvancedAuthentication: {
            title: "高级认证",
            infoCountry: "国家/地区",
            infoName: "姓名",
            infoNumber: "证件号码",
            infoAddress: "地址",
        },
        text: "未认证",
        recertification: "重新认证",
        certification: "认证",
        authenticationInProgress: "已认证",
        inAuthentication: "认证中",
        uploadTheFrontOfTheDocument: "上传证件正面",
        uploadTheReverseSideOfTheDocument: "上传证件反面",
        uploadAPhotoOfAHandHeldID: "上传手持证件照片",
        pleaseUpload: "请 ",
        pleaseCompleteOrdinaryCertificationFirst: "需等待初级认证审核成功",
        refused: "已拒绝",
        reason: "理由",
        succeeded: "已成功",
        uploading: "上传中",
        newTitle: "选择您的身份证件的签发国家/地区",
        pleaseOrdinaryCertificationFirst: "请先进行初级认证",
        text1: "修改头像",
        text2: "昵称",
        text3: "账号",
        text4: "昵称修改",
        text5: "新昵称",
        text6: "输入您的新昵称",
        text7: "旧密码",
        text8: "输入您的旧密码",
        text9: "新密码",
        text10: "输入您的新密码",
        text11: "确认密码",

        text12: "再次输入您的新密码",
        text13: "新邮箱",
        text14: "验证码",
        text15: "请输入验证码",
        text16: "您的手机号",
        text17: "新手机号码",
        text18: "请输入您的手机号码",
        text19: "手机验证码",
        text20: "初级认证",
        text21: "请认证您的身份信息",
        text22: "所在国家",
        text23: "所在城市",
        text24: "输入您的所在城市",
        text25: "输入您的姓名",
        text26: "您的证件号",
        text27: "输入您的证件号",
        text28: "证件正面照",
        text29: "点击上传您的证件",
        text30: "重新上传",
        text31: "上传",
        text32: "手持证件照",
        text33: "点击上传您的手持证件照",
        text34: "您已经绑定谷歌验证器",
        text35: "密钥",
        text36: "请输入谷歌验证码",
        text37: "发送",
        text38: "不可为空",
        text39: "邮箱格式有误",
        text40: "两次密码不一致",
        text41: "复制成功",
        text42: "复制失败",
        text43: "头像修改",
        text44: "修改密码",
        text45: "邮箱绑定",
        text46: "修改邮箱",
        text47: "手机绑定",
        text48: "修改手机",
        text49: "谷歌验证",
        text50: "绑定谷歌验证器",
        text51: "输入谷歌验证码",
        text52: "保存",
        text53: "确定提交",
        text54: "修改",
        text55: "绑定",
        text56: "关闭谷歌验证",
        text57: "下一步",
        text58: "完成",
        text59: "谷歌验证已绑定",
        text60: "谷歌验证已解绑",
        text61: "初级认证已提交",
        text62: "高级认证已提交",
        text63: "未上传图像",
        text64: "重新发送",
        text65: "请输入昵称",
        text66: "昵称保存成功",
        text67: "还未上传图像",
        text68: "密码修改成功",
        text69: "邮箱修改成功",
        text70: "头像保存成功",
        text71: "上传图像",
        text72: "您的邮箱",
        text73: "输入您的新邮箱",
        text74: "您的姓名",
        text75: "去认证",
        text76: "请认证您的手持证件照片"
    },
    retrievePassword: {
        formTitle1: "重置密码",
        formTitle2: "为了您的资产安全，修改密码后24小时内禁止交易。",
        formTitle3: "找回密码",
        formTitle4: "确认修改",
        formTitle5: "请输入旧密码",
        formTitle6: "请输入新密码"
    },
    home: {
        title1: "最受欢迎的加密货币交易平台",
        title3: "为什么是 ",
        title4: " 是一个高性能的开源区块链平台，旨在支持和运行安全、合规和可预测的数字基础设施。",
        field1: "老牌交易所",
        field2: "数字资产金融服务",
        field3: "千万级用户规模",
        field4: "设立多重投资者保护基金",
        field5: "快速交易",
        field6: "CME Gruop能够以超低延迟匹配订单，以获得终极交易体验。",
        title5: "市场趋势",
        title6: "查看更多",
        table1: "币种",
        table2: "最新价",
        table3: "24H涨跌",
        table4: "24H成交量",
        table5: "24H交易额",
        table6: "行情",
        title7: "关于 ",
        title8: " 致力于为用户提供最先进的交易工具、创新的技术及无与伦比的优质客户服务。",
        title9: "数字资产领域日新月异，只有高瞻远瞩，迅速进步，才能跟上技术革新的步伐。",
        title901: " 为我们的用户和全球流动性供应商提供最先进的数字资产交易服务。",
        title10: "我们坚信每位用户都值得享有最棒的交易体验。",
        title11: "可信赖的保障安全",
        title12: "我们复杂的安全措施合SAFU基金保护您的数字资产免受所有风险。 ",
        title13: "最优的交易费率",
        title14: "优惠的交易率，富有竞争力的VIP权益，享受最优品质的服务。",
        title15: "24*7小时客服支持",
        title16: "24/7小时全天候、全日制运营模式，第一时间为您解答相关咨询。",
        title17: "0手续费买卖",
        title18: "使用多种支付方式交易数字货币，0手续费，安全快捷。",
        title19: "交易，随时随地",
        title20: "多终端完美兼容，随时满足各种场景的交易需求",
        title21: "随时查看实时行情",
        title22: "轻松买卖BTC, ETH, XRP和其他数字资产",
        title23: "获取您关注的加密货币价格变化预警",
        title24: "查看比特币现贷、期贷、期权价格",
        title25: "比较各种交易所加密货币价格",
        title26: "立即注册",
        title27: "美元",
        title28: "24小时交易量",
        title29: "综合流动性提供者",
        title30: "用户",
        title31: "更多",
        title32: "币安贷新增抵押资产CTSI&KSM，推出BUSD&USDT降息优惠！",
        title33: "我们的优势",
        title34: "安全稳定",
        title35: "顶尖的技术团队，全方位安全保护，自主研发高速交易合引擎，海量交易并发下仍可稳定可靠运行。",
        title36: "专业合规",
        title37: "专业运营团队，多年区块链及金融从业经验，持有合规数字资产交易牌照，百分百备付金保证。",
        title38: "用户至上",
        title39: "面向全球市场，多种语言支持，7*24小时不间断运行，强大的社区支持，专业的客户服务。",
        title40: "高性能",
        title41: "每秒300,000笔交易，订单响应时间不到1毫秒。",
        title42: "关于我们",
        title43: "随时随地交易",
        title44: "完美兼容多终端，随时满足各种场景的交易需求",
        title45: "（扫码下载）",
        title46: "数字货币新闻",
        title47: "我们的合作伙伴",
        title48: "跌幅榜",
        title49: "24h成交额",
        title50: "24h涨跌幅"
    },
    quotation: {
        marketTrading: "行情",
        turnover: "成交量",
        optional: "自选",
        contractMarket: "合约市场",
        currencyPair: "币对",
        latestPrice: "收盘价",
        upsAndDowns: "涨跌幅",
        highest: "最高",
        lowest: "最低",
        transactionAmount: "交易额",
        operate: "操作",
        buy: "交易",
        topTurnovers: "成交榜",
        topGainers: "涨幅榜",
        tradingPair: "交易对",
        buyOrder: "买盘",
        sellOrder: "卖盘"
    },
    accountChange: {
        demo: "模拟",
        realAccount: "实际账户",
        demoAccount: "模拟账户",
        accountRole: "账户角色",
        pleaseSelect: "请选择",
        AccountChangeType: "账变类型",
        AccountType: "账户类型",
        currencyType: "币种类型",
        allType: "全部类型",
        allAccount: "全部账户",
        allCoin: "所有币种",
        coin: "币种",
        amount: "变化金额",
        balance: "账变余额",
        time: "时间",
        title: "账变记录",
        filtrar: "筛选",
        check: "切换到",
        showOnly: "只显示持币币种",
        holdAssets: "持币余额",
        accountList: "账户列表"
    },
    contractTransaction: {
        chart: "图表",
        basicEdition: "基础版",
        depthMap: "深度图",
        second: "秒",
        minute: "分",
        day: "天",
        week: "周",
        month: "月",
        open: "开",
        trade: "交易",
        buyLong: "做多买入",
        buyShort: "做空买入",
        cannotBuy: "该币种不支持交割合约交易",
        cannotBuy1: "该币种不支持永续交易",
        tradingAssets: "交易资产",
        amountAvailable: "可用数量",
        timeStr: "时间",
        transactionPeriod: "交易期间",
        quantity: "数量",
        enterQuantity: "请输入数量",
        buyIn: "做多",
        latestTransaction: "最新成交",
        time: "时间",
        direction: "方向",
        price: "价格",
        sell: "做空",
        positionOrder: "交割订单",
        historicalOrders: "历史订单",
        product: "产品",
        realAccount: "(真实账户)",
        demoAccount: "(模拟账户)",
        openingTime: "下单时间",
        openingPrice: "开仓价格",
        curretPrice: "当前价格",
        liquidationPrice: "平仓价格",
        profitOrLoss: "盈亏",
        endTime: "平仓时间",
        areYouSureToBuy: "确定买入吗？",
        areYouSureToLongBuy: "确定做多买入吗？",
        areYouSureToShortBuy: "确定做空买入吗？",
        successfullyBought: "买入成功",
        minBuy: "最低买入",
        assets: "资产",
        withdraw: "提现",
        entrustedOrder: "委托订单",
        total: "合计",
        continueToPlaceOrder: "继续下单",
        orderConfirmation: "订单确认",
        buyRu: "买入",
        sellChu: "卖出",
        areYouSureToSell: "确定卖出吗？",
        operation: "操作",
        buyCover: "补仓",
        commitSuccess: "交易成功",
        onlyEnterIntegers: "只能输入整数",
        trend: "走势",
        lotteryTime: "截止下单",
        countdown: "倒计时",
        period: "期号",
        cycle: "时间周期",
        unlimited: "无限制",
        put: "买跌",
        call: "买涨",
        pending: "委托中",
        unopened: "未开奖",
        opened: "已开奖",
        settlementPrice: "结算价格",
        rateOfReturn: "收益率",
        currentPositions: "当前持仓",
        positionQuantity: "持仓数量",
        guarantee: "保证金",
        text1: "涨",
        text2: "跌",
        text3: "等待买入",
        text4: "下单数量",
        text5: "成交数量",
        text6: "撤销",
        text7: "交割时间",
        text8: "撤单提醒",
        text9: "确定撤销订单吗",
        text10: "数量不合法",
        text11: "下单提醒",
        text12: "确定买入看涨吗",
        text13: "确定买入看跌吗",
        text14: "撤单成功"
    },
    mining: {
        title1: "世界领先的区块链数字资产挖矿平台",
        title2: "公开透明 · 安全稳定",
        title3: "请选择货币",
        title4: "挖矿年化收益率",
        title5: "天",
        title6: "挖矿期限",
        title7: "产品详情",
        btn: "立即挖矿",
        btn2: "关闭",
        tabs1: "进行中",
        tabs2: "已赎回",
        table1: "产品名称",
        table2: "挖矿期限",
        table3: "挖矿时间",
        table4: "挖矿金额",
        table5: "到期时间",
        table6: "到期收益",
        table7: "订单状态",
        egularMining: "定期挖矿",
        inProgress: "进行中",
        redeemed: "已赎回",
        redemption: "赎回",
        regularMining: "定期挖矿",
        earlyRedemption: "提前赎回",
        need: "需",
        handlingFee: "手续费",
        redemptionConfirmationMessage: "请确认是否提前赎回？",
        form1: "挖矿年化收益率",
        form2: "挖矿期限",
        form3: "挖币币种",
        form4: "认购金额(最小/最大)",
        form5: "提前赎回手续费: ",
        form6: "购买金额",
        form7: "可用资金",
        form8: "购买金额",
        form9: "可用资金",
        form10: "请输入购买金额",
        form11: "全部",
        form12: "预计收益",
        form13: "可用资金不足",
        form14: "请输入购买金额",
        form15: "我的订单",
        form16: "收益",
        form17: "订单详情",
        form18: "提前赎回须知:",
        form19: "认购金额(最小)",
        form20: "认购金额(最大)",
        form21: "持有数量",
        form22: "买入时间",
        form23: "赎回时间",
        form24: "已结束",
        form25: "申购成功",
        form26: "赎回成功",
        form27: "轻松理财超25%收益，跑赢市场行情，购买后还能领奖励",
        form28: "邀请好友奖励50USDT，合约代金券还能返还20%佣金"
    },
    assets: {
        content1: "账户资产",
        content2: "今日收益",
        content3: "当前账户",
        content4: "实际账户即为您的实际账户资产，可用于交易，提现",
        content5: "提币",
        content6: "充币",
        content7: "兑换",
        content8: "划转",
        content9: "请选择",
        content10: "模拟账户",
        content11: "实际账户",
        content12: "获得资产",
        content13: "您确定要切换到实际账户？",
        content14: "您确定要切换到模拟账户？",
        content15: "获取成功",
        table1: "币种",
        table2: "数量",
        table3: "冻结",
        table4: "可用",
        dialog1: "产品详情",
        dialog2: "1、该资产仅允许在模拟账户模式下使用",
        dialog3: "2、为防止滥用，1个月内仅允许领取一次",
        dialog4: "3、领取资产将在原基础上新增领取资产金额，并不改变持仓状态",
        dialog5: "4、如您有其他疑问，请联系客服",
        dialog6: "关闭",
        dialog7: "立即获得演示资产",
        dialog8: "转入",
        dialog9: "转出",
        dialog10: "切换",
        dialog11: "估值",
        dialog12: "演示资产",
        dialog13: "我的账户",
        dialog14: "账户总资产",
        dialog15: "资产总览",
        dialog16: "总资产估值",
        dialog17: "资产估值",
        dialog18: "今日收益",
        dialog19: "冻结资金",
        dialog20: "您每月可领取模拟资产额度",
        dialog21: "领取",
        dialog22: "您已领取成功",
        dialog23: '30日累计收益',
        dialog24: "理财产品",
        dialog25: "我的理财",
        dialog26: "历史理财",
        dialog27: "历史记录",
        dialog28: "资金记录"
    },
    validator: {
        dialog14: "验证器",
        dialog15: "绑定验证器",
        dialog16: "验证器开关",
        dialog17: "验证码",
        dialog18: "验证器二维码",
        dialog19: "验证器密钥",
        dialog20: "保存",
        dialog21: "请选择验证器",
        dialog22: "复制密钥",
        dialog23: "保存成功",
        dialog24: "请输入验证码",
        dialog25: "友情提示：确认保存前请您打开对应的APP扫描下方二维码或手动添加钥匙！",
        dialog26: "启动您的验证器使账户的安全提供最大的保障。",
        dialog27: "您在每次登录或提币时都需要输入一组通过手机访问的唯一验证码。",
        dialog28: "设置验证器",
        dialog29: "帮助",
        dialog30: "您还未绑定身份验证码。借助身份验证器获得更强大的保护。",
        dialog31: "当前还未设置验证器，请先设置",
        dialog32: "去设置"
    },
    recharge: {
        title1: "不知道如何使用数字货币来购买？",
        title2: "只有三个简单的步骤!",
        title3: "从下拉菜单中选择你需要购买的数字货币，然后输入购买数量或金额。",
        title4: "选择你喜欢的支付方式",
        title5: "确认付款后，您购买的数字货币将自动转入您的账户。",
        title6: "请选择您要充值的币种",
        title7: "充币记录",
        table1: "币种",
        table2: "数量",
        table3: "链名称",
        table4: "状态",
        table5: "原因",
        table6: "时间",
        table7: "查看凭证",
        dialog1: "请输入下面的验证码",
        dialog2: "付款码",
        dialog3: "复制",
        dialog4: "地址",
        dialog5: "充币数量",
        dialog6: "交易截图",
        dialog7: "重要通知",
        dialog8: "请填写充币数量",
        dialog9: "上传图片",
        dialog10: "请输入内容",
        dialog11: "请上传充值截图",
        dialog12: "未审核",
        dialog13: "已拒绝",
        dialog14: "已入金",
        tip1: "提交成功",
        tip2: "复制成功",
        tip3: "复制失败",
        tip4: "请选择要充值的币种",
        tip5: "1.以上充币地址为平台官方收款地址，请认准平台官方充币地址，误充造成资金损失需自行承担；",
        tip6: "2.请务必确认电脑及浏览器安全，防止信息被篡改或泄露；",
        tip7: "3.您充值上述地址后，需要整个网络节点的确认后方可到账；",
        tip8: "4.上述代币体系与币种，请选择好后划转相应金额进行充币，请勿转入任何其他无关资产，否则将不可找回。",
        tip9: "保存二维码",
        tip10: "充值",
        tip11: "重要通知",
        tip12: "场外链接",
        tip13: "充值二维码",
        tip14: "下一步",
        tip15: "充值记录",
        tip16: "充值详情",
        tip17: "充币地址",
        tip18: "快捷支付",
        tip20: "余额不足",
        tip22: "已提交，等待确认",
        tip23: "等待付款",
        tip24: "金额",
        tip25: "支付已提交，等待区块确认",
        tip26: "付款被拒绝",
        tip27: "付款失败",
        tip28: "付款成功",
        bankChannel: "银行卡通道",
        tip29: "历史搜索",
        tip30: "币种列表",
        tip31: "选择网络",
        tip32: "当您充值该币种时,请仅限于通过下方支持的网络充值，如您通过其他网络充值会造成资金丢失",
        tip33: "当您提现该币种时,请仅限于通过下方支持的网络提现，如您通过其他网络提现会造成资金丢失",
        tip34: "快捷充值",
        tip35: "扫码充值",
        tip36: "手动充值",
        tip37: "充值网络",

        tip38: "充值地址",
        tip39: "充值金额",
        tip40: "交易回执上传",
        tip41: "上传图片",
        tip42: "确认提交",
        tip43: "确认支付",
        tip44: "备注:请确认您需要充值的地址,若填错导致金额损失我方不负责",
        tip45: "提醒",
        tip46: "地址复制成功!",
        tip47: "地址复制失败!",
        tip48: "可用余额不足",
        tip49: "提交充值成功,等待链上确认完成",
        tip50: "充值失败",
        tip51: "充值金额有误",
        tip52: "连接失败",
        tip53: "钱包未登录",
        tip54: "连接被拒绝",
        tip55: "提交成功,等待客服审核通过",
        tip56: "请上传交易回执截图",
        tip57: "提交失败",
        tip58: "充值记录",
        tip59: "提现记录",
        tip60: "充值币种",
        tip61: "提现币种",
        tip62: "充值地址",
        tip63: "截图显示",
        tip64: "点击放大图片",
        tip65: "充值时间",
        tip66: "拒绝理由",
        tip67: "提现金额",
        tip68: "到账金额",
        tip69: "提现网络",
        tip70: "提现地址",
        tip71: "提现时间",
        tip72: "关闭",
        tip73: "交易截图",
        tip74: "待审核",
        tip75: "已拒绝",
        tip76: "已完成",
        tip77: "已拒绝",
        tip78: "已审核",
        tip79: "已出款",
        tip80: "提取",
        tip81: "转账网络",
        tip82: "地址/票据",
        tip83: "全部提现",
        tip84: "请勿直接提现至众筹或ICO地址，否则将无法收到众筹或ICO发放的代币",
        tip85: "到账数量",
        tip86: "确认提现",
        tip87: "提现地址错误",
        tip88: "提现金额错误",
        tip89: "提现金额至少为",
        tip90: "最大提现金额为",
        tip91: "提现请求已提交",
        tip92: "地址列表",
        tip93: "您还没有添加地址",
        tip94: "立即添加",
        tip95: "添加地址",
        tip96: "添加新地址",
        tip97: "请选择",
        tip98: "地址",
        tip99: "请选择转账网络",
        tip100: "请输入地址",
        tip101: "地址添加成功",
        tip102: "交易中",
    },
    fundExchange: {
        title1: "如何兑换资金？",
        title2: "选择需要兑换的相应货币。",
        title3: "选择要兑换的相应货币。",
        title4: "输入兑换的数量，然后点击提交，完成资金兑换。",
        title5: "从",
        title6: "到",
        title7: "兑换数量",
        title8: "汇率",
        title9: "可用",
        title10: "请输入兑换数量",
        title11: "没有对应的交易对可以进行兑换",
        title12: "请输入要兑换的数量",
        title13: "兑换成功",
        title14: "兑换记录",
        table1: "使用货币",
        table2: "数量",
        table3: "汇率",
        table4: "兑换币种",
        table5: "到账",
        table6: "时间",
        title15: "从",
        title16: "当前无可用资产，请选择其他"
    },
    fundTransfer: {
        title1: "如何转移资金？",
        title2: "选择需要转移的当前账户。",
        title3: "选择要转移的账户。",
        title4: "输入转账金额，点击提交，完成转账。",
        title5: "币种",
        title6: "划转数量",
        title7: "请输入要划转的数量",
        title8: "划转记录",
        title9: "请选择钱包类型",
        title10: "请选择币种",
        title11: "请选择钱包类型",
        title12: "请选择币种",
        title13: "请输入要划转的数量",
        title14: "提交成功",
        table1: "转出账户",
        table2: "转移货币",
        table3: "划转数量",
        table4: "转入账户",
        table5: "时间",
        table6: "划转",
        table7: "资金划转",
        table8: "划转至",
        table9: "划转记录",
        table10: "划转详情",
        table11: "划转成功",
        table12: "划转失败",
        table13: "当前账户不可划转"
    },
    withdrawal: {
        title1: "重要通知",
        title2: "1.为防止套现行为，交易量达到额度即可申请提币。",
        title3: "2.提交提币申请后24H内到账，如预计提币时间后未到账请咨询在线客服查询。",
        title4: "3.提交提币申请后，该笔资金属于冻结状态是因为提现正在进行中，该笔资金暂时由系统托管，并不代表您损失了这笔资产或资产出现异常。",
        title5: "币种",
        title6: "钱包地址",
        title7: "提币数量",
        title8: "提币记录",
        title9: "请选择币种",
        title10: "请选择链类型",
        title101: "",
        title11: "请输入",
        title12: "钱包地址",
        title13: "提交成功",
        title14: "审核中",
        title15: "审核成功",
        title16: "审核失败",
        title17: "已出款",
        title18: "请输入或粘贴钱包地址",
        title19: "请输入提币数量",
        title20: "手续费",
        title21: "实际到账",
        table1: "币种",
        table2: "提币数量",
        table3: "链名称",
        table4: "钱包地址",
        table5: "状态",
        table6: "原因",
        table7: "申请时间",
        table8: "提币",
        table81: "提币",
        table9: "提币地址",
        table10: "请选择您要提币的币种",
        table11: "请确保输入的是",
        table12: "地址（通常以“0x”开头）",
        table13: "可提币（已扣除手续费）：",
        table14: "选择币种",
        table15: "遇到问题？立即反馈",
        table16: "余额",
        table17: "提币详情",
        password: "密码",
        passwordTips: "请输入密码",
        passwordAg: "确认密码",
        passwordAgTips: "请再次输入密码",
        oldPassword: "旧密码",
        oldPasswordTips: "请输入旧密码",
        newPassword: "新密码",
        newPasswordTips: "请输入新密码",
        passwordNotSame: "密码不一致",
        withdrawPasswordSet: "设置提现密码",
        withdrawPasswordModify: "修改提现密码",
        withdrawPassword: "提现密码",
        withdrawPasswordTips: "请输入提现密码",
        setWithdrawPasswordFirst: "请先设置提现密码",
        withdrawPasswordSetSuccess: "设置成功",
        withdrawPasswordModifySuccess: "修改成功",
        submit: "提交",
    },
    customer: {
        content1: "在线客服",
        content2: "客服",
        content3: "请输入您的联系方式",
        content4: "请输入内容",
        content5: "发送",
        content6: "发送失败，网络连接失败",
        content7: "图片",
        content8: "消息为空",
        content9: "我的分享",
        content10: "分享链接",
        content11: "保存图片",
        content12: "我的收益",
        content13: "等级说明",
        content14: "团队人数",
        content15: "邀请收益",
        content16: "规则说明",
        content17: "会员等级",
        content18: "充值金额大于",
        content19: "团队等级",
        content20: "团队等级&收益",
        content21: "满足条件",
        content22: "分销收益",
        content23: "级团队",
        content24: "级，邀请",
        content25: "个有效会员",
        content26: "一级收益",
        content27: "二级收益",
        content28: "三级收益",
    },
    standardContract: {
        increaseMargin: "增加保证金",
        increaseAmount: "增加金额",
        Pleaseenterthemargin: "请输入要增加的保证金金额",
        Pleaseenterthecorrect: "请输入正确的保证金金额",
        Increasemarginsuccessfully: "增加保证金成功",
        takeProfitPriceWrong: "止盈价格错误",
        stoppriceerror: "止损价格错误",
        thetakeprofit1: "止盈价格必须高于市价",
        thetakeprofit2: "止损价格必须低于市价",
        thetakeprofit3: "止盈价格必须低于市价",
        thetakeprofit4: "止损价格必须高于市价",
        thetakeprofit5: "数量错误",
        estimatedprofitloss: "预计盈亏",
        positionAmount: "仓位数量",
        closeAmount: "平仓数量",
        whenthelatest: "当最新价格触达",
        whenthelatest2: "时，将会触发市价止盈委托平仓当前仓位。预期盈亏",
        whenthelatest3: "时，将会触发市价止损委托平仓当前仓位。预期盈亏",
        contract: "合约",
        ordercanceledsuccessfully: "订单撤销成功",
        successfullyordered: "下单成功",
        positionopeningreminder: "开仓提醒",
        wrongquantity: "数量有误",
        quantitycannot: "数量不可大于",
        modificationstoploss: "修改止盈止损成功",
        positionclosedsuccessfully: "平仓成功",
        orderCancellation: "订单撤销",
        orderCancellation2: "确定撤销订单吗",
        tobeexecuted: "待执行",
        expired: "已过期",
        failed: "已失败",
        contractTransaction: "永续合约",
        increase: "涨幅",
        openPosition: "全仓",
        isolatedPosition: "逐仓",
        limitOrder: "限价",
        marketOrder: "市价",
        pleaseSelectLeverage: "请选择杠杆",
        pleaseEnterQuantity: "请输入数量",
        canOpenMore: "最大可开仓",
        contractAmount: "合约金额",
        openingFee: "手续费",
        currentPosition: "持仓",
        historicalPositions: "历史成交",
        currentCommission: "当前委托",
        historicalCommission: "历史委托",
        buyPrice: "买入价格",
        income: "盈利",
        quantity: "数量",
        marginRate: "保证金率",
        initialMargin: "初始保证金",
        estimatedPriceParity: "强制平仓价",
        operate: "操作",
        finishQuantity: "已成交量",
        averagePrice: "成交均价",
        pleaseEnterLeverage: "请输入杠杆",
        takeProfitAndStopLoss: "止盈止损",
        takeProfit: "止盈",
        stopLoss: "止损",
        openLong: "开多",
        openShort: "开空",
        liquidation: "平仓",
        marketLquidation: "市价全平",
        cancel: "撤单",
        enterBuyPrice: "请输入买入价格",
        areYouSureLquidation: "确定平仓吗?",
        confirm: "确定",
        enterStopWinPrice: "请输入止盈价格",
        enterStopLossPrice: "请输入止损价格",
        commissionTime: "委托时间",
        commissionPrice: "委托价格",
        commissionQuantity: "委托总量",
        volumeAlreadyTraded: "已完成量",
        areYouSureToWinOrLoss: "确定要止盈止损吗?",
        maxQuantity: "最大持仓量",
        saleQuantity: "已平仓量",
        salePrice: "平仓均价",
        saleTime: "时间",
        succeeded: "已成功",
        cancelled: "已取消",
        piece: "张",
        triggerPrice: "触发价格",
        setWin: "设置止盈",
        setLoss: "设置止损",
        enterWinQuantity: "请输入止盈数量",
        enterLossQuantity: "请输入止损数量",
        planCommission: "计划委托",
        enterWinTriggerPrice: "请输入止盈触发价格",
        enterLossTriggerPrice: "请输入止损触发价格",
        insufficientQuantity: "数量不足",
        enterTriggerPrice: "请输入触发价格",
        tips1: "您的委托价格比当前价格高，会以当前市价直接成交，是否确定买入？",
        tips2: "您的委托价格比当前价格低，会以当前市价直接成交，是否确定买入？",
        type: "类型",
        increaseQuantity: "增加持仓",
        reduceQuantity: "减少持仓",
        limitCommission: "限价委托",
        tips3: "止损的价格必须低于市价",
        tips4: "止盈的价格必须高于市价",
        tips5: "止损的价格必须高于市价",
        tips6: "止盈的价格必须低于市价",
        tips7: "止损价格要小于市价，小于委托价格",
        tips8: "止盈价格要大于委托价格（可以比市价小）",
        tips9: "止损价格要大于市价，大于委托价格",
        tips10: "止盈价格要小于委托价格（可以比市价大）",
        accountEquity: "账户余额",
        unrealizedPL: "未实现盈亏",
        tips11: "你确定对",
        tips12: "按市价进行",
        tips13: "全部平仓",
        tips14: "不再提示",
        profitRate: "平仓收益率",
        openTime: "开仓时间",
        unwindTime: "平仓时间",
        dealTime: "成交时间",
        amountClose: "已平仓金额",
        long: "多",
        short: '空',
        orderQuantity: "委托数量",
        entrustedMargin: "委托保证金",
    },
    spotGoods: {
        spotGoods: "闪兑",
        sellPrice: "卖出价格",
        orderRecord: "闪兑记录",
        transactionTime: "成交时间",
        maxBuy: "可买",
        maxSell: "可卖",
        purchaseQuantity: "购买数量",
        slippage: "滑点",
        convertTips: "我们将以最优惠的价格成交，滑点在0-5%。",
        changePrice: "当前兑换价格",
        consume: "消耗",
        consumeAmount: "消耗数量",
        get: "获得",
        getAmount: "获得数量",
        changeTips: "确定要兑换吗？",
        confirm: "确认兑换"
    },
    news: {
        title: "新闻中心",
        redMore: "阅读更多",
    },
    credit: {
        credit: "信用分",
        creditScore: "信用总分",
        latestEntry: "最新记录",
        liveUpdate: "实时更新",
        systemEvaluation: "系统评估",
        time: "日期",
        name: "名称",
        reason: "原因",
        scoreStory: "信用分记录"
    },
    borrowing: {
        borrowing: "借贷",
        diversifiedMarket: "多样化市场",
        totalSupply: "供应总数",
        totalBorrowings: "总借款",
        tips1: "这是您可以借入的总金额，您可以根据您的抵押品借款，直到达借款上限。",
        tips2: "在您贷款期间，稳定的利率将保持不变。推荐用于长期供应和喜欢可预测性的用户。",
        totalSupplys: "供应总量",
        supplyAPR: "供应利率",
        totalBorrowings: "借款总额",
        borrowAPR: "借用利率",
        check: "查看",
        market: "市场",
        availableLiquidity: "可用流动性",
        currencyPrice: "当前货币价格",
        supplyAssets: "供应资产",
        tips3: "须知：下方利率为固定利率，供应周期结束后，本金加收益则会返回到您的账户中。",
        supplyCycle: "供应周期",
        minimumSupply: "最低供应",
        supply: "供应",
        borrowedAssets: "借入资产",
        tips4: "须知：要借款，您需要提供任何抵押资产或证明，",
        tips5: "请查看抵押资产说明。",
        repaymentCycle: "还款周期",
        BorrowableAmount: "可借额度",
        borrow: "借款",
        accountInformation: "账户信息",
        walletBalance: "钱包余额",
        canSupply: "可供应",
        AmountCanBeBorrowed: "可借总额度",
        borrowedAmount: "已借额度",
        remainingAmount: "剩余额度",
        getBorrowednAmount: "获取借款额度",
        supplyOrder: "供应订单",
        borrowedOrder: "借款订单",
        supplyAmount: "供应金额",
        expectedProfits: "预期收益",
        supplyTime: "供应时间",
        expirationTime: "到期时间",
        borrowingAmount: "借款金额",
        dueRepayment: "还款金额",
        borrowingTime: "发放时间",
        repaymentTime: "还款时间",
        repay: "还款",
        supplyCurrency: "供应币种",
        buyNow: "立即买入",
        supplyRecords: "供应记录",
        borrowRecords: "借款记录",
        completed: "已完成",
        borrowNow: "立即借款",
        borrowCurrency: "借款币种",
        borrowMin: "最小",
        borrowMax: "最大",
        borrowRange: "借款金额（最小/最大）",
        tips6: "请及时还款，若逾期，每日额外收取（本金+利息)金额中的",
        tips7: "为逾期费。",
        pleaseEnterBorrowAmount: "请输入借款金额",
        interest: "利息",
        tips8: "确定要提前还款吗？",
        borrowChannel: "借款通道",
        tips9: "让我们从认识你开始",
        name: "名",
        lastName: "姓",
        birthDate: "出生日期",
        tips10: "再多一点信息，你的地址是什么？",
        familyAddress: "家庭住址（包括单位编号）",
        city: "城市",
        country: "国家",
        postalCode: "邮政编码",
        pleaseEnterYourHomeAddress: "请输入家庭地址",
        tips11: "告诉我们您的财务状况",
        tips12: "我们可能需要稍后确认此信息，因此请尽量准确",
        yourMonthlyIncome: "您的月收入",
        enterYourMonthlyIncome: "请输入您的月收入",
        extraIncomeOptional: "额外收入（可选）",
        enterExtraIncome: "请输入您的额外收入",
        monthlyRentOrMortgagePayment: "每月租金或按揭付款",
        enterMonthlyRent: "请输入您的每月租金或按揭付款",
        tip13: "告诉我们您是否有抵押",
        tip14: "如未选择抵押担保，则审核通过概率较低",
        isThereMortgage: "是否有抵押",
        selectIsThereMortgage: "请选择是否有抵押",
        selectMortgageType: "请选择抵押类型",
        yes: "是",
        no: "否",
        uploadScreenshotOfTransaction: "请上传交易截图",
        tip15: "让我们保持联系",
        tip16: "如果我们需要有关您的申请贷款的更多信息，我们可能会通过此号码与您联系",
        tip17: "我已阅读并以电子签名方式同意",
        tip18: "使用条款和电子同意书",
        tip19: "，其中包括您同意我们向您发送有关我们的",
        tip20: "产品和服务使用条款",
        tip21: "资料填写完成之后，请等待系统评估审核。",
        phoneNumber: "电话号码",
        enterPhoneNumber: "请输入您的电话号码",
        tip22: "请勾选同意使用条款和电子同意书",
        inReview: "审核中",
        applicationAmount: "申请额度",
        reasonForRejection: "拒绝理由",
        reapply: "重新申请",
        applyStatus: "申请状态",
        currentOrder: "当前订单",
        interestRate: "利率"
    },
    yuebao: {
        filed1: "投入",
        filed2: "取出",
        filed3: "日收益",
        filed4: "团队加速",
        filed5: "已存入",
        filed6: "昨日收益",
        filed7: "总收益",
        filed8: "可用资金",
        filed9: "请输入金额",
        filed10: "流动性挖矿",
        filed11: "简介",
        field12: "最低可买",
        field13: "最多可买",
        filed14: "申购",
        filed15: '定期',
        filed16: '年化利率',
    },
    tipsPopover: {
        title: "安装此应用程序",
        press: "按",
        and: "和",
        scrren: "添加到主屏幕",
        hasInstall: "(如已安装，请关闭窗口)",
    },
    follow: {
        title1: "跟单计划",
        follow_text1: "发起跟单",
        follow_text2: "发布历史",
        follow_text3: "标题",
        follow_text4: '交易对',
        follow_text5: '购买时长',
        follow_text6: '下单时间',
        follow_text7: '发布时间',
        follow_text8: '跟单人数',
        follow_text9: '跟单金额',
        follow_text10: '操作',
        follow_text11: '邀请',
        follow_text12: '跟单方向',
        follow_text13: '设置跟单条件',
        follow_text14: '跟单条件限制',
        follow_text15: '固定金额',
        follow_text16: '百分比',
        follow_text17: '受邀人',
        follow_text18: '对方的id',
        follow_text19: "确定跟单",
        follow_text20: "您的跟单金额（选填）",
        follow_text21: "请输入标题",
        follow_text22: "请选择交易对",
        follow_text23: "请选择跟单方向",
        follow_text24: "请选择购买时长",
        follow_text25: "请选择下单时间",
        follow_text26: "请选择购买数量",
        follow_text27: "最大购买数量不可小于最小购买数量",
        follow_text28: "最低买入",
        follow_text29: "最多买入",
        follow_text30: "创建跟单成功",
        follow_text31: "交易对列表",
        follow_text32: "已跟单",
        follow_text33: "请输入受邀人信息",
        follow_text34: "已邀请",
        follow_text35: "邀请我的",
        follow_text36: "跟单历史",
        follow_text37: "跟单金额范围"
    },
    followHistory: {
        title1: '发布历史',
        title2: "跟单历史",
        follow_h_text1: "跟单总金额",
        follow_h_text2: "跟单总人数",
        follow_h_text3: "发布次数",
        follow_h_text4: "胜率",
        follow_h_text5: "跟单次数",
        follow_h_text6: "跟单盈利",
        follow_h_text7: "方向",
        follow_h_text8: "赢",
        follow_h_text9: "亏",
    },
    "asset.index.1579E8B41F8D5134": "我的资产",
    "asset.index.B3F6F05Dfbe431Cd": "模拟",
    "asset.index.B9Aeab4Ee8F33746": "资产估值",
    "asset.index.583Df8Bd541Eb7E8": "收益趋势",
    "asset.index.B1254815D133Cc26": "7天",
    "asset.index.2F615F6C029F01B7": "30天",
    "asset.index.29439Fb58E4Bbe22": "提现",
    "asset.index.831Ab31568A78285": "充值",
    "asset.index.39F7A0D48D12E8C9": "划转",
    "asset.index.B99063C5689Beaec": "闪兑",
    "asset.index.E1Dc620F2F038B2A": "领取资产",
    "asset.index.972A0B4C2B4393D0": "我的账户",
    "asset.index.9Dfb0Fb334620733": "可用余额",
    "asset.index.Cdc5Fbd26790Cb47": "冻结金额",
    "asset.index.3D8132D72Ae965Cd": "估值",
    "asset.receive.825503Cc430559Cb": "获得资产",
    "asset.receive.54154A0553A535B7": "演示资产",
    "asset.receive.F2E57B00A9C5D129": "1.该资产仅允许在模拟账户模式下使用",
    "asset.receive.Ea0D1D52989299Bd": "2.为防止滥用，1个月内仅允许领取一次",
    "asset.receive.090E0037Aa235D3D": "3.领取资产将在原基础上新增领取资产金额，并不改变持仓状态",
    "asset.receive.7Fd4E1Abc805C0A6": "4.如您有其他疑问，请联系客服",
    "asset.receive.Df5B3A511D986575": "立即获得演示资产",
    "asset.records.1Fa02E8187A39592": "近三个月",
    "asset.records.7B291C37333Fe34D": "由近到远",
    "asset.records.A476Cd1Ec868Fc5F": "近三个月,",
    "asset.records.9D5755A842E55Bb7": "由近到远,",
    "asset.records.E3Fa05E607203Cdc": "充值",
    "asset.records.B4F206C6Bec08Dea": "提现",
    "asset.index.Df5B3A511D986576": "您已成功领取演示资产",
    "deposit.depositSpeedy.Efe81D0E0174239B": "支付方式",
    "deposit.depositSpeedy.B9293Ee96E435F85": "法币",
    "deposit.depositSpeedy.16658A855F07D171": "金额",
    "deposit.depositSpeedy.204Ffab8A6E01870": "确定",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "不可为空",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "最小充值",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "最大充值",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "充值USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "充值币种",
    "deposit.depositWallet.B404C543712B71D5": "充值网络",
    "deposit.depositWallet.A484B0F5E94B3F4F": "充币地址",
    "deposit.depositWallet.D3980D54Acf242Db": "保存二维码",
    "deposit.depositWallet.A06E84801D6582Be": "充值金额",
    "deposit.depositWallet.Daaaeb1B7B22B126": "提示",
    "deposit.depositWallet.D599E40E1Eeec1B3": "充值成功",
    "deposit.depositWallet.4F48Abbafce532Bb": "提交成功，等待客服审核",
    "deposit.depositWallet.A88C481228811696": "您拒绝了切换网络",
    "deposit.depositWallet.C6Ebf27895C21932": "请手动打开波场钱包插件并登录",
    "deposit.index.B5491C8Dd09D8183": "选择您要充值的货币",
    "deposit.index.2F784B2298D39987": "快捷充值",
    "deposit.index.0Daa03Dcdcabd837": "场外链接",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "提现确认",
    "withdraw.bottomSheet.A086Fa20082C849D": "请确保以下所填信息正确无误",
    "withdraw.bottomSheet.Bf8B184D32971501": "提币网络",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "地址",
    "withdraw.bottomSheet.F9Db93B87E08763B": "数量",
    "withdraw.bottomSheet.4Ad26475002878C0": "手续费",
    "withdraw.index.A96454041169Af6D": "选择币种",
    "withdraw.index.2A563B7081Dd5B83": "提币地址",
    "withdraw.index.B189C2Ef713Cfff4": "提币数量",
    "withdraw.index.558F35140E84B562": "可用：",
    "withdraw.index.F4E759087E12Cb77": "全部提现",
    "withdraw.index.5Cbd9A6B284D25Ae": "资金密码",
    "withdraw.index.B89Cbe4Bfc33Bb41": "手续费:",
    "withdraw.index.C886De8D8A27026E": "重要通知",
    "withdraw.index.Eac7C5A4095F5606": "1.为防止套现行为，交易量达到额度即可申请提币。",
    "withdraw.index.C3Cc7Fff24504Be9": "2.提交提币申请后24H内到账，如预计提币时间后未到账请咨询在线客服查询。",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.提交提币申请后，该笔资金属于冻结状态是因为提现正在进行中，该笔资金暂时由系统托管，并不代表您损失了这笔资产或资产出现异常。",
    "withdraw.index.4639F3A6E07C00B3": "下一步",
    "withdraw.index.81Fba0D65Cc5C3Aa": "请输入或粘贴钱包地址",
    "withdraw.index.25768141C8Bdea91": "请输入提币数量",
    "withdraw.index.97A85734Cd1B7Bf0": "请输入资金密码",
    "withdraw.index.39C7D74B33241C78": "提现数量有误",
    "withdraw.index.86A5D17908197525": "提现确认，等待审核放币",
    "withdraw.index.48Fb603C6638C7F6": "请设置资金密码",
    "withdraw.index.873Ade9A3Bacc2F7": "提示",
    "bill.index.5Bbb2A6Ac68D6B2E": "实际账户",
    "bill.index.75751E0Eb8261C40": "模拟账户",
    "bill.index.Cbc853B37Ecc2E97": "账变类型",
    "bill.index.4A0Cacddeb2E38B1": "账户类型",
    "bill.index.F16217028E098B2F": "币种",
    "bill.index.A620Dbe6F06B78Ba": "全部类型",
    "bill.index.2C3C148Bb94D9B3F": "全部账户",
    "c2C.index.Ab45695E4Bf1E382": "C2C交易",
    "c2C.index.2702Dc911Dfea762": "我要买",
    "c2C.index.D737D5B8367A53C4": "我要卖",
    "c2C.index.C0C540A3Ba9Abe01": "成交量",
    "c2C.index.D8644E8D60C8Bd8A": "单价",
    "c2C.index.Ebb8670B203D3604": "限额",
    "c2C.index.F8C6245A2Ebf1D27": "所有银行卡",
    "c2C.index.F8C6245A2Ebf1D28": "币收款",
    "c2C.index.F8C6245A2Ebf1D29": "银行卡",
    "c2C.index.Cc3Aa8141Da9Ec9E": "购买",
    "c2C.index.Cc3Aa8141Da9Ec9F": "出售",
    "c2C.index.67A39834582Ad99A": "暂无数据",
    "c2C.order.04C23810B47F77F7": "按金额",
    "c2C.order.6905365902E0A73D": "按数量",
    "c2C.order.3F0Ecebbd9D2F23B": "可用",
    "c2C.order.16853Bda54120Bf1": "全部",
    "c2C.order.Ad3335796F2C95F7": "总额",
    "c2C.order.61092E27A6879318": "保护资产安全，请提高防范意识！",
    "c2C.order.0F51881F0Fb920B2": "交易信息",
    "c2C.order.Dd74Ceca85E5Bc65": "付款时限",
    "c2C.order.De3341388A59073F": "30分钟",
    "c2C.order.D334B597A352167F": "卖家昵称",
    "c2C.order.D334B597A352167G": "买家昵称",
    "c2C.order.9D5F89F99Ced890D": "交易方式",
    "c2C.order.8Adf755B14Bc6B7C": "请先阅读以下内容",
    "c2C.order.686B16F454A7338B": "银行卡转账切勿备注，不然不给予放币和直接封其账号。付款后需要提供打款后新的交易明细图（如果P图或者隐藏交易明细上报平台冻结账户）",
    "c2C.order.9Bec8Fba867B739B": "选择收款方式",
    "c2C.order.Cf2D892C419Fea36": "最小购买",
    "c2C.order.7290A6B7456A03E0": "最大购买",
    "c2C.orderConfirm.F7E99B8C203E2B07": "订单详情",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "订单已生成",
    "c2C.orderConfirm.0F5433503F3845Ce": "请在倒计时时间内付款给卖家。",
    "c2C.orderConfirm.24C3Bcacdb85A451": " 等待买家付款 ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "联系卖家",
    "c2C.orderConfirm.8E8140D7Ab607B25": "联系买家",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "卖家的数字货币现已存入托管账户，请放心付款  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "买家实名：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "7x24小时客服支持 ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "订单号",
    "c2C.orderConfirm.726C51702F70C486": "创建时间",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "联系客服",
    "c2C.orderConfirm.103408Fbbe30287F": "取消订单",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "我已确认付款",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "已付款",
    "c2C.orderConfirm.387Ca3625B260E4E": "您已付款订单",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "交易取消",
    "c2C.orderConfirm.76C86566Cc3751C0": "订单已取消",
    "c2C.orderConfirm.205B349751D176B4": "支付成功",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "收款方式",
    "c2C.paymentAdd.C87E865Df6800304": "支付方式配置",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "支付方式类型",
    "c2C.paymentAdd.06910Bddf002695F": "真实姓名",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "PayPal账号",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "银行账号/卡号",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "币种名",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "支付二维码",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "点击上传",
    "c2C.paymentAdd.6Daae340F2E9718A": "银行名称",
    "c2C.paymentAdd.347751415C3Da489": "链名称",
    "c2C.paymentAdd.15B3627Faddccb1D": "备注",
    "c2C.paymentAdd.86Da96Cbd274058F": "温馨提示：当您出售数字货币时，您选择的收款方式将向买方展示，请确认信息填写准确无误。",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "保存",
    "c2C.paymentAdd.90D2A3A5C07179C0": "请输入您的真实姓名",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "请输入您的",
    "c2C.paymentAdd.Ec389E6190Eedb62": "请输入您的银行名称",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "请输入您的链名称",
    "c2C.paymentAdd.94F034F0E01439E0": "请输入您的地址",
    "c2C.paymentAdd.B12E035663A42B32": "所有银行卡",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "币收款",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "请上传收款码",
    "c2C.paymentAdd.07C443212Ff04Bfb": "添加成功",
    "c2C.paymentDetail.86Da96Cbd274058F": "温馨提示：当您出售数字货币时，您选择的收款方式将向买方展示，请确认信息填写准确无误。",
    "c2C.paymentDetail.C4C613Da60226De4": "修改成功",
    "c2C.paymentMethod.7609F7C8337C7A17": "C2C收款方式",
    "c2C.records.9E36893D5736Abb4": "订单列表",
    "c2C.records.4B53F9A5B13B3235": "待付款",
    "c2C.records.4B53F9A5B13B3236": "交易取消",
    "c2C.records.4B53F9A5B13B3237": "已付款",
    "contract.index.9706Df877458F0Be": "全仓",
    "contract.index.623Fc7D3D40Aa479": "初始保证金",
    "contract.index.F92Dce1169330387": "市价全平",
    "contract.index.3598B97Dd28Dbf20": "请输入数量(张)",
    "contract.index.6E1Fae821Dc0B797": "永续",
    "contract.index.Eb07Eb0C37C14575": "交割",
    "contract.index.Eb6492F19B1C1Fb6": "历史成交详情",
    "contract.index.873Ade9A3Bacc2F7": "提示",
    "contract.index.Ff63B0F93D39F50B": "你确定对",
    "contract.index.Ff63B0F93D39F50D": "做多",
    "contract.index.Ff63B0F93D39F50E": "做空",
    "contract.index.Ff63B0F93D39F50F": "进行",
    "contract.index.Ff63B0F93D39F50G": "全部平仓？",
    "customer.dialog.3D410C88E8A24Fe8": "确认昵称",
    "customer.dialog.4B3340F7872B411F": "取消",
    "customer.dialog.549B1456Bb5F98B3": "请输入您的昵称",
    "customer.dialog.B773B3E45Ccfad00": "昵称为空",
    "customer.index.7308C6B849D159E9": "图片详情",
    "delivery.callDialog.C74F562C3Fe91Fe3": "下单成功",
    "delivery.callDialog.B8C98F42Caa15Ca5": "方向",
    "delivery.callDialog.B79A581Dae543974": "做多买入",
    "delivery.callDialog.B79A581Dae543975": "做空买入",
    "delivery.callDialog.3B26F0F7E90773F3": "开仓价格",
    "delivery.callDialog.65Fb252Bd94E1039": "预计收益",
    "delivery.index.B483Ba4Fce633539": "交割",
    "delivery.index.224Dd6Cb58D58249": "交易期间",
    "delivery.index.28158F517E902788": "最低买入",
    "delivery.index.A1232162B14Bec76": "交割订单",
    "delivery.index.445B1D3176D9C69D": "历史订单",
    "delivery.index.Ab6Cb86E28653C5C": "交割",
    "delivery.index.20A7A6F14D3698C0": "开仓时间",
    "delivery.index.Ec6821A49395Ddc2": "平仓时间",
    "delivery.index.254Fdc665C391743": "平仓价格",
    "delivery.index.2A0C5E68B44A472A": "盈亏",
    "delivery.index.E05B33309F6D236A": "请输入数量",
    "delivery.index.B2899E073Cb355D9": "最少买入",
    "download.description.869467A93C7Dd4A1": "安装说明",
    "download.description.C80C1Edd6Bee12Bc": "1.允许下载描述文件",
    "download.description.2F5Bc180F92642B4": "2.已下载描述文件",
    "download.description.3C3B849F6Ccf712F": "3.进入手机【设置】在账号信息的下面会显示已下载描述文件",
    "download.description.86Affd862985E045": "4.点击已下载描述文件",
    "download.description.32832492Bfc1D95E": "5.点击安装",
    "download.index.245883C80F181C4A": "下载",
    "download.index.Ef3De8Cb2722937A": "随时随地，开启交易",
    "download.index.F4A87Eaeed691638": "通过我们的APP，随时了解最新资迅",
    "download.index.Ad3Aba52D0D39D01": "下载安装包",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "温馨提示",
    "download.safariGuide.2Cc68Aaadf623477": "请在Safari中访问",
    "download.safariGuide.C80Fff2A6Ff4E768": "您正在访问一个分散的网站，请在safari应用程序中运行",
    "download.safariGuide.06Da016F3E2F79Bd": "复制链接",
    "finance.index.B034B367545D5236": "全球优质项目",
    "finance.index.6Ebfac2C0A9228B0": "活跃人数160万+，$1,224亿",
    "finance.index.52Bdcaf19659363D": "流动性挖矿",
    "finance.index.D66924272B031Bf5": "超高年化收益",
    "finance.index.34789D88Afcefa64": "查看项目",
    "finance.index.E12676B95654D21E": "信用借贷",
    "finance.index.4C924Cfd89A11Ac7": "发现更多交易机会",
    "finance.index.C251D4B594Da2848": "AI量化",
    "finance.index.4E53Cf2E5F173Dd3": "一键租赁，AI智能交易",
    "finance.records.Cce168301447D1Ba": "进行中",
    "finance.records.4587B6Bd7Cfa4454": "已赎回",
    "finance.records.18685418E3A50147": "挖矿",
    "finance.records.Df9Fd438F97Cc908": "天",
    "finance.records.E23Cb03C5Ad359E5": "期限",
    "finance.records.Bd87Dac6Cba7Eba8": "购买数量",
    "finance.records.2D0Fff99265E8B1A": "已盈利",
    "finance.records.2D0Fff99265E8B1B": "到期盈利",
    "finance.records.024F5F0B851782De": "挖矿时间：",
    "finance.records.3143A26D3F983896": "赎回时间：",
    "finance.records.96574Aaf8Db85Ead": "提前赎回",
    "finance.records.531E1829203181Ac": "您确定要提前赎回吗;",
    "finance.records.9205843B8923Dac3": "赎回成功;",
    "loan.applyCallback.B9258Ec8746A74D8": "借款通道",
    "loan.applyForm.7B8Ed340400A6E2B": "名",
    "loan.applyForm.549A44Bae725F4A2": "姓",
    "loan.applyForm.2A27E61375E7Fea8": "出生日期",
    "loan.applyForm.89925B019D537738": "电子邮件地址",
    "loan.applyForm.Afaeb32Cb4D62E28": "家庭住址（包括单位编号）",
    "loan.applyForm.E05B5D049B64B040": "城市",
    "loan.applyForm.B166E4E8Fe9513Fa": "国家",
    "loan.applyForm.83Aaede109E395F8": "邮政编码",
    "loan.applyForm.647Ed21332A4968B": "您的月收入",
    "loan.applyForm.Cf0A9B845D34D4B4": "额外收入（可选）",
    "loan.applyForm.7048F618Acc6A54C": "每月租金或按揭付款",
    "loan.applyForm.15C1Bba18Ae18545": "告诉我们您是否有抵押",
    "loan.applyForm.3921E90625A84E36": "如未选择抵押担保，则审核通过概率较低",
    "loan.applyForm.262Eec4F8906B0F6": "是否有抵押",
    "loan.applyForm.8634Eb21B6910F21": "请选择抵押类型",
    "loan.applyForm.A4897B3A331B5E61": "交易凭证",
    "loan.applyForm.6623384C683De457": "让我们保持联系",
    "loan.applyForm.A8360Bdba86834Cb": "如果我们需要有关您的申请贷款的更多信息，我们可能会通过此号码与您联系",
    "loan.applyForm.C1C373F385A4C7B3": "电话号码",
    "loan.applyForm.1B9785Badb91191F": "资料填写完成之后，请等待系统评估审核。",
    "loan.applyForm.8755B9C761D4080C": "我已阅读并以电子签名方式同意",
    "loan.applyForm.3E987B787468148C": "使用条款和电子同意书",
    "loan.applyForm.Bc54E76B61C50Fb9": "请阅读并同意协议",
    "loan.applyForm.89E158384F634Eb8": "返回上一步",
    "loan.applyForm.Efa5A4548E2F3510": "家庭住址",
    "loan.applyForm.4Cadf1787541B35D": "是,",
    "loan.applyForm.Fab408D2F4100447": "否,",
    "loan.applyForm.3Bad7A307480Cc56": "邮箱格式有误;",
    "loan.applyForm.306322F49B36855D": "已提交申请，请等待客服审核",
    "loan.applyForm.3C6E0E858C995Ff9": "请上传交易凭证",
    "loan.applyForm.2Bf443476B271376": "最多上传4张图片",
    "loan.applyQuota.Ef1D8E23003B9A6F": "借款通道",
    "loan.applyQuota.Ef1D8E23003B9A6G": "获取借款额度",
    "loan.applyQuota.9E0780F9352C7273": "钱包余额",
    "loan.applyQuota.Cbc1Eb9D639A6105": "可借总额度",
    "loan.applyQuota.033A05462195B080": "已借额度",
    "loan.applyQuota.9Aa89B036Ef4821D": "剩余额度",
    "loan.applyQuota.5656Fb52Ef17A557": "获取借款额度",
    "loan.applyQuota.6Ff6702B4C917D40": "审核中",
    "loan.applyQuota.6Ff6702B4C917D41": "已拒绝",
    "loan.applyQuota.6Ff6702B4C917D42": "已成功",
    "loan.applyQuota.0Bad0E857Ea4A952": "申请时间",
    "loan.applyQuota.3E7E56E2De2Cc546": "申请额度",
    "loan.applyQuota.8F1D170D9E54Af5B": "申请进度",
    "loan.applyQuota.6167Ca035B93Caf3": "原因",
    "loan.applyQuota.410C7954F9A3981D": "重新申请",
    "loan.bottomSheet.61Cd62F861D8F5B2": "请选择币种",
    "loan.index.4C10307B22E885C3": "发现更多交易机会创造财富",
    "loan.index.Ae8D37Dea2B3717B": "利率",
    "loan.index.1Bb54625682E85Db": "规则介绍",
    "loan.index.5Bd31Fab681C6E9D": "查看更多",
    "loan.index.111F95F404814327": "借贷币种",
    "loan.index.6Bc2723B3Bec106D": "借贷",
    "loan.index.3200106280E6E370": "利率",
    "loan.index.049A6Ccb6C81A635": "借贷数量（最小/最大）",
    "loan.productDetails.5037Ad86Ab4B7E02": "借贷",
    "loan.productDetails.37181C74559Db182": "订单 ",
    "loan.productDetails.5802Aaf06A1E4755": "可流动性",
    "loan.productDetails.89Aea9Cf9337C40F": "可借数量",
    "loan.productDetails.9E419Ae2D06F3Ad9": "待还数量",
    "loan.productDetails.C65F329Ef14C4A3D": "借币数量",
    "loan.productDetails.3A74667815Adc8Bf": "最大",
    "loan.productDetails.Ab851D8351573227": "天",
    "loan.productDetails.Ddf82D8A2C8F46A0": "还款周期",
    "loan.productDetails.C105E5562005Aeb1": "天",
    "loan.productDetails.3F346Be2137F0756": "借贷数量（最小/最大）",
    "loan.productDetails.2707F78Df864E194": "利息",
    "loan.productDetails.B9Dc9B81E726Bdf1": "请及时还款，若逾期，每日额外收取（本金+利息）金额中的",
    "loan.productDetails.B9Dc9B81E726Bdf2": "%为逾期费",
    "loan.productDetails.4D94Faa033Be7356": "我已同意",
    "loan.productDetails.62429A957A22780A": "借币服务协议",
    "loan.productDetails.Bc54E76B61C50Fb9": "请阅读并同意协议",
    "loan.productDetails.Ec8Ba071C53A67Cb": "立即借款",
    "loan.productDetails.59246A0Bf081D398": "最少",
    "loan.productDetails.Ec3F1172B71Cd36C": "最小数量",
    "loan.productDetails.7Ccc4Cf1D81930B3": "最大数量",
    "loan.productDetails.071488598990Aa68": "请阅读并同意协议;",
    "loan.productDetails.579Dd216D3339119": "借贷成功;",
    "loan.records.4951B36Fd0D2A831": "借贷记录",
    "loan.records.Bb0Aaa30514B7Cba": "当前记录",
    "loan.records.0F8B0A7D80769051": "还款记录",
    "loan.records.D4B42E43D88De6F1": "历史记录",
    "loan.records.Cb1847D6281897E6": "借款金额",
    "loan.records.C7Bfcacfc2Adea58": "还款金额",
    "loan.records.8B369164D5Fea340": "借贷时间：",
    "loan.records.Ab2402Ad3Ffd35E7": "提前还贷",
    "loan.records.B7A0Ca0C195D0Cd1": "发放时间：",
    "loan.records.42014Bdcfd9C411B": "还款时间：",
    "loan.records.A0Acfaee54F06458": "已完成",
    "loan.records.67A39834582Ad99A": "暂无数据",
    "loan.records.6A687Ff5B8F1598F": "您确定要提前还款吗",
    "loan.records.7C20D7008D0Eae86": "还款成功",
    "mining.bottomSheet.Ba91637D32Ccba04": "流动性挖矿",
    "mining.bottomSheet.7De3D636A165E008": "收益率",
    "mining.bottomSheet.F3003345B189D683": "挖矿期限",
    "mining.bottomSheet.3E4C0A8C533F5848": "硬币类型",
    "mining.bottomSheet.Adcba368993Ae65E": "认购金额（最小/最大）",
    "mining.bottomSheet.94743B24E4Ed4166": "无限制",
    "mining.bottomSheet.286944Fedd211257": "可用资金",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "预计收益:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "立即挖矿",
    "mining.bottomSheet.Bb1493C567C114Dd": "温馨提示：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "提前赎回手续费",
    "mining.index.8B2Bba1422Eed6F0": "创造百亿财富，百分百纯收入",
    "mining.index.42997E300086Ee89": "最高收益",
    "mining.index.510F74F42052Ecce": "挖矿产品",
    "mining.index.B853F96626106912": "去挖矿",
    "mining.productList.37181C74559Db182": "订单",
    "mining.productList.Aac5B810004C46C8": "定期",
    "mining.productList.D2Fb4F78C387D888": "您已成功挖矿",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "确认订单",
    "quantify.bottomSheet.6915303B698303B1": "机器人",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "租借金额",
    "quantify.bottomSheet.Fbd931286Be387Aa": "周期",
    "quantify.bottomSheet.9Ad84Db481807844": "无限期",
    "quantify.bottomSheet.994D7226Ca083Ea5": "日收益率",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "起息日",
    "quantify.bottomSheet.88008603Ba7Bde64": "购买成功",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "即日起可获得收益分成！",
    "quantify.bottomSheet.C778A674C4A054F9": "查看订单",
    "quantify.bottomSheet.685Dad322B7029B0": "返回交易",
    "quantify.buyForm.Acf8560A04334E95": "购买金额",
    "quantify.buyForm.Cf9Cfbc2C554944F": "概览",
    "quantify.buyForm.8788Df2B625E97B5": "机器人",
    "quantify.buyForm.50Ca63B45E881D50": "派息时间",
    "quantify.buyForm.Fcda03Adbc5Fca97": "每天",
    "quantify.buyForm.Afb89504Fc399189": "请输入购买数量",
    "quantify.buyForm.1435Afdfb4Ec0596": "最小购买数量",
    "quantify.buyForm.1B97F1730Bacd839": "最大购买数量",
    "quantify.index.32F56C9B7D0E250A": "周期：无限期",
    "quantify.index.E34F028D00904630": "价格",
    "quantify.index.E34F028D00904631": ",日收益率",
    "quantify.productDetail.6Bbe178Df539A5Ca": "托管金额",
    "quantify.productDetail.53635B53Bca31Dfa": "预计日收益",
    "quantify.productDetail.F9E46449A6269E09": "累计收益",
    "quantify.productDetail.98Fc46B1B8C993B2": "订单托管",
    "quantify.productDetail.93624Bb045830966": "立即租用",
    "quantify.records.0C16A18C32B9Cf4A": "订单记录",
    "quantify.records.B191F5614B0E2312": "购买记录",
    "quantify.records.6A06033B86A73F7C": "赎回记录",
    "quantify.records.45E0F7E71611618E": "累计收益",
    "quantify.records.45E0F7E71611618F": "到期盈利",
    "quantify.records.Aae1201E38C4Baaa": "运行中",
    "flash.index.C41Fd4F2Fdfa594B": "消耗",
    "flash.index.1Ac59Fa2218E3B00": "可用:",
    "flash.index.Bf7233A660821549": "获得",
    "flash.index.548E775Dd67C7472": "确定兑换",
    "flash.index.066A2449Bd616F03": "请输入兑换数量",
    "flash.index.Fca26Fcf9C06563F": "闪兑成功",
    "message.index.40031524233F4564": "消息中心",
    "message.index.8E5586210Fd44D69": "永续仓位提醒",
    "message.index.Bcd3F470E88C197D": "暂无通知",
    "message.index.23A557Fbce545879": "永续强平通知",
    "message.index.B5E19B36B5125550": "提现提醒",
    "message.index.716A981465138334": "充值提醒",
    "message.index.884474Ebff2977Ba": "系统消息",
    "message.index.08Cc7D24752Ec42C": "在线客服",
    "message.index.1D820A6A19Ab025D": "7 X 24小时在线人工服务",
    "more.index.29881E0B5Df58Bf7": "更多功能",
    "more.index.C77Ee35911D10D9D": "常用功能",
    "more.index.3558F69B0260Be61": "交易功能",
    "more.index.7243Fe610Ed98Cec": "真实账户",
    "more.index.7243Fe610Ed98Ced": "模拟账户",
    "more.index.693C7B35Bdf3E3A3": "金融",
    "more.index.F855997Fac114554": "帮助中心",
    "more.index.Ae9A1252278Da742": "帮助",
    "more.index.Ace012504Bc529E0": "消息",
    "more.index.05682Dbef5Eeb0A8": "客服",
    "more.index.C7Af227A5D5C7Bb9": "您已切换到实际账户",
    "more.index.D818A65592694786": "您已切换到模拟账户",
    "notice.details.B360807Dbe727E6E": "关于我们",
    "notice.details.92F2552F695E70E3": "关于我们",
    "notice.details.7Aaceaf69B67F3D1": "规则介绍",
    "notice.index.6128438Be34Bcb9F": "永续通知",
    "notice.index.71627F5Eb7128261": "提现通知",
    "notice.index.2Dce570Fda841705": "充值通知",
    "notice.index.228A7C9C6448A9B1": "系统通知",
    "setting.dialog.8B4F5B74660Deabe": "颜色配置",
    "setting.dialog.Bfc7D25486Ebc1A9": "绿涨红跌",
    "setting.dialog.796Ea0E1A82C320B": "红涨绿跌",
    "setting.index.8Ddc5864E1739466": "偏好设置",
    "setting.index.F5205801D57D6727": "语言",
    "setting.index.2E2C12Dd0Cc1B63B": "计价货币",
    "setting.index.322D8496Aec3D918": "版本号",
    "setting.index.Aa7E6C204C751Cfa": "退出登录",
    "share.index.75Bc38Bbf7D2E766": "邀请好友",
    "share.index.45575Eb5D42213E6": "我的邀请码",
    "share.index.29F67F0644A78Bf3": "复制",
    "share.index.19Cc65Bb6Bb98Fe7": "我的邀请链接",
    "share.index.5372712F162Fb78B": "直属下级人数",
    "share.index.C88D6B95E3Ab7D6F": "总下级人数",
    "share.index.Bb5Fa5471Aeecc62": "直属下级返佣",
    "share.index.426B6Bac9A5F3D4F": "总返佣",
    "share.index.197E4A428F0401C9": "查看返佣记录",
    "share.records.88A6B2E2B74B13C4": "返佣记录",
    "share.records.B671A35Ffdc93B89": "返佣",
    "transfer.index.B17617A869C03Ca5": "钱包",
    "transfer.index.E4116Dcc6F6C7270": "划转成功",
    "transfer.index.48Fb603C6638C7F6": "请设置资金密码",
    "transfer.records.Eb0E39Eb5911D418": "获得",
    "transfer.records.977554Be0Aa65052": "闪兑时间：",
    "transfer.records.9Aa33884Aa400B7C": "划转时间：",
    "form1.forget.76636Db8Fae5B124": "您的邮箱",
    "form1.forget.35354B9Cca1Bb0E6": "邮箱验证码",
    "form1.forget.604A0B11E916F387": "密码",
    "form1.forget.9Cb1C9C51E7503D6": "确认密码",
    "form1.forget.3A4F7E04B008453C": "您的手机号码",
    "form1.forget.Cf11Dce5B4713B5A": "手机验证码",
    "form1.forget.3012Edba57D4F297": "请输入您的邮箱",
    "form1.forget.F1Ee1D97E869E447": "请输入验证码",
    "form1.forget.3A5C4C9Ae68Fa0F6": "请输入您的密码",
    "form1.forget.C6Cb174Da411D5F7": "请输入您的手机",
    "form1.forget.947B3B21Ee45E582": "发送",
    "form1.forget.3E0580F1E8630Df6": "两次密码不一致",
    "form1.forget.C5A61836B8Cc74C6": "密码修改成功跳转中...",
    "form1.forget.B96D072Bc8962565": "重新发送",
    "form1.login.2B72C27Ff828Cfc4": "您的密码",
    "form1.login.D7C6B872Af9Cb17F": "记住我的密码",
    "form1.login.0Fff44C827A4F3B6": "登录",
    "form1.login.1B7F2C1Ede3990Cc": "还没账号？立即注册",
    "form1.login.F657F7Be1Cc33Bd2": "忘记密码",
    "form1.login.2074A1Eec4B088B0": "钱包登录",
    "form1.login.1B8938Fbcc3C9B56": "登录成功跳转中...",
    "form1.login.07162A7Bb9310555": "请安装ERC20钱包",
    "form1.login.766969966Ae151Dc": "请安装TRC20钱包",
    "form1.login.B88171E1Ad4C50Bf": "您拒绝了切换网络",
    "form1.register.Aaef0710Cb4Db554": "再次输入密码",
    "form1.register.54Dfea0415C708Ff": "邀请码",
    "form1.register.6839940Dac08C794": "注册",
    "form1.register.9901A3B5550Aa58E": "已有账号？登录",
    "form1.register.2548Ca53785252Ea": "请输入您的邀请码",
    "form1.register.Dd71Fd65900Dd03E": "请阅读注册服务协议并同意",
    "form1.register.7975613909490B77": "注册成功跳转中...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "选择钱包",
    "userc.certification.2Af9511640153D2E": "初级认证",
    "userc.certification.177B191D8E0Fa84D": "未认证",
    "userc.certification.601218D4D572E98A": "已认证",
    "userc.certification.6E37C51Ba9012Fc8": "未通过",
    "userc.certification.1B9B563E05C96474": "高级认证",
    "userc.certification.1418B83803Ab56B4": "请先完成初级认证",
    "userc.creditScore.0B149112601Acc4D": "信用分",
    "userc.creditScore.88Ac7F84E106C5F6": "信用总分",
    "userc.creditScore.3E1D6E0E7F1E6155": "系统评估",
    "userc.index.62Eac58D04Bbca0F": "个人中心",
    "userc.index.F4Cac53F93Cb2Aff": "模拟交易",
    "userc.index.42F409Fdf3559773": "安全中心",
    "userc.index.1B8E211009807Db8": "身份认证",
    "userc.index.Bdfcf21Eb8F2Eb70": "账变记录",
    "userc.index.6C18D67F6D19586B": "平台介绍",
    "userc.index.807A814Ed58A93Bc": "清除缓存",
    "userc.index.E43Daad59759611C": "未认证",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "更改登录密码",
    "userc.secureCenter.1F37Ba427Debf24A": "账户绑定",
    "userc.secureCenter.F9A5Ffa750664004": "绑定谷歌验证器",
    "userb.certificationForm.7685Af7D30043Cd6": "初级认证",
    "userb.certificationForm.7685Af7D30043Cd7": "高级认证",
    "userb.certificationForm.70E37C35Abc3F462": "所在国家",
    "userb.certificationForm.F92C25A68E4D08C5": "所在城市",
    "userb.certificationForm.0Ccc6387B38E0318": "您的姓名",
    "userb.certificationForm.94C67Bba370Fdd46": "证件号",
    "userb.certificationForm.B2D4C7Fffe79258E": "邮箱",
    "userb.certificationForm.57554268761Fb47A": "上传您的证件正面",
    "userb.certificationForm.D79E90201F5319Bd": "上传您的证件背面",
    "userb.certificationForm.9C6Bad08Af29E20E": "上传您的手持证件图片",
    "userb.certificationForm.F96B62Dfa31Cde45": "确定提交",
    "userb.certificationForm.2125D78Ea8C6D287": "上传要求",
    "userb.certificationForm.0D64864D3076A824": "查看示例",
    "userb.certificationForm.35287Deb72281E87": "1.必须能看清身份证信息。",
    "userb.certificationForm.3007F2E01Efc7B57": "2.照片内容真实有效，不得做任何修改。",
    "userb.certificationForm.25A90660554319F9": "3.必须能看清证件号和姓名，支持 JPG / JPEG / PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "请输入",
    "userb.certificationForm.3B7A1D8Ce27C937E": "提交成功，请等待审核",
    "userb.certificationForm.744Bd07D8Abd2513": "请上传您的证件正面",
    "userb.certificationForm.6De1C319130F7Fef": "请上传您的证件背面",
    "userb.certificationForm.79F8E8B172E30C8A": "请上传您的手持证件照片",
    "userb.certificationForm.46412E16D28A4753": "照片上传失败",
    "userb.changeDialog.676B0Adca1F8Eea4": "更改密码",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "手机号码",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "验证器开关",
    "userb.modifyInfoDialog.62149E566814154C": "选择验证器",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "打开身份验证应用，然后点击+",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "选择'扫描二维码'或'输入密钥'",
    "userb.modifyInfoDialog.93F094E2B4369472": "扫描二维码或者输入密钥",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "关闭/打开",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "旧资金密码",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "新资金密码",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "确认新资金密码",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "确认资金密码",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "请输入您的旧资金密码",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "请输入您的新资金密码",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "请输入您的资金密码",
    "userb.modifyInfoDialog.258A534498Fe984F": "请确认您的资金密码",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "账户绑定",
    "userb.modifyInfoDialog.D34746090C25E5Db": "资金密码",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "确认",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "验证器已关闭",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "谷歌验证开启成功",
    "userb.modifyInfoDialog.24Dba26593F43C74": "修改资金密码成功",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "设置资金密码成功",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "绑定成功",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "验证码错误",
    "userb.veBottomSheet.Eecc4683C725F6F2": "证件上传示例",
    "userb.veBottomSheet.6F38A0D5858633Ee": "深色干净的背景",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "光线充足",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "对准辅助框",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "照片清晰",
    "userb.veBottomSheet.50B741382E71F103": "可能失败的原因",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "身份证保护套未取下",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "背景有杂物影响",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "身份证有反光",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "光线过暗/被手机阴影遮挡",
    "userb.veBottomSheet.1A75D2Fc2614592B": "手持身份证",
    "home.home.C3525Eb110Db58Aa": "更多",
    "home.home.29761532Fc8Ebecc": "交易",
    "home.home.F15Ea99Bfa6E1848": "安全认证，放心购U",
    "home.home.46E1Ab39Bae7182A": "快捷",
    "home.home.Fde2Eb7F6F3Dd800": "快捷交易，操作简单",
    "home.home.Ea4756Bc1642E0F1": "名称",
    "home.home.B6B1127Ede1C97B4": "最新价格",
    "home.home.3587Ad870Eb0Ab86": "24H涨跌幅",
    "home.home.Cb1A4E14Ec2Cd2B5": "发现",
    "home.home.0F1B1Ac0E96A40D1": "区块链",
    "home.home.8Eaefb1107A0E210": "外汇",
    "home.home.Ad65263D034Ea051": "PMs",
    "home.home.5Fc1474Dbf74Ac1A": "农产品",
    "home.home.135318F4664F7773": "能源",
    "kline.kLine.C96Ff250Cf6B8B84": "24h最高价",
    "kline.kLine.6E8Be64F19D74565": "24h成交量",
    "kline.kLine.08A4Bfbe26A0F262": "24h最低价",
    "kline.kLine.8701Deb075066331": "24h成交额(USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1分",
    "kline.kLine.B49Cca240E013124": "5分",
    "kline.kLine.F27A90Be97Ba3D6C": "15分",
    "kline.kLine.Ff3E62395B694384": "30分",
    "kline.kLine.C1993F9C6Db1A674": "1时",
    "kline.kLine.Fd2Cbbae405E284C": "1天",
    "kline.kLine.C9Cd71904395042C": "1月",
    "kline.kLine.7D8127Ec18C7B8C7": "买盘",
    "kline.kLine.E3D3Ffdb9B3E5636": "数量（张）",
    "kline.kLine.6834Fec1838D8029": "价格（USDT）",
    "kline.kLine.16C52Aae474Bc9Cd": "卖盘",
    "kline.kLine.B9Bea1A9A88D1419": "开多",
    "kline.kLine.D36Cfa96Ee2B343B": "开空",
    "other.navigationBar.F6E62F9642018Cbb": "首页",
    "other.navigationBar.0Ec227161A47B49D": "行情",
    "other.navigationBar.29761532Fc8Ebecc": "交易",
    "other.navigationBar.693C7B35Bdf3E3A3": "金融",
    "other.navigationBar.9734C54620Eb09Af": "资产",
    "home.index.25Aa4Fc745150030": "加密货币之王",
    "home.index.2D6Ecf8Faaa8B124": "更快、更好、更强",
    "home.index.1Db26267D1041223": "轻松交易超过1700种加密货币",
    "home.index.F8E18C90C3E35618": "立即注册",
    "home.index.29761532Fc8Ebecc": "交易",
    "home.index.D8A95E4506487452": "热门合约",
    "home.index.5Bd31Fab681C6E9D": "查看更多",
    "home.index.010Ae07A09Caf221": "交易对",
    "home.index.F4D875118E6D6Dbb": "最新成交价",
    "home.index.3Dfdfb3D6599100E": "24 小时涨跌幅",
    "home.index.28Fb39Bd41E54242": "走势图",
    "home.index.B47A3911Bacd94C6": "涨幅榜",
    "home.index.712910D574F43D95": "稳定收益",
    "home.index.5507Fd830D7Bf51F": "顶级策略，轻松复刻",
    "home.index.7De3D636A165E008": "收益率",
    "home.index.B853F96626106912": "去挖矿",
    "home.index.213B5C96Be7Cd1F8": "天，年化利率",
    "home.index.213B5C96Be7Cd1F9": "最大可买",
    "home.index.Dc710Cffe6313Bb5": "查看详情",
    "home.index.B92Ffc837Ecaa1Dd": "新闻资讯",
    "home.index.6C18D67F6D19586B": "平台介绍",
    "home.index.6342E3C33Cb48Cd5": "数字货币是一种使用密码学原理来确保交易安全的交易媒介。与美元等法币不同，数字货币并没有实体形式。Bitcoin(BTC)、Ethereum (ETH) 和 Polkadot (DOT)等热门数字货币以区块链作为底层技术，充当去中心化的数字分类账。所有数字货币交易都记录在区块链中，一经确认和验证就无法更改。与传统货币由银行维护中心化分类账不同，数字货币交易在公共区块链上进行，任何人都可以访问。",
    "home.index.198Efc4254683Eef": "此外，根据共识机制，人人都可以验证数字货币交易并将其添加到区块链，从而实现去中心化。随着三星、贝莱德、摩根士丹利和Alphabet等全球领先公司纷纷入局投资，人们相信区块链技术将彻底改变全球金融体系。数字货币为您开启了通往去中心化金融世界的大门，让您能够探索前沿技术带来的无限可能。",
    "home.index.E710339D9576292E": "我们的优势",
    "home.index.F9Ce8B2C63885B26": "安全稳定",
    "home.index.C4D0A2C9Ff0305Ce": "顶尖的技术团队，全方位安全保护，自主研发高速交易合引擎，海量交易并发下仍可稳定可靠运行。",
    "home.index.2E8708Ee3F11Cc1A": "专业可靠",
    "home.index.Bb12Cbcf89C56C5C": "专业运营团队，多年区块链及金融从业经验，持有合规数字资产交易牌照，百分百备付金保证。",
    "home.index.Aa4723Bfbf6808F4": "用心服务",
    "home.index.1Bf0204B7D3Ee230": "面向全球市场，多种语言支持，7*24小时不间断运行，强大的社区支持，专业的客户服务。",
    "home.index.2E7Dfd53D26F7224": "高性能",
    "home.index.1C65453103B5Fcc3": "每秒300,000笔交易，订单响应时间不到1毫秒。",
    "home.index.85Ac49Ece9Dcc80B": "随时随地进行交易！",
    "home.index.2799E18842E4Ebe9": "即时开仓，OKEX App 和网页端双支持！",
    "home.index.2Bf52Ce75E29Fc88": "立即扫码下载",
    "home.index.88D1422B33571066": "安卓端",
    "home.index.04Ee893775563F21": "苹果端",
    "home.index.04Ee893775563F22": "产品服务",
    "home.index.04Ee893775563F23": "理财",
    "home.index.04Ee893775563F24": "返回",
    "home.index.04Ee893775563F25": "等待开奖",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "購買比特幣，以太坊及",
    "homepage.text1_2": "1000+ 種加密貨幣",
    "homepage.text2": "您的加密貨幣體驗之旅",
    "homepage.text4": "高利率財務管理",
    "homepage.text5": "穩定的財務管理，高年化收益",
    "homepage.text6": "分享獎勵",
    "homepage.text7": "快速交易，操作簡單",
    "homepage.text8": "查看更多",
    "homepage.text9": "即時兌換",
    "homepage.text10": "安全認證，放心購買",
    "homepage.text11": "頂尖技術團隊，全面安全",
    "homepage.text12": "保護，獨立",
    "homepage.text13": "專業且可靠",
    "homepage.text14": "專業運營團隊，擁有多年區塊鏈和金融經驗",
    "homepage.text15": "用心服務",
    "homepage.text16": "面向全球市場，支持多種語言，全天候運營",
    "homepage.text17": "高性能",
    "homepage.text18": "每秒處理 300,000 筆交易，訂單響應時間低於 1 毫秒",
    "passwod.limit":"密码长度必须大于或等于6个字符"
}
