import zhHans from 'vuetify/es5/locale/zh-Hans';
export default {
    ...zhHans,
    setting: {
        language: "Cina disederhanakan",
        dialogDetermine: "Tentu",
        dreivatives: "Pengiriman",
        markets: "Kutipan",
        assets: "aktiva",
        liquidity: "Penambangan likuiditas",
        helpCentre: "Pusat Bantuan",
        announcementCenter: "Pusat Pengumuman",
        about: "tentang",
        bulletinCentre: "Pusat Pengumuman",
        serviceAgreement: "Perjanjian Layanan",
        support: "mendukung",
        onlineSupportCustomerService: "layanan daring",
        suggestionsAndFeedback: "Saran dan masukan",
        service: "Melayani",
        download: "unduh",
        coinApplication: "Aplikasi daftar koin",
        privacyStatement: "Pernyataan Privasi",
        trade: "perdagangan kontrak",
        standardConteact: "Berkelanjutan",
        signOut: "keluar",
        assetWallets: "Dompet aset",
        financeRecords: "Catatan perubahan akun",
        identification: "Autentikasi",
        personalCenter: "Pusat Pribadi",
        chooseLanguage: "Pilih bahasa",
        tips: "petunjuk",
        dark: "mode gelap",
        light: "modus hari",
        languageTitle: "bahasa",
        convertCurrency: "Konversi mata uang",
        cancel: "Membatalkan",
        aboutUs: "tentang kami",
        standard: "Kontinuitas standar",
        share: "membagikan",
        introduce: "Pengenalan Platform",
        set: "mempersiapkan",
        changePassword: "Ubah kata sandi masuk",
        denomination: "Mata uang kutipan",
        color: "Konfigurasi warna",
        code: "nomor versi",
        color1: "Merah naik dan hijau turun",
        color2: "Hijau naik dan merah turun",
        setLangue: "pengaturan bahasa",
        vip: "Manfaat VIP",
        vip2: "Tingkat VIP saat ini",
        safeMenu: "Pusat keamanan",
        conventional: "konvensional",
        other: "lainnya",
        quickTips: "Tambahkan hingga 3 tombol fungsi pintasan",
        quickTransaction: "Transaksi cepat",
        safeConvenient: "Aman dan nyaman",
        timeSharing: "Berbagi waktu",
        currentLevel: "level saat ini",
        levelBenefits: "%{level} tingkat pendapatan",
        teamLevel: "Tingkat tim",
        levelLower: "%{level}bawahan",
        rules: "Deskripsi aturan",
        bindWithdrawalAddress: "Ikat alamat penarikan",
        tipsBindWithdrawalAddress: "Anda belum mengikat alamat penarikan, harap ikat terlebih dahulu",
        walletAddressFormatIncorrect: "Format alamat dompet salah",
        tradeRules1: "Memprediksi naik turunnya periode mendatang. Harga pada saat pemesanan digunakan sebagai harga awal, dan harga pada detik terakhir periode prediksi digunakan sebagai harga penyelesaian. Jika harga penyelesaian lebih besar dari harga awal berarti kenaikan. Jika penyelesaian harga lebih kecil dari harga awal, berarti terjadi penurunan.",
        tradeRules2: "1.apa itu kontrak",
        tradeRules3: "Platform ini menyediakan kontrak abadi berbasis USDT. Pengguna dapat menilai naik atau turunnya dan memilih untuk membeli kontrak panjang atau menjual kontrak pendek untuk mendapatkan keuntungan dari naik/turunnya harga aset digital. Kontrak abadi tidak memiliki tanggal pengiriman dan pengguna dapat menyimpannya selamanya.",
        tradeRules4: "2.Apa itu margin",
        tradeRules5: "Di pasar kontrak virtual, pengguna hanya perlu membayar sejumlah kecil dana dalam proporsi tertentu sesuai harga kontrak sebagai jaminan finansial atas pelaksanaan kontrak, dan kemudian mereka dapat berpartisipasi dalam pembelian dan penjualan kontrak. Dana semacam ini adalah setoran kontrak virtual. \nKontrak pada platform ini mengadopsi model margin terpisah dari akun terpisah. Setiap mata uang sesuai dengan akun kontrak. Aset akun dan posisi mata uang kontrak yang berbeda tidak bergantung satu sama lain, dan transfer serta transaksi antar akun kontrak yang berbeda tidak saling memengaruhi. Setelah akun kontrak pengguna dibuka, risiko dan manfaat dari semua posisi yang ada di akun kontrak akan dihitung bersama.",
        tradeRules6: "3.Cara menghitung margin",
        tradeRules7: "Margin posisi = nilai nominal kontrak * jumlah kontrak terbuka / pengganda leverage\nmargin beku = jumlah margin dari semua pesanan yang berhasil ditempatkan dan pesanan pembukaan dalam pesanan saat ini\nmargin yang tersedia = saldo akun - margin posisi - margin beku",
        tradeRules8: "4.Cara Menghitung Tingkat Margin",
        tradeRules9: "Tingkat margin adalah ukuran risiko aset pengguna. \nTingkat margin = (Laba dan rugi yang belum direalisasi + Margin posisi + Margin yang tersedia + Margin beku - Biaya likuidasi) / Margin posisi \nSemakin kecil tingkat margin, semakin tinggi risiko akun. Ketika tingkat margin kurang dari atau sama dengan 0%, likuidasi paksa akan dilakukan.",
        tradeRules10: "5.Berapa perkiraan harga paritas paksa?",
        tradeRules11: "Perkiraan harga likuidasi paksa, yaitu harga yang dihitung oleh sistem yang secara teoritis memicu likuidasi paksa, hanya sebagai referensi dan mungkin sedikit menyimpang dari nilai sebenarnya.",
        tradeRules12: "6.Berapa total ekuitas akun serta laba dan rugi yang belum direalisasi?",
        tradeRules13: "Total ekuitas akun = saldo akun + laba dan rugi yang belum direalisasi\nLaba dan rugi yang belum direalisasi adalah laba dan rugi dari posisi yang saat ini dipegang oleh pengguna dalam kontrak mata uang. Laba dan rugi yang belum direalisasi akan berubah seiring dengan perubahan harga transaksi terkini. \nLaba dan rugi yang belum direalisasi untuk posisi long = (1/harga posisi rata-rata - 1/harga transaksi terakhir) * jumlah kontrak untuk posisi long * nilai nominal kontrak * harga transaksi terakhir \n laba dan rugi yang belum direalisasi untuk posisi short = (1/ harga transaksi terbaru - 1/ Harga posisi rata-rata) * Jumlah kontrak pendek * Nilai nominal kontrak * Harga transaksi terbaru",
        text1: "Keamanan Akun",
        text2: "mode siang dan malam",
        text3: "bersihkan cache",
        text4: "Apakah Anda yakin ingin logout?",
        text5: "Apakah Anda yakin ingin menghapus cache?",
        text6: "banyak bahasa",
        text7: "Untuk melindungi keamanan Anda, sebaiknya aktifkan setidaknya satu autentikasi dua faktor"
    },
    login: {
        login: "Gabung",
        register: "daftar",
        title1: "Berdagang kapan saja, di mana saja, dan lihat kondisi pasar secara real-time",
        title2: "Berdagang kapan saja, di mana saja, dan lihat kondisi pasar secara real-time",
        title3: "Platform Perdagangan Cryptocurrency Paling Tepercaya",
        formTitle1: "Akun Masuk",
        formTitle2: "Selamat Datang kembali! Masuk dengan email Anda, nomor telepon",
        btn1: "Telepon",
        btn2: "Surat",
        btn3: "Gabung",
        btn4: "daftar",
        btn5: "lupa kata sandinya?",
        field1: "Nomor telepon",
        field2: "kata sandi",
        field3: "Surat",
        placeholder1: "bangsa",
        placeholder2: "Silakan masukkan nomor telepon",
        placeholder3: "Silakan masukkan kata sandi",
        placeholder4: "silakan masukkan email Anda",
        content12: "Silakan masukkan nomor telepon",
        content13: "Silakan masukkan kata sandi",
        content14: "silakan masukkan email Anda",
        content16: "Layanan keuangan aset digital",
        loginSuccess: "login berhasil ",
        chooseArea: "Silakan pilih kode area",
        placeholder5: "Silakan pilih kode negara",
        placeholder6: "ingat kata sandi saya",
        placeholder7: "Unduh APLIKASI",
        placeholder8: "Hubungi layanan pelanggan",
        placeholder9: "Pilih negara atau wilayah",
        placeholder10: "Kode area",
        placeholder11: "Masuk dompet",
        placeholder12: "Pengoperasian gagal, silakan beralih ke",
        placeholder13: "dompet"
    },
    aboutUs: {
        aboutCoinbeadCenterTitle: "tentang",
        aboutCoinbeadCenterDetails: "Ia memiliki platform perdagangan aset blockchain terkemuka di dunia dan mengoperasikan seluruh ekosistem.",
        footDetails1: "volume perdagangan harian",
        footDetails2: "transaksi/detik",
        footDetails3: "Pusat Bantuan",
        coinbeadCenterTitle: "",
        coinbeadCenterDetails: "Ini adalah situs web internasional terkenal di dunia untuk aset digital, terutama menyediakan koin dan turunan aset digital seperti Bitcoin (BTC), Litecoin (LTC), dan Ethereum (ETH) kepada pengguna global.",
        coinbeadCenterDetailsm: "Mengadopsi teknologi canggih seperti GSLB, server jaringan, transaksi jaringan, jaringan, mesin perdagangan memori berkecepatan tinggi multi-mesin, dompet dingin, dompet panas offline cerdas, dll., kami melayani pelanggan kami dengan banyak terminal seperti ponsel Web dan PC .Menyediakan layanan transaksi tarif digital yang aman, stabil, dan andal. Pada saat yang sama, kami meningkatkan dan meningkatkan produk dan layanan berdasarkan saran pengguna dan kebutuhan untuk memberikan layanan yang lebih baik kepada setiap pelanggan, dan peningkatan pengalaman pengguna yang inovatif tidak ada habisnya.",
        infrastructureCenterTitle: "infrastruktur",
        infrastructureCenterDetails: "Misi kami adalah menyediakan layanan infrastruktur untuk seluruh ekosistem blockchain",
        freeCenterTitle: "bebas",
        freeCenterDetails: "Visi kami adalah membuat uang mengalir bebas ke seluruh dunia. Kami percaya menyebarkan kebebasan ini dapat membuat dunia menjadi tempat yang lebih baik",
        futureCenterTitle: "Merintis masa depan teknologi",
        futureCenterDetails: "Buka masa depan teknologi",
    },
    applicationForListing: {
        title: "Aplikasi daftar koin",
        projectName: "Nama Proyek",
        tokenName: "Nama token",
        projectDescription: "Deskripsi Proyek",
        contractAddress: "Alamat kontrak",
        officialWebsite: "situs web resmi",
        ContactAndEmail: "Kontak Email",
        ContactAndName: "Nama Kontak",
        file: "Unggah Lampiran",
        pleaseEnter: "silakan masuk",
        enterFile: "Silakan unggah file",
        upload1: "Saat ini terbatas pada 1 pilihan file,",
        upload2: "Kali ini saya memilih",
        upload3: "file，",
        upload4: "Jumlah yang dipilih",
        upload5: "file",
    },
    public: {
        submit: "kirim",
        copySuccessfully: "Berhasil disalin",
        copyFailed: "Penyalinan gagal",
        noDate: "Tidak ada data",
        submitSuccessfully: "Berhasil dikirimkan",
        goBack: "kembali ke halaman terakhir",
        cancel: "Membatalkan",
        home: "halaman Depan",
        loading: "memuat...",
        confirm: "mengonfirmasi",
        loadingFailed: "anomali jaringan",
        nomore: "tidak lagi",
        tryAgain: "Silakan segarkan halaman/keluar dari program dan unggah lagi!",
        hot: "Area populer",
    },
    inviteFriends: {
        inviteFriends: "Berbagi dengan teman Kerjasama",
        freedomOfWealth: "yang saling menguntungkan",
        myInvitationCode: "kode undangan saya",
        clickCopy: "Klik untuk menyalin",
        myInvitationLink: "Tautan undangan saya",
        clicktoSaveTheQRCode: "Klik untuk menyimpan kode QR",
        saveQRCode: "Undang kode QR",
        numberOfSubordinates: "Jumlah bawahan",
        rebateIncome: "Pendapatan rabat",
        people: "rakyat",
        myPromotionRevenue: "Pendapatan promosi saya",
        numberOfDirectSubordinates: "Jumlah bawahan langsung",
        totalNumberOfSubordinates: "Jumlah total bawahan",
        directSubordinateReturnsCommission: "Rabat komisi untuk bawahan langsung",
        theTotalCommission: "total rabat",
        checkHistory: "Lihat catatan rabat",
        history: "Catatan komisi",
        theLowerAccount: "Akun bawahan",
        type: "jenis：",
        serviceCharge: "Biaya penanganan：",
        percentageofCommissionReturned: "Rasio rabat：",
        commissionrebateAmount: "Jumlah rabat：",
        time: "waktu：",
        typeName1: "Isi ulang dan komisi",
        typeName2: "Biaya penanganan pesanan MT distribusi tiga tingkat",
        typeName3: "MT Distribusi Tiga Tingkat yang Menguntungkan",
        typeName4: "Hadiah rujukan",
        typeName30: "Isi ulang dan komisi",
        typeName31: "Rabat keuntungan perdagangan berkala",
        typeName32: "Rabat keuntungan penambangan likuiditas",
        typeName45: "Rabat biaya transaksi",
        typeName46: "Potongan komisi atas biaya penanganan sendiri",
        typeName50: "Rabat biaya penanganan kontrak pengiriman",
        typeName51: "Rabat Biaya Penanganan Kontrak Abadi",
        typeName52: "Potongan komisi tambahan untuk biaya penanganan kontrak pengiriman",
        typeName53: "Rabat komisi tambahan untuk biaya penanganan kontrak abadi",
        typeName54: "Rabat tambahan untuk biaya penanganan kontrak abadi",
        shareRules1: "Instruksi undangan",
        shareRules2: "Masuk ke pusat undangan, salin tautan atau kode undangan, dan bagikan dengan teman Anda. Teman bisa menjadi bawahan Anda dengan mendaftar menggunakan kode undangan Anda.",
        shareRules3: "Dapatkan rabat",
        shareRules4: "Ketika bawahan melakukan transaksi, Anda bisa mendapatkan komisi yang sesuai, hingga tiga tingkat bawahan didukung. Misalnya, jika Anda mengundang teman A, A mengundang B, dan B mengundang C, maka A, B, dan C dapat melakukan kontrak dan transaksi lainnya di platform, dan Anda semua bisa mendapatkan komisi yang sesuai.",
        shareRules5: "Tingkat tim",
        shareRules6: "Semakin banyak bawahan tingkat pertama yang Anda promosikan, semakin tinggi level tim, dan semakin tinggi rabat yang dapat Anda nikmati. Level tim dibagi menjadi LV1-LV6. Aturan peningkatan ditunjukkan pada tabel di bawah, di mana 'N' adalah jumlah bawahan tingkat pertama yang telah mengisi ulang dan menyelesaikan otentikasi nama asli.",
        shareRules7: "Ketika bawahan memasang taruhan pada kontrak pengiriman, Anda dapat menerima komisi sebanding dengan taruhan mereka.",
        upgradeConditions: "Memerlukan",
        recommendedNumberOfPeople: "Jumlah orang yang disarankan"
    },
    suggestionsAndFeedback: {
        suggestionsAndFeedback: "Saran dan masukan",
        text: "Jika Anda menemukan bug atau memiliki saran produk saat digunakan, silakan beri kami umpan balik.",
        email: "alamat email",
        enterEmail: "Silakan masukkan alamat email",
        enterCorrectEmail: "Silakan masukkan alamat email yang benar",
        title: "judul",
        enterTitle: "Silakan masukkan judul pertanyaan",
        describeTheProblem: "Jelaskan masalahnya",
        enterDescribeTheProblem: "Silakan masukkan masalah yang Anda jelaskan",
        submit: "kirim",
    },
    help: {
        enterSearch: "mencari",
        title: "Pusat Bantuan",
        search: "Silakan masukkan pertanyaan Anda",
        text1: "Pahami kebutuhan Anda dan terus berikan Anda layanan yang lebih nyaman"
    },
    announcement: {
        enterSearch: "mencari",
        title: "Pusat Pengumuman",
        search: "Silakan masukkan pertanyaan Anda"
    },
    privacy: {
        title: "Pernyataan Privasi",
        content1: "1. Informasi pribadi apa saja tentang pengguna yang dikumpulkan?",
        content2: "Informasi pribadi dikumpulkan untuk menyediakan produk dan layanan dan untuk terus meningkatkannya.",
        content3: "Berikut ini adalah jenis informasi pribadi yang kami kumpulkan：",
        content4: 'Informasi yang Anda berikan kepada kami: Kami menerima dan menyimpan informasi apa pun yang Anda berikan sehubungan dengan Platform. Anda dapat memilih untuk tidak memberikan informasi tertentu, namun Anda mungkin tidak dapat menggunakan "layanan platform ini" tertentu. Contoh informasi yang Anda berikan kepada kami meliputi:',
        content5: "Nama",
        content6: "alamat",
        content7: "Warga Negara",
        content8: "foto tanda pengenal",
        content9: "Nomor ponsel/nomor email",
        content10: "Informasi lain yang membantu kami mengidentifikasi Anda",
        content11: "Informasi yang dikumpulkan secara otomatis: Kami secara otomatis mengumpulkan dan menyimpan jenis informasi tertentu tentang penggunaan Anda atas Layanan Platform.Seperti banyak situs web, kami menggunakan cookie dan pengidentifikasi unik lainnya. Kami memperoleh jenis informasi tertentu ketika browser web atau perangkat Anda mengakses Platform. Contoh informasi yang kami kumpulkan dan analisis meliputi:",
        content12: "Alamat Protokol Internet (IP) yang menghubungkan komputer pribadi ke Internet;",
        content13: "Informasi login, alamat email, kata sandi dan lokasi perangkat pribadi atau komputer;",
        content14: "Pengaturan versi dan zona waktu;",
        content15: "Sejarah transaksi.",
        content16: "Informasi dari Sumber Lain: Kami dapat mengumpulkan informasi tentang Anda dari sumber lain, seperti riwayat kredit dari biro kredit. Kami akan menggunakan informasi ini untuk mencegah dan mendeteksi penipuan.",
        content17: "2. Apakah bisa digunakan oleh anak-anak?",
        content18: 'Platform ini tidak mengizinkan siapa pun yang berusia di bawah 18 tahun untuk menggunakan "Layanan".',
        content19: "3. Untuk apa cookie dan pengidentifikasi lainnya digunakan?",
        content20: "Kami menggunakan cookie dan alat serupa untuk mengoptimalkan pengalaman pengguna, menyediakan layanan, dan memahami cara pelanggan menggunakan layanan kami sehingga kami dapat melakukan perbaikan yang ditargetkan. Kami menggunakan cookie untuk memastikan bahwa sistem kami dapat mengenali browser atau perangkat Anda dan memberi Anda layanan.",
        content21: 'Kami menggunakan cookie operasional dan alat serupa (secara kolektif disebut "Cookie") untuk menyediakan layanan, seperti:',
        content22: "Untuk mengidentifikasi Anda saat Anda masuk dan menggunakan layanan kami.",
        content23: "Menyediakan fitur dan layanan yang disesuaikan.",
        content24: "Melindungi dari aktivitas penipuan.",
        content25: "Meningkatkan keamanan.",
        content26: "Lacak preferensi Anda (seperti mata uang dan bahasa).",
        content27: "Kami juga menggunakan cookie untuk memahami cara pengguna menggunakan layanan kami sehingga kami dapat melakukan perbaikan yang ditargetkan.",
        content28: "4. Apakah informasi pribadi saya akan dibagikan?",
        content29: "Informasi pengguna adalah bagian penting dari bisnis kami, dan kami tidak akan menjual informasi pribadi pengguna kepada orang lain. Platform ini hanya akan membagikan informasi pribadi pengguna kepada anak perusahaan atau afiliasinya dalam keadaan berikut: Anak perusahaan atau afiliasi tersebut diwajibkan untuk mematuhi pernyataan privasi ini atau setidaknya mengikuti praktik yang sama protektifnya dengan tindakan perlindungan yang dijelaskan dalam pernyataan privasi ini.",
        content30: "Penyedia Layanan Pihak Ketiga: Kami mempekerjakan perusahaan dan individu lain untuk menjalankan fungsi atas nama kami. Contoh fungsi tersebut meliputi: menganalisis data, memberikan bantuan pemasaran, memproses pembayaran, mengirimkan konten, dan menilai serta mengelola risiko kredit. Penyedia layanan pihak ketiga ini memiliki akses ke informasi pribadi yang diperlukan untuk menjalankan fungsinya, namun tidak boleh menggunakannya untuk tujuan lain apa pun. Selain itu, mereka harus memproses informasi pribadi sesuai dengan pernyataan privasi ini dan undang-undang perlindungan data yang berlaku.",
        content31: "Transfer Bisnis: Seiring dengan pertumbuhan bisnis kami, kami dapat menjual atau membeli bisnis atau layanan lain. Dalam transaksi tersebut, informasi pengguna umumnya merupakan salah satu aset bisnis yang ditransfer namun tetap tunduk pada janji dalam pernyataan privasi yang sudah ada sebelumnya (kecuali, tentu saja, pengguna menyetujui sebaliknya). Selain itu, jika perusahaan kami atau secara substansial seluruh asetnya diakuisisi, informasi pengguna juga akan ditransfer. Perlindungan Perusahaan Kami dan Lainnya: Ketika kami yakin bahwa pelepasan akun dan informasi pribadi lainnya adalah tepat untuk mematuhi hukum atau kewajiban peraturan kami, menegakkan atau menerapkan Ketentuan Penggunaan kami dan perjanjian lainnya, atau hak, properti, atau keamanan kami pengguna atau orang lain Kapan, kami akan merilis akun dan informasi pribadi lainnya. Hal ini termasuk pertukaran informasi dengan perusahaan dan organisasi lain untuk melindungi dari penipuan dan mengurangi risiko kredit.",
        content32: "5. Transfer data pribadi secara internasional",
        content33: 'Kami dapat mentransfer data Anda ke luar Wilayah Ekonomi Eropa ("EEA"). Kami akan menerapkan pengamanan yang sesuai untuk memastikan bahwa transfer tersebut mematuhi peraturan perlindungan data yang berlaku, kecuali Komisi Eropa telah mengonfirmasi bahwa negara tujuan transfer data memberikan tingkat perlindungan yang memadai.',
        content34: "6. Apakah informasi pribadi saya aman?",
        content35: "Kami merancang sistem kami dengan mempertimbangkan keamanan dan privasi Anda. Kami menggunakan protokol enkripsi dan perangkat lunak untuk berupaya melindungi keamanan informasi pribadi selama transmisi.",
        content36: "Kami selalu menerapkan perlindungan fisik, elektronik, dan prosedural saat mengumpulkan, menyimpan, dan mengungkapkan informasi pribadi. Prosedur keamanan kami berarti bahwa kami mungkin perlu memverifikasi identitas Anda sebelum mengungkapkan informasi pribadi kepada Anda.",
        content37: "Hal terpenting yang dapat Anda lakukan adalah melindungi kata sandi akun pribadi Anda dari akses tidak sah. Kami menyarankan untuk menetapkan kata sandi unik untuk akun Anda yang berbeda dari akun online lainnya. Dan pastikan untuk logout setelah menggunakan komputer bersama.",
        content38: "7. Bagaimana cara melindungi informasi pribadi?",
        content39: "Jika Anda memiliki pertanyaan atau keberatan tentang cara kami mengumpulkan dan memproses informasi pribadi, silakan hubungi staf layanan pelanggan",
        content40: "Setelah Anda menyetujui kami memproses informasi pribadi Anda untuk tujuan tertentu, Anda dapat membatalkan niat Anda kapan saja, dan kami akan berhenti memproses data Anda untuk tujuan tersebut.",
        content41: "Selain itu, Anda berhak meminta akses, perbaikan, dan penghapusan data pribadi serta meminta portabilitas data, dengan tunduk pada hukum yang berlaku. Anda juga dapat menolak pemrosesan data pribadi Anda oleh kami, atau meminta agar pemrosesan data pribadi Anda dibatasi oleh kami dalam keadaan tertentu.",
        content42: "8. GDPR UE dan Hukum Perlindungan Data Inggris – Dasar Hukum",
        content43: "GDPR UE dan undang-undang perlindungan data Inggris mengharuskan kami mematuhi dasar hukum saat menggunakan informasi pribadi. Dasar kami bergantung pada tujuan spesifik penggunaan informasi pribadi tersebut. Basis tersebut antara lain:",
        content44: "Kinerja kontrak – untuk menyediakan atau berkomunikasi dengan Anda tentang produk atau layanan, termasuk penggunaan kami atas informasi pribadi Anda untuk menerima dan memproses pesanan serta memproses pembayaran.",
        content45: "Kepentingan bisnis dan kepentingan pengguna kami yang sah - Kami mendeteksi dan mencegah penipuan dan penyalahgunaan untuk melindungi keselamatan pengguna kami, diri kami sendiri, atau orang lain.",
        content46: "Persetujuan Anda – Kami memerlukan persetujuan Anda untuk memproses informasi pribadi Anda untuk tujuan spesifik yang dikomunikasikan kepada Anda. Setelah Anda memberi kami izin untuk memproses informasi pribadi Anda untuk tujuan tertentu, Anda dapat menarik persetujuan Anda kapan saja dan kami akan berhenti memproses data Anda untuk tujuan tersebut.",
        content47: "Mematuhi kewajiban hukum - Kami menggunakan informasi pribadi Anda sebagaimana diwajibkan oleh hukum. Misalnya, kami mengumpulkan informasi rekening bank untuk tujuan verifikasi identitas.",
        content48: "Dasar hukum di atas dan dasar hukum lainnya bergantung pada tujuan spesifik penggunaan informasi pribadi kami.",
        content49: "9. Ketentuan penggunaan, pemberitahuan dan amandemen",
        content50: "Penggunaan Anda dan perselisihan apa pun mengenai privasi tunduk pada Pernyataan ini dan Ketentuan Penggunaan kami. Jika Anda memiliki kekhawatiran tentang privasi platform ini, silakan hubungi kami dengan penjelasan rinci dan kami akan dengan senang hati menyelesaikan masalah tersebut untuk Anda. Anda juga berhak menghubungi otoritas perlindungan data setempat.",
        content51: "Bisnis kami terus berubah, begitu pula pernyataan privasi kami. Anda harus sering memeriksa situs web kami untuk melihat perubahan terbaru. Jika Anda tidak setuju dengan konten yang direvisi, Anda harus segera berhenti mengakses. Setelah versi terbaru Kebijakan Privasi dirilis, akses Anda yang berkelanjutan berarti Anda setuju dengan konten yang diperbarui dan setuju untuk mematuhi Kebijakan Privasi yang diperbarui. Kecuali dinyatakan lain, Pernyataan Privasi saat ini berlaku untuk semua informasi yang kami simpan tentang Anda dan akun Anda.",
    },
    serviceAgreement: {
        title: "Perjanjian Layanan",
        content1: 'Situs web ini merupakan platform khusus bagi pengguna untuk melakukan transaksi aset digital dan menyediakan layanan terkait (selanjutnya disebut sebagai "layanan" atau "layanan"). Untuk kemudahan dalam menjelaskan perjanjian ini, situs web ini secara kolektif menggunakan kata “kami” atau kata ganti orang pertama lainnya dalam perjanjian ini. Selama orang perseorangan atau subjek lain yang masuk ke situs web adalah pengguna situs web ini, demi kenyamanan menyatakan perjanjian ini, "Anda" atau orang kedua lainnya akan digunakan di bawah ini. Demi kemudahan dalam menyatakan Perjanjian ini, kami dan Anda secara bersama-sama disebut sebagai "Para Pihak" dalam Perjanjian ini, dan kami atau Anda sendiri-sendiri disebut sebagai "Pihak".',
        content2: "petunjuk penting：",
        content3: "Kami ingin mengingatkan Anda di sini：",
        content4: "1 Aset digital itu sendiri tidak diterbitkan oleh lembaga keuangan atau perusahaan mana pun atau situs web ini",
        content5: "2 Pasar aset digital masih baru, belum terkonfirmasi, dan mungkin tidak berkembang；",
        content6: "3 Aset digital terutama digunakan secara luas oleh spekulan, dengan penggunaan yang relatif sedikit di pasar ritel dan komersial. Transaksi aset digital sangat berisiko. Mereka diperdagangkan terus menerus sepanjang hari, tanpa batas naik dan turun, dan harga mudah dipengaruhi oleh pasar. fluktuasi;",
        content7: "4  Perusahaan berhak untuk menangguhkan atau menghentikan akun Anda kapan saja jika Perusahaan menentukan, berdasarkan kebijakannya sendiri, bahwa Anda telah melanggar Perjanjian ini, atau bahwa layanan yang disediakan oleh situs web ini atau penggunaan Anda atas layanan yang disediakan oleh situs web ini adalah ilegal berdasarkan hukum yurisdiksi Anda, atau menangguhkan atau menghentikan penggunaan Anda atas layanan atau transaksi aset digital yang disediakan oleh situs web ini. Siapapun dari [Tiongkok Daratan, Taiwan, Israel, Irak, Bangladesh, Bolivia, Ekuador, Kyrgyzstan, Sevastopol, dan Inggris (pengguna ritel)] dilarang menggunakan layanan transaksi kontrak yang disediakan oleh situs web ini. Daftar negara atau wilayah yang disebutkan di atas akan berubah tergantung pada kebijakan dan jenis produk di negara atau wilayah yang berbeda. Kami mungkin tidak memberi tahu Anda secara spesifik pada saat itu, jadi harap perhatikan pembaruan perjanjian ini pada waktu yang tepat.",
        content8: "Perdagangan aset digital memiliki risiko yang sangat tinggi dan tidak cocok untuk kebanyakan orang. Anda memahami dan memahami bahwa transaksi ini dapat mengakibatkan kerugian sebagian atau seluruhnya, sehingga Anda harus menentukan jumlah transaksi berdasarkan tingkat kerugian yang Anda mampu. Anda mengetahui dan memahami bahwa aset digital akan menimbulkan risiko derivatif, sehingga jika Anda memiliki pertanyaan, disarankan untuk mencari bantuan penasihat profesional terlebih dahulu. Selain risiko-risiko yang telah disebutkan di atas, terdapat juga risiko-risiko yang tidak dapat diprediksi. Anda harus hati-hati mempertimbangkan dan menggunakan penilaian yang jelas untuk mengevaluasi situasi keuangan Anda dan risiko-risiko yang disebutkan di atas sebelum membuat keputusan apa pun untuk membeli atau menjual aset digital, dan menanggung semua kerugian yang diakibatkannya. Kami tidak bertanggung jawab atas hal ini.",
        content9: "Peringatan untuk Anda：",
        content10: "1 Anda memahami bahwa situs web ini hanya digunakan sebagai tempat bagi Anda untuk memperoleh informasi aset digital, mencari pihak yang bertransaksi, melakukan negosiasi dan melakukan transaksi aset digital. Situs web ini tidak berpartisipasi dalam transaksi apa pun yang Anda lakukan, jadi sebaiknya Anda menggunakan penilaian yang bijaksana. untuk menentukan aset dan/atau transaksi digital yang relevan, atau keaslian, legalitas, dan validitas informasi, dan akan menanggung sendiri tanggung jawab dan kerugian yang diakibatkannya.",
        content11: "2 Segala opini, berita, diskusi, analisis, harga, rekomendasi, dan informasi lain di situs ini merupakan komentar pasar secara umum dan bukan merupakan nasihat investasi. Kami tidak bertanggung jawab atas segala kerugian yang timbul secara langsung atau tidak langsung akibat ketergantungan pada informasi ini, termasuk namun tidak terbatas pada hilangnya keuntungan.",
        content12: "3 Situs web ini berhak membuat keputusan sendiri, memodifikasi atau mengubah konten kapan saja. Kami telah mengambil tindakan yang wajar untuk memastikan keakuratan informasi di situs web, namun kami tidak dapat menjamin keakuratannya, dan kami tidak akan bertanggung jawab atas kerugian atau kerusakan apa pun yang disebabkan oleh informasi di situs web ini atau kurangnya informasi Kerugian langsung atau tidak langsung yang timbul dari penundaan atau kegagalan dalam menghubungkan ke Internet, mengirimkan atau menerima pemberitahuan dan informasi apa pun.",
        content13: "4 Ada juga risiko dalam menggunakan sistem perdagangan berbasis Internet, termasuk namun tidak terbatas pada kegagalan perangkat lunak, perangkat keras, dan koneksi Internet. Karena kami tidak memiliki kendali atas keandalan dan ketersediaan Internet, kami tidak bertanggung jawab atas distorsi, penundaan, dan kegagalan koneksi.",
        content14: "5 Dilarang menggunakan situs ini untuk terlibat dalam semua aktivitas perdagangan ilegal atau aktivitas ilegal seperti pencucian uang, penyelundupan, penyuapan komersial, dll. Jika ditemukan dugaan transaksi ilegal atau aktivitas ilegal, situs web ini akan menggunakan berbagai cara yang ada, termasuk namun tidak terbatas pada membekukan akun dan memberi tahu pihak terkait, pihak berwenang, dll., kami tidak memikul semua tanggung jawab yang timbul darinya dan berhak untuk meminta pertanggungjawaban dari pihak terkait.",
        content15: "6 Dilarang menggunakan situs web ini untuk memanipulasi pasar secara jahat, melakukan transaksi yang tidak pantas, dan aktivitas perdagangan tidak etis lainnya. Jika insiden tersebut ditemukan, situs web ini akan memperingatkan, membatasi transaksi, dan menutup semua perilaku tidak etis seperti manipulasi harga yang berbahaya dan pengaruh jahat pada pasar. sistem perdagangan. Kami tidak menanggung semua tanggung jawab yang timbul dari tindakan perlindungan preventif seperti penangguhan akun dan berhak untuk meminta pertanggungjawaban dari pihak terkait.",
        content16: "1. Prinsip umum",
        content17: '1.1 "Perjanjian Pengguna" (selanjutnya disebut "Perjanjian ini" atau "Syarat dan Ketentuan Ini"), terdiri dari teks utama, "Ketentuan Privasi", "Kenali Pelanggan Anda dan Kebijakan Anti Pencucian Uang" dan berbagai kebijakan lain yang memiliki telah diterbitkan atau mungkin diterbitkan di masa depan di situs web ini Peraturan kelas, pernyataan, deskripsi, dll.',
        content18: "1.2 Anda harus membaca perjanjian ini dengan cermat sebelum menggunakan layanan yang disediakan oleh situs web ini. Jika Anda tidak memahami apa pun atau jika diperlukan, silakan berkonsultasi dengan pengacara profesional. Jika Anda tidak menyetujui Perjanjian ini dan/atau perubahannya sewaktu-waktu, harap segera berhenti menggunakan layanan yang disediakan oleh situs web ini atau tidak lagi masuk ke situs web ini. Setelah Anda masuk ke situs web ini, menggunakan layanan apa pun dari situs web ini atau perilaku serupa lainnya, itu berarti Anda telah memahami dan sepenuhnya menyetujui isi Perjanjian ini, termasuk setiap modifikasi yang dilakukan oleh situs web ini terhadap Perjanjian ini kapan saja.",
        content19: '1.3 Anda dapat menjadi anggota situs web ini (selanjutnya disebut sebagai "anggota") dengan mengisi informasi yang relevan sesuai dengan persyaratan situs web ini dan berhasil mendaftar setelah prosedur terkait lainnya.Dengan mengklik tombol "Setuju" saat pendaftaran proses, Anda menandatangani secara elektronik Bentuk perjanjian dengan perusahaan; atau ketika Anda mengklik tombol apa pun yang bertanda "Setuju" atau arti serupa selama penggunaan situs web ini, atau benar-benar menggunakan layanan yang disediakan oleh situs web ini dengan cara lain yang diizinkan oleh situs web ini , itu berarti Anda sepenuhnya memahami, menyetujui dan menerima semua ketentuan dalam Perjanjian ini. Tidak adanya tanda tangan tulisan tangan Anda tidak akan mempengaruhi kekuatan pengikatan hukum Perjanjian ini pada Anda.',
        content20: "1.4 Setelah menjadi anggota situs web ini, Anda akan menerima akun anggota dan kata sandi terkait, yang Anda simpan; Anda bertanggung jawab secara hukum atas semua aktivitas dan acara yang dilakukan di bawah akun Anda.",
        content21: "1.5 Hanya anggota yang menjadi anggota situs web ini yang dapat menggunakan platform perdagangan aset digital yang disediakan situs web ini untuk bertransaksi dan menikmati layanan lain yang disediakan oleh situs web ini yang hanya tersedia untuk anggota; non-anggota hanya dapat masuk ke situs web, menelusuri situs web dan layanan lain yang disediakan oleh situs web ini.",
        content22: "1.6 Dengan mendaftar dan menggunakan segala layanan dan fungsi yang disediakan oleh website ini, Anda dianggap telah membaca, memahami dan:",
        content23: "1.6.1 Menerima untuk terikat oleh semua syarat dan ketentuan Perjanjian ini.",
        content24: "1.6.2 Anda mengonfirmasi bahwa Anda berusia di atas 18 tahun atau memiliki usia legal untuk menandatangani kontrak sesuai dengan undang-undang yang berlaku. Pendaftaran Anda di situs web ini, penjualan atau pembelian, penerbitan informasi, dll., dan penerimaan layanan situs web ini harus mematuhi dengan kebutuhan Anda Memiliki yurisdiksi atas undang-undang dan peraturan yang relevan di negara atau wilayah berdaulat, dan memiliki kemampuan penuh untuk menerima persyaratan ini, melakukan transaksi, dan menggunakan situs web ini untuk melakukan transaksi aset digital.",
        content25: "1.6.3 Anda menjamin bahwa seluruh aset digital milik Anda yang terlibat dalam transaksi diperoleh secara sah dan memiliki kepemilikan.",
        content26: "1.6.4 Anda setuju bahwa Anda sepenuhnya bertanggung jawab atas aktivitas perdagangan atau non-perdagangan Anda sendiri dan atas segala keuntungan atau kerugian.",
        content27: "1.6.5 Anda mengonfirmasi bahwa informasi yang Anda berikan saat mendaftar adalah benar dan akurat.",
        content28: "1.6.6 Anda setuju untuk mematuhi persyaratan undang-undang yang relevan, termasuk melaporkan keuntungan perdagangan apa pun untuk tujuan perpajakan.",
        content29: "1.6.7 Anda setuju untuk tidak terlibat atau berpartisipasi dalam perilaku atau aktivitas yang merugikan kepentingan situs web ini atau perusahaan kapan pun, baik terkait dengan layanan yang disediakan oleh situs web ini maupun tidak.",
        content30: "1.6.8 Perjanjian ini hanya mengatur hak dan kewajiban antara Anda dan kami, dan tidak melibatkan hubungan hukum dan perselisihan hukum yang timbul dari transaksi aset digital antara pengguna situs web ini dan situs web lain dan Anda.",
        content31: "2. Amandemen perjanjian",
        content32: "Kami berhak untuk merevisi Perjanjian ini dari waktu ke waktu dan mengumumkannya di situs web tanpa memberi tahu Anda secara terpisah. Setelah revisi, waktu revisi akan ditandai pada [Waktu Pembaruan Terakhir] yang ditampilkan dalam Perjanjian ini, dan secara otomatis akan memakan waktu berlaku setelah dipublikasikan di situs web. Anda harus menelusuri dan memperhatikan waktu pembaruan dan konten yang diperbarui dari Perjanjian ini dari waktu ke waktu. Jika Anda tidak setuju dengan perubahan yang relevan, Anda harus segera berhenti menggunakan layanan situs web ini; Anda terus menggunakan layanan ini website berarti Anda menerima dan setuju untuk terikat dengan perjanjian yang direvisi. .",
        content33: "3. Pendaftaran",
        content34: "3.1 Kualifikasi pendaftaran",
        content35: "Anda mengonfirmasi dan berjanji bahwa ketika Anda menyelesaikan proses pendaftaran atau benar-benar menggunakan layanan yang disediakan oleh situs web ini dengan cara lain yang diizinkan oleh situs web ini, Anda harus memiliki kemampuan untuk menandatangani perjanjian ini dan menggunakan layanan situs web ini sebagaimana diatur oleh undang-undang yang berlaku. orang perseorangan, badan hukum, atau organisasi lain. Setelah Anda mengklik tombol Setuju untuk Mendaftar, berarti Anda atau agen resmi Anda telah menyetujui isi perjanjian dan telah terdaftar serta menggunakan layanan website ini oleh agennya. Jika Anda tidak memiliki kualifikasi subjek yang disebutkan di atas, Anda dan agen resmi Anda akan menanggung semua konsekuensi yang diakibatkannya, dan perusahaan berhak untuk membatalkan atau membekukan akun Anda secara permanen, dan meminta pertanggungjawaban Anda dan agen resmi Anda. .",
        content36: "3.2 Tujuan pendaftaran",
        content37: "Anda mengonfirmasi dan berjanji bahwa pendaftaran Anda di website ini bukan dengan tujuan untuk melanggar peraturan perundang-undangan atau mengganggu ketertiban transaksi aset digital di website ini.",
        content38: "3.3 Proses pendaftaran",
        content39: "3.3.1 Anda setuju untuk memberikan alamat email yang valid, nomor ponsel dan informasi lainnya sebagaimana diperlukan pada halaman pendaftaran pengguna situs web ini. Anda dapat menggunakan alamat email, nomor ponsel yang Anda berikan atau konfirmasi atau metode lain yang diizinkan oleh situs web ini sebagai login metode untuk memasuki situs web ini. Jika perlu, sesuai dengan undang-undang dan peraturan terkait di yurisdiksi yang berbeda, Anda harus memberikan nama asli Anda, dokumen identitas, dan informasi relevan lainnya yang diatur dalam undang-undang dan peraturan, klausul privasi, dan klausul anti pencucian uang, dan terus memperbarui informasi pendaftaran di secara tepat waktu, rinci dan akurat. Semua informasi yang diketik asli akan dirujuk sebagai informasi pendaftaran. Anda bertanggung jawab atas keaslian, kelengkapan dan keakuratan informasi tersebut, dan menanggung segala kerugian langsung atau tidak langsung serta konsekuensi buruk yang timbul karenanya.",
        content40: "3.3.2 Jika undang-undang, peraturan, aturan, perintah, dan peraturan lain dari negara atau wilayah berdaulat di mana Anda berada memiliki persyaratan nama asli untuk nomor telepon seluler, Anda setuju bahwa nomor telepon seluler terdaftar yang diberikan telah didaftarkan dengan nama asli. Jika Anda tidak menyediakannya sesuai dengan peraturan, segala akibat yang ditimbulkan pada Anda akan berupa kerugian langsung atau tidak langsung dan akibat buruk ditanggung oleh Anda.",
        content41: "3.3.3 Jika Anda memberikan informasi yang diperlukan untuk pendaftaran secara sah, lengkap dan efektif serta telah memverifikasinya, Anda berhak mendapatkan nomor akun dan kata sandi situs web ini. Ketika Anda mendapatkan nomor akun dan kata sandi situs web ini, pendaftaran Anda dianggap sebagai berhasil dan Anda dapat login sebagai anggota di website ini.",
        content42: "3.3.4 Anda setuju untuk menerima email dan/atau pesan singkat yang dikirimkan oleh situs ini terkait dengan pengelolaan dan pengoperasian situs ini.",
        content43: "4. Pelayanan",
        content44: "Website ini hanya menyediakan layanan platform perdagangan online untuk aktivitas perdagangan aset digital Anda (termasuk namun tidak terbatas pada perdagangan aset digital dan layanan lainnya) melalui website ini.",
        content45: "4.1 Konten Layanan",
        content46: "4.1.1 Anda berhak menelusuri kondisi pasar real-time dan informasi transaksi berbagai produk aset digital di website ini, dan Anda berhak menyampaikan instruksi transaksi aset digital dan menyelesaikan transaksi aset digital melalui website ini.",
        content47: "4.1.2 Anda berhak melihat informasi akun keanggotaan Anda di situs web ini dan menerapkan fungsi yang disediakan oleh situs web ini untuk beroperasi.",
        content48: "4.1.3 Anda berhak untuk berpartisipasi dalam aktivitas situs web yang diselenggarakan oleh situs web ini sesuai dengan aturan aktivitas yang dipublikasikan di situs web ini.",
        content49: "4.1.4 Situs web ini berjanji untuk memberi Anda layanan lain.",
        content50: "4.2 Aturan Layanan Anda berjanji untuk mematuhi aturan layanan situs web berikut ini:",
        content51: "4.2.1 Anda harus mematuhi undang-undang, peraturan, dan persyaratan kebijakan, memastikan legalitas sumber semua aset digital di akun Anda, dan tidak boleh terlibat dalam aktivitas ilegal atau aktivitas lain yang merugikan hak dan kepentingan situs web ini atau pihak ketiga di situs web ini atau dengan menggunakan layanannya, termasuk namun tidak terbatas pada: Anda tidak terbatas pada mengirimkan atau menerima informasi ilegal, tidak sah, atau melanggar hak orang lain, mengirim atau menerima skema piramida atau informasi atau komentar berbahaya lainnya, menggunakan atau memalsukan email informasi header di situs web ini tanpa izin dari situs web ini, dll.",
        content52: "4.2.2 Anda harus mematuhi undang-undang dan peraturan serta menggunakan dan menyimpan dengan benar nomor akun Anda, kata sandi login, kata sandi dana, nomor ponsel yang terikat pada Anda saat mendaftar, dan kode verifikasi ponsel yang diterima oleh ponsel Anda. Anda bertanggung jawab penuh atas segala operasi dan konsekuensi penggunaan akun dan kata sandi login, kata sandi dana, dan kode verifikasi ponsel Anda. Ketika Anda menemukan bahwa nomor akun situs web ini, kata sandi login, atau kata sandi dana atau kode verifikasi digunakan oleh pihak ketiga tanpa izin Anda, atau ada masalah keamanan akun lainnya, Anda harus segera dan secara efektif memberi tahu situs web ini dan meminta situs web ini untuk ditangguhkan. layanan akun website ini. . Website ini berhak mengambil tindakan atas permintaan Anda dalam jangka waktu yang wajar, namun website ini tidak bertanggung jawab atas akibat (termasuk namun tidak terbatas pada kerugian yang Anda derita) yang terjadi sebelum mengambil tindakan. Anda tidak boleh menyumbangkan, meminjam, menyewakan, mentransfer, atau membuang akun Anda di situs web ini kepada orang lain tanpa izin dari situs web ini.",
        content53: "4.2.3 Anda setuju untuk bertanggung jawab atas semua aktivitas yang terjadi berdasarkan akun dan kata sandi Anda di situs web ini (termasuk namun tidak terbatas pada keterbukaan informasi, posting informasi, klik online untuk menyetujui atau penyerahan berbagai aturan dan perjanjian, pembaruan perjanjian secara online atau pembelian layanan, dll).",
        content54: "4.2.4 Saat melakukan transaksi aset digital di situs web ini, Anda tidak boleh dengan sengaja mengganggu kemajuan normal transaksi aset digital atau mengganggu urutan transaksi; Anda tidak boleh mengganggu pengoperasian normal situs web ini atau mengganggu penggunaan situs web ini oleh pengguna lain. layanan dengan cara teknis apa pun atau cara lain; Anda tidak boleh menggunakan fiktif. Dengan sengaja mencemarkan nama baik situs web ini melalui fakta dan cara lain.",
        content55: "4.2.5 Jika Anda memiliki perselisihan dengan pengguna lain karena transaksi online, Anda tidak boleh meminta situs web ini untuk memberikan informasi yang relevan melalui saluran non-yudisial atau administratif.",
        content56: "4.2.6 Anda sendiri yang akan menilai dan menanggung pajak apa pun yang harus dibayar, serta semua perangkat keras, perangkat lunak, layanan, dan biaya lain yang timbul selama Anda menggunakan layanan yang disediakan oleh situs web ini.",
        content57: "4.2.7 Anda harus mematuhi perjanjian ini dan persyaratan layanan lainnya serta aturan pengoperasian yang dipublikasikan dan diperbarui oleh situs web ini dari waktu ke waktu, dan berhak untuk menghentikan penggunaan layanan yang disediakan oleh situs web ini kapan saja.",
        content58: "4.3 aturan produk",
        content59: "4.3.1 Telusuri informasi transaksi",
        content60: "Saat Anda menelusuri informasi transaksi di situs ini, Anda harus membaca dengan cermat semua konten yang terkandung dalam informasi transaksi, termasuk namun tidak terbatas pada harga, volume komisi, biaya penanganan, arah pembelian atau penjualan, dan hanya setelah Anda sepenuhnya menerima semua konten yang terkandung dalam informasi transaksi Perdagangan dapat dilakukan dengan mengklik tombol.",
        content61: "4.3.2 Lihat detail transaksi",
        content62: "Anda dapat melihat catatan transaksi terkait melalui akun Anda.",
        content63: "5. Hak dan kewajiban website ini",
        content64: "5.1 Jika Anda tidak memiliki kualifikasi pendaftaran yang ditentukan dalam perjanjian ini, situs ini berhak menolak pendaftaran Anda. Bagi yang sudah mendaftar, situs ini berhak membatalkan akun keanggotaan Anda. Situs ini berhak menahan Anda atau agen resmi Anda bertanggung jawab.s benar. Pada saat yang sama, situs web ini berhak memutuskan apakah akan menerima pendaftaran Anda dalam keadaan lain.",
        content65: "5.2 Berdasarkan penilaian situs web ini sendiri, jika situs web ini menemukan bahwa Anda atau pengguna akun terkait Anda tidak cocok untuk investasi berisiko tinggi, kami berhak untuk menangguhkan atau menghentikan akun Anda dan penggunaan semua akun terkait.",
        content66: "5.3 Ketika situs web ini menemukan bahwa pengguna akun bukanlah pendaftar awal akun tersebut, maka situs ini berhak untuk menangguhkan atau menghentikan penggunaan akun tersebut.",
        content67: "5.4 Ketika situs web ini secara wajar mencurigai bahwa informasi yang Anda berikan salah, tidak benar, tidak valid atau tidak lengkap melalui pengujian teknis, pengambilan sampel manual, dan metode pengujian lainnya, situs ini berhak memberi tahu Anda untuk memperbaiki atau memperbarui informasi atau untuk menangguhkan atau menghentikan penyediaan. layanan situs web ini kepada Anda.",
        content68: "5.5 Situs web ini berhak mengoreksi informasi apa pun yang ditampilkan di situs web ini jika ditemukan adanya kesalahan yang nyata.",
        content69: "5.6 Situs web ini berhak untuk mengubah, menangguhkan, atau menghentikan layanan situs web ini kapan saja. Situs web ini tidak perlu memberi tahu Anda terlebih dahulu untuk menggunakan hak untuk mengubah atau menghentikan layanan. Jika situs web ini menghentikan satu atau lebih layanan ini situs web, penghentian akan dilakukan dari situs web ini di situs web Efektif pada tanggal publikasi pengumuman penghentian.",
        content70: "5.7 Situs web ini akan mengambil sarana teknis dan langkah-langkah manajemen yang diperlukan untuk memastikan pengoperasian normal situs web ini, menyediakan lingkungan perdagangan dan layanan perdagangan yang diperlukan dan dapat diandalkan, dan menjaga ketertiban transaksi aset digital.",
        content71: "5.8 Jika Anda tidak masuk ke situs ini menggunakan akun keanggotaan dan kata sandi Anda selama satu tahun berturut-turut, situs ini berhak membatalkan akun Anda. Setelah akun dibatalkan, situs web ini berhak membuka nama anggota yang sesuai kepada pengguna lain untuk pendaftaran dan penggunaan.",
        content72: "5.9 Situs web ini memastikan keamanan aset digital Anda dengan memperkuat investasi teknologi, meningkatkan tindakan pencegahan keamanan dan tindakan lainnya, dan akan memberi tahu Anda sebelumnya ketika risiko keamanan yang dapat diperkirakan terjadi pada akun Anda.",
        content73: "5.10 Situs web ini berhak menghapus segala jenis informasi konten di situs web ini yang tidak mematuhi peraturan perundang-undangan atau peraturan situs web ini kapan pun. Situs web ini tidak perlu memberi tahu Anda terlebih dahulu untuk menggunakan hak tersebut.",
        content74: "5.11 Situs web ini berhak meminta Anda untuk memberikan lebih banyak informasi atau materi sesuai dengan undang-undang, peraturan, aturan, perintah, dan persyaratan peraturan lainnya di negara atau wilayah kedaulatan Anda, dan untuk mengambil tindakan yang wajar untuk mematuhi persyaratan peraturan setempat. berkewajiban untuk bekerja sama; situs web ini berhak untuk menangguhkan atau secara permanen menghentikan pembukaan sebagian atau seluruh layanan situs web ini kepada Anda sesuai dengan persyaratan undang-undang, peraturan, aturan, perintah, dan spesifikasi lain dari negara atau wilayah kedaulatan Anda.",
        content75: "5.12 Situs web ini berhak menutup akun Anda setelah pemberitahuan tujuh hari kerja atas kebijakannya sendiri. Oleh karena itu, Anda memiliki waktu tujuh hari kerja untuk membatalkan pesanan dan menutup posisi. Jika Anda belum membatalkan pesanan dan menutup posisi ketika jangka waktu berakhir, kami akan secara paksa membatalkan pesanan dan menutup posisi, serta mengembalikan sisa aset digital di akun Anda kepada Anda.",
        content76: "5.13 Untuk melindungi hak dan kepentingan pedagang, situs web ini berhak melakukan penyesuaian terhadap produk online dalam keadaan khusus (seperti kegagalan sistem, kegagalan jaringan, kondisi pasar ekstrem, dll.), seperti: kontrak pengiriman awal dan penyelesaian, jenis kontrak dan kontrak untuk pengiriman dan penyelesaian awal Jangka waktu dan penyelesaian serta harga pengiriman tunduk pada pengumuman di situs web ini.",
        content77: "6. Kompensasi",
        content78: "6.1 Tanggung jawab kami kepada Anda atas kerusakan langsung tidak akan melebihi total biaya layanan yang kami bebankan kepada Anda atas penggunaan situs web ini selama jangka waktu tiga (3) bulan.",
        content79: "6.2 Jika Anda melanggar Perjanjian ini atau undang-undang dan peraturan lainnya, Anda harus memberikan kompensasi kepada kami setidaknya sebesar US$2 juta dan menanggung semua biaya yang timbul (termasuk biaya pengacara, dll.). Jika tidak cukup untuk mengkompensasi kerugian yang sebenarnya, Anda harus melakukan untuk itu.",
        content80: "7. Hak untuk meminta ganti rugi",
        content81: "Kami dan Anda berdua mengakui bahwa upaya hukum yang berlaku umum atas pelanggaran kontrak atau kemungkinan pelanggaran kontrak yang Anda lakukan mungkin tidak cukup untuk mengkompensasi semua kerugian yang kami derita, sehingga kami berhak untuk meminta ganti rugi sebagaimana diizinkan oleh hukum umum atau ekuitas dalam perjanjian. jika terjadi pelanggaran kontrak oleh Anda atau kemungkinan pelanggaran kontrak, semua upaya hukum lainnya.",
        content82: "8. Batasan tanggung jawab dan penafian",
        content83: "8.1 Anda memahami dan menyetujui bahwa, dalam keadaan apa pun, kami tidak akan bertanggung jawab atas hal-hal berikut:",
        content84: "8.1.1 kehilangan pendapatan;",
        content85: "8.1.2 keuntungan perdagangan atau kerugian kontrak;",
        content86: "8.1.3 Kerugian akibat terhentinya usaha;",
        content87: "8.1.4 hilangnya tabungan moneter yang diantisipasi;",
        content88: "8.1.5 Kerugian yang disebabkan oleh masalah informasi;",
        content89: "8.1.6 hilangnya peluang, niat baik atau reputasi;",
        content90: "8.1.7 kerusakan atau kehilangan data;",
        content91: "8.1.8 biaya pembelian produk atau jasa pengganti;",
        content92: "8.1.9 Setiap kerugian atau kerusakan tidak langsung, khusus atau insidental yang timbul dari perbuatan melawan hukum (termasuk kelalaian), pelanggaran kontrak atau alasan lainnya, baik kerugian atau kerusakan tersebut dapat diperkirakan secara wajar oleh kami atau tidak; baik kami telah diberitahu atau tidak mengenai adanya hal tersebut terlebih dahulu Kemungkinan Kehilangan atau Kerusakan.",
        content93: "8.1.1 Artikel hingga 8.1.9 tidak bergantung satu sama lain.",
        content94: "8.2 Anda memahami dan menyetujui bahwa kami tidak bertanggung jawab atas kerugian apa pun yang menimpa Anda karena keadaan berikut:",
        content95: "8.2.1 Transaksi spesifik Anda mungkin melibatkan pelanggaran besar terhadap hukum atau pelanggaran kontrak.",
        content96: "8.2.2 Perilaku Anda di situs ini mungkin ilegal atau tidak etis.",
        content97: "8.2.3 Biaya dan kerugian yang timbul karena pembelian atau perolehan data, informasi, atau pelaksanaan transaksi apa pun melalui layanan situs web ini atau tindakan penggantinya.",
        content98: "8.2.4 Kesalahpahaman Anda tentang layanan website ini.",
        content99: "8.2.5 Segala kerugian lain terkait dengan layanan yang disediakan oleh website ini yang bukan disebabkan oleh kami.",
        content100: "8.3 Kami bertanggung jawab atas pemeliharaan peralatan jaringan informasi, kegagalan koneksi jaringan informasi, komputer, komunikasi atau kegagalan sistem lainnya, kegagalan listrik, kondisi cuaca, kecelakaan, pemogokan, perselisihan perburuhan, kerusuhan, pemberontakan, kerusuhan, produktivitas atau bahan produksi yang tidak mencukupi, kebakaran, Banjir , badai, ledakan, perang, alasan bank atau mitra lainnya, jatuhnya pasar aset digital, tindakan pemerintah, perintah lembaga peradilan atau administratif, tindakan lain yang berada di luar kendali kami atau yang tidak dapat kami kendalikan, atau alasan pihak ketiga Kami tidak bertanggung jawab atas ketidakmampuan atau keterlambatan layanan, atau kerugian Anda.",
        content101: "8.4 Kami tidak dapat menjamin bahwa semua informasi, program, teks, dll yang terkandung dalam situs web ini benar-benar aman dan tidak akan diganggu dan dihancurkan oleh virus, trojan horse, dan program jahat lainnya. Oleh karena itu, jika Anda masuk dan menggunakan layanan apa pun di situs web ini atau mengunduh dan menggunakan program apa pun yang diunduh, Informasi, data, dll. adalah keputusan Anda sendiri dan Anda menanggung risiko dan kemungkinan kerugian atas risiko Anda sendiri.",
        content102: "8.5 Kami tidak membuat jaminan atau komitmen apa pun mengenai informasi, produk, dan layanan apa pun dari situs web pihak ketiga mana pun yang tertaut ke situs web ini atau segala bentuk konten lain yang bukan milik kami. Jika Anda menggunakan layanan apa pun, informasi dan layanan yang disediakan oleh pihak ketiga -situs web pihak, Produk, dll. adalah keputusan Anda sendiri dan Anda menanggung semua tanggung jawab yang timbul darinya.",
        content103: "8.6 Kami tidak memberikan jaminan tersurat maupun tersirat atas penggunaan Anda atas layanan di situs web ini, termasuk namun tidak terbatas pada kesesuaian layanan yang disediakan oleh situs web ini, tidak ada kesalahan atau kelalaian, kontinuitas, keakuratan, keandalan, dan kesesuaian untuk tujuan tertentu. tujuan. Pada saat yang sama, kami tidak membuat komitmen atau jaminan apa pun mengenai validitas, keakuratan, kebenaran, keandalan, kualitas, stabilitas, kelengkapan, dan ketepatan waktu teknologi dan informasi yang terlibat dalam layanan yang disediakan oleh situs web ini. Apakah akan masuk atau menggunakan layanan yang disediakan oleh situs web ini adalah keputusan pribadi Anda dan Anda menanggung risiko dan kemungkinan kerugian Anda sendiri. Kami tidak memberikan jaminan tersurat maupun tersirat mengenai pasar, nilai, dan harga aset digital. Anda memahami dan memahami bahwa pasar aset digital tidak stabil. Harga dan nilai dapat berfluktuasi atau runtuh secara signifikan kapan saja. Perdagangan aset digital adalah kebebasan pribadi Anda Anda memilih dan memutuskan risiko dan kemungkinan kerugian Anda sendiri.",
        content104: "8.7 Jaminan dan komitmen kami yang tercantum dalam Perjanjian ini adalah satu-satunya jaminan dan pernyataan yang kami buat sehubungan dengan Perjanjian ini dan layanan yang disediakan di situs web ini dan menggantikan segala jaminan dan komitmen yang timbul dengan cara dan cara lain apa pun, baik tertulis atau lisan, tersurat. dari atau tersirat. Semua jaminan dan pernyataan ini hanya mewakili komitmen dan jaminan kami sendiri dan tidak menjamin bahwa pihak ketiga mana pun akan mematuhi jaminan dan komitmen dalam Perjanjian ini.",
        content105: "8.8 Kami tidak mengesampingkan hak apa pun yang tidak disebutkan dalam Perjanjian ini untuk membatasi, mengecualikan, atau mengimbangi tanggung jawab kami atas kerusakan sejauh hukum yang berlaku.",
        content106: "8.9 Setelah Anda mendaftar, Anda menyetujui setiap operasi yang kami lakukan sesuai dengan aturan yang ditetapkan dalam perjanjian ini, dan segala risiko yang timbul ditanggung oleh Anda.",
        content107: "9. Pengakhiran Perjanjian",
        content108: "9.1 Situs web ini berhak untuk menghentikan semua layanan situs web ini sesuai dengan perjanjian ini.Perjanjian ini akan diakhiri pada tanggal ketika semua layanan situs web ini dihentikan.",
        content109: "9.2 Setelah pengakhiran perjanjian ini, Anda tidak mempunyai hak untuk meminta situs web ini untuk terus memberikan layanan apa pun kepadanya atau melakukan kewajiban lainnya, termasuk namun tidak terbatas pada mengharuskan situs web ini untuk menyimpan atau mengungkapkan kepada Anda informasi apa pun di akun situs aslinya. kepada Anda atau pihak ketiga. Pihak ketiga meneruskan informasi apa pun yang belum dibaca atau dikirim.",
        content110: "9.3 Pengakhiran perjanjian ini tidak akan mempengaruhi tanggung jawab lain pihak yang tidak melanggar terhadap pihak yang melanggar.",
        content111: "10. Hak Kekayaan Intelektual",
        content112: "10.1 Semua pencapaian intelektual yang terdapat dalam situs web ini termasuk namun tidak terbatas pada logo situs web, basis data, desain situs web, teks dan grafik, perangkat lunak, foto, video, musik, suara dan kombinasinya, kompilasi perangkat lunak, kode sumber terkait, dan perangkat lunak (termasuk aplikasi kecil ) Hak kekayaan intelektual atas program dan skrip) adalah milik situs web ini. Anda tidak boleh mereproduksi, mengubah, menyalin, mengirim atau menggunakan materi atau konten apa pun di atas untuk tujuan komersial.",
        content113: "10.2 Semua hak yang terkandung dalam nama situs web ini (termasuk namun tidak terbatas pada niat baik dan merek dagang, logo) adalah milik perusahaan.",
        content114: "10.3 Penerimaan Anda terhadap perjanjian ini akan dianggap sebagai inisiatif Anda untuk mengalihkan hak cipta atas segala bentuk informasi yang Anda terbitkan di situs web ini, termasuk namun tidak terbatas pada: hak reproduksi, hak distribusi, hak sewa, hak pameran, hak pertunjukan, hak pemutaran, hak siar, hak transmisi informasi Internet, hak pembuatan film, hak adaptasi, hak terjemahan, hak kompilasi dan hak-hak lain yang dapat dialihkan yang harus dinikmati oleh pemegang hak cipta secara eksklusif ditransfer ke situs web ini secara gratis.Situs web ini berhak untuk mengajukan independensi mengajukan gugatan terhadap subjek apa pun karena pelanggaran dan mendapatkan kompensasi penuh. Perjanjian ini berlaku untuk setiap konten karya yang dilindungi oleh undang-undang hak cipta yang Anda publikasikan di situs web ini, baik konten tersebut dibuat sebelum penandatanganan perjanjian ini atau setelah penandatanganan perjanjian ini.",
        content115: "10.4 Anda tidak boleh secara ilegal menggunakan atau membuang hak kekayaan intelektual situs web ini atau pihak lain saat menggunakan layanan situs web ini. Anda tidak boleh mempublikasikan atau mengizinkan situs web (dan media) lain untuk menggunakan informasi yang dipublikasikan di situs web ini dalam bentuk apa pun.",
        content116: "11. Perlindungan informasi",
        content117: 'Ketentuan "Ketentuan Privasi" yang dipublikasikan secara terpisah di situs web ini akan berlaku.',
        content118: "12. Perhitungan",
        content119: "Seluruh hasil perhitungan transaksi telah kami verifikasi, dan seluruh metode perhitungan telah dipublikasikan di website, namun kami tidak dapat menjamin bahwa penggunaan website tidak akan terganggu atau bebas dari kesalahan.",
        content120: "13. Pengendalian Ekspor",
        content121: "Anda memahami dan mengakui bahwa menurut undang-undang yang relevan, Anda tidak diperbolehkan mengekspor, mengekspor ulang, mengimpor atau mentransfer materi apa pun (termasuk perangkat lunak) di situs web ini, jadi Anda menjamin bahwa Anda tidak akan secara aktif menerapkan atau membantu atau berpartisipasi dalam hal apa pun. ekspor atau kegiatan terkait yang disebutkan di atas yang melanggar hukum dan peraturan. Transfer atau pelanggaran lain terhadap hukum dan peraturan yang berlaku; jika situasi seperti itu ditemukan, Anda harus secara aktif melaporkannya kepada kami pada waktu yang tepat dan membantu kami dalam menanganinya.",
        content122: "14. Pemindahan",
        content123: "Hak-hak dan kewajiban-kewajiban yang diatur dalam Perjanjian ini juga mengikat para penerima hak, ahli waris, pelaksana dan pengurus dari pihak-pihak yang memperoleh manfaat dari hak dan kewajiban tersebut. Anda tidak boleh mengalihkannya kepada pihak ketiga mana pun tanpa persetujuan kami, namun kami dapat mengalihkan hak dan kewajiban kami dalam Perjanjian ini kepada pihak ketiga mana pun kapan saja dan menyampaikan pemberitahuan kepada Anda.",
        content124: "15. Dapat dibagi",
        content125: "Jika ada ketentuan dalam Perjanjian ini yang dianggap tidak dapat dilaksanakan, tidak sah, atau ilegal oleh pengadilan mana pun dengan yurisdiksi yang kompeten, hal tersebut tidak akan memengaruhi keabsahan ketentuan lainnya dalam Perjanjian ini.",
        content126: "16. Hubungan non-agensi",
        content127: "Tidak ada ketentuan dalam Perjanjian ini yang dianggap menciptakan, menyiratkan, atau menjadikan kami sebagai agen Anda, wali amanat, atau perwakilan lainnya, kecuali ditentukan lain dalam Perjanjian ini.",
        content128: "17. Golput",
        content129: "Pengesampingan oleh kami atau Anda atas tanggung jawab pihak mana pun atas pelanggaran kontrak atau tanggung jawab lain yang ditetapkan dalam Perjanjian ini tidak akan dianggap atau ditafsirkan sebagai pelepasan tanggung jawab lain atas pelanggaran kontrak; kegagalan untuk melaksanakan hak atau upaya hukum apa pun tidak akan berarti apa pun. dapat ditafsirkan sebagai pengabaian hak-hak tersebut atau upaya untuk menyerah.",
        content130: "18. Judul",
        content131: "Semua judul ditujukan untuk kemudahan pengungkapan perjanjian saja dan tidak digunakan untuk memperluas atau membatasi isi atau ruang lingkup ketentuan perjanjian.",
        content132: "19. Hukum yang Berlaku",
        content133: "19.1 Sebelum mengajukan perselisihan atau klaim, Anda mengetahui dan setuju untuk terlebih dahulu menghubungi situs web ini melalui email untuk menyelesaikan perselisihan secara informal. Kami akan mencoba menyelesaikan perselisihan Anda secara internal sesegera mungkin; kedua belah pihak sepakat untuk menyelesaikan perselisihan melalui negosiasi dengan itikad baik (diskusi). bersifat rahasia dan dilindungi oleh peraturan yang berlaku serta tidak digunakan sebagai alat bukti dalam proses hukum apa pun).",
        content134: '19.2 Anda mengakui dan menyetujui bahwa jika terjadi perselisihan, kontroversi, ketidaksepakatan atau klaim, termasuk keberadaan, keabsahan, penafsiran, kinerja, pelanggaran atau pengakhiran Ketentuan ini atau perselisihan apa pun yang timbul dari atau sehubungan dengan Ketentuan ini ("Sengketa" ) , para pihak tersebut di atas akan mengajukan perselisihan tersebut ke Pengadilan Arbitrase Internasional dari Kamar Dagang Internasional ("Pengadilan Arbitrase Internasional ICC") sesuai dengan "Peraturan Arbitrase ICC" yang berlaku pada saat itu. Tempat arbitrase adalah Belize dan hukum arbitrase yang berlaku akan ditentukan sesuai dengan Peraturan Arbitrase Kamar Dagang Internasional. Kecuali disepakati lain oleh para pihak, hanya satu arbiter yang akan ditunjuk berdasarkan Peraturan Arbitrase ICC. Arbiter mempunyai kewenangan eksklusif untuk memutuskan yurisdiksinya sendiri, termasuk, namun tidak terbatas pada, setiap keberatan terhadap keberadaan, ruang lingkup, atau keabsahan perjanjian arbitrase atau kesewenang-wenangan klaim atau tuntutan balik. Arbitrase apa pun akan dilakukan dalam bahasa Inggris. Terlepas dari bagaimana arbitrase dilakukan, arbiter harus mengeluarkan putusan tertulis yang masuk akal dan cukup untuk menjelaskan putusan tersebut dan kesimpulan penting, jika ada, yang menjadi dasar putusan tersebut. Putusan arbitrase bersifat final dan mengikat para pihak dan dapat dilaksanakan di pengadilan mana pun dengan yurisdiksi yang kompeten.',
        content135: "20. Efektivitas dan penafsiran perjanjian",
        content136: "20.1 Perjanjian ini berlaku ketika Anda mengklik halaman pendaftaran situs web ini untuk menyetujui pendaftaran, menyelesaikan proses pendaftaran, dan mendapatkan akun dan kata sandi untuk situs web ini. Perjanjian ini mengikat baik situs web ini maupun Anda.",
        content137: "20.2 Hak interpretasi akhir dari perjanjian ini adalah milik situs web ini.",
        content138: "Kenali pelanggan Anda dan kebijakan anti pencucian uang",
        content139: "1. Perkenalan",
        content140: '1.1 Kami berjanji untuk secara hati-hati mematuhi hukum dan peraturan terkait dengan "Kenali Pelanggan Anda" dan anti pencucian uang dan tidak akan dengan sengaja melanggar "Kebijakan Kenali Pelanggan Anda dan Anti Pencucian Uang". Dalam lingkup kendali wajar kami, kami akan mengambil tindakan dan teknologi yang diperlukan untuk memberi Anda layanan yang aman dan melindungi Anda dari kerugian yang disebabkan oleh perilaku pencucian uang tersangka kriminal sebanyak mungkin.',
        content141: "1.2 Kebijakan Kenali Pelanggan Anda dan Anti Pencucian Uang kami adalah sistem kebijakan internasional komprehensif yang mencakup kebijakan Kenali Pelanggan Anda dan Anti Pencucian Uang untuk berbagai yurisdiksi hukum tempat Anda berafiliasi.",
        content142: "2. Kebijakan mengenal pelanggan dan anti pencucian uang adalah sebagai berikut:",
        content143: "2.1 Menyebarkan kebijakan mengenal pelanggan Anda dan kebijakan anti pencucian uang serta memperbaruinya dari waktu ke waktu untuk memenuhi standar yang ditetapkan oleh undang-undang dan peraturan terkait;",
        content144: "2.2 Menyebarkan dan memperbarui beberapa prinsip dan aturan panduan untuk mengoperasikan situs web ini, dan karyawan kami akan memberikan layanan sesuai dengan panduan prinsip dan aturan ini;",
        content145: "2.3 Merancang dan melengkapi prosedur untuk pemantauan internal dan pengendalian transaksi, seperti memverifikasi identitas melalui cara yang ketat dan mengatur pembentukan tim profesional yang bertanggung jawab atas pekerjaan anti pencucian uang;",
        content146: "2.4 Menggunakan metode pencegahan risiko dengan melakukan uji tuntas dan pengawasan berkelanjutan terhadap pelanggan;",
        content147: "2.5 Meninjau dan memeriksa secara berkala transaksi yang telah terjadi;",
        content148: "2.6 Melaporkan transaksi mencurigakan kepada pihak yang berwenang;",
        content149: "2.7 Bukti identitas, alamat, dan catatan transaksi akan disimpan setidaknya selama enam tahun dan dapat diserahkan kepada pihak berwenang tanpa pemberitahuan lebih lanjut kepada Anda.",
        content150: "3. Informasi dan verifikasi identitas",
        content151: "3.1 Informasi Identitas",
        content152: "3.1.1 Menurut peraturan yang berbeda di yurisdiksi yang berbeda dan jenis entitas yang berbeda, konten informasi Anda yang kami kumpulkan mungkin tidak konsisten. Pada prinsipnya, informasi berikut akan dikumpulkan dari individu yang terdaftar:",
        content153: "Informasi pribadi dasar: nama Anda, alamat (dan alamat tetap, jika berbeda), tanggal lahir dan kewarganegaraan serta informasi lain yang tersedia. Verifikasi identitas harus didasarkan pada dokumen yang dikeluarkan oleh pejabat atau otoritas serupa lainnya, seperti paspor, kartu identitas, atau dokumen identifikasi lainnya yang diperlukan dan dipicu oleh yurisdiksi berbeda. Alamat yang Anda berikan akan diverifikasi menggunakan metode yang tepat, seperti memeriksa tiket angkutan penumpang atau catatan suku bunga atau memeriksa daftar pemilih.",
        content154: "Foto yang Sah: Sebelum mendaftar, Anda harus memberikan foto diri Anda yang sedang memegang kartu identitas di dada;",
        content155: "Detail kontak: Nomor telepon/ponsel dan/atau alamat email yang valid.",
        content156: "3.1.2 Jika Anda adalah korporasi atau badan hukum lainnya, kami akan mengumpulkan informasi berikut untuk mengidentifikasi Anda atau penerima manfaat utama dari akun perwalian Anda.",
        content157: "Pendaftaran dan sertifikat pendaftaran perusahaan; salinan anggaran dasar dan memorandum perusahaan; materi sertifikasi terperinci tentang struktur ekuitas perusahaan dan deskripsi kepemilikan, membuktikan keputusan dewan dari prinsipal yang berwenang yang menentukan pembukaan dan pelaksanaan akun di situs web ini; perusahaan direktur yang perlu diberikan sesuai kebutuhan, dokumen identitas pemegang saham utama dan orang yang diberi wewenang untuk menandatangani akun di situs web ini; alamat bisnis utama perusahaan, jika berbeda dengan alamat surat perusahaan, berikan alamat surat. Jika alamat lokal perusahaan tidak sesuai dengan alamat bisnis utamanya, maka perusahaan tersebut dianggap sebagai pelanggan berisiko tinggi dan perlu menyerahkan dokumentasi tambahan tambahan.",
        content158: "Bergantung pada peraturan yang berbeda di yurisdiksi yang berbeda dan jenis entitas yang berbeda, kami memerlukan sertifikasi dan dokumen lain yang dikeluarkan oleh otoritas serta dokumen yang kami anggap perlu.",
        content159: "3.1.3 Kami hanya menerima informasi identitas versi bahasa Inggris atau Mandarin. Jika tidak, harap terjemahkan informasi identitas Anda ke dalam bahasa Inggris dan mintalah notaris.",
        content160: "3.2 Konfirmasi Verifikasi",
        content161: "3.2.1 Kami meminta Anda untuk memberikan isi satu halaman penuh dari dokumen identifikasi Anda.",
        content162: "3.2.2 Kami meminta foto Anda memegang dokumen identitas di dada Anda.",
        content163: "3.2.3 Salinan dokumen pendukung umumnya harus dicocokkan dengan voucher aslinya. Namun, salinan dapat diterima jika lembaga sertifikasi yang sesuai dan dapat dipercaya dapat menyatakan bahwa salinan tersebut merupakan reproduksi yang akurat dan lengkap dari dokumen asli. Pemberi sertifikat tersebut termasuk duta besar, komisaris yudisial, hakim lokal, dan lain-lain.",
        content164: "3.2.4 Persyaratan untuk mengidentifikasi penerima manfaat utama dan pengendalian rekening adalah untuk menentukan individu mana yang pada akhirnya memiliki atau mengendalikan pelanggan langsung, dan/atau untuk menetapkan bahwa transaksi yang sedang berjalan dijalankan atas nama pihak lain. Jika ini adalah sebuah bisnis, identitas pemegang saham utama (seperti mereka yang memegang 10% atau lebih hak suara) harus diverifikasi. Secara umum, memegang 25% saham akan dianggap sebagai risiko normal, dan identitas pemegang saham harus diverifikasi; memegang 10% saham atau memegang lebih banyak hak suara atau saham dianggap sebagai situasi berisiko tinggi, dan identitas pemegang saham harus diverifikasi.",
        content165: "4. Pantau transaksi",
        content166: "4.1 Kami menetapkan dan menyesuaikan batas maksimum transaksi harian dan penarikan mata uang dari waktu ke waktu berdasarkan keamanan dan kondisi transaksi aktual;",
        content167: "4.2 Jika transaksi sering terjadi pada pengguna terdaftar atau terdapat keadaan yang tidak wajar, tim profesional kami akan mengevaluasi dan memutuskan apakah transaksi tersebut mencurigakan;",
        content168: "4.3 Jika kami menentukan bahwa suatu transaksi mencurigakan berdasarkan penilaian kami sendiri, kami dapat mengambil tindakan pembatasan seperti menangguhkan transaksi, menolak transaksi, atau bahkan membatalkan transaksi sesegera mungkin, dan melaporkannya kepada pihak yang berwenang, namun tidak akan memberitahu Anda;",
        content169: "4.4 Kami berhak menolak permohonan pendaftaran dari orang-orang dari yurisdiksi yang tidak mematuhi standar anti pencucian uang internasional atau orang-orang yang mungkin dianggap orang yang terpapar politik. Kami berhak untuk menangguhkan atau mengakhiri transaksi yang mencurigakan menurut penilaian kami sendiri di kapan saja, namun kami tidak melakukannya. Melanggar tugas atau tanggung jawab apa pun yang harus Anda bayar.",
    },
    register: {
        formTitle1: "membuat",
        formTitle2: "Daftar menggunakan email atau nomor ponsel Anda",
        formTitle3: "Akun",
        field1: "Kode verifikasi",
        field2: "Memperoleh",
        field3: "konfirmasi sandi",
        field4: "Kode undangan",
        field41: "（Opsional）",
        field5: "Saya telah membaca dan setuju",
        field6: "Ketentuan Layanan",
        placeholder1: "silakan masukkan kode verifikasi",
        content7: "silakan masukkan kode verifikasi",
        content12: "Silakan masukkan nomor telepon",
        content13: "Silakan masukkan kata sandi",
        content14: "silakan masukkan email Anda",
        content18: "Silakan masukkan kata sandi lagi",
        content19: "Harap setujui persyaratan layanan",
        content20: "Silakan masukkan kode verifikasi yang benar",
        content23: "仍Tidak menerima kode verifikasi?",
        content24: "Silakan masukkan kode verifikasi di bawah ini",
        registerSuccess: "registrasi berhasil",
        content25: "Kata sandi tidak konsisten",
        content26: "Silakan masukkan alamat email yang benar",
        content27: "Penyetelan ulang kata sandi berhasil"
    },
    download: {
        content1: "Mudah, kapan saja, di mana saja",
        content2: "Sangat kompatibel dengan banyak terminal, memenuhi kebutuhan transaksi berbagai skenario kapan saja",
        content3: "Periksa kondisi pasar real-time kapan saja",
        content4: "Beli dan jual BTC, ETH, XRP, dan aset digital lainnya dengan mudah",
        content5: "Dapatkan peringatan dini mengenai perubahan harga mata uang kripto yang Anda minati",
        content6: "Lihat pinjaman spot Bitcoin, pinjaman berjangka, dan harga opsi",
        content7: "Bandingkan harga mata uang kripto di berbagai bursa",
        title: "unduh",
        install: "catatan instalasi",
        one: "1、Izinkan file deskripsi diunduh",
        two: "2、File deskripsi diunduh",
        three: "3、Buka [Pengaturan] di ponsel Anda dan temukan [Umum]",
        four: "4、Profil dan Pengelola Perangkat",
        five: "5、Klik pada profil",
    },
    identity: {
        noAuthentication: {
            title: "Sertifikasi Junior",
            pleaseSelectYourCountry: "Silakan pilih negara Anda",
            name: "Nama",
            enterName: "Silahkan masukan nama anda",
            city: "kota",
            enterCity: "Silakan masukkan kota Anda",
            IDNumber: "nomor ID",
            enterIDNumber: "Silakan masukkan nomor ID Anda",
        },
        noAdvancedAuthentication: {
            title: "Sertifikasi lanjutan",
            infoCountry: "negara/wilayah",
            infoName: "Nama",
            infoNumber: "nomor ID",
            infoAddress: "alamat",
        },
        text: "Tidak Sertifikasi",
        recertification: "sertifikasi ulang",
        certification: "Sertifikasi",
        authenticationInProgress: "diverifikasi",
        inAuthentication: "Sertifikasi sedang berlangsung",
        uploadTheFrontOfTheDocument: "Unggah bagian depan ID",
        uploadTheReverseSideOfTheDocument: "Unggah sisi belakang sertifikat",
        uploadAPhotoOfAHandHeldID: "Upload foto KTP yang ada di tangan",
        pleaseUpload: "Tolong ",
        pleaseCompleteOrdinaryCertificationFirst: "Perlu menunggu keberhasilan tinjauan sertifikasi utama",
        refused: "ditolak",
        reason: "alasan",
        succeeded: "berhasil",
        uploading: "mengunggah",
        newTitle: "Pilih negara tempat ID Anda diterbitkan",
        pleaseOrdinaryCertificationFirst: "Silakan lakukan sertifikasi utama terlebih dahulu",
        text1: "Ubah avatar",
        text2: "nama panggilan",
        text3: "akun",
        text4: "Modifikasi nama panggilan",
        text5: "nama panggilan baru",
        text6: "Masukkan nama panggilan baru Anda",
        text7: "password lama",
        text8: "Masukkan kata sandi lama Anda",
        text9: "kata sandi baru",
        text10: "Masukkan kata sandi baru Anda",
        text11: "konfirmasi sandi",

        text12: "Masukkan kata sandi baru Anda lagi",
        text13: "kotak surat baru",
        text14: "Kode verifikasi",
        text15: "silakan masukkan kode verifikasi",
        text16: "nomor ponsel Anda",
        text17: "Nomor ponsel baru",
        text18: "Masukkan nomor telepon anda",
        text19: "Kode verifikasi ponsel",
        text20: "Sertifikasi Junior",
        text21: "Harap verifikasi informasi identitas Anda",
        text22: "Negara",
        text23: "Kota",
        text24: "Masukkan kota Anda",
        text25: "Masukkan nama Anda",
        text26: "Nomor ID Anda",
        text27: "Masukkan nomor ID Anda",
        text28: "Foto depan KTP",
        text29: "Klik untuk mengunggah dokumen Anda",
        text30: "mengunggah ulang",
        text31: "mengunggah",
        text32: "Memegang foto identitas",
        text33: "Klik untuk mengunggah foto tanda pengenal Anda",
        text34: "Anda telah mengikat Google Authenticator",
        text35: "kunci",
        text36: "Silakan masukkan kode verifikasi Google",
        text37: "mengirim",
        text38: "Tidak boleh kosong",
        text39: "Format email salah",
        text40: "Dua kata sandi tidak konsisten",
        text41: "Berhasil disalin",
        text42: "Penyalinan gagal",
        text43: "Modifikasi avatar",
        text44: "ganti kata sandi",
        text45: "Pengikatan email",
        text46: "Ubah email",
        text47: "Pengikatan ponsel",
        text48: "Modifikasi ponsel",
        text49: "Verifikasi Google",
        text50: "Ikat Google Authenticator",
        text51: "Masukkan kode verifikasi Google",
        text52: "menyimpan",
        text53: "Konfirmasi Pengiriman",
        text54: "Merevisi",
        text55: "mengikat",
        text56: "Matikan verifikasi Google",
        text57: "Langkah berikutnya",
        text58: "Menyelesaikan",
        text59: "Verifikasi Google terikat",
        text60: "Verifikasi Google telah dipisahkan",
        text61: "Sertifikasi primer telah diserahkan",
        text62: "Sertifikasi Lanjutan Diserahkan",
        text63: "Tidak ada gambar yang diunggah",
        text64: "Kirim ulang",
        text65: "Silakan masukkan nama panggilan",
        text66: "Nama panggilan berhasil disimpan",
        text67: "Belum ada gambar yang diunggah",
        text68: "Penyetelan ulang kata sandi selesai",
        text69: "Email berhasil diubah",
        text70: "Avatar berhasil disimpan",
        text71: "Unggah gambar",
        text72: "kotak surat Anda",
        text73: "Masukkan email baru Anda",
        text74: "Namamu",
        text75: "Pergi ke otentikasi",
        text76: "Harap verifikasi foto tanda pengenal Anda"
    },
    retrievePassword: {
        formTitle1: "mengatur ulang kata sandi",
        formTitle2: "Demi keamanan aset Anda, transaksi dilarang dalam waktu 24 jam setelah perubahan kata sandi.",
        formTitle3: "Ambil kata sandi",
        formTitle4: "Konfirmasikan perubahannya",
        formTitle5: "Silakan masukkan kata sandi lama",
        formTitle6: "Silakan masukkan kata sandi baru"
    },
    home: {
        title1: "Platform Perdagangan Mata Uang Kripto Paling Populer",
        title3: "kenapa ",
        title4: " Ini adalah platform blockchain sumber terbuka berkinerja tinggi yang dirancang untuk mendukung dan mengoperasikan infrastruktur digital yang aman, patuh, dan dapat diprediksi.",
        field1: "Pertukaran yang mapan",
        field2: "Layanan keuangan aset digital",
        field3: "Puluhan juta pengguna",
        field4: "Membentuk banyak dana perlindungan investor",
        field5: "Perdagangan Cepat",
        field6: "CME Gruop mampu mencocokkan pesanan dengan latensi sangat rendah untuk pengalaman perdagangan terbaik.",
        title5: "tren pasar",
        title6: "lihat lebih banyak",
        table1: "Mata uang",
        table2: "Harga terbaru",
        table3: "24 jam naik turun",
        table4: "Volume perdagangan 24 jam",
        table5: "Volume transaksi 24 jam",
        table6: "Kutipan",
        title7: "tentang ",
        title8: " Kami berkomitmen untuk menyediakan alat perdagangan tercanggih, teknologi inovatif, dan layanan pelanggan berkualitas tak tertandingi kepada pengguna.",
        title9: "Bidang aset digital terus berubah setiap harinya. Hanya dengan berpandangan jauh ke depan dan membuat kemajuan pesat kita dapat mengimbangi laju inovasi teknologi.",
        title901: " Memberikan layanan perdagangan aset digital tercanggih kepada pengguna kami dan penyedia likuiditas global.",
        title10: "Kami sangat yakin bahwa setiap pengguna berhak mendapatkan pengalaman trading terbaik.",
        title11: "Keamanan yang dapat Anda percayai",
        title12: "Langkah-langkah keamanan canggih dan dana SAFU kami melindungi aset digital Anda dari semua risiko.",
        title13: "Tarif transaksi terbaik",
        title14: "Tarif transaksi preferensial, hak VIP kompetitif, dan nikmati layanan kualitas terbaik.",
        title15: "Dukungan layanan pelanggan 24*7 jam",
        title16: "Mode operasi penuh waktu sepanjang waktu 24/7, kami akan menjawab pertanyaan Anda yang relevan sesegera mungkin.",
        title17: "0 perdagangan biaya",
        title18: "Gunakan berbagai metode pembayaran untuk memperdagangkan mata uang digital tanpa biaya penanganan, aman dan cepat.",
        title19: "Berdagang kapan saja, di mana saja",
        title20: "Sangat kompatibel dengan banyak terminal, memenuhi kebutuhan transaksi berbagai skenario kapan saja",
        title21: "Periksa kondisi pasar real-time kapan saja",
        title22: "Beli dan jual BTC, ETH, XRP, dan aset digital lainnya dengan mudah",
        title23: "Dapatkan peringatan dini mengenai perubahan harga mata uang kripto yang Anda minati",
        title24: "Lihat pinjaman spot Bitcoin, pinjaman berjangka, dan harga opsi",
        title25: "Bandingkan harga mata uang kripto di berbagai bursa",
        title26: "Daftar sekarang",
        title27: "Dolar",
        title28: "Volume perdagangan 24 jam",
        title29: "Penyedia Likuiditas Terintegrasi",
        title30: "pengguna",
        title31: "Lagi",
        title32: "Pinjaman Binance menambahkan CTSI & KSM sebagai aset jaminan, dan meluncurkan diskon pengurangan suku bunga BUSD & USDT!",
        title33: "Keuntungan kami",
        title34: "aman dan stabil",
        title35: "Tim teknis terbaik, perlindungan keamanan komprehensif, penelitian independen, dan pengembangan mesin sintesis transaksi berkecepatan tinggi, masih dapat beroperasi dengan stabil dan andal dalam transaksi bersamaan yang besar.",
        title36: "Kepatuhan Profesional",
        title37: "Tim operasi profesional, dengan pengalaman bertahun-tahun di bidang blockchain dan keuangan, memegang lisensi perdagangan aset digital yang sesuai, dan memiliki jaminan cadangan 100%.",
        title38: "Pengguna terlebih dahulu",
        title39: "Menghadapi pasar global, mendukung berbagai bahasa, beroperasi 24/7, dukungan komunitas yang kuat, dan layanan pelanggan profesional.",
        title40: "kinerja tinggi",
        title41: "300.000 transaksi per detik dan waktu respons pesanan kurang dari 1 milidetik.",
        title42: "tentang kami",
        title43: "Berdagang kapan saja, di mana saja",
        title44: "Sangat kompatibel dengan banyak terminal, memenuhi kebutuhan transaksi berbagai skenario kapan saja",
        title45: "（Pindai kode untuk mengunduh）",
        title46: "Berita Mata Uang Digital",
        title47: "mitra bisnis kami",
        title48: "Daftar pecundang",
        title49: "omset 24 jam",
        title50: "24 jam bertambah dan berkurang"
    },
    quotation: {
        marketTrading: "Kutipan",
        turnover: "Volume",
        optional: "Opsional",
        contractMarket: "pasar kontrak",
        currencyPair: "pasangan mata uang",
        latestPrice: "Harga penutup",
        upsAndDowns: "Perubahan kutipan",
        highest: "Paling tinggi",
        lowest: "terendah",
        transactionAmount: "Volume transaksi",
        operate: "beroperasi",
        buy: "berdagang",
        topTurnovers: "Daftar transaksi",
        topGainers: "Daftar pemenang",
        tradingPair: "pasangan perdagangan",
        buyOrder: "Membeli",
        sellOrder: "pesanan jual"
    },
    accountChange: {
        demo: "simulasi",
        realAccount: "akun sebenarnya",
        demoAccount: "Akun demo",
        accountRole: "Peran akun",
        pleaseSelect: "tolong pilih",
        AccountChangeType: "Jenis perubahan akun",
        AccountType: "Jenis akun",
        currencyType: "Jenis mata uang",
        allType: "Semua jenis",
        allAccount: "Semua akun",
        allCoin: "Semua mata uang",
        coin: "Mata uang",
        amount: "Ubah jumlah",
        balance: "Saldo rekening",
        time: "waktu",
        title: "Catatan perubahan akun",
        filtrar: "Saring",
        check: "beralih ke",
        showOnly: "Hanya tampilkan kepemilikan mata uang",
        holdAssets: "Saldo mata uang",
        accountList: "Daftar akun"
    },
    contractTransaction: {
        chart: "bagan",
        basicEdition: "Versi dasar",
        depthMap: "Peta kedalaman",
        second: "Kedua",
        minute: "titik",
        day: "langit",
        week: "pekan",
        month: "bulan",
        open: "membuka",
        trade: "berdagang",
        buyLong: "pembelian panjang",
        buyShort: "pembelian pendek",
        cannotBuy: "Mata uang ini tidak mendukung transaksi kontrak pengiriman",
        cannotBuy1: "Mata uang ini tidak mendukung perdagangan abadi",
        tradingAssets: "aset perdagangan",
        amountAvailable: "Kuantitas Tersedia",
        timeStr: "waktu",
        transactionPeriod: "periode perdagangan",
        quantity: "kuantitas",
        enterQuantity: "Silakan masukkan kuantitas",
        buyIn: "Bersikaplah lama",
        latestTransaction: "transaksi terbaru",
        time: "waktu",
        direction: "arah",
        price: "harga",
        sell: "pendek",
        positionOrder: "Pesan antar",
        historicalOrders: "Perintah sejarah",
        product: "produk",
        realAccount: "(akun nyata)",
        demoAccount: "(Akun demo)",
        openingTime: "waktu pemesanan",
        openingPrice: "Harga pembukaan",
        curretPrice: "Harga sekarang",
        liquidationPrice: "Harga penutup",
        profitOrLoss: "Laba rugi",
        endTime: "Waktu tutup",
        areYouSureToBuy: "Apakah Anda yakin ingin membeli?",
        areYouSureToLongBuy: "Apakah Anda yakin ingin berlama-lama?",
        areYouSureToShortBuy: "Apakah Anda yakin ingin membeli yang pendek?",
        successfullyBought: "Beli dengan sukses",
        minBuy: "Pembelian minimum",
        assets: "aktiva",
        withdraw: "menarik",
        entrustedOrder: "Memesan",
        total: "total",
        continueToPlaceOrder: "Lanjutkan melakukan pemesanan",
        orderConfirmation: "Konfirmasi pesanan",
        buyRu: "Membeli",
        sellChu: "menjual",
        areYouSureToSell: "Apakah Anda yakin ingin menjualnya?",
        operation: "beroperasi",
        buyCover: "posisi penutup",
        commitSuccess: "Transaksi berhasil",
        onlyEnterIntegers: "Hanya bilangan bulat yang dapat dimasukkan",
        trend: "Kecenderungan",
        lotteryTime: "Batas waktu pemesanan",
        countdown: "Hitung mundur",
        period: "Masalah",
        cycle: "Jangka waktu",
        unlimited: "Tak terbatas",
        put: "Put",
        call: "Call",
        pending: "Berdasarkan komisi",
        unopened: "Belum ditarik",
        opened: "Hadiah ditarik",
        settlementPrice: "Harga Penyelesaian",
        rateOfReturn: "tingkat pengembalian",
        currentPositions: "Posisi saat ini",
        positionQuantity: "Kuantitas posisi",
        guarantee: "batas",
        text1: "Naik",
        text2: "Turun",
        text3: "Menunggu untuk membeli",
        text4: "Jumlah pesanan",
        text5: "Jumlah transaksi",
        text6: "Membatalkan",
        text7: "waktu pengiriman",
        text8: "Pengingat pembatalan",
        text9: "Apakah Anda yakin ingin membatalkan pesanan?",
        text10: "Kuantitas ilegal",
        text11: "Pengingat pesanan",
        text12: "Apakah Anda yakin membeli bullish?",
        text13: "Apakah Anda yakin untuk membeli put tersebut?",
        text14: "Pembatalan berhasil"
    },
    mining: {
        title1: "Platform penambangan aset digital blockchain terkemuka di dunia",
        title2: "Terbuka dan transparan · aman dan stabil",
        title3: "Silakan pilih mata uang",
        title4: "Hasil Tahunan Penambangan",
        title5: "langit",
        title6: "Periode penambangan",
        title7: "Rincian Produk",
        btn: "Milikku sekarang",
        btn2: "penutup",
        tabs1: "sedang berlangsung",
        tabs2: "ditebus",
        table1: "nama Produk",
        table2: "Periode penambangan",
        table3: "Waktu penambangan",
        table4: "Jumlah penambangan",
        table5: "Jumlah penambangan",
        table6: "Hasil hingga jatuh tempo",
        table7: "Status pemesanan",
        egularMining: "Menambang secara teratur",
        inProgress: "sedang berlangsung",
        redeemed: "ditebus",
        redemption: "penebusan",
        regularMining: "Menambang secara teratur",
        earlyRedemption: "penebusan awal",
        need: "membutuhkan",
        handlingFee: "Biaya penanganan",
        redemptionConfirmationMessage: "Mohon konfirmasi apakah akan menebus terlebih dahulu?",
        form1: "Hasil Tahunan Penambangan",
        form2: "Periode penambangan",
        form3: "Mata uang penambangan",
        form4: "Jumlah berlangganan (minimum/maksimum)",
        form5: "Biaya penebusan awal:",
        form6: "harga pembelian",
        form7: "Dana yang tersedia",
        form8: "harga pembelian",
        form9: "Dana yang tersedia",
        form10: "Silakan masukkan jumlah pembelian",
        form11: "semua",
        form12: "Perkiraan pendapatan",
        form13: "Dana yang tersedia tidak mencukupi",
        form14: "Silakan masukkan jumlah pembelian",
        form15: "Pesananku",
        form16: "penghasilan",
        form17: "Detail pesanan",
        form18: "Petunjuk untuk penebusan awal:",
        form19: "Jumlah berlangganan (minimum)",
        form20: "Jumlah berlangganan (maksimum)",
        form21: "Kuantitas yang dimiliki",
        form22: "Membeli waktu",
        form23: "waktu penebusan",
        form24: "lebih",
        form25: "Langganan berhasil",
        form26: "Penukaran berhasil",
        form27: "Manajemen keuangan yang mudah dengan pengembalian lebih dari 25%, mengungguli pasar, dan Anda juga dapat menerima imbalan setelah pembelian",
        form28: "Undang teman untuk menerima hadiah 50 USDT, dan voucher kontrak juga dapat mengembalikan komisi 20%."
    },
    assets: {
        content1: "Aset akun",
        content2: "Penghasilan hari ini",
        content3: "akun saat ini",
        content4: "Akun sebenarnya adalah aset akun Anda yang sebenarnya, yang dapat digunakan untuk transaksi dan penarikan.",
        content5: "Tarik koin",
        content6: "Setor koin",
        content7: "menukarkan",
        content8: "transfer",
        content9: "tolong pilih",
        content10: "Akun demo",
        content11: "akun sebenarnya",
        content12: "memperoleh aset",
        content13: "Apakah Anda yakin ingin beralih ke akun nyata?",
        content14: "Apakah Anda yakin ingin beralih ke akun demo?",
        content15: "mendapatkan kesuksesan",
        table1: "Mata uang",
        table2: "kuantitas",
        table3: "membekukan",
        table4: "Tersedia",
        dialog1: "Rincian Produk",
        dialog2: "1、Aset ini hanya diperbolehkan untuk digunakan dalam mode akun demo",
        dialog3: "2、Untuk mencegah penyalahgunaan, hanya satu kali penukaran yang diperbolehkan dalam satu bulan.",
        dialog4: "3、Penerimaan aset akan menambah jumlah aset yang diterima pada dasar aslinya dan tidak akan mengubah status posisi.",
        dialog5: "4、Jika Anda memiliki pertanyaan lain, silakan hubungi layanan pelanggan",
        dialog6: "penutup",
        dialog7: "Dapatkan aset demo sekarang",
        dialog8: "transfer masuk",
        dialog9: "transfer keluar",
        dialog10: "mengalihkan",
        dialog11: "Penilaian",
        dialog12: "Aset demo",
        dialog13: "akun saya",
        dialog14: "Total aset akun",
        dialog15: "Ikhtisar aset",
        dialog16: "Penilaian total aset",
        dialog17: "Penilaian Aset",
        dialog18: "Penghasilan hari ini",
        dialog19: "membekukan dana",
        dialog20: "Anda dapat menerima kuota aset simulasi setiap bulan",
        dialog21: "menerima",
        dialog22: "Anda telah berhasil menerimanya",
        dialog23: 'Pendapatan kumulatif 30 hari',
        dialog24: "Produk keuangan",
        dialog25: "Manajemen keuangan saya",
        dialog26: "Manajemen keuangan historis",
        dialog27: "catatan sejarah",
        dialog28: "Catatan pendanaan"
    },
    validator: {
        dialog14: "validator",
        dialog15: "Ikat validator",
        dialog16: "saklar validator",
        dialog17: "Kode verifikasi",
        dialog18: "Kode QR validator",
        dialog19: "kunci validator",
        dialog20: "menyimpan",
        dialog21: "Silakan pilih validator",
        dialog22: "kunci salinan",
        dialog23: "Berhasil disimpan",
        dialog24: "silakan masukkan kode verifikasi",
        dialog25: "Pengingat ramah: Sebelum mengonfirmasi penyimpanan, silakan buka APLIKASI yang sesuai dan pindai kode QR di bawah atau tambahkan kunci secara manual!",
        dialog26: "Mengaktifkan pengautentikasi Anda memberikan keamanan maksimum untuk akun Anda.",
        dialog27: "Anda harus memasukkan kode verifikasi unik yang diakses melalui ponsel Anda setiap kali Anda masuk atau menarik dana.",
        dialog28: "Siapkan validator",
        dialog29: "membantu",
        dialog30: "Anda belum mengikat kode autentikasi. Dapatkan perlindungan yang lebih kuat dengan pengautentikasi.",
        dialog31: "Validatornya belum disetting, silahkan diset terlebih dahulu",
        dialog32: "Pergi ke pengaturan"
    },
    recharge: {
        title1: "Tidak yakin bagaimana cara membeli menggunakan mata uang digital?",
        title2: "Hanya tiga langkah mudah!",
        title3: "Pilih mata uang digital yang ingin Anda beli dari menu drop-down dan masukkan jumlah atau jumlah pembelian.",
        title4: "Pilih metode pembayaran pilihan Anda",
        title5: "Setelah pembayaran dikonfirmasi, mata uang digital yang Anda beli akan secara otomatis ditransfer ke akun Anda.",
        title6: "Silakan pilih mata uang yang ingin Anda isi ulang",
        title7: "Catatan setoran",
        table1: "Mata uang",
        table2: "kuantitas",
        table3: "Nama rantai",
        table4: "negara",
        table5: "alasan",
        table6: "waktu",
        table7: "Lihat voucher",
        dialog1: "Silakan masukkan kode verifikasi di bawah ini",
        dialog2: "kode pembayaran",
        dialog3: "menyalin",
        dialog4: "alamat",
        dialog5: "Jumlah setoran",
        dialog6: "Tangkapan layar transaksi",
        dialog7: "pemberitahuan Penting",
        dialog8: "Silakan isi jumlah deposit",
        dialog9: "mengunggah gambar",
        dialog10: "Silakan masukkan konten",
        dialog11: "Silakan unggah tangkapan layar isi ulang",
        dialog12: "Tidak ditinjau",
        dialog13: "ditolak",
        dialog14: "Disimpan",
        tip1: "Berhasil dikirimkan",
        tip2: "Berhasil disalin",
        tip3: "Penyalinan gagal",
        tip4: "Silakan pilih mata uang yang ingin Anda isi ulang",
        tip5: "1.Alamat penyetoran di atas adalah alamat pembayaran resmi platform. Silakan cari alamat penyetoran resmi platform. Anda bertanggung jawab atas hilangnya dana yang disebabkan oleh kesalahan penyetoran;",
        tip6: "2.Pastikan untuk mengonfirmasi keamanan komputer dan browser Anda untuk mencegah informasi dirusak atau dibocorkan;",
        tip7: "3.Setelah Anda mengisi ulang alamat di atas, seluruh node jaringan perlu mengonfirmasi sebelum akun dapat dikreditkan;",
        tip8: "4.Silakan pilih sistem token dan mata uang di atas dan transfer jumlah deposit yang sesuai. Harap jangan mentransfer aset lain yang tidak terkait, jika tidak, aset tersebut tidak akan diambil.",
        tip9: "Simpan kode QR",
        tip10: "isi ulang",
        tip11: "pemberitahuan Penting",
        tip12: "Tautan di luar lokasi",
        tip13: "Isi ulang kode QR",
        tip14: "Langkah berikutnya",
        tip15: "Catatan isi ulang",
        tip16: "Detail isi ulang",
        tip17: "Alamat penyetoran",
        tip18: "Pembayaran cepat",
        tip20: "Saldo tidak mencukupi",
        tip22: "Dikirim, menunggu konfirmasi",
        tip23: "menunggu pembayaran",
        tip24: "Jumlah",
        tip25: "Pembayaran sudah terkirim, tinggal menunggu konfirmasi blok",
        tip26: "Pembayaran ditolak",
        tip27: "Pembayaran Gagal",
        tip28: "Pembayaran berhasil",
        bankChannel: "Saluran kartu bank",
        tip29: "Pencarian sejarah",
        tip30: "Daftar mata uang",
        tip31: "Pilih jaringan",
        tip32: "Saat Anda mengisi ulang mata uang ini, harap isi ulang hanya melalui jaringan yang didukung di bawah ini. Jika Anda mengisi ulang melalui jaringan lain, dana akan hilang.",
        tip33: "Saat Anda menarik mata uang ini, harap hanya menarik melalui jaringan yang didukung di bawah ini. Jika Anda menarik melalui jaringan lain, dana akan hilang.",
        tip34: "Isi ulang dengan cepat",
        tip35: "Pindai kode untuk mengisi ulang",
        tip36: "Isi ulang secara manual",
        tip37: "Isi ulang jaringan",

        tip38: "Alamat isi ulang",
        tip39: "Jumlah isi ulang",
        tip40: "Unggah bukti transaksi",
        tip41: "mengunggah gambar",
        tip42: "konfirmasi penyerahan",
        tip43: "konfirmasi pembayaran",
        tip44: "Catatan: Harap konfirmasi alamat yang ingin Anda isi ulang. Kami tidak bertanggung jawab atas hilangnya uang jika Anda salah mengisinya.",
        tip45: "mengingatkan",
        tip46: "Alamat berhasil disalin!",
        tip47: "Penyalinan alamat gagal!",
        tip48: "Saldo yang tersedia tidak mencukupi",
        tip49: "Pengisian ulang berhasil dikirimkan dan menunggu konfirmasi di rantai.",
        tip50: "Gagal mengisi ulang",
        tip51: "Jumlah isi ulang salah",
        tip52: "Koneksi gagal",
        tip53: "Dompet tidak masuk",
        tip54: "Koneksi ditolak",
        tip55: "Berhasil dikirimkan, menunggu peninjauan dan persetujuan layanan pelanggan",
        tip56: "Silakan upload screenshot bukti transaksi",
        tip57: "Pengiriman Gagal",
        tip58: "Catatan isi ulang",
        tip59: "Catatan penarikan",
        tip60: "Isi ulang mata uang",
        tip61: "Mata uang penarikan",
        tip62: "Alamat isi ulang",
        tip63: "Tangkapan layar ditampilkan",
        tip64: "Klik untuk memperbesar gambar",
        tip65: "Waktu isi ulang",
        tip66: "Alasan penolakan",
        tip67: "Jumlah penarikan",
        tip68: "Jumlah yang diterima",
        tip69: "Jaringan penarikan tunai",
        tip70: "Alamat penarikan",
        tip71: "Waktu penarikan",
        tip72: "penutup",
        tip73: "Tangkapan layar transaksi",
        tip74: "Tinjauan tertunda",
        tip75: "ditolak",
        tip76: "lengkap",
        tip77: "ditolak",
        tip78: "Diaudit",
        tip79: "Dicairkan",
        tip80: "ekstrak",
        tip81: "jaringan transfer",
        tip82: "Alamat/Faktur",
        tip83: "Tarik semua",
        tip84: "Harap jangan menarik uang langsung ke alamat crowdfunding atau ICO, jika tidak, Anda tidak akan dapat menerima token yang dikeluarkan oleh crowdfunding atau ICO.",
        tip85: "Kuantitas kedatangan",
        tip86: "Konfirmasikan penarikan",
        tip87: "Alamat penarikan salah",
        tip88: "Jumlah penarikan salah",
        tip89: "Jumlah yang harus ditarik setidaknya",
        tip90: "Jumlah penarikan maksimum adalah",
        tip91: "Permintaan penarikan telah diajukan",
        tip92: "daftar alamat",
        tip93: "Anda belum menambahkan alamat",
        tip94: "Tambahkan sekarang",
        tip95: "Tambahkan alamat",
        tip96: "Tambahkan alamat baru",
        tip97: "tolong pilih",
        tip98: "alamat",
        tip99: "Silakan pilih jaringan transfer",
        tip100: "Silakan masukkan alamat",
        tip101: "Alamat berhasil ditambahkan",
        tip102: "dalam transaksi",
    },
    fundExchange: {
        title1: "Bagaimana cara menukarkan dana?",
        title2: "Pilih mata uang yang akan dikonversi.",
        title3: "Pilih mata uang yang akan dikonversi.",
        title4: "Masukkan jumlah yang akan ditukarkan, lalu klik Kirim untuk menyelesaikan penukaran dana.",
        judul5: "dari",
        judul6: "tiba",
        title7: "Kuantitas penukaran",
        title8: "Nilai tukar",
        judul9 : "Tersedia",
        title10: "Silakan masukkan jumlah penukaran",
        title11: "Tidak ada pasangan perdagangan yang sesuai yang dapat ditukar",
        title12: "Silakan masukkan jumlah yang ingin ditukarkan",
        title13: "Penukaran berhasil",
        title14: "Catatan Pertukaran",
        tabel1: "Gunakan mata uang",
        tabel2: "jumlah",
        tabel3: "Nilai tukar",
        tabel4: "Penukaran mata uang",
        tabel5: "Tiba",
        tabel6: "waktu",
        judul15: "dari",
        title16: "Saat ini tidak ada aset yang tersedia, silakan pilih yang lain"
    },
    fundTransfer: {
        title1: "Bagaimana cara mentransfer dana?",
        title2: "Pilih rekening giro yang perlu ditransfer.",
        title3: "Pilih akun yang ingin Anda transfer.",
        title4: "Masukkan jumlah transfer dan klik Kirim untuk menyelesaikan transfer.",
        title5: "Mata uang",
        title6: "Kuantitas transfer",
        title7: "Silakan masukkan jumlah yang akan ditransfer",
        title8: "catatan perpindahan",
        title9: "Silakan pilih jenis dompet",
        title10: "Silakan pilih mata uang",
        title11: "Silakan pilih jenis dompet",
        title12: "Silakan pilih mata uang",
        title13: "Silakan masukkan jumlah yang akan ditransfer",
        title14: "Berhasil dikirimkan",
        table1: "Transfer keluar rekening",
        table2: "mentransfer mata uang",
        table3: "Kuantitas transfer",
        table4: "rekening transfer",
        table5: "rekening transfer",
        table6: "transfer",
        table7: "Pengiriman dana",
        table8: "Transfer ke",
        table9: "catatan perpindahan",
        table10: "Detail transfer",
        table11: "Transfer berhasil",
        table12: "Transfer gagal",
        table13: "Rekening giro tidak dapat ditransfer"
    },
    withdrawal: {
        title1: "pemberitahuan Penting",
        title2: "1.Untuk mencegah arbitrase, Anda dapat mengajukan penarikan mata uang ketika volume transaksi mencapai kuota.",
        title3: "2.Permohonan penarikan akan dikreditkan dalam waktu 24 jam setelah pengajuan. Jika penarikan tidak diterima setelah perkiraan waktu penarikan, silakan berkonsultasi dengan layanan pelanggan online.",
        title4: "3.Setelah mengajukan permohonan penarikan, dana dalam keadaan beku karena penarikan sedang berlangsung dan dana ditahan sementara oleh sistem, bukan berarti Anda kehilangan aset atau ada kelainan pada aset tersebut.",
        title5: "Mata uang",
        title6: "alamat dompet",
        title7: "Jumlah koin yang ditarik",
        title8: "Catatan penarikan koin",
        title9: "Silakan pilih mata uang",
        title10: "Silakan pilih jenis rantai",
        title101: "",
        title11: "silakan masuk",
        title12: "alamat dompet",
        title13: "Berhasil dikirimkan",
        title14: "sedang ditinjau",
        title15: "Ulasan berhasil",
        title16: "Kegagalan audit",
        title17: "Dicairkan",
        title18: "Silakan masukkan atau tempel alamat dompet Anda",
        title19: "Silakan masukkan jumlah penarikan",
        title20: "Biaya penanganan",
        title21: "Kedatangan sebenarnya",
        table1: "Mata uang",
        table2: "Jumlah koin yang ditarik",
        table3: "Nama rantai",
        table4: "alamat dompet",
        table5: "negara",
        table6: "alasan",
        table7: "waktu aplikasi",
        table8: "Tarik koin",
        table81: "Tarik koin",
        table9: "Alamat penarikan",
        table10: "Silakan pilih mata uang yang ingin Anda tarik",
        table11: "Harap pastikan Anda masuk",
        table12: 'Alamat (biasanya dimulai dengan "0x")',
        table13: "Koin yang dapat ditarik (dikurangi biaya penanganan)：",
        table14: "Pilih Mata Uang",
        table15: "Mengalami masalah? Umpan balik segera",
        table16: "keseimbangan",
        table17: "Detail penarikan",
        password: "kata sandi",
        passwordTips: "Silakan masukkan kata sandi",
        passwordAg: "konfirmasi sandi",
        passwordAgTips: "Silakan masukkan kata sandi lagi",
        oldPassword: "password lama",
        oldPasswordTips: "Silakan masukkan kata sandi lama",
        newPassword: "kata sandi baru",
        newPasswordTips: "Silakan masukkan kata sandi baru",
        passwordNotSame: "Kata sandi tidak konsisten",
        withdrawPasswordSet: "Tetapkan kata sandi penarikan",
        withdrawPasswordModify: "Ubah kata sandi penarikan",
        withdrawPassword: "Tarik kata sandi",
        withdrawPasswordTips: "Silakan masukkan kata sandi penarikan",
        setWithdrawPasswordFirst: "Silakan atur kata sandi penarikan terlebih dahulu",
        withdrawPasswordSetSuccess: "Penyiapan berhasil",
        withdrawPasswordModifySuccess: "Berhasil dimodifikasi",
        submit: "kirim",
    },
    customer: {
        content1: "layanan daring",
        content2: "pelayanan pelanggan",
        content3: "Silakan masukkan informasi kontak Anda",
        content4: "Silakan masukkan konten",
        content5: "mengirim",
        content6: "Pengiriman gagal, koneksi jaringan gagal",
        content7: "gambar",
        content8: "pesan kosong",
        content9: "bagianku",
        content10: "membagikan tautan",
        content11: "Simpan gambar",
        content12: "penghasilan saya",
        content13: "Deskripsi tingkat",
        content14: "Ukuran tim",
        content15: "Pendapatan undangan",
        content16: "Deskripsi aturan",
        content17: "tingkat keanggotaan",
        content18: "Jumlah isi ulang lebih besar dari",
        content19: "Tingkat tim",
        content20: "Tingkat Tim & Pendapatan",
        content21: "Untuk memenuhi persyaratan",
        content22: "Pendapatan distribusi",
        content23: "tim tingkat",
        content24: "tingkat, undangan",
        content25: "anggota yang sah",
        content26: "pendapatan utama",
        content27: "pendapatan sekunder",
        content28: "Pendapatan tingkat 3",
    },
    standardContract: {
        increaseMargin: "Increase margin",
        increaseAmount: "Increase amount",
        Pleaseenterthemargin: "Please enter the margin amount to be increased",
        Pleaseenterthecorrect: "Please enter the correct deposit amount",
        Increasemarginsuccessfully: "Increase margin successfully",
        takeProfitPriceWrong: "Harga Take Profit Salah",
        stoppriceerror: "Hentikan kesalahan harga",
        thetakeprofit1: "Harga take profit harus lebih tinggi dari harga pasar",
        thetakeprofit2: "Harga stop loss harus lebih rendah dari harga pasar",
        thetakeprofit3: "Harga take profit harus lebih rendah dari harga pasar",
        thetakeprofit4: "Harga stop loss harus lebih tinggi dari harga pasar",
        thetakeprofit5: "Jumlah yang salah",
        estimatedprofitloss: "Perkiraan untung dan rugi",
        positionAmount: "Jumlah posisi",
        closeAmount: "Menutup kuantitas",
        whenthelatest: "Ketika harga terbaru tercapai",
        whenthelatest2: ", order take-profit harga pasar akan dipicu untuk menutup posisi saat ini. Keuntungan dan kerugian yang diharapkan",
        whenthelatest3: ", perintah stop loss pasar akan dipicu untuk menutup posisi saat ini. Keuntungan dan kerugian yang diharapkan",
        contract: "kontrak",
        ordercanceledsuccessfully: "Pesanan berhasil dibatalkan",
        successfullyordered: "berhasil dipesan",
        positionopeningreminder: "Pengingat pembukaan posisi",
        wrongquantity: "Jumlah yang salah",
        quantitycannot: "Jumlahnya tidak boleh lebih besar dari",
        modificationstoploss: "Modifikasi stop profit dan stop loss berhasil",
        positionclosedsuccessfully: "Posisi berhasil ditutup",
        orderCancellation: "Pembatalan Pesanan",
        orderCancellation2: "Apakah Anda yakin ingin membatalkan pesanan?",
        tobeexecuted: "Akan dieksekusi",
        expired: "kedaluwarsa",
        failed: "gagal",
        contractTransaction: "Kontrak abadi",
        increase: "Meningkatkan",
        openPosition: "Gudang penuh",
        isolatedPosition: "Posisi terisolasi",
        limitOrder: "batas harga",
        marketOrder: "harga pasar",
        pleaseSelectLeverage: "Silakan pilih leverage",
        pleaseEnterQuantity: "Silakan masukkan kuantitas",
        canOpenMore: "Posisi maksimal yang bisa dibuka",
        contractAmount: "Jumlah kontrak",
        openingFee: "Biaya penanganan",
        currentPosition: "Posisi",
        historicalPositions: "Transaksi historis",
        currentCommission: "Pesanan saat ini",
        historicalCommission: "komisi sejarah",
        buyPrice: "harga pembelian",
        income: "laba",
        quantity: "kuantitas",
        marginRate: "tingkat margin",
        initialMargin: "Margin awal",
        estimatedPriceParity: "Harga likuidasi paksa",
        operate: "beroperasi",
        finishQuantity: "Volume transaksi",
        averagePrice: "harga transaksi rata-rata",
        pleaseEnterLeverage: "Silakan masukkan leverage",
        takeProfitAndStopLoss: "TP&SL",
        takeProfit: "Mengambil keuntungan",
        stopLoss: "menghentikan kerugian",
        openLong: "Long",
        openShort: "Short",
        liquidation: "Tutup posisi",
        marketLquidation: "Penutupan Pasar",
        cancel: "Batalkan pesanan",
        enterBuyPrice: "Silakan masukkan harga beli",
        areYouSureLquidation: "Apakah Anda yakin akan menutup posisi?",
        confirm: "Tentu",
        enterStopWinPrice: "Silakan masukkan harga take profit",
        enterStopLossPrice: "Silakan masukkan harga stop loss",
        commissionTime: "Waktu komisi",
        commissionPrice: "Harga komisi",
        commissionQuantity: "Jumlah total komisi",
        volumeAlreadyTraded: "Jumlah selesai",
        areYouSureToWinOrLoss: "Apakah Anda yakin ingin menghentikan keuntungan dan menghentikan kerugian?",
        maxQuantity: "Posisi maksimal",
        saleQuantity: "Posisi ditutup",
        salePrice: "harga penutupan rata-rata",
        saleTime: "waktu",
        succeeded: "berhasil",
        cancelled: "Dibatalkan",
        piece: "membuka",
        triggerPrice: "harga pemicu",
        setWin: "Tetapkan ambil untung",
        setLoss: "Tetapkan penghentian kerugian",
        enterWinQuantity: "Silakan masukkan jumlah take profit",
        enterLossQuantity: "Silakan masukkan jumlah stop loss",
        planCommission: "Komisi rencana",
        enterWinTriggerPrice: "Silakan masukkan harga pemicu take profit",
        enterLossTriggerPrice: "Silakan masukkan harga pemicu stop loss",
        insufficientQuantity: "Kuantitas tidak mencukupi",
        enterTriggerPrice: "Silakan masukkan harga pemicu",
        tips1: "Harga order Anda lebih tinggi dari harga saat ini, dan akan langsung dieksekusi pada harga pasar saat ini. Apakah Anda yakin ingin membeli?",
        tips2: "Harga order Anda lebih rendah dari harga saat ini, dan akan langsung dieksekusi pada harga pasar saat ini. Apakah Anda yakin untuk membeli?",
        type: "jenis",
        increaseQuantity: "Tingkatkan posisi",
        reduceQuantity: "Kurangi posisi",
        limitCommission: "membatasi pesanan",
        tips3: "Harga stop loss harus lebih rendah dari harga pasar",
        tips4: "Harga take profit harus lebih tinggi dari harga pasar",
        tips5: "Harga stop loss harus lebih tinggi dari harga pasar",
        tips6: "Harga take profit harus lebih rendah dari harga pasar",
        tips7: "Harga stop loss harus lebih rendah dari harga pasar dan lebih rendah dari harga pesanan",
        tips8: "Harga take profit sebaiknya lebih besar dari harga order (bisa lebih kecil dari harga pasar)",
        tips9: "Harga stop loss harus lebih besar dari harga pasar dan lebih besar dari harga pesanan",
        tips10: "Harga take profit sebaiknya lebih kecil dari harga order (bisa lebih besar dari harga pasar)",
        accountEquity: "Saldo rekening",
        unrealizedPL: "laba atau rugi yang belum direalisasi",
        tips11: "Apakah Anda yakin Anda benar",
        tips12: "Harga pasar",
        tips13: "Tutup semua posisi",
        tips14: "Jangan ingatkan lagi",
        profitRate: "Tingkat pengembalian penutupan",
        openTime: "Waktu pembukaan",
        unwindTime: "Waktu tutup",
        dealTime: "Waktu transaksi",
        amountClose: "Jumlah posisi tertutup",
        long: "banyak",
        short: 'batal',
        orderQuantity: "Jumlah pesanan",
        entrustedMargin: "margin yang dipercayakan",
    },
    spotGoods: {
        spotGoods: "Pertukaran kilat",
        sellPrice: "harga penjualan",
        orderRecord: "Catatan penukaran flash",
        transactionTime: "Waktu transaksi",
        maxBuy: "tersedia untuk pembelian",
        maxSell: "Tersedia untuk dijual",
        purchaseQuantity: "Kuantitas pembelian",
        slippage: "Kelicinan",
        convertTips: "Kami akan menutup transaksi pada harga paling menguntungkan dengan slippage 0-5%.",
        changePrice: "Harga tukar saat ini",
        consume: "mengkonsumsi",
        get: "mendapatkan",
        changeTips: "Apakah Anda yakin ingin menebusnya?",
        confirm: "Konfirmasi penukaran"
    },
    news: {
        title: "Pusat Berita",
        redMore: "Baca selengkapnya",
    },
    credit: {
        credit: "nilai kredit",
        creditScore: "total skor kredit",
        latestEntry: "rekor terbaru",
        liveUpdate: "Pembaruan Langsung",
        systemEvaluation: "Evaluasi sistem",
        time: "tanggal",
        name: "nama",
        reason: "alasan",
        scoreStory: "Catatan skor kredit"
    },
    borrowing: {
        borrowing: "meminjamkan",
        diversifiedMarket: "Pasar yang terdiversifikasi",
        totalSupply: "Pasokan total",
        totalBorrowings: "total pinjaman",
        tips1: "Ini adalah jumlah total yang dapat Anda pinjam dengan jaminan Anda, hingga batas pinjaman.",
        tips2: "Suku bunga stabil Anda akan tetap sama selama masa pinjaman Anda. Direkomendasikan untuk pasokan jangka panjang dan pengguna yang menyukai prediktabilitas.",
        totalSupplys: "pasokan total",
        supplyAPR: "tingkat pasokan",
        totalBorrowings: "Total pinjaman",
        borrowAPR: "tingkat pinjaman",
        check: "Memeriksa",
        market: "pasar",
        availableLiquidity: "Likuiditas yang tersedia",
        currencyPrice: "harga mata uang saat ini",
        supplyAssets: "aset pasokan",
        tips3: "Catatan: Suku bunga di bawah ini adalah suku bunga tetap, setelah masa penawaran selesai, pokok ditambah penghasilan akan dikembalikan ke rekening Anda.",
        supplyCycle: "siklus pasokan",
        minimumSupply: "pasokan minimum",
        supply: "memasok",
        borrowedAssets: "aset yang dipinjam",
        tips4: "Penting untuk diketahui: Untuk meminjam uang, Anda perlu memberikan aset atau bukti jaminan apa pun,",
        tips5: "Silakan lihat deskripsi aset hipotek.",
        repaymentCycle: "siklus pembayaran",
        BorrowableAmount: "Jumlah pinjaman yang tersedia",
        borrow: "meminjamkan",
        accountInformation: "Informasi Akun",
        walletBalance: "saldo dompet",
        canSupply: "Tersedia",
        AmountCanBeBorrowed: "Total jumlah pinjaman yang tersedia",
        borrowedAmount: "Jumlah pinjaman",
        remainingAmount: "Saldo yang tersisa",
        getBorrowednAmount: "Dapatkan batas pinjaman",
        supplyOrder: "pesanan pasokan",
        borrowedOrder: "pesanan pinjaman",
        supplyAmount: "jumlah pasokan",
        expectedProfits: "Pengembalian yang diharapkan",
        supplyTime: "Waktu pasokan",
        expirationTime: "Tanggal kadaluarsa",
        borrowingAmount: "Jumlah pinjaman",
        dueRepayment: "Jumlah Pelunasan",
        borrowingTime: "Jumlah Pelunasan",
        repaymentTime: "Waktu pembayaran",
        repay: "pembayaran kembali",
        supplyCurrency: "pasokan mata uang",
        buyNow: "Beli sekarang",
        supplyRecords: "catatan pasokan",
        borrowRecords: "Catatan pinjaman",
        completed: "lengkap",
        borrowNow: "Pinjam sekarang",
        borrowCurrency: "Meminjam mata uang",
        borrowMin: "terkecil",
        borrowMax: "maksimum",
        borrowRange: "Jumlah pinjaman (minimum/maksimum)",
        tips6: "Mohon dilunasi tepat waktu, jika terlambat akan dikenakan tambahan jumlah harian (pokok + bunga).",
        tips7: "Biaya jatuh tempo.",
        pleaseEnterBorrowAmount: "Silakan masukkan jumlah pinjaman",
        interest: "Minat",
        tips8: "Apakah Anda yakin ingin membayar kembali di muka?",
        borrowChannel: "Saluran peminjaman",
        tips9: "Mari kita mulai dengan mengenal Anda",
        name: "nama",
        lastName: "nama belakang",
        birthDate: "tanggal lahir",
        tips10: "Sedikit informasi lebih lanjut, di mana alamat Anda?",
        familyAddress: "Alamat rumah (termasuk nomor unit)",
        city: "Kota",
        country: "bangsa",
        postalCode: "kode Pos",
        pleaseEnterYourHomeAddress: "Silakan masukkan alamat rumah Anda",
        tips11: "Beritahu kami tentang situasi keuangan Anda",
        tips12: "Kami mungkin perlu mengonfirmasi informasi ini nanti, jadi harap berikan seakurat mungkin",
        yourMonthlyIncome: "penghasilan bulanan Anda",
        enterYourMonthlyIncome: "Silakan masukkan penghasilan bulanan Anda",
        extraIncomeOptional: "Penghasilan tambahan (opsional)",
        enterExtraIncome: "Silakan masukkan penghasilan tambahan Anda",
        monthlyRentOrMortgagePayment: "Sewa bulanan atau pembayaran hipotek",
        enterMonthlyRent: "Silakan masukkan pembayaran sewa atau hipotek bulanan Anda",
        tip13: "Beritahu kami jika Anda memiliki hipotek",
        tip14: "Jika tidak ada jaminan hipotek yang dipilih, kemungkinan lolos peninjauan rendah.",
        isThereMortgage: "Apakah ada hipotek?",
        selectIsThereMortgage: "Silakan pilih apakah ada hipotek",
        selectMortgageType: "Silakan pilih jenis hipotek",
        yes: "Ya",
        no: "TIDAK",
        uploadScreenshotOfTransaction: "Silakan upload screenshot transaksi",
        tip15: "Mari kita tetap berhubungan",
        tip16: "Kami dapat menghubungi Anda di nomor ini jika kami memerlukan informasi lebih lanjut mengenai pengajuan pinjaman Anda",
        tip17: "Saya telah membaca dan menandatangani persetujuan saya secara elektronik",
        tip18: "Ketentuan Penggunaan dan Persetujuan Elektronik",
        tip19: ", yang mencakup persetujuan Anda agar kami mengirimkan informasi tentang kami kepada Anda",
        tip20: "Ketentuan Penggunaan Produk dan Layanan",
        tip21: "Setelah mengisi informasi, harap menunggu evaluasi dan peninjauan sistem.",
        phoneNumber: "nomor telepon",
        enterPhoneNumber: "Masukkan nomor telepon anda",
        tip22: "Silakan centang kotak untuk menyetujui ketentuan penggunaan dan formulir persetujuan elektronik",
        inReview: "sedang ditinjau",
        applicationAmount: "Jumlah aplikasi",
        reasonForRejection: "Alasan penolakan",
        reapply: "Melamar lagi",
        applyStatus: "status aplikasi",
        currentOrder: "pesanan saat ini",
        interestRate: "suku bunga"
    },
    yuebao: {
        filed1: "menginvestasikan",
        filed2: "mengambil",
        filed3: "Pendapatan harian",
        filed4: "akselerasi tim",
        filed5: "Disimpan",
        filed6: "Penghasilan kemarin",
        filed7: "Total pendapatan",
        filed8: "Dana yang tersedia",
        filed9: "Silakan masukkan jumlahnya",
        filed10: "Penambangan likuiditas",
        filed11: "Perkenalan",
        field12: "Harga minimal pembelian",
        field13: "Yang paling bisa Anda beli",
        filed14: "Berlangganan",
        filed15: 'reguler',
        filed16: 'tingkat bunga tahunan',
    },
    tipsPopover: {
        title: "Instal aplikasi ini",
        press: "berdasarkan",
        and: "Dan",
        scrren: "Tambahkan ke layar beranda",
        hasInstall: "(Jika sudah terpasang, silakan tutup jendelanya)",
    },
    follow: {
        title1: "Rencana tindak lanjut",
        follow_text1: "Memulai perintah tindak lanjut",
        follow_text2: "Riwayat rilis",
        follow_text3: "judul",
        follow_text4: 'pasangan perdagangan',
        follow_text5: 'Durasi pembelian',
        follow_text6: 'waktu pemesanan',
        follow_text7: 'waktu rilis',
        follow_text8: 'Jumlah pengikut',
        follow_text9: 'Jumlah pesanan',
        follow_text10: 'beroperasi',
        follow_text11: 'mengundang',
        follow_text12: 'Ikuti arah pesanan',
        follow_text13: 'Tetapkan kondisi pesanan',
        follow_text14: 'Ketentuan untuk mengikuti perintah',
        follow_text15: 'jumlah tetap',
        follow_text16: 'persentase',
        follow_text17: 'Orang yang diundang',
        follow_text18: 'ID pihak lain',
        follow_text19: "Konfirmasi untuk mengikuti pesanan",
        follow_text20: "Jumlah pesanan Anda (opsional)",
        follow_text21: "Silakan masukkan judul",
        follow_text22: "Silakan pilih pasangan perdagangan",
        follow_text23: "Silakan pilih arah pemesanan",
        follow_text24: "Silakan pilih durasi pembelian",
        follow_text25: "Silakan pilih waktu pemesanan",
        follow_text26: "Silakan pilih jumlah pembelian",
        follow_text27: "Jumlah pembelian maksimum tidak boleh kurang dari jumlah pembelian minimum",
        follow_text28: "Pembelian minimum",
        follow_text29: "Kebanyakan membeli",
        follow_text30: "Berhasil membuat pesanan penyalinan",
        follow_text31: "Daftar pasangan perdagangan",
        follow_text32: "Sudah mengikuti pesanan",
        follow_text33: "Silakan masukkan informasi orang yang diundang",
        follow_text34: "Diundang",
        follow_text35: "mengundang saya",
        follow_text36: "Menyalin sejarah",
        follow_text37: "Kisaran jumlah pesanan"
    },
    followHistory: {
        title1: 'Riwayat rilis',
        title2: "Menyalin sejarah",
        follow_h_text1: "Jumlah total pesanan",
        follow_h_text2: "Jumlah total orang yang mengikuti perintah",
        follow_h_text3: "Jumlah rilis",
        follow_h_text4: "tingkat kemenangan",
        follow_h_text5: "Berapa kali untuk mengikuti perintah",
        follow_h_text6: "Untung dari mengikuti pesanan",
        follow_h_text7: "arah",
        follow_h_text8: "menang",
        follow_h_text9: "defisit",
    },
    "asset.index.1579E8B41F8D5134": "aset saya",
    "asset.index.B3F6F05Dfbe431Cd": "simulasi",
    "asset.index.B9Aeab4Ee8F33746": "Penilaian Aset",
    "asset.index.583Df8Bd541Eb7E8": "tren pendapatan",
    "asset.index.B1254815D133Cc26": "7 hari",
    "asset.index.2F615F6C029F01B7": "30 hari",
    "asset.index.29439Fb58E4Bbe22": "menarik",
    "asset.index.831Ab31568A78285": "isi ulang",
    "asset.index.39F7A0D48D12E8C9": "transfer",
    "asset.index.B99063C5689Beaec": "Pertukaran kilat",
    "asset.index.E1Dc620F2F038B2A": "Menerima aset",
    "asset.index.972A0B4C2B4393D0": "akun saya",
    "asset.index.9Dfb0Fb334620733": "Saldo Tersedia",
    "asset.index.Cdc5Fbd26790Cb47": "jumlah beku",
    "asset.index.3D8132D72Ae965Cd": "Penilaian",
    "asset.receive.825503Cc430559Cb": "memperoleh aset",
    "asset.receive.54154A0553A535B7": "Aset demo",
    "asset.receive.F2E57B00A9C5D129": "1.Aset ini hanya diperbolehkan untuk digunakan dalam mode akun demo",
    "asset.receive.Ea0D1D52989299Bd": "2.Untuk mencegah penyalahgunaan, hanya satu kali penukaran yang diperbolehkan dalam satu bulan.",
    "asset.receive.090E0037Aa235D3D": "3.Penerimaan aset akan menambah jumlah aset yang diterima pada dasar aslinya dan tidak akan mengubah status posisi.",
    "asset.receive.7Fd4E1Abc805C0A6": "4.Jika Anda memiliki pertanyaan lain, silakan hubungi layanan pelanggan",
    "asset.receive.Df5B3A511D986575": "Dapatkan aset demo sekarang",
    "asset.records.1Fa02E8187A39592": "tiga bulan terakhir",
    "asset.records.7B291C37333Fe34D": "dari dekat ke jauh",
    "asset.records.A476Cd1Ec868Fc5F": "tiga bulan terakhir,",
    "asset.records.9D5755A842E55Bb7": "dari dekat ke jauh,",
    "asset.records.E3Fa05E607203Cdc": "isi ulang",
    "asset.records.B4F206C6Bec08Dea": "menarik",
    "asset.index.Df5B3A511D986576": "Anda telah berhasil menerima aset demo",
    "deposit.depositSpeedy.Efe81D0E0174239B": "Cara Pembayaran",
    "deposit.depositSpeedy.B9293Ee96E435F85": "Mata uang fiat",
    "deposit.depositSpeedy.16658A855F07D171": "Jumlah",
    "deposit.depositSpeedy.204Ffab8A6E01870": "Tentu",
    "deposit.depositSpeedy.22E5Dfb74Ead8Aa9": "Tidak boleh kosong",
    "deposit.depositSpeedy.B8E774D0E4E5E4C1": "Isi ulang minimal",
    "deposit.depositSpeedy.F169D816F6Dd83C5": "Isi ulang maksimum",
    "deposit.depositWallet.F64Ec60B1Db4A6D6": "Setor USDT",
    "deposit.depositWallet.551640C76F4Ca4A1": "Isi ulang mata uang",
    "deposit.depositWallet.B404C543712B71D5": "Isi ulang jaringan",
    "deposit.depositWallet.A484B0F5E94B3F4F": "Alamat penyetoran",
    "deposit.depositWallet.D3980D54Acf242Db": "Simpan kode QR",
    "deposit.depositWallet.A06E84801D6582Be": "Jumlah isi ulang",
    "deposit.depositWallet.Daaaeb1B7B22B126": "petunjuk",
    "deposit.depositWallet.D599E40E1Eeec1B3": "Isi ulang berhasil",
    "deposit.depositWallet.4F48Abbafce532Bb": "Berhasil dikirim, menunggu ulasan layanan pelanggan",
    "deposit.depositWallet.A88C481228811696": "Anda menolak untuk berpindah jaringan",
    "deposit.depositWallet.C6Ebf27895C21932": "Silakan buka plug-in dompet TRON secara manual dan masuk",
    "deposit.index.B5491C8Dd09D8183": "Pilih mata uang yang ingin Anda isi ulang",
    "deposit.index.2F784B2298D39987": "Isi ulang dengan cepat",
    "deposit.index.0Daa03Dcdcabd837": "Tautan di luar lokasi",
    "withdraw.bottomSheet.628A63056Ef2D2Fe": "Konfirmasi penarikan",
    "withdraw.bottomSheet.A086Fa20082C849D": "Pastikan informasi di bawah ini benar",
    "withdraw.bottomSheet.Bf8B184D32971501": "Jaringan penarikan",
    "withdraw.bottomSheet.8758Fd50C87D6C9C": "alamat",
    "withdraw.bottomSheet.F9Db93B87E08763B": "kuantitas",
    "withdraw.bottomSheet.4Ad26475002878C0": "Biaya penanganan",
    "withdraw.index.A96454041169Af6D": "Pilih Mata Uang",
    "withdraw.index.2A563B7081Dd5B83": "Alamat penarikan",
    "withdraw.index.B189C2Ef713Cfff4": "Jumlah koin yang ditarik",
    "withdraw.index.558F35140E84B562": "Jumlah koin yang ditarik：",
    "withdraw.index.F4E759087E12Cb77": "Tarik semua",
    "withdraw.index.5Cbd9A6B284D25Ae": "Kata sandi dana",
    "withdraw.index.B89Cbe4Bfc33Bb41": "Biaya penanganan:",
    "withdraw.index.C886De8D8A27026E": "pemberitahuan Penting",
    "withdraw.index.Eac7C5A4095F5606": "1.Untuk mencegah arbitrase, Anda dapat mengajukan penarikan mata uang ketika volume transaksi mencapai kuota.",
    "withdraw.index.C3Cc7Fff24504Be9": "2.Permohonan penarikan akan dikreditkan dalam waktu 24 jam setelah pengajuan. Jika penarikan tidak diterima setelah perkiraan waktu penarikan, silakan berkonsultasi dengan layanan pelanggan online.",
    "withdraw.index.0B4Ad7Ccdc833B0D": "3.Setelah mengajukan permohonan penarikan, dana dalam keadaan beku karena penarikan sedang berlangsung dan dana ditahan sementara oleh sistem, bukan berarti Anda kehilangan aset atau ada kelainan pada aset tersebut.",
    "withdraw.index.4639F3A6E07C00B3": "Langkah berikutnya",
    "withdraw.index.81Fba0D65Cc5C3Aa": "Silakan masukkan atau tempel alamat dompet Anda",
    "withdraw.index.25768141C8Bdea91": "Silakan masukkan jumlah penarikan",
    "withdraw.index.97A85734Cd1B7Bf0": "Silakan masukkan kata sandi dana",
    "withdraw.index.39C7D74B33241C78": "Jumlah penarikan salah",
    "withdraw.index.86A5D17908197525": "Konfirmasi penarikan, menunggu review untuk mengeluarkan koin)",
    "withdraw.index.48Fb603C6638C7F6": "Silakan atur kata sandi dana",
    "withdraw.index.873Ade9A3Bacc2F7": "petunjuk",
    "bill.index.5Bbb2A6Ac68D6B2E": "akun sebenarnya",
    "bill.index.75751E0Eb8261C40": "Akun demo",
    "bill.index.Cbc853B37Ecc2E97": "Jenis perubahan akun",
    "bill.index.4A0Cacddeb2E38B1": "Jenis akun",
    "bill.index.F16217028E098B2F": "Mata uang",
    "bill.index.A620Dbe6F06B78Ba": "Semua jenis",
    "bill.index.2C3C148Bb94D9B3F": "Semua akun",
    "c2C.index.Ab45695E4Bf1E382": "transaksi C2C",
    "c2C.index.2702Dc911Dfea762": "saya ingin membeli",
    "c2C.index.D737D5B8367A53C4": "saya ingin menjual",
    "c2C.index.C0C540A3Ba9Abe01": "Volume",
    "c2C.index.D8644E8D60C8Bd8A": "harga satuan",
    "c2C.index.Ebb8670B203D3604": "membatasi",
    "c2C.index.F8C6245A2Ebf1D27": "Semua kartu bank",
    "c2C.index.F8C6245A2Ebf1D28": "pengumpulan mata uang",
    "c2C.index.F8C6245A2Ebf1D29": "kartu bank",
    "c2C.index.Cc3Aa8141Da9Ec9E": "Membeli",
    "c2C.index.Cc3Aa8141Da9Ec9F": "menjual",
    "c2C.index.67A39834582Ad99A": "Tidak ada data",
    "c2C.order.04C23810B47F77F7": "berdasarkan jumlah",
    "c2C.order.6905365902E0A73D": "berdasarkan kuantitas",
    "c2C.order.3F0Ecebbd9D2F23B": "Tersedia",
    "c2C.order.16853Bda54120Bf1": "semua",
    "c2C.order.Ad3335796F2C95F7": "jumlah bulat",
    "c2C.order.61092E27A6879318": "Untuk melindungi keamanan aset, harap tingkatkan kesadaran Anda akan pencegahan!",
    "c2C.order.0F51881F0Fb920B2": "Informasi Perdagangan",
    "c2C.order.Dd74Ceca85E5Bc65": "Batas waktu pembayaran",
    "c2C.order.De3341388A59073F": "30 menit",
    "c2C.order.D334B597A352167F": "Nama panggilan penjual",
    "c2C.order.D334B597A352167G": "Nama panggilan pembeli",
    "c2C.order.9D5F89F99Ced890D": "sarana transaksi",
    "c2C.order.8Adf755B14Bc6B7C": "Silakan baca terlebih dahulu berikut ini",
    "c2C.order.686B16F454A7338B": "Jangan berkomentar apa pun saat melakukan transfer kartu bank, jika tidak uang tidak akan dikeluarkan dan rekening akan langsung diblokir. Setelah pembayaran, Anda perlu memberikan diagram detail transaksi baru setelah pembayaran (jika diagram P atau detail transaksi tersembunyi dilaporkan ke platform untuk membekukan akun)",
    "c2C.order.9Bec8Fba867B739B": "Pilih metode pembayaran",
    "c2C.order.Cf2D892C419Fea36": "pembelian minimal",
    "c2C.order.7290A6B7456A03E0": "pembelian terbesar",
    "c2C.orderConfirm.F7E99B8C203E2B07": "Detail pesanan",
    "c2C.orderConfirm.036D94Dc2D1Cf7D3": "Pesanan telah dibuat",
    "c2C.orderConfirm.0F5433503F3845Ce": "Silakan membayar penjual dalam waktu hitung mundur.",
    "c2C.orderConfirm.24C3Bcacdb85A451": " Menunggu pembayaran pembeli ",
    "c2C.orderConfirm.8E8140D7Ab607B24": "hubungi penjual",
    "c2C.orderConfirm.8E8140D7Ab607B25": "hubungi pembeli",
    "c2C.orderConfirm.Ec4D8722Ec15C813": "Mata uang digital penjual sekarang disimpan di rekening escrow, silakan membayar  ",
    "c2C.orderConfirm.69D1Fbbc7700C1Ab": "Nama asli pembeli：",
    "c2C.orderConfirm.E5Ed8Ec53Edcd5D3": "Dukungan layanan pelanggan 7x24 jam ",
    "c2C.orderConfirm.Abf9F4B8C93D641E": "jumlah order",
    "c2C.orderConfirm.726C51702F70C486": "Waktu penciptaan",
    "c2C.orderConfirm.E097Bb9B1216Bfd8": "Hubungi Layanan Pelanggan",
    "c2C.orderConfirm.103408Fbbe30287F": "Batalkan pesanan",
    "c2C.orderConfirm.4E93Df1Fb521F4Ba": "Saya telah mengkonfirmasi pembayaran",
    "c2C.orderConfirm.3B5418Dbea7Ebfe4": "Sudah dibayar",
    "c2C.orderConfirm.387Ca3625B260E4E": "Pesanan berbayar Anda",
    "c2C.orderConfirm.7C83D5F1Db1A08B7": "membatalkan transaksi",
    "c2C.orderConfirm.76C86566Cc3751C0": "Pesanan dibatalkan",
    "c2C.orderConfirm.205B349751D176B4": "pembayaran berhasil",
    "c2C.paymentAdd.6F2A7Dcc66E6Acba": "Cara Pembayaran",
    "c2C.paymentAdd.C87E865Df6800304": "Konfigurasi metode pembayaran",
    "c2C.paymentAdd.B59Ae0F1B1C235Df": "Jenis metode pembayaran",
    "c2C.paymentAdd.06910Bddf002695F": "nama sebenarnya",
    "c2C.paymentAdd.3E33Ae3Efa7507Dd": "Akun paypal",
    "c2C.paymentAdd.3E33Ae3Efa7507De": "Nomor rekening bank/nomor kartu",
    "c2C.paymentAdd.3E33Ae3Efa7507Df": "Nama mata uang",
    "c2C.paymentAdd.595Ede91Abf6F6E0": "Bayar kode QR",
    "c2C.paymentAdd.1Ecc8Be9Ba1C940A": "Klik untuk mengunggah",
    "c2C.paymentAdd.6Daae340F2E9718A": "nama Bank",
    "c2C.paymentAdd.347751415C3Da489": "Nama rantai",
    "c2C.paymentAdd.15B3627Faddccb1D": "Komentar",
    "c2C.paymentAdd.86Da96Cbd274058F": "Pengingat hangat: Saat Anda menjual mata uang digital, metode pembayaran yang Anda pilih akan ditampilkan kepada pembeli. Harap konfirmasi bahwa informasi diisi secara akurat.",
    "c2C.paymentAdd.4Ce9979Bfb6576D9": "menyimpan",
    "c2C.paymentAdd.90D2A3A5C07179C0": "silakan masukkan nama asli Anda",
    "c2C.paymentAdd.A3B0Cd3Ad9Ca3475": "Silakan masukkan milik Anda",
    "c2C.paymentAdd.Ec389E6190Eedb62": "Silakan masukkan nama bank Anda",
    "c2C.paymentAdd.9Be51Fb1E9025Fb2": "Silakan masukkan nama rantai Anda",
    "c2C.paymentAdd.94F034F0E01439E0": "Silakan masukkan alamat Anda",
    "c2C.paymentAdd.B12E035663A42B32": "Semua kartu bank",
    "c2C.paymentAdd.C7B677F393A8F8Ac": "pengumpulan mata uang",
    "c2C.paymentAdd.Abafe11737Bab7Cd": "Silakan unggah kode pembayaran",
    "c2C.paymentAdd.07C443212Ff04Bfb": "Berhasil ditambahkan",
    "c2C.paymentDetail.86Da96Cbd274058F": "Pengingat hangat: Saat Anda menjual mata uang digital, metode pembayaran yang Anda pilih akan ditampilkan kepada pembeli. Harap konfirmasi bahwa informasi diisi secara akurat.",
    "c2C.paymentDetail.C4C613Da60226De4": "Berhasil dimodifikasi",
    "c2C.paymentMethod.7609F7C8337C7A17": "Metode pembayaran C2C",
    "c2C.records.9E36893D5736Abb4": "Daftar Pesanan",
    "c2C.records.4B53F9A5B13B3235": "Pembayaran tertunda",
    "c2C.records.4B53F9A5B13B3236": "membatalkan transaksi",
    "c2C.records.4B53F9A5B13B3237": "Sudah dibayar",
    "contract.index.9706Df877458F0Be": "Gudang penuh",
    "contract.index.623Fc7D3D40Aa479": "Margin awal",
    "contract.index.F92Dce1169330387": "Penutupan Pasar",
    "contract.index.3598B97Dd28Dbf20": "Silakan masukkan jumlah (potongan)",
    "contract.index.6E1Fae821Dc0B797": "Berkelanjutan",
    "contract.index.Eb07Eb0C37C14575": "Pengiriman",
    "contract.index.Eb6492F19B1C1Fb6": "Detail transaksi historis",
    "contract.index.873Ade9A3Bacc2F7": "petunjuk",
    "contract.index.Ff63B0F93D39F50B": "Apakah Anda yakin Anda benar",
    "contract.index.Ff63B0F93D39F50D": "Bersikaplah lama",
    "contract.index.Ff63B0F93D39F50E": "pendek",
    "contract.index.Ff63B0F93D39F50F": "mengadakan",
    "contract.index.Ff63B0F93D39F50G": "Tutup semua posisi?",
    "customer.dialog.3D410C88E8A24Fe8": "Konfirmasikan nama panggilan",
    "customer.dialog.4B3340F7872B411F": "Membatalkan",
    "customer.dialog.549B1456Bb5F98B3": "Silakan masukkan nama panggilan Anda",
    "customer.dialog.B773B3E45Ccfad00": "Nama panggilan kosong",
    "customer.index.7308C6B849D159E9": "Detail gambar",
    "delivery.callDialog.C74F562C3Fe91Fe3": "berhasil dipesan",
    "delivery.callDialog.B8C98F42Caa15Ca5": "arah",
    "delivery.callDialog.B79A581Dae543974": "pembelian panjang",
    "delivery.callDialog.B79A581Dae543975": "pembelian pendek",
    "delivery.callDialog.3B26F0F7E90773F3": "Harga pembukaan",
    "delivery.callDialog.65Fb252Bd94E1039": "Perkiraan pendapatan",
    "delivery.index.B483Ba4Fce633539": "Pengiriman",
    "delivery.index.224Dd6Cb58D58249": "periode perdagangan",
    "delivery.index.28158F517E902788": "Pembelian minimum",
    "delivery.index.A1232162B14Bec76": "Pesan antar",
    "delivery.index.445B1D3176D9C69D": "Perintah sejarah",
    "delivery.index.Ab6Cb86E28653C5C": "Pengiriman",
    "delivery.index.20A7A6F14D3698C0": "Waktu pembukaan",
    "delivery.index.Ec6821A49395Ddc2": "Waktu tutup",
    "delivery.index.254Fdc665C391743": "Harga penutup",
    "delivery.index.2A0C5E68B44A472A": "Laba rugi",
    "delivery.index.E05B33309F6D236A": "Silakan masukkan kuantitas",
    "delivery.index.B2899E073Cb355D9": "Minimal pembelian",
    "download.description.869467A93C7Dd4A1": "catatan instalasi",
    "download.description.C80C1Edd6Bee12Bc": "1.Izinkan file deskripsi diunduh",
    "download.description.2F5Bc180F92642B4": "2.File deskripsi diunduh",
    "download.description.3C3B849F6Ccf712F": "3.Masuk ke [Pengaturan] di ponsel Anda dan file deskripsi yang diunduh akan ditampilkan di bawah informasi akun.",
    "download.description.86Affd862985E045": "4.Klik untuk mengunduh file deskripsi",
    "download.description.32832492Bfc1D95E": "5.Klik untuk menginstal",
    "download.index.245883C80F181C4A": "unduh",
    "download.index.Ef3De8Cb2722937A": "Mulai berdagang kapan saja, di mana saja",
    "download.index.F4A87Eaeed691638": "Tetap up to date dengan berita terbaru melalui APP kami",
    "download.index.Ad3Aba52D0D39D01": "Unduh paket instalasi",
    "download.safariGuide.61Bd9A8Fcc2Ea788": "Tip yang baik",
    "download.safariGuide.2Cc68Aaadf623477": "Silakan kunjungi di Safari",
    "download.safariGuide.C80Fff2A6Ff4E768": "Anda mengunjungi situs web terdesentralisasi, silakan jalankan di aplikasi safari",
    "download.safariGuide.06Da016F3E2F79Bd": "Salin tautan",
    "finance.index.B034B367545D5236": "Proyek global berkualitas tinggi",
    "finance.index.6Ebfac2C0A9228B0": "1,6 juta+ pengguna aktif, $122,4 miliar",
    "finance.index.52Bdcaf19659363D": "Penambangan likuiditas",
    "finance.index.D66924272B031Bf5": "Pendapatan tahunan yang sangat tinggi",
    "finance.index.34789D88Afcefa64": "Lihat item",
    "finance.index.E12676B95654D21E": "pinjaman kredit",
    "finance.index.4C924Cfd89A11Ac7": "Temukan lebih banyak peluang perdagangan",
    "finance.index.C251D4B594Da2848": "kuantifikasi AI",
    "finance.index.4E53Cf2E5F173Dd3": "Penyewaan sekali klik, transaksi cerdas AI",
    "finance.records.Cce168301447D1Ba": "sedang berlangsung",
    "finance.records.4587B6Bd7Cfa4454": "ditebus",
    "finance.records.18685418E3A50147": "Pertambangan",
    "finance.records.Df9Fd438F97Cc908": "langit",
    "finance.records.E23Cb03C5Ad359E5": "syarat",
    "finance.records.Bd87Dac6Cba7Eba8": "Kuantitas pembelian",
    "finance.records.2D0Fff99265E8B1A": "Menguntungkan",
    "finance.records.2D0Fff99265E8B1B": "Keuntungan pada saat jatuh tempo",
    "finance.records.024F5F0B851782De": "Waktu penambangan：",
    "finance.records.3143A26D3F983896": "waktu penebusan：",
    "finance.records.96574Aaf8Db85Ead": "penebusan awal",
    "finance.records.531E1829203181Ac": "Apakah Anda yakin ingin menebus lebih awal;",
    "finance.records.9205843B8923Dac3": "Penukaran berhasil;",
    "loan.applyCallback.B9258Ec8746A74D8": "Saluran peminjaman",
    "loan.applyForm.7B8Ed340400A6E2B": "nama",
    "loan.applyForm.549A44Bae725F4A2": "nama belakang",
    "loan.applyForm.2A27E61375E7Fea8": "tanggal lahir",
    "loan.applyForm.89925B019D537738": "alamat email",
    "loan.applyForm.Afaeb32Cb4D62E28": "Alamat rumah (termasuk nomor unit)",
    "loan.applyForm.E05B5D049B64B040": "Kota",
    "loan.applyForm.B166E4E8Fe9513Fa": "bangsa",
    "loan.applyForm.83Aaede109E395F8": "kode Pos",
    "loan.applyForm.647Ed21332A4968B": "penghasilan bulanan Anda",
    "loan.applyForm.Cf0A9B845D34D4B4": "Penghasilan tambahan (opsional)",
    "loan.applyForm.7048F618Acc6A54C": "Sewa bulanan atau pembayaran hipotek",
    "loan.applyForm.15C1Bba18Ae18545": "Beritahu kami jika Anda memiliki hipotek",
    "loan.applyForm.3921E90625A84E36": "Jika tidak ada jaminan hipotek yang dipilih, kemungkinan lolos peninjauan rendah.",
    "loan.applyForm.262Eec4F8906B0F6": "Apakah ada hipotek?",
    "loan.applyForm.8634Eb21B6910F21": "Silakan pilih jenis hipotek",
    "loan.applyForm.A4897B3A331B5E61": "Voucher transaksi",
    "loan.applyForm.6623384C683De457": "Mari kita tetap berhubungan",
    "loan.applyForm.A8360Bdba86834Cb": "Kami dapat menghubungi Anda di nomor ini jika kami memerlukan informasi lebih lanjut mengenai pengajuan pinjaman Anda",
    "loan.applyForm.C1C373F385A4C7B3": "nomor telepon",
    "loan.applyForm.1B9785Badb91191F": "Setelah mengisi informasi, harap menunggu evaluasi dan peninjauan sistem.",
    "loan.applyForm.8755B9C761D4080C": "Saya telah membaca dan menandatangani persetujuan saya secara elektronik",
    "loan.applyForm.3E987B787468148C": "Ketentuan Penggunaan dan Persetujuan Elektronik",
    "loan.applyForm.Bc54E76B61C50Fb9": "Harap membaca dan menyetujui perjanjian tersebut",
    "loan.applyForm.89E158384F634Eb8": "Kembali ke langkah sebelumnya",
    "loan.applyForm.Efa5A4548E2F3510": "Alamat rumah",
    "loan.applyForm.4Cadf1787541B35D": "Ya,",
    "loan.applyForm.Fab408D2F4100447": "TIDAK,",
    "loan.applyForm.3Bad7A307480Cc56": "Format email salah;",
    "loan.applyForm.306322F49B36855D": "Permohonan telah dikirimkan, harap tunggu ulasan layanan pelanggan",
    "loan.applyForm.3C6E0E858C995Ff9": "Silakan upload voucher transaksi",
    "loan.applyForm.2Bf443476B271376": "Maksimal 4 gambar dapat diunggah",
    "loan.applyQuota.Ef1D8E23003B9A6F": "Saluran peminjaman",
    "loan.applyQuota.Ef1D8E23003B9A6G": "Dapatkan batas pinjaman",
    "loan.applyQuota.9E0780F9352C7273": "saldo dompet",
    "loan.applyQuota.Cbc1Eb9D639A6105": "Total jumlah pinjaman yang tersedia",
    "loan.applyQuota.033A05462195B080": "Jumlah pinjaman",
    "loan.applyQuota.9Aa89B036Ef4821D": "Saldo yang tersisa",
    "loan.applyQuota.5656Fb52Ef17A557": "Dapatkan batas pinjaman",
    "loan.applyQuota.6Ff6702B4C917D40": "sedang ditinjau",
    "loan.applyQuota.6Ff6702B4C917D41": "ditolak",
    "loan.applyQuota.6Ff6702B4C917D42": "berhasil",
    "loan.applyQuota.0Bad0E857Ea4A952": "waktu aplikasi",
    "loan.applyQuota.3E7E56E2De2Cc546": "Jumlah aplikasi",
    "loan.applyQuota.8F1D170D9E54Af5B": "Kemajuan Aplikasi",
    "loan.applyQuota.6167Ca035B93Caf3": "alasan",
    "loan.applyQuota.410C7954F9A3981D": "Melamar lagi",
    "loan.bottomSheet.61Cd62F861D8F5B2": "Silakan pilih mata uang",
    "loan.index.4C10307B22E885C3": "Temukan lebih banyak peluang perdagangan dan ciptakan kekayaan",
    "loan.index.Ae8D37Dea2B3717B": "suku bunga",
    "loan.index.1Bb54625682E85Db": "Pengenalan aturan",
    "loan.index.5Bd31Fab681C6E9D": "lihat lebih banyak",
    "loan.index.111F95F404814327": "Mata uang pinjaman",
    "loan.index.6Bc2723B3Bec106D": "meminjamkan",
    "loan.index.3200106280E6E370": "suku bunga",
    "loan.index.049A6Ccb6C81A635": "Jumlah pinjaman (min/maks)",
    "loan.productDetails.5037Ad86Ab4B7E02": "meminjamkan",
    "loan.productDetails.37181C74559Db182": "Memesan ",
    "loan.productDetails.5802Aaf06A1E4755": "Likuiditas",
    "loan.productDetails.89Aea9Cf9337C40F": "Jumlah yang tersedia untuk dipinjam",
    "loan.productDetails.9E419Ae2D06F3Ad9": "Jumlah yang harus dibayar kembali",
    "loan.productDetails.C65F329Ef14C4A3D": "Jumlah koin yang dipinjam",
    "loan.productDetails.3A74667815Adc8Bf": "maksimum",
    "loan.productDetails.Ab851D8351573227": "langit",
    "loan.productDetails.Ddf82D8A2C8F46A0": "siklus pembayaran",
    "loan.productDetails.C105E5562005Aeb1": "langit",
    "loan.productDetails.3F346Be2137F0756": "Jumlah pinjaman (min/maks)",
    "loan.productDetails.2707F78Df864E194": "Minat",
    "loan.productDetails.B9Dc9B81E726Bdf1": "Mohon dilunasi tepat waktu, jika terlambat akan dikenakan tambahan jumlah harian (pokok + bunga).",
    "loan.productDetails.B9Dc9B81E726Bdf2": "% adalah biaya yang telah jatuh tempo",
    "loan.productDetails.4D94Faa033Be7356": "Saya sudah setuju",
    "loan.productDetails.62429A957A22780A": "Perjanjian Layanan Peminjaman Koin",
    "loan.productDetails.Bc54E76B61C50Fb9": "Harap membaca dan menyetujui perjanjian tersebut",
    "loan.productDetails.Ec8Ba071C53A67Cb": "Pinjam sekarang",
    "loan.productDetails.59246A0Bf081D398": "paling sedikit",
    "loan.productDetails.Ec3F1172B71Cd36C": "jumlah minimal",
    "loan.productDetails.7Ccc4Cf1D81930B3": "jumlah terbesar",
    "loan.productDetails.071488598990Aa68": "Harap membaca dan menyetujui perjanjian tersebut;",
    "loan.productDetails.579Dd216D3339119": "Pinjaman tersebut berhasil;",
    "loan.records.4951B36Fd0D2A831": "Sejarah pinjaman dan kredit",
    "loan.records.Bb0Aaa30514B7Cba": "rekor saat ini",
    "loan.records.0F8B0A7D80769051": "Riwayat pembayaran",
    "loan.records.D4B42E43D88De6F1": "catatan sejarah",
    "loan.records.Cb1847D6281897E6": "Jumlah pinjaman",
    "loan.records.C7Bfcacfc2Adea58": "Jumlah Pelunasan",
    "loan.records.8B369164D5Fea340": "Waktu pinjaman：",
    "loan.records.Ab2402Ad3Ffd35E7": "Lunasi pinjaman lebih awal",
    "loan.records.B7A0Ca0C195D0Cd1": "Waktu rilis：",
    "loan.records.42014Bdcfd9C411B": "Waktu pembayaran：",
    "loan.records.A0Acfaee54F06458": "lengkap",
    "loan.records.67A39834582Ad99A": "Tidak ada data",
    "loan.records.6A687Ff5B8F1598F": "Apakah Anda yakin ingin membayar kembali di muka?",
    "loan.records.7C20D7008D0Eae86": "Pelunasan berhasil",
    "mining.bottomSheet.Ba91637D32Ccba04": "Penambangan likuiditas",
    "mining.bottomSheet.7De3D636A165E008": "tingkat pengembalian",
    "mining.bottomSheet.F3003345B189D683": "Periode penambangan",
    "mining.bottomSheet.3E4C0A8C533F5848": "jenis koin",
    "mining.bottomSheet.Adcba368993Ae65E": "Jumlah berlangganan (minimum/maksimum)",
    "mining.bottomSheet.94743B24E4Ed4166": "Tak terbatas",
    "mining.bottomSheet.286944Fedd211257": "Dana yang tersedia",
    "mining.bottomSheet.7B5766Dc65Abc0C3": "Perkiraan pendapatan:",
    "mining.bottomSheet.9Bbaf80Fdb6Fd148": "Milikku sekarang",
    "mining.bottomSheet.Bb1493C567C114Dd": "Tip yang baik：",
    "mining.bottomSheet.D2315E87E34A3Bd3": "Biaya penebusan awal",
    "mining.index.8B2Bba1422Eed6F0": "Ciptakan kekayaan puluhan miliar, laba bersih 100%.",
    "mining.index.42997E300086Ee89": "pengembalian tertinggi",
    "mining.index.510F74F42052Ecce": "Produk pertambangan",
    "mining.index.B853F96626106912": "Pergi menambang",
    "mining.productList.37181C74559Db182": "Memesan",
    "mining.productList.Aac5B810004C46C8": "reguler",
    "mining.productList.D2Fb4F78C387D888": "Anda telah berhasil menambang",
    "quantify.bottomSheet.9Ea4A318F690Bc95": "Konfirmasi pemesanan",
    "quantify.bottomSheet.6915303B698303B1": "robot",
    "quantify.bottomSheet.68045296Dd0F8Ad6": "Jumlah sewa",
    "quantify.bottomSheet.Fbd931286Be387Aa": "siklus",
    "quantify.bottomSheet.9Ad84Db481807844": "tanpa batas waktu",
    "quantify.bottomSheet.994D7226Ca083Ea5": "Tingkat pengembalian harian",
    "quantify.bottomSheet.0Bf82Ee4Bfe063B7": "tanggal nilai",
    "quantify.bottomSheet.88008603Ba7Bde64": "Pembelian berhasil",
    "quantify.bottomSheet.Ff50B3091Bbd09Ab": "Dapatkan bagi hasil mulai sekarang！",
    "quantify.bottomSheet.C778A674C4A054F9": "periksa pesanan",
    "quantify.bottomSheet.685Dad322B7029B0": "Transaksi pengembalian",
    "quantify.buyForm.Acf8560A04334E95": "harga pembelian",
    "quantify.buyForm.Cf9Cfbc2C554944F": "Ringkasan",
    "quantify.buyForm.8788Df2B625E97B5": "robot",
    "quantify.buyForm.50Ca63B45E881D50": "Waktu pembayaran dividen",
    "quantify.buyForm.Fcda03Adbc5Fca97": "setiap hari",
    "quantify.buyForm.Afb89504Fc399189": "Silakan masukkan jumlah pembelian",
    "quantify.buyForm.1435Afdfb4Ec0596": "Kuantitas pembelian minimum",
    "quantify.buyForm.1B97F1730Bacd839": "Kuantitas pembelian maksimum",
    "quantify.index.32F56C9B7D0E250A": "Periode: tidak terbatas",
    "quantify.index.E34F028D00904630": "harga",
    "quantify.index.E34F028D00904631": ", tingkat pengembalian harian",
    "quantify.productDetail.6Bbe178Df539A5Ca": "Jumlah escrow",
    "quantify.productDetail.53635B53Bca31Dfa": "Perkiraan pendapatan harian",
    "quantify.productDetail.F9E46449A6269E09": "Pendapatan kumulatif",
    "quantify.productDetail.98Fc46B1B8C993B2": "Pesan hosting",
    "quantify.productDetail.93624Bb045830966": "Sewa sekarang",
    "quantify.records.0C16A18C32B9Cf4A": "Catatan pesanan",
    "quantify.records.B191F5614B0E2312": "Riwayat Pembelian",
    "quantify.records.6A06033B86A73F7C": "catatan penebusan",
    "quantify.records.45E0F7E71611618E": "Pendapatan kumulatif",
    "quantify.records.45E0F7E71611618F": "Keuntungan pada saat jatuh tempo",
    "quantify.records.Aae1201E38C4Baaa": "Berlari",
    "flash.index.C41Fd4F2Fdfa594B": "mengkonsumsi",
    "flash.index.1Ac59Fa2218E3B00": "Tersedia:",
    "flash.index.Bf7233A660821549": "mendapatkan",
    "flash.index.548E775Dd67C7472": "Konfirmasikan pertukaran",
    "flash.index.066A2449Bd616F03": "Silakan masukkan jumlah penukaran",
    "flash.index.Fca26Fcf9C06563F": "Penukaran flash berhasil",
    "message.index.40031524233F4564": "Pusat pesan",
    "message.index.8E5586210Fd44D69": "Pengingat posisi abadi",
    "message.index.Bcd3F470E88C197D": "Belum ada pemberitahuan",
    "message.index.23A557Fbce545879": "Pemberitahuan Likuidasi Permanen",
    "message.index.B5E19B36B5125550": "Pengingat penarikan",
    "message.index.716A981465138334": "Pengingat isi ulang",
    "message.index.884474Ebff2977Ba": "sistem Informasi",
    "message.index.08Cc7D24752Ec42C": "layanan daring",
    "message.index.1D820A6A19Ab025D": "Layanan manual online 7 X 24 jam",
    "more.index.29881E0B5Df58Bf7": "Lebih banyak fitur",
    "more.index.C77Ee35911D10D9D": "Fungsi Umum",
    "more.index.3558F69B0260Be61": "Fungsi perdagangan",
    "more.index.7243Fe610Ed98Cec": "akun nyata",
    "more.index.7243Fe610Ed98Ced": "Akun demo",
    "more.index.693C7B35Bdf3E3A3": "keuangan",
    "more.index.F855997Fac114554": "Pusat Bantuan",
    "more.index.Ae9A1252278Da742": "membantu",
    "more.index.Ace012504Bc529E0": "informasi",
    "more.index.05682Dbef5Eeb0A8": "pelayanan pelanggan",
    "more.index.C7Af227A5D5C7Bb9": "Anda telah beralih ke akun live",
    "more.index.D818A65592694786": "Anda telah beralih ke akun demo",
    "notice.details.B360807Dbe727E6E": "tentang kami",
    "notice.details.92F2552F695E70E3": "tentang kami",
    "notice.details.7Aaceaf69B67F3D1": "Pengenalan aturan",
    "notice.index.6128438Be34Bcb9F": "Pemberitahuan abadi",
    "notice.index.71627F5Eb7128261": "Pemberitahuan penarikan",
    "notice.index.2Dce570Fda841705": "Pemberitahuan isi ulang",
    "notice.index.228A7C9C6448A9B1": "pemberitahuan sistem",
    "setting.dialog.8B4F5B74660Deabe": "Konfigurasi warna",
    "setting.dialog.Bfc7D25486Ebc1A9": "Hijau naik dan merah turun",
    "setting.dialog.796Ea0E1A82C320B": "Merah naik dan hijau turun",
    "setting.index.8Ddc5864E1739466": "Preferensi",
    "setting.index.F5205801D57D6727": "bahasa",
    "setting.index.2E2C12Dd0Cc1B63B": "Mata uang kutipan",
    "setting.index.322D8496Aec3D918": "nomor versi",
    "setting.index.Aa7E6C204C751Cfa": "keluar",
    "share.index.75Bc38Bbf7D2E766": "mengundang teman-teman",
    "share.index.45575Eb5D42213E6": "kode undangan saya",
    "share.index.29F67F0644A78Bf3": "menyalin",
    "share.index.19Cc65Bb6Bb98Fe7": "Tautan undangan saya",
    "share.index.5372712F162Fb78B": "Jumlah bawahan langsung",
    "share.index.C88D6B95E3Ab7D6F": "Jumlah total bawahan",
    "share.index.Bb5Fa5471Aeecc62": "Rabat komisi untuk bawahan langsung",
    "share.index.426B6Bac9A5F3D4F": "total rabat",
    "share.index.197E4A428F0401C9": "Lihat catatan rabat",
    "share.records.88A6B2E2B74B13C4": "Catatan komisi",
    "share.records.B671A35Ffdc93B89": "Potongan harga",
    "transfer.index.B17617A869C03Ca5": "dompet",
    "transfer.index.E4116Dcc6F6C7270": "Transfer berhasil",
    "transfer.index.48Fb603C6638C7F6": "Silakan atur kata sandi dana",
    "transfer.records.Eb0E39Eb5911D418": "mendapatkan",
    "transfer.records.977554Be0Aa65052": "Waktu penukaran kilat：",
    "transfer.records.9Aa33884Aa400B7C": "waktu perpindahan：",
    "form1.forget.76636Db8Fae5B124": "kotak surat Anda",
    "form1.forget.35354B9Cca1Bb0E6": "Kode verifikasi email",
    "form1.forget.604A0B11E916F387": "kata sandi",
    "form1.forget.9Cb1C9C51E7503D6": "konfirmasi sandi",
    "form1.forget.3A4F7E04B008453C": "Nomor telepon Anda",
    "form1.forget.Cf11Dce5B4713B5A": "Kode verifikasi ponsel",
    "form1.forget.3012Edba57D4F297": "Masukkan email Anda",
    "form1.forget.F1Ee1D97E869E447": "silakan masukkan kode verifikasi",
    "form1.forget.3A5C4C9Ae68Fa0F6": "silakan masukkan kata sandi Anda",
    "form1.forget.C6Cb174Da411D5F7": "Silakan masukkan ponsel Anda",
    "form1.forget.947B3B21Ee45E582": "mengirim",
    "form1.forget.3E0580F1E8630Df6": "Dua kata sandi tidak konsisten",
    "form1.forget.C5A61836B8Cc74C6": "Perubahan kata sandi berhasil dialihkan...",
    "form1.forget.B96D072Bc8962565": "Kirim ulang",
    "form1.login.2B72C27Ff828Cfc4": "kata sandi Anda",
    "form1.login.D7C6B872Af9Cb17F": "ingat kata sandi saya",
    "form1.login.0Fff44C827A4F3B6": "Gabung",
    "form1.login.1B7F2C1Ede3990Cc": "Belum punya akun? Daftar sekarang",
    "form1.login.F657F7Be1Cc33Bd2": "lupa kata sandinya",
    "form1.login.2074A1Eec4B088B0": "Masuk dompet",
    "form1.login.1B8938Fbcc3C9B56": "Login berhasil dan pengalihan...",
    "form1.login.07162A7Bb9310555": "Silakan instal dompet ERC20",
    "form1.login.766969966Ae151Dc": "Silakan instal dompet TRC20",
    "form1.login.B88171E1Ad4C50Bf": "Anda menolak untuk berpindah jaringan",
    "form1.register.Aaef0710Cb4Db554": "Masukkan kata sandi lagi",
    "form1.register.54Dfea0415C708Ff": "Kode undangan",
    "form1.register.6839940Dac08C794": "daftar",
    "form1.register.9901A3B5550Aa58E": "Sudah memiliki akun? Gabung",
    "form1.register.2548Ca53785252Ea": "Silakan masukkan kode undangan Anda",
    "form1.register.Dd71Fd65900Dd03E": "Silakan baca Perjanjian Layanan Pendaftaran dan setuju,",
    "form1.register.7975613909490B77": "Pendaftaran berhasil dan pengalihan...",
    "form1.walletDialog.105829Acfc0Bd6Aa": "Pilih dompet",
    "userc.certification.2Af9511640153D2E": "Sertifikasi Junior",
    "userc.certification.177B191D8E0Fa84D": "Tidak Sertifikasi",
    "userc.certification.601218D4D572E98A": "diverifikasi",
    "userc.certification.6E37C51Ba9012Fc8": "Tidak lulus",
    "userc.certification.1B9B563E05C96474": "Sertifikasi lanjutan",
    "userc.certification.1418B83803Ab56B4": "Silakan selesaikan sertifikasi utama terlebih dahulu",
    "userc.creditScore.0B149112601Acc4D": "nilai kredit",
    "userc.creditScore.88Ac7F84E106C5F6": "total skor kredit",
    "userc.creditScore.3E1D6E0E7F1E6155": "Evaluasi sistem",
    "userc.index.62Eac58D04Bbca0F": "Pusat Pribadi",
    "userc.index.F4Cac53F93Cb2Aff": "perdagangan simulasi",
    "userc.index.42F409Fdf3559773": "Pusat keamanan",
    "userc.index.1B8E211009807Db8": "Autentikasi",
    "userc.index.Bdfcf21Eb8F2Eb70": "Catatan perubahan akun",
    "userc.index.6C18D67F6D19586B": "Pengenalan Platform",
    "userc.index.807A814Ed58A93Bc": "bersihkan cache",
    "userc.index.E43Daad59759611C": "Tidak Sertifikasi",
    "userc.secureCenter.F5C70F0Db2F1Ba76": "Ubah kata sandi masuk",
    "userc.secureCenter.1F37Ba427Debf24A": "Pengikatan akun",
    "userc.secureCenter.F9A5Ffa750664004": "Ikat Google Authenticator",
    "userb.certificationForm.7685Af7D30043Cd6": "Sertifikasi Junior",
    "userb.certificationForm.7685Af7D30043Cd7": "Sertifikasi lanjutan",
    "userb.certificationForm.70E37C35Abc3F462": "Sertifikasi lanjutan",
    "userb.certificationForm.F92C25A68E4D08C5": "Kota",
    "userb.certificationForm.0Ccc6387B38E0318": "Namamu",
    "userb.certificationForm.94C67Bba370Fdd46": "nomor lisensi",
    "userb.certificationForm.B2D4C7Fffe79258E": "Surat",
    "userb.certificationForm.57554268761Fb47A": "Unggah bagian depan tanda pengenal Anda",
    "userb.certificationForm.D79E90201F5319Bd": "Unggah bagian belakang ID Anda",
    "userb.certificationForm.9C6Bad08Af29E20E": "Unggah gambar kartu identitas Anda",
    "userb.certificationForm.F96B62Dfa31Cde45": "Konfirmasi Pengiriman",
    "userb.certificationForm.2125D78Ea8C6D287": "Persyaratan unggah",
    "userb.certificationForm.0D64864D3076A824": "Lihat contoh",
    "userb.certificationForm.35287Deb72281E87": "1.Harus dapat membaca informasi KTP dengan jelas.",
    "userb.certificationForm.3007F2E01Efc7B57": "2.Isi foto adalah benar dan sah serta tidak diperbolehkan adanya modifikasi.",
    "userb.certificationForm.25A90660554319F9": "3.Nomor ID dan nama harus terlihat jelas, support JPG/JPEG/PNG",
    "userb.certificationForm.5A9Aefbc03C778F7": "silakan masuk",
    "userb.certificationForm.3B7A1D8Ce27C937E": "Pengiriman berhasil, harap menunggu peninjauan",
    "userb.certificationForm.744Bd07D8Abd2513": "Silakan unggah bagian depan tanda pengenal Anda",
    "userb.certificationForm.6De1C319130F7Fef": "Silakan unggah bagian belakang tanda pengenal Anda",
    "userb.certificationForm.79F8E8B172E30C8A": "Silakan unggah foto kartu identitas Anda",
    "userb.certificationForm.46412E16D28A4753": "Pengunggahan foto gagal",
    "userb.changeDialog.676B0Adca1F8Eea4": "ganti kata sandi",
    "userb.modifyInfoDialog.F41De3A1Fa69135A": "nomor telepon",
    "userb.modifyInfoDialog.3C3Aac9664A35Ef8": "saklar validator",
    "userb.modifyInfoDialog.62149E566814154C": "Pilih validator",
    "userb.modifyInfoDialog.8E69468E2435Fc89": "Buka aplikasi Otentikasi dan ketuk +",
    "userb.modifyInfoDialog.7625E5Bdcbfe9963": "Pilih 'Pindai kode QR' atau 'Masukkan kunci'",
    "userb.modifyInfoDialog.93F094E2B4369472": "Pindai kode QR atau masukkan kuncinya",
    "userb.modifyInfoDialog.43E4F1532529Ee1E": "tutup buka",
    "userb.modifyInfoDialog.Bae6358Dfd95025A": "Kata sandi dana lama",
    "userb.modifyInfoDialog.E1133Cd4B1C2Abb7": "Kata sandi dana baru",
    "userb.modifyInfoDialog.C1Ce0403A2523Ee0": "Konfirmasikan kata sandi dana baru",
    "userb.modifyInfoDialog.8Dd453Adc2Cd78Fb": "Konfirmasikan kata sandi dana",
    "userb.modifyInfoDialog.Bb0D9854C79325Bb": "Silakan masukkan kata sandi dana lama Anda",
    "userb.modifyInfoDialog.E66Cb29B00Bc54D1": "Silakan masukkan kata sandi dana baru Anda",
    "userb.modifyInfoDialog.E8Ae2Ec5Df401255": "Silakan masukkan kata sandi dana Anda",
    "userb.modifyInfoDialog.258A534498Fe984F": "Harap konfirmasi kata sandi dana Anda",
    "userb.modifyInfoDialog.768Ec43Faa193F08": "Pengikatan akun",
    "userb.modifyInfoDialog.D34746090C25E5Db": "Kata sandi dana",
    "userb.modifyInfoDialog.E4F27D8916E5E536": "mengonfirmasi",
    "userb.modifyInfoDialog.Bef7160Ce53773E0": "Validator ditutup",
    "userb.modifyInfoDialog.Be8Bf47Ba6820De1": "Verifikasi Google berhasil diaktifkan",
    "userb.modifyInfoDialog.24Dba26593F43C74": "Kata sandi dana berhasil diubah",
    "userb.modifyInfoDialog.5C369D42Dc7Dbe76": "Berhasil mengatur kata sandi dana",
    "userb.modifyInfoDialog.667Bf8D0B6C36D4C": "Pengikatan berhasil",
    "userb.modifyInfoDialog.1C5E05Efee2B0B9F": "Kesalahan kode verifikasi",
    "userb.veBottomSheet.Eecc4683C725F6F2": "Contoh pengunggahan sertifikat",
    "userb.veBottomSheet.6F38A0D5858633Ee": "latar belakang bersih gelap",
    "userb.veBottomSheet.Af162Dbdfc4Fcae9": "Pencahayaan yang cukup",
    "userb.veBottomSheet.0F7Baf0Cee3C8141": "Bingkai bantu penyelarasan",
    "userb.veBottomSheet.Ac9E471Eead0Cf11": "Foto jelas",
    "userb.veBottomSheet.50B741382E71F103": "Kemungkinan alasan kegagalan",
    "userb.veBottomSheet.Aa56C68E8Fd4Cfb4": "Penutup pelindung kartu ID belum dilepas",
    "userb.veBottomSheet.5D2F429C75D4C7F2": "Latar belakang dipengaruhi oleh kekacauan",
    "userb.veBottomSheet.90F42D3Cb7769Dbc": "Kartu ID bersifat reflektif",
    "userb.veBottomSheet.1Ee78Cee782D81B4": "Cahaya terlalu gelap/terhalang oleh bayangan ponsel",
    "userb.veBottomSheet.1A75D2Fc2614592B": "memegang kartu identitas",
    "home.home.C3525Eb110Db58Aa": "Lagi",
    "home.home.29761532Fc8Ebecc": "berdagang",
    "home.home.F15Ea99Bfa6E1848": "Sertifikasi keselamatan, beli dengan percaya diri",
    "home.home.46E1Ab39Bae7182A": "Cepat",
    "home.home.Fde2Eb7F6F3Dd800": "Transaksi cepat dan pengoperasian sederhana",
    "home.home.Ea4756Bc1642E0F1": "nama",
    "home.home.B6B1127Ede1C97B4": "harga terbaru",
    "home.home.3587Ad870Eb0Ab86": "24 jam bertambah dan berkurang",
    "home.home.Cb1A4E14Ec2Cd2B5": "Menemukan",
    "home.home.0F1B1Ac0E96A40D1": "Blockchain",
    "home.home.8Eaefb1107A0E210": "Valas",
    "home.home.Ad65263D034Ea051": "Logam",
    "home.home.5Fc1474Dbf74Ac1A": "Pertanian",
    "home.home.135318F4664F7773": "energi",
    "kline.kLine.C96Ff250Cf6B8B84": "Harga tertinggi 24 jam",
    "kline.kLine.6E8Be64F19D74565": "Volume perdagangan 24 jam",
    "kline.kLine.08A4Bfbe26A0F262": "Harga terendah 24 jam",
    "kline.kLine.8701Deb075066331": "Volume perdagangan 24 jam (USDT)",
    "kline.kLine.Ea3C3052136C84Cd": "1 poin",
    "kline.kLine.B49Cca240E013124": "5 poin",
    "kline.kLine.F27A90Be97Ba3D6C": "15 tanda",
    "kline.kLine.Ff3E62395B694384": "30 poin",
    "kline.kLine.C1993F9C6Db1A674": "1 jam",
    "kline.kLine.Fd2Cbbae405E284C": "1 hari",
    "kline.kLine.C9Cd71904395042C": "Januari",
    "kline.kLine.7D8127Ec18C7B8C7": "Membeli",
    "kline.kLine.E3D3Ffdb9B3E5636": "Jumlah (potongan)",
    "kline.kLine.6834Fec1838D8029": "Harga (USDT)",
    "kline.kLine.16C52Aae474Bc9Cd": "pesanan jual",
    "kline.kLine.B9Bea1A9A88D1419": "Buka lama",
    "kline.kLine.D36Cfa96Ee2B343B": "Buka sebentar",
    "other.languageRateSelect.6897784800834C1B": "pengaturan bahasa",
    "other.languageRateSelect.6897784800834C1C": "Silakan pilih mata uang harga",
    "other.navigationBar.F6E62F9642018Cbb": "halaman Depan",
    "other.navigationBar.0Ec227161A47B49D": "Kutipan",
    "other.navigationBar.29761532Fc8Ebecc": "berdagang",
    "other.navigationBar.693C7B35Bdf3E3A3": "keuangan",
    "other.navigationBar.9734C54620Eb09Af": "aktiva",

    "home.index.25Aa4Fc745150030": "The King of Cryptocurrency",
    "home.index.2D6Ecf8Faaa8B124": "Faster, better, stronger",
    "home.index.1Db26267D1041223": "Easily trade over 1700 cryptocurrencies",
    "home.index.F8E18C90C3E35618": "Register now",
    "home.index.29761532Fc8Ebecc": "Transaction",
    "home.index.D8A95E4506487452": "Popular Contracts",
    "home.index.5Bd31Fab681C6E9D": "View more",
    "home.index.010Ae07A09Caf221": "Trading pair",
    "home.index.F4D875118E6D6Dbb": "Latest transaction price",
    "home.index.3Dfdfb3D6599100E": "24-hour increase or decrease",
    "home.index.28Fb39Bd41E54242": "Trend Chart",
    "home.index.B47A3911Bacd94C6": "Growth List",
    "home.index.712910D574F43D95": "Stable income",
    "home.index.5507Fd830D7Bf51F": "Top strategy, easy to copy",
    "home.index.7De3D636A165E008": "yield",
    "home.index.B853F96626106912": "Go mining",
    "home.index.213B5C96Be7Cd1F8": "Days, annualized interest rate",
    "home.index.213B5C96Be7Cd1F9": "Maximum purchase",
    "home.index.Dc710Cffe6313Bb5": "View details",
    "home.index.B92Ffc837Ecaa1Dd": "News",
    "home.index.6C18D67F6D19586B": "Platform introduction",
    "home.index.6342E3C33Cb48Cd5": "Digital currency is a medium of exchange that uses cryptographic principles to ensure transaction security. Unlike fiat currencies such as the U.S. dollar, digital currencies do not have a physical form. Bitcoin (BTC), Ethereum (ETH) and Polkadot Popular digital currencies such as (DOT) use blockchain as the underlying technology and act as a decentralized digital ledger. All digital currency transactions are recorded in the blockchain and cannot be changed once confirmed and verified. Unlike traditional currencies, which are maintained by banks Unlike centralized ledgers, digital currency transactions occur on a public blockchain and can be accessed by anyone.",
    "home.index.198Efc4254683Eef": "In addition, according to the consensus mechanism, everyone can verify digital currency transactions and add them to the blockchain, thereby achieving decentralization. With Samsung, BlackRock, Morgan Stanley Leading global companies such as Alphabet and Alphabet have invested in it, and people believe that blockchain technology will completely change the global financial system. Digital currency opens the door to the world of decentralized finance for you, allowing you to explore the infinite possibilities brought by cutting-edge technology .",
    "home.index.E710339D9576292E": "Our advantages",
    "home.index.F9Ce8B2C63885B26": "Safe and stable",
    "home.index.C4D0A2C9Ff0305Ce": "Top technical team, comprehensive security protection, independent research and development of high-speed transaction synthesis engine, which can still operate stably and reliably under massive concurrent transactions.",
    "home.index.2E8708Ee3F11Cc1A": "Professional and reliable",
    "home.index.Bb12Cbcf89C56C5C": "Professional operation team, many years of experience in blockchain and financial industry, holding a compliant digital asset trading license, and 100% reserve guarantee.",
    "home.index.Aa4723Bfbf6808F4": "Service with heart",
    "home.index.1Bf0204B7D3Ee230": "Facing the global market, supporting multiple languages, running 24/7, strong community support, and professional customer service.",
    "home.index.2E7Dfd53D26F7224": "High performance",
    "home.index.1C65453103B5Fcc3": "300,000 transactions per second, order response time is less than 1 millisecond.",
    "home.index.85Ac49Ece9Dcc80B": "Trade anytime, anywhere!",
    "home.index.2799E18842E4Ebe9": "Instant position opening, supported by OKEX App and web page!",
    "home.index.2Bf52Ce75E29Fc88": "Scan the code to download now",
    "home.index.88D1422B33571066": "Android",
    "home.index.04Ee893775563F21": "Apple",
    "home.index.04Ee893775563F22": "Products and Services",
    "home.index.04Ee893775563F23": "Financial Management",
    "home.index.04Ee893775563F26": "IOS & ANDROID",
    "homepage.text1": "Beli Bitcoin, Ethereum dan",
	"homepage.text1_2": "1000+ cryptocurrency lainnya",
	"homepage.text2": "Pengalaman mata uang kripto Anda",
	"homepage.text4": "Manajemen keuangan dengan tingkat bunga tinggi",
	"homepage.text5": "Manajemen keuangan stabil, hasil tahunan tinggi",
	"homepage.text6": "Bagikan hadiah",
	"homepage.text7": "Transaksi cepat, mudah dioperasikan",
	"homepage.text8": "Lihat lebih banyak",
	"homepage.text9": "Tukar langsung",
	"homepage.text10": "Verifikasi keamanan, belanja dengan aman",
	"homepage.text11": "Tim teknis teratas, keamanan komprehensif",
	"homepage.text12": "Perlindungan, independen",
	"homepage.text13": "Profesional dan dapat diandalkan",
	"homepage.text14": "Tim operasional profesional, berpengalaman bertahun-tahun dalam blockchain dan keuangan",
	"homepage.text15": "Layanan dengan hati",
	"homepage.text16": "Melayani pasar global, mendukung berbagai bahasa, operasional 24/7",
	"homepage.text17": "Kinerja tinggi",
	"homepage.text18": "Memproses 300,000 transaksi per detik, waktu respons pesanan kurang dari 1 milidetik",
    "passwod.limit":"Panjang kata sandi harus lebih besar atau sama dengan 6 karakter"
}
